
import { List, SimpleList, DateField, Datagrid, TextField, FunctionField, EmailField, UrlField, EditButton } from "react-admin";
import { Edit, Create, DateInput,TopToolbar, SelectColumnsButton,CreateButton,ExportButton,DatagridConfigurable, SimpleForm, ReferenceInput, TextInput, ReferenceField, SelectInput} from "react-admin";
import { BulkDeleteButton, BulkExportButton } from 'react-admin';
import { useNotify, useRefresh, useRedirect, useDataProvider} from 'react-admin';
import { Fragment,useState } from 'react';
import { MyEmailField } from "./MyUrlField";
import { required } from 'react-admin';
import {  FilterButton } from "ra-ui-materialui";
import { useFormContext } from 'react-hook-form';
import { Box, Typography } from "@mui/material";
import { useRecordContext } from 'react-admin';
import {Grid} from "@mui/material";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";
import {validateCodiceFiscaleEnhanced} from './validations';
import { useForm } from "react-hook-form"

import {cities, PROVINCES, COUNTRIES, STATES_NAMES} from "./states";

var CodiceFiscale = require('codice-fiscale-js');

const getTime=() =>{
  const now = new Date();
  const formattedDate = now.toLocaleString('it-IT', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
  }).replace(/\//g, '-').replace(',', ''); 
  const parts = formattedDate.split(' ');
  const finalDate = `${parts[0].split('-').reverse().join('-')} ${parts[1]}`;
  return finalDate;
}

const GenitoreBulkActionButtons = () => (
  <Fragment>
      <BulkExportButton maxResults= {15000} exporter={exportToXLS} />      
      <BulkDeleteButton mutationMode="pessimistic"/>
  </Fragment>
);




const GenitoreFilters = [
  <TextInput label="Cerca" source="q" alwaysOn />
];



const GenitoreActions = (props) => {
  const {
    className,
    basePath,    
    resource,
    currentSort,
    filterValues,
    exporter,
  } = props;
  return (
    <TopToolbar className={className}>
      <SelectColumnsButton />            
      <FilterButton/>
      <CreateButton basePath={basePath} /> 
      <ExportButton         
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
        maxResults= {15000}   
      />    
    </TopToolbar>
  );
};


const ExpandGenitore = () => {
  const record = useRecordContext();
  
  return (
    <Grid container>   
      <h3>ALTRE INFORMAZIONI</h3>
            <Box display="flex" alignItems="center" width="100%">
                <Typography variant="h7" sx={{ marginRight: 1, fontWeight:'bold' }}> 
                    Luogo di nascita: 
                 </Typography>
                 <TextField source="luogo_nascita"/>
            </Box>
            <Box display="flex" alignItems="center" width="100%">
                <Typography variant="h7" sx={{ marginRight: 1, fontWeight:'bold' }}> 
                    Provincia di nascita: 
                 </Typography>
                 <TextField source="provincia_nascita"/>
            </Box>
            
            <Box display="flex" alignItems="center" width="100%">
                <Typography variant="h7" sx={{ marginRight: 1, fontWeight:'bold' }}>                 
                    Stato di nascita: 
                </Typography>                    
                <TextField source="stato_nascita"/>                                         
            </Box>                         
            <Box display="flex" alignItems="center" width="100%">
                <Typography variant="h7" sx={{ marginRight: 1, fontWeight:'bold' }}>                 
                  Cittadinanza: 
                </Typography>                    
                <TextField source="cittadinanza"/>                                         
            </Box>        
            <Box display="flex" alignItems="center" width="100%">
                <Typography variant="h7" sx={{ marginRight: 1, fontWeight:'bold' }}>                 
                  Città di residenza: 
                </Typography>                    
                <TextField source="citta_residenza"/>                                         
            </Box>     
            <Box display="flex" alignItems="center" width="100%">
                <Typography variant="h7" sx={{ marginRight: 1, fontWeight:'bold' }}>                 
                  Indirizzo di residenza: 
                </Typography>                    
                <TextField source="indirizzo_residenza"/>                                         
            </Box> 
            <Box display="flex" alignItems="center" width="100%">
                <Typography variant="h7" sx={{ marginRight: 1, fontWeight:'bold' }}>                 
                Città di domicilio: 
                </Typography>                    
                <TextField source="citta_domicilio"/>                                         
            </Box>                 
            <Box display="flex" alignItems="center" width="100%">
                <Typography variant="h7" sx={{ marginRight: 1, fontWeight:'bold' }}>                 
                Indirizzo di domicilio: 
                </Typography>                    
                <TextField source="indirizzo_domicilio"/>                                         
            </Box>                         
    </Grid>             
  );
}

/*
const exportToXLS = (records, fetchRelatedRecords) => {  
  const worksheet = XLSX.utils.json_to_sheet(records);    

  const colWidths = Object.keys(records[0] || {}).map(key => ({
    wch: Math.max(
        key.length, // Larghezza del nome della colonna
        ...records.map(record => (record[key] ? record[key].toString().length : 0)) // Larghezza del contenuto
    )
}));
worksheet["!cols"] = colWidths;

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Dati");  
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });    
  const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  saveAs(data, "genitori.xlsx");
};
*/

const relazioneMap = {
  M: "Madre",
  P: "Padre",
  A: "Altro",
  T: "Tutore"
};

const exportToXLS = async (records, fetchRelatedRecords) => {
  if (!records || records.length === 0) return;
  
  //const bambiniMap = await fetchRelatedRecords(records, "id_bambino", "bambini");
  		

  const exportedRecords = records.map(record => ({
    "Identificativo": record.id,
    "Relazione con bambino": relazioneMap[record.relazione_con_bambino] || "N/A",
    "Cognome": record.cognome,
    "Nome": record.nome,
    "Genere": record.sesso,
    "Codice fiscale": record.codice_fiscale,
    "Data di nascita": record.data_nascita ? moment(record.data_nascita).format("DD/MM/YYYY") : "",
    "Luogo di nascita": record.luogo_nascita,
    "Provincia di nascita": record.provincia_nascita,
    "Stato di nascita": record.stato_nascita,
    "Cittadinanza": record.cittadinanza,
    "Città di residenza": record.citta_residenza,
    "Indirizzo di residenza": record.indirizzo_residenza,
    "Città di domicilio": record.citta_domicilio,
    "Indirizzo di domicilio": record.indirizzo_domicilio,
    "Telefono": record.telefono,
    "E-mail": record.email,
    "Data di inserimento": record.creation_time    
  }));
  const worksheet = XLSX.utils.json_to_sheet(exportedRecords);
  const colWidths = Object.keys(exportedRecords[0] || {}).map(key => ({
    wch: Math.max(
      key.length, 
      ...exportedRecords.map(record => (record[key] ? record[key].toString().length : 0))
    )
  }));
  worksheet["!cols"] = colWidths;
  
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Dati");  
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  saveAs(data, "genitori.xlsx");
};





export const GenitoreList = () => (
  <List  filters={GenitoreFilters} perPage={50} actions={<GenitoreActions exporter={exportToXLS} />}>    
    <DatagridConfigurable bulkActionButtons={<GenitoreBulkActionButtons />} expand={<ExpandGenitore/>}>       
      <TextField source="id" />      
      <TextField source="nome" />
      <TextField source="cognome" />      
      <TextField source="relazione_con_bambino" label="Ruolo"/>
      <TextField source="codice_fiscale" label="Codice fiscale"/>
      <TextField source="sesso" label="Genere"/>
      <DateField source="data_nascita" label="Data di nascita" locales="it-IT" showTime={false}/>            
      <MyEmailField source="email" />
      <TextField source="telefono" />
      
      <EditButton/>      
    </DatagridConfigurable>
  </List>
);



export const GenitoreEdit = () => {
  const notify = useNotify();  
  const redirect = useRedirect();
  const dataProvider = useDataProvider();

  const validateCodiceFiscale = (value) => {
    return /^[a-zA-Z0-9]{16}$/.test(value) 
        ? undefined 
        : 'Il codice fiscale deve contenere esattamente 16 caratteri alfanumerici';
  };

  const transform = (data) => {

    const sanitizedData = {};
    for (const key in data) {
        sanitizedData[key] = data[key]; 
    }
    sanitizedData["creation_time"]=getTime();    
    return sanitizedData; 
};

  const handleEdit = async (values) => {
    try {
      var data2=transform(values);
      console.log("Editing",data2,values);
      const { data } = await dataProvider.update('genitori', { id:data2.id, data: data2 });          
      console.log('Resource updated successfully:', data, values);          
      notify("Dati aggiornati con successo!", { messageArgs: { smart_count: 1 } });
      redirect('/genitori');                  
    } catch (error) {
      // Handle error
    }
  };

  return(
  <Edit title={<username />} mutationMode="pessimistic" transform={transform}>     
      <SimpleForm onSubmit={handleEdit} sx={{ maxWidth: '100%' }}>  
      <Typography variant="h6" gutterBottom>
          Dati genitore
    </Typography>       
    
    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
        <TextInput source="cognome" validate={required()} disabled={true} fullWidth/>
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
        <TextInput source="nome" validate={required()} disabled={true}  fullWidth/>
            
        </Box>
    </Box> 

    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
        <SelectInput sx={{textAlign: "left"}}  source="relazione_con_bambino" label="Ruolo" options={{fullWidth:true}} fullWidth disabled={true}
                choices={[{ id: 'M', name: 'Madre' },{ id: 'P', name: 'Padre' },{ id: 'T', name: 'Tutore' },{ id: 'A', name: 'Altro' } ]} validate={required()}/>              
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>          
        <SelectInput sx={{textAlign: "left"}}  source="sesso" label="Genere" options={{fullWidth:true}} fullWidth disabled={true}
                choices={[{ id: 'M', name: 'M' },{ id: 'F', name: 'F' } ]} validate={required()}/>                    
        </Box>
        
    </Box>
    
    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
        <TextInput source="codice_fiscale" label="Codice fiscale"  validate={[required(), validateCodiceFiscale]} disabled={true}  fullWidth />
        
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
        <DateInput source="data_nascita" label="Data di nascita" locales="it-IT" fullWidth disabled={true} validate={required()}/>            
        
        </Box>
        
    </Box>

    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
        <TextInput source="stato_nascita" label="Stato di nascita" validate={required()} disabled={true} fullWidth/>                    
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
        <TextInput source="provincia_nascita" label="Provincia di nascita" validate={required()} disabled={true} fullWidth/>                      
        </Box>
        
    </Box>


    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
        <TextInput source="luogo_nascita" label="Luogo di nascita" validate={required()} disabled={true} fullWidth/>                    
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>      
          <TextInput source="cittadinanza" validate={required()}  fullWidth/>                          
        </Box>        
    </Box>

    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
        <TextInput source="indirizzo_residenza" label="Indirizzo di residenza" fullWidth validate={required()} />                          
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="citta_residenza" label="Città di residenza" fullWidth validate={required()} />                
        </Box>        
    </Box>
  
    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
        <TextInput source="indirizzo_domicilio" label="Indirizzo di domicilio (se diverso da residenza)" fullWidth/>      
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="citta_domicilio" label="Città di domicilio (se diversa da residenza)" fullWidth/>                
        </Box>        
    </Box>

    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="telefono" fullWidth validate={required()} />                                    
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="email" fullWidth validate={required()} />            
        </Box>
    </Box>  
  
    </SimpleForm>
  </Edit> );
};




const transform2 = (data) => {  
  const sanitizedData = {};
  for (const key in data) {   
    if(key=="provincia_nascita"){
        //console.log(data[key]);
        if(data[key]==""||data[key]==undefined||data[key]==null )
          sanitizedData[key] = "EE";    
        else   
          sanitizedData[key] = data[key]; 
    }else{
        if(key=="codice_fiscale"){
          sanitizedData[key] = data[key].toUpperCase(); 
        }
        else{
          sanitizedData[key] = data[key]; 
        }
    }
  }  
  sanitizedData["creation_time"]=getTime();
  return sanitizedData; 
};

export const GenitoreCreate = ({ onSuccess, ...props }) => {   
    const dataProvider = useDataProvider();
    const [selectedStato, setSelectedStato] = useState("");
    const notify = useNotify();    
    const redirect = useRedirect();
    const [selectedProvince, setSelectedProvince] = useState("EE");
    const [filteredCities, setFilteredCities] = useState([]);
    
  
    const CFComponent = () => {          
      const { setValue } = useFormContext(); 
  
      const handleProvinceChange = (e) => {    
        const provinceCode = e.target.value;
        console.log("e vale",e,selectedProvince,provinceCode);
      
          setSelectedProvince(provinceCode);                            
          const uniqueCities = [];
          //const seenIds = new Set();
          for (const [id, province, name] of COUNTRIES) {
            //if (province === provinceCode && !seenIds.has(id)) {
            if (province === provinceCode) {
              uniqueCities.push({ id, name });
              //seenIds.add(id);
            }
          }            
          setFilteredCities(uniqueCities);               
    };
    
    return (
        <>                
      <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>    
          <TextInput source="cognome" fullWidth validate={required()}/>    
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
          <TextInput source="nome" fullWidth validate={required()}/>
        </Box>
      </Box>  

      <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <SelectInput sx={{textAlign: "left"}}  source="relazione_con_bambino" label="Ruolo" options={{fullWidth:true}} fullWidth
                choices={[{ id: 'M', name: 'Madre' },{ id: 'P', name: 'Padre' },{ id: 'T', name: 'Tutore' },{ id: 'A', name: 'Altro' } ]} validate={required()}/>                        
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>          
            <SelectInput sx={{textAlign: "left"}}  source="sesso" label="Genere" options={{fullWidth:true}} fullWidth
                choices={[{ id: 'M', name: 'M' },{ id: 'F', name: 'F' } ]} validate={required()}/>       
        </Box>        
      </Box>
        
      <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="codice_fiscale" label="Codice fiscale" fullWidth validate={[required(), validateCodiceFiscale]} />    
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>                            
            <DateInput source="data_nascita" label="Data di nascita" locales="it-IT" fullWidth validate={[required(), validateDataNascita]}/>    
        </Box>        
      </Box>
    
      <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <SelectInput sx={{textAlign: "left"}} source="stato_nascita"  label="Stato di nascita"  fullWidth  validate={required()} 
            optionText="name" optionValue="name"
            choices={STATES_NAMES.map(name => ({ id: name, name }))}  onChange={(e) => {setSelectedStato(e.target.value);          
              setValue("provincia_nascita", ""); setFilteredCities([]);  setValue("luogo_nascita", ""); }}/>
       
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
            {selectedStato=='Italia' ? (
              <SelectInput sx={{textAlign: "left"}} source="provincia_nascita"  label="Provincia di nascita"  fullWidth  validate={required()} 
              optionValue="id" 
              optionText="name"   
              choices={Object.entries(PROVINCES).map(([id, name]) => ({ id, name }))}                        
              onChange={handleProvinceChange} />       
            ):(              
              <TextInput source="luogo_nascita" label="Luogo di nascita" fullWidth validate={required()}/>
            )}
          </Box>
        </Box>
  
        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            {selectedStato=='Italia' && selectedProvince != '' && selectedProvince != undefined && selectedProvince != null? (
            <SelectInput sx={{textAlign: "left"}} source="luogo_nascita"  label="Luogo di nascita"  fullWidth  validate={required()} 
            optionText="name" optionValue="name" 
            choices={filteredCities}           
            />
            ):(
            <></>
            )}  
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>        
          </Box>
        </Box>  
        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>            
                <TextInput source="cittadinanza" validate={required()}  fullWidth/>                                      
        </Box>
        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source="indirizzo_residenza" label="Indirizzo di residenza" fullWidth validate={required()}/>    
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
              <TextInput source="citta_residenza" label="Città di residenza" fullWidth validate={required()}/>
            </Box>
        </Box>  
        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="indirizzo_domicilio" label="Indirizzo di domicilio (se diverso da residenza)" fullWidth/>  
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="citta_domicilio" label="Città di domicilio (se diversa da residenza)" fullWidth/>                
            </Box>
        </Box>        
        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="telefono" fullWidth validate={required()} />            
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>                    
            <TextInput source="email" fullWidth validate={required()} />            
        </Box>
    </Box>  
    </>    
      );
    };
    
    function normalizeText(text) {
      const mapping = {
          'à': "a'", 'è': "e'", 'ì': "i'", 'ò': "o'", 'ù': "u'",
          'À': "A'", 'È': "E'", 'Ì': "I'", 'Ò': "O'", 'Ù': "U'"
      };
  
      return text.replace(/[àèìòùÀÈÌÒÙ]/g, match => mapping[match]).toUpperCase();
  }
  
    
    const validateGenitoreFields = (values) => {
      console.log("VALIDATION", values);
      const errors = {};
      
      const requiredFields = [
        "cognome",
        "nome",
        "relazione_con_bambino",
        "sesso",
        "data_nascita",
        "stato_nascita",
        "luogo_nascita",        
        "citta_residenza",
        "indirizzo_residenza",        
        "cittadinanza",       
        "telefono",
        "email"
      ];
    
      requiredFields.forEach((field) => {
        if (!values[field]) {
          errors[field] = "Il campo è obbligatorio";
        }
      });
    
      
      if (!values.codice_fiscale) {
        errors.codice_fiscale = "Il codice fiscale è obbligatorio";
      } else {
        const cfError = validateCodiceFiscale(values.codice_fiscale);
        if (cfError) errors.codice_fiscale = cfError;
      }
    
      
      if (values.data_nascita) {
        const dnError = validateDataNascita(values.data_nascita);
        if (dnError) errors.data_nascita = dnError;
      }
    
      
      if (
        values.nome &&
        values.cognome &&
        values.sesso &&
        values.data_nascita &&
        values.codice_fiscale &&
        values.stato_nascita &&
        values.luogo_nascita
      ) {
        const parsedDate = moment(values.data_nascita, "YYYY-MM-DD");
        let cfBuilder;
    
        if (values.stato_nascita === "Italia") {
          if (!values.provincia_nascita) {
            errors.codice_fiscale = "Necessario selezionare la provincia";
          } else {
            cfBuilder = new CodiceFiscale({
              name: values.nome,
              surname: values.cognome,
              gender: values.sesso,
              day: parsedDate.format("DD"),
              month: parsedDate.format("MM"),
              year: parsedDate.format("YYYY"),
              birthplace: values.luogo_nascita,
              birthplaceProvincia: values.provincia_nascita,
            });
            console.log("CFBUILDER ITA:", cfBuilder.code);
          }
        } else {
          console.log("STATO ESTERO",values.stato_nascita.toUpperCase());
          cfBuilder = new CodiceFiscale({
            name: values.nome,
            surname: values.cognome,
            gender: values.sesso,
            day: parsedDate.format("DD"),
            month: parsedDate.format("MM"),
            year: parsedDate.format("YYYY"),
            birthplace: normalizeText(values.stato_nascita.toUpperCase()),
          });
          console.log("CFBUILDER EST:", cfBuilder.code);
        }
    
        if (cfBuilder?.code) {
          console.log("EXP CF", cfBuilder.code);
          if (
            cfBuilder.code !== values.codice_fiscale.toUpperCase() &&
            values.codice_fiscale.toUpperCase() !== "XXXXXXXXXXXXXXXX"
          ) {
            console.log("EXP CF2", cfBuilder.code);
            errors.codice_fiscale =
              "Il codice fiscale inserito non è coerente con i dati anagrafici";
          }
        } else {
          errors.codice_fiscale = "Il campo codice fiscale è essenziale per la validazione";
        }
      } else {
        errors.codice_fiscale =
          "I campi Nome, Cognome, Genere, Data di Nascita sono essenziali per la verifica del codice fiscale";
      }
    
      return errors;
    };
  
  
    const validateCodiceFiscale = (value) => {
      //console.log("VALIDATIN CODICE FISCALE");
      return /^[a-zA-Z0-9]{16}$/.test(value) 
          ? undefined 
          : 'Il codice fiscale deve contenere esattamente 16 caratteri alfanumerici';
    };
    
    const validateDataNascita = (value) => {
      if (!value) return "La data di nascita è obbligatoria";
    
      const dataInserita = moment(value);
      //const unAnnoFa = moment().subtract(1, "year");
      const unAnnoFa = moment().subtract(1, "year").subtract(1, "day");
    
      return dataInserita.isAfter(unAnnoFa)
        ? "Il genitore deve avere almento 12 mesi ed 1 giorno"
        : undefined;
    };

    
    const handleCreate = async (values) => {
      try {
        var data2=transform2(values);
        const { data } = await dataProvider.create('genitori', { data: data2 });
        
        // Custom logic after successful creation
        console.log('Resource created successfully:', data, values);
        //doRegisterMail(values.email, values.password, values.username);        
        notify("Utente creato correttamente! Una mail è stata inviata all'indirizzo inserito con la password generata", { messageArgs: { smart_count: 1 } });
        redirect('/genitori');        
      } catch (error) {
        notify("Qualcosa è andato storto nella creazione dell'utente. Riprovare più tardi");
      }
    };

  return(
  <Create transform={transform2}  {...props} >   
    <SimpleForm validate={validateGenitoreFields} onSubmit={handleCreate} sx={{ maxWidth: '100%' }}>  
    <Typography variant="h6" gutterBottom>
          Dati genitore
    </Typography>           
    <CFComponent/>
    </SimpleForm>
  </Create> );




};

