//import { List, Datagrid, TextField, EmailField } from "react-admin";
import { SimpleList, SelectColumnsButton, Datagrid, TextField, EmailField, UrlField, EditButton, TopToolbar, getValuesFromRecords } from "react-admin";
import { Edit, Create, DatagridConfigurable, DateField, SimpleForm, ReferenceInput, TextInput, ReferenceField, SelectInput} from "react-admin";
import { BulkDeleteButton, BulkExportButton } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Fragment,useRef } from 'react';
import {cities, PROVINCES, COUNTRIES, STATES_NAMES} from "./states";
import { useEffect } from "react";
import { MyEmailField,MyEmptyDateField } from "./MyUrlField";
import { DateInput, Toolbar, SaveButton } from 'react-admin';
import { useNotify, useRefresh, useRedirect, useDataProvider} from 'react-admin';
import { ImportButton } from "react-admin-import-csv";
import { CreateButton, FilterButton, ExportButton } from "ra-ui-materialui";
import { Chip }  from '@mui/material';
import { FileInput, FileField } from 'react-admin';
//import { makeStyles} from '@mui/styles';
import { List, downloadCSV, required } from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { useTranslate } from 'react-admin';
import React, { useState } from 'react';
import { Box, Typography } from "@mui/material";
import { useRecordContext } from 'react-admin';
import {Grid} from "@mui/material";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";
import {validateCodiceFiscaleEnhanced} from './validations';
import { useForm } from "react-hook-form"
var CodiceFiscale = require('codice-fiscale-js');



const getTime=() =>{
  const now = new Date();
  const formattedDate = now.toLocaleString('it-IT', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
  }).replace(/\//g, '-').replace(',', ''); 
  const parts = formattedDate.split(' ');
  const finalDate = `${parts[0].split('-').reverse().join('-')} ${parts[1]}`;
  return finalDate;
}

const exportToXLS = async (records, fetchRelatedRecords) => {
  if (!records || records.length === 0) return;
  
  const genitoriMap = await fetchRelatedRecords(records, "genitore_id", "genitori");

  const exportedRecords = records.map(record => ({
    "Identificativo": record.id,
    Genitore:     genitoriMap[record.genitore_id]
    ? `${genitoriMap[record.genitore_id].nome} ${genitoriMap[record.genitore_id].cognome} - ${genitoriMap[record.genitore_id].codice_fiscale}`
    : "N/A",
    "Codice fiscale": record.codice_fiscale,
    "Cognome": record.cognome,
    "Nome": record.nome,
    "Genere": record.sesso,
    "Data di nascita": record.data_nascita? moment(record.data_nascita).format("DD/MM/YYYY") : "",
    "Stato di nascita": record.stato_nascita,
    "Provincia di nascita": record.provincia_nascita,
    "Luogo di nascita": record.luogo_nascita,
    "Provincia di residenza": record.provincia_residenza,
    "Città di residenza": record.citta_residenza,
    "Indirizzo di residenza": record.indirizzo_residenza,
    "CAP di residenza": record.cap_residenza,                
    "Data di pagamento dell'assicurazione": record.data_pagamento_assicurazione? moment(record.data_pagamento_assicurazione).format("DD/MM/YYYY") : "",
    "Certificato di vaccinazione": record.certificato_vaccinazione,
    "Data di scadenza del certificato di vaccinazione": record.data_scadenza_certificato_vaccinazioni ? moment(record.data_scadenza_certificato_vaccinazioni).format("DD/MM/YYYY") : "",
    "Liberatoria privacy": record.conferma_presa_atto,
    "Data di inserimento": record.timestamp_submit      
  }));
  const worksheet = XLSX.utils.json_to_sheet(exportedRecords);
  const colWidths = Object.keys(exportedRecords[0] || {}).map(key => ({
    wch: Math.max(
      key.length, 
      ...exportedRecords.map(record => (record[key] ? record[key].toString().length : 0))
    )
  }));
  worksheet["!cols"] = colWidths;
  
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Dati");  
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  saveAs(data, "bambini.xlsx");
};


const BambinoBulkActionButtons = () => (
  <Fragment>
      <BulkExportButton maxResults= {15000} exporter={exportToXLS}  />      
      <BulkDeleteButton mutationMode="pessimistic"/>
  </Fragment>
);

const ExpandBambino = () => {
  const record = useRecordContext();
            
  return (
    <Grid container>   
      <h3>ALTRE INFORMAZIONI</h3>
            <Box display="flex" alignItems="center" width="100%">
                <Typography variant="h7" sx={{ marginRight: 1, fontWeight:'bold' }}>                 
                    Stato di nascita: 
                </Typography>                    
                <TextField source="stato_nascita"/>                                         
            </Box>                         
            <Box display="flex" alignItems="center" width="100%">
                <Typography variant="h7" sx={{ marginRight: 1, fontWeight:'bold' }}> 
                    Provincia di nascita: 
                 </Typography>
                 <TextField source="provincia_nascita"/>
            </Box>            
            <Box display="flex" alignItems="center" width="100%">
                <Typography variant="h7" sx={{ marginRight: 1, fontWeight:'bold' }}> 
                    Luogo di nascita: 
                 </Typography>
                 <TextField source="luogo_nascita"/>
            </Box>            
            <Box display="flex" alignItems="center" width="100%">
                <Typography variant="h7" sx={{ marginRight: 1, fontWeight:'bold' }}>                 
                  Indirizzo di residenza: 
                </Typography>                    
                <TextField source="indirizzo_residenza"/>                                         
            </Box>
            <Box display="flex" alignItems="center" width="100%">
                <Typography variant="h7" sx={{ marginRight: 1, fontWeight:'bold' }}>                 
                  Città di residenza: 
                </Typography>                    
                <TextField source="citta_residenza"/>                                         
            </Box>     
            <Box display="flex" alignItems="center" width="100%">
                <Typography variant="h7" sx={{ marginRight: 1, fontWeight:'bold' }}>                 
                  Provincia di residenza: 
                </Typography>                    
                <TextField source="provincia_residenza"/>                                         
            </Box>      
            <Box display="flex" alignItems="center" width="100%">
                <Typography variant="h7" sx={{ marginRight: 1, fontWeight:'bold' }}>                 
                  CAP di residenza: 
                </Typography>                    
                <TextField source="cap_residenza"/>                                         
            </Box>                                 
    </Grid>             
  );
}



const BambinoFilters = [
  <TextInput label="Cerca" source="q" alwaysOn />,
  <ReferenceInput source="genitore_id" reference="genitori" label="Genitore" perPage={1000} sort={{ field: 'nome', order: 'ASC' }}>
      <SelectInput optionText={(record) => `${record.nome} ${record.cognome} (${record.codice_fiscale})`} />                    
  </ReferenceInput>,    
  <TextInput label="Cognome" source="cognome" />,    
];



const BambinoActions = (props) => {
  const {
    className,
    basePath,    
    resource,
    currentSort,
    filterValues,
    exporter,
  } = props;
  return (
    <TopToolbar className={className}>
      <SelectColumnsButton />            
      <FilterButton/>
      <CreateButton basePath={basePath} />
      <ExportButton         
        resource={resource}
        sort={currentSort}
        filter={filterValues}        
        exporter={exporter}
        maxResults= {15000}   
      />        
    </TopToolbar>
  );
};


export const BambinoAdminList = (props) => (
  <List {...props} sort={{ field: 'timestamp_submit', order: 'DESC' }} filters={BambinoFilters} perPage={50} actions={<BambinoActions exporter={exportToXLS}/>}>
  {/*<List exporter={exporter} filters={BambinoFilters}> */}
    {/*<Datagrid rowClick="edit">*/}
    <DatagridConfigurable  bulkActionButtons={<BambinoBulkActionButtons />} expand={<ExpandBambino/>}> 
    <TextField source="id" />     
    <ReferenceField source="genitore_id" reference="genitori" label="Genitore">
        <TextField source="nome" /> <TextField source="cognome" /> - <TextField source="codice_fiscale" />
    </ReferenceField>            
    <TextField source="codice_fiscale" label="Codice fiscale"/>
    <TextField source="cognome" />
    <TextField source="nome" />
    <TextField source="sesso" label="Genere" />
    <DateField source="data_nascita" label="Data di nascita" locales="it-IT" showTime={false}/>    
    <TextField source="conferma_presa_atto" label="Liberatoria privacy"/>    
    <DateField source="data_pagamento_assicurazione" label="Data di pagamento dell'assicurazione" locales="it-IT" showTime={false}/>
    <TextField source="certificato_vaccinazione" label="Certificato vaccinazione"/>    
    <DateField source="data_scadenza_certificato_vaccinazioni" label="Data di scadenza certificato vaccinazioni" locales="it-IT" showTime={false}/>  
    <EditButton/>
    </DatagridConfigurable>
  </List>
);

const BambinoEditToolbar = props => (
  <Toolbar {...props} >
      <SaveButton />
  </Toolbar>
);

const validateDataNascita = (value) => {
  if (!value) return "La data di nascita è obbligatoria";

  const dataInserita = moment(value);
  //const unAnnoFa = moment().subtract(1, "year");
  const unAnnoFa = moment().subtract(1, "year").subtract(1, "day");

  return dataInserita.isAfter(unAnnoFa)
    ? "Il bambino deve avere almento 12 mesi ed 1 giorno"
    : undefined;
};

/*
const validateCodiceFiscale = (value) => {
  return /^[a-zA-Z0-9]{16}$/.test(value) 
      ? undefined 
      : 'Il codice fiscale deve contenere esattamente 16 caratteri alfanumerici';
};
*/




export const BambinoAdminEdit = () => {
  //const [selectedStato, setSelectedStato] = useState();
  //const [selectedProvince, setSelectedProvince] = useState();
  //const [filteredCities, setFilteredCities] = useState([]);  
 
 

  return(  
      <Edit title={<username />} mutationMode="pessimistic"> 
        {/*<SimpleForm validate={validateBambinoFields} sx={{ maxWidth: '100%' }} toolbar={<BambinoEditToolbar />} >*/}
        <SimpleForm   sx={{ maxWidth: '100%' }} toolbar={<BambinoEditToolbar />} > 
        <Typography variant="h6" gutterBottom>
        Dati registrazione bambino
    </Typography>       
    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <ReferenceInput source="genitore_id" reference="genitori" label="Genitore" perPage={1000} >                     
                <SelectInput option="nome" optionText={(record) => `${record.nome} ${record.cognome} - ${record.codice_fiscale}`}    options={{fullWidth:true}} sx={{textAlign: "left"}} validate={required()} fullWidth  disabled={true} />
            </ReferenceInput>
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="codice_fiscale" label="Codice fiscale" fullWidth  disabled={true} validate={required()} />    
        </Box>
    </Box>
    
    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>    
        <TextInput source="cognome" disabled={true} fullWidth validate={required()}/>    
      </Box>
      <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
        <TextInput source="nome" disabled={true} fullWidth validate={required()}/>
      </Box>
    </Box>  

    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
        <SelectInput sx={{textAlign: "left"}}  source="sesso" label="Genere" disabled={true} options={{fullWidth:true}} fullWidth
        choices={[{ id: 'M', name: 'M' },{ id: 'F', name: 'F' } ]} validate={required()}/>
      </Box>
      <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
        <DateInput source="data_nascita" label="Data di nascita" disabled={true} locales="it-IT" fullWidth validate={[required(), validateDataNascita]}/>    
      </Box>
    </Box>

    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
        <TextInput disabled={true} source="stato_nascita" label="Stato di nascita" fullWidth validate={required()}/>
      </Box>
      <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
        <TextInput disabled={true} source="provincia_nascita" label="Provincia di nascita" fullWidth validate={required()}/>
      </Box>
    </Box>

    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
        <TextInput disabled={true} source="luogo_nascita" label="Luogo di nascita" fullWidth validate={required()}/>
      </Box>
      <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
        
      </Box>
    </Box>

    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
        <TextInput source="indirizzo_residenza" label="Indirizzo di residenza" fullWidth validate={required()}/>    
      </Box>
      <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
        <TextInput source="citta_residenza" label="Città di residenza" fullWidth validate={required()}/>
      </Box>
    </Box>

    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
        <TextInput source="cap_residenza" label="CAP di residenza" fullWidth validate={required()}/>    
      </Box>
      <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
        <TextInput source="provincia_residenza" label="Provincia di residenza" fullWidth validate={required()}/>
      </Box>
    </Box>

    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
      <SelectInput sx={{textAlign: "left"}}  source="conferma_presa_atto" validate={required()} options={{fullWidth:true}} fullWidth label="Liberatoria privacy"
          choices={[{ id: 'Si', name: 'Si' },{ id: 'No', name: 'No' } ]}/>           
      </Box>      
      <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
          <DateInput source="data_pagamento_assicurazione" label="Data di pagamento dell'assicurazione" locales="it-IT" fullWidth />          
      </Box>
    </Box>
    
    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
      <SelectInput sx={{textAlign: "left"}}  source="certificato_vaccinazione"  options={{fullWidth:true}} fullWidth label="Certificato vaccinazione"
          choices={[{ id: 'Si', name: 'Si' },{ id: 'No', name: 'No' } ]}/>           
      </Box>      
      <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
            <DateInput source="data_scadenza_certificato_vaccinazioni" label="Data di scadenza certificato vaccinazioni" locales="it-IT" fullWidth />          
      </Box>
    </Box>
        </SimpleForm>
      </Edit>
    )
};



const transform2 = (data) => {
  const sanitizedData = {};
  for (const key in data) {   
    if(key=="provincia_nascita"){
        //console.log(data[key]);
        if(data[key]==""||data[key]==undefined||data[key]==null )
          sanitizedData[key] = "EE";    
        else   
          sanitizedData[key] = data[key]; 
    }else {
        if(key=="codice_fiscale"){
          sanitizedData[key] = data[key].toUpperCase(); 
        }
        else{
          sanitizedData[key] = data[key]; 
        }
    }
  }
  sanitizedData["timestamp_submit"]=getTime();
  //sanitizedData["conferma_presa_atto"]="Confermo la presa visione";    
  return sanitizedData; 
};

function normalizeText(text) {
  const mapping = {
      'à': "a'", 'è': "e'", 'ì': "i'", 'ò': "o'", 'ù': "u'",
      'À': "A'", 'È': "E'", 'Ì': "I'", 'Ò': "O'", 'Ù': "U'"
  };

  return text.replace(/[àèìòùÀÈÌÒÙ]/g, match => mapping[match]).toUpperCase();
}
  
  
  


export const BambinoAdminCreate =  ({ onSuccess, ...props }) => {    
  const notify = useNotify();    
   
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const [selectedStato, setSelectedStato] = useState("");
  //const [selectedLuogo, setSelectedLuogo] = useState();
  const [selectedProvince, setSelectedProvince] = useState("EE");
  const [filteredCities, setFilteredCities] = useState([]);
  
  const CFComponent = () => {   
    //const { getValues } = useFormContext();     
    const { setValue } = useFormContext(); 

    const handleProvinceChange = (e) => {    
      const provinceCode = e.target.value;
      console.log("e vale",e,selectedProvince,provinceCode);
      //if(e.target.value!= selectedProvince){
      //  console.log("DIVERSO");
        setSelectedProvince(provinceCode);                            
        const uniqueCities = [];
        //const seenIds = new Set();
        for (const [id, province, name] of COUNTRIES) {
          //if (province === provinceCode && !seenIds.has(id)) {
            if (province === provinceCode) {
            uniqueCities.push({ id, name });
            //seenIds.add(id);
          }
        }            
        setFilteredCities(uniqueCities);     
        //trigger("codice_fiscale"); 
        //trigger();
      //}
  };
  
    return (
      <>
       <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <ReferenceInput source="genitore_id" reference="genitori" label="Genitore" perPage={1000} >     
              <SelectInput option="nome" optionText={(record) => `${record.nome} ${record.cognome} - ${record.codice_fiscale}`}    options={{fullWidth:true}} sx={{textAlign: "left"}} validate={required()} fullWidth   />                
            </ReferenceInput>
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="codice_fiscale" label="Codice fiscale" fullWidth validate={[required(), validateCodiceFiscale]} />    
        </Box>
    </Box>
    
    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>    
        <TextInput source="cognome" fullWidth validate={required()}/>    
      </Box>
      <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
        <TextInput source="nome" fullWidth validate={required()}/>
      </Box>
    </Box>  

    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
        <SelectInput sx={{textAlign: "left"}}  source="sesso" label="Genere" options={{fullWidth:true}} fullWidth
        choices={[{ id: 'M', name: 'M' },{ id: 'F', name: 'F' } ]} validate={required()}/>
      </Box>
      <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
        <DateInput source="data_nascita" label="Data di nascita" locales="it-IT" fullWidth validate={[required(), validateDataNascita]}/>    
      </Box>
    </Box>
      <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <SelectInput sx={{textAlign: "left"}} source="stato_nascita"  label="Stato di nascita"  fullWidth  validate={required()} 
          optionText="name" optionValue="name"
          choices={STATES_NAMES.map(name => ({ id: name, name }))}  onChange={(e) => {setSelectedStato(e.target.value);          
            setValue("provincia_nascita", ""); setFilteredCities([]);  setValue("luogo_nascita", ""); }}
        />
     
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
          {selectedStato=='Italia' ? (
            <SelectInput sx={{textAlign: "left"}} source="provincia_nascita"  label="Provincia di nascita"  fullWidth  validate={required()} 
            optionValue="id" 
            optionText="name"   
            choices={Object.entries(PROVINCES).map(([id, name]) => ({ id, name }))}                        
            onChange={handleProvinceChange}            
          />       
          ):(
            
          <TextInput source="luogo_nascita" label="Luogo di nascita" fullWidth validate={required()}/>
          )}
        </Box>
      </Box>

      <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          {selectedStato=='Italia' && selectedProvince != '' && selectedProvince != undefined && selectedProvince != null? (
          <SelectInput sx={{textAlign: "left"}} source="luogo_nascita"  label="Luogo di nascita"  fullWidth  validate={required()} 
          optionText="name" optionValue="name" 
          choices={filteredCities}           
          />
          ):(
          <></>
          )}  
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>        
        </Box>
      </Box>  

    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
        <TextInput source="indirizzo_residenza" label="Indirizzo di residenza" fullWidth validate={required()}/>    
      </Box>
      <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
        <TextInput source="citta_residenza" label="Città di residenza" fullWidth validate={required()}/>
      </Box>
    </Box>

    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
        <TextInput source="cap_residenza" label="CAP di residenza" fullWidth validate={required()}/>    
      </Box>
      <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
        <TextInput source="provincia_residenza" label="Provincia di residenza" fullWidth validate={required()}/>
      </Box>
    </Box>

    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
      <SelectInput sx={{textAlign: "left"}}  source="conferma_presa_atto" validate={required()} options={{fullWidth:true}} fullWidth label="Liberatoria privacy"
          choices={[{ id: 'Si', name: 'Si' },{ id: 'No', name: 'No' } ]}/>           
      </Box>      
      <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
          <DateInput source="data_pagamento_assicurazione" label="Data di pagamento dell'assicurazione" locales="it-IT" fullWidth />          
      </Box>
    </Box>
    
    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
      <SelectInput sx={{textAlign: "left"}}  source="certificato_vaccinazione"  options={{fullWidth:true}} fullWidth label="Certificato vaccinazione"
          choices={[{ id: 'Si', name: 'Si' },{ id: 'No', name: 'No' } ]}/>           
      </Box>      
      <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
            <DateInput source="data_scadenza_certificato_vaccinazioni" label="Data di scadenza certificato vaccinazioni" locales="it-IT" fullWidth />          
      </Box>
    </Box>
    </>    
    );
  };

  
  const validateBambinoFields = (values) => {
    console.log("VALIDATION", values);
    const errors = {};
    
    const requiredFields = [
      "cognome",
      "nome",
      "genitore_id",
      "sesso",
      "data_nascita",
      "stato_nascita",
      "luogo_nascita",
      "provincia_residenza",
      "citta_residenza",
      "indirizzo_residenza",
      "cap_residenza",
      "conferma_presa_atto",
    ];
  
    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = "Il campo è obbligatorio";
      }
    });
  
    
    if (!values.codice_fiscale) {
      errors.codice_fiscale = "Il codice fiscale è obbligatorio";
    } else {
      const cfError = validateCodiceFiscale(values.codice_fiscale);
      if (cfError) errors.codice_fiscale = cfError;
    }
  
    
    if (values.data_nascita) {
      const dnError = validateDataNascita(values.data_nascita);
      if (dnError) errors.data_nascita = dnError;
    }
  
    
    if (
      values.nome &&
      values.cognome &&
      values.sesso &&
      values.data_nascita &&
      values.codice_fiscale &&
      values.stato_nascita &&
      values.luogo_nascita
    ) {
      const parsedDate = moment(values.data_nascita, "YYYY-MM-DD");
      let cfBuilder;
  
      if (values.stato_nascita === "Italia") {
        if (!values.provincia_nascita) {
          errors.codice_fiscale = "Necessario selezionare la provincia";
        } else {
          cfBuilder = new CodiceFiscale({
            name: values.nome,
            surname: values.cognome,
            gender: values.sesso,
            day: parsedDate.format("DD"),
            month: parsedDate.format("MM"),
            year: parsedDate.format("YYYY"),
            birthplace: values.luogo_nascita,
            birthplaceProvincia: values.provincia_nascita,
          });
          console.log("CFBUILDER ITA:", cfBuilder.code);
        }
      } else {
        cfBuilder = new CodiceFiscale({
          name: values.nome,
          surname: values.cognome,
          gender: values.sesso,
          day: parsedDate.format("DD"),
          month: parsedDate.format("MM"),
          year: parsedDate.format("YYYY"),
          //birthplace: values.stato_nascita.toUpperCase(),
          birthplace: normalizeText(values.stato_nascita.toUpperCase()),
        });
        console.log("CFBUILDER EST:", cfBuilder.code);
      }
  
      if (cfBuilder?.code) {
        console.log("EXP CF", cfBuilder.code);
        if (
          cfBuilder.code !== values.codice_fiscale.toUpperCase() &&
          values.codice_fiscale.toUpperCase() !== "XXXXXXXXXXXXXXXX"
        ) {
          console.log("EXP CF2", cfBuilder.code);
          errors.codice_fiscale =
            "Il codice fiscale inserito non è coerente con i dati anagrafici";
        }
      } else {
        errors.codice_fiscale = "Il campo codice fiscale è essenziale per la validazione";
      }
    } else {
      errors.codice_fiscale =
        "I campi Nome, Cognome, Genere, Data di Nascita sono essenziali per la verifica del codice fiscale";
    }
  
    return errors;
  };


  const validateCodiceFiscale = (value) => {
    //console.log("VALIDATIN CODICE FISCALE");
    return /^[a-zA-Z0-9]{16}$/.test(value) 
        ? undefined 
        : 'Il codice fiscale deve contenere esattamente 16 caratteri alfanumerici';
  };


  const validateDataNascita = (value) => {
    if (!value) return "La data di nascita è obbligatoria";
  
    const dataInserita = moment(value);
    //const unAnnoFa = moment().subtract(1, "year");
    const unAnnoFa = moment().subtract(1, "year").subtract(1, "day");
  
    return dataInserita.isAfter(unAnnoFa)
      ? "Il bambino deve avere almento 12 mesi ed 1 giorno"
      : undefined;
  };

  const handleCreate = async (values) => {
    try {
      var data2=transform2(values);
      const { data } = await dataProvider.create('bambini', { data: data2 });            
      console.log('Resource created successfully:', data, values);        
      notify("Bambino creato correttamente! ", { messageArgs: { smart_count: 1 } });
      redirect('/bambini');        
    } catch (error) {
      notify("Qualcosa è andato storto nella creazione del bambino. Riprovare più tardi");
    }
  };

  
  return(

    <Create transform={transform2}  {...props} >   
    {/* <SimpleForm validate={validateBambinoFields} sx={{ maxWidth: '100%' }}> */}
    <SimpleForm validate={validateBambinoFields} onSubmit={handleCreate}  sx={{ maxWidth: '100%' }}> 
        <Typography variant="h6" gutterBottom>
            Dati registrazione bambino
        </Typography>             
        <CFComponent/>
    </SimpleForm>
    </Create> 
  );
}
