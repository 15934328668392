//import { List, Datagrid, TextField, EmailField } from "react-admin";
import { List, SimpleList, DatagridConfigurable, TextField, EmailField, UrlField, ReferenceField, TopToolbar, SelectColumnsButton } from "react-admin";
import { Edit, Create, SimpleForm, ReferenceInput, TextInput, SelectInput, EditButton, DateField, DateInput} from "react-admin";
import { Fragment } from 'react';
import { BulkDeleteButton, BulkExportButton } from 'react-admin';
import { useNotify, useRecordContext, useRefresh, useRedirect, useDataProvider} from 'react-admin';
import { CreateButton, FilterButton, ExportButton } from "ra-ui-materialui";
import { Box, Typography } from "@mui/material";
import { required } from 'react-admin';
import { FunctionField } from "react-admin";
import { Tooltip } from "@mui/material";
import { SaveButton,Toolbar } from 'react-admin';
import { Chip }  from '@mui/material';
import { useTranslate } from 'react-admin';
import moment from "moment";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";


const arrayMezzore=[ "07:00", "07:30", '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30',
'13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30'];

const getTime=() =>{
  const now = new Date();
  const formattedDate = now.toLocaleString('it-IT', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
  }).replace(/\//g, '-').replace(',', ''); 
  const parts = formattedDate.split(' ');
  const finalDate = `${parts[0].split('-').reverse().join('-')} ${parts[1]}`;
  return finalDate;
}

const ValidCircleField =  (props)  => (
  <FunctionField            
    source="valid"
    //label={<span style={{ fontWeight: "bold" }}>Coperta da titoli</span>}
    sortable={false}
    render={(record) => (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%"}} >
        <Tooltip title={record.valid ===true ? "Coperta da titoli" : 
        record.valid ===false? "Non coperta da titoli" : "Cancellata"}>
          <span
            style={{
              display: "inline-block",
              width: "12px",
              height: "12px",
              borderRadius: "50%",            
              //backgroundColor: record.valid ? "green" : "red",
              backgroundColor: record.valid === true ? "green" 
                : record.valid === false ? "red" 
                : "lightgray"
            }}/>
        </Tooltip>
      </div>
    )}
  />
);



const PrenotazioneBulkActionButtons = () => (
  <Fragment>
  <BulkExportButton exporter={exportToXLS} maxResults= {15000}/>      
 {/*<BulkDeleteButton mutationMode="pessimistic"/>*/}
</Fragment>
);



const PrenotazioneFilters = [
  <TextInput label="Cerca" source="q" alwaysOn />,    
  <ReferenceInput source="ente_id" reference="enti" label="Ente " perPage={1000} sort={{ field: 'nome', order: 'ASC' }}>     
    <SelectInput sx={{textAlign: "left"}} option="nome" optionText="nome"/>
  </ReferenceInput>,
  <ReferenceInput source="bambino_id" reference="bambini" label="Bambino" perPage={1000} sort={{ field: 'nome', order: 'ASC' }}>
  {/*<SelectInput option="nome" optionText="nome" />*/}
  <SelectInput optionText={(record) => `${record.nome} ${record.cognome} (${record.codice_fiscale})`} />
</ReferenceInput> ,

<ReferenceInput source="stato_prenotazione_id" reference="stati_prenotazioni" label="Stato prenotazione" perPage={1000} sort={{ field: 'stato', order: 'ASC' }}>
  <SelectInput option="stato" optionText="stato" />
</ReferenceInput> ,

<ReferenceInput source="iscrizione_id" reference="iscrizioni" label="Iscrizione" perPage={1000} sort={{ field: 'id', order: 'ASC' }}>
  <SelectInput option="id" optionText="id" />
</ReferenceInput> ,

<DateInput label="Data prenotazione" source="data_attivita" />,


<SelectInput 
  label="Coperto da Titoli" 
  source="valid" 
  choices={[    
    { id: true, name: 'Coperto da titoli' },
    { id: false, name: 'Non Coperto da titoli' },
  ]} />

];




const PrenotazioneActions = (props) => {
  const {
    className,
    basePath,    
    resource,
    currentSort,
    filterValues,
    exporter,
  } = props;
  return (
    <TopToolbar className={className}>
      <SelectColumnsButton />                  
      <CreateButton basePath={basePath} />
      <FilterButton/>       
      <ExportButton         
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
        maxResults= {15000}        
      />    
    </TopToolbar>
  );
};

/*
const exportToXLS = (records, fetchRelatedRecords) => {  
  const worksheet = XLSX.utils.json_to_sheet(records);    

  const colWidths = Object.keys(records[0] || {}).map(key => ({
    wch: Math.max(
        key.length, 
        ...records.map(record => (record[key] ? record[key].toString().length : 0)) 
      )
  }));
  worksheet["!cols"] = colWidths;

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Dati");  
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });    
  const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  saveAs(data, "prenotazioni.xlsx");
};*/










const exportToXLS = async (records, fetchRelatedRecords) => {
  if (!records || records.length === 0) return;
  
  const bambiniMap = await fetchRelatedRecords(records, "bambino_id", "bambini");
  const entiMap = await fetchRelatedRecords(records, "ente_id", "enti");
  const statoMap = await fetchRelatedRecords(records, "stato_prenotazione_id", "stati_prenotazioni");


  const exportedRecords = records.map(record => ({
    "Identificativo": record.id,    
    "Data attività": record.data_attivita ? moment(record.data_attivita).format("DD/MM/YYYY") : "",
    Ente: entiMap[record.ente_id]
    ? `${entiMap[record.ente_id].nome}`
    : "N/A",
    Bambino: bambiniMap[record.bambino_id]
      ? `${bambiniMap[record.bambino_id].nome} ${bambiniMap[record.bambino_id].cognome} - ${bambiniMap[record.bambino_id].codice_fiscale}`
      : "N/A",
    "Stato prenotazione":  statoMap[record.stato_prenotazione_id]
    ? `${statoMap[record.stato_prenotazione_id].stato} `
    : "N/A",
    "Ora inizio prenotazione": record.ora_inizio_attivita,
    "Ora fine prenotazione": record.ora_fine_attivita,
    "Ora ingresso": record.ora_inizio_reale,
    "Ora uscita": record.ora_fine_reale,
    "Coperta da titoli": record.valid,
    "Note": record.note,
    "Identificativo Iscrizione": record.iscrizione_id,
    "Data di inserimento": record.timestamp_inserimento,    
    "Assicurazione pagata": bambiniMap[record.bambino_id] &&
    bambiniMap[record.bambino_id].data_pagamento_assicurazione
    ? moment(bambiniMap[record.bambino_id].data_pagamento_assicurazione).isAfter(moment().subtract(1, "year"))
      ? moment(bambiniMap[record.bambino_id].data_pagamento_assicurazione).format("DD/MM/YYYY")
      : "No"
    : "No",    
  }));
  const worksheet = XLSX.utils.json_to_sheet(exportedRecords);
  const colWidths = Object.keys(exportedRecords[0] || {}).map(key => ({
    wch: Math.max(
      key.length, 
      ...exportedRecords.map(record => (record[key] ? record[key].toString().length : 0))
    )
  }));
  worksheet["!cols"] = colWidths;
  
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Dati");  
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  saveAs(data, "prenotazioni.xlsx");
};

const AssicurazioneField = () => {
  const record = useRecordContext(); 
  //console.log("ASSICURAZIONE",record);
  if (!record?.data_pagamento_assicurazione) return <Typography>No</Typography>;

  const dataAssicurazione = moment(record.data_pagamento_assicurazione);
  const unAnnoFa = moment().subtract(1, "year");
  //console.log("ASSICURAZIONE",dataAssicurazione, unAnnoFa);

  return <Typography>{dataAssicurazione.isAfter(unAnnoFa) ? dataAssicurazione.format("DD/MM/YYYY")  : "No"}</Typography>;
};



export const PrenotazioneList = (props) => (
  <List sort={{ field: 'data_attivita', order: 'ASC' }} filters={PrenotazioneFilters} perPage={50} actions={<PrenotazioneActions exporter={exportToXLS}/>}>
    {/*<Datagrid rowClick="edit">  */}
    <DatagridConfigurable bulkActionButtons={<PrenotazioneBulkActionButtons />}> 
      <TextField source="id" />       
      <ReferenceField source="ente_id" reference="enti" label="Ente">
        <TextField source="nome" />
      </ReferenceField>      
      <ReferenceField source="bambino_id" reference="bambini" label="Bambino">
      <TextField source="nome" /> <TextField source="cognome" /> - <TextField source="codice_fiscale" />
      </ReferenceField>
      <DateField source="data_attivita" label="Data prenotazione" locales="it-IT" showTime={false}/>            
      <TextField source="ora_inizio_attivita" label="Ora inizio attività"/>
      <TextField source="ora_fine_attivita" label="Ora fine attività"/>
            
      <TextField source="ora_inizio_reale" label="Ora Ingresso"/>
      <TextField source="ora_fine_reale" label="Ora Uscita"/>

      <ReferenceField source="stato_prenotazione_id" reference="stati_prenotazioni" label="Stao prenotazione">
        <TextField source="stato" />
      </ReferenceField>               
      <ReferenceField source="iscrizione_id" reference="iscrizioni" label="Iscrizione">
        <TextField source="id" />
      </ReferenceField>               
      <TextField source="note" />
      
      <ValidCircleField label="Coperta da titoli"/>
      <ReferenceField source="bambino_id" reference="bambini" label="Assicurazione pagata">
        <AssicurazioneField />
      </ReferenceField>
      {/**<TextField source="valid" label="Coperta da titoli" sortable={false}/> */}
            
      <EditButton/> 
    </DatagridConfigurable>
  </List>
);



const CustomToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);


export const PrenotazioneEdit = () => {
  const notify = useNotify();  
  const redirect = useRedirect();
  const dataProvider = useDataProvider();

  const transform = (data) => {
    const sanitizedData = {};
    for (const key in data) {
        sanitizedData[key] = data[key]; 
    }
    sanitizedData["timestamp_inserimento"]=getTime();    
    return sanitizedData; 
};

 
  const handleEdit = async (values) => {
    try {
      var data2=transform(values);
      console.log("Editing",data2,values);
      const { data } = await dataProvider.update('prenotazioni', { id:data2.id, data: data2 });          
      console.log('Resource updated successfully:', data, values);          
      notify("Dati aggiornati con successo!", { messageArgs: { smart_count: 1 } });
      redirect('/prenotazioni');                  
    } catch (error) {
      // Handle error
    }
  };


  const validateDurationFields = (values) => {
    const errors = {};
    console.log("VALID",values);

    if(values.ente_id==""||values.ente_id==null)
          errors.ente_id="Il campo è obbligatorio";
    if(values.data_attivita==""||values.data_attivita==null)
          errors.data_attivita="Il campo è obbligatorio";
    if(values.bambino_id==""||values.bambino_id==null)
          errors.bambino_id="Il campo è obbligatorio";
    if(values.ora_inizio_attivita==""||values.ora_inizio_attivita==null)
          errors.ora_inizio_attivita="Il campo è obbligatorio";
    if(values.ora_fine_attivita==""||values.ora_fine_attivita==null)
          errors.ora_fine_attivita="Il campo è obbligatorio";

    /*if(values.ora_inizio_reale=="")
            errors.ora_inizio_reale="Il campo è obbligatorio";
    if(values.ora_fine_reale=="")
            errors.ora_fine_reale="Il campo è obbligatorio";*/
    if(values.ora_inizio_attivita!=""&&  values.ora_fine_attivita!=""&&values.ora_inizio_attivita!=null &&  values.ora_fine_attivita!=null){
      if (moment(values.ora_fine_attivita, "HH:mm").isSameOrBefore(moment(values.ora_inizio_attivita, "HH:mm"))) {
        errors.ora_inizio_attivita = "L'orario di fine deve essere successivo a quello di inizio";
        errors.ora_fine_attivita = "L'orario di fine deve essere successivo a quello di inizio";
      }
    }
    if(values.ora_inizio_reale!=""&&  values.ora_fine_reale!=""&&values.ora_inizio_reale!=null &&  values.ora_fine_reale!=null){
      if (moment(values.ora_fine_reale, "HH:mm").isSameOrBefore(moment(values.ora_inizio_reale, "HH:mm"))) {
        errors.ora_inizio_reale = "L'orario di fine deve essere successivo a quello di inizio";
        errors.ora_fine_reale = "L'orario di fine deve essere successivo a quello di inizio";
      }
    }
      
    
    return errors;  
  };



  return(
  <Edit title={<username />} mutationMode="pessimistic" transform={transform}>     
      <SimpleForm toolbar={<CustomToolbar />} onSubmit={handleEdit} validate={validateDurationFields} sx={{ maxWidth: '100%' }}>  
      <Typography variant="h6" gutterBottom>
        Prenotazione
    </Typography>       
    
    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <ReferenceInput source="ente_id" reference="enti" label="Ente" perPage={1000} >     
                <SelectInput sx={{textAlign: "left"}} option="nome" optionText="nome"  options={{fullWidth:true}} fullWidth validate={required()} disabled={true}/>
            </ReferenceInput>
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
            <ReferenceInput source="bambino_id" reference="bambini" label="Bambino" perPage={1000} >                     
                <SelectInput option="nome" optionText={(record) => `${record.nome} ${record.cognome} - ${record.codice_fiscale}`}    options={{fullWidth:true}} sx={{textAlign: "left"}} fullWidth  disabled={true} validate={required()}/>
            </ReferenceInput>
        </Box>
    </Box>

    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <DateInput source="data_attivita" label="Data prenotazione" locales="it-IT" fullWidth validate={required()}/>    
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
        <ReferenceInput source="stato_prenotazione_id" reference="stati_prenotazioni" label="Stato Prenotazione" perPage={1000} >     
                <SelectInput sx={{textAlign: "left"}} option="stato" optionText="stato"  options={{fullWidth:true}} fullWidth validate={required()}/>
            </ReferenceInput>
        </Box>
    </Box>

    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput source="ora_inizio_attivita" label="Ora Inizio Attiivtà" validate={required()} fullWidth placeholder="hh:mm"
            onChange={(event) => {
                event.target.value = formatTime(event.target.value);
              }}/>
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
        <TextInput source="ora_fine_attivita" label="Ora Fine Attiivtà" validate={required()} fullWidth placeholder="hh:mm"
            onChange={(event) => {
                event.target.value = formatTime(event.target.value);
              }}/>
        </Box>
    </Box>
    
    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput source="ora_inizio_reale" label="Ora Ingresso"  fullWidth placeholder="hh:mm"
            onChange={(event) => {
                event.target.value = formatTime(event.target.value);
              }}/>
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
        <TextInput source="ora_fine_reale" label="Ora Uscita"  fullWidth placeholder="hh:mm"
            onChange={(event) => {
                event.target.value = formatTime(event.target.value);
              }}/>
        </Box>
    </Box>

    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>       
        <TextInput source="note" fullWidth />        
    </Box>         
    </SimpleForm>
  </Edit> );
};




const transform2 = (data) => {
  const sanitizedData = {};
  for (const key in data) {   
      sanitizedData[key] = data[key]; 
  }
  sanitizedData["timestamp_inserimento"]=getTime();    
  //sanitizedData["conferma_presa_atto"]="Confermo la presa visione";    
  return sanitizedData; 
};


const transform = (data) => {
  const sanitizedData = {};
  for (const key in data) {   
      sanitizedData[key] = data[key]; 
  }
  sanitizedData["timestamp_inserimento"]=getTime();    
  sanitizedData["iscrizione_id"]=0;    
  //sanitizedData["conferma_presa_atto"]="Confermo la presa visione";    
  return sanitizedData; 
};

const formatTime = (input) => {
  const digits = input.replace(/\D/g, "");
  if (digits.length <= 2) return digits;
  return `${digits.slice(0, 2)}:${digits.slice(2, 4)}`;
};



export const PrenotazioneCreate =  ({ onSuccess, ...props }) => {    
  const notify = useNotify();    
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  
 
  const validateDurationFields = (values) => {
    const errors = {};
    console.log("VALID",values);

    if(values.ente_id==""||values.ente_id==null)
          errors.ente_id="Il campo è obbligatorio";
    if(values.data_attivita==""||values.data_attivita==null)
          errors.data_attivita="Il campo è obbligatorio";
    if(values.bambino_id==""||values.bambino_id==null)
          errors.bambino_id="Il campo è obbligatorio";
    if(values.ora_inizio_attivita==""||values.ora_inizio_attivita==null)
          errors.ora_inizio_attivita="Il campo è obbligatorio";
    if(values.ora_fine_attivita==""||values.ora_fine_attivita==null)
          errors.ora_fine_attivita="Il campo è obbligatorio";

    /*if(values.ora_inizio_reale=="")
            errors.ora_inizio_reale="Il campo è obbligatorio";
    if(values.ora_fine_reale=="")
            errors.ora_fine_reale="Il campo è obbligatorio";*/
    if(values.ora_inizio_attivita!=""&&  values.ora_fine_attivita!=""&&values.ora_inizio_attivita!=null &&  values.ora_fine_attivita!=null){
      if (moment(values.ora_fine_attivita, "HH:mm").isSameOrBefore(moment(values.ora_inizio_attivita, "HH:mm"))) {
        errors.ora_inizio_attivita = "L'orario di fine deve essere successivo a quello di inizio";
        errors.ora_fine_attivita = "L'orario di fine deve essere successivo a quello di inizio";
      }
    }
    if(values.ora_inizio_reale!=""&&  values.ora_fine_reale!=""&&values.ora_inizio_reale!=null &&  values.ora_fine_reale!=null){
      if (moment(values.ora_fine_reale, "HH:mm").isSameOrBefore(moment(values.ora_inizio_reale, "HH:mm"))) {
        errors.ora_inizio_reale = "L'orario di fine deve essere successivo a quello di inizio";
        errors.ora_fine_reale = "L'orario di fine deve essere successivo a quello di inizio";
      }
    }
      
    
    return errors;  
  };
  
  const handleCreate = async (values) => {
    try {
      var data2=transform(values);
      const { data } = await dataProvider.create('prenotazioni', { data: data2 });            
      console.log('Resource created successfully:', data, values);      
      notify("Prenotazione creata correttamente! ", { messageArgs: { smart_count: 1 } });
      redirect('/prenotazioni');        
    } catch (error) {
      notify("Qualcosa è andato storto nella creazione della prenotazione. Riprovare più tardi");
    }
  };
  return(

    <Create transform={transform2}  {...props} >       
    <SimpleForm validate={validateDurationFields} onSubmit={handleCreate}  sx={{ maxWidth: '100%' }}> 
   
    <Typography variant="h6" gutterBottom>
        Prenotazione
    </Typography>       
    
    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <ReferenceInput source="ente_id" reference="enti" label="Ente" perPage={1000} >     
                <SelectInput sx={{textAlign: "left"}} option="nome" optionText="nome"  options={{fullWidth:true}} fullWidth validate={required()}/>
            </ReferenceInput>
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
            <ReferenceInput source="bambino_id" reference="bambini" label="Bambino" perPage={1000} >                     
                <SelectInput option="nome" optionText={(record) => `${record.nome} ${record.cognome} - ${record.codice_fiscale}`}    options={{fullWidth:true}} sx={{textAlign: "left"}} fullWidth  validate={required()}/>
            </ReferenceInput>
        </Box>
    </Box>

    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <DateInput source="data_attivita" label="Data prenotazione" locales="it-IT" fullWidth validate={required()}/>    
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
            <ReferenceInput source="stato_prenotazione_id" reference="stati_prenotazioni" label="Stato Prenotazione" perPage={1000} >     
                <SelectInput disabled={true} defaultValue={1} sx={{textAlign: "left"}} option="stato" optionText="stato"  options={{fullWidth:true}} fullWidth validate={required()}/>
            </ReferenceInput>
        </Box>
    </Box>

    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>                                    
          <SelectInput sx={{textAlign: "left"}}  source="ora_inizio_attivita" label="Ora Inizio Attività" options={{fullWidth:true}} fullWidth validate={required()}
                  choices={[                    
                  {id:"07:00", name:"07:00"},
                  {id:"07:30", name:"07:30"},
                  {id:"08:00", name:"08:00"},
                  {id:"08:30", name:"08:30"},
                  {id:"09:00", name:"09:00"},
                  {id:"09:30", name:"09:30"},
                  {id:"10:00", name:"10:00"},
                  {id:"10:30", name:"10:30"},
                  {id:"11:00", name:"11:00"},
                  {id:"11:30", name:"11:30"},
                  {id:"12:00", name:"12:00"},
                  {id:"12:30", name:"12:30"},
                  {id:"13:00", name:"13:00"},
                  {id:"13:30", name:"13:30"},
                  {id:"14:00", name:"14:00"},
                  {id:"14:30", name:"14:30"},
                  {id:"15:00", name:"15:00"},
                  {id:"15:30", name:"15:30"},
                  {id:"16:00", name:"16:00"},
                  {id:"16:30", name:"16:30"},
                  {id:"17:00", name:"17:00"},
                  {id:"17:30", name:"17:30"},
                  {id:"18:00", name:"18:00"},
                  {id:"18:30", name:"18:30"},
                  {id:"19:00", name:"19:00"},                 
                  {id:"19:30", name:"19:30"}]} 
                  onChange={(event) => {event.target.value = formatTime(event.target.value); }}/>                      
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
        <SelectInput sx={{textAlign: "left"}}  source="ora_fine_attivita" label="Ora Fine Attività" options={{fullWidth:true}} fullWidth validate={required()}
                  choices={[                    
                  {id:"07:00", name:"07:00"},
                  {id:"07:30", name:"07:30"},
                  {id:"08:00", name:"08:00"},
                  {id:"08:30", name:"08:30"},
                  {id:"09:00", name:"09:00"},
                  {id:"09:30", name:"09:30"},
                  {id:"10:00", name:"10:00"},
                  {id:"10:30", name:"10:30"},
                  {id:"11:00", name:"11:00"},
                  {id:"11:30", name:"11:30"},
                  {id:"12:00", name:"12:00"},
                  {id:"12:30", name:"12:30"},
                  {id:"13:00", name:"13:00"},
                  {id:"13:30", name:"13:30"},
                  {id:"14:00", name:"14:00"},
                  {id:"14:30", name:"14:30"},
                  {id:"15:00", name:"15:00"},
                  {id:"15:30", name:"15:30"},
                  {id:"16:00", name:"16:00"},
                  {id:"16:30", name:"16:30"},
                  {id:"17:00", name:"17:00"},
                  {id:"17:30", name:"17:30"},
                  {id:"18:00", name:"18:00"},
                  {id:"18:30", name:"18:30"},
                  {id:"19:00", name:"19:00"},                 
                  {id:"19:30", name:"19:30"}]} 
                  onChange={(event) => {event.target.value = formatTime(event.target.value); }}/>         
        </Box>
    </Box>

    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
      <TextInput source="note" fullWidth />          
    </Box>


    
    </SimpleForm>
    </Create> 
  );
}