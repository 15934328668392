import React, { useState, useEffect } from "react";
import Scheduler, { SchedulerData, ViewTypes, SummaryPos, DATE_FORMAT } from "react-big-scheduler";
import moment from "moment";
import "moment/locale/it";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import Button from '@mui/material/Button';
import withDragDropContext from "./withDnDContext";
import { useNotify, useDataProvider, ConfirmClasses } from 'react-admin';
import "react-big-scheduler/lib/css/style.css";
import { useRefresh } from 'react-admin';
import {Typography} from '@mui/material';
import { useEditContext,useGetOne,useGetMany,useGetManyReference } from 'react-admin';
import {ThreeDots} from 'react-loader-spinner';

//const DashboardMonthlyView = () => {
const DashboardMonthlyView = ({ enteId }) => {
  const [loading, setLoading]= useState(true);  
  //const [enteId, setEnteId] = useState(48);          
  
  const [dataAttivita, setDataAttivita] = useState(moment().startOf('month').format("YYYY-MM-DD"));
  
  let totale;
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [isPopoverVisible, setIsPopoverVisible] = useState(true);  

  //SLOTS tutti
  const { data: slots, isPending2, error2 } = useGetManyReference(
      'slots',
      { 
          target: 'ente_id',
          id: enteId,          
          pagination: { page: 1, perPage: 1000 },
          sort: { field: 'giorno', order: 'DESC' }
      }
  );
  
  

  const { data: prenotazioni, isPending, error } = useGetManyReference(
    'prenotazioni',
    { 
        target: 'ente_id',
        id: enteId,
        filter: {            
            
            data_attivita_gte: dataAttivita, 
            //data_attivita_lte: moment(dataAttivita).add(6, 'days').format('YYYY-MM-DD'),               
            data_attivita_lte: moment(dataAttivita).endOf('month').format('YYYY-MM-DD'),               
            stato_prenotazione_id_neq: 3                     
          },
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'bambino_id', order: 'DESC' }
    }
  );

  const getSummary = (schedulerData, headerEvents, slotId, slotName, headerStart, headerEnd) => {   
  }

  const createSchedulerData = (events = [], resources = []) => {    
    const scheduler = new SchedulerData(
      dataAttivita,  
      ViewTypes.Day,
      false,
      false,
      {
        dayStartFrom: 7,
        dayStopTo: 19,
        isEventPerspective: true,
        views: [],
        nonAgendaOtherCellHeaderFormat: "ddd D/M",
        resourceName: "Disponibilità",
        schedulerWidth: "90%",        
        resourceTableWidth:350,
        //dayResourceTableWidth:350,        
        //agendaResourceTableWidth:350,
        //dayCellWidth:350,
        //agendaMaxEventWidth:350,        
        startResizable: false,
        endResizable: false,
        movable: false,
        creatable: false,
        nonWorkingTimeHeadBgColor:"white",
        nonWorkingTimeBodyBgColor:"white",
        nonWorkingTimeHeadColor:"black",    
        dayResourceTableWidth:400,   
        schedulerWidth:"100",             
      }
    );
    
    scheduler.setResources(resources); 
    scheduler.setEvents(events);        
    scheduler.config.summaryPos = SummaryPos.Top;
    scheduler.behaviors.getSummaryFunc=getSummary;   
    
    scheduler.behaviors.getEventItemBgColor = (schedulerData, event) => {
    //console.log("CALLING ADDD",schedulerData, event);
    const slotName = schedulerData.getSlotById(event.resourceId)?.name || "";
      
    return slotName.toLowerCase().includes("sabato") ? "red" : undefined;
    };
    
    return scheduler;
};
 
 const [schedulerData, setSchedulerData] = useState(createSchedulerData([], []));
  


const generateTimeSlots = (startDate) => {
  const timeslots = [];
  let id = 0; 
  const startHour = 7;
  const endHour = 20;
  const intervalMinutes = 30;  
  const days = moment(dataAttivita).daysInMonth(); 

  for (let day = 0; day < days; day++) {
    const realDate = moment(startDate).add(day, "days").format("YYYY-MM-DD");
    const currentDate = moment(startDate).format("YYYY-MM-DD");

    for (let hour = startHour; hour < endHour; hour++) {
      for (let minute of [0, intervalMinutes]) {
        const start = moment(`${currentDate} ${hour}:${minute}:00`, "YYYY-MM-DD HH:mm:ss");
        const end = start.clone().add(intervalMinutes, "minutes");
        const real_start=moment(`${realDate} ${hour}:${minute}:00`, "YYYY-MM-DD HH:mm:ss");
        const real_end=real_start.clone().add(intervalMinutes, "minutes");
        timeslots.push({
          id: parseInt(id++)%26,
          resourceId: day,
          start: start.format("YYYY-MM-DD HH:mm:ss"),
          end: end.format("YYYY-MM-DD HH:mm:ss"),
          title: "",
          bgColor: "grey",
          label:  moment(startDate).add(day, "days").format("dddd") + " "+moment(startDate).add(day, "days").format("DD/MM/YYYY"),
          val:-1,
          val_pre:-1,
          val_iscr:-1,
          real_start:real_start.format("YYYY-MM-DD HH:mm:ss"),          
          real_end:real_end.format("YYYY-MM-DD HH:mm:ss"),
        });
      }
    }
  }

  return timeslots;
};

const updateTimeSlots = (timeslots, activities) => {
  const slotCounts = {};
  const slotCountsPre = {};
  const slotCountsIscr = {};

  activities.forEach(activity => {
    const { data_attivita, ora_inizio_attivita, ora_fine_attivita } = activity;
    let start = moment(`${data_attivita} ${ora_inizio_attivita}`, "YYYY-MM-DD HH:mm");
    let end = moment(`${data_attivita} ${ora_fine_attivita}`, "YYYY-MM-DD HH:mm");    
    while (start.isBefore(end)) {
      const key = start.format("YYYY-MM-DD HH:mm");

      ////
      if(activity.stato_prenotazione_id==5){
        if (!slotCountsPre[key]) {
          slotCountsPre[key] = 0;
        }
        slotCountsPre[key]++;
      }
      //if(activity.stato_prenotazione_id==1){
        if(activity.stato_prenotazione_id==1||activity.stato_prenotazione_id==2||activity.stato_prenotazione_id==6){
        if (!slotCountsIscr[key]) {
          slotCountsIscr[key] = 0;
        }
        slotCountsIscr[key]++;   
      }      
      if(activity.stato_prenotazione_id==1 || activity.stato_prenotazione_id==5||activity.stato_prenotazione_id==2||activity.stato_prenotazione_id==6){
      if (!slotCounts[key]) {
        slotCounts[key] = 0;
      }
      slotCounts[key]++;   
      }                        
      start.add(30, "minutes");
    }
  });  
  
  return timeslots.map(slot => {
    const key = moment(slot.real_start).format("YYYY-MM-DD HH:mm");
    return {
      ...slot,
      val: slotCounts[key] || -1 ,
      val_iscr:slotCountsIscr[key] || -1,
      val_pre:slotCountsPre[key] || -1,              
      title: slotCounts[key] === -1 || slotCounts[key] === undefined ? "" : slotCounts[key].toString(),
    };
  });
};



function updateTimeSlotsWithSlots(firstArray, secondArray) {  
  const getDayOfWeek = (date) => {
    const daysOfWeek = ["domenica", "lunedì", "martedì", "mercoledì", "giovedì", "venerdì", "sabato"];
    const day = new Date(date).getDay();
    return daysOfWeek[day];
  };
  
  const firstArrayMap = new Map();

  firstArray.forEach(item => {
    firstArrayMap.set(item.giorno, item);
  });
  
  return secondArray.map(item => {
    const dayOfWeek = getDayOfWeek(item.real_start);
    const matchingItem = firstArrayMap.get(dayOfWeek);    
    if (matchingItem) {
      //console.log("MI",dayOfWeek,matchingItem,item);      
      const time = item.real_start.substring(11, 16).replace(":", "");      
      if (matchingItem[time] > 0) {      
        if (item.val === -1) {
          return { ...item, val: 0, val_pre:0, val_iscr:0 };
        }
      }
    }          
    return item;
  });
};


  useEffect(() => {
    setLoading(true);
    moment.locale("it");
    console.log("DATA",dataAttivita);
    console.log("PRENO",prenotazioni);    
    console.log("SLT",slots);    
                   
    if (prenotazioni && slots) {
      const res=[];
      let daysInMonth = moment(dataAttivita).daysInMonth();
      for (let i = 0; i < daysInMonth; i++) {
        let currentDate = moment(dataAttivita).add(i, "days");
        const day=currentDate.format("dddd")
        res.push({
          id: i,
          name: day.charAt(0).toUpperCase() + day.slice(1) + " " + currentDate.format("DD/MM/YYYY"),
        });
      }
      const pren = slots.map(slot => ({          
        id: slot.id,
        name: slot.giorno
    }));

    const ev=generateTimeSlots(dataAttivita);
    const ev2=updateTimeSlots(ev,prenotazioni);
    const ev3=updateTimeSlotsWithSlots(slots,ev2);
    //console.log("EV2",ev2);
    //console.log("EV3",ev3);
    totale=ev3;
       
    const newSchedulerData = createSchedulerData(ev3,res);
    setSchedulerData(newSchedulerData); 
      }
    const timer = setTimeout(() => {
        setLoading(false);
      }, 500); 
  }, [prenotazioni, slots, dataAttivita]);
  


const prevClick = () => {    
    setLoading(true); 

    const newDate = moment(dataAttivita).subtract(1, 'month').startOf('month').format("YYYY-MM-DD");    
    setDataAttivita(newDate);
};

const nextClick = () => {  
    setLoading(true); 
    const newDate = moment(dataAttivita).add(1, 'month').startOf('month').format("YYYY-MM-DD");    
    setDataAttivita(newDate);
};


const onSelectDate = (schedulerData, date) => {  
  setLoading(true); 
  const newDate = moment(date).startOf('month').format("YYYY-MM-DD")
  console.log(newDate);
  setDataAttivita(newDate);  
};


const eventItemPopoverTemplateResolver = (schedulerData,eventItem,title,start,end,statusColor) => {
  setIsPopoverVisible(true); 
  title=eventItem.label;

  if (!isPopoverVisible) {
    return null;  
  }
  return (    
    //console.log("OVER",eventItem),
    <div style={{ width: "300px" }}>
      <Row type="flex" align="middle">
        <Col span={2}>
          <div
            className="status-dot"
            style={{ backgroundColor: statusColor }}
          />
        </Col>
        <Col span={22} className="overflow-text">
          <span className="header2-text" title={title}>
            {title}
          </span>
        </Col>
      </Row>
      <Row type="flex" align="middle">
        <Col span={2}>
          <div />
        </Col>
        <Col span={22}>
          <span className="header1-text">
            {start.format("HH:mm")} - {end.format("HH:mm")}
          </span>
        </Col>
      </Row>
      <Row type="flex" align="middle">
        <Col span={2}>
          <div />
        </Col>
        <Col span={22}>
        <div style={{ display:'flex', flexDirection: 'column', marginTop:'10px', marginBottom:'20px', width:'100%', gap: '10px'}}>
          <span className="header2-text">
              Iscrizioni: {eventItem.val_iscr  === -1 ? 0 : eventItem.val_iscr}     
          </span>
          <span className="header2-text">
              Pre-Iscrizioni: {eventItem.val_pre === -1 ? 0 : eventItem.val_pre}               
          </span>
         
          <span className="header2-text">
              Totale: {eventItem.val === -1 ? 0 : eventItem.val}                   
          </span>
          </div>
              
        </Col>
      </Row>
     
    </div>
  );
};

const eventItemTemplateResolver = (schedulerData, event, bgColor, isStart, isEnd, mustAddCssClass, mustBeHeight) => {
  
  const giorno = moment(event.start).format("dddd").toLowerCase();     
  let backgroundColor = bgColor; // Mantiene il colore predefinito per gli altri giorni
  let pointerEvents = "auto"; // Abilitato per default  
  if (event.val === -1) {
    backgroundColor = "#FFF0F6"; 
    pointerEvents = "none"; 
  }
  if (event.val === 0) {
    backgroundColor = "#FFFFFF"; 
    pointerEvents = "none"; 
  }


  return (
    //console.log("ET",event.title),
    <div
      key={event.id}
      className={mustAddCssClass}
      style={{
        display: "flex",        
        alignContent:"center",
        alignItems: "center",         
        width: "100%", 
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "clip", 
        backgroundColor,
        color: "white",        
        margin:"0px", 
        padding:"0px",               
        pointerEvents,
        height: mustBeHeight,
        fontSize: '0.9rem'
      }}      
    > <Typography   style={{
     paddingTop:"10px",
     paddingLeft:"8px",
      alignContent:"center",
      alignItems: "center", 
      justifyContent: "center",
      textAlign: "center", 
      width: "100%", 
      
      fontSize: '0.9rem'
    }}      >{event.title}</Typography>
    </div>
  );
};



  return (

    <div>
       {loading ? (
          <div style={{ width:"25%",   display: "flex", marginLeft: "auto", marginRight: "auto" }}>
              <ThreeDots color="#2196F3" height='350' width='150'/>
          </div>
        ):(
          <Scheduler                
            schedulerData={schedulerData}
            prevClick={prevClick}
            nextClick={nextClick}
            onSelectDate={onSelectDate}        
            eventItemPopoverTemplateResolver = {eventItemPopoverTemplateResolver}    
            eventItemTemplateResolver = {eventItemTemplateResolver}                
          />)}
    </div>
  );
};

export default withDragDropContext(DashboardMonthlyView);
