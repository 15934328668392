export const properties = {
    productionUrl: "https://zerozerosei.top",
    //productionUrl: "https://eduteche.it",
    //productionUrl: "http://localhost:8080",    
    version: "0.92"
};


/*
TO DO PER RIPRISTINARE LA SITUAZIONE FUNZIONANTE:
- Togliere if in dashboard.js in modo da far sparire il fine estate per ruoli diversi da AM, CSP e ADMIN
- sostituire il file MyAdmin.js con MyAdmin_OK.js
- Ricordarsi la modifica per differenziare user-ente da abbonamento musei
validate={validateDurationFields} 
*/