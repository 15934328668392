import React, { useState, useEffect } from "react";
import Scheduler, { SchedulerData, ViewTypes, SummaryPos, DATE_FORMAT } from "react-big-scheduler";
import moment from "moment";
import "moment/locale/it";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import Button from '@mui/material/Button';
import withDragDropContext from "./withDnDContext";
import { useNotify, useDataProvider, ConfirmClasses } from 'react-admin';
import "react-big-scheduler/lib/css/style.css";
import { useRefresh } from 'react-admin';
import {Typography} from '@mui/material';
import { useEditContext,useGetOne,useGetMany,useGetManyReference } from 'react-admin';
import {ThreeDots} from 'react-loader-spinner';

const DashboardWeeklyView =  ({ enteId }) => {
  //const refresh = useRefresh();
  const [loading, setLoading]= useState(true);  
  //const [enteId, setEnteId] = useState(48);          
  
  const [dataAttivita, setDataAttivita] = useState(moment().startOf('week').format("YYYY-MM-DD"));
  
  let totale;
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [isPopoverVisible, setIsPopoverVisible] = useState(true);  

  //SLOTS tutti
  const { data: slots, isPending2, error2 } = useGetManyReference(
      'slots',
      { 
          target: 'ente_id',
          id: enteId,          
          pagination: { page: 1, perPage: 1000 },
          sort: { field: 'giorno', order: 'DESC' }
      }
  );
  
  

  const { data: prenotazioni, isPending, error } = useGetManyReference(
    'prenotazioni',
    { 
        target: 'ente_id',
        id: enteId,
        filter: {            
            //data_attivita: dataAttivita,                          
            data_attivita_gte: dataAttivita, 
            data_attivita_lte: moment(dataAttivita).add(6, 'days').format('YYYY-MM-DD'),               
            stato_prenotazione_id_neq: 3                     
          },
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'bambino_id', order: 'DESC' }
    }
  );

/*
  const bambinoIds = prenotazioni ? [...new Set(prenotazioni.map(p => p.bambino_id))] : [];
  
  const { data: bambini, isPending: isPendingBambini, error: errorBambini } = useGetMany(
    'bambini',
    { ids: bambinoIds }
  );
*/




  const getSummary = (schedulerData, headerEvents, slotId, slotName, headerStart, headerEnd) => {
    //console.log("SUM",schedulerData, headerEvents, slotId, slotName, headerStart, headerEnd);
    /*if(totale){
     
      //console.log("CALLED GS",headerStart,headerEnd,totale);
      let dd=moment(headerStart).format("HH:mm");
      console.log("CALLED GS", headerEvents, slotId, slotName, headerStart, headerEnd,totale,dd );
      //console.log("MOMENT",dd);
      //if(slotName=="Totale"){
        if (dd in totale) {
          //console.log("Valore:", totale[dd]); 
          return {text: totale[dd], color: 'black', fontSize: '0.5rem'};
        }
        else
          return {text: "0",
          color: 'red', fontSize: '0.9rem',  
          };        
    //}
}*/
  }
/*
  function hasNonZeroValue(entry, time) { 
    const key = time.replace(":", "");        
    return entry[key] && entry[key] !== "0";
}

  const isNonWorkingTime = (schedulerData,time) => {
    if(slots){
      //console.log("CALLED",time,slots);
      var t=moment(time);
      //console.log("giorno",t.format("dddd"));
      const intDay=t.format("dddd");
      const foundEntry = slots.find(entry => entry.giorno === intDay);

      if (foundEntry) {
        //console.log(`Trovata  ${intDay}:`, foundEntry);
        return !hasNonZeroValue(foundEntry,t.format("HH:mm"));
      } else {
        //console.log(`Nessuna entry trovata per ${intDay}`);
        return false;
      }    
    }
    
  };
  */
  

  const createSchedulerData = (events = [], resources = []) => {    
    const scheduler = new SchedulerData(
      dataAttivita,  
      ViewTypes.Day,
      false,
      false,
      {
        dayStartFrom: 7,
        dayStopTo: 19,
        isEventPerspective: true,
        views: [],
        nonAgendaOtherCellHeaderFormat: "ddd D/M",
        resourceName: "Disponibilità",
        schedulerWidth: "90%",
        //agendaResourceTableWidth:"450",
        //resourceTableWidth: "450", 
        dayResourceTableWidth:350,
        startResizable: false,
        endResizable: false,
        movable: false,
        creatable: false,
        nonWorkingTimeHeadBgColor:"white",
        nonWorkingTimeBodyBgColor:"white",
        nonWorkingTimeHeadColor:"black",   
        dayResourceTableWidth:400,   
        schedulerWidth:"100", 
        //getSummaryFunc: getSummary,
        
      }
    );
    
    scheduler.setResources(resources); 
    scheduler.setEvents(events);    
    //scheduler.behaviours.isNonWorkingTimeFunc = isNonWorkingTime;
    //scheduler.behaviors.isNonWorkingTimeFunc= isNonWorkingTime;
    scheduler.config.summaryPos = SummaryPos.Top;
    scheduler.behaviors.getSummaryFunc=getSummary;
    /*
    scheduler.behaviors.getNonAgendaViewRowBgColor = (schedulerData, slotId, slotName) => {
      return slotName.toLowerCase().includes("sabato") ? "red" : undefined;
    };
    scheduler.behaviors.getNonAgendaViewBodyCellBgColor = (schedulerData, slotId, slotName, header) => {
      console.log("SDESES",schedulerData, slotId, slotName, header);
      return slotName.toLowerCase().includes("sabato") ? "red" : undefined;
    };
    */
    scheduler.behaviors.getEventItemBgColor = (schedulerData, event) => {
      console.log("CALLING ADDD",schedulerData, event);
      const slotName = schedulerData.getSlotById(event.resourceId)?.name || "";
      
      // Se la riga è il sabato, imposta il colore di sfondo rosso
      return slotName.toLowerCase().includes("sabato") ? "red" : undefined;
    };

    //scheduler.eventItemPopoverTemplateResolver = eventItemPopoverTemplateResolver;
    return scheduler;
};
 
 const [schedulerData, setSchedulerData] = useState(createSchedulerData([], []));
  
/*
 const ElaborateSlotsIntoRes = (day) => {
  if(slots){
    
    var t=moment(time);    
    const intDay=t.format("dddd");
    const foundEntry = slots.find(entry => entry.giorno === day);

    if (foundEntry) {
      //console.log(`Trovata  ${intDay}:`, foundEntry);
       !hasNonZeroValue(foundEntry,t.format("HH:mm"));
    } else {
      //console.log(`Nessuna entry trovata per ${intDay}`);
      return false;
    }    
  }
  
};
*/

const generateTimeSlots = (startDate) => {
  const timeslots = [];
  let id = 0; 
  const startHour = 7;
  const endHour = 20;
  const intervalMinutes = 30;
  //const days = 6; 
  const days = 5; 

  for (let day = 0; day < days; day++) {
    const realDate = moment(startDate).add(day, "days").format("YYYY-MM-DD");
    const currentDate = moment(startDate).format("YYYY-MM-DD");

    for (let hour = startHour; hour < endHour; hour++) {
      for (let minute of [0, intervalMinutes]) {
        const start = moment(`${currentDate} ${hour}:${minute}:00`, "YYYY-MM-DD HH:mm:ss");
        const end = start.clone().add(intervalMinutes, "minutes");
        const real_start=moment(`${realDate} ${hour}:${minute}:00`, "YYYY-MM-DD HH:mm:ss");
        const real_end=real_start.clone().add(intervalMinutes, "minutes");
        timeslots.push({
          id: parseInt(id++)%26,
          resourceId: day,
          start: start.format("YYYY-MM-DD HH:mm:ss"),
          end: end.format("YYYY-MM-DD HH:mm:ss"),
          title: "",
          bgColor: "grey",
          label:  moment(startDate).add(day, "days").format("dddd") + " "+moment(startDate).add(day, "days").format("DD/MM/YYYY"),
          val:-1,
          val_pre:-1,
          val_iscr:-1,
          real_start:real_start.format("YYYY-MM-DD HH:mm:ss"),          
          real_end:real_end.format("YYYY-MM-DD HH:mm:ss"),
        });
      }
    }
  }

  return timeslots;
};

const updateTimeSlots = (timeslots, activities) => {
  const slotCounts = {};
  const slotCountsPre = {};
  const slotCountsIscr = {};

  activities.forEach(activity => {
    const { data_attivita, ora_inizio_attivita, ora_fine_attivita } = activity;
    let start = moment(`${data_attivita} ${ora_inizio_attivita}`, "YYYY-MM-DD HH:mm");
    let end = moment(`${data_attivita} ${ora_fine_attivita}`, "YYYY-MM-DD HH:mm");
    //console.log("ACT",activity);
    while (start.isBefore(end)) {
      const key = start.format("YYYY-MM-DD HH:mm");

      ////
      if(activity.stato_prenotazione_id==5){
        if (!slotCountsPre[key]) {
          slotCountsPre[key] = 0;
        }
        slotCountsPre[key]++;
      }
      if(activity.stato_prenotazione_id==1||activity.stato_prenotazione_id==2||activity.stato_prenotazione_id==6){
        if (!slotCountsIscr[key]) {
          slotCountsIscr[key] = 0;
        }
        slotCountsIscr[key]++;   
      }      
      if(activity.stato_prenotazione_id==1 || activity.stato_prenotazione_id==5||activity.stato_prenotazione_id==2||activity.stato_prenotazione_id==6){
      if (!slotCounts[key]) {
        slotCounts[key] = 0;
      }
      slotCounts[key]++;   
      }                        
      start.add(30, "minutes");
    }
  });
  //console.log("SCC",slotCounts, timeslots)
  
  return timeslots.map(slot => {
    const key = moment(slot.real_start).format("YYYY-MM-DD HH:mm");
    return {
      ...slot,
      val: slotCounts[key] || -1 ,
      val_iscr:slotCountsIscr[key] || -1,
      val_pre:slotCountsPre[key] || -1,
      //title: slotCounts[key] === -1 ? "" : slotCounts[key].toString(),            
      title: slotCounts[key] === -1 || slotCounts[key] === undefined ? "" : slotCounts[key].toString(),
    };
  });
};



function updateTimeSlotsWithSlots(firstArray, secondArray) {  
  const getDayOfWeek = (date) => {
    const daysOfWeek = ["domenica", "lunedì", "martedì", "mercoledì", "giovedì", "venerdì", "sabato"];
    const day = new Date(date).getDay();
    return daysOfWeek[day];
  };
  
  const firstArrayMap = new Map();

  firstArray.forEach(item => {
    firstArrayMap.set(item.giorno, item);
  });
  
  return secondArray.map(item => {
    const dayOfWeek = getDayOfWeek(item.real_start);
    const matchingItem = firstArrayMap.get(dayOfWeek);    
    if (matchingItem) {
      //console.log("MI",dayOfWeek,matchingItem,item);      
      const time = item.real_start.substring(11, 16).replace(":", "");      
      if (matchingItem[time] > 0) {      
        if (item.val === -1) {
          return { ...item, val: 0, val_pre:0, val_iscr:0 };
        }
      }
    }          
    return item;
  });
};


  useEffect(() => {
    moment.locale("it");
    console.log("DATA",dataAttivita);
    console.log("PRENO",prenotazioni);    
    console.log("SLT",slots);    
                   
    if (prenotazioni && slots) {
      const res=[
      {id: 0,
        name: "Lunedì "+ moment(dataAttivita).format("DD/MM/YYYY")
      },
      {id: 1,
        name: "Martedì " + moment(dataAttivita).add(1, 'days').format("DD/MM/YYYY")
      },
      {id: 2,
        name: "Mercoledì " + moment(dataAttivita).add(2, 'days').format("DD/MM/YYYY")
      },
      {id: 3,
        name: "Giovedì " + moment(dataAttivita).add(3, 'days').format("DD/MM/YYYY")
      },
      {id: 4,
        name: "Venerdì " + moment(dataAttivita).add(4, 'days').format("DD/MM/YYYY")
      },
      //{id: 5,
      //  name: "Sabato " + moment(dataAttivita).add(5, 'days').format("DD/MM/YYYY")
      //}, 
      ];

      const pren = slots.map(slot => ({          
        id: slot.id,
        name: slot.giorno
    }));

    const ev=generateTimeSlots(dataAttivita);
    const ev2=updateTimeSlots(ev,prenotazioni);
    const ev3=updateTimeSlotsWithSlots(slots,ev2);
    console.log("EV2",ev2);
    //console.log("EV3",ev3);
    totale=ev3;
    //console.log("EV",ev,res);
    /*  const prenotazioniConNomi = prenotazioni && bambini 
      ? prenotazioni.map(p => {
        const bambino = bambini.find(b => b.id === p.bambino_id);
        return {
          ...p,
          bambinoCF: bambino 
            ? `${bambino.codice_fiscale}` : "Sconosciuto",
          bambinoNome: bambino 
            ? `${bambino.nome} ${bambino.cognome} (${bambino.codice_fiscale})` 
            : "Sconosciuto"
        };
        })
      : [];
      console.log("Prenotazioni con nomi:", prenotazioniConNomi);
    */
      
    /*
      const res = prenotazioniConNomi.map(prenotazione => ({          
          id: prenotazione.bambino_id,
          name: prenotazione.bambinoNome
      }));   
      //console.log("RES",res);
      //console.log("RES22",Datas.resources);
      
      const pren = prenotazioniConNomi.map(prenotazione => ({
          id: parseInt(prenotazione.id),
          start: `${prenotazione.data_attivita} ${prenotazione.ora_inizio_attivita}:00`,
          end: `${prenotazione.data_attivita} ${prenotazione.ora_fine_attivita}:00`,
          //rrule: "FREQ=WEEKLY;DTSTART=20210621T100000Z;BYDAY=MO,TU,WE,TH,FR,SA,SU",
          resourceId: prenotazione.bambino_id,
          //title: prenotazione.bambinoNome,
          title: "",
          //bgColor: "green",                    
          name: prenotazione.bambinoNome,
          codiceFiscale: prenotazione.bambinoCF,
          inizio_reale:`${prenotazione.ora_inizio_reale}`,
          fine_reale:`${prenotazione.ora_fine_reale}`,
          bgColor: prenotazione.ora_inizio_reale === null && prenotazione.ora_fine_reale === null ? "green" :
          prenotazione.ora_inizio_reale != null && prenotazione.ora_fine_reale === null ? "orange" : "grey"
      }));    
        pren.sort((a, b) => new Date(a.start) - new Date(b.start));
        console.log("PP",pren);
        
        //0602
        const timeSlotCounts = {}; 
        const allTimeSlots = [];
        for (let hour = 7; hour <= 19; hour++) {
          const startTime = (hour < 10 ? `0${hour}` : `${hour}`) + ":00";
          allTimeSlots.push(startTime);
          allTimeSlots.push((hour < 10 ? `0${hour}` : `${hour}`) + ":30");
        }
        //console.log("test",pren, res,allTimeSlots);
        if(pren.length>0 && res.length>0){
            prenotazioniConNomi.forEach((prenotazione) => {
            
              const startTime = moment(`${prenotazione.data_attivita} ${prenotazione.ora_inizio_attivita}:00`);
              const endTime = moment(`${prenotazione.data_attivita} ${prenotazione.ora_fine_attivita}:00`);
            
              while (startTime < endTime) {
                const key = startTime.format("HH:mm");              
                if (!timeSlotCounts[key]) {
                  timeSlotCounts[key] = 0;
                }      
                timeSlotCounts[key]++;              
                startTime.add(30, 'minutes');
              }
            });
            //console.log("COUNTING",timeSlotCounts);
            totale=timeSlotCounts;
            const ultimaRiga = {
              id: "totale", 
              start: Object.keys(timeSlotCounts)[0], 
              end: Object.keys(timeSlotCounts).slice(-1)[0], 
              resourceId: "totale", 
              title: "", 
              bgColor: "lightgray", 
              name: "Totale eventi", 
              codiceFiscale: "", 
              inizio_reale: "",
              fine_reale: "",
              conteggi: timeSlotCounts, 
            };
            pren.push(ultimaRiga); 
            const rigaConTotale = { id: "totale", name: "Totale", bgColor: "#00f0f0" };
            res.push(rigaConTotale);
            //console.log("PREN",pren);
        }
        */
        //0602
        //const newSchedulerData = createSchedulerData([],res);
        const newSchedulerData = createSchedulerData(ev3,res);
        setSchedulerData(newSchedulerData); 
        const timer = setTimeout(() => {
          setLoading(false);
        }, 100); 
      }
    
  }, [prenotazioni, slots, dataAttivita]);
  

/*
const eventClicked = (schedulerData, event) => {
    alert(`Evento cliccato: ${event.title}`);
  };
*/



const prevClick = () => {
  setLoading(true); 
    //const prevMonday = new Date(schedulerData.startDate);
    //prevMonday.setDate(prevMonday.getDate() - 7);
    const newDate = moment(dataAttivita).subtract(7, 'days').startOf('week').format("YYYY-MM-DD");
    //const newDate = moment(prevMonday).format("YYYY-MM-DD");
    setDataAttivita(newDate);
};

const nextClick = () => {
  setLoading(true); 
  //const newDate = moment(dataAttivita).add(1, 'days').format("YYYY-MM-DD");
  //const nextMonday = new Date(schedulerData.startDate);  
  //nextMonday.setDate(nextMonday.getDate() + 7);
    //const newDate = moment(dataAttivita).subtract(1, 'days').format("YYYY-MM-DD");
  //  const newDate = moment(nextMonday).format("YYYY-MM-DD");
    const newDate = moment(dataAttivita).add(7, 'days').startOf('week').format("YYYY-MM-DD");
    setDataAttivita(newDate);
};


const onSelectDate = (schedulerData, date) => {  
  setLoading(true); 
  const newDate = moment(date).startOf('week').format("YYYY-MM-DD")
  console.log(newDate);
  setDataAttivita(newDate);  
};


//0602
/*const eventItemTemplateResolver = (schedulerData, event, bgColor, isStart, isEnd, mustAddCssClass, mustBeHeight) => {
  
  if (event.id !== "totale") {
    return undefined;
  }

  return (
    <div style={{ 
      fontWeight: "bold", 
      textAlign: "center", 
      backgroundColor: "lightgray", 
      borderRadius: "4px", 
      padding: "5px"
    }}>
      {event.conteggi[event.start] || 0} eventi
    </div>
  );
}; */
//0602

const eventItemPopoverTemplateResolver = (schedulerData,eventItem,title,start,end,statusColor) => {
  //return null;
  setIsPopoverVisible(true); 
  //console.log("WEI",eventItem)
  title=eventItem.label;

 
  const handleClick = function() {
    console.log("HC",eventItem);    
    setIsPopoverVisible(false); 
    
  }

  const handleClickExit = function() {
    console.log("HC",eventItem);   
    setIsPopoverVisible(false);     
  }

  if (!isPopoverVisible) {
    return null;  
  }
  return (    
    //console.log("OVER",eventItem),
    <div style={{ width: "300px" }}>
      <Row type="flex" align="middle">
        <Col span={2}>
          <div
            className="status-dot"
            style={{ backgroundColor: statusColor }}
          />
        </Col>
        <Col span={22} className="overflow-text">
          <span className="header2-text" title={title}>
            {title}
          </span>
        </Col>
      </Row>
      <Row type="flex" align="middle">
        <Col span={2}>
          <div />
        </Col>
        <Col span={22}>
          <span className="header1-text">
            {start.format("HH:mm")} - {end.format("HH:mm")}
          </span>
        </Col>
      </Row>
      <Row type="flex" align="middle">
        <Col span={2}>
          <div />
        </Col>
        <Col span={22}>
        <div style={{ display:'flex', flexDirection: 'column', marginTop:'10px', marginBottom:'20px', width:'100%', gap: '10px'}}>
          <span className="header2-text">
              Iscrizioni: {eventItem.val_iscr  === -1 ? 0 : eventItem.val_iscr}     
          </span>
          <span className="header2-text">
              Pre-Iscrizioni: {eventItem.val_pre === -1 ? 0 : eventItem.val_pre}               
          </span>
         
          <span className="header2-text">
              Totale: {eventItem.val === -1 ? 0 : eventItem.val}                   
          </span>
          </div>
              
        </Col>
      </Row>
     
    </div>
  );
};

/*
const eventItemTemplateResolver = (schedulerData, event, bgColor, isStart, isEnd, mustAddCssClass, mustBeHeight) => {
  //console.log("EITR",schedulerData, event, bgColor, isStart, isEnd, mustAddCssClass, mustBeHeight);
  const slotName2 = schedulerData.getSlotById(event.resourceId)?.name || "";
  console.log("EITR",slotName2);
  if (slotName2.toLowerCase().includes("Sabato")) {
    return (
      <div
        key={event.id}
        className={`event-item event-item-red ${mustAddCssClass}`}
        style={{
          backgroundColor: "red",
          height: mustBeHeight,
          //cursor: "default", // Disabilita il mouseover
        }}
      >
        <span>{event.title}</span>
      </div>
    );
  }  
  return null;
};
*/
const eventItemTemplateResolver = (schedulerData, event, bgColor, isStart, isEnd, mustAddCssClass, mustBeHeight) => {
  //console.log("EITR",schedulerData, event, bgColor, isStart, isEnd, mustAddCssClass, mustBeHeight);
  const giorno = moment(event.start).format("dddd").toLowerCase(); 
  //console.log("GG",giorno);
  
  let backgroundColor = bgColor; // Mantiene il colore predefinito per gli altri giorni
  let pointerEvents = "auto"; // Abilitato per default

  //if (giorno === "lunedì") {
  //if (event.id === 20 && event.resourceId===3) {
  if (event.val === -1) {
    backgroundColor = "#FFF0F6"; 
    pointerEvents = "none"; 
  }
  if (event.val === 0) {
    backgroundColor = "#FFFFFF"; 
    pointerEvents = "none"; 
  }


  return (
    //console.log("ET",event.title),
    <div
      key={event.id}
      className={mustAddCssClass}
      style={{
        display: "flex",
        //alignItems: "center", 
        alignContent:"center",
        alignItems: "center", 
        //justifyContent: "center",
        //textAlign: "center", 
        width: "100%", 
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "clip", 
        backgroundColor,
        color: "white",
        //borderRadius: "0px",
        //padding: "0px",
        margin:"0px", 
        padding:"0px",       
        //alignItems: "center", 
        //justifyContent: "center", 
        //textAlign: "center",
        pointerEvents,
        height: mustBeHeight,
        fontSize: '0.9rem'
      }}      
    > <Typography   style={{
     paddingTop:"10px",
     paddingLeft:"8px",
      alignContent:"center",
      alignItems: "center", 
      justifyContent: "center",
      textAlign: "center", 
      width: "100%", 
      
      fontSize: '0.9rem'
    }}      >{event.title}</Typography>
    </div>
  );
};

/*
const slotItemTemplateResolver = (schedulerData, slot) => {
  console.log("SITR",schedulerData, slot);
  if (slot.name.toLowerCase().includes("sabato")) {
    return (
      <div
        key={slot.id}
        style={{
          backgroundColor: "red",
          color: "white",
          padding: "10px",
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        {slot.name}
      </div>
    );
  }
  return null; // Mantiene il comportamento predefinito per gli altri giorni
};
*/

/*
const toggleExpandFunc = (schedulerData, slotId) => {
  schedulerData.toggleExpandStatus(slotId);
  setSchedulerData(schedulerData);
};
*/


  return (
    <div>
      {loading ? (
        <div style={{ width:"25%",   display: "flex", marginLeft: "auto", marginRight: "auto" }}>
          <ThreeDots color="#2196F3" height='350' width='150'/>
        </div>
      ):(
      <Scheduler        
        //key={reloadKey}
        schedulerData={schedulerData}
        prevClick={prevClick}
        nextClick={nextClick}
        onSelectDate={onSelectDate}
        //onViewChange={onViewChange}
        //eventItemClick={eventClicked}
        // toggleExpandFunc={toggleExpandFunc}  
        eventItemPopoverTemplateResolver = {eventItemPopoverTemplateResolver}    
        eventItemTemplateResolver = {eventItemTemplateResolver}        
        //slotItemTemplateResolver={slotItemTemplateResolver}
        //eventItemPopoverTemplateResolver={() =>  null}
      />
      )}
    </div>
  );
};

export default withDragDropContext(DashboardWeeklyView);
