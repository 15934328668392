import { Admin, fetchUtils,Resource, ListGuesser,EditGuesser, resolveBrowserLocale } from "react-admin";
//import { Admin, Resource } from "react-admin";
import polyglotI18nProvider from "ra-i18n-polyglot";

import italianCustom from '../Translations/italianCustom';

import merge from 'deepmerge';

import React, { useEffect, useState } from "react";
import { UserList,UserEdit, UserCreate } from "./users";
import { EnteAdminList,EnteAdminEdit, EnteAdminCreate } from "./entiAdmin";
import { GenitoreList,GenitoreEdit, GenitoreCreate } from "./genitori";


import { TitoloList, TitoloEdit, TitoloCreate } from "./titoli";
import { BambinoAdminList, BambinoAdminEdit, BambinoAdminCreate } from "./bambiniAdmin";
import {SlotList, SlotCreate, SlotEdit} from "./slots"
import { IscrizioneList, IscrizioneEdit, IscrizioneCreate } from "./iscrizioni";
import { PrenotazioneCreate, PrenotazioneEdit, PrenotazioneList } from "./prenotazioni";

import { Dashboard } from './dashboard';
import  {authProvider} from './authProvider';
import treeqlProvider from 'ra-data-treeql';
import LoginXKE from "./LoginXKE";
import {properties} from "../utils/properties";
import {setAdminStatusChangeCallback} from './authProvider';

export default function MyAdmin() {    
    const raLanguageItalian = require("../ra-italian/packages/ra-language-italian");
            
    const messages = {      
      it: merge(raLanguageItalian, italianCustom)
    };
    const i18nProvider = polyglotI18nProvider(
      (locale) => {
        const localeMessages = messages.it;
        console.log('i18nProvider: polyglotI18nProvider', {locale, localeMessages});
        return localeMessages;
      },
      resolveBrowserLocale()  
    );

    useEffect(() => {                                
        //console.log("useEffect");
        },[]);


    return ( 
      <div>   
        <Admin loginPage={LoginXKE} disableTelemetry dataProvider={treeqlProvider(properties.productionUrl+'/php/core/api/api.php/')} dashboard={Dashboard} authProvider={authProvider} i18nProvider={i18nProvider}>      
          <Resource name="users" list={UserList}  edit={UserEdit} create={UserCreate}/>
          <Resource name="enti" list={EnteAdminList}  edit={EnteAdminEdit} create={EnteAdminCreate} /> 
          <Resource name="slots" list={SlotList}  edit={SlotEdit} create={SlotCreate} /> 
          <Resource name="genitori" list={GenitoreList}  edit={GenitoreEdit} create={GenitoreCreate} /> 
          <Resource name="bambini" list={BambinoAdminList}  edit={BambinoAdminEdit} create={BambinoAdminCreate}/> 
          <Resource name="iscrizioni" list={IscrizioneList}  edit={IscrizioneEdit} create={IscrizioneCreate} />        
          <Resource name="prenotazioni" list={PrenotazioneList} create={PrenotazioneCreate} edit={PrenotazioneEdit}  />
          <Resource name="titoli" list={TitoloList}  edit={TitoloEdit} create={TitoloCreate}/>       
        </Admin>
      </div>                 
    );      
  }  
