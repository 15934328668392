import { Card, CardContent, CardMedia, Typography, CardHeader } from "@mui/material";
import React, { useState, useEffect } from 'react';
import { properties } from "../utils/properties";

//import logo from "../assets/logo_aquilone2.png";
//import logo_aquilone from "../assets/aquilone.png";
import logo_aquilone from "../assets/aquilone.jpg";
//import logo_paguro from "../assets/paguro.png";
import logo_paguro from "../assets/paguro.jpg";
import logo_altro from "../assets/eduteca.jpg";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import DashboardDailyView from "./DashboardDailyView";
import DashboardWeeklyView from "./DashboardWeeklyView";
import DashboardMonthlyView from "./DashboardMonthlyView";
import LogoStepper from "./LogoStepper"; 
import { useGetOne,useGetList, useGetMany,useGetManyReference } from 'react-admin';
import { SurveyQuestionMatrixDropdownBase } from "survey-react-ui";

const images = [logo_aquilone,logo_paguro];
const idEnti=["48","50"]


function getName() {
  let result = 'Benvenuto ';
  result=result+localStorage.getItem("username")  
  //let result = 'Benvenuto Admin';
  return result;
}

/*
const generateImageWithText = (imageSrc, text) => {
  return new Promise((resolve) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.src = imageSrc;

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      
      ctx.font = "40px Arial";
      ctx.fillStyle = "black"; 
      ctx.fillText(text, 200, 220); 

      resolve(canvas.toDataURL()); 
    };
  });
};
*/


export const Dashboard = () => {

  const [idEnti, setIdEnti]= useState([]);
  const [images, setImages]=useState([]);

  const { data, total, isPending, error } = useGetList(
    'enti',
    { 
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'id', order: 'ASC' }
    }
  );
  const [activeStep, setActiveStep] = useState(0);
  const [tabValue, setTabValue] = React.useState(0);
  const [ente, setEnte] = React.useState(0);

  const handleNext = () => {    
    //console.log("PEE", idEnti, images, ente, (ente + 1) % idEnti.length)
    setEnte((prevIndex) => (prevIndex + 1) % idEnti.length);
    setActiveStep((prevStep) => (prevStep + 1) % images.length);

  };
  
  const handlePrev = () => {
    setEnte((prevIndex) => (prevIndex - 1 + idEnti.length) % idEnti.length);
    setActiveStep((prevStep) => (prevStep - 1 + images.length) % images.length);    
  };

  const handleChange = (event, newValue) => {
    console.log("HC",event, newValue);
    setTabValue(newValue);
  };

  /*useEffect(() => {
    console.log("UE",ente);        
  }, [ente]);*/

  useEffect(() => {
    console.log("DATA",data);    
    if(data){
      //data.sort((a, b) => Number(a.id) - Number(b.id));
      let intImages=[];
      let intIds=[];
      for (let i = 0; i < data.length; i++) {  
        console.log("TESET",data);
        //if (data[i].id =="50") {  //PAGURO
        if (data[i].nome =="Paguro") {  //PAGURO
          intIds.push(data[i].id);
          intImages.push(logo_paguro);          
        } 
        //else if (data[i].id =="48") {  //aquilone
        else if (data[i].nome=="Aquilone") {  //aquilone
          intIds.push(data[i].id);
          intImages.push(logo_aquilone);
        }
        else if (data[i].id !="1") {  //altri enti
          intIds.push(data[i].id);
          intImages.push(logo_altro);

          //let logo= generateImageWithText(logo_altro, data[i].nome).then((logo) => {
          //  console.log("POS",i);
          //  intImages[i]=logo;
            //intImages.push(logo);
          //});
          
          //intImages.push(logo_altro);
        }
      }
      setImages(intImages);
      setIdEnti(intIds);
    }
  }, [data,ente]);


  
  return (
    <Card  sx={{ width: "100%", overflowX: "hidden" }}>
    <CardHeader title={getName()} style={{textAlign:"left"}}/>
    <LogoStepper images={images}    
        activeStep={activeStep}
        handleNext={handleNext}
        handlePrev={handlePrev} />
    
        <CardContent  sx={{ width:"100%", overflowX: "hidden"}} >        
            <Box sx={{ borderBottom: 1, borderColor: 'divider',overflowX: "auto" }}>
                <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Giornaliera" />
                    <Tab label="Settimanale"  />
                    <Tab label="Mensile"  />
                </Tabs>
            </Box>          
             {tabValue === 0 &&
              <div style={{ width: "100%", overflowX: "hidden" }}>
                <DashboardDailyView enteId={idEnti[ente]}/>     
              </div>
             }
               {tabValue === 1 &&
              <DashboardWeeklyView enteId={idEnti[ente]}/>     
             }
            {tabValue === 2 &&
              <DashboardMonthlyView enteId={idEnti[ente]}/>     
             }
          
          <Typography variant="body1" color="text.secondary">          
                ZeroSei Versione {properties.version}
          </Typography>
        </CardContent>    
  </Card>
      );  


};