export const STATES_NAMES = [
"Italia",
"AFGHANISTAN",
"AFRICA DEL SUD-OVEST",
"ALBANIA",
"ALGERIA",
"ANDORRA",
"ANGOLA",
"ANGUILLA (ISOLA)",
"ANTIGUA E BARBUDA",
"ANTILLE BRITANNICHE",
"ANTILLE OLANDESI",
"ARABIA MERIDIONALE FEDERAZIONE",
"ARABIA MERIDIONALE PROTETTORATO",
"ARABIA SAUDITA",
"ARGENTINA",
"ARMENIA",
"AUSTRALIA",
"AUSTRIA",
"AZERBAIGIAN",
"BAHAMA (ISOLE)",
"BAHAMAS",
"BAHREIN",
"BANGLADESH",
"BARBADOS",
"BASUTOLAND-SUD AFRICA BRITANNICO",
"BECIUANIA-SUD AFRICA BRITANNICO",
"BELGIO",
"BELIZE",
"BENIN",
"BERMUDA (ISOLE)",
"BHUTAN",
"BIELORUSSIA",
"BIRMANIA",
"BOLIVIA",
"BOPHUTHATSWANA",
"BOSNIA ED ERZEGOVINA",
"BOTSWANA",
"BRASILE",
"BRUNEI",
"BULGARIA",
"BURKINA",
"BURKINA FASO",
"BURUNDI",
"CAMBOGIA",
"CAMERUN",
"CANADA",
"CAPO VERDE",
"CAROLINE (ISOLE)",
"CAYMAN (ISOLE)",
"CECA REPUBBLICA",
"CECOSLOVACCHIA",
"CENTRAFRICANA REPUBBLICA",
"CENTROAFRICANO IMPERO",
"CEYLON",
"CHRISTMAS (ISOLA)",
"CIAD",
"CILE",
"CINA NAZIONALE",
"CINA REPUBBLICA POPOLARE",
"CIPRO",
"CISKEI",
"CITTA' DEL VATICANO",
"COCOS (ISOLE)",
"COLOMBIA",
"COMORE (ISOLE)",
"CONGO BRAZZAVILLE",
"CONGO LEOPOLDVILLE",
"CONGO REPUBBLICA DEMOCRATICA",
"CONGO REPUBBLICA POPOLARE",
"COOK (ISOLE)",
"COREA DEL NORD",
"COREA DEL SUD",
"COSTA D'AVORIO",
"COSTA DEI PIRATI=TRUCIAL STATES",
"COSTA RICA",
"CROAZIA",
"CUBA",
"DAHOMEY=BENIN",
"DANIMARCA",
"DIPENDENZE AUSTRALIANE",
"DIPENDENZE BRITANNICHE",
"DIPENDENZE CANADESI",
"DIPENDENZE FRANCESI",
"DIPENDENZE NEOZELANDESI",
"DIPENDENZE NORVEGESI ANTARTICHE",
"DIPENDENZE NORVEGESI ARTICHE",
"DIPENDENZE RUSSE",
"DIPENDENZE SOVIETICHE",
"DIPENDENZE STATUNITENSI",
"DIPENDENZE SUDAFRICANE",
"DOMINICA",
"DOMINICANA REPUBBLICA",
"ECUADOR",
"EGITTO",
"EL SALVADOR",
"EMIRATI ARABI UNITI",
"ERITREA",
"ESTONIA",
"ETIOPIA",
"FAER OER (ISOLE)",
"FALKLAND (ISOLE)",
"FEDERAZIONE RUSSA",
"FIGI=VITI",
"FILIPPINE",
"FINLANDIA",
"FORMOSA=TAIWAN",
"FRANCIA",
"GABON",
"GAMBIA",
"GAZA (TERRITORIO DI)",
"GEORGIA",
"GERMANIA",
"GERMANIA REPUBBLICA DEMOCRATICA",
"GERMANIA REPUBBLICA FEDERALE",
"GHANA",
"GIAMAICA",
"GIAPPONE",
"GIBILTERRA",
"GIBUTI",
"GILBERT E ELLICE (ISOLE)",
"GIORDANIA",
"GRAN BRETAGNA E IRLANDA DEL NORD",
"GRECIA",
"GRENADA",
"GROENLANDIA",
"GUADALUPA",
"GUAM (ISOLA)",
"GUATEMALA",
"GUAYANA OLANDESE",
"GUAYANA=GUYANA FRANCESE",
"GUIANA FRANCESE",
"GUINEA",
"GUINEA BISSAU",
"GUINEA EQUATORIALE",
"GUINEA PORTOGHESE",
"GUINEA SPAGNOLA",
"GUYANA",
"GUYANA BRITANNICA",
"HAITI",
"HONDURAS",
"HONDURAS BRITANNICO",
"HONG KONG",
"IFNI",
"INDIA",
"INDONESIA",
"IRAN",
"IRAQ",
"IRIAN OCCIDENTALE",
"IRLANDA",
"ISLANDA",
"ISOLE CILENE (PASQUA E SALA Y GOMEZ)",
"ISOLE DEL CANALE",
"ISOLE DELL'UNIONE",
"ISRAELE",
"IUGOSLAVIA",
"KAZAKISTAN",
"KENYA",
"KIRGHIZISTAN",
"KIRIBATI",
"KOSOVO",
"KUWAIT",
"LA REUNION (ISOLA)",
"LAOS",
"LESOTHO",
"LETTONIA",
"LIBANO",
"LIBERIA",
"LIBIA",
"LIECHTENSTEIN",
"LITUANIA",
"LUSSEMBURGO",
"MACAO",
"MACEDONIA",
"MACEDONIA DEL NORD",
"MACQUARIE (ISOLE)",
"MADAGASCAR",
"MALAWI",
"MALAYSIA",
"MALAYSIA",
"MALDIVE",
"MALI",
"MALTA",
"MALVINE=FALKLAND (ISOLE)",
"MAN (ISOLA)",
"MARCUS (ISOLE)",
"MARIANNE (ISOLE)",
"MAROCCO",
"MARSHALL (ISOLE)",
"MARTINICA",
"MAURITANIA",
"MAURIZIO (ISOLE)",
"MAYOTTE (ISOLA)",
"MESSICO",
"MICRONESIA STATI FEDERATI",
"MIDWAY (ISOLE)",
"MOLDAVIA",
"MONACO",
"MONGOLIA",
"MONTENEGRO",
"MONTSERRAT",
"MOZAMBICO",
"MYANMAR",
"NAMIBIA",
"NAURU (ISOLE)",
"NEPAL",
"NICARAGUA",
"NIGER",
"NIGERIA",
"NIUE=SAVAGE (ISOLE)",
"NORFOLK (ISOLE E ISOLE DEL MAR DEI CORALLI)",
"NORMANNE (ISOLE)=ISOLE DEL CANALE",
"NORVEGIA",
"NUOVA CALEDONIA (ISOLE E DIPENDENZE)",
"NUOVA GUINEA",
"NUOVA ZELANDA",
"NUOVE EBRIDI (ISOLE CONDOMINIO FRANCO-INGLESE)",
"OMAN",
"PAESI BASSI",
"PAKISTAN",
"PALAU REPUBBLICA",
"PANAMA",
"PANAMA ZONA DEL CANALE",
"PAPUA NUOVA GUINEA",
"PAPUASIA",
"PARAGUAY",
"PASQUA (ISOLA)",
"PERU'",
"PITCAIRN (E DIPENDENZE)",
"POLINESIA FRANCESE (ISOLE)",
"POLONIA",
"PORTOGALLO",
"PUERTO RICO",
"QATAR",
"REGNO UNITO",
"REPUBBLICA CECA",
"REPUBBLICA DI COREA",
"REPUBBLICA DI POLONIA",
"REPUBBLICA DOMINICANA",
"RHODESIA",
"ROMANIA",
"RUANDA",
"RUSSIA=FEDERAZIONE RUSSA",
"RYUKYU (ISOLE)",
"SAHARA MERIDIONALE",
"SAHARA SETTENTRIONALE",
"SAHARA SPAGNOLO",
"SAINT KITTS E NEVIS",
"SAINT LUCIA",
"SAINT PIERRE ET MIQUELON (ISOLE)",
"SAINT VINCENT E GRENADINE",
"SALOMONE (ISOLE)",
"SAMOA",
"SAMOA AMERICANE (ISOLE)",
"SAN MARINO",
"SANT'ELENA (ISOLA)",
"SAO TOME' E PRINCIPE (ISOLE)",
"SAVAGE (ISOLE)",
"SEICELLE (ISOLE)",
"SENEGAL",
"SERBIA",
"SERBIA E MONTENEGRO",
"SIERRA LEONE",
"SIKKIM",
"SINGAPORE",
"SIRIA",
"SLOVACCHIA",
"SLOVENIA",
"SOMALIA",
"SOMALIA FRANCESE",
"SPAGNA",
"SRI LANKA",
"STATI UNITI D'AMERICA",
"SUD SUDAN",
"SUDAFRICANA REPUBBLICA",
"SUDAN",
"SURINAME",
"SVEZIA",
"SVIZZERA",
"SWAZILAND",
"TAGIKISTAN",
"TAIWAN",
"TANGANICA",
"TANZANIA",
"TERRITORI PALESTINESI",
"TERRITORIO FRANCESE DEGLI AFAR E DEGLI ISSA",
"THAILANDIA",
"TIMOR (ISOLA)",
"TOGO",
"TOKELAU=ISOLE DELL'UNIONE",
"TONGA",
"TRANSKEI",
"TRINIDAD E TOBAGO",
"TUNISIA",
"TURCHIA",
"TURKMENISTAN",
"TURKS E CAICOS (ISOLE)",
"TUVALU",
"UCRAINA",
"UGANDA",
"UNGHERIA",
"UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE",
"URUGUAY",
"UZBEKISTAN",
"VANUATU",
"VENDA",
"VENEZUELA",
"VERGINI AMERICANE (ISOLE)",
"VERGINI BRITANNICHE (ISOLE)",
"VIETNAM",
"VIETNAM DEL NORD",
"VIETNAM DEL SUD",
"VITI",
"VOLTA=ALTOVOLTA",
"WALLIS E FUTUNA (ISOLE)",
"YEMEN",
"YEMEN REPUBBLICA DEMOCRATICA POPOLARE",
"ZAIRE",
"ZAMBIA",
"ZANZIBAR",
"ZIMBABWE"
]

export const PROVINCES = {
    AG: "Agrigento",
    AL: "Alessandria",
    AN: "Ancona",
    AO: "Aosta",
    AP: "Ascoli Piceno",
    AQ: "L'Aquila",
    AR: "Arezzo",
    AT: "Asti",
    AV: "Avellino",
    BA: "Bari",
    BG: "Bergamo",
    BI: "Biella",
    BL: "Belluno",
    BN: "Benevento",
    BO: "Bologna",
    BR: "Brindisi",
    BS: "Brescia",
    BT: "Barletta-Andria-Trani",
    BZ: "Bolzano",
    CA: "Cagliari",
    CB: "Campobasso",
    CE: "Caserta",
    CH: "Chieti",
    CL: "Caltanissetta",
    CN: "Cuneo",
    CO: "Como",
    CR: "Cremona",
    CS: "Cosenza",
    CT: "Catania",
    CZ: "Catanzaro",
    EN: "Enna",
    FC: "Forlì-Cesena",
    FE: "Ferrara",
    FG: "Foggia",
    FI: "Firenze",
    FM: "Fermo",
    FR: "Frosinone",
    GE: "Genova",
    GO: "Gorizia",
    GR: "Grosseto",
    IM: "Imperia",
    IS: "Isernia",
    KR: "Crotone",
    LC: "Lecco",
    LE: "Lecce",
    LI: "Livorno",
    LO: "Lodi",
    LT: "Latina",
    LU: "Lucca",
    MB: "Monza e della Brianza",
    MC: "Macerata",
    ME: "Messina",
    MI: "Milano",
    MN: "Mantova",
    MO: "Modena",
    MS: "Massa-Carrara",
    MT: "Matera",
    NA: "Napoli",
    NO: "Novara",
    NU: "Nuoro",
    OR: "Oristano",
    PA: "Palermo",
    PC: "Piacenza",
    PD: "Padova",
    PE: "Pescara",
    PG: "Perugia",
    PI: "Pisa",
    PN: "Pordenone",
    PO: "Prato",
    PR: "Parma",
    PT: "Pistoia",
    PU: "Pesaro e Urbino",
    PV: "Pavia",
    PZ: "Potenza",
    RA: "Ravenna",
    RC: "Reggio Calabria",
    RE: "Reggio Emilia",
    RG: "Ragusa",
    RI: "Rieti",
    RM: "Roma",
    RN: "Rimini",
    RO: "Rovigo",
    SA: "Salerno",
    SI: "Siena",
    SO: "Sondrio",
    SP: "La Spezia",
    SR: "Siracusa",
    SS: "Sassari",
    SU: "Sud Sardegna",
    SV: "Savona",
    TA: "Taranto",
    TE: "Teramo",
    TN: "Trento",
    TO: "Torino",
    TP: "Trapani",
    TR: "Terni",
    TS: "Trieste",
    TV: "Treviso",
    UD: "Udine",
    VA: "Varese",
    VB: "Verbano-Cusio-Ossola",
    VC: "Vercelli",
    VE: "Venezia",
    VI: "Vicenza",
    VR: "Verona",
    VS: "Medio Campidano",
    VT: "Viterbo",
    VV: "Vibo Valentia",
    EE: "Estero"
}

export const COUNTRIES = [["A001", "PD", "ABANO BAGNI", 0],
    ["A001", "PD", "ABANO TERME", 1],
    ["A002", "CO", "ABBADIA", 0],
    ["A003", "TO", "ABBADIA", 0],
    ["A003", "TO", "ABBADIA ALPINA", 0],
    ["A004", "LO", "ABBADIA CERRETO", 1],
    ["A005", "LC", "ABBADIA LARIANA", 1],
    ["A006", "SI", "ABBADIA SAN SALVATORE", 1],
    ["A002", "CO", "ABBADIA SOPRA ADDA", 0],
    ["A007", "OR", "ABBASANTA", 1],
    ["A008", "PE", "ABBATEGGIO", 1],
    ["A009", "FM", "ABBAZIA", 0],
    ["A010", "MI", "ABBIATEGRASSO", 1],
    ["A011", "VA", "ABBIATEGUAZZONE", 0],
    ["A012", "PT", "ABETONE", 0],
    ["M376", "PT", "ABETONE CUTIGLIANO", 1],
    ["A013", "PZ", "ABRIOLA", 1],
    ["A014", "RG", "ACATE", 1],
    ["A015", "FG", "ACCADIA", 1],
    ["A016", "CN", "ACCEGLIO", 1],
    ["A017", "MT", "ACCETTURA", 1],
    ["A018", "AQ", "ACCIANO", 1],
    ["A019", "RI", "ACCUMOLI", 1],
    ["A020", "PZ", "ACERENZA", 1],
    ["A021", "BZ", "ACERETO", 0],
    ["A022", "BZ", "ACERNES", 0],
    ["A023", "SA", "ACERNO", 1],
    ["A024", "NA", "ACERRA", 1],
    ["A025", "CT", "ACI BONACCORSI", 1],
    ["A026", "CT", "ACI CASTELLO", 1],
    ["A027", "CT", "ACI CATENA", 1],
    ["A029", "CT", "ACI SANT'ANTONIO", 1],
    ["A028", "CT", "ACIREALE", 1],
    ["A030", "SO", "ACQUA", 0],
    ["A031", "MC", "ACQUACANINA", 0],
    ["A032", "FR", "ACQUAFONDATA", 1],
    ["A033", "CS", "ACQUAFORMOSA", 1],
    ["A034", "BS", "ACQUAFREDDA", 1],
    ["A035", "PU", "ACQUALAGNA", 1],
    ["A036", "BS", "ACQUALUNGA", 0],
    ["A037", "CR", "ACQUALUNGA BADONA", 0],
    ["A038", "BS", "ACQUANEGRA", 0],
    ["A039", "CR", "ACQUANEGRA", 0],
    ["A039", "CR", "ACQUANEGRA CREMONESE", 1],
    ["A038", "MN", "ACQUANEGRA SUL CHIESE", 1],
    ["A040", "VT", "ACQUAPENDENTE", 1],
    ["A041", "CS", "ACQUAPPESA", 1],
    ["A042", "LE", "ACQUARICA DEL CAPO", 0],
    ["A043", "VV", "ACQUARO", 1],
    ["A044", "AP", "ACQUASANTA", 0],
    ["A044", "AP", "ACQUASANTA TERME", 1],
    ["A045", "TR", "ACQUASPARTA", 1],
    ["A046", "CO", "ACQUATE", 0],
    ["A047", "AP", "ACQUAVIVA", 0],
    ["A048", "BA", "ACQUAVIVA", 0],
    ["A049", "CL", "ACQUAVIVA", 0],
    ["A050", "CB", "ACQUAVIVA COLLECROCE", 1],
    ["A051", "IS", "ACQUAVIVA D'ISERNIA", 1],
    ["A048", "BA", "ACQUAVIVA DELLE FONTI", 1],
    ["A047", "AP", "ACQUAVIVA PICENA", 1],
    ["A049", "CL", "ACQUAVIVA PLATANI", 1],
    ["M211", "ME", "ACQUEDOLCI", 1],
    ["A052", "AL", "ACQUI", 0],
    ["A052", "AL", "ACQUI TERME", 1],
    ["A053", "CS", "ACRI", 1],
    ["A054", "FR", "ACUTO", 1],
    ["A055", "BA", "ADELFIA", 1],
    ["A056", "CT", "ADERNO", 0],
    ["A056", "CT", "ADRANO", 1],
    ["A057", "BG", "ADRARA SAN MARTINO", 1],
    ["A058", "BG", "ADRARA SAN ROCCO", 1],
    ["A059", "RO", "ADRIA", 1],
    ["A060", "BS", "ADRO", 1],
    ["A061", "VR", "AFFI", 1],
    ["A062", "RM", "AFFILE", 1],
    ["A063", "MI", "AFFORI", 0],
    ["Z200", "EE", "AFGHANISTAN", 1],
    ["A064", "NA", "AFRAGOLA", 1],
    ["Z300", "EE", "AFRICA DEL SUD-OVEST", 0],
    ["A065", "RC", "AFRICO", 1],
    ["A066", "NO", "AGARO", 0],
    ["A067", "PC", "AGAZZANO", 1],
    ["A068", "NA", "AGEROLA", 1],
    ["A069", "SS", "AGGIUS", 1],
    ["A070", "EN", "AGIRA", 1],
    ["A071", "PT", "AGLIANA", 1],
    ["A072", "AT", "AGLIANO", 0],
    ["A072", "AT", "AGLIANO TERME", 1],
    ["A073", "MI", "AGLIATE", 0],
    ["A074", "TO", "AGLIE'", 1],
    ["H848", "SS", "AGLIENTU", 1],
    ["A075", "PD", "AGNA", 1],
    ["A076", "CR", "AGNADELLO", 1],
    ["A077", "RC", "AGNANA CALABRA", 1],
    ["A078", "NO", "AGNELLENGO", 0],
    ["A079", "VC", "AGNONA", 0],
    ["A080", "IS", "AGNONE", 1],
    ["A081", "CE", "AGNONE", 0],
    ["A082", "BS", "AGNOSINE", 1],
    ["A083", "BL", "AGORDO", 1],
    ["A084", "RM", "AGOSTA", 1],
    ["A085", "VA", "AGRA", 1],
    ["A086", "NO", "AGRANO", 0],
    ["A087", "MI", "AGRATE", 0],
    ["A088", "NO", "AGRATE", 0],
    ["A087", "MB", "AGRATE BRIANZA", 1],
    ["A087", "MI", "AGRATE BRIANZA", 0],
    ["A088", "NO", "AGRATE CONTURBIA", 1],
    ["A089", "AG", "AGRIGENTO", 1],
    ["A090", "TN", "AGRONE", 0],
    ["A091", "SA", "AGROPOLI", 1],
    ["A092", "AN", "AGUGLIANO", 1],
    ["A093", "VI", "AGUGLIARO", 1],
    ["A094", "AO", "AIAS", 0],
    ["A095", "GO", "AIBA", 0],
    ["A096", "MB", "AICURZIO", 1],
    ["A096", "MI", "AICURZIO", 0],
    ["A097", "OR", "AIDOMAGGIORE", 1],
    ["A098", "EN", "AIDONE", 1],
    ["A099", "GO", "AIDUSSINA", 0],
    ["A100", "AQ", "AIELLI", 1],
    ["A101", "AV", "AIELLO", 0],
    ["A102", "CS", "AIELLO CALABRO", 1],
    ["A103", "UD", "AIELLO DEL FRIULI", 1],
    ["A101", "AV", "AIELLO DEL SABATO", 1],
    ["A104", "CS", "AIELLO IN CALABRIA", 0],
    ["A105", "CS", "AIETA", 1],
    ["A106", "CE", "AILANO", 1],
    ["A107", "BI", "AILOCHE", 1],
    ["A108", "AO", "AIMAVILLA", 0],
    ["A109", "TO", "AIRASCA", 1],
    ["A110", "BN", "AIROLA", 1],
    ["A111", "IM", "AIROLE", 1],
    ["A112", "LC", "AIRUNO", 1],
    ["A113", "CN", "AISONE", 1],
    ["A114", "CO", "AIZURRO", 0],
    ["A115", "SS", "ALA", 0],
    ["A116", "TN", "ALA", 1],
    ["A117", "TO", "ALA DI STURA", 1],
    ["A115", "SS", "ALA' DEI SARDI", 1],
    ["A118", "PV", "ALAGNA", 1],
    ["A119", "VC", "ALAGNA", 0],
    ["A119", "VC", "ALAGNA VALSESIA", 1],
    ["A120", "PE", "ALANNO", 1],
    ["A121", "BL", "ALANO", 0],
    ["A121", "BL", "ALANO DI PIAVE", 1],
    ["A122", "SV", "ALASSIO", 1],
    ["A123", "FR", "ALATRI", 1],
    ["A124", "CN", "ALBA", 1],
    ["A125", "TE", "ALBA ADRIATICA", 1],
    ["A126", "OR", "ALBAGIARA", 1],
    ["A127", "MI", "ALBAIRATE", 1],
    ["A128", "SA", "ALBANELLA", 1],
    ["Z100", "EE", "ALBANIA", 1],
    ["A129", "BG", "ALBANO", 0],
    ["A130", "NO", "ALBANO", 0],
    ["A131", "PZ", "ALBANO", 0],
    ["A132", "RM", "ALBANO", 0],
    ["A131", "PZ", "ALBANO DI LUCANIA", 1],
    ["A132", "RM", "ALBANO LAZIALE", 1],
    ["A129", "BG", "ALBANO SANT'ALESSANDRO", 1],
    ["A130", "VC", "ALBANO VERCELLESE", 1],
    ["A133", "CE", "ALBANOVA", 0],
    ["A134", "PV", "ALBAREDO", 0],
    ["A135", "SO", "ALBAREDO", 0],
    ["A136", "TV", "ALBAREDO", 0],
    ["A137", "VR", "ALBAREDO", 0],
    ["A134", "PV", "ALBAREDO ARNABOLDI", 1],
    ["A137", "VR", "ALBAREDO D'ADIGE", 1],
    ["A135", "SO", "ALBAREDO PER SAN MARCO", 1],
    ["A138", "PR", "ALBARETO", 1],
    ["A138", "PR", "ALBARETO DI BORGOTARO", 0],
    ["A139", "CN", "ALBARETTO", 0],
    ["A140", "CN", "ALBARETTO", 0],
    ["A139", "CN", "ALBARETTO DELLA TORRE", 1],
    ["A140", "CN", "ALBARETTO VALLE DI MACRA", 0],
    ["A141", "TS", "ALBARO VESCOVA", 0],
    ["A142", "CO", "ALBATE", 0],
    ["A143", "CO", "ALBAVILLA", 1],
    ["A144", "BG", "ALBEGNO", 0],
    ["A145", "SV", "ALBENGA", 1],
    ["A148", "TS", "ALBER DI SESANA", 0],
    ["A146", "AL", "ALBERA", 0],
    ["A147", "CR", "ALBERA", 0],
    ["A146", "AL", "ALBERA LIGURE", 1],
    ["A149", "BA", "ALBEROBELLO", 1],
    ["A150", "FG", "ALBERONA", 1],
    ["A151", "BZ", "ALBES", 0],
    ["A152", "CO", "ALBESE", 0],
    ["A153", "CO", "ALBESE CON CASSANO", 1],
    ["A154", "VI", "ALBETTONE", 1],
    ["A155", "CZ", "ALBI", 1],
    ["A156", "MS", "ALBIANO", 0],
    ["A157", "TO", "ALBIANO", 0],
    ["A158", "TN", "ALBIANO", 1],
    ["A157", "TO", "ALBIANO D'IVREA", 1],
    ["A156", "MS", "ALBIANO MAGRA", 0],
    ["A159", "MB", "ALBIATE", 1],
    ["A159", "MI", "ALBIATE", 0],
    ["A160", "CS", "ALBIDONA", 1],
    ["A161", "PD", "ALBIGNASEGO", 1],
    ["A162", "RE", "ALBINEA", 1],
    ["A163", "BG", "ALBINO", 1],
    ["A164", "CO", "ALBIOLO", 1],
    ["A166", "SV", "ALBISOLA SUPERIORE", 1],
    ["A165", "SV", "ALBISSOLA MARINA", 1],
    ["A167", "VA", "ALBIZZATE", 1],
    ["A168", "CO", "ALBOGASIO", 0],
    ["A169", "NO", "ALBOGNO", 0],
    ["A170", "PL", "ALBONA", 0],
    ["A171", "PV", "ALBONESE", 1],
    ["A172", "SO", "ALBOSAGGIA", 1],
    ["A173", "AT", "ALBUGNANO", 1],
    ["A174", "MI", "ALBUSCIAGO", 0],
    ["A175", "PV", "ALBUZZANO", 1],
    ["A176", "TP", "ALCAMO", 1],
    ["A177", "ME", "ALCARA LI FUSI", 1],
    ["A178", "TN", "ALDENO", 1],
    ["A179", "BZ", "ALDINO", 1],
    ["A180", "OR", "ALES", 1],
    ["A181", "AG", "ALESSANDRIA", 0],
    ["A182", "AL", "ALESSANDRIA", 1],
    ["A183", "CS", "ALESSANDRIA", 0],
    ["A183", "CS", "ALESSANDRIA DEL CARRETTO", 1],
    ["A181", "AG", "ALESSANDRIA DELLA ROCCA", 1],
    ["A184", "LE", "ALESSANO", 1],
    ["A185", "LE", "ALEZIO", 1],
    ["A186", "SA", "ALFANO", 1],
    ["A187", "AQ", "ALFEDENA", 1],
    ["A188", "BS", "ALFIANELLO", 1],
    ["A189", "AL", "ALFIANO", 0],
    ["A190", "CR", "ALFIANO", 0],
    ["A190", "CR", "ALFIANO ED UNITI", 0],
    ["A189", "AL", "ALFIANO NATTA", 1],
    ["A191", "RA", "ALFONSINE", 1],
    ["Z301", "EE", "ALGERIA", 1],
    ["A192", "SS", "ALGHERO", 1],
    ["A193", "BG", "ALGUA", 1],
    ["A193", "BG", "ALGUA DI COSTA SERINA", 0],
    ["A194", "ME", "ALI'", 1],
    ["A201", "ME", "ALI' MARINA", 0],
    ["A194", "ME", "ALI' SUPERIORE", 0],
    ["A201", "ME", "ALI' TERME", 1],
    ["A195", "PA", "ALIA", 1],
    ["A196", "MT", "ALIANO", 1],
    ["A197", "AL", "ALICE", 0],
    ["A198", "NO", "ALICE", 0],
    ["A197", "AL", "ALICE BEL COLLE", 1],
    ["A198", "VC", "ALICE CASTELLO", 1],
    ["A199", "TO", "ALICE SUPERIORE", 0],
    ["A200", "CE", "ALIFE", 1],
    ["A202", "PA", "ALIMENA", 1],
    ["A203", "PA", "ALIMINUSA", 1],
    ["A204", "OR", "ALLAI", 1],
    ["A205", "AO", "ALLAIN", 0],
    ["A206", "BL", "ALLEGHE", 1],
    ["A205", "AO", "ALLEIN", 1],
    ["A205", "AO", "ALLENO", 0],
    ["A207", "TR", "ALLERONA", 1],
    ["A208", "LE", "ALLISTE", 1],
    ["A209", "BZ", "ALLIZ", 0],
    ["A210", "RM", "ALLUMIERE", 1],
    ["A211", "AL", "ALLUVIONI CAMBIO'", 0],
    ["M397", "AL", "ALLUVIONI PIOVERA", 1],
    ["A212", "CN", "ALMA", 0],
    ["A213", "TN", "ALMAZZAGO", 0],
    ["A214", "BG", "ALME'", 1],
    ["M305", "BG", "ALME' CON VILLA", 0],
    ["A216", "BG", "ALMENNO SAN BARTOLOMEO", 1],
    ["A217", "BG", "ALMENNO SAN SALVATORE", 1],
    ["A218", "TO", "ALMESE", 1],
    ["A219", "BS", "ALONE", 0],
    ["A220", "VI", "ALONTE", 1],
    ["M375", "BL", "ALPAGO", 1],
    ["A221", "TO", "ALPETTE", 1],
    ["A222", "TO", "ALPIGNANO", 1],
    ["A223", "PC", "ALSENO", 1],
    ["A224", "CO", "ALSERIO", 1],
    ["M386", "PC", "ALTA VAL TIDONE", 1],
    ["M383", "CO", "ALTA VALLE INTELVI", 1],
    ["A225", "BA", "ALTAMURA", 1],
    ["A226", "SV", "ALTARE", 1],
    ["M349", "TN", "ALTAVALLE", 1],
    ["A227", "AL", "ALTAVILLA", 0],
    ["A228", "AV", "ALTAVILLA", 0],
    ["A229", "PA", "ALTAVILLA", 0],
    ["A230", "SA", "ALTAVILLA", 0],
    ["A231", "VI", "ALTAVILLA", 0],
    ["A228", "AV", "ALTAVILLA IRPINA", 1],
    ["A229", "PA", "ALTAVILLA MILICIA", 1],
    ["A227", "AL", "ALTAVILLA MONFERRATO", 1],
    ["A230", "SA", "ALTAVILLA SILENTINA", 1],
    ["A231", "VI", "ALTAVILLA VICENTINA", 1],
    ["A232", "AP", "ALTETA", 0],
    ["A233", "AP", "ALTIDONA", 0],
    ["A233", "FM", "ALTIDONA", 1],
    ["A234", "CS", "ALTILIA", 1],
    ["A235", "CH", "ALTINO", 1],
    ["A236", "VI", "ALTISSIMO", 1],
    ["A237", "TV", "ALTIVOLE", 1],
    ["A238", "CN", "ALTO", 1],
    ["M369", "BO", "ALTO RENO TERME", 1],
    ["M389", "VC", "ALTO SERMENZA", 1],
    ["A239", "PA", "ALTOFONTE", 1],
    ["A240", "CS", "ALTOMONTE", 1],
    ["A241", "LU", "ALTOPASCIO", 1],
    ["M350", "TN", "ALTOPIANO DELLA VIGOLANA", 1],
    ["A242", "TR", "ALVIANO", 1],
    ["A243", "CE", "ALVIGNANO", 1],
    ["A244", "FR", "ALVITO", 1],
    ["A245", "AL", "ALZANO", 0],
    ["A246", "BG", "ALZANO LOMBARDO", 1],
    ["A247", "BG", "ALZANO MAGGIORE", 0],
    ["A245", "AL", "ALZANO SCRIVIA", 1],
    ["A248", "BG", "ALZANO SOPRA", 0],
    ["A249", "CO", "ALZATE", 0],
    ["A250", "NO", "ALZATE", 0],
    ["A249", "CO", "ALZATE BRIANZA", 1],
    ["A250", "NO", "ALZATE CON LINDUNO", 0],
    ["A249", "CO", "ALZATE CON VERZAGO", 0],
    ["A251", "SA", "AMALFI", 1],
    ["A252", "AP", "AMANDOLA", 0],
    ["A252", "FM", "AMANDOLA", 1],
    ["A253", "CS", "AMANTEA", 1],
    ["A254", "UD", "AMARO", 1],
    ["A255", "CZ", "AMARONI", 1],
    ["A256", "FR", "AMASENO", 1],
    ["A257", "CZ", "AMATO", 1],
    ["A258", "RI", "AMATRICE", 1],
    ["A259", "BG", "AMBIVERE", 1],
    ["A260", "TN", "AMBLAR", 0],
    ["M351", "TN", "AMBLAR-DON", 1],
    ["A261", "SP", "AMEGLIA", 1],
    ["A262", "TR", "AMELIA", 1],
    ["A263", "CS", "AMENDOLARA", 1],
    ["A264", "NO", "AMENO", 1],
    ["A265", "BN", "AMOROSI", 1],
    ["A266", "BL", "AMPEZZO", 0],
    ["A267", "UD", "AMPEZZO", 1],
    ["A268", "NA", "ANACAPRI", 1],
    ["A269", "FR", "ANAGNI", 1],
    ["A270", "TE", "ANCARANO", 1],
    ["A271", "AN", "ANCONA", 1],
    ["A272", "CZ", "ANDALI", 1],
    ["A273", "SO", "ANDALO", 0],
    ["A274", "TN", "ANDALO", 1],
    ["A273", "SO", "ANDALO VALTELLINO", 1],
    ["A275", "TO", "ANDEZENO", 1],
    ["A276", "TN", "ANDOGNO", 0],
    ["A277", "CN", "ANDONNO", 0],
    ["A278", "SV", "ANDORA", 1],
    ["A279", "VC", "ANDORNO CACCIORNA", 0],
    ["A280", "BI", "ANDORNO MICCA", 1],
    ["Z101", "EE", "ANDORRA", 1],
    ["A281", "LE", "ANDRANO", 1],
    ["A282", "TO", "ANDRATE", 1],
    ["A283", "PN", "ANDREIS", 1],
    ["A284", "AV", "ANDRETTA", 1],
    ["A285", "BA", "ANDRIA", 0],
    ["A285", "BT", "ANDRIA", 1],
    ["A286", "BZ", "ANDRIANO", 1],
    ["A287", "SS", "ANELA", 1],
    ["A288", "BS", "ANFO", 1],
    ["A289", "BS", "ANFURRO", 0],
    ["A290", "VA", "ANGERA", 1],
    ["A291", "AR", "ANGHIARI", 1],
    ["A292", "VR", "ANGIARI", 1],
    ["Z302", "EE", "ANGOLA", 1],
    ["A293", "BS", "ANGOLO", 0],
    ["A293", "BS", "ANGOLO TERME", 1],
    ["A294", "SA", "ANGRI", 1],
    ["A295", "TO", "ANGROGNA", 1],
    ["Z529", "EE", "ANGUILLA (ISOLA)", 1],
    ["A296", "PD", "ANGUILLARA", 0],
    ["A297", "RM", "ANGUILLARA", 0],
    ["A297", "RM", "ANGUILLARA SABAZIA", 1],
    ["A296", "PD", "ANGUILLARA VENETA", 1],
    ["A298", "GO", "ANICOVA CORADA", 0],
    ["A299", "CR", "ANNICCO", 1],
    ["A300", "AL", "ANNONE", 0],
    ["A301", "CO", "ANNONE", 0],
    ["A302", "VE", "ANNONE", 0],
    ["A301", "LC", "ANNONE DI BRIANZA", 1],
    ["A302", "VE", "ANNONE VENETO", 1],
    ["A303", "RC", "ANOIA", 1],
    ["A304", "BG", "ANTEGNATE", 1],
    ["A305", "AO", "ANTEI SANT'ANDREA", 0],
    ["A306", "BZ", "ANTERIVO", 1],
    ["A307", "BZ", "ANTERSELVA", 0],
    ["A308", "TO", "ANTEY LA MAGDELEINE", 0],
    ["A305", "AO", "ANTEY-SAINT-ANDRE'", 1],
    ["A309", "RM", "ANTICOLI", 0],
    ["A309", "RM", "ANTICOLI CORRADO", 1],
    ["A310", "FR", "ANTICOLI DI CAMPAGNA", 0],
    ["A311", "PL", "ANTIGNANA", 0],
    ["A312", "AT", "ANTIGNANO", 1],
    ["Z532", "EE", "ANTIGUA E BARBUDA", 1],
    ["Z500", "EE", "ANTILLE BRITANNICHE", 0],
    ["Z521", "EE", "ANTILLE BRITANNICHE", 0],
    ["Z523", "EE", "ANTILLE BRITANNICHE", 1],
    ["Z501", "EE", "ANTILLE OLANDESI", 1],
    ["A313", "ME", "ANTILLO", 1],
    ["A314", "RC", "ANTONIMINA", 1],
    ["A315", "RI", "ANTRODOCO", 1],
    ["A317", "VB", "ANTRONA SCHIERANCO", 1],
    ["A316", "NO", "ANTRONAPIANA", 0],
    ["A318", "AQ", "ANVERSA", 0],
    ["A318", "AQ", "ANVERSA DEGLI ABRUZZI", 1],
    ["A319", "CO", "ANZANO", 0],
    ["A320", "FG", "ANZANO", 0],
    ["A320", "FG", "ANZANO DEGLI IRPINI", 0],
    ["A319", "CO", "ANZANO DEL PARCO", 1],
    ["A320", "FG", "ANZANO DI PUGLIA", 1],
    ["A321", "PZ", "ANZI", 1],
    ["A322", "NO", "ANZINO", 0],
    ["A323", "RM", "ANZIO", 1],
    ["A324", "BO", "ANZOLA", 0],
    ["A325", "NO", "ANZOLA", 0],
    ["A325", "VB", "ANZOLA D'OSSOLA", 1],
    ["A324", "BO", "ANZOLA DELL'EMILIA", 1],
    ["A326", "AO", "AOSTA", 1],
    ["A327", "PU", "APECCHIO", 1],
    ["A328", "BN", "APICE", 1],
    ["A329", "MC", "APIRO", 1],
    ["A330", "BN", "APOLLOSA", 1],
    ["A331", "GE", "APPARIZIONE", 0],
    ["A332", "BZ", "APPIANO", 0],
    ["A333", "CO", "APPIANO", 0],
    ["A333", "CO", "APPIANO GENTILE", 1],
    ["A332", "BZ", "APPIANO SULLA STRADA DEL VINO", 1],
    ["A334", "MC", "APPIGNANO", 1],
    ["A335", "AP", "APPIGNANO DEL TRONTO", 1],
    ["A335", "AP", "APPIGNANO DI OFFIDA", 0],
    ["A336", "FM", "APRIANO", 0],
    ["A337", "SO", "APRICA", 1],
    ["A338", "IM", "APRICALE", 1],
    ["A339", "FG", "APRICENA", 1],
    ["A340", "CS", "APRIGLIANO", 1],
    ["A341", "LT", "APRILIA", 1],
    ["A342", "MS", "APUANIA", 0],
    ["A343", "SA", "AQUARA", 1],
    ["A344", "IM", "AQUILA", 0],
    ["A345", "AQ", "AQUILA", 0],
    ["A344", "IM", "AQUILA D'ARROSCIA", 1],
    ["A345", "AQ", "AQUILA DEGLI ABRUZZI", 0],
    ["A346", "UD", "AQUILEIA", 1],
    ["A347", "AV", "AQUILONIA", 1],
    ["A348", "FR", "AQUINO", 1],
    ["A349", "NO", "ARA", 0],
    ["Z201", "EE", "ARABIA MERIDIONALE FEDERAZIONE", 0],
    ["Z202", "EE", "ARABIA MERIDIONALE PROTETTORATO", 0],
    ["Z203", "EE", "ARABIA SAUDITA", 1],
    ["A350", "LE", "ARADEO", 1],
    ["A351", "AG", "ARAGONA", 1],
    ["A352", "AT", "ARAMENGO", 1],
    ["A353", "VC", "ARANCO", 0],
    ["A354", "PN", "ARBA", 1],
    ["A355", "NU", "ARBATAX DI TORTOLI", 0],
    ["A356", "VA", "ARBIZZO", 0],
    ["A357", "OR", "ARBOREA", 1],
    ["A358", "VC", "ARBORIO", 1],
    ["A359", "CA", "ARBUS", 0],
    ["A359", "SU", "ARBUS", 1],
    ["A360", "TV", "ARCADE", 1],
    ["A361", "MI", "ARCAGNA", 0],
    ["A362", "MI", "ARCAGNANO", 0],
    ["A363", "FR", "ARCE", 1],
    ["A364", "CO", "ARCELLASCO", 0],
    ["A365", "BG", "ARCENE", 1],
    ["A366", "AN", "ARCEVIA", 1],
    ["A367", "CH", "ARCHI", 1],
    ["A368", "CA", "ARCIDANO", 0],
    ["A369", "GR", "ARCIDOSSO", 1],
    ["A370", "RM", "ARCINAZZO ROMANO", 1],
    ["A371", "VA", "ARCISATE", 1],
    ["A372", "TN", "ARCO", 1],
    ["A373", "SP", "ARCOLA", 1],
    ["A374", "VR", "ARCOLE", 1],
    ["A375", "MI", "ARCONATE", 1],
    ["A376", "MB", "ARCORE", 1],
    ["A376", "MI", "ARCORE", 0],
    ["A377", "VI", "ARCUGNANO", 1],
    ["A378", "VA", "ARCUMEGGIA", 0],
    ["A379", "SS", "ARDARA", 1],
    ["A380", "OR", "ARDAULI", 1],
    ["M213", "RM", "ARDEA", 1],
    ["A381", "VA", "ARDENA", 0],
    ["A382", "SO", "ARDENNO", 1],
    ["A383", "BG", "ARDESIO", 1],
    ["A384", "CR", "ARDOLE SAN MARINO", 0],
    ["A385", "RC", "ARDORE", 1],
    ["A386", "VV", "ARENA", 1],
    ["A387", "PV", "ARENA", 0],
    ["A387", "PV", "ARENA PO", 1],
    ["A388", "GE", "ARENZANO", 1],
    ["A389", "MI", "ARESE", 1],
    ["A390", "AR", "AREZZO", 1],
    ["A391", "CO", "ARGEGNO", 1],
    ["A392", "BO", "ARGELATO", 1],
    ["A393", "FE", "ARGENTA", 1],
    ["A394", "CN", "ARGENTERA", 1],
    ["Z600", "EE", "ARGENTINA", 1],
    ["A395", "PV", "ARGINE", 0],
    ["A395", "PV", "ARGINE PO", 0],
    ["A396", "CN", "ARGUELLO", 1],
    ["A397", "CZ", "ARGUSTO", 1],
    ["A398", "CH", "ARI", 1],
    ["A399", "AV", "ARIANO", 0],
    ["A400", "RO", "ARIANO", 0],
    ["A399", "AV", "ARIANO DI PUGLIA", 0],
    ["A399", "AV", "ARIANO IRPINO", 1],
    ["A400", "RO", "ARIANO NEL POLESINE", 1],
    ["A401", "RM", "ARICCIA", 1],
    ["A402", "CH", "ARIELLI", 1],
    ["A403", "CE", "ARIENZO", 1],
    ["A404", "CE", "ARIENZO SANFELICE", 0],
    ["A405", "TO", "ARIGNANO", 1],
    ["A406", "AQ", "ARISCHIA", 0],
    ["A407", "NU", "ARITZO", 1],
    ["A408", "CA", "ARIXI", 0],
    ["A409", "NO", "ARIZZANO", 0],
    ["A409", "VB", "ARIZZANO", 1],
    ["A410", "NO", "ARIZZANO INFERIORE", 0],
    ["A411", "NO", "ARIZZANO SUPERIORE", 0],
    ["A412", "RM", "ARLENA", 0],
    ["A412", "VT", "ARLENA DI CASTRO", 1],
    ["A413", "MI", "ARLUNO", 1],
    ["Z137", "EE", "ARMENIA", 0],
    ["Z252", "EE", "ARMENIA", 1],
    ["A414", "NO", "ARMENO", 1],
    ["A415", "PZ", "ARMENTO", 1],
    ["A416", "VA", "ARMIO", 0],
    ["A417", "BS", "ARMO", 0],
    ["A418", "IM", "ARMO", 1],
    ["A419", "CA", "ARMUNGIA", 0],
    ["A419", "SU", "ARMUNGIA", 1],
    ["A424", "AO", "ARNAD", 1],
    ["A420", "TN", "ARNAGO", 0],
    ["A421", "FR", "ARNARA", 1],
    ["A422", "SV", "ARNASCO", 1],
    ["A423", "MI", "ARNATE", 0],
    ["A424", "AO", "ARNAZ", 0],
    ["A425", "LE", "ARNESANO", 1],
    ["A426", "TN", "ARNO", 0],
    ["A427", "VB", "AROLA", 1],
    ["A428", "VA", "AROLO", 0],
    ["A429", "NO", "ARONA", 1],
    ["A430", "CO", "AROSIO", 1],
    ["A431", "BN", "ARPAIA", 1],
    ["A432", "BN", "ARPAISE", 1],
    ["A433", "FR", "ARPINO", 1],
    ["A434", "PD", "ARQUA'", 0],
    ["A435", "RO", "ARQUA'", 0],
    ["A434", "PD", "ARQUA' PETRARCA", 1],
    ["A435", "RO", "ARQUA' POLESINE", 1],
    ["A436", "AL", "ARQUATA", 0],
    ["A437", "AP", "ARQUATA", 0],
    ["A437", "AP", "ARQUATA DEL TRONTO", 1],
    ["A436", "AL", "ARQUATA SCRIVIA", 1],
    ["A438", "PD", "ARRE", 1],
    ["A439", "TR", "ARRONE", 1],
    ["A440", "BG", "ARSAGO", 0],
    ["A441", "MI", "ARSAGO", 0],
    ["A441", "VA", "ARSAGO SEPRIO", 1],
    ["A442", "PL", "ARSIA", 0],
    ["A443", "BL", "ARSIE'", 1],
    ["A444", "VI", "ARSIERO", 1],
    ["A445", "TE", "ARSITA", 1],
    ["A446", "RM", "ARSOLI", 1],
    ["A447", "UD", "ARTA", 0],
    ["A447", "UD", "ARTA TERME", 1],
    ["A448", "UD", "ARTEGNA", 1],
    ["A449", "RM", "ARTENA", 1],
    ["A450", "NO", "ARTO'", 0],
    ["A451", "BS", "ARTOGNE", 1],
    ["A452", "AO", "ARVIE", 0],
    ["A452", "AO", "ARVIER", 1],
    ["A453", "SS", "ARZACHENA", 1],
    ["A440", "BG", "ARZAGO D'ADDA", 1],
    ["A454", "NU", "ARZANA", 1],
    ["A455", "NA", "ARZANO", 1],
    ["A456", "PN", "ARZENE", 0],
    ["A457", "IM", "ARZENO D'ONEGLIA", 0],
    ["A458", "PD", "ARZERGRANDE", 1],
    ["A459", "VI", "ARZIGNANO", 1],
    ["A460", "SA", "ASCEA", 1],
    ["A461", "SI", "ASCIANO", 1],
    ["A462", "AP", "ASCOLI", 0],
    ["A463", "FG", "ASCOLI", 0],
    ["A462", "AP", "ASCOLI PICENO", 1],
    ["A463", "FG", "ASCOLI SATRIANO", 1],
    ["A464", "RI", "ASCREA", 1],
    ["A465", "VI", "ASIAGO", 1],
    ["A466", "VC", "ASIGLIANO", 0],
    ["A467", "VI", "ASIGLIANO", 0],
    ["A467", "VI", "ASIGLIANO VENETO", 1],
    ["A466", "VC", "ASIGLIANO VERCELLESE", 1],
    ["A468", "SI", "ASINALUNGA", 0],
    ["A469", "CO", "ASNAGO", 0],
    ["A470", "MN", "ASOLA", 1],
    ["A471", "TV", "ASOLO", 1],
    ["A472", "RI", "ASPRA", 0],
    ["A472", "RI", "ASPRA SABINA", 0],
    ["A473", "MI", "ASSAGO", 1],
    ["A474", "CA", "ASSEMINI", 1],
    ["A475", "PG", "ASSISI", 1],
    ["A476", "CO", "ASSO", 1],
    ["A477", "OR", "ASSOLO", 1],
    ["A477", "CA", "ASSOLO NURECI", 0],
    ["A478", "EN", "ASSORO", 1],
    ["A479", "AT", "ASTI", 1],
    ["A480", "OR", "ASUNI", 1],
    ["A481", "AQ", "ATELETA", 1],
    ["A482", "PZ", "ATELLA", 1],
    ["A483", "CE", "ATELLA DI NAPOLI", 0],
    ["A484", "SA", "ATENA", 0],
    ["A484", "SA", "ATENA LUCANA", 1],
    ["A485", "CH", "ATESSA", 1],
    ["A486", "FR", "ATINA", 1],
    ["A487", "SA", "ATRANI", 1],
    ["A488", "TE", "ATRI", 1],
    ["A489", "AV", "ATRIPALDA", 1],
    ["A490", "TR", "ATTIGLIANO", 1],
    ["A491", "UD", "ATTIMIS", 1],
    ["A492", "NU", "ATZARA", 1],
    ["A493", "PU", "AUDITORE", 0],
    ["A494", "SR", "AUGUSTA", 1],
    ["A495", "SA", "AULETTA", 1],
    ["A496", "MS", "AULLA", 1],
    ["A497", "VB", "AURANO", 1],
    ["A498", "TS", "AUREMO DI SOPRA", 0],
    ["A499", "IM", "AURIGO", 1],
    ["A500", "TS", "AURISINA", 0],
    ["A501", "BL", "AURONZO", 0],
    ["A501", "BL", "AURONZO DI CADORE", 1],
    ["A502", "FR", "AUSONIA", 1],
    ["A503", "NU", "AUSTIS", 1],
    ["Z700", "EE", "AUSTRALIA", 1],
    ["Z102", "EE", "AUSTRIA", 1],
    ["A504", "NO", "AUZATE", 0],
    ["A505", "GO", "AUZZA", 0],
    ["A506", "GE", "AVEGNO", 1],
    ["A507", "BZ", "AVELENGO", 1],
    ["A507", "BZ", "AVELIGNA", 0],
    ["A508", "AV", "AVELLA", 1],
    ["A509", "AV", "AVELLINO", 1],
    ["A510", "BS", "AVENONE", 0],
    ["A511", "BG", "AVERARA", 1],
    ["A512", "CE", "AVERSA", 1],
    ["A513", "VR", "AVESA", 0],
    ["A514", "TA", "AVETRANA", 1],
    ["A515", "AQ", "AVEZZANO", 1],
    ["A516", "PN", "AVIANO", 1],
    ["A517", "BG", "AVIATICO", 1],
    ["A518", "TO", "AVIGLIANA", 1],
    ["A519", "PZ", "AVIGLIANO", 1],
    ["M258", "TR", "AVIGLIANO UMBRO", 1],
    ["A520", "TN", "AVIO", 1],
    ["A521", "AO", "AVISE", 1],
    ["A522", "SR", "AVOLA", 1],
    ["A523", "AL", "AVOLASCA", 1],
    ["A524", "TO", "AVUGLIONE E VERNONE", 0],
    ["A094", "AO", "AYAS", 1],
    ["A108", "AO", "AYMAVILLES", 1],
    ["A525", "TO", "AZEGLIO", 1],
    ["Z141", "EE", "AZERBAIGIAN", 0],
    ["Z253", "EE", "AZERBAIGIAN", 1],
    ["A526", "CR", "AZZANELLO", 1],
    ["A527", "AL", "AZZANO", 0],
    ["A528", "BG", "AZZANO", 0],
    ["A529", "BS", "AZZANO", 0],
    ["A530", "UD", "AZZANO", 0],
    ["A527", "AT", "AZZANO D'ASTI", 1],
    ["A530", "PN", "AZZANO DECIMO", 1],
    ["A527", "AL", "AZZANO DEL TANARO", 0],
    ["A529", "BS", "AZZANO MELLA", 1],
    ["A528", "BG", "AZZANO SAN PAOLO", 1],
    ["A531", "VA", "AZZATE", 1],
    ["A532", "VA", "AZZIO", 1],
    ["A533", "BG", "AZZONE", 1],
    ["A534", "VB", "BACENO", 1],
    ["A535", "NA", "BACOLI", 1],
    ["A445", "TE", "BACUCCO", 0],
    ["A536", "IM", "BADALUCCO", 1],
    ["M214", "SS", "BADESI", 1],
    ["A537", "BZ", "BADIA", 1],
    ["A538", "PV", "BADIA", 0],
    ["A539", "RO", "BADIA", 0],
    ["A540", "VR", "BADIA CALAVENA", 1],
    ["A538", "PV", "BADIA PAVESE", 1],
    ["A539", "RO", "BADIA POLESINE", 1],
    ["A541", "AR", "BADIA TEDALDA", 1],
    ["A542", "CZ", "BADOLATO", 1],
    ["A543", "CO", "BAGAGGERA", 0],
    ["A544", "RC", "BAGALADI", 1],
    ["A545", "MI", "BAGGIO", 0],
    ["A546", "PA", "BAGHERIA", 1],
    ["A547", "RA", "BAGNACAVALLO", 1],
    ["A548", "VT", "BAGNAIA", 0],
    ["A549", "BN", "BAGNARA", 0],
    ["A550", "PV", "BAGNARA", 0],
    ["A551", "RA", "BAGNARA", 0],
    ["A552", "RC", "BAGNARA", 0],
    ["A552", "RC", "BAGNARA CALABRA", 1],
    ["A549", "BN", "BAGNARA DI BENEVENTO", 0],
    ["A551", "RA", "BAGNARA DI ROMAGNA", 1],
    ["A550", "PV", "BAGNARIA", 1],
    ["A553", "UD", "BAGNARIA", 0],
    ["A553", "UD", "BAGNARIA ARSA", 1],
    ["A554", "CR", "BAGNAROLO", 0],
    ["A555", "CN", "BAGNASCO", 1],
    ["A556", "AL", "BAGNASCO D'ASTI", 0],
    ["A557", "BG", "BAGNATICA", 1],
    ["A558", "BO", "BAGNI DELLA PORRETTA", 0],
    ["A559", "PI", "BAGNI DI CASCIANA", 0],
    ["A560", "LU", "BAGNI DI LUCCA", 1],
    ["A561", "PT", "BAGNI DI MONTECATINI", 0],
    ["A562", "PI", "BAGNI SAN GIULIANO", 0],
    ["A563", "AQ", "BAGNO", 0],
    ["A560", "LU", "BAGNO A CORSENA", 0],
    ["A564", "FI", "BAGNO A RIPOLI", 1],
    ["A565", "FC", "BAGNO DI ROMAGNA", 1],
    ["A566", "AV", "BAGNOLI", 0],
    ["A567", "CB", "BAGNOLI", 0],
    ["A568", "PD", "BAGNOLI", 0],
    ["A567", "IS", "BAGNOLI DEL TRIGNO", 1],
    ["A568", "PD", "BAGNOLI DI SOPRA", 1],
    ["A566", "AV", "BAGNOLI IRPINO", 1],
    ["A569", "BS", "BAGNOLO", 0],
    ["A570", "CR", "BAGNOLO", 0],
    ["A571", "CN", "BAGNOLO", 0],
    ["A572", "LE", "BAGNOLO", 0],
    ["A573", "RE", "BAGNOLO", 0],
    ["A574", "RO", "BAGNOLO", 0],
    ["A570", "CR", "BAGNOLO CREMASCO", 1],
    ["A572", "LE", "BAGNOLO DEL SALENTO", 1],
    ["A574", "RO", "BAGNOLO DI PO", 1],
    ["A573", "RE", "BAGNOLO IN PIANO", 1],
    ["A569", "BS", "BAGNOLO MELLA", 1],
    ["A571", "CN", "BAGNOLO PIEMONTE", 1],
    ["A575", "MN", "BAGNOLO SAN VITO", 1],
    ["A576", "MS", "BAGNONE", 1],
    ["A577", "VT", "BAGNOREA", 0],
    ["A577", "VT", "BAGNOREGIO", 1],
    ["A578", "BS", "BAGOLINO", 1],
    ["Z502", "EE", "BAHAMA (ISOLE)", 0],
    ["Z502", "EE", "BAHAMAS", 1],
    ["Z204", "EE", "BAHREIN", 1],
    ["A579", "CE", "BAIA E LATINA", 1],
    ["A580", "AV", "BAIANO", 1],
    ["A581", "IM", "BAIARDO", 0],
    ["A582", "CO", "BAIEDO", 0],
    ["A583", "TO", "BAIO", 0],
    ["A583", "TO", "BAIO DORA", 0],
    ["A584", "TO", "BAIRO", 1],
    ["A585", "AO", "BAIRO TORRE", 0],
    ["A586", "RE", "BAISO", 1],
    ["A581", "IM", "BAJARDO", 1],
    ["A587", "TO", "BALANGERO", 1],
    ["M215", "TN", "BALBIDO", 0],
    ["A588", "AT", "BALDICHIERI D'ASTI", 1],
    ["A589", "CN", "BALDISSERO", 0],
    ["A590", "TO", "BALDISSERO", 0],
    ["A591", "TO", "BALDISSERO", 0],
    ["A590", "TO", "BALDISSERO CANAVESE", 1],
    ["A589", "CN", "BALDISSERO D'ALBA", 1],
    ["A591", "TO", "BALDISSERO TORINESE", 1],
    ["A592", "PA", "BALESTRATE", 1],
    ["A593", "SV", "BALESTRINO", 1],
    ["A594", "LC", "BALLABIO", 1],
    ["A595", "CO", "BALLABIO INFERIORE", 0],
    ["A596", "CO", "BALLABIO SUPERIORE", 0],
    ["A597", "CA", "BALLAO", 0],
    ["A597", "SU", "BALLAO", 1],
    ["A598", "VA", "BALLARATE", 0],
    ["M216", "TN", "BALLINO", 0],
    ["A599", "TO", "BALME", 1],
    ["A600", "VC", "BALMUCCIA", 1],
    ["A601", "VC", "BALOCCO", 1],
    ["A602", "MI", "BALSAMO", 0],
    ["A603", "AQ", "BALSORANO", 1],
    ["A604", "PZ", "BALVANO", 1],
    ["A605", "AL", "BALZOLA", 1],
    ["A606", "SS", "BANARI", 1],
    ["A607", "TO", "BANCHETTE", 1],
    ["A608", "TN", "BANCO", 0],
    ["Z249", "EE", "BANGLADESH", 1],
    ["A609", "CA", "BANNARI", 0],
    ["A609", "CA", "BANNARI DI USELLUS", 0],
    ["A610", "NO", "BANNIO", 0],
    ["A610", "VB", "BANNIO ANZINO", 1],
    ["A611", "SS", "BANTINE", 0],
    ["A612", "PZ", "BANZI", 1],
    ["A613", "PD", "BAONE", 1],
    ["A614", "OR", "BARADILI", 1],
    ["A615", "PZ", "BARAGIANO", 1],
    ["A616", "CB", "BARANELLO", 1],
    ["A617", "NA", "BARANO", 0],
    ["A617", "NA", "BARANO D'ISCHIA", 1],
    ["A618", "MI", "BARANZATE", 1],
    ["A619", "VA", "BARASSO", 1],
    ["A620", "MI", "BARATE", 0],
    ["A621", "CA", "BARATILI", 0],
    ["A621", "OR", "BARATILI SAN PIETRO", 1],
    ["A622", "TO", "BARATONIA", 0],
    ["Z522", "EE", "BARBADOS", 1],
    ["A623", "MI", "BARBAIANA", 0],
    ["A624", "PL", "BARBANA D'ISTRIA", 0],
    ["A625", "TO", "BARBANIA", 1],
    ["A626", "AN", "BARBARA", 1],
    ["A627", "VI", "BARBARANO", 0],
    ["A628", "VT", "BARBARANO", 0],
    ["M401", "VI", "BARBARANO MOSSANO", 1],
    ["A628", "VT", "BARBARANO ROMANO", 1],
    ["A627", "VI", "BARBARANO VICENTINO", 0],
    ["A629", "CN", "BARBARESCO", 1],
    ["A630", "BS", "BARBARIGA", 1],
    ["A631", "BG", "BARBATA", 1],
    ["A632", "FI", "BARBERINO DI MUGELLO", 1],
    ["M408", "FI", "BARBERINO TAVARNELLE", 1],
    ["A633", "FI", "BARBERINO VAL D'ELSA", 0],
    ["A634", "PV", "BARBIANELLO", 1],
    ["A635", "BZ", "BARBIANO", 1],
    ["A636", "CR", "BARBISELLE", 0],
    ["A637", "PD", "BARBONA", 1],
    ["A638", "ME", "BARCELLONA POZZO DI GOTTO", 1],
    ["M217", "TN", "BARCESINO", 0],
    ["A639", "PU", "BARCHI", 0],
    ["A640", "PN", "BARCIS", 1],
    ["A641", "BS", "BARCO", 0],
    ["A642", "CO", "BARCONE", 0],
    ["A643", "AO", "BARD", 1],
    ["A644", "TO", "BARDASSANO", 0],
    ["A645", "VA", "BARDELLO", 1],
    ["A646", "PR", "BARDI", 1],
    ["A647", "SV", "BARDINETO", 1],
    ["A648", "SV", "BARDINO NUOVO", 0],
    ["A649", "SV", "BARDINO VECCHIO", 0],
    ["A650", "VR", "BARDOLINO", 1],
    ["A651", "TO", "BARDONECCHIA", 1],
    ["A652", "MI", "BAREGGIO", 1],
    ["A653", "NO", "BARENGO", 1],
    ["A654", "BG", "BARESI", 0],
    ["A655", "OR", "BARESSA", 1],
    ["A656", "AQ", "BARETE", 1],
    ["A657", "LU", "BARGA", 1],
    ["A658", "GE", "BARGAGLI", 1],
    ["A659", "MI", "BARGANO", 0],
    ["A660", "CN", "BARGE", 1],
    ["A661", "BS", "BARGHE", 1],
    ["A662", "BA", "BARI", 1],
    ["A663", "CA", "BARI", 0],
    ["A662", "BA", "BARI DELLE PUGLIE", 0],
    ["A663", "NU", "BARI SARDO", 1],
    ["A664", "BG", "BARIANO", 1],
    ["A665", "BO", "BARICELLA", 1],
    ["A666", "PZ", "BARILE", 1],
    ["A667", "AQ", "BARISCIANO", 1],
    ["A668", "MB", "BARLASSINA", 1],
    ["A668", "MI", "BARLASSINA", 0],
    ["A669", "BA", "BARLETTA", 0],
    ["A669", "BT", "BARLETTA", 1],
    ["A670", "CO", "BARNI", 1],
    ["A671", "CN", "BAROLO", 1],
    ["A672", "PV", "BARONA", 0],
    ["A673", "TO", "BARONE CANAVESE", 1],
    ["A674", "SA", "BARONISSI", 1],
    ["A675", "NA", "BARRA", 0],
    ["A676", "EN", "BARRAFRANCA", 1],
    ["A677", "CA", "BARRALI", 0],
    ["A677", "SU", "BARRALI", 1],
    ["A678", "AQ", "BARREA", 1],
    ["A680", "CO", "BARTESATE", 0],
    ["A681", "CA", "BARUMINI", 0],
    ["A681", "SU", "BARUMINI", 1],
    ["A682", "VA", "BARZA", 0],
    ["A683", "LC", "BARZAGO", 1],
    ["A684", "BG", "BARZANA", 1],
    ["A685", "CR", "BARZANIGA", 0],
    ["A686", "LC", "BARZANO'", 1],
    ["A687", "LC", "BARZIO", 1],
    ["A679", "BG", "BARZIZZA", 0],
    ["A688", "VA", "BARZOLA", 0],
    ["A689", "AL", "BASALUZZO", 1],
    ["A690", "PV", "BASCAPE'", 1],
    ["A691", "TR", "BASCHI", 1],
    ["A692", "TE", "BASCIANO", 1],
    ["A693", "TN", "BASELGA", 0],
    ["A694", "TN", "BASELGA DI PINE'", 1],
    ["A693", "TN", "BASELGA DI VEZZANO", 0],
    ["A695", "PV", "BASELICA BOLOGNA", 0],
    ["A696", "BN", "BASELICE", 1],
    ["A697", "MI", "BASIANO", 1],
    ["A698", "ME", "BASICO'", 1],
    ["A699", "MI", "BASIGLIO", 1],
    ["A700", "UD", "BASILIANO", 1],
    ["A701", "VT", "BASSANELLO", 0],
    ["A702", "BS", "BASSANO", 0],
    ["A703", "VI", "BASSANO", 0],
    ["A702", "BS", "BASSANO BRESCIANO", 1],
    ["A703", "VI", "BASSANO DEL GRAPPA", 1],
    ["A704", "VT", "BASSANO DI SUTRI", 0],
    ["A705", "CO", "BASSANO DI TRONZANO", 0],
    ["A706", "VT", "BASSANO IN TEVERINA", 1],
    ["A704", "VT", "BASSANO ROMANO", 1],
    ["A707", "LT", "BASSIANO", 1],
    ["A708", "AL", "BASSIGNANA", 1],
    ["A709", "CN", "BASTIA", 0],
    ["A709", "CN", "BASTIA MONDOVI'", 1],
    ["A710", "PG", "BASTIA UMBRA", 1],
    ["A711", "PV", "BASTIDA DE' DOSSI", 0],
    ["A712", "PV", "BASTIDA PANCARANA", 1],
    ["A713", "MO", "BASTIGLIA", 1],
    ["Z303", "EE", "BASUTOLAND-SUD AFRICA BRITANNICO", 0],
    ["A714", "PD", "BATTAGLIA", 0],
    ["A715", "GO", "BATTAGLIA DELLA BAINSIZZA", 0],
    ["A714", "PD", "BATTAGLIA TERME", 1],
    ["A716", "CN", "BATTIFOLLO", 1],
    ["A717", "SA", "BATTIPAGLIA", 1],
    ["A718", "PV", "BATTUDA", 1],
    ["A719", "PA", "BAUCINA", 1],
    ["A720", "FR", "BAUCO", 0],
    ["A721", "OR", "BAULADU", 1],
    ["A722", "NU", "BAUNEI", 1],
    ["A723", "ME", "BAUSO", 0],
    ["A724", "GE", "BAVARI", 0],
    ["A725", "VB", "BAVENO", 1],
    ["A726", "BO", "BAZZANO", 0],
    ["A727", "TO", "BEAULARD", 0],
    ["Z304", "EE", "BECIUANIA-SUD AFRICA BRITANNICO", 0],
    ["A728", "VA", "BEDERO VALCUVIA", 1],
    ["A729", "BS", "BEDIZZOLE", 1],
    ["A730", "TN", "BEDOLLO", 1],
    ["A731", "PR", "BEDONIA", 1],
    ["A732", "BG", "BEDULITA", 1],
    ["A733", "VB", "BEE", 1],
    ["A734", "TO", "BEINASCO", 1],
    ["A735", "CN", "BEINETTE", 1],
    ["A767", "BS", "BEL PRATO", 0],
    ["A736", "CZ", "BELCASTRO", 1],
    ["A737", "VR", "BELFIORE", 1],
    ["A737", "VR", "BELFIORE DI PORCILE", 0],
    ["A738", "AL", "BELFORTE", 0],
    ["A739", "MC", "BELFORTE", 0],
    ["A740", "PU", "BELFORTE", 0],
    ["A740", "PU", "BELFORTE ALL'ISAURO", 1],
    ["A739", "MC", "BELFORTE DEL CHIENTI", 1],
    ["A739", "MC", "BELFORTE DEL CHIENTO", 0],
    ["A738", "AL", "BELFORTE MONFERRATO", 1],
    ["Z103", "EE", "BELGIO", 1],
    ["A741", "PV", "BELGIOIOSO", 1],
    ["A742", "VB", "BELGIRATE", 1],
    ["Z512", "EE", "BELIZE", 1],
    ["A743", "PZ", "BELLA", 1],
    ["A744", "CO", "BELLAGIO", 0],
    ["M335", "CO", "BELLAGIO", 1],
    ["A745", "LC", "BELLANO", 1],
    ["A746", "TE", "BELLANTE", 1],
    ["A747", "RN", "BELLARIA-IGEA MARINA", 1],
    ["A748", "CO", "BELLEDO", 0],
    ["A749", "RM", "BELLEGRA", 1],
    ["A750", "CN", "BELLINO", 1],
    ["A751", "MI", "BELLINZAGO", 0],
    ["A752", "NO", "BELLINZAGO", 0],
    ["A751", "MI", "BELLINZAGO LOMBARDO", 1],
    ["A752", "NO", "BELLINZAGO NOVARESE", 1],
    ["A753", "AV", "BELLIZZI", 0],
    ["M294", "SA", "BELLIZZI", 1],
    ["A753", "AV", "BELLIZZI IRPINO", 0],
    ["A754", "RO", "BELLOMBRA", 0],
    ["A755", "CE", "BELLONA", 1],
    ["A756", "SA", "BELLOSGUARDO", 1],
    ["A757", "BL", "BELLUNO", 1],
    ["A758", "VR", "BELLUNO VERONESE", 0],
    ["A759", "MB", "BELLUSCO", 1],
    ["A759", "MI", "BELLUSCO", 0],
    ["A760", "AP", "BELMONTE", 0],
    ["A761", "CB", "BELMONTE", 0],
    ["A762", "CS", "BELMONTE", 0],
    ["A763", "FR", "BELMONTE", 0],
    ["A764", "PA", "BELMONTE", 0],
    ["A765", "RI", "BELMONTE", 0],
    ["A762", "CS", "BELMONTE CALABRO", 1],
    ["A763", "FR", "BELMONTE CASTELLO", 1],
    ["A761", "IS", "BELMONTE DEL SANNIO", 1],
    ["A765", "RI", "BELMONTE IN SABINA", 1],
    ["A764", "PA", "BELMONTE MEZZAGNO", 1],
    ["A760", "AP", "BELMONTE PICENO", 0],
    ["A760", "FM", "BELMONTE PICENO", 1],
    ["A766", "CT", "BELPASSO", 1],
    ["A768", "CS", "BELSITO", 1],
    ["A769", "AN", "BELVEDERE", 0],
    ["A770", "AT", "BELVEDERE", 0],
    ["A771", "BO", "BELVEDERE", 0],
    ["A772", "CZ", "BELVEDERE", 0],
    ["A773", "CS", "BELVEDERE", 0],
    ["A774", "CN", "BELVEDERE", 0],
    ["A775", "PV", "BELVEDERE", 0],
    ["A775", "PV", "BELVEDERE AL PO", 0],
    ["A772", "KR", "BELVEDERE DI SPINELLO", 1],
    ["A774", "CN", "BELVEDERE LANGHE", 1],
    ["A773", "CS", "BELVEDERE MARITTIMO", 1],
    ["A769", "AN", "BELVEDERE OSTRENSE", 1],
    ["A770", "AT", "BELVEGLIO", 1],
    ["A776", "NU", "BELVI", 0],
    ["A776", "NU", "BELVI'", 1],
    ["A777", "SO", "BEMA", 1],
    ["A778", "CO", "BENE", 0],
    ["A779", "CN", "BENE", 0],
    ["A778", "CO", "BENE LARIO", 1],
    ["A779", "CN", "BENE VAGIENNA", 1],
    ["A780", "RC", "BENESTARE", 1],
    ["A781", "SS", "BENETUTTI", 1],
    ["A782", "CN", "BENEVELLO", 1],
    ["A783", "BN", "BENEVENTO", 1],
    ["Z314", "EE", "BENIN", 1],
    ["A784", "BI", "BENNA", 1],
    ["A785", "BO", "BENTIVOGLIO", 1],
    ["A786", "BG", "BERBENNO", 1],
    ["A787", "SO", "BERBENNO", 0],
    ["A787", "SO", "BERBENNO DI VALTELLINA", 1],
    ["A788", "PR", "BERCETO", 1],
    ["A789", "SS", "BERCHIDDA", 1],
    ["A790", "FM", "BERDO SAN GIOVANNI", 0],
    ["A791", "CO", "BEREGAZZO", 0],
    ["A791", "CO", "BEREGAZZO CON FIGLIARO", 1],
    ["A792", "PV", "BEREGUARDO", 1],
    ["A793", "AL", "BERGAMASCO", 1],
    ["A794", "BG", "BERGAMO", 1],
    ["A795", "RO", "BERGANTINO", 1],
    ["A796", "SV", "BERGEGGI", 1],
    ["A797", "GO", "BERGOGNA", 0],
    ["A798", "CN", "BERGOLO", 1],
    ["A799", "BS", "BERLINGO", 1],
    ["Z400", "EE", "BERMUDA (ISOLE)", 1],
    ["A800", "CO", "BERNAGA", 0],
    ["A801", "MT", "BERNALDA", 1],
    ["A802", "MB", "BERNAREGGIO", 1],
    ["A802", "MI", "BERNAREGGIO", 0],
    ["A803", "CO", "BERNATE", 0],
    ["A804", "MI", "BERNATE", 0],
    ["A803", "CO", "BERNATE DI COMO", 0],
    ["A803", "CO", "BERNATE ROSALES", 0],
    ["A804", "MI", "BERNATE TICINO", 1],
    ["A805", "CN", "BERNEZZO", 1],
    ["A806", "FE", "BERRA", 0],
    ["A807", "CN", "BERSEZIO", 0],
    ["A808", "TN", "BERSONE", 0],
    ["A809", "FC", "BERTINORO", 1],
    ["A810", "UD", "BERTIOLO", 1],
    ["A811", "LO", "BERTONICO", 1],
    ["A812", "AL", "BERZANO", 0],
    ["A813", "AL", "BERZANO", 0],
    ["A812", "AT", "BERZANO DI SAN PIETRO", 1],
    ["A813", "AL", "BERZANO DI TORTONA", 1],
    ["A815", "BG", "BERZO", 0],
    ["A816", "BS", "BERZO DEMO", 1],
    ["A817", "BS", "BERZO INFERIORE", 1],
    ["A815", "BG", "BERZO SAN FERMO", 1],
    ["A818", "MI", "BESANA", 0],
    ["A818", "MB", "BESANA IN BRIANZA", 1],
    ["A818", "MI", "BESANA IN BRIANZA", 0],
    ["A819", "VA", "BESANO", 1],
    ["A820", "MI", "BESATE", 1],
    ["A821", "TN", "BESENELLO", 1],
    ["A822", "TN", "BESENO", 0],
    ["A823", "PC", "BESENZONE", 1],
    ["A824", "FM", "BESERZIO DEL CARNARO", 0],
    ["A825", "VA", "BESNATE", 1],
    ["A826", "VA", "BESOZZO", 1],
    ["A827", "SS", "BESSUDE", 1],
    ["A828", "IM", "BESTAGNO", 0],
    ["A829", "MI", "BESTAZZO", 0],
    ["A830", "CR", "BETTENESCO", 0],
    ["A831", "PC", "BETTOLA", 1],
    ["A832", "PG", "BETTONA", 1],
    ["A833", "NO", "BEURA", 0],
    ["A834", "VB", "BEURA-CARDEZZA", 1],
    ["A835", "PG", "BEVAGNA", 1],
    ["A836", "SP", "BEVERINO", 1],
    ["A837", "VR", "BEVILACQUA", 1],
    ["A838", "VR", "BEVILACQUA BOSCHI", 0],
    ["A839", "TN", "BEZZECCA", 0],
    ["Z205", "EE", "BHUTAN", 1],
    ["A840", "TN", "BIACESA", 0],
    ["A841", "CT", "BIANCAVILLA", 1],
    ["A842", "CS", "BIANCHI", 1],
    ["A843", "RC", "BIANCO", 1],
    ["A844", "NO", "BIANDRATE", 1],
    ["A845", "VA", "BIANDRONNO", 1],
    ["A846", "BG", "BIANZANO", 1],
    ["A847", "VC", "BIANZE'", 1],
    ["A848", "SO", "BIANZONE", 1],
    ["A849", "MB", "BIASSONO", 1],
    ["A849", "MI", "BIASSONO", 0],
    ["A850", "RE", "BIBBIANO", 1],
    ["A851", "AR", "BIBBIENA", 1],
    ["A852", "LI", "BIBBONA", 1],
    ["A853", "TO", "BIBIANA", 1],
    ["A854", "FG", "BICCARI", 1],
    ["A855", "UD", "BICINICCO", 1],
    ["A856", "OR", "BIDONI'", 1],
    ["A857", "VT", "BIEDA", 0],
    ["A858", "VA", "BIEGNO", 0],
    ["A859", "BI", "BIELLA", 1],
    ["Z139", "EE", "BIELORUSSIA", 1],
    ["A860", "MI", "BIENATE", 0],
    ["A861", "BS", "BIENNO", 1],
    ["A862", "NO", "BIENO", 0],
    ["A863", "TN", "BIENO", 1],
    ["A864", "PI", "BIENTINA", 1],
    ["A865", "SV", "BIESTRO", 0],
    ["A866", "MN", "BIGARELLO", 0],
    ["A867", "GO", "BIGLIA", 0],
    ["A868", "GO", "BIGLIANA", 0],
    ["A869", "CO", "BIGLIO", 0],
    ["A870", "CO", "BINAGO", 1],
    ["A871", "CR", "BINANUOVA", 0],
    ["A872", "MI", "BINASCO", 1],
    ["A873", "CO", "BINDO", 0],
    ["A874", "BA", "BINETTO", 1],
    ["A875", "MI", "BINZAGO", 0],
    ["A876", "BI", "BIOGLIO", 1],
    ["A877", "AO", "BIONA", 0],
    ["A877", "AO", "BIONAZ", 1],
    ["A878", "BS", "BIONE", 1],
    ["A879", "MI", "BIRAGO", 0],
    ["Z206", "EE", "BIRMANIA", 0],
    ["A880", "NU", "BIRORI", 1],
    ["A881", "AV", "BISACCIA", 1],
    ["A882", "PA", "BISACQUINO", 1],
    ["A014", "RG", "BISCARI", 0],
    ["A883", "BA", "BISCEGLIE", 0],
    ["A883", "BT", "BISCEGLIE", 1],
    ["A884", "AQ", "BISEGNA", 1],
    ["A885", "TE", "BISENTI", 1],
    ["A886", "MI", "BISENTRATE", 0],
    ["A887", "CS", "BISIGNANO", 1],
    ["A888", "AL", "BISIO", 0],
    ["A889", "AL", "BISTAGNO", 1],
    ["A890", "FM", "BISTERZA", 0],
    ["A891", "VA", "BISUSCHIO", 1],
    ["A892", "BA", "BITETTO", 1],
    ["A893", "BA", "BITONTO", 1],
    ["A894", "BA", "BITRITTO", 1],
    ["A895", "NU", "BITTI", 1],
    ["A896", "AG", "BIVONA", 1],
    ["A897", "RC", "BIVONGI", 1],
    ["A898", "CO", "BIZZARONE", 1],
    ["A899", "VA", "BIZZOZERO", 0],
    ["A900", "TN", "BLEGGIO", 0],
    ["A901", "TN", "BLEGGIO INFERIORE", 0],
    ["A902", "TN", "BLEGGIO SUPERIORE", 1],
    ["A903", "BG", "BLELLO", 1],
    ["A857", "VT", "BLERA", 1],
    ["A904", "CO", "BLESSAGNO", 1],
    ["A905", "CO", "BLEVIO", 1],
    ["M268", "PA", "BLUFI", 1],
    ["A906", "PD", "BOARA", 0],
    ["A907", "RO", "BOARA", 0],
    ["A906", "PD", "BOARA PISANI", 1],
    ["A907", "RO", "BOARA POLESINE", 0],
    ["A908", "VA", "BOBBIATE", 0],
    ["A909", "PC", "BOBBIO", 1],
    ["A910", "TO", "BOBBIO PELLICE", 1],
    ["A911", "NO", "BOCA", 1],
    ["A912", "CS", "BOCCHIGLIERO", 1],
    ["A913", "PG", "BOCCHIGNANO", 0],
    ["A914", "VC", "BOCCIOLETO", 1],
    ["A915", "PR", "BOCCOLO DEI TASSI", 0],
    ["A916", "TN", "BOCENAGO", 1],
    ["A917", "VA", "BODIO", 0],
    ["A918", "VA", "BODIO LOMNAGO", 1],
    ["A919", "MI", "BOFFALORA", 0],
    ["A920", "MI", "BOFFALORA", 0],
    ["A919", "LO", "BOFFALORA D'ADDA", 1],
    ["A919", "MI", "BOFFALORA D'ADDA", 0],
    ["A920", "MI", "BOFFALORA SOPRA TICINO", 1],
    ["A921", "SO", "BOFFETTO", 0],
    ["A922", "GE", "BOGLIASCO", 1],
    ["A923", "GE", "BOGLIASCO PIEVE", 0],
    ["A924", "PL", "BOGLIUNO", 0],
    ["A925", "VB", "BOGNANCO", 1],
    ["A926", "NO", "BOGNANCO DENTRO", 0],
    ["A927", "NO", "BOGNANCO FUORI", 0],
    ["A928", "VA", "BOGNO", 0],
    ["A929", "NO", "BOGOGNO", 1],
    ["A930", "CB", "BOIANO", 0],
    ["A930", "CB", "BOIANO (corr. BOJANO)", 0],
    ["A931", "SV", "BOISSANO", 1],
    ["A930", "CB", "BOJANO", 1],
    ["A932", "SP", "BOLANO", 1],
    ["A933", "TN", "BOLBENO", 0],
    ["A934", "MI", "BOLDINASCO", 0],
    ["A935", "TN", "BOLENTINA", 0],
    ["A936", "NO", "BOLETO", 0],
    ["A937", "BG", "BOLGARE", 1],
    ["A938", "MI", "BOLGIANO", 0],
    ["Z601", "EE", "BOLIVIA", 1],
    ["A939", "MI", "BOLLADELLO", 0],
    ["A940", "MI", "BOLLATE", 1],
    ["A941", "TO", "BOLLENGO", 1],
    ["A942", "MT", "BOLLITA", 0],
    ["A943", "TN", "BOLLONE", 0],
    ["A944", "BO", "BOLOGNA", 1],
    ["A945", "PE", "BOLOGNANO", 1],
    ["A946", "PA", "BOLOGNETTA", 1],
    ["A947", "MC", "BOLOGNOLA", 1],
    ["A948", "NU", "BOLOTANA", 1],
    ["A949", "VT", "BOLSENA", 1],
    ["A950", "BG", "BOLTIERE", 1],
    ["A951", "GE", "BOLZANETO", 0],
    ["A952", "BZ", "BOLZANO", 1],
    ["A953", "NO", "BOLZANO", 0],
    ["A954", "VI", "BOLZANO", 0],
    ["A953", "NO", "BOLZANO NOVARESE", 1],
    ["A954", "VI", "BOLZANO VICENTINO", 1],
    ["A955", "VT", "BOMARZO", 1],
    ["A956", "CH", "BOMBA", 1],
    ["A957", "CL", "BOMPENSIERE", 1],
    ["A958", "PA", "BOMPIETRO", 1],
    ["A959", "MO", "BOMPORTO", 1],
    ["A960", "OR", "BONARCADO", 1],
    ["A961", "SP", "BONASSOLA", 1],
    ["A963", "BG", "BONATE SOPRA", 1],
    ["A962", "BG", "BONATE SOTTO", 1],
    ["A964", "VR", "BONAVIGO", 1],
    ["A965", "FE", "BONDENO", 1],
    ["A966", "BG", "BONDIONE", 0],
    ["A967", "TN", "BONDO", 0],
    ["A426", "TN", "BONDO BREGUZZO", 0],
    ["A969", "BG", "BONDO PETELLO", 0],
    ["A968", "TN", "BONDONE", 1],
    ["M218", "TN", "BONDONE STORO", 0],
    ["A970", "BN", "BONEA", 1],
    ["A971", "CB", "BONEFRO", 1],
    ["A972", "CR", "BONEMERSE", 1],
    ["A973", "CS", "BONIFATI", 1],
    ["A974", "MI", "BONIROLA", 0],
    ["A975", "AV", "BONITO", 1],
    ["A976", "SS", "BONNANARO", 1],
    ["A977", "SS", "BONO", 1],
    ["M219", "TN", "BONO", 0],
    ["A978", "SS", "BONORVA", 1],
    ["A979", "CN", "BONVICINO", 1],
    ["A980", "TO", "BONZO", 0],
    ["Z364", "EE", "BOPHUTHATSWANA", 0],
    ["A981", "RI", "BORBONA", 1],
    ["A982", "BL", "BORCA", 0],
    ["A982", "BL", "BORCA DI CADORE", 1],
    ["A983", "UD", "BORDANO", 1],
    ["A984", "IM", "BORDIGHERA", 1],
    ["A985", "BG", "BORDOGNA", 0],
    ["A986", "CR", "BORDOLANO", 1],
    ["A987", "PR", "BORE", 1],
    ["A988", "RE", "BORETTO", 1],
    ["A989", "PV", "BORGARELLO", 1],
    ["A990", "TO", "BORGARO TORINESE", 1],
    ["A991", "PA", "BORGETTO", 1],
    ["A992", "GE", "BORGHETTO", 0],
    ["A993", "IM", "BORGHETTO", 0],
    ["A994", "IM", "BORGHETTO", 0],
    ["A995", "MI", "BORGHETTO", 0],
    ["A996", "RI", "BORGHETTO", 0],
    ["A997", "TN", "BORGHETTO", 0],
    ["A993", "IM", "BORGHETTO D'ARROSCIA", 1],
    ["A998", "AL", "BORGHETTO DI BORBERA", 1],
    ["A992", "SP", "BORGHETTO DI VARA", 1],
    ["A995", "LO", "BORGHETTO LODIGIANO", 1],
    ["A994", "IM", "BORGHETTO SAN NICOLO'", 0],
    ["A999", "SV", "BORGHETTO SANTO SPIRITO", 1],
    ["B001", "FC", "BORGHI", 1],
    ["B002", "CZ", "BORGIA", 1],
    ["B003", "TO", "BORGIALLO", 1],
    ["B004", "SV", "BORGIO", 0],
    ["B005", "SV", "BORGIO VEREZZI", 1],
    ["B006", "TN", "BORGO", 0],
    ["B007", "LU", "BORGO A MOZZANO", 1],
    ["B007", "LU", "BORGO A MOZZANO E CERRETO DI SOTTO", 0],
    ["M352", "TN", "BORGO CHIESE", 1],
    ["B009", "VC", "BORGO D'ALE", 1],
    ["M429", "TN", "BORGO D'ANAUNIA", 1],
    ["B010", "BG", "BORGO DI TERZO", 1],
    ["M353", "TN", "BORGO LARES", 1],
    ["B017", "MI", "BORGO LITTORIO", 0],
    ["M396", "MN", "BORGO MANTOVANO", 1],
    ["B026", "PU", "BORGO PACE", 1],
    ["B027", "BO", "BORGO PANIGALE", 0],
    ["B028", "PV", "BORGO PRIOLO", 1],
    ["B032", "PC", "BORGO SAN BERNARDINO", 0],
    ["B033", "CN", "BORGO SAN DALMAZZO", 1],
    ["B034", "PR", "BORGO SAN DONNINO", 0],
    ["B035", "BS", "BORGO SAN GIACOMO", 1],
    ["B017", "LO", "BORGO SAN GIOVANNI", 1],
    ["B036", "FI", "BORGO SAN LORENZO", 1],
    ["B037", "AL", "BORGO SAN MARTINO", 1],
    ["B038", "PV", "BORGO SAN SIRO", 1],
    ["B039", "IM", "BORGO SANT'AGATA", 0],
    ["B043", "NO", "BORGO TICINO", 1],
    ["B044", "BO", "BORGO TOSSIGNANO", 1],
    ["B042", "PR", "BORGO VAL DI TARO", 1],
    ["M421", "BL", "BORGO VALBELLUNA", 1],
    ["B006", "TN", "BORGO VALSUGANA", 1],
    ["A996", "RI", "BORGO VELINO", 1],
    ["M402", "PD", "BORGO VENETO", 1],
    ["B046", "VC", "BORGO VERCELLI", 1],
    ["M340", "MN", "BORGO VIRGILIO", 1],
    ["M406", "MN", "BORGOCARBONARA", 1],
    ["B008", "RI", "BORGOCOLLEFEGATO", 0],
    ["B011", "MN", "BORGOFORTE", 0],
    ["B012", "MN", "BORGOFORTE A DESTRA", 0],
    ["B013", "MN", "BORGOFRANCO", 0],
    ["B014", "PV", "BORGOFRANCO", 0],
    ["B015", "TO", "BORGOFRANCO", 0],
    ["B015", "TO", "BORGOFRANCO D'IVREA", 1],
    ["B013", "MN", "BORGOFRANCO SUL PO", 0],
    ["B016", "NO", "BORGOLAVEZZARO", 1],
    ["B018", "CN", "BORGOMALE", 1],
    ["B019", "NO", "BORGOMANERO", 1],
    ["B020", "IM", "BORGOMARO", 1],
    ["B021", "TO", "BORGOMASINO", 1],
    ["M370", "VB", "BORGOMEZZAVALLE", 1],
    ["B022", "BS", "BORGONATO", 0],
    ["B023", "NO", "BORGONE", 0],
    ["B024", "TO", "BORGONE", 0],
    ["B023", "NO", "BORGONE D'OSSOLA", 0],
    ["B024", "TO", "BORGONE SUSA", 1],
    ["B025", "PC", "BORGONOVO VAL TIDONE", 1],
    ["A831", "PC", "BORGONURE", 0],
    ["B029", "AL", "BORGORATTO", 0],
    ["B030", "PV", "BORGORATTO", 0],
    ["B029", "AL", "BORGORATTO ALESSANDRINO", 1],
    ["B030", "PV", "BORGORATTO MORMOROLO", 1],
    ["B031", "PD", "BORGORICCO", 1],
    ["B008", "RI", "BORGOROSE", 1],
    ["B040", "BS", "BORGOSATOLLO", 1],
    ["B041", "VC", "BORGOSESIA", 1],
    ["B042", "PR", "BORGOTARO", 0],
    ["B045", "BG", "BORGOUNITO", 0],
    ["B047", "GO", "BORIANO", 0],
    ["B048", "SV", "BORMIDA", 1],
    ["B049", "SO", "BORMIO", 1],
    ["B050", "MI", "BORNAGO", 0],
    ["B051", "PV", "BORNASCO", 1],
    ["B052", "VC", "BORNATE", 0],
    ["B053", "BS", "BORNATO", 0],
    ["B054", "BS", "BORNO", 1],
    ["B055", "OR", "BORONEDDU", 1],
    ["B056", "NU", "BORORE", 1],
    ["B057", "CH", "BORRELLO", 1],
    ["B058", "BI", "BORRIANA", 1],
    ["B059", "MI", "BORSANO", 0],
    ["B060", "RO", "BORSEA", 0],
    ["B061", "TV", "BORSO", 0],
    ["B061", "TV", "BORSO DEL GRAPPA", 1],
    ["B062", "NU", "BORTIGALI", 1],
    ["B063", "SS", "BORTIGIADAS", 1],
    ["B064", "SS", "BORUTTA", 1],
    ["B065", "TN", "BORZAGO", 0],
    ["B066", "GE", "BORZOLI", 0],
    ["B067", "GE", "BORZONASCA", 1],
    ["B068", "NU", "BOSA", 0],
    ["B068", "OR", "BOSA", 1],
    ["B069", "RO", "BOSARO", 1],
    ["B070", "VR", "BOSCHI SANT'ANNA", 1],
    ["A598", "CO", "BOSCO", 0],
    ["B071", "AL", "BOSCO", 0],
    ["B072", "CO", "BOSCO", 0],
    ["B073", "VR", "BOSCO CHIESANUOVA", 1],
    ["A598", "CO", "BOSCO DI GAVIRATE", 0],
    ["B071", "AL", "BOSCO MARENGO", 1],
    ["B072", "VA", "BOSCO VALTRAVAGLIA", 0],
    ["B074", "IM", "BOSCOMARE", 0],
    ["B075", "TO", "BOSCONERO", 1],
    ["B076", "NA", "BOSCOREALE", 1],
    ["B077", "NA", "BOSCOTRECASE", 1],
    ["B078", "TN", "BOSENTINO", 0],
    ["B079", "CN", "BOSIA", 1],
    ["B080", "AL", "BOSIO", 1],
    ["B081", "CO", "BOSISIO", 0],
    ["B081", "LC", "BOSISIO PARINI", 1],
    ["B082", "PV", "BOSNASCO", 1],
    ["Z153", "EE", "BOSNIA ED ERZEGOVINA", 1],
    ["B083", "BG", "BOSSICO", 1],
    ["B084", "CN", "BOSSOLASCO", 1],
    ["B085", "CZ", "BOTRICELLO", 1],
    ["B086", "LE", "BOTRUGNO", 1],
    ["Z358", "EE", "BOTSWANA", 1],
    ["B087", "CR", "BOTTAIANO", 0],
    ["B088", "BG", "BOTTANUCO", 1],
    ["B089", "PV", "BOTTARONE", 0],
    ["B090", "MI", "BOTTEDO", 0],
    ["B091", "BS", "BOTTICINO", 1],
    ["B092", "BS", "BOTTICINO MATTINA", 0],
    ["B093", "BS", "BOTTICINO SERA", 0],
    ["B094", "SS", "BOTTIDDA", 1],
    ["B095", "RO", "BOTTRIGHE", 0],
    ["B096", "TO", "BOUSSON", 0],
    ["B097", "RC", "BOVA", 1],
    ["B099", "RC", "BOVA MARINA", 1],
    ["B098", "RC", "BOVALINO", 1],
    ["B100", "BS", "BOVEGNO", 1],
    ["B101", "CN", "BOVES", 1],
    ["B102", "BS", "BOVEZZO", 1],
    ["B103", "TO", "BOVILE", 0],
    ["M299", "RM", "BOVILLE", 0],
    ["A720", "FR", "BOVILLE ERNICA", 1],
    ["B104", "FG", "BOVINO", 1],
    ["B105", "MI", "BOVISIO", 0],
    ["B105", "MB", "BOVISIO-MASCIAGO", 1],
    ["B105", "MI", "BOVISIO-MASCIAGO", 0],
    ["B106", "PD", "BOVOLENTA", 1],
    ["B107", "VR", "BOVOLONE", 1],
    ["B108", "TN", "BOZZANA", 0],
    ["B109", "AL", "BOZZOLE", 1],
    ["B110", "MN", "BOZZOLO", 1],
    ["B111", "CN", "BRA", 1],
    ["B112", "BG", "BRACCA", 1],
    ["B113", "BG", "BRACCA DI COSTA SERINA", 0],
    ["B114", "RM", "BRACCIANO", 1],
    ["B115", "SA", "BRACIGLIANO", 1],
    ["B116", "BZ", "BRAIES", 1],
    ["B117", "PV", "BRALLO DI PREGOLA", 1],
    ["B118", "RC", "BRANCALEONE", 1],
    ["B119", "CR", "BRANCERE", 0],
    ["M220", "TN", "BRANCOLINO", 0],
    ["B120", "BS", "BRANDICO", 1],
    ["B121", "TO", "BRANDIZZO", 1],
    ["B122", "PV", "BRANDUZZO", 0],
    ["B123", "BG", "BRANZI", 1],
    ["B124", "BS", "BRAONE", 1],
    ["Z602", "EE", "BRASILE", 1],
    ["B125", "GO", "BRAZZANO", 0],
    ["B126", "VA", "BREBBIA", 1],
    ["B127", "CO", "BRECCIA", 0],
    ["B128", "TV", "BREDA", 0],
    ["B129", "CR", "BREDA DE' BUGNI", 0],
    ["B128", "TV", "BREDA DI PIAVE", 1],
    ["B130", "CR", "BREDA GUAZZONE", 0],
    ["B131", "VA", "BREGANO", 1],
    ["B132", "VI", "BREGANZE", 1],
    ["B133", "CO", "BREGLIA", 0],
    ["B134", "CO", "BREGNANO", 1],
    ["B135", "TN", "BREGUZZO", 0],
    ["B136", "VC", "BREIA", 0],
    ["B137", "BG", "BREMBATE", 1],
    ["B138", "BG", "BREMBATE DI SOPRA", 1],
    ["B139", "BG", "BREMBATE DI SOTTO", 0],
    ["B140", "BG", "BREMBILLA", 0],
    ["B141", "LO", "BREMBIO", 1],
    ["B142", "PV", "BREME", 1],
    ["B143", "VI", "BRENDOLA", 1],
    ["B144", "CO", "BRENNA", 1],
    ["B145", "BZ", "BRENNERO", 1],
    ["B146", "CO", "BRENNO", 0],
    ["B147", "CO", "BRENNO", 0],
    ["B147", "CO", "BRENNO DELLA TORRE", 0],
    ["B146", "VA", "BRENNO USERIA", 0],
    ["B148", "BG", "BRENO", 0],
    ["B149", "BS", "BRENO", 1],
    ["B150", "VA", "BRENTA", 1],
    ["B151", "VR", "BRENTINO", 0],
    ["B152", "VR", "BRENTINO BELLUNO", 1],
    ["B153", "TN", "BRENTONICO", 1],
    ["B154", "VR", "BRENZONE", 0],
    ["B154", "VR", "BRENZONE SUL GARDA", 1],
    ["B155", "VR", "BREONIO", 0],
    ["B156", "RE", "BRESCELLO", 1],
    ["B157", "BS", "BRESCIA", 1],
    ["B158", "TN", "BRESIMO", 1],
    ["A395", "PV", "BRESSANA", 0],
    ["B159", "PV", "BRESSANA BOTTARONE", 1],
    ["B160", "BZ", "BRESSANONE", 1],
    ["B161", "VI", "BRESSANVIDO", 1],
    ["B162", "MI", "BRESSO", 1],
    ["B163", "GO", "BRESTOVIZZA IN VALLE", 0],
    ["B164", "GO", "BRETTO", 0],
    ["B165", "TN", "BREZ", 0],
    ["B166", "VA", "BREZZO DI BEDERO", 1],
    ["B167", "CN", "BRIAGLIA", 1],
    ["B168", "CO", "BRIANZOLA", 0],
    ["B169", "VV", "BRIATICO", 1],
    ["B170", "MI", "BRIAVACCA", 0],
    ["B171", "TO", "BRICHERASIO", 1],
    ["B172", "CO", "BRIENNO", 1],
    ["B173", "PZ", "BRIENZA", 1],
    ["B174", "CN", "BRIGA", 0],
    ["B175", "CN", "BRIGA ALTA", 1],
    ["B174", "CN", "BRIGA MARITTIMA", 0],
    ["B176", "NO", "BRIGA NOVARESE", 1],
    ["B177", "AL", "BRIGNANO", 0],
    ["B178", "BG", "BRIGNANO", 0],
    ["B177", "AL", "BRIGNANO DEL CURONE", 0],
    ["B178", "BG", "BRIGNANO GERA D'ADDA", 1],
    ["B179", "AL", "BRIGNANO-FRASCATA", 1],
    ["B180", "BR", "BRINDISI", 1],
    ["B181", "PZ", "BRINDISI", 0],
    ["B181", "PZ", "BRINDISI MONTAGNA", 1],
    ["B182", "VA", "BRINZIO", 1],
    ["B183", "NO", "BRIONA", 1],
    ["B184", "BS", "BRIONE", 1],
    ["B185", "TN", "BRIONE", 0],
    ["B186", "PL", "BRIONI MAGGIORE", 0],
    ["B187", "MB", "BRIOSCO", 1],
    ["B187", "MI", "BRIOSCO", 0],
    ["B188", "RA", "BRISIGHELLA", 1],
    ["B189", "NO", "BRISINO", 0],
    ["B190", "VA", "BRISSAGO", 0],
    ["B191", "VA", "BRISSAGO-VALTRAVAGLIA", 1],
    ["B192", "AO", "BRISSOGNE", 1],
    ["B193", "PE", "BRITTOLI", 1],
    ["B194", "LC", "BRIVIO", 1],
    ["B195", "FR", "BROCCO", 0],
    ["B195", "FR", "BROCCOSTELLA", 1],
    ["B196", "VI", "BROGLIANO", 1],
    ["B197", "VV", "BROGNATURO", 1],
    ["B198", "ME", "BROLO", 1],
    ["B199", "CR", "BROLPASINO", 0],
    ["B200", "CN", "BRONDELLO", 1],
    ["B201", "PV", "BRONI", 1],
    ["B202", "CT", "BRONTE", 1],
    ["B203", "BZ", "BRONZOLO", 1],
    ["B204", "CN", "BROSSASCO", 1],
    ["B205", "TO", "BROSSO", 1],
    ["B206", "NO", "BROVELLO", 0],
    ["B207", "VB", "BROVELLO-CARPUGNINO", 1],
    ["B208", "SV", "BROVIDA", 0],
    ["B209", "TO", "BROZOLO", 1],
    ["B210", "FI", "BROZZI", 0],
    ["B211", "BS", "BROZZO", 0],
    ["B212", "MB", "BRUGHERIO", 1],
    ["B212", "MI", "BRUGHERIO", 0],
    ["B213", "PD", "BRUGINE", 1],
    ["B214", "SP", "BRUGNATO", 1],
    ["B215", "PN", "BRUGNERA", 1],
    ["B216", "TO", "BRUINO", 1],
    ["B217", "BG", "BRUMANO", 1],
    ["B218", "CO", "BRUNATE", 1],
    ["Z207", "EE", "BRUNEI", 1],
    ["B219", "VA", "BRUNELLO", 1],
    ["B220", "BZ", "BRUNICO", 1],
    ["B221", "AT", "BRUNO", 1],
    ["B222", "BG", "BRUNTINO", 0],
    ["B223", "BG", "BRUSAPORTO", 1],
    ["B224", "AL", "BRUSASCHETTO", 0],
    ["B225", "TO", "BRUSASCO", 1],
    ["B226", "TO", "BRUSASCO CAVAGNOLO", 0],
    ["B227", "NA", "BRUSCIANO", 1],
    ["B228", "VA", "BRUSIMPIANO", 1],
    ["M221", "TN", "BRUSINO", 0],
    ["B229", "BI", "BRUSNENGO", 1],
    ["B230", "AO", "BRUSSON", 1],
    ["B230", "AO", "BRUSSONE", 0],
    ["B231", "MI", "BRUSUGLIO", 0],
    ["B232", "TO", "BRUZOLO", 1],
    ["B233", "MI", "BRUZZANO", 0],
    ["B234", "RC", "BRUZZANO", 0],
    ["B233", "MI", "BRUZZANO DEI DUE BORGHI", 0],
    ["B234", "RC", "BRUZZANO ZEFFIRIO", 1],
    ["B235", "MI", "BUBBIANO", 1],
    ["B236", "AT", "BUBBIO", 1],
    ["B237", "SR", "BUCCHERI", 1],
    ["B238", "CH", "BUCCHIANICO", 1],
    ["B239", "BN", "BUCCIANO", 1],
    ["B240", "MI", "BUCCINASCO", 1],
    ["B241", "CO", "BUCCINIGO", 0],
    ["B242", "SA", "BUCCINO", 1],
    ["B243", "AR", "BUCINE", 1],
    ["B244", "TS", "BUCUIE", 0],
    ["B245", "GO", "BUDAGNE", 0],
    ["B246", "SS", "BUDDUSO'", 1],
    ["B247", "PN", "BUDOIA", 1],
    ["B248", "NU", "BUDONI", 0],
    ["B248", "SS", "BUDONI", 1],
    ["B249", "BO", "BUDRIO", 1],
    ["B250", "CA", "BUGGERRU", 0],
    ["B250", "SU", "BUGGERRU", 1],
    ["B251", "PT", "BUGGIANO", 1],
    ["B252", "CO", "BUGGIOLO", 0],
    ["B253", "CO", "BUGIALLO", 0],
    ["B254", "NO", "BUGLIO", 0],
    ["B255", "SO", "BUGLIO", 0],
    ["B255", "SO", "BUGLIO IN MONTE", 1],
    ["B256", "AQ", "BUGNARA", 1],
    ["B257", "NO", "BUGNATE", 0],
    ["B258", "VA", "BUGUGGIATE", 1],
    ["B260", "PL", "BUIE D'ISTRIA", 0],
    ["B259", "UD", "BUJA", 1],
    ["B259", "UD", "BUJA ( corretto BUIA)", 0],
    ["B261", "LC", "BULCIAGO", 1],
    ["Z104", "EE", "BULGARIA", 1],
    ["B262", "CO", "BULGAROGRASSO", 1],
    ["B263", "CO", "BULGORELLO", 0],
    ["B264", "SS", "BULTEI", 1],
    ["B265", "SS", "BULZI", 1],
    ["A958", "PA", "BUOMPIETRO", 0],
    ["B266", "SA", "BUONABITACOLO", 1],
    ["B267", "BN", "BUONALBERGO", 1],
    ["B268", "CH", "BUONANOTTE", 0],
    ["B269", "SI", "BUONCONVENTO", 1],
    ["B270", "CS", "BUONVICINO", 1],
    ["B271", "BS", "BURAGO", 0],
    ["B272", "MI", "BURAGO", 0],
    ["B272", "MB", "BURAGO DI MOLGORA", 1],
    ["B272", "MI", "BURAGO DI MOLGORA", 0],
    ["B271", "BS", "BURAGO RIVIERA", 0],
    ["B273", "VE", "BURANO", 0],
    ["B274", "CA", "BURCEI", 0],
    ["B274", "SU", "BURCEI", 1],
    ["B275", "AG", "BURGIO", 1],
    ["B276", "SS", "BURGOS", 1],
    ["B277", "BZ", "BURGUSIO", 0],
    ["B278", "TO", "BURIASCO", 1],
    ["Z354", "EE", "BURKINA", 0],
    ["Z354", "EE", "BURKINA FASO", 1],
    ["B279", "TO", "BUROLO", 1],
    ["B280", "VC", "BURONZO", 1],
    ["Z305", "EE", "BURUNDI", 1],
    ["B281", "OR", "BUSACHI", 1],
    ["B282", "GE", "BUSALLA", 1],
    ["B283", "RE", "BUSANA", 0],
    ["B284", "TO", "BUSANO", 1],
    ["B285", "CN", "BUSCA", 1],
    ["B286", "MI", "BUSCATE", 1],
    ["B287", "SR", "BUSCEMI", 1],
    ["B288", "TP", "BUSETO PALIZZOLO", 1],
    ["B289", "MB", "BUSNAGO", 1],
    ["B289", "MI", "BUSNAGO", 0],
    ["B290", "RO", "BUSO", 0],
    ["B290", "RO", "BUSO SARZANO", 0],
    ["B291", "IM", "BUSSANA", 0],
    ["B292", "MI", "BUSSERO", 1],
    ["B293", "PR", "BUSSETO", 1],
    ["B294", "AQ", "BUSSI", 0],
    ["B294", "PE", "BUSSI SUL TIRINO", 1],
    ["B295", "CB", "BUSSO", 1],
    ["B296", "VR", "BUSSOLENGO", 1],
    ["B297", "TO", "BUSSOLENO", 1],
    ["B298", "TO", "BUSSOLINO", 0],
    ["B298", "TO", "BUSSOLINO GASSINESE", 0],
    ["B299", "MI", "BUSTIGHERA", 0],
    ["B300", "VA", "BUSTO ARSIZIO", 1],
    ["B301", "MI", "BUSTO GAROLFO", 1],
    ["B302", "CL", "BUTERA", 1],
    ["B303", "PI", "BUTI", 1],
    ["B304", "VR", "BUTTAPIETRA", 1],
    ["B305", "TO", "BUTTIGLIERA", 0],
    ["B305", "TO", "BUTTIGLIERA ALTA", 1],
    ["B306", "AT", "BUTTIGLIERA D'ASTI", 1],
    ["B307", "PV", "BUTTIRAGO", 0],
    ["B308", "NO", "BUTTOGNO", 0],
    ["B309", "UD", "BUTTRIO", 1],
    ["B310", "BG", "BUZZONE SAN PAOLO", 0],
    ["B320", "CR", "CA' D'ANDREA", 0],
    ["B322", "CR", "CA' DE' BONAVOGLI", 0],
    ["B323", "CR", "CA' DE' CAGGI", 0],
    ["B324", "CR", "CA' DE' CORTI", 0],
    ["B331", "MI", "CA' DE' MAZZI", 0],
    ["B333", "CR", "CA' DE' QUINZANI", 0],
    ["B336", "CR", "CA' DE' SFONDRATI", 0],
    ["B337", "CR", "CA' DE' SORESINI", 0],
    ["B338", "CR", "CA' DE' STAOLI", 0],
    ["B339", "CR", "CA' DE' STEFANI", 0],
    ["B340", "PV", "CA' DE' TEDIOLI", 0],
    ["B341", "MI", "CA' DE' ZECCHI", 0],
    ["B327", "MI", "CA' DEI BOLLI", 0],
    ["B329", "MI", "CA' DELL'ACQUA", 0],
    ["B330", "PV", "CA' DELLA TERRA", 0],
    ["B342", "VR", "CA' DI DAVID", 0],
    ["B348", "RO", "CA' EMO", 0],
    ["B930", "GO", "CA' SANTO SPIRITO DELLA BAINSIZZA", 0],
    ["B311", "AL", "CABELLA LIGURE", 1],
    ["B312", "VA", "CABIAGLIO", 0],
    ["B313", "CO", "CABIATE", 1],
    ["B314", "OR", "CABRAS", 1],
    ["B315", "PA", "CACCAMO", 1],
    ["B316", "BS", "CACCAVERO", 0],
    ["B317", "CB", "CACCAVONE", 0],
    ["B318", "TS", "CACCIA", 0],
    ["B319", "KR", "CACCURI", 1],
    ["B321", "NO", "CADDO", 0],
    ["B325", "VA", "CADEGLIANO", 0],
    ["B326", "VA", "CADEGLIANO-VICONAGO", 1],
    ["B328", "RE", "CADELBOSCO DI SOPRA", 1],
    ["B332", "PC", "CADEO", 1],
    ["B334", "VA", "CADERO CON GRAGLIO", 0],
    ["B335", "TN", "CADERZONE", 0],
    ["B335", "TN", "CADERZONE TERME", 1],
    ["B343", "BS", "CADIGNANO", 0],
    ["B344", "TN", "CADINE", 0],
    ["B345", "PD", "CADONEGHE", 1],
    ["B346", "CO", "CADORAGO", 1],
    ["B347", "VA", "CADREZZATE", 0],
    ["M425", "VA", "CADREZZATE CON OSMATE", 1],
    ["B349", "TV", "CAERANO", 0],
    ["B349", "TV", "CAERANO DI SAN MARCO", 1],
    ["B350", "TO", "CAFASSE", 1],
    ["B351", "SA", "CAGGIANO", 1],
    ["B352", "PU", "CAGLI", 1],
    ["B353", "CO", "CAGLIANO", 0],
    ["B354", "CA", "CAGLIARI", 1],
    ["B355", "CO", "CAGLIO", 1],
    ["B356", "GE", "CAGNA", 0],
    ["B357", "FG", "CAGNANO", 0],
    ["B358", "AQ", "CAGNANO", 0],
    ["B358", "AQ", "CAGNANO AMITERNO", 1],
    ["B357", "FG", "CAGNANO VARANO", 1],
    ["B359", "CO", "CAGNO", 0],
    ["B360", "TN", "CAGNO'", 0],
    ["B361", "CE", "CAIANELLO", 1],
    ["B362", "CE", "CAIAZZO", 1],
    ["B363", "MI", "CAIDATE", 0],
    ["B364", "BZ", "CAINES", 1],
    ["B365", "BS", "CAINO", 1],
    ["B366", "SO", "CAIOLO", 1],
    ["B367", "AV", "CAIRANO", 1],
    ["B368", "VA", "CAIRATE", 1],
    ["B369", "GE", "CAIRO", 0],
    ["B370", "PV", "CAIRO", 0],
    ["B370", "PV", "CAIRO LOMELLINO", 0],
    ["B369", "SV", "CAIRO MONTENOTTE", 1],
    ["B371", "NA", "CAIVANO", 1],
    ["B372", "MI", "CAJELLO", 0],
    ["B373", "BS", "CAJONVICO", 0],
    ["B401", "GO", "CAL DI CANALE", 0],
    ["B374", "AV", "CALABRITTO", 1],
    ["B375", "BL", "CALALZO", 0],
    ["B375", "BL", "CALALZO DI CADORE", 1],
    ["B376", "AT", "CALAMANDRANA", 1],
    ["B377", "AG", "CALAMONACI", 1],
    ["B378", "SS", "CALANGIANUS", 1],
    ["B379", "RC", "CALANNA", 1],
    ["B380", "NO", "CALASCA", 0],
    ["B380", "VB", "CALASCA-CASTIGLIONE", 1],
    ["B380", "EN", "CALASCIBETTA", 0],
    ["B381", "EN", "CALASCIBETTA", 1],
    ["B381", "AQ", "CALASCIO", 0],
    ["B382", "AQ", "CALASCIO", 1],
    ["B382", "CA", "CALASETTA", 0],
    ["B383", "CA", "CALASETTA", 0],
    ["B383", "SU", "CALASETTA", 1],
    ["B383", "CT", "CALATABIANO", 0],
    ["B384", "CT", "CALATABIANO", 1],
    ["B384", "TP", "CALATAFIMI", 0],
    ["B385", "TP", "CALATAFIMI", 0],
    ["B385", "TP", "CALATAFIMI SEGESTA", 0],
    ["B385", "TP", "CALATAFIMI-SEGESTA", 1],
    ["B385", "TN", "CALAVINO", 0],
    ["B386", "TN", "CALAVINO", 0],
    ["B386", "PV", "CALCABABBIO", 0],
    ["B387", "VT", "CALCATA", 0],
    ["B388", "VT", "CALCATA", 1],
    ["B388", "TN", "CALCERANICA", 0],
    ["B389", "TN", "CALCERANICA AL LAGO", 1],
    ["B389", "PI", "CALCI", 0],
    ["B390", "PI", "CALCI", 1],
    ["B390", "MT", "CALCIANO", 0],
    ["B391", "MT", "CALCIANO", 1],
    ["B391", "PI", "CALCINAIA", 0],
    ["B392", "PI", "CALCINAIA", 1],
    ["B392", "BG", "CALCINATE", 0],
    ["B393", "BG", "CALCINATE", 1],
    ["B393", "BS", "CALCINATO", 0],
    ["B394", "BS", "CALCINATO", 1],
    ["B394", "BG", "CALCIO", 0],
    ["B395", "BG", "CALCIO", 1],
    ["B396", "LC", "CALCO", 1],
    ["B396", "BZ", "CALDARO", 0],
    ["B397", "BZ", "CALDARO SULLA STRADA DEL VINO", 1],
    ["B397", "MC", "CALDAROLA", 0],
    ["B398", "MC", "CALDAROLA", 1],
    ["B398", "BO", "CALDERARA", 0],
    ["B399", "BO", "CALDERARA DI RENO", 1],
    ["B399", "TN", "CALDES", 0],
    ["B400", "TN", "CALDES", 1],
    ["B400", "VR", "CALDIERO", 0],
    ["B402", "VR", "CALDIERO", 1],
    ["B402", "VI", "CALDOGNO", 0],
    ["B403", "VI", "CALDOGNO", 1],
    ["B403", "TN", "CALDONAZZO", 0],
    ["B404", "TN", "CALDONAZZO", 1],
    ["B404", "PC", "CALENDASCO", 0],
    ["B405", "PC", "CALENDASCO", 1],
    ["B405", "FI", "CALENZANO", 0],
    ["B406", "FI", "CALENZANO", 1],
    ["B406", "BG", "CALEPIO", 0],
    ["B407", "PR", "CALESTANO", 0],
    ["B408", "PR", "CALESTANO", 1],
    ["B408", "GE", "CALICE", 0],
    ["B409", "MS", "CALICE", 0],
    ["B410", "SP", "CALICE AL CORNOVIGLIO", 1],
    ["B409", "SV", "CALICE LIGURE", 1],
    ["B410", "SV", "CALICE LIGURE", 0],
    ["B409", "NO", "CALICE OSSOLANO", 0],
    ["B411", "PV", "CALIGNANO", 0],
    ["B412", "LE", "CALIMERA", 0],
    ["B413", "LE", "CALIMERA", 1],
    ["B413", "BS", "CALINO", 0],
    ["B414", "AV", "CALITRI", 0],
    ["B415", "AV", "CALITRI", 1],
    ["B415", "SV", "CALIZZANO", 0],
    ["B416", "SV", "CALIZZANO", 1],
    ["B417", "BI", "CALLABIANA", 1],
    ["B418", "AT", "CALLIANO", 1],
    ["B419", "TN", "CALLIANO", 1],
    ["B420", "MI", "CALO'", 0],
    ["B421", "NO", "CALOGNA", 0],
    ["B423", "BG", "CALOLZIO", 0],
    ["B423", "LC", "CALOLZIOCORTE", 1],
    ["B424", "CS", "CALOPEZZATI", 1],
    ["B425", "AT", "CALOSSO", 1],
    ["B426", "CS", "CALOVETO", 1],
    ["B427", "AG", "CALTABELLOTTA", 1],
    ["B428", "CT", "CALTAGIRONE", 1],
    ["B429", "CL", "CALTANISSETTA", 1],
    ["B430", "PA", "CALTAVUTURO", 1],
    ["B431", "NO", "CALTIGNAGA", 1],
    ["B432", "RO", "CALTO", 1],
    ["B433", "VI", "CALTRANO", 1],
    ["B434", "BG", "CALUSCO", 0],
    ["B434", "BG", "CALUSCO D'ADDA", 1],
    ["B435", "TO", "CALUSO", 1],
    ["B436", "BS", "CALVAGESE", 0],
    ["B436", "BS", "CALVAGESE DELLA RIVIERA", 1],
    ["B437", "SA", "CALVANICO", 1],
    ["B438", "ME", "CALVARUSO", 0],
    ["B439", "CR", "CALVATONE", 1],
    ["B440", "PZ", "CALVELLO", 1],
    ["B441", "VI", "CALVENE", 1],
    ["B442", "BG", "CALVENZANO", 1],
    ["B443", "PZ", "CALVERA", 1],
    ["B444", "BN", "CALVI", 1],
    ["B445", "CE", "CALVI", 0],
    ["B446", "TR", "CALVI", 0],
    ["B446", "TR", "CALVI DELL'UMBRIA", 1],
    ["B445", "CE", "CALVI RISORTA", 1],
    ["B449", "BN", "CALVI SAN NAZZARO", 0],
    ["B447", "PV", "CALVIGNANO", 1],
    ["B448", "MI", "CALVIGNASCO", 1],
    ["B450", "BS", "CALVISANO", 1],
    ["B451", "GE", "CALVISIO", 0],
    ["B452", "NA", "CALVIZZANO", 1],
    ["B454", "TO", "CAMAGNA", 0],
    ["B454", "TO", "CAMAGNA DI TORINO", 0],
    ["B453", "AL", "CAMAGNA MONFERRATO", 1],
    ["B455", "LU", "CAMAIORE", 1],
    ["B456", "LO", "CAMAIRAGO", 0],
    ["B457", "BI", "CAMANDONA", 1],
    ["B458", "AQ", "CAMARDA", 0],
    ["B459", "VC", "CAMASCO", 0],
    ["B460", "AG", "CAMASTRA", 1],
    ["B461", "MI", "CAMBIAGO", 1],
    ["B462", "TO", "CAMBIANO", 1],
    ["B463", "VB", "CAMBIASCA", 1],
    ["B464", "PV", "CAMBIO'", 0],
    ["Z208", "EE", "CAMBOGIA", 1],
    ["B465", "BI", "CAMBURZANO", 1],
    ["B466", "CB", "CAMELI", 0],
    ["B467", "CN", "CAMERANA", 1],
    ["B468", "AN", "CAMERANO", 1],
    ["B469", "AT", "CAMERANO CASASCO", 1],
    ["B470", "AN", "CAMERATA", 0],
    ["B471", "BG", "CAMERATA", 0],
    ["B472", "RM", "CAMERATA", 0],
    ["B471", "BG", "CAMERATA CORNELLO", 1],
    ["B472", "RM", "CAMERATA NUOVA", 1],
    ["B470", "AN", "CAMERATA PICENA", 1],
    ["B473", "NO", "CAMERI", 1],
    ["B474", "MC", "CAMERINO", 1],
    ["B475", "CO", "CAMERLATA", 0],
    ["B476", "SA", "CAMEROTA", 1],
    ["Z306", "EE", "CAMERUN", 1],
    ["B477", "CE", "CAMIGLIANO", 1],
    ["B793", "GO", "CAMIGNA", 0],
    ["B478", "BS", "CAMIGNONE", 0],
    ["B479", "PC", "CAMINATA", 0],
    ["B480", "BZ", "CAMINATA DI TURES", 0],
    ["B481", "RC", "CAMINI", 1],
    ["B482", "AL", "CAMINO", 1],
    ["B483", "UD", "CAMINO AL TAGLIAMENTO", 1],
    ["B483", "UD", "CAMINO DI CODROIPO", 0],
    ["B484", "CR", "CAMISANO", 1],
    ["B485", "VI", "CAMISANO", 0],
    ["B485", "VI", "CAMISANO VICENTINO", 1],
    ["B486", "AG", "CAMMARATA", 1],
    ["B487", "CO", "CAMNAGO", 0],
    ["B488", "CO", "CAMNAGO D'UGGIATE", 0],
    ["B488", "CO", "CAMNAGO FALOPPIA", 0],
    ["B487", "CO", "CAMNAGO SAN MARTINO", 0],
    ["B487", "CO", "CAMNAGO VOLTA", 0],
    ["B489", "CN", "CAMO", 0],
    ["B490", "GE", "CAMOGLI", 1],
    ["B491", "AO", "CAMOSIO", 0],
    ["B492", "SA", "CAMPAGNA", 1],
    ["B493", "VE", "CAMPAGNA", 0],
    ["B493", "VE", "CAMPAGNA LUPIA", 1],
    ["B494", "CE", "CAMPAGNANO", 0],
    ["B495", "CO", "CAMPAGNANO", 0],
    ["B496", "RM", "CAMPAGNANO", 0],
    ["B496", "RM", "CAMPAGNANO DI ROMA", 1],
    ["B495", "VA", "CAMPAGNANO VEDASCA", 0],
    ["B497", "GR", "CAMPAGNATICO", 1],
    ["B498", "CR", "CAMPAGNOLA", 0],
    ["B499", "RE", "CAMPAGNOLA", 0],
    ["B498", "CR", "CAMPAGNOLA CREMASCA", 1],
    ["B499", "RE", "CAMPAGNOLA EMILIA", 1],
    ["B500", "CS", "CAMPANA", 1],
    ["B501", "MB", "CAMPARADA", 1],
    ["B501", "MI", "CAMPARADA", 0],
    ["B502", "RE", "CAMPEGINE", 1],
    ["B503", "NO", "CAMPELLO", 0],
    ["B504", "PG", "CAMPELLO", 0],
    ["B503", "NO", "CAMPELLO MONTI", 0],
    ["B504", "PG", "CAMPELLO SUL CLITUNNO", 1],
    ["B505", "VC", "CAMPERTOGNO", 1],
    ["B506", "LE", "CAMPI", 0],
    ["B507", "FI", "CAMPI BISENZIO", 1],
    ["B506", "LE", "CAMPI SALENTINA", 1],
    ["B508", "NO", "CAMPIGLIA", 0],
    ["B509", "PI", "CAMPIGLIA", 0],
    ["B510", "TO", "CAMPIGLIA", 0],
    ["B511", "VI", "CAMPIGLIA", 0],
    ["B508", "BI", "CAMPIGLIA CERVO", 0],
    ["M373", "BI", "CAMPIGLIA CERVO", 1],
    ["B511", "VI", "CAMPIGLIA DEI BERICI", 1],
    ["B509", "LI", "CAMPIGLIA MARITTIMA", 1],
    ["B510", "AO", "CAMPIGLIA SOANA", 0],
    ["B512", "TO", "CAMPIGLIONE", 0],
    ["B512", "TO", "CAMPIGLIONE FENILE", 1],
    ["B513", "CO", "CAMPIONE", 0],
    ["B513", "CO", "CAMPIONE D'ITALIA", 1],
    ["B514", "TN", "CAMPITELLO", 0],
    ["B514", "TN", "CAMPITELLO DI FASSA", 1],
    ["B515", "TE", "CAMPLI", 1],
    ["B516", "RC", "CAMPO", 0],
    ["B517", "TO", "CAMPO", 0],
    ["B518", "TN", "CAMPO", 0],
    ["B516", "RC", "CAMPO CALABRO", 1],
    ["B517", "TO", "CAMPO CANAVESE", 0],
    ["B516", "RC", "CAMPO DI CALABRIA", 0],
    ["B526", "AQ", "CAMPO DI GIOVE", 1],
    ["B529", "BZ", "CAMPO DI TRENS", 1],
    ["B540", "AO", "CAMPO LARIS", 0],
    ["B538", "GE", "CAMPO LIGURE", 1],
    ["B553", "LI", "CAMPO NELL'ELBA", 1],
    ["B558", "PV", "CAMPO RINALDO", 0],
    ["B564", "PD", "CAMPO SAN MARTINO", 1],
    ["B570", "BZ", "CAMPO TURES", 1],
    ["B519", "CB", "CAMPOBASSO", 1],
    ["B520", "AG", "CAMPOBELLO DI LICATA", 1],
    ["B521", "TP", "CAMPOBELLO DI MAZARA", 1],
    ["B522", "CB", "CAMPOCHIARO", 1],
    ["B523", "SV", "CAMPOCHIESA", 0],
    ["B524", "PD", "CAMPODARSEGO", 1],
    ["B525", "TN", "CAMPODENNO", 1],
    ["B527", "LT", "CAMPODIMELE", 1],
    ["B528", "CB", "CAMPODIPIETRA", 1],
    ["B530", "SO", "CAMPODOLCINO", 1],
    ["B531", "PD", "CAMPODORO", 1],
    ["B532", "PA", "CAMPOFELICE", 0],
    ["B533", "PA", "CAMPOFELICE DI FITALIA", 1],
    ["B532", "PA", "CAMPOFELICE DI ROCCELLA", 1],
    ["B534", "AP", "CAMPOFILONE", 0],
    ["B534", "FM", "CAMPOFILONE", 1],
    ["B535", "PA", "CAMPOFIORITO", 1],
    ["B536", "UD", "CAMPOFORMIDO", 1],
    ["B537", "CL", "CAMPOFRANCO", 1],
    ["B538", "GE", "CAMPOFREDDO", 0],
    ["B539", "MO", "CAMPOGALLIANO", 1],
    ["B541", "BN", "CAMPOLATTARO", 1],
    ["B542", "BN", "CAMPOLI", 0],
    ["B543", "CE", "CAMPOLI", 0],
    ["B543", "FR", "CAMPOLI APPENNINO", 1],
    ["B542", "BN", "CAMPOLI DEL MONTE TABURNO", 1],
    ["B544", "CB", "CAMPOLIETO", 1],
    ["B545", "UD", "CAMPOLONGO", 0],
    ["B546", "VE", "CAMPOLONGO", 0],
    ["B547", "VI", "CAMPOLONGO", 0],
    ["B545", "UD", "CAMPOLONGO AL TORRE", 0],
    ["B546", "VE", "CAMPOLONGO MAGGIORE", 1],
    ["B547", "VI", "CAMPOLONGO SUL BRENTA", 0],
    ["M311", "UD", "CAMPOLONGO TAPOGLIANO", 1],
    ["B548", "MI", "CAMPOLUNGO", 0],
    ["B549", "PZ", "CAMPOMAGGIORE", 1],
    ["B550", "CB", "CAMPOMARINO", 1],
    ["B551", "GE", "CAMPOMORONE", 1],
    ["B552", "PV", "CAMPOMORTO", 0],
    ["B554", "VE", "CAMPONOGARA", 1],
    ["B555", "SA", "CAMPORA", 1],
    ["B556", "PA", "CAMPOREALE", 1],
    ["B557", "LU", "CAMPORGIANO", 1],
    ["B559", "IM", "CAMPOROSSO", 1],
    ["B560", "UD", "CAMPOROSSO", 0],
    ["B560", "UD", "CAMPOROSSO IN VALCANALE", 0],
    ["B561", "CT", "CAMPOROTONDO", 0],
    ["B562", "MC", "CAMPOROTONDO", 0],
    ["B562", "MC", "CAMPOROTONDO DI FIASTRONE", 1],
    ["B561", "CT", "CAMPOROTONDO ETNEO", 1],
    ["B563", "PD", "CAMPOSAMPIERO", 1],
    ["B565", "NA", "CAMPOSANO", 1],
    ["B566", "MO", "CAMPOSANTO", 1],
    ["B567", "PV", "CAMPOSPINOSO", 1],
    ["B568", "PV", "CAMPOSPINOSO ALBAREDO", 0],
    ["B569", "AQ", "CAMPOTOSTO", 1],
    ["B316", "BS", "CAMPOVERDE", 0],
    ["B571", "SO", "CAMPOVICO", 0],
    ["B572", "BO", "CAMUGNANO", 1],
    ["Z401", "EE", "CANADA", 1],
    ["B577", "TN", "CANAL SAN BOVO", 1],
    ["B573", "CN", "CANALE", 1],
    ["M222", "TN", "CANALE", 0],
    ["B574", "BL", "CANALE D'AGORDO", 1],
    ["B575", "GO", "CANALE D'ISONZO", 0],
    ["B576", "RM", "CANALE E MONTEVIRGINIO", 0],
    ["B576", "RM", "CANALE MONTERANO", 1],
    ["B578", "RO", "CANARO", 1],
    ["B579", "TN", "CANAZEI", 1],
    ["B580", "PZ", "CANCELLARA", 1],
    ["B581", "CE", "CANCELLO ED ARNONE", 1],
    ["B582", "RO", "CANDA", 1],
    ["B583", "IM", "CANDEASCO", 0],
    ["B584", "FG", "CANDELA", 1],
    ["B585", "PU", "CANDELARA", 0],
    ["B586", "BI", "CANDELO", 1],
    ["B587", "PV", "CANDIA", 0],
    ["B588", "TO", "CANDIA", 0],
    ["B588", "TO", "CANDIA CANAVESE", 1],
    ["B587", "PV", "CANDIA LOMELLINA", 1],
    ["B589", "PD", "CANDIANA", 1],
    ["B590", "AV", "CANDIDA", 1],
    ["B591", "RC", "CANDIDONI", 1],
    ["B592", "TO", "CANDIOLO", 1],
    ["B593", "MI", "CANEGRATE", 1],
    ["B594", "AT", "CANELLI", 1],
    ["B595", "RI", "CANEMORTO", 0],
    ["B596", "GE", "CANEPA", 0],
    ["B597", "VT", "CANEPINA", 1],
    ["B598", "PN", "CANEVA", 1],
    ["B599", "PV", "CANEVINO", 0],
    ["B600", "TN", "CANEZZA", 0],
    ["B601", "PL", "CANFANARO", 0],
    ["B602", "AG", "CANICATTI'", 1],
    ["B603", "SR", "CANICATTINI BAGNI", 1],
    ["B604", "VT", "CANINO", 1],
    ["B605", "TO", "CANISCHIO", 1],
    ["B606", "AQ", "CANISTRO", 1],
    ["B607", "CS", "CANNA", 1],
    ["B608", "SA", "CANNALONGA", 1],
    ["B609", "PG", "CANNARA", 1],
    ["B610", "NO", "CANNERO", 0],
    ["B610", "VB", "CANNERO RIVIERA", 1],
    ["B611", "BA", "CANNETO", 0],
    ["B612", "BS", "CANNETO", 0],
    ["B611", "BA", "CANNETO DI BARI", 0],
    ["B613", "PV", "CANNETO PAVESE", 1],
    ["B612", "MN", "CANNETO SULL'OGLIO", 1],
    ["B614", "RC", "CANNITELLO", 0],
    ["B615", "VB", "CANNOBIO", 1],
    ["B616", "LE", "CANNOLE", 1],
    ["B617", "RC", "CANOLO", 1],
    ["B618", "BG", "CANONICA D'ADDA", 1],
    ["B619", "BA", "CANOSA", 0],
    ["B620", "CH", "CANOSA", 0],
    ["B619", "BA", "CANOSA DI PUGLIA", 0],
    ["B619", "BT", "CANOSA DI PUGLIA", 1],
    ["B620", "CH", "CANOSA SANNITA", 1],
    ["B621", "CN", "CANOSIO", 1],
    ["C669", "RE", "CANOSSA", 1],
    ["B622", "CR", "CANOVA DEL MORBASCO", 0],
    ["B623", "CR", "CANOVA OLZANO", 0],
    ["B624", "AQ", "CANSANO", 1],
    ["B625", "CR", "CANSERO", 0],
    ["B626", "PO", "CANTAGALLO", 1],
    ["B627", "RI", "CANTALICE", 1],
    ["B628", "TO", "CANTALUPA", 1],
    ["B629", "AL", "CANTALUPO", 0],
    ["B630", "CB", "CANTALUPO", 0],
    ["B631", "RI", "CANTALUPO", 0],
    ["B632", "RM", "CANTALUPO BARDELLA", 0],
    ["B631", "RI", "CANTALUPO IN SABINA", 1],
    ["B629", "AL", "CANTALUPO LIGURE", 1],
    ["B630", "IS", "CANTALUPO NEL SANNIO", 1],
    ["B633", "AT", "CANTARANA", 1],
    ["B634", "VA", "CANTELLO", 1],
    ["B635", "RM", "CANTERANO", 1],
    ["B636", "PU", "CANTIANO", 1],
    ["B637", "TO", "CANTOIRA", 1],
    ["B638", "MI", "CANTONALE", 0],
    ["B639", "CO", "CANTU'", 1],
    ["B640", "TE", "CANZANO", 1],
    ["B641", "CO", "CANZO", 1],
    ["B642", "VE", "CAORLE", 1],
    ["B643", "PC", "CAORSO", 1],
    ["B644", "SA", "CAPACCIO", 0],
    ["B644", "SA", "CAPACCIO PAESTUM", 1],
    ["B645", "PA", "CAPACI", 1],
    ["B646", "GR", "CAPALBIO", 1],
    ["B647", "PI", "CAPANNOLI", 1],
    ["B648", "LU", "CAPANNORI", 1],
    ["B649", "RM", "CAPENA", 1],
    ["B650", "CR", "CAPERGNANICA", 1],
    ["B651", "AQ", "CAPESTRANO", 1],
    ["B652", "CO", "CAPIAGO", 0],
    ["B653", "CO", "CAPIAGO INTIMIANO", 1],
    ["B654", "CO", "CAPIATE", 0],
    ["B655", "VV", "CAPISTRANO", 1],
    ["B656", "AQ", "CAPISTRELLO", 1],
    ["B657", "SA", "CAPITELLO", 0],
    ["B658", "AQ", "CAPITIGNANO", 1],
    ["B659", "PG", "CAPITONE", 0],
    ["B660", "ME", "CAPIZZI", 1],
    ["B661", "BG", "CAPIZZONE", 1],
    ["B666", "ME", "CAPO D'ORLANDO", 1],
    ["B664", "BS", "CAPO DI PONTE", 1],
    ["Z307", "EE", "CAPO VERDE", 1],
    ["B663", "VT", "CAPODIMONTE", 1],
    ["B662", "BL", "CAPODIPONTE", 0],
    ["B665", "PL", "CAPODISTRIA", 0],
    ["B667", "CE", "CAPODRISE", 1],
    ["B668", "VA", "CAPOLAGO", 0],
    ["B669", "LI", "CAPOLIVERI", 1],
    ["B670", "AR", "CAPOLONA", 1],
    ["B671", "MB", "CAPONAGO", 1],
    ["B671", "MI", "CAPONAGO", 0],
    ["B672", "AQ", "CAPORCIANO", 1],
    ["B673", "GO", "CAPORETTO", 0],
    ["B674", "AV", "CAPOSELE", 1],
    ["B675", "CA", "CAPOTERRA", 1],
    ["B676", "BS", "CAPOVALLE", 1],
    ["B677", "AQ", "CAPPADOCIA", 1],
    ["B678", "TV", "CAPPELLA", 0],
    ["B679", "CR", "CAPPELLA CANTONE", 1],
    ["B679", "CR", "CAPPELLA CANTONE CON OSCASALE", 0],
    ["B680", "CR", "CAPPELLA DE' PICENARDI", 1],
    ["B678", "TV", "CAPPELLA MAGGIORE", 1],
    ["B681", "PE", "CAPPELLE", 0],
    ["B681", "PE", "CAPPELLE SUL TAVO", 1],
    ["B682", "IS", "CAPRACOTTA", 1],
    ["B683", "AP", "CAPRADOSSO", 0],
    ["B684", "FI", "CAPRAIA", 0],
    ["B684", "FI", "CAPRAIA E LIMITE", 1],
    ["B685", "LI", "CAPRAIA ISOLA", 1],
    ["B686", "CR", "CAPRALBA", 1],
    ["B686", "CR", "CAPRALBA CON FARINATE", 0],
    ["B687", "RM", "CAPRANICA", 0],
    ["B688", "VT", "CAPRANICA", 1],
    ["B687", "RM", "CAPRANICA PRENESTINA", 1],
    ["B689", "BO", "CAPRARA SOPRA PANICO", 0],
    ["B690", "LE", "CAPRARICA DI LECCE", 1],
    ["B691", "VT", "CAPRAROLA", 1],
    ["B692", "CN", "CAPRAUNA", 1],
    ["B693", "AR", "CAPRESE", 0],
    ["B693", "AR", "CAPRESE MICHELANGELO", 1],
    ["B694", "VB", "CAPREZZO", 1],
    ["B695", "ME", "CAPRI", 0],
    ["B696", "NA", "CAPRI", 1],
    ["B695", "ME", "CAPRI LEONE", 1],
    ["B697", "TN", "CAPRIANA", 1],
    ["B698", "BS", "CAPRIANO", 0],
    ["B699", "MI", "CAPRIANO", 0],
    ["B698", "BS", "CAPRIANO DEL COLLE", 1],
    ["B699", "MI", "CAPRIANO DI BRIANZA", 0],
    ["B700", "BS", "CAPRIANO-AZZANO", 0],
    ["B701", "AL", "CAPRIATA", 0],
    ["B701", "AL", "CAPRIATA D'ORBA", 1],
    ["B702", "BG", "CAPRIATE", 0],
    ["B702", "BG", "CAPRIATE D'ADDA", 0],
    ["B703", "BG", "CAPRIATE SAN GERVASIO", 1],
    ["B704", "CE", "CAPRIATI", 0],
    ["B704", "CE", "CAPRIATI A VOLTURNO", 1],
    ["B705", "TO", "CAPRIE", 1],
    ["B706", "AV", "CAPRIGLIA IRPINA", 1],
    ["B707", "AT", "CAPRIGLIO", 1],
    ["B708", "BI", "CAPRILE", 1],
    ["B709", "VR", "CAPRINO", 0],
    ["B710", "BG", "CAPRINO BERGAMASCO", 1],
    ["B709", "VR", "CAPRINO VERONESE", 1],
    ["B711", "BS", "CAPRIOLO", 1],
    ["B712", "GO", "CAPRIVA", 0],
    ["B713", "TS", "CAPRIVA DEL CARSO", 0],
    ["B712", "GO", "CAPRIVA DEL FRIULI", 1],
    ["B712", "GO", "CAPRIVA DI CORMONS", 0],
    ["B714", "VA", "CAPRONNO", 0],
    ["B715", "CE", "CAPUA", 1],
    ["B716", "BA", "CAPURSO", 1],
    ["B717", "CZ", "CARAFFA", 0],
    ["B718", "RC", "CARAFFA", 0],
    ["B718", "RC", "CARAFFA DEL BIANCO", 1],
    ["B717", "CZ", "CARAFFA DI CATANZARO", 1],
    ["B719", "CN", "CARAGLIO", 1],
    ["B720", "CN", "CARAMAGNA", 0],
    ["B721", "IM", "CARAMAGNA", 0],
    ["B721", "IM", "CARAMAGNA LIGURE", 0],
    ["B720", "CN", "CARAMAGNA PIEMONTE", 1],
    ["B722", "PE", "CARAMANICO", 0],
    ["B722", "PE", "CARAMANICO TERME", 1],
    ["B723", "TN", "CARANO", 0],
    ["B724", "FG", "CARAPELLE", 1],
    ["B725", "AQ", "CARAPELLE", 0],
    ["B725", "AQ", "CARAPELLE CALVISIO", 1],
    ["B726", "GE", "CARASCO", 1],
    ["B727", "AP", "CARASSAI", 1],
    ["B728", "CO", "CARATE", 0],
    ["B729", "MI", "CARATE", 0],
    ["B729", "MB", "CARATE BRIANZA", 1],
    ["B729", "MI", "CARATE BRIANZA", 0],
    ["B728", "CO", "CARATE LARIO", 0],
    ["B730", "CO", "CARATE URIO", 1],
    ["B731", "BG", "CARAVAGGIO", 1],
    ["B732", "VA", "CARAVATE", 1],
    ["B733", "TO", "CARAVINO", 1],
    ["B734", "IM", "CARAVONICA", 1],
    ["B735", "VT", "CARBOGNANO", 1],
    ["A347", "AV", "CARBONARA", 0],
    ["B736", "AL", "CARBONARA", 0],
    ["B737", "BA", "CARBONARA", 0],
    ["B738", "CA", "CARBONARA", 0],
    ["B739", "MN", "CARBONARA", 0],
    ["B740", "NA", "CARBONARA", 0],
    ["B741", "PV", "CARBONARA", 0],
    ["B741", "PV", "CARBONARA AL TICINO", 1],
    ["B737", "BA", "CARBONARA DI BARI", 0],
    ["B740", "NA", "CARBONARA DI NOLA", 1],
    ["B739", "MN", "CARBONARA DI PO", 0],
    ["B736", "AL", "CARBONARA SCRIVIA", 1],
    ["B742", "CO", "CARBONATE", 1],
    ["B743", "PZ", "CARBONE", 1],
    ["B744", "TV", "CARBONERA", 1],
    ["B745", "CA", "CARBONIA", 0],
    ["B745", "SU", "CARBONIA", 1],
    ["B746", "CT", "CARCACI", 0],
    ["B747", "CO", "CARCANO", 0],
    ["B748", "SV", "CARCARE", 1],
    ["B749", "PD", "CARCERI", 1],
    ["B750", "TN", "CARCIATO", 0],
    ["B751", "BS", "CARCINA", 0],
    ["B752", "VC", "CARCOFORO", 1],
    ["B753", "VA", "CARDANA", 0],
    ["B754", "VA", "CARDANO", 0],
    ["B754", "VA", "CARDANO AL CAMPO", 1],
    ["B755", "CN", "CARDE'", 1],
    ["M285", "NU", "CARDEDU", 1],
    ["B756", "RC", "CARDETO", 1],
    ["B757", "NO", "CARDEZZA", 0],
    ["B758", "CZ", "CARDINALE", 1],
    ["B759", "NA", "CARDITO", 1],
    ["B760", "LU", "CAREGGINE", 1],
    ["B761", "CO", "CARELLA CON MARIAGA", 0],
    ["B762", "TO", "CAREMA", 1],
    ["B763", "LC", "CARENNO", 1],
    ["B764", "CO", "CARENO", 0],
    ["B765", "AL", "CARENTINO", 1],
    ["B766", "RC", "CARERI", 1],
    ["M223", "TN", "CARES", 0],
    ["B767", "VC", "CARESANA", 1],
    ["B768", "VC", "CARESANABLOT", 1],
    ["B769", "AL", "CAREZZANO", 1],
    ["B769", "AL", "CAREZZANO INFERIORE", 0],
    ["B769", "AL", "CAREZZANO MAGGIORE", 0],
    ["B770", "AL", "CAREZZANO SUPERIORE", 0],
    ["B771", "KR", "CARFIZZI", 1],
    ["B772", "SS", "CARGEGHE", 1],
    ["B773", "NO", "CARGIAGO", 0],
    ["B774", "CS", "CARIATI", 1],
    ["B775", "RC", "CARIDA'", 0],
    ["B776", "AV", "CARIFE", 1],
    ["B777", "TO", "CARIGNANO", 1],
    ["B778", "CO", "CARIMATE", 1],
    ["B779", "CE", "CARINARO", 1],
    ["B780", "PA", "CARINI", 1],
    ["B781", "CE", "CARINOLA", 1],
    ["B782", "VC", "CARISIO", 1],
    ["B783", "TN", "CARISOLO", 1],
    ["B784", "FG", "CARLANTINO", 1],
    ["B785", "CO", "CARLAZZO", 1],
    ["B786", "CO", "CARLAZZO VALSOLDA", 0],
    ["B787", "SR", "CARLENTINI", 1],
    ["B788", "UD", "CARLINO", 1],
    ["B789", "CA", "CARLOFORTE", 0],
    ["B789", "SU", "CARLOFORTE", 1],
    ["B790", "CZ", "CARLOPOLI", 1],
    ["B791", "TO", "CARMAGNOLA", 1],
    ["B792", "LE", "CARMIANO", 1],
    ["B794", "PO", "CARMIGNANO", 1],
    ["B795", "PD", "CARMIGNANO", 0],
    ["B795", "PD", "CARMIGNANO DI BRENTA", 1],
    ["B796", "VA", "CARNAGO", 1],
    ["B797", "PG", "CARNAIOLA", 0],
    ["B798", "MB", "CARNATE", 1],
    ["B798", "MI", "CARNATE", 0],
    ["B799", "BZ", "CARNEDO", 0],
    ["B800", "BG", "CAROBBIO", 0],
    ["B801", "BG", "CAROBBIO DEGLI ANGELI", 1],
    ["B802", "CS", "CAROLEI", 1],
    ["Z701", "EE", "CAROLINE (ISOLE)", 0],
    ["B803", "BG", "CARONA", 1],
    ["B804", "ME", "CARONIA", 1],
    ["B805", "MI", "CARONNO", 0],
    ["B806", "VA", "CARONNO CORBELLARO", 0],
    ["B807", "VA", "CARONNO GHIRINGHELLO", 0],
    ["B805", "VA", "CARONNO MILANESE", 0],
    ["B805", "VA", "CARONNO PERTUSELLA", 1],
    ["B807", "VA", "CARONNO VARESINO", 1],
    ["B808", "TA", "CAROSINO", 1],
    ["B809", "BR", "CAROVIGNO", 1],
    ["B810", "IS", "CAROVILLI", 1],
    ["B811", "CR", "CARPANETA CON DOSIMO", 0],
    ["B812", "PC", "CARPANETO", 0],
    ["B812", "PC", "CARPANETO PIACENTINO", 1],
    ["B813", "CS", "CARPANZANO", 1],
    ["B814", "IM", "CARPASIO", 0],
    ["B815", "GE", "CARPE", 0],
    ["B816", "PU", "CARPEGNA", 1],
    ["B817", "BS", "CARPENEDOLO", 1],
    ["B818", "AL", "CARPENETO", 1],
    ["B819", "MO", "CARPI", 1],
    ["B820", "MI", "CARPIANO", 1],
    ["B821", "PV", "CARPIGNAGO", 0],
    ["B822", "LE", "CARPIGNANO", 0],
    ["B823", "NO", "CARPIGNANO", 0],
    ["B824", "PV", "CARPIGNANO", 0],
    ["B822", "LE", "CARPIGNANO SALENTINO", 1],
    ["B823", "NO", "CARPIGNANO SESIA", 1],
    ["B825", "RE", "CARPINETI", 1],
    ["B826", "CH", "CARPINETO", 0],
    ["B827", "PE", "CARPINETO", 0],
    ["B828", "RM", "CARPINETO", 0],
    ["B827", "PE", "CARPINETO DELLA NORA", 1],
    ["B828", "RM", "CARPINETO ROMANO", 1],
    ["B826", "CH", "CARPINETO SINELLO", 1],
    ["B829", "FG", "CARPINO", 1],
    ["B830", "IS", "CARPINONE", 1],
    ["B831", "NO", "CARPUGNINO", 0],
    ["B832", "MS", "CARRARA", 1],
    ["B833", "PD", "CARRARA SAN GIORGIO", 0],
    ["B834", "PD", "CARRARA SANTO STEFANO", 0],
    ["B835", "VI", "CARRE'", 1],
    ["B836", "AL", "CARREGA LIGURE", 1],
    ["B837", "GE", "CARRETTO", 0],
    ["B838", "SP", "CARRO", 1],
    ["B839", "SP", "CARRODANO", 1],
    ["B840", "AL", "CARROSIO", 1],
    ["B841", "CN", "CARRU'", 1],
    ["B842", "AQ", "CARSOLI", 1],
    ["B843", "IM", "CARTARI E CALDERARA", 0],
    ["B844", "VI", "CARTIGLIANO", 1],
    ["B845", "CN", "CARTIGNANO", 1],
    ["B846", "PU", "CARTOCETO", 1],
    ["B847", "AL", "CARTOSIO", 1],
    ["B848", "PD", "CARTURA", 1],
    ["B849", "CR", "CARUBERTO", 0],
    ["B850", "MI", "CARUGATE", 1],
    ["B851", "CO", "CARUGO", 1],
    ["B852", "CO", "CARUGO AROSIO", 0],
    ["B852", "CO", "CARUGO CON AROSIO", 0],
    ["B853", "CH", "CARUNCHIO", 1],
    ["B854", "BG", "CARVICO", 1],
    ["B855", "BS", "CARZAGO", 0],
    ["B855", "BS", "CARZAGO DELLA RIVIERA", 0],
    ["B856", "TN", "CARZANO", 1],
    ["B857", "KR", "CASABONA", 1],
    ["B858", "CB", "CASACALENDA", 1],
    ["B859", "CH", "CASACANDITELLA", 1],
    ["B860", "CE", "CASAGIOVE", 1],
    ["B870", "AL", "CASAL CERMELLI", 1],
    ["B872", "CE", "CASAL DI PRINCIPE", 1],
    ["B912", "PD", "CASAL SER UGO", 0],
    ["B895", "SA", "CASAL VELINO", 1],
    ["B861", "CH", "CASALANGUIDA", 1],
    ["B862", "FR", "CASALATTICO", 1],
    ["B863", "CE", "CASALBA", 0],
    ["B864", "NO", "CASALBELTRAME", 1],
    ["B865", "CH", "CASALBORDINO", 1],
    ["B866", "AV", "CASALBORE", 1],
    ["B867", "TO", "CASALBORGONE", 1],
    ["B868", "SA", "CASALBUONO", 1],
    ["B869", "CR", "CASALBUTTANO", 0],
    ["B869", "CR", "CASALBUTTANO ED UNITI", 1],
    ["B871", "CB", "CASALCIPRANO", 1],
    ["B873", "BN", "CASALDUNI", 1],
    ["B874", "CR", "CASALE", 0],
    ["B875", "MI", "CASALE", 0],
    ["B876", "NO", "CASALE", 0],
    ["B877", "PD", "CASALE", 0],
    ["B878", "PI", "CASALE", 0],
    ["B879", "TV", "CASALE", 0],
    ["B876", "VB", "CASALE CORTE CERRO", 1],
    ["B874", "CR", "CASALE CREMASCO", 0],
    ["B881", "CR", "CASALE CREMASCO-VIDOLASCO", 1],
    ["B877", "PD", "CASALE DI SCODOSIA", 1],
    ["B878", "PI", "CASALE DI VAL DI CECINA", 0],
    ["B875", "VA", "CASALE LITTA", 1],
    ["B878", "PI", "CASALE MARITTIMO", 1],
    ["B885", "AL", "CASALE MONFERRATO", 1],
    ["B879", "TV", "CASALE SUL SILE", 1],
    ["B880", "BO", "CASALECCHIO DI RENO", 1],
    ["B882", "AL", "CASALEGGIO", 0],
    ["B883", "NO", "CASALEGGIO", 0],
    ["B882", "AL", "CASALEGGIO BOIRO", 1],
    ["B884", "NO", "CASALEGGIO CASTELLAZZO", 0],
    ["B883", "NO", "CASALEGGIO NOVARA", 1],
    ["B886", "VR", "CASALEONE", 1],
    ["B887", "MI", "CASALETTO", 0],
    ["B888", "SA", "CASALETTO", 0],
    ["B889", "CR", "CASALETTO CEREDANO", 1],
    ["B890", "CR", "CASALETTO DI SOPRA", 1],
    ["B887", "LO", "CASALETTO LODIGIANO", 1],
    ["B888", "SA", "CASALETTO SPARTANO", 1],
    ["B891", "CR", "CASALETTO VAPRIO", 1],
    ["B892", "BO", "CASALFIUMANESE", 1],
    ["B893", "RE", "CASALGRANDE", 1],
    ["B894", "CN", "CASALGRASSO", 1],
    ["M385", "CS", "CASALI DEL MANCO", 1],
    ["B895", "SA", "CASALICCHIO", 0],
    ["B896", "CH", "CASALINCONTRADA", 1],
    ["B897", "NO", "CASALINO", 1],
    ["B898", "CR", "CASALMAGGIORE", 1],
    ["B899", "LO", "CASALMAIOCCO", 1],
    ["B900", "CR", "CASALMORANO", 1],
    ["B901", "MN", "CASALMORO", 1],
    ["B902", "AL", "CASALNOCETO", 1],
    ["A698", "ME", "CASALNUOVO", 0],
    ["B868", "SA", "CASALNUOVO", 0],
    ["B903", "CS", "CASALNUOVO", 0],
    ["B904", "FG", "CASALNUOVO", 0],
    ["B905", "NA", "CASALNUOVO", 0],
    ["B906", "PZ", "CASALNUOVO", 0],
    ["B905", "NA", "CASALNUOVO DI NAPOLI", 1],
    ["B906", "PZ", "CASALNUOVO LUCANO", 0],
    ["B904", "FG", "CASALNUOVO MONTEROTARO", 1],
    ["B907", "MN", "CASALOLDO", 1],
    ["B908", "CR", "CASALORZO GEROLDI", 0],
    ["B909", "MN", "CASALPOGLIO", 0],
    ["B910", "LO", "CASALPUSTERLENGO", 1],
    ["B911", "MN", "CASALROMANO", 1],
    ["B912", "PD", "CASALSERUGO", 1],
    ["B913", "CR", "CASALSIGONE", 0],
    ["B914", "CR", "CASALSIGONE ED UNITI", 0],
    ["B915", "FG", "CASALTRINITA", 0],
    ["B916", "CE", "CASALUCE", 1],
    ["B917", "FG", "CASALVECCHIO", 0],
    ["B918", "ME", "CASALVECCHIO", 0],
    ["B917", "FG", "CASALVECCHIO DI PUGLIA", 1],
    ["B918", "ME", "CASALVECCHIO SICULO", 1],
    ["B919", "FR", "CASALVIERI", 1],
    ["B920", "NO", "CASALVOLONE", 1],
    ["B921", "VA", "CASALZUIGNO", 1],
    ["B922", "NA", "CASAMARCIANO", 1],
    ["B923", "BA", "CASAMASSIMA", 1],
    ["B924", "NA", "CASAMICCIOLA", 0],
    ["B924", "NA", "CASAMICCIOLA TERME", 1],
    ["B925", "NA", "CASANDRINO", 1],
    ["B860", "CE", "CASANOVA", 0],
    ["B926", "CO", "CASANOVA", 0],
    ["B927", "GE", "CASANOVA", 0],
    ["B928", "NO", "CASANOVA", 0],
    ["M224", "CR", "CASANOVA DEL MORBASCO", 0],
    ["B926", "CO", "CASANOVA DI UGGIATE", 0],
    ["B860", "CE", "CASANOVA E COCCAGNA", 0],
    ["B928", "VC", "CASANOVA ELVO", 1],
    ["B926", "CO", "CASANOVA LANZA", 0],
    ["B927", "SV", "CASANOVA LERRONE", 1],
    ["B929", "PV", "CASANOVA LONATI", 1],
    ["B931", "CR", "CASANUOVA OFFREDI", 0],
    ["B932", "RM", "CASAPE", 1],
    ["M260", "CE", "CASAPESENNA", 1],
    ["B933", "BI", "CASAPINTA", 1],
    ["B934", "RI", "CASAPROTA", 1],
    ["B935", "CE", "CASAPULLA", 1],
    ["B936", "LE", "CASARANO", 1],
    ["B937", "LC", "CASARGO", 1],
    ["B938", "MI", "CASARILE", 1],
    ["B940", "UD", "CASARSA", 0],
    ["B940", "PN", "CASARSA DELLA DELIZIA", 1],
    ["B939", "GE", "CASARZA", 0],
    ["B939", "GE", "CASARZA LIGURE", 1],
    ["B941", "AL", "CASASCO", 1],
    ["B942", "CO", "CASASCO", 0],
    ["B942", "CO", "CASASCO D'INTELVI", 0],
    ["B943", "LC", "CASATENOVO", 1],
    ["B944", "PV", "CASATICO", 0],
    ["B945", "PV", "CASATISMA", 1],
    ["B946", "NA", "CASAVATORE", 1],
    ["B947", "BG", "CASAZZA", 1],
    ["B948", "PG", "CASCIA", 1],
    ["B949", "VA", "CASCIAGO", 1],
    ["A559", "PI", "CASCIANA TERME", 0],
    ["M327", "PI", "CASCIANA TERME LARI", 1],
    ["B950", "PI", "CASCINA", 1],
    ["C026", "CR", "CASCINE", 0],
    ["B951", "VC", "CASCINE DI STRA", 0],
    ["C029", "CR", "CASCINE GANDINI", 0],
    ["B952", "VC", "CASCINE SAN GIACOMO", 0],
    ["B953", "TO", "CASCINETTE D'IVREA", 1],
    ["B954", "PV", "CASEI GEROLA", 1],
    ["B955", "TO", "CASELETTE", 1],
    ["B956", "GE", "CASELLA", 1],
    ["B957", "MI", "CASELLE", 0],
    ["B958", "MI", "CASELLE", 0],
    ["B959", "SA", "CASELLE", 0],
    ["B960", "TO", "CASELLE", 0],
    ["B957", "MI", "CASELLE D'OZERO", 0],
    ["B959", "SA", "CASELLE IN PITTARI", 1],
    ["B961", "LO", "CASELLE LANDI", 1],
    ["B958", "LO", "CASELLE LURANI", 1],
    ["B960", "TO", "CASELLE TORINESE", 1],
    ["B962", "FI", "CASELLINA E TORRI", 0],
    ["B963", "CE", "CASERTA", 1],
    ["B964", "TN", "CASEZ", 0],
    ["B965", "TV", "CASIER", 1],
    ["B966", "RC", "CASIGNANA", 1],
    ["B967", "RE", "CASINA", 1],
    ["B968", "CZ", "CASINO", 0],
    ["B969", "BO", "CASIO E CASOLA", 0],
    ["B970", "CO", "CASIRAGO", 0],
    ["B971", "BG", "CASIRATE", 0],
    ["B972", "MI", "CASIRATE", 0],
    ["B971", "BG", "CASIRATE D'ADDA", 1],
    ["B972", "MI", "CASIRATE OLONA", 0],
    ["B973", "CO", "CASLETTO", 0],
    ["B974", "CO", "CASLINO", 0],
    ["B975", "CO", "CASLINO", 0],
    ["B975", "CO", "CASLINO AL PIANO", 0],
    ["B974", "CO", "CASLINO D'ERBA", 1],
    ["B974", "CO", "CASLINO PIANO D'ERBA", 0],
    ["B976", "CO", "CASNATE", 0],
    ["B977", "CO", "CASNATE CON BERNATE", 1],
    ["B978", "BG", "CASNIGO", 1],
    ["B979", "MS", "CASOLA", 0],
    ["B980", "NA", "CASOLA", 0],
    ["B980", "NA", "CASOLA DI NAPOLI", 1],
    ["B979", "MS", "CASOLA IN LUNIGIANA", 1],
    ["B982", "RA", "CASOLA VALSENIO", 1],
    ["B981", "MI", "CASOLATE", 0],
    ["B983", "CS", "CASOLE", 0],
    ["B984", "SI", "CASOLE", 0],
    ["B983", "CS", "CASOLE BRUZIO", 0],
    ["B984", "SI", "CASOLE D'ELSA", 1],
    ["B985", "CH", "CASOLI", 1],
    ["B986", "MI", "CASONE", 0],
    ["B987", "MI", "CASORATE", 0],
    ["B988", "PV", "CASORATE", 0],
    ["B988", "PV", "CASORATE PRIMO", 1],
    ["B987", "VA", "CASORATE SEMPIONE", 1],
    ["B989", "MI", "CASOREZZO", 1],
    ["B990", "NA", "CASORIA", 1],
    ["B991", "AT", "CASORZO", 1],
    ["B992", "VI", "CASOTTO", 0],
    ["A472", "RI", "CASPERIA", 1],
    ["B993", "SO", "CASPOGGIO", 1],
    ["B994", "UD", "CASSACCO", 1],
    ["B995", "CO", "CASSAGO", 0],
    ["B996", "LC", "CASSAGO BRIANZA", 1],
    ["B997", "AV", "CASSANO", 0],
    ["B998", "BA", "CASSANO", 0],
    ["B999", "CO", "CASSANO", 0],
    ["C001", "CO", "CASSANO", 0],
    ["C002", "CS", "CASSANO", 0],
    ["C001", "CO", "CASSANO ALBESE", 0],
    ["C002", "CS", "CASSANO ALL'IONIO", 1],
    ["C002", "CS", "CASSANO ALLO IONIO", 0],
    ["C003", "MI", "CASSANO D'ADDA", 1],
    ["B998", "BA", "CASSANO DELLE MURGE", 1],
    ["B997", "AV", "CASSANO IRPINO", 1],
    ["C004", "VA", "CASSANO MAGNAGO", 1],
    ["C005", "AL", "CASSANO SPINOLA", 0],
    ["M388", "AL", "CASSANO SPINOLA", 1],
    ["B999", "VA", "CASSANO VALCUVIA", 1],
    ["C006", "SR", "CASSARO", 1],
    ["C007", "BG", "CASSIGLIO", 1],
    ["C008", "MI", "CASSINA ALIPRANDI", 0],
    ["C009", "MI", "CASSINA AMATA", 0],
    ["C010", "MI", "CASSINA BARAGGIA", 0],
    ["C011", "CO", "CASSINA DE' BRACCHI", 0],
    ["C012", "MI", "CASSINA DE' GATTI", 0],
    ["C014", "MI", "CASSINA DE' PECCHI", 1],
    ["C013", "MI", "CASSINA DEL PERO", 0],
    ["C015", "MI", "CASSINA FERRARA", 0],
    ["C016", "CO", "CASSINA MARIAGA", 0],
    ["C017", "MI", "CASSINA NUOVA", 0],
    ["C018", "MI", "CASSINA PERTUSELLA", 0],
    ["C019", "MI", "CASSINA POBBIA", 0],
    ["C020", "CO", "CASSINA RIZZARDI", 1],
    ["C021", "MI", "CASSINA SAVINA", 0],
    ["C023", "MI", "CASSINA TRIULZA", 0],
    ["C024", "LC", "CASSINA VALSASSINA", 1],
    ["C025", "MI", "CASSINA VERGHERA", 0],
    ["C022", "AT", "CASSINASCO", 1],
    ["C027", "AL", "CASSINE", 1],
    ["C028", "PV", "CASSINE CALDERARI", 0],
    ["C031", "PV", "CASSINE SIRIGARI", 0],
    ["C032", "PV", "CASSINE TOLENTINE", 0],
    ["C030", "AL", "CASSINELLE", 1],
    ["C033", "MI", "CASSINETTA DI LUGAGNANO", 1],
    ["C034", "FR", "CASSINO", 1],
    ["C035", "PV", "CASSINO", 0],
    ["C036", "MI", "CASSINO D'ALBERI", 0],
    ["C035", "PV", "CASSINO PO", 0],
    ["C037", "VI", "CASSOLA", 1],
    ["C038", "PV", "CASSOLNOVO", 1],
    ["C039", "CZ", "CASTAGNA", 0],
    ["C040", "TE", "CASTAGNA", 0],
    ["C041", "VR", "CASTAGNARO", 1],
    ["C042", "MI", "CASTAGNATE OLONA", 0],
    ["C043", "TN", "CASTAGNE'", 0],
    ["C044", "PI", "CASTAGNETO", 0],
    ["C045", "TO", "CASTAGNETO", 0],
    ["C044", "LI", "CASTAGNETO CARDUCCI", 1],
    ["C044", "PI", "CASTAGNETO MARITTIMO", 0],
    ["C045", "TO", "CASTAGNETO PO", 1],
    ["C046", "CN", "CASTAGNITO", 1],
    ["C047", "AL", "CASTAGNOLE", 0],
    ["C048", "TO", "CASTAGNOLE", 0],
    ["C049", "AT", "CASTAGNOLE DELLE LANZE", 1],
    ["C047", "AT", "CASTAGNOLE MONFERRATO", 1],
    ["C048", "TO", "CASTAGNOLE PIEMONTE", 1],
    ["C050", "PV", "CASTANA", 1],
    ["C051", "ME", "CASTANIA", 0],
    ["C052", "MI", "CASTANO", 0],
    ["C052", "MI", "CASTANO PRIMO", 1],
    ["C053", "PV", "CASTEGGIO", 1],
    ["C054", "MI", "CASTEGNATE", 0],
    ["C054", "MI", "CASTEGNATE OLONA", 0],
    ["C055", "BS", "CASTEGNATO", 1],
    ["C056", "VI", "CASTEGNERO", 1],
    ["C058", "AV", "CASTEL BARONIA", 1],
    ["C064", "AT", "CASTEL BOGLIONE", 1],
    ["C065", "RA", "CASTEL BOLOGNESE", 1],
    ["B494", "CE", "CASTEL CAMPAGNANO", 1],
    ["C040", "TE", "CASTEL CASTAGNA", 1],
    ["C068", "VT", "CASTEL CELLESI", 0],
    ["C070", "AP", "CASTEL CLEMENTINO", 0],
    ["C071", "AN", "CASTEL COLONNA", 0],
    ["C183", "TN", "CASTEL CONDINO", 1],
    ["C075", "BO", "CASTEL D'AIANO", 1],
    ["C076", "MN", "CASTEL D'ARIO", 1],
    ["C078", "VR", "CASTEL D'AZZANO", 1],
    ["C079", "BG", "CASTEL DE' CONTI", 0],
    ["C087", "AL", "CASTEL DE' RATTI", 0],
    ["C082", "IS", "CASTEL DEL GIUDICE", 1],
    ["C083", "AQ", "CASTEL DEL MONTE", 1],
    ["C084", "UD", "CASTEL DEL MONTE UDINESE", 0],
    ["C085", "GR", "CASTEL DEL PIANO", 1],
    ["C086", "BO", "CASTEL DEL RIO", 1],
    ["B969", "BO", "CASTEL DI CASIO", 1],
    ["C088", "AP", "CASTEL DI CROCE", 0],
    ["C090", "AQ", "CASTEL DI IERI", 1],
    ["C091", "CT", "CASTEL DI IUDICA", 1],
    ["C092", "PG", "CASTEL DI LAGO", 0],
    ["C093", "AP", "CASTEL DI LAMA", 1],
    ["C094", "ME", "CASTEL DI LUCIO", 1],
    ["C096", "AQ", "CASTEL DI SANGRO", 1],
    ["C097", "CE", "CASTEL DI SASSO", 1],
    ["C098", "RI", "CASTEL DI TORA", 1],
    ["C099", "GO", "CASTEL DOBRA", 0],
    ["C102", "AR", "CASTEL FOCOGNANO", 1],
    ["C114", "CH", "CASTEL FRENTANO", 1],
    ["C115", "CR", "CASTEL GABBIANO", 1],
    ["C116", "RM", "CASTEL GANDOLFO", 1],
    ["C117", "TR", "CASTEL GIORGIO", 1],
    ["C118", "MN", "CASTEL GOFFREDO", 1],
    ["C121", "BO", "CASTEL GUELFO", 0],
    ["C121", "BO", "CASTEL GUELFO DI BOLOGNA", 1],
    ["C124", "FM", "CASTEL IABLANIZZA", 0],
    ["M354", "TN", "CASTEL IVANO", 1],
    ["C131", "PV", "CASTEL LAMBRO", 0],
    ["C203", "RM", "CASTEL MADAMA", 1],
    ["C204", "BO", "CASTEL MAGGIORE", 1],
    ["C208", "BS", "CASTEL MELLA", 1],
    ["C210", "ME", "CASTEL MOLA", 0],
    ["C211", "CE", "CASTEL MORRONE", 1],
    ["C252", "PG", "CASTEL RITALDI", 1],
    ["C252", "PG", "CASTEL RITALDI E SAN GIOVANNI", 0],
    ["C253", "AT", "CASTEL ROCCHERO", 1],
    ["C255", "BG", "CASTEL ROZZONE", 1],
    ["C256", "SA", "CASTEL RUGGERO", 0],
    ["C257", "PG", "CASTEL SAN BENEDETTO REATINO", 0],
    ["C258", "PG", "CASTEL SAN FELICE", 0],
    ["C259", "SA", "CASTEL SAN GIORGIO", 1],
    ["C260", "PG", "CASTEL SAN GIOVANNI", 0],
    ["C261", "PC", "CASTEL SAN GIOVANNI", 1],
    ["C260", "PG", "CASTEL SAN GIOVANNI DI SPOLETO", 0],
    ["C262", "SA", "CASTEL SAN LORENZO", 1],
    ["C263", "AR", "CASTEL SAN NICCOLO'", 1],
    ["C264", "AL", "CASTEL SAN PIETRO", 0],
    ["C265", "BO", "CASTEL SAN PIETRO", 0],
    ["C266", "RM", "CASTEL SAN PIETRO", 0],
    ["C264", "AL", "CASTEL SAN PIETRO MONFERRATO", 0],
    ["C265", "BO", "CASTEL SAN PIETRO NELL'EMILIA", 0],
    ["C266", "RM", "CASTEL SAN PIETRO ROMANO", 1],
    ["C265", "BO", "CASTEL SAN PIETRO TERME", 1],
    ["C270", "IS", "CASTEL SAN VINCENZO", 1],
    ["C268", "RI", "CASTEL SANT'ANGELO", 1],
    ["C269", "VT", "CASTEL SANT'ELIA", 1],
    ["C114", "CH", "CASTEL TRENTANO", 0],
    ["C282", "AO", "CASTEL VERRES", 0],
    ["C289", "TR", "CASTEL VISCARDO", 1],
    ["C110", "IM", "CASTEL VITTORIO", 1],
    ["C291", "CE", "CASTEL VOLTURNO", 1],
    ["C057", "PD", "CASTELBALDO", 1],
    ["C059", "MN", "CASTELBELFORTE", 1],
    ["C060", "AN", "CASTELBELLINO", 1],
    ["C061", "BZ", "CASTELBELLO", 0],
    ["C062", "BZ", "CASTELBELLO CIARDES", 0],
    ["C062", "BZ", "CASTELBELLO-CIARDES", 1],
    ["C063", "SV", "CASTELBIANCO", 1],
    ["C066", "CB", "CASTELBOTTACCIO", 1],
    ["C067", "PA", "CASTELBUONO", 1],
    ["C069", "SA", "CASTELCIVITA", 1],
    ["C072", "BS", "CASTELCOVATI", 1],
    ["C073", "TV", "CASTELCUCCO", 1],
    ["C074", "PA", "CASTELDACCIA", 1],
    ["C077", "BZ", "CASTELDARNE", 0],
    ["C080", "PU", "CASTELDELCI", 0],
    ["C080", "RN", "CASTELDELCI", 1],
    ["C081", "CN", "CASTELDELFINO", 1],
    ["C089", "CR", "CASTELDIDONE", 1],
    ["C095", "PU", "CASTELDIMEZZO", 0],
    ["C100", "AN", "CASTELFIDARDO", 1],
    ["C101", "FI", "CASTELFIORENTINO", 1],
    ["C103", "TN", "CASTELFONDO", 0],
    ["C104", "LT", "CASTELFORTE", 1],
    ["C105", "AV", "CASTELFRANCI", 1],
    ["C106", "BN", "CASTELFRANCO", 0],
    ["C107", "BO", "CASTELFRANCO", 0],
    ["C108", "CS", "CASTELFRANCO", 0],
    ["C109", "CR", "CASTELFRANCO", 0],
    ["C110", "IM", "CASTELFRANCO", 0],
    ["C111", "TV", "CASTELFRANCO", 0],
    ["C109", "CR", "CASTELFRANCO D'OGLIO", 0],
    ["C112", "AR", "CASTELFRANCO DI SOPRA", 0],
    ["C113", "PI", "CASTELFRANCO DI SOTTO", 1],
    ["C107", "MO", "CASTELFRANCO EMILIA", 1],
    ["C106", "BN", "CASTELFRANCO IN MISCANO", 1],
    ["C107", "MO", "CASTELFRANCO NELL'EMILIA", 0],
    ["M322", "AR", "CASTELFRANCO PIANDISCO'", 1],
    ["C111", "TV", "CASTELFRANCO VENETO", 1],
    ["M393", "LO", "CASTELGERUNDO", 1],
    ["C119", "VI", "CASTELGOMBERTO", 1],
    ["C120", "PZ", "CASTELGRANDE", 1],
    ["C122", "RO", "CASTELGUGLIELMO", 1],
    ["C123", "CH", "CASTELGUIDONE", 1],
    ["C127", "AT", "CASTELL'ALFERO", 1],
    ["C145", "PC", "CASTELL'ARQUATO", 1],
    ["C147", "GR", "CASTELL'AZZARA", 1],
    ["C051", "ME", "CASTELL'UMBERTO", 1],
    ["C125", "SA", "CASTELLABATE", 1],
    ["C126", "AQ", "CASTELLAFIUME", 1],
    ["C128", "TE", "CASTELLALTO", 1],
    ["C129", "NA", "CASTELLAMARE", 0],
    ["C132", "PE", "CASTELLAMMARE", 0],
    ["C132", "PE", "CASTELLAMMARE ADRIATICO", 0],
    ["C130", "TP", "CASTELLAMMARE DEL GOLFO", 1],
    ["C129", "NA", "CASTELLAMMARE DI STABIA", 1],
    ["C133", "TO", "CASTELLAMONTE", 1],
    ["C134", "BA", "CASTELLANA", 0],
    ["C134", "BA", "CASTELLANA GROTTE", 1],
    ["C135", "PA", "CASTELLANA SICULA", 1],
    ["C136", "TA", "CASTELLANETA", 1],
    ["C137", "AL", "CASTELLANIA", 0],
    ["C137", "AL", "CASTELLANIA COPPI", 1],
    ["C138", "TN", "CASTELLANO", 0],
    ["C139", "VA", "CASTELLANZA", 1],
    ["C140", "CN", "CASTELLAR", 0],
    ["C142", "AL", "CASTELLAR GUIDOBONO", 1],
    ["C141", "RE", "CASTELLARANO", 1],
    ["C076", "MN", "CASTELLARO", 0],
    ["C143", "IM", "CASTELLARO", 1],
    ["C144", "PV", "CASTELLARO DE' GIORGI", 0],
    ["C146", "BL", "CASTELLAVAZZO", 0],
    ["C148", "AL", "CASTELLAZZO", 0],
    ["C149", "NO", "CASTELLAZZO", 0],
    ["C148", "AL", "CASTELLAZZO BORMIDA", 1],
    ["C150", "MI", "CASTELLAZZO DE' BARZI", 0],
    ["C149", "NO", "CASTELLAZZO NOVARESE", 1],
    ["C151", "VC", "CASTELLENGO", 0],
    ["C152", "AN", "CASTELLEONE", 0],
    ["C153", "CR", "CASTELLEONE", 1],
    ["C152", "AN", "CASTELLEONE DI SUASA", 1],
    ["C154", "AT", "CASTELLERO", 1],
    ["B154", "VR", "CASTELLETTO", 0],
    ["C164", "PV", "CASTELLETTO", 0],
    ["C155", "BI", "CASTELLETTO CERVO", 1],
    ["C156", "AL", "CASTELLETTO D'ERRO", 1],
    ["C158", "AL", "CASTELLETTO D'ORBA", 1],
    ["C157", "PV", "CASTELLETTO DI BRANDUZZO", 1],
    ["B154", "VR", "CASTELLETTO DI BRENZONE", 0],
    ["C159", "MI", "CASTELLETTO MENDOSIO", 0],
    ["C160", "AL", "CASTELLETTO MERLI", 1],
    ["C161", "AT", "CASTELLETTO MOLINA", 1],
    ["C162", "AL", "CASTELLETTO MONFERRATO", 1],
    ["C163", "CN", "CASTELLETTO MONFORTE", 0],
    ["C164", "PV", "CASTELLETTO PO", 0],
    ["C162", "AL", "CASTELLETTO SCAZZOSO", 0],
    ["C166", "NO", "CASTELLETTO SOPRA TICINO", 1],
    ["C165", "CN", "CASTELLETTO STURA", 1],
    ["C167", "CN", "CASTELLETTO UZZONE", 1],
    ["C168", "VC", "CASTELLETTO VILLA", 0],
    ["C169", "TE", "CASTELLI", 1],
    ["C079", "BG", "CASTELLI CALEPIO", 1],
    ["C171", "NO", "CASTELLI CUSIANI", 0],
    ["C172", "SI", "CASTELLINA IN CHIANTI", 1],
    ["C174", "PI", "CASTELLINA MARITTIMA", 1],
    ["C173", "CN", "CASTELLINALDO", 0],
    ["C173", "CN", "CASTELLINALDO D'ALBA", 1],
    ["C175", "CB", "CASTELLINO", 0],
    ["C176", "CN", "CASTELLINO", 0],
    ["C175", "CB", "CASTELLINO DEL BIFERNO", 1],
    ["C176", "CN", "CASTELLINO TANARO", 1],
    ["C177", "FR", "CASTELLIRI", 1],
    ["C178", "CE", "CASTELLO", 0],
    ["C179", "CO", "CASTELLO", 0],
    ["C180", "CO", "CASTELLO", 0],
    ["C181", "CO", "CASTELLO", 0],
    ["C182", "TN", "CASTELLO", 0],
    ["C183", "TN", "CASTELLO", 0],
    ["B312", "VA", "CASTELLO CABIAGLIO", 1],
    ["C184", "PV", "CASTELLO D'AGOGNA", 1],
    ["C178", "CE", "CASTELLO D'ALIFE", 0],
    ["C185", "BO", "CASTELLO D'ARGILE", 1],
    ["C178", "CE", "CASTELLO DEL MATESE", 1],
    ["C186", "SO", "CASTELLO DELL'ACQUA", 1],
    ["A300", "AT", "CASTELLO DI ANNONE", 1],
    ["C187", "LC", "CASTELLO DI BRIANZA", 1],
    ["B494", "CE", "CASTELLO DI CAMPAGNANO", 0],
    ["C188", "NA", "CASTELLO DI CISTERNA", 1],
    ["C189", "TN", "CASTELLO DI FIEMME", 0],
    ["C190", "TV", "CASTELLO DI GODEGO", 1],
    ["C077", "BZ", "CASTELLO DI PUSTERIA", 0],
    ["C191", "BO", "CASTELLO DI SERRAVALLE", 0],
    ["C180", "CO", "CASTELLO SOPRA LECCO", 0],
    ["C194", "TN", "CASTELLO TESINO", 1],
    ["C179", "CO", "CASTELLO VALSOLDA", 0],
    ["C181", "VA", "CASTELLO VALTRAVAGLIA", 0],
    ["C189", "TN", "CASTELLO-MOLINA DI FIEMME", 1],
    ["C192", "CB", "CASTELLONE AL VOLTURNO", 0],
    ["C193", "LT", "CASTELLONORATO", 0],
    ["C195", "MN", "CASTELLUCCHIO", 1],
    ["C069", "SA", "CASTELLUCCIA", 0],
    ["C094", "ME", "CASTELLUCCIO", 0],
    ["C177", "CE", "CASTELLUCCIO", 0],
    ["C196", "PZ", "CASTELLUCCIO", 0],
    ["C197", "CB", "CASTELLUCCIO ACQUABORRANO", 0],
    ["C198", "FG", "CASTELLUCCIO DEI SAURI", 1],
    ["C177", "CE", "CASTELLUCCIO DI SORA", 0],
    ["C200", "CB", "CASTELLUCCIO IN VERRINO", 0],
    ["C199", "PZ", "CASTELLUCCIO INFERIORE", 1],
    ["C201", "PZ", "CASTELLUCCIO SUPERIORE", 1],
    ["C202", "FG", "CASTELLUCCIO VALMAGGIORE", 1],
    ["C205", "CN", "CASTELMAGNO", 1],
    ["C206", "CO", "CASTELMARTE", 1],
    ["C207", "RO", "CASTELMASSA", 1],
    ["C197", "CB", "CASTELMAURO", 1],
    ["C209", "PZ", "CASTELMEZZANO", 1],
    ["C210", "ME", "CASTELMOLA", 1],
    ["C212", "MI", "CASTELNOVATE", 0],
    ["C213", "PV", "CASTELNOVETTO", 1],
    ["C214", "CN", "CASTELNOVO", 0],
    ["C215", "RO", "CASTELNOVO", 0],
    ["C216", "TN", "CASTELNOVO", 0],
    ["C217", "UD", "CASTELNOVO", 0],
    ["C215", "RO", "CASTELNOVO BARIANO", 1],
    ["C217", "PN", "CASTELNOVO DEL FRIULI", 1],
    ["C218", "RE", "CASTELNOVO DI SOTTO", 1],
    ["C219", "RE", "CASTELNOVO NE' MONTI", 1],
    ["C114", "CH", "CASTELNUOVO", 0],
    ["C208", "BS", "CASTELNUOVO", 0],
    ["C216", "TN", "CASTELNUOVO", 1],
    ["C220", "CO", "CASTELNUOVO", 0],
    ["C221", "CR", "CASTELNUOVO", 0],
    ["C222", "FG", "CASTELNUOVO", 0],
    ["C223", "FR", "CASTELNUOVO", 0],
    ["C224", "RI", "CASTELNUOVO", 0],
    ["C225", "VR", "CASTELNUOVO", 0],
    ["C220", "CO", "CASTELNUOVO BAZENTE", 0],
    ["C226", "AT", "CASTELNUOVO BELBO", 1],
    ["C227", "SI", "CASTELNUOVO BERARDENGA", 1],
    ["C228", "LO", "CASTELNUOVO BOCCA D'ADDA", 1],
    ["C229", "AL", "CASTELNUOVO BORMIDA", 1],
    ["C220", "CO", "CASTELNUOVO BOZZENTE", 1],
    ["C230", "AT", "CASTELNUOVO CALCEA", 1],
    ["C231", "SA", "CASTELNUOVO CILENTO", 1],
    ["C221", "CR", "CASTELNUOVO CREMASCO", 0],
    ["C232", "AL", "CASTELNUOVO D'ASTI", 0],
    ["C238", "FM", "CASTELNUOVO D'ISTRIA", 0],
    ["C225", "VR", "CASTELNUOVO DEL GARDA", 1],
    ["C233", "CR", "CASTELNUOVO DEL VESCOVO", 0],
    ["C234", "CR", "CASTELNUOVO DEL ZAPPA", 0],
    ["C222", "FG", "CASTELNUOVO DELLA DAUNIA", 1],
    ["C214", "CN", "CASTELNUOVO DI CEVA", 1],
    ["C235", "SA", "CASTELNUOVO DI CONZA", 1],
    ["C224", "RI", "CASTELNUOVO DI FARFA", 1],
    ["C236", "LU", "CASTELNUOVO DI GARFAGNANA", 1],
    ["C237", "RM", "CASTELNUOVO DI PORTO", 1],
    ["C244", "PI", "CASTELNUOVO DI VAL DI CECINA", 1],
    ["C225", "VR", "CASTELNUOVO DI VERONA", 0],
    ["C232", "AT", "CASTELNUOVO DON BOSCO", 1],
    ["C239", "CR", "CASTELNUOVO GHERARDI", 0],
    ["C240", "SP", "CASTELNUOVO MAGRA", 1],
    ["C241", "TO", "CASTELNUOVO NIGRA", 1],
    ["C223", "FR", "CASTELNUOVO PARANO", 1],
    ["C242", "MO", "CASTELNUOVO RANGONE", 1],
    ["C243", "AL", "CASTELNUOVO SCRIVIA", 1],
    ["C244", "PI", "CASTELNUOVO VAL DI CECINA", 0],
    ["C245", "BN", "CASTELPAGANO", 1],
    ["C246", "IS", "CASTELPETROSO", 1],
    ["M225", "TN", "CASTELPIETRA", 0],
    ["C247", "IS", "CASTELPIZZUTO", 1],
    ["C248", "AN", "CASTELPLANIO", 1],
    ["C249", "CR", "CASTELPONZONE", 0],
    ["C250", "BN", "CASTELPOTO", 1],
    ["C251", "MC", "CASTELRAIMONDO", 1],
    ["C254", "BZ", "CASTELROTTO", 1],
    ["C267", "MC", "CASTELSANTANGELO", 0],
    ["C267", "MC", "CASTELSANTANGELO SUL NERA", 1],
    ["C271", "PZ", "CASTELSARACENO", 1],
    ["C272", "SS", "CASTELSARDO", 1],
    ["C273", "VA", "CASTELSEPRIO", 1],
    ["B968", "KR", "CASTELSILANO", 1],
    ["C274", "AL", "CASTELSPINA", 1],
    ["C275", "AG", "CASTELTERMINI", 1],
    ["C181", "VA", "CASTELVECCANA", 1],
    ["C098", "PG", "CASTELVECCHIO", 0],
    ["C276", "GE", "CASTELVECCHIO", 0],
    ["C277", "IM", "CASTELVECCHIO", 0],
    ["C278", "AQ", "CASTELVECCHIO CALVISIO", 1],
    ["C276", "SV", "CASTELVECCHIO DI ROCCA BARBENA", 1],
    ["C277", "IM", "CASTELVECCHIO DI SANTA MARIA MAGGIORE", 0],
    ["C279", "AQ", "CASTELVECCHIO SUBEQUO", 1],
    ["C280", "BN", "CASTELVENERE", 1],
    ["B129", "CR", "CASTELVERDE", 1],
    ["C064", "AL", "CASTELVERO", 0],
    ["C281", "AL", "CASTELVERO", 0],
    ["C281", "AL", "CASTELVERO D'ASTI", 0],
    ["C200", "IS", "CASTELVERRINO", 1],
    ["C283", "AV", "CASTELVETERE", 0],
    ["C284", "BN", "CASTELVETERE", 0],
    ["C285", "RC", "CASTELVETERE", 0],
    ["C283", "AV", "CASTELVETERE DI CALORE", 0],
    ["C284", "BN", "CASTELVETERE IN VAL FORTORE", 1],
    ["C283", "AV", "CASTELVETERE SUL CALORE", 1],
    ["C286", "TP", "CASTELVETRANO", 1],
    ["C287", "MO", "CASTELVETRO", 0],
    ["C288", "PC", "CASTELVETRO", 0],
    ["C287", "MO", "CASTELVETRO DI MODENA", 1],
    ["C288", "PC", "CASTELVETRO PIACENTINO", 1],
    ["C290", "CR", "CASTELVISCONTI", 1],
    ["C292", "BO", "CASTENASO", 1],
    ["C293", "BS", "CASTENEDOLO", 1],
    ["M288", "CA", "CASTIADAS", 0],
    ["M288", "SU", "CASTIADAS", 1],
    ["C294", "AO", "CASTIGLION DORA", 0],
    ["C318", "AR", "CASTIGLION FIBOCCHI", 1],
    ["C319", "AR", "CASTIGLION FIORENTINO", 1],
    ["C320", "AR", "CASTIGLION UBERTINI", 0],
    ["C295", "AL", "CASTIGLIONE", 0],
    ["C296", "BO", "CASTIGLIONE", 0],
    ["C297", "CT", "CASTIGLIONE", 0],
    ["C298", "CH", "CASTIGLIONE", 0],
    ["C299", "CO", "CASTIGLIONE", 0],
    ["C300", "CO", "CASTIGLIONE", 0],
    ["C301", "CS", "CASTIGLIONE", 0],
    ["C302", "GE", "CASTIGLIONE", 0],
    ["C303", "MS", "CASTIGLIONE", 0],
    ["C304", "MI", "CASTIGLIONE", 0],
    ["C305", "NO", "CASTIGLIONE", 0],
    ["C306", "SA", "CASTIGLIONE", 0],
    ["C307", "TO", "CASTIGLIONE", 0],
    ["C308", "PE", "CASTIGLIONE A CASAURIA", 1],
    ["C308", "TE", "CASTIGLIONE ALLA PESCARA", 0],
    ["C302", "GE", "CASTIGLIONE CHIAVARESE", 1],
    ["C301", "CS", "CASTIGLIONE COSENTINO", 1],
    ["C304", "LO", "CASTIGLIONE D'ADDA", 1],
    ["C295", "AL", "CASTIGLIONE D'ASTI", 0],
    ["C299", "CO", "CASTIGLIONE D'INTELVI", 0],
    ["C313", "SI", "CASTIGLIONE D'ORCIA", 1],
    ["C305", "NO", "CASTIGLIONE D'OSSOLA", 0],
    ["C296", "BO", "CASTIGLIONE DEI PEPOLI", 1],
    ["C306", "SA", "CASTIGLIONE DEL GENOVESI", 1],
    ["C309", "PG", "CASTIGLIONE DEL LAGO", 1],
    ["C310", "GR", "CASTIGLIONE DELLA PESCAIA", 1],
    ["C311", "TE", "CASTIGLIONE DELLA VALLE", 0],
    ["C312", "MN", "CASTIGLIONE DELLE STIVIERE", 1],
    ["C303", "LU", "CASTIGLIONE DI GARFAGNANA", 1],
    ["C297", "CT", "CASTIGLIONE DI SICILIA", 1],
    ["C314", "CN", "CASTIGLIONE FALLETTO", 1],
    ["C315", "VT", "CASTIGLIONE IN TEVERINA", 1],
    ["C298", "CH", "CASTIGLIONE MESSER MARINO", 1],
    ["C316", "TE", "CASTIGLIONE MESSER RAIMONDO", 1],
    ["C300", "VA", "CASTIGLIONE OLONA", 1],
    ["C317", "CN", "CASTIGLIONE TINELLA", 1],
    ["C307", "TO", "CASTIGLIONE TORINESE", 1],
    ["C321", "AP", "CASTIGNANO", 1],
    ["C322", "TE", "CASTILENTI", 1],
    ["C323", "CN", "CASTINO", 1],
    ["C324", "BG", "CASTIONE", 0],
    ["C325", "SO", "CASTIONE", 0],
    ["C326", "VR", "CASTIONE", 0],
    ["C325", "SO", "CASTIONE ANDEVENNO", 1],
    ["C324", "BG", "CASTIONE DELLA PRESOLANA", 1],
    ["C326", "VR", "CASTIONE VERONESE", 0],
    ["C327", "UD", "CASTIONS DI STRADA", 1],
    ["C328", "MI", "CASTIRAGA DA REGGIO", 0],
    ["C329", "LO", "CASTIRAGA VIDARDO", 1],
    ["C330", "BS", "CASTO", 1],
    ["C331", "AP", "CASTORANO", 1],
    ["C332", "BS", "CASTREZZATO", 1],
    ["C333", "BS", "CASTREZZONE", 0],
    ["C334", "LE", "CASTRI DI LECCE", 1],
    ["C334", "LE", "CASTRI FRANCONE", 0],
    ["C335", "LE", "CASTRIGNANO DE' GRECI", 1],
    ["C336", "LE", "CASTRIGNANO DEL CAPO", 1],
    ["C337", "BG", "CASTRO", 1],
    ["C338", "RM", "CASTRO", 0],
    ["M261", "LE", "CASTRO", 1],
    ["C338", "FR", "CASTRO DEI VOLSCI", 1],
    ["C339", "FC", "CASTROCARO E TERRA DEL SOLE", 0],
    ["C339", "FC", "CASTROCARO TERME E TERRA DEL SOLE", 1],
    ["C340", "FR", "CASTROCIELO", 1],
    ["C341", "AG", "CASTROFILIPPO", 1],
    ["C342", "EN", "CASTROGIOVANNI", 0],
    ["C108", "CS", "CASTROLIBERO", 1],
    ["C343", "VA", "CASTRONNO", 1],
    ["C344", "PA", "CASTRONOVO", 0],
    ["C344", "PA", "CASTRONOVO DI SICILIA", 1],
    ["C345", "PZ", "CASTRONUOVO", 0],
    ["C345", "PZ", "CASTRONUOVO DI SANT'ANDREA", 1],
    ["C346", "CB", "CASTROPIGNANO", 1],
    ["C347", "ME", "CASTROREALE", 1],
    ["C348", "CS", "CASTROREGIO", 1],
    ["C349", "CS", "CASTROVILLARI", 1],
    ["C350", "RC", "CATAFORIO", 0],
    ["C351", "CT", "CATANIA", 1],
    ["C352", "CZ", "CATANZARO", 1],
    ["C353", "EN", "CATENANUOVA", 1],
    ["C354", "PE", "CATIGNANO", 1],
    ["C355", "RC", "CATONA", 0],
    ["C356", "AG", "CATTOLICA", 0],
    ["C357", "RN", "CATTOLICA", 1],
    ["C356", "AG", "CATTOLICA ERACLEA", 1],
    ["C285", "RC", "CAULONIA", 1],
    ["C358", "TN", "CAURIA", 0],
    ["C359", "BN", "CAUTANO", 1],
    ["C360", "PV", "CAVA", 0],
    ["C361", "SA", "CAVA", 0],
    ["B824", "PV", "CAVA CARPIGNANO", 0],
    ["C361", "SA", "CAVA DE' TIRRENI", 1],
    ["C360", "PV", "CAVA MANARA", 1],
    ["C386", "CR", "CAVA TIGOZZI", 0],
    ["C362", "LO", "CAVACURTA", 0],
    ["C363", "BI", "CAVAGLIA'", 1],
    ["C364", "NO", "CAVAGLIETTO", 1],
    ["C365", "NO", "CAVAGLIO", 0],
    ["C366", "NO", "CAVAGLIO", 0],
    ["C365", "NO", "CAVAGLIO D'AGOGNA", 1],
    ["C366", "NO", "CAVAGLIO SAN DONNINO", 0],
    ["C367", "VB", "CAVAGLIO-SPOCCIA", 0],
    ["C368", "PV", "CAVAGNERA", 0],
    ["C369", "TO", "CAVAGNOLO", 1],
    ["C370", "VR", "CAVAION", 0],
    ["C370", "VR", "CAVAION VERONESE", 1],
    ["C371", "MI", "CAVAIONE", 0],
    ["C372", "TN", "CAVALESE", 1],
    ["C373", "CR", "CAVALLARA", 0],
    ["C374", "CO", "CAVALLASCA", 0],
    ["C375", "CN", "CAVALLERLEONE", 1],
    ["C376", "CN", "CAVALLERMAGGIORE", 1],
    ["C377", "LE", "CAVALLINO", 1],
    ["M308", "VE", "CAVALLINO-TREPORTI", 1],
    ["C378", "NO", "CAVALLIRIO", 1],
    ["C379", "NO", "CAVANDONE", 0],
    ["C380", "TN", "CAVARENO", 1],
    ["C381", "CO", "CAVARGNA", 1],
    ["C382", "VA", "CAVARIA CON PREMEZZO", 1],
    ["C382", "MI", "CAVARIA ED UNITI", 0],
    ["C383", "VE", "CAVARZERE", 1],
    ["C384", "TV", "CAVASO", 0],
    ["C384", "TV", "CAVASO DEL TOMBA", 1],
    ["C385", "UD", "CAVASSO", 0],
    ["C385", "PN", "CAVASSO NUOVO", 1],
    ["C387", "AL", "CAVATORE", 1],
    ["C388", "VE", "CAVAZUCCHERINA", 0],
    ["C389", "UD", "CAVAZZO", 0],
    ["C389", "UD", "CAVAZZO CARNICO", 1],
    ["C390", "RM", "CAVE", 1],
    ["C391", "TS", "CAVE AUREMIANE", 0],
    ["C392", "TN", "CAVEDAGO", 1],
    ["C393", "TN", "CAVEDINE", 1],
    ["C394", "MI", "CAVENAGO", 0],
    ["C395", "MI", "CAVENAGO", 0],
    ["C394", "LO", "CAVENAGO D'ADDA", 1],
    ["C395", "MB", "CAVENAGO DI BRIANZA", 1],
    ["C395", "MI", "CAVENAGO DI BRIANZA", 0],
    ["C396", "BG", "CAVERNAGO", 1],
    ["C397", "CO", "CAVERSACCIO", 0],
    ["C398", "MO", "CAVEZZO", 1],
    ["C399", "MI", "CAVIAGA", 0],
    ["C400", "TN", "CAVIZZANA", 1],
    ["C401", "BZ", "CAVOLANO", 0],
    ["C402", "VA", "CAVONA", 0],
    ["C403", "TO", "CAVORETTO", 0],
    ["C404", "TO", "CAVOUR", 1],
    ["M226", "TN", "CAVRASTO", 0],
    ["C405", "RE", "CAVRIAGO", 1],
    ["C406", "MN", "CAVRIANA", 1],
    ["C407", "AR", "CAVRIGLIA", 1],
    ["Z530", "EE", "CAYMAN (ISOLE)", 1],
    ["C408", "BS", "CAZZAGO", 0],
    ["C409", "CO", "CAZZAGO", 0],
    ["C409", "VA", "CAZZAGO BRABBIA", 1],
    ["C408", "BS", "CAZZAGO SAN MARTINO", 1],
    ["C410", "BG", "CAZZANO", 0],
    ["C411", "MI", "CAZZANO", 0],
    ["C412", "VR", "CAZZANO", 0],
    ["C411", "MI", "CAZZANO BEFANA", 0],
    ["C411", "MI", "CAZZANO BESANA", 0],
    ["C412", "VR", "CAZZANO DI TRAMIGNA", 1],
    ["C410", "BG", "CAZZANO SANT'ANDREA", 1],
    ["B017", "MI", "CAZZIMANI", 0],
    ["B634", "VA", "CAZZONE", 0],
    ["Z156", "EE", "CECA REPUBBLICA", 1],
    ["C413", "FR", "CECCANO", 1],
    ["C414", "PV", "CECIMA", 1],
    ["C415", "LI", "CECINA", 1],
    ["C416", "GO", "CECONICO", 0],
    ["Z105", "EE", "CECOSLOVACCHIA", 0],
    ["C417", "BS", "CEDEGOLO", 1],
    ["C418", "SO", "CEDRASCO", 1],
    ["C419", "MI", "CEDRATE", 0],
    ["C420", "PA", "CEFALA' DIANA", 1],
    ["C421", "PA", "CEFALU'", 1],
    ["C422", "VE", "CEGGIA", 1],
    ["C423", "BA", "CEGLIE", 0],
    ["C424", "BR", "CEGLIE", 0],
    ["C425", "FM", "CEGLIE", 0],
    ["C423", "BA", "CEGLIE DEL CAMPO", 0],
    ["C424", "BR", "CEGLIE MESSAPICA", 1],
    ["C424", "BR", "CEGLIE MESSAPICO", 0],
    ["C426", "AQ", "CELANO", 1],
    ["C427", "TN", "CELENTINO", 0],
    ["C428", "CH", "CELENZA", 0],
    ["C429", "FG", "CELENZA", 0],
    ["C428", "CH", "CELENZA SUL FRIGNO", 0],
    ["C428", "CH", "CELENZA SUL TRIGNO", 1],
    ["C429", "FG", "CELENZA VALFORTORE", 1],
    ["C430", "CS", "CELICO", 1],
    ["C431", "VA", "CELINA", 0],
    ["C432", "AL", "CELLA", 0],
    ["C433", "CR", "CELLA", 0],
    ["C434", "PV", "CELLA", 0],
    ["C435", "CR", "CELLA DATI", 1],
    ["C434", "PV", "CELLA DI BOBBIO", 0],
    ["C432", "AL", "CELLA MONTE", 1],
    ["C436", "BA", "CELLAMARE", 1],
    ["C437", "CS", "CELLARA", 1],
    ["C438", "AT", "CELLARENGO", 1],
    ["C439", "BS", "CELLATICA", 1],
    ["C440", "AL", "CELLE", 0],
    ["C441", "CN", "CELLE", 0],
    ["C442", "FG", "CELLE", 0],
    ["C443", "GE", "CELLE", 0],
    ["C444", "SA", "CELLE", 0],
    ["C444", "SA", "CELLE DI BULGHERIA", 1],
    ["C441", "CN", "CELLE DI MACRA", 1],
    ["C442", "FG", "CELLE DI SAN VITO", 1],
    ["C440", "AT", "CELLE ENOMONDO", 1],
    ["C443", "SV", "CELLE LIGURE", 1],
    ["C442", "FG", "CELLE SAN VITO", 0],
    ["C445", "TN", "CELLEDIZZO", 0],
    ["C446", "VT", "CELLENO", 1],
    ["C447", "VT", "CELLERE", 1],
    ["C448", "BR", "CELLINO", 0],
    ["C449", "TE", "CELLINO", 0],
    ["C449", "TE", "CELLINO ATTANASIO", 1],
    ["C448", "BR", "CELLINO SAN MARCO", 1],
    ["C450", "VC", "CELLIO", 0],
    ["M398", "VC", "CELLIO CON BREIA", 1],
    ["M262", "CE", "CELLOLE", 1],
    ["C451", "PV", "CELPENCHIO", 0],
    ["C452", "TN", "CEMBRA", 0],
    ["M355", "TN", "CEMBRA LISIGNAGO", 1],
    ["C453", "CZ", "CENADI", 1],
    ["C454", "BG", "CENATE", 0],
    ["C455", "BG", "CENATE D'ARGON", 0],
    ["C456", "BG", "CENATE DI SOPRA", 0],
    ["C457", "BG", "CENATE DI SOTTO", 0],
    ["C456", "BG", "CENATE SOPRA", 1],
    ["C457", "BG", "CENATE SOTTO", 1],
    ["C458", "BL", "CENCENIGHE", 0],
    ["C458", "BL", "CENCENIGHE AGORDINO", 1],
    ["C459", "BG", "CENE", 1],
    ["C460", "TV", "CENEDA", 0],
    ["C461", "RO", "CENESELLI", 1],
    ["C462", "SV", "CENESI", 0],
    ["C463", "SV", "CENGIO", 1],
    ["C464", "BZ", "CENGLES", 0],
    ["C465", "IM", "CENOVA", 0],
    ["C467", "TN", "CENTA SAN NICOLO'", 0],
    ["C466", "CN", "CENTALLO", 1],
    ["C468", "CO", "CENTEMERO", 0],
    ["C469", "FE", "CENTO", 1],
    ["C470", "SA", "CENTOLA", 1],
    ["C471", "EN", "CENTORBI", 0],
    ["C472", "CZ", "CENTRACHE", 1],
    ["Z308", "EE", "CENTRAFRICANA REPUBBLICA", 0],
    ["C473", "BG", "CENTRISOLA", 0],
    ["M394", "CO", "CENTRO VALLE INTELVI", 1],
    ["Z308", "EE", "CENTROAFRICANA REPUBBLICA", 1],
    ["Z308", "EE", "CENTROAFRICANO IMPERO", 0],
    ["C471", "EN", "CENTURIPE", 1],
    ["C471", "EN", "CENTURUPI", 0],
    ["C474", "PE", "CEPAGATTI", 1],
    ["C475", "BG", "CEPINO", 0],
    ["C476", "BN", "CEPPALONI", 1],
    ["C477", "MI", "CEPPEDA", 0],
    ["C478", "VB", "CEPPO MORELLI", 1],
    ["C479", "FR", "CEPRANO", 1],
    ["C480", "EN", "CERAMI", 1],
    ["C481", "GE", "CERANESI", 1],
    ["C482", "CO", "CERANO", 0],
    ["C483", "NO", "CERANO", 1],
    ["C482", "CO", "CERANO D'INTELVI", 1],
    ["C484", "PV", "CERANOVA", 1],
    ["C485", "SA", "CERASO", 1],
    ["C486", "CB", "CERCEMAGGIORE", 1],
    ["C487", "TO", "CERCENASCO", 1],
    ["C488", "CB", "CERCEPICCOLA", 1],
    ["C489", "CS", "CERCHIARA", 0],
    ["C490", "RI", "CERCHIARA", 0],
    ["C489", "CS", "CERCHIARA DI CALABRIA", 1],
    ["C490", "RI", "CERCHIARA DI SABINA", 0],
    ["C491", "MI", "CERCHIATE", 0],
    ["C492", "AQ", "CERCHIO", 1],
    ["C493", "SO", "CERCINO", 1],
    ["C494", "UD", "CERCIVENTO", 1],
    ["C495", "NA", "CERCOLA", 1],
    ["C496", "PA", "CERDA", 1],
    ["C497", "TO", "CERE", 0],
    ["C498", "VR", "CEREA", 1],
    ["C499", "CO", "CEREDA", 0],
    ["C500", "RO", "CEREGNANO", 1],
    ["C501", "KR", "CERENZIA", 1],
    ["C497", "TO", "CERES", 1],
    ["C502", "MN", "CERESARA", 1],
    ["C503", "AL", "CERESETO", 1],
    ["C504", "CN", "CERESOLE", 0],
    ["C505", "TO", "CERESOLE", 0],
    ["C504", "CN", "CERESOLE ALBA", 1],
    ["C505", "TO", "CERESOLE REALE", 1],
    ["C506", "BG", "CERETE", 1],
    ["C507", "AL", "CERETTO", 0],
    ["C507", "AL", "CERETTO GRUE", 0],
    ["C508", "PV", "CERETTO LOMELLINA", 1],
    ["C509", "PV", "CERGNAGO", 1],
    ["C510", "SV", "CERIALE", 1],
    ["C511", "IM", "CERIANA", 1],
    ["C512", "MI", "CERIANO", 0],
    ["C512", "MB", "CERIANO LAGHETTO", 1],
    ["C512", "MI", "CERIANO LAGHETTO", 0],
    ["C513", "PC", "CERIGNALE", 1],
    ["C514", "FG", "CERIGNOLA", 1],
    ["C515", "CS", "CERISANO", 1],
    ["C516", "CO", "CERMENATE", 1],
    ["A022", "BZ", "CERMES", 1],
    ["C517", "TE", "CERMIGNANO", 1],
    ["C518", "RM", "CERNETO", 0],
    ["C519", "GO", "CERNIZZA GORIZIANA", 0],
    ["C520", "CO", "CERNOBBIO", 1],
    ["C523", "MI", "CERNUSCO ASINARIO", 0],
    ["C521", "CO", "CERNUSCO LOMBARDONE", 0],
    ["C521", "LC", "CERNUSCO LOMBARDONE", 1],
    ["C522", "CO", "CERNUSCO MONTEVECCHIA", 0],
    ["C523", "MI", "CERNUSCO SUL NAVIGLIO", 1],
    ["C518", "RM", "CERRETO", 0],
    ["C524", "AN", "CERRETO", 0],
    ["C525", "BN", "CERRETO", 0],
    ["C526", "NO", "CERRETO", 0],
    ["C527", "PG", "CERRETO", 0],
    ["C526", "BI", "CERRETO CASTELLO", 0],
    ["C528", "AT", "CERRETO D'ASTI", 1],
    ["C524", "AN", "CERRETO D'ESI", 1],
    ["C527", "PG", "CERRETO DI SPOLETO", 1],
    ["C507", "AL", "CERRETO GRUE", 1],
    ["C529", "FI", "CERRETO GUIDI", 1],
    ["C518", "RM", "CERRETO LAZIALE", 1],
    ["C525", "BN", "CERRETO SANNITA", 1],
    ["C508", "PV", "CERRETTO", 0],
    ["C528", "AL", "CERRETTO", 0],
    ["C530", "CN", "CERRETTO", 0],
    ["C530", "CN", "CERRETTO DELLE LANGHE", 0],
    ["C530", "CN", "CERRETTO LANGHE", 1],
    ["C508", "PV", "CERRETTO LOMELLINO", 0],
    ["C531", "AL", "CERRINA", 0],
    ["C531", "AL", "CERRINA MONFERRATO", 1],
    ["C532", "BI", "CERRIONE", 1],
    ["C533", "AL", "CERRO", 0],
    ["C534", "CB", "CERRO", 0],
    ["C535", "CO", "CERRO", 0],
    ["C536", "MI", "CERRO", 0],
    ["C537", "MI", "CERRO", 0],
    ["C538", "VR", "CERRO", 0],
    ["C536", "MI", "CERRO AL LAMBRO", 1],
    ["C534", "IS", "CERRO AL VOLTURNO", 1],
    ["C535", "VA", "CERRO LAGO MAGGIORE", 0],
    ["C537", "MI", "CERRO MAGGIORE", 1],
    ["C533", "AT", "CERRO TANARO", 1],
    ["C538", "VR", "CERRO VERONESE", 1],
    ["C539", "PZ", "CERSOSIMO", 1],
    ["C540", "FI", "CERTALDO", 1],
    ["C541", "PV", "CERTOSA DI PAVIA", 1],
    ["C542", "CZ", "CERVA", 1],
    ["C543", "RM", "CERVARA", 0],
    ["C543", "RM", "CERVARA DI ROMA", 1],
    ["C544", "PD", "CERVARESE SANTA CROCE", 1],
    ["C545", "FR", "CERVARO", 1],
    ["C546", "VC", "CERVAROLO", 0],
    ["C547", "CN", "CERVASCA", 1],
    ["C548", "VC", "CERVATTO", 1],
    ["C549", "BS", "CERVENO", 1],
    ["C550", "CN", "CERVERE", 1],
    ["C551", "PV", "CERVESINA", 1],
    ["C552", "RM", "CERVETERI", 1],
    ["C553", "RA", "CERVIA", 1],
    ["C554", "CS", "CERVICATI", 1],
    ["C555", "MI", "CERVIGNANO", 0],
    ["C556", "UD", "CERVIGNANO", 0],
    ["C555", "LO", "CERVIGNANO D'ADDA", 1],
    ["C556", "UD", "CERVIGNANO DEL FRIULI", 1],
    ["C557", "AV", "CERVINARA", 1],
    ["C558", "CE", "CERVINO", 1],
    ["C559", "IM", "CERVO", 1],
    ["C560", "CS", "CERZETO", 1],
    ["C561", "CE", "CESA", 1],
    ["C562", "BL", "CESANA", 0],
    ["C563", "CO", "CESANA", 0],
    ["C564", "TO", "CESANA", 0],
    ["C563", "LC", "CESANA BRIANZA", 1],
    ["C563", "CO", "CESANA DI BRIANZA", 0],
    ["C564", "TO", "CESANA TORINESE", 1],
    ["C565", "MI", "CESANO BOSCONE", 1],
    ["C566", "MB", "CESANO MADERNO", 1],
    ["C566", "MI", "CESANO MADERNO", 0],
    ["C567", "VB", "CESARA", 1],
    ["C568", "ME", "CESARO'", 1],
    ["C569", "MI", "CESATE", 1],
    ["C570", "UD", "CESCLANS", 0],
    ["C571", "PG", "CESELLI", 0],
    ["C572", "CO", "CESELLO BRIANZA", 0],
    ["C573", "FC", "CESENA", 1],
    ["C574", "FC", "CESENATICO", 1],
    ["C575", "PG", "CESI", 0],
    ["C576", "AV", "CESINALI", 1],
    ["C577", "BL", "CESIO", 0],
    ["C578", "IM", "CESIO", 1],
    ["C577", "BL", "CESIOMAGGIORE", 1],
    ["C579", "TO", "CESNOLA", 0],
    ["C580", "TV", "CESSALTO", 1],
    ["C581", "VV", "CESSANITI", 1],
    ["C582", "MC", "CESSAPALOMBO", 1],
    ["C583", "AT", "CESSOLE", 1],
    ["C584", "SA", "CETARA", 1],
    ["C585", "BS", "CETO", 1],
    ["C586", "BS", "CETO-CERVENO", 0],
    ["C587", "SI", "CETONA", 1],
    ["C588", "CS", "CETRARO", 1],
    ["C589", "CN", "CEVA", 1],
    ["C590", "BZ", "CEVES", 0],
    ["C591", "BS", "CEVO", 1],
    ["Z209", "EE", "CEYLON", 0],
    ["C593", "AO", "CHALLAND-SAINT-ANSELME", 1],
    ["C594", "AO", "CHALLAND-SAINT-VICTOR", 1],
    ["C592", "AO", "CHALLANT", 0],
    ["C593", "AO", "CHALLANT SAINT ANSELME", 0],
    ["C594", "AO", "CHALLANT SAINT VICTOR", 0],
    ["C595", "AO", "CHAMBAVE", 1],
    ["B491", "AO", "CHAMOIS", 1],
    ["C596", "AO", "CHAMPDEPRAZ", 1],
    ["C597", "TO", "CHAMPLAS DU COL", 0],
    ["B540", "AO", "CHAMPORCHER", 1],
    ["C598", "AO", "CHARVENSOD", 1],
    ["C294", "AO", "CHATILLON", 1],
    ["C599", "CN", "CHERASCO", 1],
    ["C600", "SS", "CHEREMULE", 1],
    ["C601", "PL", "CHERSO", 0],
    ["C602", "TO", "CHIABRANO", 0],
    ["C603", "NA", "CHIAIANO", 0],
    ["C603", "NA", "CHIAIANO ED UNITI", 0],
    ["C604", "TO", "CHIALAMBERTO", 1],
    ["C605", "VI", "CHIAMPO", 1],
    ["C606", "AV", "CHIANCHE", 1],
    ["C607", "AV", "CHIANCHETELLE", 0],
    ["C608", "SI", "CHIANCIANO", 0],
    ["C608", "SI", "CHIANCIANO TERME", 1],
    ["C609", "PI", "CHIANNI", 1],
    ["C610", "TO", "CHIANOC", 0],
    ["C610", "TO", "CHIANOCCO", 1],
    ["C611", "GO", "CHIAPOVANO", 0],
    ["C612", "SR", "CHIARAMONTE", 0],
    ["C612", "RG", "CHIARAMONTE GULFI", 1],
    ["C613", "SS", "CHIARAMONTI", 1],
    ["C614", "TV", "CHIARANO", 1],
    ["C615", "AN", "CHIARAVALLE", 1],
    ["C616", "CZ", "CHIARAVALLE", 0],
    ["C617", "MI", "CHIARAVALLE", 0],
    ["C616", "CZ", "CHIARAVALLE CENTRALE", 1],
    ["C617", "MI", "CHIARAVALLE MILANESE", 0],
    ["C618", "BS", "CHIARI", 1],
    ["C619", "PZ", "CHIAROMONTE", 1],
    ["C620", "IS", "CHIAUCI", 1],
    ["C621", "GE", "CHIAVARI", 1],
    ["C622", "VC", "CHIAVAZZA", 0],
    ["C623", "SO", "CHIAVENNA", 1],
    ["C624", "TO", "CHIAVERANO", 1],
    ["B705", "TO", "CHIAVRIE", 0],
    ["C625", "BZ", "CHIENES", 1],
    ["C626", "TN", "CHIENIS", 0],
    ["C627", "TO", "CHIERI", 1],
    ["C630", "BL", "CHIES D'ALPAGO", 1],
    ["C628", "SO", "CHIESA", 0],
    ["C628", "SO", "CHIESA IN VALMALENCO", 1],
    ["C629", "TO", "CHIESANOVA", 0],
    ["C629", "TO", "CHIESANUOVA", 1],
    ["C631", "PT", "CHIESINA UZZANESE", 1],
    ["C632", "CH", "CHIETI", 1],
    ["C633", "FG", "CHIEUTI", 1],
    ["C634", "CR", "CHIEVE", 1],
    ["C635", "BG", "CHIGNOLO", 0],
    ["C636", "NO", "CHIGNOLO", 0],
    ["C637", "PV", "CHIGNOLO", 0],
    ["C635", "BG", "CHIGNOLO D'ISOLA", 1],
    ["C637", "PV", "CHIGNOLO PO", 1],
    ["C636", "NO", "CHIGNOLO VERBANO", 0],
    ["C638", "VE", "CHIOGGIA", 1],
    ["C639", "TO", "CHIOMONTE", 1],
    ["C640", "PN", "CHIONS", 1],
    ["C641", "UD", "CHIOPRIS VISCONE", 0],
    ["C641", "UD", "CHIOPRIS-VISCONE", 1],
    ["C642", "MI", "CHIOSI D'ADDA VIGADORE", 0],
    ["C643", "MI", "CHIOSI DI PORTA D'ADDA", 0],
    ["C644", "MI", "CHIOSI PORTA CREMONESE", 0],
    ["C645", "MI", "CHIOSI PORTA REGALE", 0],
    ["C646", "MI", "CHIOSI UNITI CON BOTTEDO", 0],
    ["C647", "VE", "CHIRIGNAGO", 0],
    ["C648", "AR", "CHITIGNANO", 1],
    ["C649", "BG", "CHIUDUNO", 1],
    ["C650", "VI", "CHIUPPANO", 1],
    ["C651", "SO", "CHIURO", 1],
    ["C652", "BZ", "CHIUSA", 1],
    ["C653", "CN", "CHIUSA", 0],
    ["C654", "PA", "CHIUSA", 0],
    ["C655", "TO", "CHIUSA", 0],
    ["C656", "UD", "CHIUSA", 0],
    ["C652", "BZ", "CHIUSA ALL'ISARCO", 0],
    ["C653", "CN", "CHIUSA DI PESIO", 1],
    ["C655", "TO", "CHIUSA DI SAN MICHELE", 1],
    ["C654", "PA", "CHIUSA SCLAFANI", 1],
    ["C656", "UD", "CHIUSAFORTE", 1],
    ["C657", "IM", "CHIUSANICO", 1],
    ["C658", "AL", "CHIUSANO", 0],
    ["C659", "AV", "CHIUSANO", 0],
    ["C658", "AT", "CHIUSANO D'ASTI", 1],
    ["C659", "AV", "CHIUSANO DI SAN DOMENICO", 1],
    ["C660", "IM", "CHIUSAVECCHIA", 1],
    ["C661", "SI", "CHIUSDINO", 1],
    ["C662", "SI", "CHIUSI", 1],
    ["C663", "AR", "CHIUSI DELLA VERNA", 1],
    ["C663", "AR", "CHIUSI IN CASENTINO", 0],
    ["C664", "CO", "CHIUSO", 0],
    ["C665", "TO", "CHIVASSO", 1],
    ["C666", "TN", "CHIZZOLA", 0],
    ["Z702", "EE", "CHRISTMAS (ISOLA)", 0],
    ["C294", "AO", "CHATILLON", 0],
    ["Z309", "EE", "CIAD", 1],
    ["C667", "TN", "CIAGO", 0],
    ["C595", "AO", "CIAMBAVE", 0],
    ["M272", "RM", "CIAMPINO", 1],
    ["C668", "AG", "CIANCIANA", 1],
    ["C669", "RE", "CIANO", 0],
    ["C669", "RE", "CIANO D'ENZA", 0],
    ["C670", "TV", "CIANO-NOGARE'", 0],
    ["C671", "BZ", "CIARDES", 0],
    ["C671", "BZ", "CIARS", 0],
    ["C672", "BL", "CIBIANA", 0],
    ["C672", "BL", "CIBIANA DI CADORE", 1],
    ["C673", "GE", "CICAGNA", 1],
    ["C674", "CZ", "CICALA", 1],
    ["C675", "NA", "CICCIANO", 1],
    ["C676", "SA", "CICERALE", 1],
    ["C677", "RM", "CICILIANO", 1],
    ["C678", "CR", "CICOGNOLO", 1],
    ["C679", "TO", "CICONIO", 1],
    ["C680", "VC", "CIGLIANO", 1],
    ["C681", "CN", "CIGLIE'", 1],
    ["C682", "BS", "CIGNANO", 0],
    ["C683", "CR", "CIGNONE", 0],
    ["C684", "PV", "CIGOGNOLA", 1],
    ["C685", "BS", "CIGOLE", 1],
    ["C686", "PV", "CILAVEGNA", 1],
    ["Z603", "EE", "CILE", 1],
    ["C687", "BS", "CILIVERGHE", 0],
    ["C688", "CO", "CIMA", 0],
    ["C689", "TV", "CIMADOLMO", 1],
    ["C690", "NO", "CIMAMULERA", 0],
    ["C691", "BS", "CIMBERGO", 1],
    ["C692", "BS", "CIMBERGO-PASPARDO", 0],
    ["C693", "MI", "CIMBRO", 0],
    ["C694", "TN", "CIMEGO", 0],
    ["C695", "RC", "CIMINA'", 1],
    ["C696", "PA", "CIMINNA", 1],
    ["C697", "NA", "CIMITILE", 1],
    ["C698", "BS", "CIMMO", 0],
    ["C699", "PN", "CIMOLAIS", 1],
    ["C700", "TN", "CIMONE", 1],
    ["Z217", "EE", "CINA NAZIONALE", 0],
    ["Z210", "EE", "CINA REPUBBLICA POPOLARE", 1],
    ["C701", "AT", "CINAGLIO", 1],
    ["C702", "RM", "CINETO ROMANO", 1],
    ["C703", "CR", "CINGIA DE' BOTTI", 1],
    ["C704", "MC", "CINGOLI", 1],
    ["C705", "GR", "CINIGIANO", 1],
    ["C706", "MI", "CINISELLO", 0],
    ["C707", "MI", "CINISELLO BALSAMO", 1],
    ["C708", "PA", "CINISI", 1],
    ["C709", "SO", "CINO", 1],
    ["C710", "RC", "CINQUEFRONDI", 1],
    ["C711", "TO", "CINTANO", 1],
    ["C712", "TN", "CINTE TESINO", 1],
    ["C713", "PD", "CINTO", 0],
    ["C714", "VE", "CINTO", 0],
    ["C714", "VE", "CINTO CAOMAGGIORE", 1],
    ["C713", "PD", "CINTO EUGANEO", 1],
    ["C715", "TO", "CINZANO", 1],
    ["C716", "CE", "CIORLANO", 1],
    ["C717", "CS", "CIPOLLINA", 0],
    ["C718", "IM", "CIPRESSA", 1],
    ["Z211", "EE", "CIPRO", 1],
    ["C719", "BN", "CIRCELLO", 1],
    ["C720", "GO", "CIRCHINA", 0],
    ["C721", "NO", "CIREGGIO", 0],
    ["C722", "TO", "CIRIE'", 1],
    ["C723", "MT", "CIRIGLIANO", 1],
    ["C724", "CO", "CIRIMIDO", 1],
    ["C725", "KR", "CIRO'", 1],
    ["C726", "CZ", "CIRO' MARINA", 0],
    ["C726", "KR", "CIRO' MARINA", 1],
    ["C727", "TN", "CIS", 1],
    ["C728", "BG", "CISANO", 0],
    ["C729", "GE", "CISANO", 0],
    ["C728", "BG", "CISANO BERGAMASCO", 1],
    ["C729", "SV", "CISANO SUL NEVA", 1],
    ["C730", "BG", "CISERANO", 1],
    ["C731", "UD", "CISERIIS", 0],
    ["Z367", "EE", "CISKEI", 0],
    ["C732", "VA", "CISLAGO", 1],
    ["C733", "MI", "CISLIANO", 1],
    ["C734", "VI", "CISMON", 0],
    ["C734", "VI", "CISMON DEL GRAPPA", 0],
    ["C735", "TV", "CISON", 0],
    ["C735", "TV", "CISON DI VALMARINO", 1],
    ["C736", "NO", "CISORE", 0],
    ["C737", "BZ", "CISSICO", 0],
    ["C738", "CN", "CISSONE", 1],
    ["C188", "NA", "CISTERNA", 0],
    ["C739", "AL", "CISTERNA", 0],
    ["C740", "RM", "CISTERNA", 0],
    ["C739", "AT", "CISTERNA D'ASTI", 1],
    ["C740", "LT", "CISTERNA DI LATINA", 1],
    ["C740", "LT", "CISTERNA DI LITTORIA", 0],
    ["C740", "LT", "CISTERNA DI ROMA", 0],
    ["C741", "BR", "CISTERNINO", 1],
    ["C742", "PG", "CITERNA", 1],
    ["Z106", "EE", "CITTA' DEL VATICANO", 1],
    ["C744", "PG", "CITTA' DELLA PIEVE", 1],
    ["C745", "PG", "CITTA' DI CASTELLO", 1],
    ["C750", "PE", "CITTA' SANT'ANGELO", 1],
    ["C743", "PD", "CITTADELLA", 1],
    ["C746", "RI", "CITTADUCALE", 1],
    ["C747", "RC", "CITTANOVA", 1],
    ["C748", "PL", "CITTANOVA D'ISTRIA", 0],
    ["C749", "RI", "CITTAREALE", 1],
    ["C751", "VA", "CITTIGLIO", 1],
    ["C752", "LC", "CIVATE", 1],
    ["C753", "CO", "CIVELLO", 0],
    ["C754", "CO", "CIVENNA", 0],
    ["C755", "IM", "CIVEZZA", 1],
    ["C756", "TN", "CIVEZZANO", 1],
    ["C757", "VC", "CIVIASCO", 1],
    ["C758", "UD", "CIVIDALE DEL FRIULI", 1],
    ["C759", "BG", "CIVIDATE", 0],
    ["C759", "BG", "CIVIDATE AL PIANO", 1],
    ["C760", "BS", "CIVIDATE CAMUNO", 1],
    ["C761", "BS", "CIVIDATE-MALEGNO", 0],
    ["C762", "CO", "CIVIGLIO", 0],
    ["C763", "CS", "CIVITA", 1],
    ["C765", "VT", "CIVITA CASTELLANA", 1],
    ["C766", "AQ", "CIVITA D'ANTINO", 1],
    ["C767", "RM", "CIVITA LAVINIA", 0],
    ["C764", "CB", "CIVITACAMPOMARANO", 1],
    ["C760", "BS", "CIVITADE", 0],
    ["C760", "BS", "CIVITADE ALPINO", 0],
    ["C768", "CH", "CIVITALUPARELLA", 1],
    ["C769", "CB", "CIVITANOVA", 0],
    ["C770", "MC", "CIVITANOVA", 0],
    ["C769", "IS", "CIVITANOVA DEL SANNIO", 1],
    ["C770", "MC", "CIVITANOVA MARCHE", 1],
    ["C771", "PE", "CIVITAQUANA", 1],
    ["C772", "CB", "CIVITAVECCHIA", 0],
    ["C773", "RM", "CIVITAVECCHIA", 1],
    ["A749", "RM", "CIVITELLA", 0],
    ["C774", "AR", "CIVITELLA", 0],
    ["C775", "BN", "CIVITELLA", 0],
    ["C776", "CH", "CIVITELLA", 0],
    ["C777", "FC", "CIVITELLA", 0],
    ["C778", "AQ", "CIVITELLA ALFEDENA", 1],
    ["C779", "PE", "CIVITELLA CASANOVA", 1],
    ["C780", "VT", "CIVITELLA D'AGLIANO", 1],
    ["C781", "TE", "CIVITELLA DEL TRONTO", 1],
    ["C777", "FC", "CIVITELLA DI ROMAGNA", 1],
    ["C774", "AR", "CIVITELLA IN VAL DI CHIANA", 1],
    ["C775", "BN", "CIVITELLA LICINIO", 0],
    ["C776", "CH", "CIVITELLA MESSER RAIMONDO", 1],
    ["C782", "GR", "CIVITELLA PAGANICO", 1],
    ["C783", "AQ", "CIVITELLA ROVETO", 1],
    ["C784", "RM", "CIVITELLA SAN PAOLO", 1],
    ["A749", "RM", "CIVITELLA SAN SISTO", 0],
    ["C785", "SO", "CIVO", 1],
    ["C786", "BS", "CIZZAGO", 0],
    ["C787", "CO", "CLAINO CON OSTENO", 1],
    ["C788", "FM", "CLANA", 0],
    ["C789", "BG", "CLANEZZO", 0],
    ["C790", "PN", "CLAUT", 1],
    ["C791", "PN", "CLAUZETTO", 1],
    ["C792", "CN", "CLAVESANA", 1],
    ["C793", "TO", "CLAVIERE", 1],
    ["C793", "TO", "CLAVIERES", 0],
    ["C794", "TN", "CLES", 1],
    ["C795", "CS", "CLETO", 1],
    ["C796", "VA", "CLIVIO", 1],
    ["C797", "TN", "CLOZ", 0],
    ["C798", "BS", "CLUSANE", 0],
    ["C798", "BS", "CLUSANE SUL LAGO", 0],
    ["C799", "BZ", "CLUSIO", 0],
    ["C800", "BG", "CLUSONE", 1],
    ["C801", "TO", "COASSOLO", 0],
    ["C801", "TO", "COASSOLO TORINESE", 1],
    ["C802", "MI", "COAZZANO", 0],
    ["C803", "TO", "COAZZE", 1],
    ["C804", "AT", "COAZZOLO", 1],
    ["C805", "GO", "COBBIA", 0],
    ["C806", "BS", "COCCAGLIO", 1],
    ["C807", "AT", "COCCONATO", 1],
    ["C808", "AL", "COCCONITO", 0],
    ["Z212", "EE", "COCOS (ISOLE)", 1],
    ["C809", "VA", "COCQUIO", 0],
    ["C810", "VA", "COCQUIO-TREVISAGO", 1],
    ["C811", "AQ", "COCULLO", 1],
    ["C812", "PD", "CODEVIGO", 1],
    ["C813", "PV", "CODEVILLA", 1],
    ["C814", "FE", "CODIGORO", 1],
    ["C815", "TV", "CODOGNE'", 1],
    ["C816", "LO", "CODOGNO", 1],
    ["C817", "UD", "CODROIPO", 1],
    ["C818", "SS", "CODRONGIANOS", 1],
    ["C818", "SS", "CODRONGIANUS", 0],
    ["C819", "BI", "COGGIOLA", 1],
    ["C820", "MB", "COGLIATE", 1],
    ["C820", "MI", "COGLIATE", 0],
    ["C821", "AO", "COGNE", 1],
    ["C822", "TN", "COGNOLA", 0],
    ["C823", "GE", "COGOLETO", 1],
    ["C824", "VI", "COGOLLO", 0],
    ["C824", "VI", "COGOLLO DEL CENGIO", 1],
    ["C825", "TN", "COGOLO", 0],
    ["C826", "GE", "COGORNO", 1],
    ["C827", "NO", "COIMO", 0],
    ["C828", "NO", "COIRO", 0],
    ["C828", "NO", "COIROMONTE", 0],
    ["C906", "TO", "COL SAN GIOVANNI", 0],
    ["C829", "NO", "COLAZZA", 1],
    ["C830", "PU", "COLBORDOLO", 0],
    ["C831", "AT", "COLCAVAGNO", 0],
    ["M426", "VI", "COLCERESA", 1],
    ["C832", "CO", "COLCIAGO", 0],
    ["C833", "BZ", "COLDERANO", 0],
    ["C834", "IM", "COLDIRODI", 0],
    ["C833", "BZ", "COLDRANO", 0],
    ["C835", "BG", "COLERE", 1],
    ["C836", "FR", "COLFELICE", 1],
    ["C837", "BZ", "COLFOSCO", 0],
    ["C838", "PC", "COLI", 1],
    ["C839", "LC", "COLICO", 1],
    ["C834", "IM", "COLLA", 0],
    ["C840", "RE", "COLLAGNA", 0],
    ["C841", "PG", "COLLALTO", 0],
    ["C842", "UD", "COLLALTO", 0],
    ["C842", "UD", "COLLALTO DELLA SOIMA", 0],
    ["C841", "RI", "COLLALTO SABINO", 1],
    ["C844", "AQ", "COLLARMELE", 1],
    ["C845", "PG", "COLLAZZONE", 1],
    ["C846", "BN", "COLLE", 0],
    ["C847", "SI", "COLLE", 0],
    ["C848", "TV", "COLLE", 0],
    ["C851", "LC", "COLLE BRIANZA", 1],
    ["C854", "CB", "COLLE D'ANCHISE", 1],
    ["C857", "RI", "COLLE DI TORA", 1],
    ["C847", "SI", "COLLE DI VAL D'ELSA", 1],
    ["C861", "BZ", "COLLE IN CASIES", 0],
    ["M306", "BZ", "COLLE ISARCO", 0],
    ["C870", "FR", "COLLE SAN MAGNO", 1],
    ["C846", "BN", "COLLE SANNITA", 1],
    ["C872", "BL", "COLLE SANTA LUCIA", 1],
    ["C848", "TV", "COLLE UMBERTO", 1],
    ["C849", "PG", "COLLEBACCARO", 0],
    ["C850", "BS", "COLLEBEATO", 1],
    ["C852", "PR", "COLLECCHIO", 1],
    ["C853", "PE", "COLLECORVINO", 1],
    ["C311", "TE", "COLLEDARA", 1],
    ["C855", "CH", "COLLEDIMACINE", 1],
    ["C856", "CH", "COLLEDIMEZZO", 1],
    ["C858", "RM", "COLLEFERRO", 1],
    ["C859", "RI", "COLLEGIOVE", 1],
    ["C860", "TO", "COLLEGNO", 1],
    ["C862", "AQ", "COLLELONGO", 1],
    ["C863", "PG", "COLLEMANCIO", 0],
    ["C864", "FR", "COLLEPARDO", 1],
    ["C865", "LE", "COLLEPASSO", 1],
    ["C866", "AQ", "COLLEPIETRO", 1],
    ["C867", "TO", "COLLERETTO CASTELNUOVO", 1],
    ["C868", "TO", "COLLERETTO GIACOSA", 1],
    ["C868", "TO", "COLLERETTO PARELLA", 0],
    ["C869", "LI", "COLLESALVETTI", 1],
    ["C871", "PA", "COLLESANO", 1],
    ["C873", "TR", "COLLESCIPOLI", 0],
    ["C874", "TR", "COLLESTATTE", 0],
    ["C875", "CB", "COLLETORTO", 1],
    ["C876", "RI", "COLLEVECCHIO", 1],
    ["C877", "AP", "COLLI", 0],
    ["C878", "CB", "COLLI", 0],
    ["C878", "IS", "COLLI A VOLTURNO", 1],
    ["M380", "PU", "COLLI AL METAURO", 1],
    ["C877", "AP", "COLLI DEL TRONTO", 1],
    ["C880", "RI", "COLLI DI LABRO", 0],
    ["C881", "BZ", "COLLI IN PUSTERIA", 0],
    ["C880", "RI", "COLLI SUL VELINO", 1],
    ["M419", "PV", "COLLI VERDI", 1],
    ["C879", "SA", "COLLIANO", 1],
    ["C882", "CA", "COLLINAS", 0],
    ["C882", "SU", "COLLINAS", 1],
    ["C883", "BS", "COLLIO", 1],
    ["C884", "VC", "COLLOBIANO", 1],
    ["C885", "UD", "COLLOREDO DI MONTE ALBANO", 1],
    ["C886", "MC", "COLMURANO", 1],
    ["C887", "MI", "COLNAGO", 0],
    ["C888", "MT", "COLOBRARO", 1],
    ["C889", "CO", "COLOGNA", 0],
    ["C890", "VR", "COLOGNA", 0],
    ["C892", "TN", "COLOGNA", 0],
    ["C891", "TN", "COLOGNA CAVAZZO", 0],
    ["C890", "VR", "COLOGNA VENETA", 1],
    ["C893", "BS", "COLOGNE", 1],
    ["B899", "MI", "COLOGNO", 0],
    ["C894", "BG", "COLOGNO", 0],
    ["C895", "MI", "COLOGNO", 0],
    ["C894", "BG", "COLOGNO AL SERIO", 1],
    ["C895", "MI", "COLOGNO MONZESE", 1],
    ["C896", "BG", "COLOGNOLA", 0],
    ["C897", "VR", "COLOGNOLA", 0],
    ["C897", "VR", "COLOGNOLA AI COLLI", 1],
    ["C896", "BG", "COLOGNOLA DEL PIANO", 0],
    ["C898", "BS", "COLOMBARO", 0],
    ["C899", "CR", "COLOMBAROLO", 0],
    ["Z604", "EE", "COLOMBIA", 1],
    ["C900", "RM", "COLONNA", 1],
    ["C901", "TE", "COLONNELLA", 1],
    ["C902", "CO", "COLONNO", 1],
    ["C903", "SO", "COLORINA", 1],
    ["C904", "PR", "COLORNO", 1],
    ["C905", "CS", "COLOSIMI", 1],
    ["C907", "BZ", "COLSANO", 0],
    ["C908", "MI", "COLTURANO", 1],
    ["M336", "CO", "COLVERDE", 1],
    ["C909", "MI", "COLZANO", 0],
    ["C910", "BG", "COLZATE", 1],
    ["C911", "VA", "COMABBIO", 1],
    ["C912", "FE", "COMACCHIO", 1],
    ["C913", "PV", "COMAIRANO", 0],
    ["C914", "MS", "COMANO", 1],
    ["C915", "TN", "COMANO", 0],
    ["M314", "TN", "COMANO TERME", 1],
    ["C916", "TN", "COMASINE", 0],
    ["C917", "LO", "COMAZZO", 1],
    ["C918", "UD", "COMEGLIANS", 1],
    ["C919", "BL", "COMELICO INFERIORE", 0],
    ["C920", "BL", "COMELICO SUPERIORE", 1],
    ["C921", "GO", "COMENO", 0],
    ["C922", "VA", "COMERIO", 1],
    ["C923", "BS", "COMERO", 0],
    ["C924", "BS", "COMEZZANO", 0],
    ["C925", "BS", "COMEZZANO-CIZZAGO", 1],
    ["M227", "TN", "COMIGHELLO", 0],
    ["C926", "NO", "COMIGNAGO", 1],
    ["C927", "RG", "COMISO", 1],
    ["C928", "AG", "COMITINI", 1],
    ["C929", "NA", "COMIZIANO", 1],
    ["C930", "MN", "COMMESSAGGIO", 1],
    ["C931", "TN", "COMMEZZADURA", 1],
    ["C932", "NO", "COMNAGO", 0],
    ["C933", "CO", "COMO", 1],
    ["Z310", "EE", "COMORE (ISOLE)", 1],
    ["C934", "PR", "COMPIANO", 1],
    ["C937", "BG", "COMUN NUOVO", 1],
    ["C935", "AP", "COMUNANZA", 1],
    ["C935", "AP", "COMUNANZA DEL LITTORIO", 0],
    ["C936", "CO", "COMUNE DELLA VALSOLDA", 0],
    ["C938", "VE", "CONA", 1],
    ["C939", "CE", "CONCA", 0],
    ["C940", "SA", "CONCA", 0],
    ["C941", "IS", "CONCA CASALE", 1],
    ["C940", "SA", "CONCA DEI MARINI", 1],
    ["C939", "CE", "CONCA DELLA CAMPANIA", 1],
    ["C940", "SA", "CONCA MARINI", 0],
    ["C942", "RO", "CONCADIRAME", 0],
    ["C943", "VR", "CONCAMARISE", 1],
    ["C944", "TN", "CONCEI", 0],
    ["C945", "CO", "CONCENEDO", 0],
    ["C946", "RI", "CONCERVIANO", 1],
    ["C947", "MI", "CONCESA", 0],
    ["C948", "BS", "CONCESIO", 1],
    ["C949", "VI", "CONCO", 0],
    ["C950", "VE", "CONCORDIA", 0],
    ["C950", "VE", "CONCORDIA SAGITARIA", 0],
    ["C950", "VE", "CONCORDIA SAGITTARIA", 1],
    ["C951", "MO", "CONCORDIA SULLA SECCHIA", 1],
    ["C952", "MB", "CONCOREZZO", 1],
    ["C952", "MI", "CONCOREZZO", 0],
    ["C953", "TN", "CONDINO", 0],
    ["C954", "RC", "CONDOFURI", 1],
    ["C955", "TO", "CONDOVE", 1],
    ["C956", "ME", "CONDRO'", 1],
    ["C957", "TV", "CONEGLIANO", 1],
    ["C958", "PV", "CONFIENZA", 1],
    ["C959", "RI", "CONFIGNI", 1],
    ["C960", "CZ", "CONFLENTI", 1],
    ["Z311", "EE", "CONGO BRAZZAVILLE", 0],
    ["Z312", "EE", "CONGO LEOPOLDVILLE", 0],
    ["Z312", "EE", "CONGO REPUBBLICA DEMOCRATICA", 1],
    ["Z311", "EE", "CONGO REPUBBLICA POPOLARE", 0],
    ["C961", "IM", "CONIO", 0],
    ["C962", "AL", "CONIOLO", 1],
    ["C963", "RA", "CONSELICE", 1],
    ["C964", "PD", "CONSELVE", 1],
    ["C965", "CO", "CONSIGLIO DI RUMO", 0],
    ["C966", "CO", "CONSONNO", 0],
    ["M356", "TN", "CONTA'", 1],
    ["C967", "RO", "CONTARINA", 0],
    ["C967", "TV", "CONTARINA", 0],
    ["C968", "PA", "CONTESSA", 0],
    ["C968", "PA", "CONTESSA ENTELLINA", 1],
    ["C969", "RI", "CONTIGLIANO", 1],
    ["C970", "CO", "CONTRA", 0],
    ["C971", "AV", "CONTRADA", 1],
    ["C972", "TE", "CONTROGUERRA", 1],
    ["C973", "SA", "CONTRONE", 1],
    ["C974", "SA", "CONTURSI TERME", 1],
    ["C975", "BA", "CONVERSANO", 1],
    ["C976", "AV", "CONZA DELLA CAMPANIA", 1],
    ["C977", "AL", "CONZANO", 1],
    ["Z703", "EE", "COOK (ISOLE)", 1],
    ["C978", "LE", "COPERTINO", 1],
    ["C979", "PV", "COPIANO", 1],
    ["C980", "FE", "COPPARO", 1],
    ["C981", "MI", "COPRENO", 0],
    ["C982", "PV", "CORANA", 1],
    ["C983", "BA", "CORATO", 1],
    ["C984", "SA", "CORBARA", 1],
    ["C985", "PV", "CORBESATE", 0],
    ["C986", "MI", "CORBETTA", 1],
    ["C987", "RO", "CORBOLA", 1],
    ["D083", "BZ", "CORCES", 0],
    ["C988", "VT", "CORCHIANO", 1],
    ["C989", "NO", "CORCIAGO", 0],
    ["C990", "PG", "CORCIANO", 1],
    ["C991", "PN", "CORDENONS", 1],
    ["C992", "TV", "CORDIGNANO", 1],
    ["C993", "PN", "CORDOVADO", 1],
    ["Z214", "EE", "COREA DEL NORD", 1],
    ["Z213", "EE", "COREA DEL SUD", 1],
    ["C994", "TN", "COREDO", 0],
    ["C995", "GE", "COREGLIA", 0],
    ["C996", "LU", "COREGLIA", 0],
    ["C996", "LU", "COREGLIA ANTELMINELLI", 1],
    ["C995", "GE", "COREGLIA DI FONTANABUONA", 0],
    ["C995", "GE", "COREGLIA LIGURE", 1],
    ["C997", "CO", "CORENNO", 0],
    ["C997", "CO", "CORENNO PLINIO", 0],
    ["C998", "FR", "CORENO", 0],
    ["C998", "FR", "CORENO AUSONIO", 1],
    ["C999", "AQ", "CORFINIO", 1],
    ["D001", "MI", "CORGENO", 0],
    ["D002", "TS", "CORGNALE", 0],
    ["D003", "LT", "CORI", 1],
    ["D004", "RN", "CORIANO", 1],
    ["D005", "CS", "CORIGLIANO", 0],
    ["D006", "LE", "CORIGLIANO", 0],
    ["D005", "CS", "CORIGLIANO CALABRO", 0],
    ["D006", "LE", "CORIGLIANO D'OTRANTO", 1],
    ["M403", "CS", "CORIGLIANO-ROSSANO", 1],
    ["D007", "AN", "CORINALDO", 1],
    ["D008", "TO", "CORIO", 1],
    ["D009", "PA", "CORLEONE", 1],
    ["D010", "PZ", "CORLETO", 0],
    ["D011", "SA", "CORLETO", 0],
    ["D011", "SA", "CORLETO MONFORTE", 1],
    ["D010", "PZ", "CORLETO PERTICARA", 1],
    ["D012", "AO", "CORMAIORE", 0],
    ["D013", "MI", "CORMANO", 1],
    ["D014", "GO", "CORMONS", 1],
    ["D015", "BG", "CORNA IMAGNA", 1],
    ["D016", "BG", "CORNALBA", 1],
    ["D017", "PV", "CORNALE", 0],
    ["M338", "PV", "CORNALE E BASTIDA", 1],
    ["D018", "MI", "CORNAREDO", 1],
    ["D019", "MI", "CORNATE", 0],
    ["D019", "MB", "CORNATE D'ADDA", 1],
    ["D019", "MI", "CORNATE D'ADDA", 0],
    ["B799", "BZ", "CORNEDO", 0],
    ["D020", "VI", "CORNEDO", 0],
    ["B799", "BZ", "CORNEDO ALL'ISARCO", 1],
    ["D020", "VI", "CORNEDO VICENTINO", 1],
    ["D021", "MI", "CORNEGLIANO", 0],
    ["D021", "LO", "CORNEGLIANO LAUDENSE", 1],
    ["D022", "CN", "CORNELIANO", 0],
    ["D023", "MI", "CORNELIANO", 0],
    ["D023", "MI", "CORNELIANO BERTARIO", 0],
    ["D022", "CN", "CORNELIANO D'ALBA", 1],
    ["D024", "RM", "CORNETO", 0],
    ["D024", "RM", "CORNETO TARQUINIA", 0],
    ["D025", "GE", "CORNIGLIANO", 0],
    ["D025", "GE", "CORNIGLIANO LIGURE", 0],
    ["D026", "PR", "CORNIGLIO", 1],
    ["D027", "UD", "CORNO DI ROSAZZO", 1],
    ["D028", "LO", "CORNO GIOVINE", 1],
    ["D029", "LO", "CORNOVECCHIO", 1],
    ["D030", "TV", "CORNUDA", 1],
    ["D031", "GO", "CORONA", 0],
    ["D032", "BZ", "CORONA ALLA MUTA", 0],
    ["D033", "MI", "CORONATE", 0],
    ["D034", "CR", "CORPI SANTI DI CREMONA", 0],
    ["D035", "MI", "CORPI SANTI DI MILANO", 0],
    ["D036", "PV", "CORPI SANTI DI PAVIA", 0],
    ["D037", "RE", "CORREGGIO", 1],
    ["D038", "MB", "CORREZZANA", 1],
    ["D038", "MI", "CORREZZANA", 0],
    ["D039", "VR", "CORREZZO", 0],
    ["D040", "PD", "CORREZZOLA", 1],
    ["D041", "CO", "CORRIDO", 1],
    ["D042", "MC", "CORRIDONIA", 1],
    ["D043", "TE", "CORROPOLI", 1],
    ["D044", "LE", "CORSANO", 1],
    ["D045", "MI", "CORSICO", 1],
    ["D046", "AT", "CORSIONE", 1],
    ["D047", "CO", "CORTABBIO", 0],
    ["D048", "BZ", "CORTACCIA", 0],
    ["D048", "BZ", "CORTACCIA SULLA STRADA DEL VINO", 1],
    ["D049", "CZ", "CORTALE", 1],
    ["D050", "AT", "CORTANDONE", 1],
    ["D051", "AT", "CORTANZE", 1],
    ["D052", "AT", "CORTAZZONE", 1],
    ["D053", "BG", "CORTE", 0],
    ["D054", "PC", "CORTE BRUGNATELLA", 1],
    ["D055", "CR", "CORTE DE' CORTESI", 0],
    ["D056", "CR", "CORTE DE' CORTESI CON CIGNONE", 1],
    ["D057", "CR", "CORTE DE' FRATI", 1],
    ["D058", "BS", "CORTE FRANCA", 1],
    ["D059", "BZ", "CORTE IN PUSTERIA", 0],
    ["D060", "CR", "CORTE MADAMA", 0],
    ["D068", "LO", "CORTE PALASIO", 1],
    ["D070", "MI", "CORTE SANT'ANDREA", 0],
    ["D061", "PC", "CORTEMAGGIORE", 1],
    ["D062", "CN", "CORTEMILIA", 1],
    ["D063", "BS", "CORTENEDOLO", 0],
    ["D064", "BS", "CORTENO", 0],
    ["D064", "BS", "CORTENO GOLGI", 1],
    ["D065", "LC", "CORTENOVA", 1],
    ["D066", "BG", "CORTENUOVA", 1],
    ["D067", "PV", "CORTEOLONA", 0],
    ["M372", "PV", "CORTEOLONA E GENZONE", 1],
    ["D069", "AL", "CORTERANZO", 0],
    ["D071", "CR", "CORTETANO", 0],
    ["D059", "BZ", "CORTI IN PUSTERIA", 0],
    ["D072", "AL", "CORTICELLE", 0],
    ["D073", "BS", "CORTICELLE", 0],
    ["D073", "BS", "CORTICELLE PIEVE", 0],
    ["D072", "AT", "CORTIGLIONE", 1],
    ["D074", "PR", "CORTILE SAN MARTINO", 0],
    ["D075", "BZ", "CORTINA ALL'ADIGE", 0],
    ["A266", "BL", "CORTINA D'AMPEZZO", 1],
    ["D075", "BZ", "CORTINA SULLA STRADA DEL VINO", 1],
    ["D076", "TE", "CORTINO", 1],
    ["D077", "AR", "CORTONA", 1],
    ["D078", "PE", "CORVARA", 1],
    ["D079", "BZ", "CORVARA IN BADIA", 1],
    ["D080", "BZ", "CORVARA IN PASSIRIA", 0],
    ["D081", "PV", "CORVINO", 0],
    ["D081", "PV", "CORVINO SAN QUIRICO", 1],
    ["D082", "BS", "CORZANO", 1],
    ["D084", "GO", "COSBANA", 0],
    ["D084", "GO", "COSBANA NEL COLLIO", 0],
    ["D085", "UD", "COSEANO", 1],
    ["D086", "CS", "COSENZA", 1],
    ["D087", "IM", "COSIO", 0],
    ["D088", "SO", "COSIO", 0],
    ["D087", "IM", "COSIO D'ARROSCIA", 1],
    ["D088", "SO", "COSIO VALTELLINO", 1],
    ["D089", "RC", "COSOLETO", 1],
    ["D090", "TS", "COSSANA", 0],
    ["D091", "CO", "COSSANO", 0],
    ["D092", "TO", "COSSANO", 0],
    ["D093", "CN", "COSSANO BELBO", 1],
    ["D092", "TO", "COSSANO CANAVESE", 1],
    ["D094", "BI", "COSSATO", 1],
    ["D095", "SV", "COSSERIA", 1],
    ["D096", "AP", "COSSIGNANO", 1],
    ["D097", "VC", "COSSILA", 0],
    ["D098", "BS", "COSSIRANO", 0],
    ["D099", "VB", "COSSOGNO", 1],
    ["D100", "SS", "COSSOINE", 1],
    ["D101", "AT", "COSSOMBRATO", 1],
    ["D102", "AL", "COSTA", 0],
    ["D103", "BG", "COSTA", 0],
    ["D104", "IM", "COSTA", 0],
    ["D105", "RO", "COSTA", 0],
    ["D106", "MI", "COSTA AL LAMBRO", 0],
    ["Z313", "EE", "COSTA D'AVORIO", 1],
    ["D109", "PV", "COSTA DE' NOBILI", 1],
    ["Z215", "EE", "COSTA DEI PIRATI=TRUCIAL STATES", 0],
    ["D110", "BG", "COSTA DI MEZZATE", 1],
    ["D110", "BG", "COSTA DI MONTICELLI", 0],
    ["D104", "IM", "COSTA DI ONEGLIA", 0],
    ["D105", "RO", "COSTA DI ROVIGO", 1],
    ["D111", "BG", "COSTA DI SERINA", 0],
    ["D106", "MI", "COSTA LOMBARDA", 0],
    ["D112", "LC", "COSTA MASNAGA", 1],
    ["D114", "IM", "COSTA RAINERA", 0],
    ["Z503", "EE", "COSTA RICA", 1],
    ["D109", "PV", "COSTA SAN ZENONE", 0],
    ["D115", "CR", "COSTA SANT'ABRAMO", 0],
    ["D111", "BG", "COSTA SERINA", 1],
    ["D103", "BG", "COSTA VALLE IMAGNA", 1],
    ["D102", "AL", "COSTA VESCOVATO", 1],
    ["D117", "BG", "COSTA VOLPINO", 1],
    ["D107", "VI", "COSTABISSARA", 1],
    ["D108", "PG", "COSTACCIARO", 1],
    ["D113", "VC", "COSTANZANA", 1],
    ["D114", "IM", "COSTARAINERA", 1],
    ["D116", "TN", "COSTASAVINA", 0],
    ["D118", "VR", "COSTERMANO", 0],
    ["D118", "VR", "COSTERMANO SUL GARDA", 1],
    ["D119", "AT", "COSTIGLIOLE D'ASTI", 1],
    ["D120", "CN", "COSTIGLIOLE SALUZZO", 1],
    ["D121", "RA", "COTIGNOLA", 1],
    ["D122", "CZ", "COTRONE", 0],
    ["D123", "KR", "COTRONEI", 1],
    ["D124", "RI", "COTTANELLO", 1],
    ["D012", "AO", "COURMAYEUR", 1],
    ["C401", "BZ", "COVELANO", 0],
    ["D125", "TN", "COVELO", 0],
    ["D126", "BG", "COVO", 1],
    ["D127", "PV", "COZZO", 1],
    ["D128", "MT", "CRACO", 1],
    ["D129", "NO", "CRANA", 0],
    ["D130", "NO", "CRANA CATTUGNO", 0],
    ["D131", "CO", "CRANDOLA", 0],
    ["D131", "LC", "CRANDOLA VALSASSINA", 1],
    ["D132", "VC", "CRAVAGLIANA", 1],
    ["D133", "CN", "CRAVANZANA", 1],
    ["D134", "VB", "CRAVEGGIA", 1],
    ["D135", "NO", "CRAVEGNA", 0],
    ["D136", "VI", "CREAZZO", 1],
    ["D137", "CH", "CRECCHIO", 1],
    ["D138", "GO", "CREDA", 0],
    ["D139", "BG", "CREDARO", 1],
    ["D140", "CR", "CREDERA", 0],
    ["M252", "CR", "CREDERA CON ROVERETO", 0],
    ["D141", "CR", "CREDERA RUBBIANO", 1],
    ["D142", "CR", "CREMA", 1],
    ["D143", "LC", "CREMELLA", 1],
    ["D144", "VA", "CREMENAGA", 1],
    ["D145", "LC", "CREMENO", 1],
    ["D146", "BS", "CREMEZZANO", 0],
    ["D147", "CO", "CREMIA", 1],
    ["D148", "CO", "CREMNAGO", 0],
    ["D149", "AL", "CREMOLINO", 1],
    ["D150", "CR", "CREMONA", 1],
    ["D150", "CR", "CREMONA ED UNITI", 0],
    ["D151", "CR", "CREMOSANO", 1],
    ["D152", "VA", "CRENNA", 0],
    ["D153", "TS", "CRENOVIZZA", 0],
    ["D154", "VC", "CRESCENTINO", 1],
    ["D155", "MI", "CRESCENZAGO", 0],
    ["D156", "VI", "CRESPADORO", 1],
    ["D157", "TV", "CRESPANO", 0],
    ["D157", "TV", "CRESPANO DEL GRAPPA", 0],
    ["D157", "TV", "CRESPANO VENETO", 0],
    ["D158", "BO", "CRESPELLANO", 0],
    ["D159", "LO", "CRESPIATICA", 1],
    ["D160", "PI", "CRESPINA", 0],
    ["M328", "PI", "CRESPINA LORENZANA", 1],
    ["D161", "RO", "CRESPINO", 1],
    ["D162", "NO", "CRESSA", 1],
    ["D163", "CO", "CRESSOGNO", 0],
    ["D164", "TN", "CRETO", 0],
    ["D165", "BI", "CREVACUORE", 1],
    ["D166", "BO", "CREVALCORE", 1],
    ["D167", "CO", "CREVENNA", 0],
    ["D168", "NO", "CREVOLA", 0],
    ["D169", "VC", "CREVOLA", 0],
    ["D168", "NO", "CREVOLA D'OSSOLA", 0],
    ["D169", "VC", "CREVOLA SESIA", 0],
    ["D168", "VB", "CREVOLADOSSOLA", 1],
    ["D170", "NA", "CRISPANO", 1],
    ["D171", "TA", "CRISPIANO", 1],
    ["D172", "CN", "CRISSOLO", 1],
    ["Z149", "EE", "CROAZIA", 1],
    ["D173", "CO", "CROCE", 0],
    ["D174", "VC", "CROCE DI MOSSO", 0],
    ["D175", "GE", "CROCEFIESCHI", 1],
    ["D176", "RO", "CROCETTA", 0],
    ["C670", "TV", "CROCETTA DEL MONTELLO", 1],
    ["C670", "TV", "CROCETTA TREVIGIANA", 0],
    ["D177", "VB", "CRODO", 1],
    ["D177", "NO", "CRODO MOZZIO", 0],
    ["D178", "TE", "CROGNALETO", 0],
    ["D179", "TE", "CROGNALETO", 1],
    ["D179", "CS", "CROPALATI", 0],
    ["D180", "CS", "CROPALATI", 1],
    ["D180", "CZ", "CROPANI", 0],
    ["D181", "CZ", "CROPANI", 1],
    ["D182", "BI", "CROSA", 0],
    ["D183", "VI", "CROSARA", 0],
    ["D184", "CS", "CROSIA", 1],
    ["D185", "VA", "CROSIO DELLA VALLE", 1],
    ["D122", "KR", "CROTONE", 1],
    ["D186", "CR", "CROTTA D'ADDA", 1],
    ["D187", "VC", "CROVA", 1],
    ["D188", "TN", "CROVIANA", 1],
    ["D189", "KR", "CRUCOLI", 1],
    ["D190", "MI", "CRUGNOLA", 0],
    ["D191", "NO", "CRUSINALLO", 0],
    ["D192", "VA", "CUASSO AL MONTE", 1],
    ["Z504", "EE", "CUBA", 1],
    ["D193", "VR", "CUCCA", 0],
    ["D194", "AL", "CUCCARO", 0],
    ["D195", "SA", "CUCCARO", 0],
    ["D194", "AL", "CUCCARO MONFERRATO", 0],
    ["D195", "SA", "CUCCARO VETERE", 1],
    ["D196", "CO", "CUCCIAGO", 1],
    ["D197", "TO", "CUCEGLIO", 1],
    ["D198", "MI", "CUGGIONO", 1],
    ["D199", "VA", "CUGLIATE", 0],
    ["D199", "VA", "CUGLIATE-FABIASCO", 1],
    ["D200", "OR", "CUGLIERI", 1],
    ["D201", "PE", "CUGNOLI", 1],
    ["C840", "RE", "CULAGNA", 0],
    ["D202", "TO", "CUMIANA", 1],
    ["C929", "NA", "CUMIGNANO", 0],
    ["D203", "CR", "CUMIGNANO", 0],
    ["C929", "NA", "CUMIGNANO E GALLO DI NOLA", 0],
    ["D203", "CR", "CUMIGNANO SUL NAVIGLIO", 1],
    ["D204", "VA", "CUNARDO", 1],
    ["D205", "CN", "CUNEO", 1],
    ["D206", "TN", "CUNEVO", 0],
    ["D207", "AT", "CUNICO", 1],
    ["D208", "TO", "CUORGNE'", 1],
    ["D209", "CH", "CUPELLO", 1],
    ["D210", "AP", "CUPRA MARITTIMA", 1],
    ["D211", "AN", "CUPRAMONTANA", 1],
    ["D212", "AL", "CUQUELLO", 0],
    ["B824", "PV", "CURA CARPIGNANO", 1],
    ["D213", "BZ", "CURACES", 0],
    ["D214", "OR", "CURCURIS", 1],
    ["D215", "BG", "CURDOMO", 0],
    ["D216", "NO", "CUREGGIO", 1],
    ["D217", "VA", "CURIGLIA", 0],
    ["D217", "VA", "CURIGLIA CON MONTEVIASCO", 1],
    ["D218", "CZ", "CURINGA", 1],
    ["D219", "BI", "CURINO", 1],
    ["D220", "BG", "CURNASCO", 0],
    ["D221", "BG", "CURNO", 1],
    ["D032", "BZ", "CURON", 0],
    ["D222", "BZ", "CURON VENOSTA", 1],
    ["D223", "LE", "CURSI", 1],
    ["D224", "NO", "CURSOLO", 0],
    ["D225", "VB", "CURSOLO-ORASSO", 0],
    ["D226", "PD", "CURTAROLO", 1],
    ["D227", "MN", "CURTATONE", 1],
    ["D228", "CE", "CURTI", 1],
    ["D229", "MI", "CUSAGO", 1],
    ["D230", "BN", "CUSANO", 0],
    ["D231", "MI", "CUSANO", 0],
    ["D231", "MI", "CUSANO MILANINO", 1],
    ["D230", "BN", "CUSANO MUTRI", 1],
    ["D231", "MI", "CUSANO SUL SEVESO", 0],
    ["D232", "CO", "CUSINO", 1],
    ["D233", "BG", "CUSIO", 1],
    ["D234", "TP", "CUSTONACI", 1],
    ["D235", "PT", "CUTIGLIANO", 0],
    ["D236", "KR", "CUTRO", 1],
    ["D237", "LE", "CUTROFIANO", 1],
    ["D238", "VA", "CUVEGLIO", 1],
    ["D238", "VA", "CUVEGLIO IN VALLE", 0],
    ["D239", "VA", "CUVIO", 1],
    ["D240", "MI", "CUVIRONE", 0],
    ["D241", "NO", "CUZZAGO", 0],
    ["D242", "NO", "DAGNENTE", 0],
    ["Z314", "EE", "DAHOMEY=BENIN", 1],
    ["D243", "TN", "DAIANO", 0],
    ["D244", "MI", "DAIRAGO", 1],
    ["D245", "BG", "DALMINE", 1],
    ["D246", "TN", "DAMBEL", 1],
    ["Z107", "EE", "DANIMARCA", 1],
    ["D247", "BL", "DANTA", 0],
    ["D247", "BL", "DANTA DI CADORE", 1],
    ["D248", "TN", "DAONE", 0],
    ["D249", "TN", "DARDINE", 0],
    ["D250", "TN", "DARE'", 0],
    ["D251", "BS", "DARFO", 0],
    ["D251", "BS", "DARFO BOARIO TERME", 1],
    ["D252", "TN", "DARZO", 0],
    ["D253", "VV", "DASA'", 1],
    ["M228", "TN", "DASINDO", 0],
    ["D254", "CO", "DASIO", 0],
    ["D255", "GE", "DAVAGNA", 1],
    ["D256", "VA", "DAVERIO", 1],
    ["D257", "CZ", "DAVOLI", 1],
    ["D258", "SO", "DAZIO", 1],
    ["D259", "CA", "DECIMOMANNU", 1],
    ["D260", "CA", "DECIMOPUTZU", 0],
    ["D260", "SU", "DECIMOPUTZU", 1],
    ["D261", "CZ", "DECOLLATURA", 1],
    ["D262", "BS", "DEGAGNA", 0],
    ["D263", "TN", "DEGGIANO", 0],
    ["D264", "SV", "DEGO", 1],
    ["D265", "SP", "DEIVA", 0],
    ["D265", "SP", "DEIVA MARINA", 1],
    ["D266", "SO", "DELEBIO", 1],
    ["D267", "CL", "DELIA", 1],
    ["D268", "RC", "DELIANUOVA", 1],
    ["D269", "FG", "DELICETO", 1],
    ["D270", "BS", "DELLO", 1],
    ["D271", "CN", "DEMONTE", 1],
    ["D272", "AL", "DENICE", 1],
    ["D273", "TN", "DENNO", 1],
    ["D274", "TN", "DERCOLO", 0],
    ["D275", "MI", "DERGANO", 0],
    ["D276", "TN", "DERMULO", 0],
    ["D277", "AL", "DERNICE", 1],
    ["D278", "CR", "DEROVERE", 1],
    ["D279", "PG", "DERUTA", 1],
    ["D280", "LC", "DERVIO", 1],
    ["D281", "VC", "DESANA", 1],
    ["D282", "GO", "DESCLA", 0],
    ["D283", "BG", "DESENZANO", 0],
    ["D284", "BS", "DESENZANO", 0],
    ["D283", "BG", "DESENZANO AL SERIO", 0],
    ["D284", "BS", "DESENZANO DEL GARDA", 1],
    ["D284", "BS", "DESENZANO SUL LAGO", 0],
    ["D285", "TO", "DESERTES", 0],
    ["D286", "MB", "DESIO", 1],
    ["D286", "MI", "DESIO", 0],
    ["D287", "NU", "DESULO", 1],
    ["D288", "BG", "DEZZO DI SCALVE", 0],
    ["D289", "CS", "DIAMANTE", 1],
    ["D290", "CS", "DIANO", 0],
    ["D291", "CN", "DIANO", 0],
    ["D292", "SA", "DIANO", 0],
    ["D293", "IM", "DIANO ARENTINO", 1],
    ["D294", "IM", "DIANO BORELLO", 0],
    ["D295", "IM", "DIANO CALDERINA", 0],
    ["D296", "IM", "DIANO CASTELLO", 1],
    ["D291", "CN", "DIANO D'ALBA", 1],
    ["D297", "IM", "DIANO MARINA", 1],
    ["D298", "IM", "DIANO SAN PIETRO", 1],
    ["D299", "FI", "DICOMANO", 1],
    ["D300", "UD", "DIGNANO", 1],
    ["D301", "PL", "DIGNANO D'ISTRIA", 0],
    ["D302", "TN", "DIMARO", 0],
    ["M366", "TN", "DIMARO FOLGARIDA", 1],
    ["D303", "VV", "DINAMI", 1],
    ["Z900", "EE", "DIPENDENZE AUSTRALIANE", 1],
    ["Z901", "EE", "DIPENDENZE BRITANNICHE", 1],
    ["Z800", "EE", "DIPENDENZE CANADESI", 1],
    ["Z902", "EE", "DIPENDENZE FRANCESI", 1],
    ["Z903", "EE", "DIPENDENZE NEOZELANDESI", 1],
    ["Z904", "EE", "DIPENDENZE NORVEGESI ANTARTICHE", 1],
    ["Z801", "EE", "DIPENDENZE NORVEGESI ARTICHE", 1],
    ["Z802", "EE", "DIPENDENZE RUSSE", 1],
    ["Z802", "EE", "DIPENDENZE SOVIETICHE", 0],
    ["Z905", "EE", "DIPENDENZE STATUNITENSI", 1],
    ["Z906", "EE", "DIPENDENZE SUDAFRICANE", 1],
    ["D304", "CS", "DIPIGNANO", 1],
    ["D305", "LE", "DISO", 1],
    ["D306", "NO", "DISSIMO", 0],
    ["D307", "TS", "DIVACCIA GROTTE DEL TIMAVO", 0],
    ["D308", "TS", "DIVACCIA SAN CANZIANO", 0],
    ["D309", "NO", "DIVIGNANO", 1],
    ["D310", "CO", "DIZZASCO", 1],
    ["D311", "BZ", "DOBBIACO", 1],
    ["D312", "GO", "DOBERDO'", 0],
    ["D312", "GO", "DOBERDO' DEL LAGO", 1],
    ["D313", "VC", "DOCCIO", 0],
    ["D314", "CN", "DOGLIANI", 1],
    ["D315", "CH", "DOGLIOLA", 1],
    ["D316", "UD", "DOGNA", 1],
    ["D322", "GO", "DOL GRANDE", 0],
    ["D326", "GO", "DOL OTTELZA", 0],
    ["D317", "VR", "DOLCE'", 1],
    ["D318", "IM", "DOLCEACQUA", 1],
    ["D319", "IM", "DOLCEDO", 1],
    ["D320", "GO", "DOLE", 0],
    ["D321", "GO", "DOLEGNA", 0],
    ["D321", "GO", "DOLEGNA DEL COLLIO", 1],
    ["D323", "CA", "DOLIANOVA", 0],
    ["D323", "SU", "DOLIANOVA", 1],
    ["D324", "TS", "DOLINA", 0],
    ["D325", "VE", "DOLO", 1],
    ["D327", "LC", "DOLZAGO", 1],
    ["D328", "CS", "DOMANICO", 1],
    ["D329", "CO", "DOMASO", 1],
    ["D330", "BL", "DOMEGGE", 0],
    ["D330", "BL", "DOMEGGE DI CADORE", 1],
    ["D331", "AV", "DOMICELLA", 1],
    ["Z526", "EE", "DOMINICA", 1],
    ["Z505", "EE", "DOMINICANA REPUBBLICA", 1],
    ["D332", "VB", "DOMODOSSOLA", 1],
    ["D333", "CA", "DOMUS DE MARIA", 0],
    ["D333", "SU", "DOMUS DE MARIA", 1],
    ["D334", "CA", "DOMUSNOVAS", 0],
    ["D334", "SU", "DOMUSNOVAS", 1],
    ["D335", "CA", "DOMUSNOVAS CANALES", 0],
    ["D336", "TN", "DON", 0],
    ["D337", "RO", "DONADA", 0],
    ["D338", "AO", "DONAS", 0],
    ["D339", "BI", "DONATO", 1],
    ["D340", "PV", "DONELASCO", 0],
    ["D341", "CO", "DONGO", 1],
    ["D342", "CA", "DONIGALA", 0],
    ["D343", "CA", "DONIGALA", 0],
    ["D342", "CA", "DONIGALA FENUGHEDU", 0],
    ["D343", "CA", "DONIGALA SIURGUS", 0],
    ["D338", "AO", "DONNAS", 1],
    ["D338", "AO", "DONNAZ", 0],
    ["D344", "CA", "DONORI", 0],
    ["D344", "SU", "DONORI", 1],
    ["D345", "NU", "DORGALI", 1],
    ["D346", "LC", "DORIO", 1],
    ["D347", "NO", "DORMELLETTO", 1],
    ["D348", "PV", "DORNO", 1],
    ["D349", "TN", "DORSINO", 0],
    ["D350", "BI", "DORZANO", 1],
    ["D351", "MN", "DOSOLO", 1],
    ["D352", "BG", "DOSSENA", 1],
    ["D353", "CR", "DOSSO BAROARDO", 0],
    ["D354", "CR", "DOSSO DE' FRATI", 0],
    ["D355", "CO", "DOSSO DEL LIRO", 1],
    ["D356", "AO", "DOUES", 1],
    ["D357", "FC", "DOVADOLA", 1],
    ["D358", "CR", "DOVERA", 1],
    ["D356", "AO", "DOVIA D'AOSTA", 0],
    ["D359", "CO", "DOZIO", 0],
    ["D360", "BO", "DOZZA", 1],
    ["D361", "CE", "DRAGONI", 1],
    ["D362", "PL", "DRAGUCCIO", 0],
    ["D363", "CO", "DRANO", 0],
    ["D364", "VV", "DRAPIA", 1],
    ["D365", "TN", "DRENA", 1],
    ["D366", "UD", "DRENCHIA", 1],
    ["D367", "MI", "DRESANO", 1],
    ["D368", "GO", "DRESENZA", 0],
    ["D369", "CO", "DREZZO", 0],
    ["D370", "CR", "DRIZZONA", 0],
    ["D371", "TN", "DRO", 1],
    ["D372", "CN", "DRONERO", 1],
    ["D373", "TO", "DRUENT", 0],
    ["D373", "TO", "DRUENTO", 1],
    ["D374", "VB", "DRUOGNO", 1],
    ["D375", "AO", "DRUSACCO", 0],
    ["D376", "NU", "DUALCHI", 1],
    ["D377", "SO", "DUBINO", 1],
    ["M300", "PD", "DUE CARRARE", 1],
    ["C318", "AR", "DUE COMUNI DI LATERINA", 0],
    ["D091", "VA", "DUE COSSANI", 0],
    ["D378", "CR", "DUEMIGLIA", 0],
    ["D379", "VI", "DUEVILLE", 1],
    ["D380", "BN", "DUGENTA", 1],
    ["D381", "MI", "DUGNANO", 0],
    ["D382", "TS", "DUINO", 0],
    ["D383", "TS", "DUINO AURISINA", 1],
    ["D383", "TS", "DUINO-AURISINA", 0],
    ["D384", "VA", "DUMENZA", 1],
    ["D385", "VA", "DUNO", 1],
    ["D386", "BN", "DURAZZANO", 1],
    ["C772", "CB", "DURONIA", 1],
    ["D387", "AT", "DUSINO", 0],
    ["D388", "AT", "DUSINO SAN MICHELE", 1],
    ["D389", "TS", "DUTTOGLIANO", 0],
    ["M229", "TN", "DUVREDO", 0],
    ["D390", "SA", "EBOLI", 1],
    ["Z605", "EE", "ECUADOR", 1],
    ["D391", "BS", "EDOLO", 1],
    ["Z336", "EE", "EGITTO", 1],
    ["D392", "BZ", "EGNA", 1],
    ["Z506", "EE", "EL SALVADOR", 1],
    ["D393", "RM", "ELENA", 0],
    ["D394", "PE", "ELICE", 1],
    ["D395", "NU", "ELINI", 1],
    ["D396", "BZ", "ELLE", 0],
    ["D397", "SV", "ELLERA", 0],
    ["D398", "LC", "ELLO", 1],
    ["D399", "CA", "ELMAS", 1],
    ["D400", "FM", "ELSANE", 0],
    ["D401", "CN", "ELVA", 1],
    ["D402", "AO", "EMARE'SE", 0],
    ["D402", "AO", "EMARESE", 1],
    ["Z215", "EE", "EMIRATI ARABI UNITI", 1],
    ["D403", "FI", "EMPOLI", 1],
    ["D404", "BG", "ENDENNA", 0],
    ["D405", "BG", "ENDINE", 0],
    ["D406", "BG", "ENDINE GAIANO", 1],
    ["D407", "VI", "ENEGO", 1],
    ["D408", "UD", "ENEMONZO", 1],
    ["D409", "TN", "ENGUISO", 0],
    ["C342", "EN", "ENNA", 1],
    ["D410", "CN", "ENTRACQUE", 1],
    ["D410", "CN", "ENTRAQUE", 0],
    ["D411", "BG", "ENTRATICO", 1],
    ["D412", "CN", "ENVIE", 1],
    ["D413", "BZ", "EORES", 0],
    ["D414", "PZ", "EPISCOPIA", 1],
    ["D415", "VE", "ERACLEA", 1],
    ["D416", "CO", "ERBA", 1],
    ["D417", "CO", "ERBA INCINO", 0],
    ["D418", "BS", "ERBANNO", 0],
    ["D419", "VR", "ERBE'", 1],
    ["D420", "VR", "ERBEZZO", 1],
    ["D421", "BS", "ERBUSCO", 1],
    ["D422", "BR", "ERCHIE", 1],
    ["H243", "NA", "ERCOLANO", 1],
    ["D423", "TP", "ERICE", 1],
    ["Z368", "EE", "ERITREA", 1],
    ["D424", "SV", "ERLI", 1],
    ["D425", "PL", "ERPELLE-COSINA", 0],
    ["D427", "GO", "ERSEL IN MONTE", 0],
    ["D426", "PN", "ERTO E CASSO", 1],
    ["M292", "SS", "ERULA", 1],
    ["D428", "LC", "ERVE", 1],
    ["D429", "MC", "ESANATOGLIA", 1],
    ["D430", "NU", "ESCALAPLANO", 0],
    ["D430", "SU", "ESCALAPLANO", 1],
    ["D431", "NU", "ESCOLCA", 0],
    ["D431", "SU", "ESCOLCA", 1],
    ["D432", "CA", "ESCOVEDU", 0],
    ["D433", "TO", "ESILLE", 0],
    ["D434", "BS", "ESINE", 1],
    ["D435", "CO", "ESINO INFERIORE", 0],
    ["D436", "LC", "ESINO LARIO", 1],
    ["D437", "CO", "ESINO SUPERIORE", 0],
    ["D438", "NO", "ESIO", 0],
    ["D439", "BG", "ESMATE", 0],
    ["D440", "FR", "ESPERIA", 1],
    ["D441", "SS", "ESPORLATU", 1],
    ["D442", "PD", "ESTE", 1],
    ["D443", "NU", "ESTERZILI", 0],
    ["D443", "SU", "ESTERZILI", 1],
    ["Z144", "EE", "ESTONIA", 1],
    ["Z315", "EE", "ETIOPIA", 1],
    ["D444", "AO", "ETROBLE", 0],
    ["D444", "AO", "ETROUBLES", 1],
    ["D445", "CO", "EUPILIO", 1],
    ["D433", "TO", "EXILLES", 1],
    ["D447", "AL", "FABBRICA", 0],
    ["D448", "CO", "FABBRICA", 0],
    ["D447", "AL", "FABBRICA CURONE", 1],
    ["D448", "CO", "FABBRICA DURINI", 0],
    ["D449", "LU", "FABBRICHE DI VALLICO", 0],
    ["M319", "LU", "FABBRICHE DI VERGEMOLI", 1],
    ["D450", "RE", "FABBRICO", 1],
    ["D446", "VA", "FABIASCO", 0],
    ["D451", "AN", "FABRIANO", 1],
    ["D452", "RM", "FABRICA", 0],
    ["D452", "VT", "FABRICA DI ROMA", 1],
    ["D453", "VV", "FABRIZIA", 1],
    ["D454", "TR", "FABRO", 1],
    ["D455", "UD", "FAEDIS", 1],
    ["D456", "SO", "FAEDO", 0],
    ["D457", "TN", "FAEDO", 0],
    ["D456", "SO", "FAEDO VALTELLINO", 1],
    ["D458", "RA", "FAENZA", 1],
    ["Z108", "EE", "FAER OER (ISOLE)", 1],
    ["D459", "FG", "FAETO", 1],
    ["D460", "TO", "FAETTO", 0],
    ["D461", "UD", "FAGAGNA", 1],
    ["D462", "CO", "FAGGETO LARIO", 1],
    ["D463", "TA", "FAGGIANO", 1],
    ["D464", "CS", "FAGNANO", 0],
    ["D465", "AQ", "FAGNANO", 0],
    ["D466", "MI", "FAGNANO", 0],
    ["D467", "MI", "FAGNANO", 0],
    ["D465", "AQ", "FAGNANO ALTO", 1],
    ["D464", "CS", "FAGNANO CASTELLO", 1],
    ["D467", "VA", "FAGNANO OLONA", 1],
    ["D466", "MI", "FAGNANO SUL NAVIGLIO", 0],
    ["D468", "TN", "FAI", 0],
    ["D468", "TN", "FAI DELLA PAGANELLA", 1],
    ["D469", "BN", "FAICCHIO", 1],
    ["D470", "BL", "FALCADE", 1],
    ["D471", "CE", "FALCIANO DEL MASSICO", 1],
    ["D472", "AN", "FALCONARA", 0],
    ["D473", "CS", "FALCONARA", 0],
    ["D473", "CS", "FALCONARA ALBANESE", 1],
    ["D472", "AN", "FALCONARA MARITTIMA", 1],
    ["D474", "ME", "FALCONE", 1],
    ["D475", "VT", "FALERIA", 1],
    ["D476", "CZ", "FALERNA", 1],
    ["D477", "AP", "FALERONE", 0],
    ["D477", "FM", "FALERONE", 1],
    ["D478", "TN", "FALESINA", 0],
    ["Z609", "EE", "FALKLAND (ISOLE)", 1],
    ["D479", "CH", "FALLASCOSO", 0],
    ["D480", "CH", "FALLO", 1],
    ["D481", "VB", "FALMENTA", 0],
    ["D482", "CO", "FALOPPIO", 1],
    ["D483", "FR", "FALVATERRA", 1],
    ["D484", "BZ", "FALZES", 1],
    ["D485", "TS", "FAMIE", 0],
    ["D486", "MO", "FANANO", 1],
    ["D487", "PN", "FANNA", 1],
    ["D488", "PU", "FANO", 1],
    ["D489", "TE", "FANO ADRIANO", 1],
    ["D519", "BZ", "FAOGNA DI SOTTO", 0],
    ["D490", "BG", "FARA", 0],
    ["D491", "BG", "FARA", 0],
    ["D492", "NO", "FARA", 0],
    ["D493", "RI", "FARA", 0],
    ["D490", "BG", "FARA D'ADDA", 0],
    ["D494", "CH", "FARA FILIORUM PETRI", 1],
    ["D490", "BG", "FARA GERA D'ADDA", 1],
    ["D493", "RI", "FARA IN SABINA", 1],
    ["D492", "NO", "FARA NOVARESE", 1],
    ["D491", "BG", "FARA OLIVANA CON SOLA", 1],
    ["D495", "CH", "FARA SAN MARTINO", 1],
    ["D496", "VI", "FARA VICENTINO", 1],
    ["D497", "PZ", "FARDELLA", 1],
    ["D498", "BS", "FARFENGO", 0],
    ["D499", "CN", "FARIGLIANO", 1],
    ["D500", "CR", "FARINATE", 0],
    ["D501", "PE", "FARINDOLA", 1],
    ["D502", "PC", "FARINI", 1],
    ["D502", "PC", "FARINI D'OLMO", 0],
    ["D503", "VT", "FARNESE", 1],
    ["D504", "GO", "FARRA", 0],
    ["D505", "TV", "FARRA", 0],
    ["D506", "BL", "FARRA D'ALPAGO", 0],
    ["D504", "GO", "FARRA D'ISONZO", 1],
    ["D505", "TV", "FARRA DI SOLIGO", 1],
    ["D507", "PL", "FASANA", 0],
    ["B348", "RO", "FASANA POLESINE", 0],
    ["D508", "BR", "FASANO", 1],
    ["D509", "GE", "FASCIA", 1],
    ["D510", "PI", "FAUGLIA", 1],
    ["D511", "CN", "FAULE", 1],
    ["D512", "GE", "FAVALE", 0],
    ["D513", "PZ", "FAVALE", 0],
    ["D512", "GE", "FAVALE DI MALVARO", 1],
    ["D513", "PZ", "FAVALE SAN CATULDO", 0],
    ["D514", "AG", "FAVARA", 1],
    ["D515", "VE", "FAVARO", 0],
    ["D515", "VE", "FAVARO VENETO", 0],
    ["D516", "TN", "FAVER", 0],
    ["D517", "BS", "FAVERZANO", 0],
    ["D518", "TP", "FAVIGNANA", 1],
    ["D519", "BZ", "FAVOGNA DI SOTTO", 0],
    ["D520", "TO", "FAVRIA", 1],
    ["D521", "TO", "FAVRIA-OGLIANICO", 0],
    ["M230", "TN", "FAVRIO", 0],
    ["D537", "AO", "FE'NIS", 0],
    ["Z154", "EE", "FEDERAZIONE RUSSA", 1],
    ["D290", "CS", "FEGGIANO", 0],
    ["D522", "SV", "FEGLINO", 0],
    ["D523", "CN", "FEISOGLIO", 1],
    ["D524", "TO", "FELETTO", 1],
    ["D525", "UD", "FELETTO", 0],
    ["D525", "UD", "FELETTO UMBERTO", 0],
    ["D526", "PR", "FELINO", 1],
    ["D527", "SA", "FELITTO", 1],
    ["D528", "AL", "FELIZZANO", 1],
    ["D529", "MN", "FELONICA", 0],
    ["D530", "BL", "FELTRE", 1],
    ["D531", "CO", "FENEGRO'", 1],
    ["D532", "TO", "FENESTRELLE", 1],
    ["D533", "CR", "FENGO", 0],
    ["D534", "PU", "FENIGLI", 0],
    ["D535", "TO", "FENILE", 0],
    ["D536", "TO", "FENILS", 0],
    ["D537", "AO", "FENIS", 1],
    ["D538", "TR", "FERENTILLO", 1],
    ["D539", "FR", "FERENTINO", 1],
    ["D540", "SR", "FERLA", 1],
    ["D541", "PU", "FERMIGNANO", 1],
    ["D542", "AP", "FERMO", 0],
    ["D542", "FM", "FERMO", 1],
    ["D543", "VA", "FERNO", 1],
    ["D544", "CZ", "FEROLETO ANTICO", 1],
    ["D545", "RC", "FEROLETO DELLA CHIESA", 1],
    ["D546", "CZ", "FEROLETO PIANO", 0],
    ["D547", "MT", "FERRANDINA", 1],
    ["D548", "FE", "FERRARA", 1],
    ["D549", "VR", "FERRARA DI MONTE BALDO", 1],
    ["D550", "CB", "FERRAZZANO", 1],
    ["D551", "CO", "FERRERA", 0],
    ["D552", "PV", "FERRERA", 0],
    ["D553", "TO", "FERRERA", 0],
    ["D553", "TO", "FERRERA CENISIO", 0],
    ["D551", "VA", "FERRERA DI VARESE", 1],
    ["D552", "PV", "FERRERA ERBOGNONE", 1],
    ["D554", "AT", "FERRERE", 1],
    ["D555", "PC", "FERRIERE", 1],
    ["D556", "VC", "FERRUTA", 0],
    ["D557", "RC", "FERRUZZANO", 1],
    ["D558", "CE", "FERTILIA", 0],
    ["D559", "AL", "FIACCONE", 0],
    ["D560", "RI", "FIAMIGNANO", 1],
    ["D561", "RM", "FIANO", 0],
    ["D562", "TO", "FIANO", 1],
    ["D561", "RM", "FIANO ROMANO", 1],
    ["D563", "PL", "FIANONA", 0],
    ["D564", "MC", "FIASTRA", 1],
    ["D565", "TN", "FIAVE'", 1],
    ["D566", "MC", "FICANO", 0],
    ["D567", "PA", "FICARAZZI", 1],
    ["D568", "RO", "FICAROLO", 1],
    ["D569", "ME", "FICARRA", 1],
    ["D570", "TR", "FICULLE", 1],
    ["B034", "PR", "FIDENZA", 1],
    ["D571", "BZ", "FIE'", 0],
    ["D571", "BZ", "FIE' ALLO SCILIAR", 1],
    ["D572", "TN", "FIERA DI PRIMIERO", 0],
    ["D573", "TN", "FIEROZZO", 1],
    ["D574", "CR", "FIESCO", 1],
    ["D575", "FI", "FIESOLE", 1],
    ["D576", "BS", "FIESSE", 1],
    ["D577", "RO", "FIESSO", 0],
    ["D578", "VE", "FIESSO", 0],
    ["D578", "VE", "FIESSO D'ARTICO", 1],
    ["D577", "RO", "FIESSO UMBERTIANO", 1],
    ["Z704", "EE", "FIGI=VITI", 1],
    ["D579", "CO", "FIGINO", 0],
    ["D580", "MI", "FIGINO", 0],
    ["D580", "MI", "FIGINO DI MILANO", 0],
    ["D579", "CO", "FIGINO SERENZA", 1],
    ["D581", "CO", "FIGLIARO", 0],
    ["D582", "CS", "FIGLINE", 0],
    ["D583", "FI", "FIGLINE", 0],
    ["M321", "FI", "FIGLINE E INCISA VALDARNO", 1],
    ["D583", "FI", "FIGLINE VALDARNO", 0],
    ["D582", "CS", "FIGLINE VEGLIATURO", 1],
    ["D584", "CA", "FIGU", 0],
    ["D585", "CA", "FIGU-GONNOSNO'", 0],
    ["D586", "RM", "FILACCIANO", 1],
    ["D587", "VV", "FILADELFIA", 1],
    ["D588", "BG", "FILAGO", 1],
    ["D589", "VV", "FILANDARI", 1],
    ["D590", "MS", "FILATTIERA", 1],
    ["D591", "FR", "FILETTINO", 1],
    ["D591", "FR", "FILETTINO GRAZIANI", 0],
    ["D592", "CH", "FILETTO", 1],
    ["D593", "PZ", "FILIANO", 1],
    ["D594", "PV", "FILIGHERA", 1],
    ["D595", "IS", "FILIGNANO", 1],
    ["Z216", "EE", "FILIPPINE", 1],
    ["D596", "VV", "FILOGASO", 1],
    ["D597", "AN", "FILOTTRANO", 1],
    ["D598", "SV", "FINALBORGO", 0],
    ["D599", "MO", "FINALE", 0],
    ["D599", "MO", "FINALE EMILIA", 1],
    ["D600", "SV", "FINALE LIGURE", 1],
    ["D599", "MO", "FINALE NELL'EMILIA", 0],
    ["D601", "SV", "FINALMARINA", 0],
    ["D602", "SV", "FINALPIA", 0],
    ["D603", "NO", "FINERO", 0],
    ["Z109", "EE", "FINLANDIA", 1],
    ["D604", "BG", "FINO", 0],
    ["D605", "CO", "FINO", 0],
    ["D604", "BG", "FINO DEL MONTE", 1],
    ["D605", "CO", "FINO MORNASCO", 1],
    ["D606", "BG", "FIORANO", 0],
    ["D607", "MO", "FIORANO", 0],
    ["D608", "TO", "FIORANO", 0],
    ["D606", "BG", "FIORANO AL SERIO", 1],
    ["D608", "TO", "FIORANO CANAVESE", 1],
    ["D607", "MO", "FIORANO MODENESE", 1],
    ["D609", "MC", "FIORDIMONTE", 0],
    ["D610", "PU", "FIORENZUOLA", 0],
    ["D611", "PC", "FIORENZUOLA", 0],
    ["D611", "PC", "FIORENZUOLA D'ARDA", 1],
    ["D610", "PU", "FIORENZUOLA DI FOCARA", 0],
    ["D612", "FI", "FIRENZE", 1],
    ["D613", "FI", "FIRENZUOLA", 1],
    ["D614", "CS", "FIRMO", 1],
    ["M323", "FE", "FISCAGLIA", 1],
    ["D615", "SA", "FISCIANO", 1],
    ["D616", "TN", "FISTO", 0],
    ["C415", "PI", "FITTO DI CECINA", 0],
    ["A310", "FR", "FIUGGI", 1],
    ["D617", "MO", "FIUMALBO", 1],
    ["D618", "FC", "FIUMANA", 0],
    ["D619", "RC", "FIUMARA", 1],
    ["D620", "FM", "FIUME", 0],
    ["D621", "UD", "FIUME", 0],
    ["D621", "PN", "FIUME VENETO", 1],
    ["D622", "ME", "FIUMEDINISI", 1],
    ["D623", "CT", "FIUMEFREDDO", 0],
    ["D624", "CS", "FIUMEFREDDO", 0],
    ["D624", "CS", "FIUMEFREDDO BRUZIO", 1],
    ["D623", "CT", "FIUMEFREDDO DI SICILIA", 1],
    ["D625", "BG", "FIUMENERO", 0],
    ["D626", "BS", "FIUMICELLO", 0],
    ["D627", "UD", "FIUMICELLO", 0],
    ["D626", "BS", "FIUMICELLO URAGO", 0],
    ["M400", "UD", "FIUMICELLO VILLA VICENTINA", 1],
    ["M297", "RM", "FIUMICINO", 1],
    ["D628", "MC", "FIUMINATA", 1],
    ["D629", "MS", "FIVIZZANO", 1],
    ["D630", "UD", "FLAIBANO", 1],
    ["D631", "TN", "FLAVON", 0],
    ["D632", "VC", "FLECCHIA", 0],
    ["D633", "BZ", "FLERES", 0],
    ["D634", "BS", "FLERO", 1],
    ["D635", "ME", "FLORESTA", 1],
    ["D636", "SR", "FLORIDIA", 1],
    ["D637", "SS", "FLORINAS", 1],
    ["D638", "AV", "FLUMERI", 1],
    ["D639", "CA", "FLUMINIMAGGIORE", 0],
    ["D639", "SU", "FLUMINIMAGGIORE", 1],
    ["D640", "NU", "FLUSSIO", 0],
    ["D640", "OR", "FLUSSIO", 1],
    ["D641", "VC", "FOBELLO", 1],
    ["D642", "GE", "FOCE", 0],
    ["D643", "FG", "FOGGIA", 1],
    ["D644", "BN", "FOGLIANISE", 1],
    ["D645", "TS", "FOGLIANO DI MONFALCONE", 0],
    ["D645", "GO", "FOGLIANO REDIPUGLIA", 1],
    ["D646", "TO", "FOGLIZZO", 1],
    ["D647", "SA", "FOGNA", 0],
    ["D648", "BZ", "FOIANA", 0],
    ["D649", "AR", "FOIANO", 0],
    ["D650", "BN", "FOIANO", 0],
    ["D649", "AR", "FOIANO DELLA CHIANA", 1],
    ["D650", "BN", "FOIANO DI VAL FORTORE", 1],
    ["H249", "TN", "FOLAS-REVIAN", 0],
    ["D651", "TN", "FOLGARIA", 1],
    ["D652", "AP", "FOLIGNANO", 1],
    ["D653", "PG", "FOLIGNO", 1],
    ["D654", "TV", "FOLLINA", 1],
    ["D655", "SP", "FOLLO", 1],
    ["D656", "GR", "FOLLONICA", 1],
    ["D657", "NO", "FOLSOGNO", 0],
    ["D658", "BS", "FOLZANO", 0],
    ["D659", "NO", "FOMARCO", 0],
    ["D660", "LO", "FOMBIO", 1],
    ["D661", "ME", "FONDACHELLI-FANTINA", 1],
    ["D662", "LT", "FONDI", 1],
    ["D663", "TN", "FONDO", 0],
    ["D664", "BG", "FONDRA", 0],
    ["D665", "NU", "FONNI", 1],
    ["D666", "AO", "FONTAINEMORE", 1],
    ["D667", "FR", "FONTANA", 0],
    ["D668", "RA", "FONTANA", 0],
    ["D669", "FM", "FONTANA DEL CONTE", 0],
    ["D668", "BO", "FONTANA ELICE", 0],
    ["D667", "FR", "FONTANA LIRI", 1],
    ["D670", "PN", "FONTANAFREDDA", 1],
    ["D671", "AV", "FONTANAROSA", 1],
    ["D668", "BO", "FONTANELICE", 1],
    ["D672", "BG", "FONTANELLA", 1],
    ["D673", "PR", "FONTANELLATO", 1],
    ["D674", "TV", "FONTANELLE", 1],
    ["D675", "NO", "FONTANETO D'AGOGNA", 1],
    ["D675", "NO", "FONTANETTO", 0],
    ["D676", "NO", "FONTANETTO", 0],
    ["D676", "VC", "FONTANETTO DA PO", 0],
    ["D676", "VC", "FONTANETTO PO", 1],
    ["D677", "GE", "FONTANIGORDA", 1],
    ["D678", "AT", "FONTANILE", 1],
    ["D679", "PD", "FONTANIVA", 1],
    ["D680", "TV", "FONTE", 1],
    ["M309", "RM", "FONTE NUOVA", 1],
    ["D681", "AQ", "FONTECCHIO", 1],
    ["D682", "FR", "FONTECHIARI", 1],
    ["D683", "CE", "FONTEGRECA", 1],
    ["D684", "BG", "FONTENO", 1],
    ["D685", "PR", "FONTEVIVO", 1],
    ["D686", "BL", "FONZASO", 1],
    ["D687", "PV", "FOPPA", 0],
    ["D688", "BG", "FOPPOLO", 1],
    ["D689", "RI", "FORANO", 1],
    ["D690", "CH", "FORCABOBOLINA", 0],
    ["D691", "AP", "FORCE", 1],
    ["D692", "CR", "FORCELLO", 0],
    ["D693", "BN", "FORCHIA", 1],
    ["D694", "SO", "FORCOLA", 1],
    ["D695", "OR", "FORDONGIANUS", 1],
    ["D696", "PZ", "FORENZA", 1],
    ["D697", "BG", "FORESTO", 0],
    ["D698", "NO", "FORESTO", 0],
    ["D699", "TO", "FORESTO", 0],
    ["D699", "TO", "FORESTO DI SUSA", 0],
    ["D698", "VC", "FORESTO SESIA", 0],
    ["D697", "BG", "FORESTO SPARSO", 1],
    ["D700", "UD", "FORGARIA", 0],
    ["D700", "UD", "FORGARIA NEL FRIULI", 1],
    ["D701", "AV", "FORINO", 1],
    ["D702", "NA", "FORIO", 1],
    ["D703", "CB", "FORLI'", 0],
    ["D704", "FC", "FORLI'", 1],
    ["D703", "IS", "FORLI' DEL SANNIO", 1],
    ["D705", "FC", "FORLIMPOPOLI", 1],
    ["D706", "VB", "FORMAZZA", 1],
    ["D707", "RM", "FORMELLO", 1],
    ["D708", "LT", "FORMIA", 1],
    ["D709", "CE", "FORMICOLA", 1],
    ["D710", "CR", "FORMIGARA", 1],
    ["D711", "MO", "FORMIGINE", 1],
    ["D712", "VC", "FORMIGLIANA", 1],
    ["D713", "FE", "FORMIGNANA", 0],
    ["Z217", "EE", "FORMOSA=TAIWAN", 1],
    ["D714", "TN", "FORNACE", 1],
    ["D715", "IS", "FORNELLI", 1],
    ["D716", "NO", "FORNERO", 0],
    ["D717", "VI", "FORNI", 0],
    ["D718", "UD", "FORNI AVOLTRI", 1],
    ["D719", "UD", "FORNI DI SOPRA", 1],
    ["D720", "UD", "FORNI DI SOTTO", 1],
    ["D721", "VI", "FORNI IN VAL D'ASTICO", 0],
    ["D722", "NO", "FORNO", 0],
    ["D723", "TN", "FORNO", 0],
    ["D724", "TO", "FORNO ALPI GRAIE", 0],
    ["D725", "TO", "FORNO CANAVESE", 1],
    ["B574", "BL", "FORNO DI CANALE", 0],
    ["D725", "TO", "FORNO DI RIVARA", 0],
    ["D726", "BL", "FORNO DI ZOLDO", 0],
    ["D724", "TO", "FORNO GROSCAVALLO", 0],
    ["D727", "BG", "FORNOVO", 0],
    ["D728", "PR", "FORNOVO", 0],
    ["D727", "BG", "FORNOVO DI SAN GIOVANNI", 0],
    ["D728", "PR", "FORNOVO DI TARO", 1],
    ["D727", "BG", "FORNOVO SAN GIOVANNI", 1],
    ["D729", "AL", "FOROTONDO", 0],
    ["C882", "CA", "FORRU", 0],
    ["D730", "LU", "FORTE DEI MARMI", 1],
    ["D731", "BZ", "FORTEZZA", 1],
    ["D732", "PV", "FORTUNAGO", 1],
    ["D733", "ME", "FORZA D'AGRO'", 1],
    ["D734", "LU", "FOSCIANDORA", 1],
    ["D735", "MS", "FOSDINOVO", 1],
    ["D736", "AQ", "FOSSA", 1],
    ["D739", "CR", "FOSSA GUAZZONA", 0],
    ["D683", "CE", "FOSSACECA", 0],
    ["D737", "CB", "FOSSACECA", 0],
    ["D738", "CH", "FOSSACECA", 0],
    ["D738", "CH", "FOSSACESIA", 1],
    ["D740", "VE", "FOSSALTA", 0],
    ["D741", "VE", "FOSSALTA", 0],
    ["D740", "VE", "FOSSALTA DI PIAVE", 1],
    ["D741", "VE", "FOSSALTA DI PORTOGRUARO", 1],
    ["D737", "CB", "FOSSALTO", 1],
    ["D742", "CN", "FOSSANO", 1],
    ["D743", "PV", "FOSSARMATO", 0],
    ["D744", "CZ", "FOSSATO", 0],
    ["D745", "PG", "FOSSATO", 0],
    ["D746", "RC", "FOSSATO CALABRO", 0],
    ["D745", "PG", "FOSSATO DI VICO", 1],
    ["D744", "CZ", "FOSSATO SERRALTA", 1],
    ["D747", "NO", "FOSSENO", 0],
    ["D748", "VE", "FOSSO'", 1],
    ["D749", "PU", "FOSSOMBRONE", 1],
    ["D750", "VI", "FOZA", 1],
    ["D751", "CN", "FRABOSA SOPRANA", 1],
    ["D752", "CN", "FRABOSA SOTTANA", 1],
    ["D753", "CR", "FRACCHIA", 0],
    ["D559", "AL", "FRACONALTO", 1],
    ["D754", "TA", "FRAGAGNANO", 1],
    ["D755", "BN", "FRAGNETO L'ABATE", 1],
    ["D756", "BN", "FRAGNETO MONFORTE", 1],
    ["D757", "CH", "FRAINE", 1],
    ["D758", "SP", "FRAMURA", 1],
    ["D759", "AL", "FRANCAVILLA", 0],
    ["D760", "AP", "FRANCAVILLA", 0],
    ["D761", "BR", "FRANCAVILLA", 0],
    ["D762", "CZ", "FRANCAVILLA", 0],
    ["D763", "CH", "FRANCAVILLA", 0],
    ["D764", "CS", "FRANCAVILLA", 0],
    ["D765", "ME", "FRANCAVILLA", 0],
    ["D766", "PZ", "FRANCAVILLA", 0],
    ["D763", "CH", "FRANCAVILLA AL MARE", 1],
    ["D762", "VV", "FRANCAVILLA ANGITOLA", 1],
    ["D759", "AL", "FRANCAVILLA BISIO", 1],
    ["D760", "AP", "FRANCAVILLA D'ETE", 0],
    ["D760", "FM", "FRANCAVILLA D'ETE", 1],
    ["D765", "ME", "FRANCAVILLA DI SICILIA", 1],
    ["D761", "BR", "FRANCAVILLA FONTANA", 1],
    ["D766", "PZ", "FRANCAVILLA IN SINNI", 1],
    ["D764", "CS", "FRANCAVILLA MARITTIMA", 1],
    ["Z110", "EE", "FRANCIA", 1],
    ["D767", "VV", "FRANCICA", 1],
    ["D768", "SR", "FRANCOFONTE", 1],
    ["D769", "CE", "FRANCOLISE", 1],
    ["D770", "AL", "FRASCARO", 1],
    ["D771", "PV", "FRASCAROLO", 1],
    ["D772", "AL", "FRASCATA", 0],
    ["D773", "RM", "FRASCATI", 1],
    ["D774", "CS", "FRASCINETO", 1],
    ["D775", "TN", "FRASSILONGO", 1],
    ["D776", "RO", "FRASSINELLE", 0],
    ["D776", "RO", "FRASSINELLE POLESINE", 1],
    ["D777", "AL", "FRASSINELLO", 0],
    ["D777", "AL", "FRASSINELLO MONFERRATO", 1],
    ["D778", "AL", "FRASSINELLO-OLIVOLA", 0],
    ["D779", "TO", "FRASSINERE", 0],
    ["D780", "AL", "FRASSINETO PO", 1],
    ["D781", "TO", "FRASSINETTO", 1],
    ["D782", "CN", "FRASSINO", 1],
    ["D783", "MO", "FRASSINORO", 1],
    ["D784", "BN", "FRASSO", 0],
    ["D785", "RI", "FRASSO", 0],
    ["D785", "RI", "FRASSO SABINO", 1],
    ["D784", "BN", "FRASSO TELESINO", 1],
    ["D786", "PG", "FRATTA", 0],
    ["D787", "PG", "FRATTA", 0],
    ["D788", "RO", "FRATTA", 0],
    ["D788", "RO", "FRATTA POLESINE", 1],
    ["D787", "PG", "FRATTA TODINA", 1],
    ["D789", "NA", "FRATTAMAGGIORE", 1],
    ["D790", "NA", "FRATTAMINORE", 1],
    ["A502", "FR", "FRATTE", 0],
    ["D791", "PU", "FRATTE", 0],
    ["D791", "PU", "FRATTE ROSA", 1],
    ["D792", "TN", "FRAVEGGIO", 0],
    ["D793", "ME", "FRAZZANO'", 1],
    ["D794", "TV", "FREGONA", 1],
    ["D795", "BG", "FREROLA", 0],
    ["D796", "CH", "FRESAGRANDINARIA", 1],
    ["D797", "AL", "FRESONARA", 1],
    ["D798", "AV", "FRIGENTO", 1],
    ["D799", "CE", "FRIGNANO", 1],
    ["D800", "CE", "FRIGNANO MAGGIORE", 0],
    ["D801", "CE", "FRIGNANO PICCOLO", 0],
    ["D802", "AT", "FRINCO", 1],
    ["D803", "CH", "FRISA", 1],
    ["D804", "PN", "FRISANCO", 1],
    ["D805", "TO", "FRONT", 1],
    ["D806", "BS", "FRONTIGNANO", 0],
    ["D807", "PU", "FRONTINO", 1],
    ["D808", "PU", "FRONTONE", 1],
    ["D809", "PU", "FRONTONE-SERRA", 0],
    ["D810", "FR", "FROSINONE", 1],
    ["D811", "IS", "FROSOLONE", 1],
    ["D812", "TO", "FROSSASCO", 1],
    ["D813", "AL", "FRUGAROLO", 1],
    ["D814", "AL", "FUBINE", 0],
    ["D814", "AL", "FUBINE MONFERRATO", 1],
    ["D815", "FI", "FUCECCHIO", 1],
    ["D816", "BG", "FUIPIANO", 0],
    ["D816", "BG", "FUIPIANO AL BREMBO", 0],
    ["D817", "BG", "FUIPIANO VALLE IMAGNA", 1],
    ["D818", "VR", "FUMANE", 1],
    ["D819", "FR", "FUMONE", 1],
    ["D820", "BZ", "FUNDOLES", 0],
    ["D820", "BZ", "FUNDRES", 0],
    ["D821", "BZ", "FUNES", 1],
    ["D822", "MI", "FURATO", 0],
    ["D823", "CH", "FURCI", 1],
    ["D824", "ME", "FURCI SICULO", 1],
    ["D825", "ME", "FURNARI", 1],
    ["D826", "SA", "FURORE", 1],
    ["D827", "CA", "FURTEI", 0],
    ["D827", "SU", "FURTEI", 1],
    ["D828", "CS", "FUSCALDO", 1],
    ["D829", "RA", "FUSIGNANO", 1],
    ["D830", "SO", "FUSINE", 1],
    ["D831", "UD", "FUSINE IN VALROMANA", 0],
    ["D832", "SA", "FUTANI", 1],
    ["B035", "BS", "GABBIANO", 0],
    ["C115", "CR", "GABBIANO", 0],
    ["D833", "CR", "GABBIONETA", 0],
    ["D834", "CR", "GABBIONETA BINANUOVA", 0],
    ["D834", "CR", "GABBIONETA-BINANUOVA", 1],
    ["D835", "AL", "GABIANO", 1],
    ["D836", "PU", "GABICCE", 0],
    ["D836", "PU", "GABICCE MARE", 1],
    ["Z316", "EE", "GABON", 1],
    ["D837", "GO", "GABRIA", 0],
    ["D838", "GO", "GABROVIZZA", 0],
    ["D839", "AO", "GABY", 1],
    ["D840", "CR", "GADESCO", 0],
    ["D841", "CR", "GADESCO PIEVE DELMONA", 0],
    ["D841", "CR", "GADESCO-PIEVE DELMONA", 1],
    ["D842", "NU", "GADONI", 1],
    ["D843", "LT", "GAETA", 1],
    ["D844", "ME", "GAGGI", 1],
    ["D845", "MI", "GAGGIANO", 1],
    ["D846", "CO", "GAGGINO", 0],
    ["D847", "BO", "GAGGIO MONTANO", 1],
    ["D848", "BI", "GAGLIANICO", 1],
    ["D849", "CT", "GAGLIANO", 0],
    ["D850", "AQ", "GAGLIANO", 0],
    ["D851", "LE", "GAGLIANO", 0],
    ["D850", "AQ", "GAGLIANO ATERNO", 1],
    ["D849", "EN", "GAGLIANO CASTELFERRATO", 1],
    ["D851", "LE", "GAGLIANO DEL CAPO", 1],
    ["D852", "CZ", "GAGLIATO", 1],
    ["D853", "MC", "GAGLIOLE", 1],
    ["D854", "TV", "GAIARINE", 1],
    ["D855", "RO", "GAIBA", 1],
    ["D856", "CN", "GAIOLA", 1],
    ["D857", "CN", "GAIOLA-MOIOLA", 0],
    ["D858", "SI", "GAIOLE", 0],
    ["D858", "SI", "GAIOLE IN CHIANTI", 1],
    ["D859", "NU", "GAIRO", 1],
    ["D860", "BZ", "GAIS", 1],
    ["D861", "ME", "GALATI", 0],
    ["D861", "ME", "GALATI MAMERTINO", 1],
    ["D862", "LE", "GALATINA", 1],
    ["D863", "LE", "GALATONE", 1],
    ["D864", "RC", "GALATRO", 1],
    ["D865", "LC", "GALBIATE", 1],
    ["D866", "SA", "GALDO", 0],
    ["D867", "FC", "GALEATA", 1],
    ["D868", "LO", "GALGAGNANO", 1],
    ["D869", "VA", "GALLARATE", 1],
    ["D870", "VT", "GALLESE", 1],
    ["D871", "CO", "GALLIATE", 0],
    ["D872", "NO", "GALLIATE", 1],
    ["D871", "VA", "GALLIATE LOMBARDO", 1],
    ["D873", "PV", "GALLIAVOLA", 1],
    ["D874", "LU", "GALLICANO", 1],
    ["D875", "RM", "GALLICANO", 0],
    ["D875", "RM", "GALLICANO NEL LAZIO", 1],
    ["D876", "PZ", "GALLICCHIO", 1],
    ["D877", "RC", "GALLICO", 0],
    ["D878", "BO", "GALLIERA", 1],
    ["D879", "PD", "GALLIERA", 0],
    ["D879", "PD", "GALLIERA VENETA", 1],
    ["D880", "RC", "GALLINA", 0],
    ["D881", "FR", "GALLINARO", 1],
    ["D882", "VI", "GALLIO", 1],
    ["D883", "LE", "GALLIPOLI", 1],
    ["D884", "CE", "GALLO", 0],
    ["D884", "CE", "GALLO MATESE", 1],
    ["D885", "ME", "GALLODORO", 1],
    ["D886", "CE", "GALLUCCIO", 1],
    ["D887", "FI", "GALLUZZO", 0],
    ["D888", "NU", "GALTELLI", 0],
    ["D888", "NU", "GALTELLI'", 1],
    ["D889", "PD", "GALZIGNANO", 0],
    ["D889", "PD", "GALZIGNANO TERME", 1],
    ["D890", "AL", "GAMALERO", 1],
    ["D891", "BS", "GAMBARA", 1],
    ["D892", "PV", "GAMBARANA", 1],
    ["D893", "VE", "GAMBARARE", 0],
    ["D894", "CN", "GAMBASCA", 1],
    ["D895", "FI", "GAMBASSI", 0],
    ["D895", "FI", "GAMBASSI TERME", 1],
    ["D896", "CB", "GAMBATESA", 1],
    ["D897", "VI", "GAMBELLARA", 1],
    ["D898", "CH", "GAMBERALE", 1],
    ["D899", "FC", "GAMBETTOLA", 1],
    ["Z317", "EE", "GAMBIA", 1],
    ["D900", "CR", "GAMBINA BARCHETTI", 0],
    ["D901", "PV", "GAMBOLO'", 1],
    ["D902", "VI", "GAMBUGLIANO", 1],
    ["D903", "BG", "GANDELLINO", 1],
    ["D904", "CR", "GANDINI", 0],
    ["D905", "BG", "GANDINO", 1],
    ["D906", "BG", "GANDOSSO", 1],
    ["D907", "PA", "GANGI", 1],
    ["D908", "VA", "GARABIOLO", 0],
    ["D909", "MT", "GARAGUSO", 1],
    ["D910", "AL", "GARBAGNA", 1],
    ["D911", "NO", "GARBAGNA", 0],
    ["D911", "NO", "GARBAGNA NOVARESE", 1],
    ["D912", "MI", "GARBAGNATE", 0],
    ["D912", "MI", "GARBAGNATE MILANESE", 1],
    ["D913", "LC", "GARBAGNATE MONASTERO", 1],
    ["D914", "MI", "GARBATOLA", 0],
    ["D915", "VR", "GARDA", 1],
    ["D916", "TN", "GARDOLO", 0],
    ["D917", "BS", "GARDONE RIVIERA", 1],
    ["D918", "BS", "GARDONE VAL TROMPIA", 1],
    ["D919", "MI", "GAREGNANO", 0],
    ["D920", "CN", "GARESSIO", 1],
    ["D921", "NO", "GARGALLO", 1],
    ["D922", "GO", "GARGARO", 0],
    ["D923", "BZ", "GARGAZZONE", 1],
    ["D924", "BS", "GARGNANO", 1],
    ["D925", "PV", "GARLASCO", 1],
    ["D926", "LC", "GARLATE", 1],
    ["D927", "SV", "GARLENDA", 1],
    ["D928", "TN", "GARNIGA", 0],
    ["D928", "TN", "GARNIGA TERME", 1],
    ["D929", "SS", "GAROFAI", 0],
    ["D930", "CO", "GARZENO", 1],
    ["D931", "TO", "GARZIGLIANA", 1],
    ["D932", "CZ", "GASPERINA", 1],
    ["D933", "TO", "GASSINO", 0],
    ["D933", "TO", "GASSINO TORINESE", 1],
    ["D934", "RE", "GATTATICO", 1],
    ["D935", "FC", "GATTEO", 1],
    ["D936", "MI", "GATTERA MAIOCCA", 0],
    ["D937", "NO", "GATTICO", 0],
    ["M416", "NO", "GATTICO-VERUNO", 1],
    ["D938", "VC", "GATTINARA", 1],
    ["D939", "AO", "GAUNA", 0],
    ["D940", "BS", "GAVARDO", 1],
    ["D941", "AL", "GAVAZZANA", 0],
    ["D942", "RO", "GAVELLO", 1],
    ["D943", "BG", "GAVERINA", 0],
    ["D943", "BG", "GAVERINA TERME", 1],
    ["D944", "AL", "GAVI", 1],
    ["D945", "RM", "GAVIGNANO", 1],
    ["D946", "VA", "GAVIRATE", 1],
    ["D947", "NU", "GAVOI", 1],
    ["D948", "GR", "GAVORRANO", 1],
    ["Z218", "EE", "GAZA (TERRITORIO DI)", 0],
    ["D949", "MN", "GAZOLDO DEGLI IPPOLITI", 1],
    ["D950", "VA", "GAZZADA", 0],
    ["D951", "VA", "GAZZADA SCHIANNO", 1],
    ["D952", "BG", "GAZZANIGA", 1],
    ["D953", "RE", "GAZZANO", 0],
    ["D954", "IM", "GAZZELLI", 0],
    ["D955", "CR", "GAZZO", 0],
    ["D956", "PD", "GAZZO", 1],
    ["D957", "VR", "GAZZO", 0],
    ["D956", "PD", "GAZZO PADOVANO", 0],
    ["D957", "VR", "GAZZO VERONESE", 1],
    ["D958", "PC", "GAZZOLA", 1],
    ["D949", "MN", "GAZZOLDO", 0],
    ["D959", "MN", "GAZZUOLO", 1],
    ["D960", "CL", "GELA", 1],
    ["D961", "RN", "GEMMANO", 1],
    ["D962", "UD", "GEMONA", 0],
    ["D962", "UD", "GEMONA DEL FRIULI", 1],
    ["D963", "VA", "GEMONIO", 1],
    ["D964", "RM", "GENAZZANO", 1],
    ["D965", "AN", "GENGA", 1],
    ["D966", "CR", "GENIVOLTA", 1],
    ["D967", "CN", "GENOLA", 1],
    ["D968", "NU", "GENONI", 0],
    ["D968", "SU", "GENONI", 1],
    ["D969", "GE", "GENOVA", 1],
    ["D970", "CA", "GENURI", 0],
    ["D970", "SU", "GENURI", 1],
    ["D971", "MT", "GENZANO", 0],
    ["D972", "RM", "GENZANO", 0],
    ["D971", "PZ", "GENZANO DI LUCANIA", 1],
    ["D972", "RM", "GENZANO DI ROMA", 1],
    ["D973", "PV", "GENZONE", 0],
    ["Z136", "EE", "GEORGIA", 0],
    ["Z254", "EE", "GEORGIA", 1],
    ["D974", "CO", "GERA", 0],
    ["D974", "CO", "GERA LARIO", 1],
    ["D975", "RC", "GERACE", 1],
    ["D976", "RC", "GERACE MARINA", 0],
    ["D975", "RC", "GERACE SUPERIORE", 0],
    ["D977", "PA", "GERACI", 0],
    ["D977", "PA", "GERACI SICULO", 1],
    ["D978", "RM", "GERANO", 1],
    ["D979", "CR", "GERE DEL PESCE", 0],
    ["D980", "PV", "GERENZAGO", 1],
    ["D981", "VA", "GERENZANO", 1],
    ["D982", "NU", "GERGEI", 0],
    ["D982", "SU", "GERGEI", 1],
    ["D983", "TO", "GERMAGNANO", 1],
    ["D984", "VB", "GERMAGNO", 1],
    ["D985", "CO", "GERMANEDO", 0],
    ["Z112", "EE", "GERMANIA", 1],
    ["Z111", "EE", "GERMANIA REPUBBLICA DEMOCRATICA", 0],
    ["Z112", "EE", "GERMANIA REPUBBLICA FEDERALE", 0],
    ["D986", "CO", "GERMASINO", 0],
    ["D987", "VA", "GERMIGNAGA", 1],
    ["D988", "VV", "GEROCARNE", 1],
    ["D989", "BS", "GEROLA", 0],
    ["D990", "SO", "GEROLA", 0],
    ["D990", "SO", "GEROLA ALTA", 1],
    ["D989", "BS", "GEROLANUOVA", 0],
    ["D991", "BG", "GEROSA", 0],
    ["D992", "PV", "GERRE CHIOZZO", 0],
    ["D993", "CR", "GERRE DE' CAPRIOLI", 1],
    ["D994", "CA", "GESICO", 0],
    ["D994", "SU", "GESICO", 1],
    ["D995", "MI", "GESSATE", 1],
    ["D996", "CH", "GESSOPALENA", 1],
    ["D997", "CA", "GESTURI", 0],
    ["D997", "SU", "GESTURI", 1],
    ["D998", "AV", "GESUALDO", 1],
    ["Z318", "EE", "GHANA", 1],
    ["D999", "BS", "GHEDI", 1],
    ["E001", "NO", "GHEMME", 1],
    ["E002", "NO", "GHEVIO", 0],
    ["E003", "VB", "GHIFFA", 1],
    ["E004", "OR", "GHILARZA", 1],
    ["E005", "CA", "GHILARZA-ABBASANTA", 0],
    ["E006", "BG", "GHISALBA", 1],
    ["E007", "VC", "GHISLARENGO", 1],
    ["E008", "RO", "GIACCIANO CON BARUCHELLA", 1],
    ["E009", "TO", "GIAGLIONE", 1],
    ["Z507", "EE", "GIAMAICA", 1],
    ["E010", "BS", "GIANICO", 1],
    ["E011", "CE", "GIANO", 0],
    ["E012", "PG", "GIANO", 0],
    ["E012", "PG", "GIANO DELL'UMBRIA", 1],
    ["E011", "CE", "GIANO VETUSTO", 1],
    ["Z219", "EE", "GIAPPONE", 1],
    ["E013", "PA", "GIARDINELLO", 1],
    ["E014", "ME", "GIARDINI", 0],
    ["E014", "ME", "GIARDINI-NAXOS", 1],
    ["E015", "AL", "GIAROLE", 1],
    ["E016", "RG", "GIARRATANA", 1],
    ["E017", "CT", "GIARRE", 1],
    ["E018", "CT", "GIARRE-RIPOSTO", 0],
    ["E019", "SS", "GIAVE", 1],
    ["E020", "TO", "GIAVENO", 1],
    ["E021", "TV", "GIAVERA DEL MONTELLO", 1],
    ["E022", "CA", "GIBA", 0],
    ["E022", "SU", "GIBA", 1],
    ["E023", "TP", "GIBELLINA", 1],
    ["Z113", "EE", "GIBILTERRA", 1],
    ["Z361", "EE", "GIBUTI", 1],
    ["E024", "BI", "GIFFLENGA", 1],
    ["E025", "RC", "GIFFONE", 1],
    ["E026", "SA", "GIFFONI SEI CASALI", 1],
    ["E027", "SA", "GIFFONI VALLE PIANA", 1],
    ["E028", "VB", "GIGNESE", 1],
    ["E029", "AO", "GIGNOD", 1],
    ["Z705", "EE", "GILBERT E ELLICE (ISOLE)", 1],
    ["E030", "CB", "GILDONE", 1],
    ["E031", "CZ", "GIMIGLIANO", 1],
    ["E032", "PL", "GIMINO", 0],
    ["E033", "PZ", "GINESTRA", 1],
    ["E034", "BN", "GINESTRA DEGLI SCHIAVONI", 1],
    ["E035", "PU", "GINESTRETO", 0],
    ["E036", "TA", "GINOSA", 1],
    ["E037", "SA", "GIOI", 1],
    ["E038", "BA", "GIOIA", 0],
    ["E039", "CE", "GIOIA", 0],
    ["E040", "AQ", "GIOIA", 0],
    ["E041", "RC", "GIOIA", 0],
    ["E040", "AQ", "GIOIA DEI MARSI", 1],
    ["E038", "BA", "GIOIA DEL COLLE", 1],
    ["E039", "CE", "GIOIA SANNITICA", 1],
    ["E041", "RC", "GIOIA TAURO", 1],
    ["E042", "GO", "GIOIACI", 0],
    ["E043", "ME", "GIOIOSA", 0],
    ["E044", "RC", "GIOIOSA", 0],
    ["E044", "RC", "GIOIOSA IONICA", 1],
    ["E043", "ME", "GIOIOSA MAREA", 1],
    ["Z220", "EE", "GIORDANIA", 1],
    ["E045", "TR", "GIOVE", 1],
    ["E046", "PV", "GIOVENZANO", 0],
    ["E047", "BA", "GIOVINAZZO", 1],
    ["E048", "TN", "GIOVO", 1],
    ["E049", "NU", "GIRASOLE", 1],
    ["A089", "AG", "GIRGENTI", 0],
    ["E050", "CZ", "GIRIFALCO", 1],
    ["E051", "CO", "GIRONICO", 0],
    ["E052", "CH", "GISSI", 1],
    ["E053", "LE", "GIUGGIANELLO", 1],
    ["E054", "NA", "GIUGLIANO IN CAMPANIA", 1],
    ["E055", "PA", "GIULIANA", 1],
    ["E054", "NA", "GIULIANO", 0],
    ["E056", "CH", "GIULIANO", 0],
    ["E057", "RM", "GIULIANO", 0],
    ["E057", "FR", "GIULIANO DI ROMA", 1],
    ["E054", "NA", "GIULIANO IN CAMPANIA", 0],
    ["E056", "CH", "GIULIANO TEATINO", 1],
    ["E058", "TE", "GIULIANOVA", 1],
    ["E059", "LU", "GIUNCUGNANO", 0],
    ["E060", "SA", "GIUNGANO", 1],
    ["E061", "LE", "GIURDIGNANO", 1],
    ["E062", "PV", "GIUSSAGO", 1],
    ["E063", "MB", "GIUSSANO", 1],
    ["E063", "MI", "GIUSSANO", 0],
    ["E064", "SV", "GIUSTENICE", 1],
    ["E065", "TN", "GIUSTINO", 1],
    ["E066", "SV", "GIUSVALLA", 1],
    ["E067", "TO", "GIVOLETTO", 1],
    ["E068", "CZ", "GIZZERIA", 1],
    ["E069", "BZ", "GLORENZA", 1],
    ["E265", "PV", "GNIGNANO", 0],
    ["E070", "SP", "GODANO", 0],
    ["E071", "TV", "GODEGA", 0],
    ["E071", "TV", "GODEGA DI SANT'URBANO", 1],
    ["C190", "TV", "GODEGO", 0],
    ["M231", "TN", "GODENZO", 0],
    ["E072", "PV", "GODIASCO", 0],
    ["E072", "PV", "GODIASCO SALICE TERME", 1],
    ["E073", "GO", "GODOVICI", 0],
    ["E074", "PA", "GODRANO", 1],
    ["E075", "BS", "GOGLIONE SOPRA", 0],
    ["E076", "BS", "GOGLIONE SOTTO", 0],
    ["E077", "PV", "GOIDO", 0],
    ["E078", "MN", "GOITO", 1],
    ["E079", "VA", "GOLASECCA", 1],
    ["E080", "PR", "GOLESE", 0],
    ["E081", "PV", "GOLFERENZO", 1],
    ["M274", "SS", "GOLFO ARANCI", 1],
    ["E082", "CR", "GOMBITO", 1],
    ["E083", "UD", "GONARS", 1],
    ["E084", "CA", "GONI", 0],
    ["E084", "SU", "GONI", 1],
    ["E086", "CA", "GONNESA", 0],
    ["E086", "SU", "GONNESA", 1],
    ["E087", "OR", "GONNOSCODINA", 1],
    ["E085", "CA", "GONNOSFANADIGA", 0],
    ["E085", "SU", "GONNOSFANADIGA", 1],
    ["D585", "OR", "GONNOSNO'", 1],
    ["E088", "OR", "GONNOSTRAMATZA", 1],
    ["E089", "MN", "GONZAGA", 1],
    ["E090", "SO", "GORDONA", 1],
    ["E091", "RM", "GORGA", 1],
    ["E092", "TV", "GORGO", 0],
    ["E092", "TV", "GORGO AL MONTICANO", 1],
    ["E093", "MT", "GORGOGLIONE", 1],
    ["E094", "MI", "GORGONZOLA", 1],
    ["E095", "GO", "GORIANO", 0],
    ["E096", "AQ", "GORIANO SICOLI", 1],
    ["E097", "AQ", "GORIANO VALLI", 0],
    ["E098", "GO", "GORIZIA", 1],
    ["E099", "MI", "GORLA", 0],
    ["E101", "VA", "GORLA MAGGIORE", 1],
    ["E102", "VA", "GORLA MINORE", 1],
    ["E099", "MI", "GORLA PRECOTTO", 0],
    ["E099", "MI", "GORLA PRIMO", 0],
    ["E100", "BG", "GORLAGO", 1],
    ["E103", "BG", "GORLE", 1],
    ["E104", "VA", "GORNATE INFERIORE", 0],
    ["E104", "VA", "GORNATE OLONA", 1],
    ["E105", "VA", "GORNATE SUPERIORE", 0],
    ["E104", "VA", "GORNATE-OLONA", 0],
    ["E106", "BG", "GORNO", 1],
    ["E107", "FE", "GORO", 1],
    ["E108", "GE", "GORRA", 0],
    ["E109", "GE", "GORRETO", 1],
    ["E110", "CN", "GORRINO", 0],
    ["E111", "CN", "GORZEGNO", 1],
    ["E112", "BS", "GORZONE", 0],
    ["E113", "BL", "GOSALDO", 1],
    ["E114", "PC", "GOSSOLENGO", 1],
    ["E115", "CN", "GOTTASECCA", 1],
    ["E116", "BS", "GOTTOLENGO", 1],
    ["E117", "CO", "GOTTRO", 0],
    ["E118", "CN", "GOVONE", 1],
    ["E119", "GO", "GOZZA", 0],
    ["E120", "NO", "GOZZANO", 1],
    ["E121", "GO", "GRACOVA SERRAVALLE", 0],
    ["E122", "PU", "GRADARA", 1],
    ["E123", "CR", "GRADELLA", 0],
    ["E124", "GO", "GRADISCA", 0],
    ["E124", "GO", "GRADISCA D'ISONZO", 1],
    ["E125", "GO", "GRADO", 1],
    ["E126", "VT", "GRADOLI", 1],
    ["E127", "LO", "GRAFFIGNANA", 1],
    ["E128", "VT", "GRAFFIGNANO", 1],
    ["E129", "NO", "GRAGLIA", 0],
    ["E130", "BI", "GRAGLIA", 1],
    ["E129", "NO", "GRAGLIA PIANA", 0],
    ["B334", "CO", "GRAGLIO CON CADERO", 0],
    ["E131", "NA", "GRAGNANO", 1],
    ["E132", "PC", "GRAGNANO", 0],
    ["E132", "PC", "GRAGNANO TREBBIENSE", 1],
    ["E133", "CT", "GRAMMICHELE", 1],
    ["Z114", "EE", "GRAN BRETAGNA E IRLANDA DEL NORD", 1],
    ["E134", "AT", "GRANA", 1],
    ["E135", "BO", "GRANAGLIONE", 0],
    ["E136", "BO", "GRANAROLO DELL'EMILIA", 1],
    ["E137", "MI", "GRANCINO", 0],
    ["E138", "VI", "GRANCONA", 0],
    ["E139", "CO", "GRANDATE", 1],
    ["E140", "CO", "GRANDOLA", 0],
    ["E141", "CO", "GRANDOLA ED UNITI", 1],
    ["E142", "ME", "GRANITI", 1],
    ["E143", "NO", "GRANOZZO CON MONTICELLO", 1],
    ["E144", "VA", "GRANTOLA", 1],
    ["E145", "PD", "GRANTORTO", 1],
    ["E146", "PD", "GRANZE", 1],
    ["E147", "MT", "GRASSANO", 1],
    ["E148", "BG", "GRASSOBBIO", 1],
    ["E149", "PA", "GRATTERI", 1],
    ["E150", "TN", "GRAUNO", 0],
    ["E151", "CO", "GRAVEDONA", 0],
    ["M315", "CO", "GRAVEDONA ED UNITI", 1],
    ["E152", "PV", "GRAVELLONA LOMELLINA", 1],
    ["E153", "VB", "GRAVELLONA TOCE", 1],
    ["E154", "TO", "GRAVERE", 1],
    ["E155", "BA", "GRAVINA", 0],
    ["E156", "CT", "GRAVINA", 0],
    ["E156", "CT", "GRAVINA DI CATANIA", 1],
    ["E155", "BA", "GRAVINA IN PUGLIA", 1],
    ["E157", "MI", "GRAZZANELLO", 0],
    ["E158", "CE", "GRAZZANISE", 1],
    ["E159", "AL", "GRAZZANO", 0],
    ["E159", "AT", "GRAZZANO BADOGLIO", 1],
    ["E159", "AT", "GRAZZANO MONFERRATO", 0],
    ["E160", "RI", "GRECCIO", 1],
    ["E161", "AV", "GRECI", 1],
    ["Z115", "EE", "GRECIA", 1],
    ["E162", "MI", "GRECO MILANESE", 0],
    ["E163", "VC", "GREGGIO", 1],
    ["E164", "AL", "GREMIASCO", 1],
    ["Z524", "EE", "GRENADA", 1],
    ["E165", "AO", "GRESSAN", 1],
    ["E166", "AO", "GRESSONEI", 0],
    ["E166", "AO", "GRESSONEY", 0],
    ["E167", "AO", "GRESSONEY-LA-TRINITE'", 1],
    ["E168", "AO", "GRESSONEY-SAINT-JEAN", 1],
    ["E169", "FI", "GREVE", 0],
    ["E169", "FI", "GREVE IN CHIANTI", 1],
    ["C417", "BS", "GREVO", 0],
    ["E170", "MI", "GREZZAGO", 1],
    ["E171", "VR", "GREZZANA", 1],
    ["E172", "CO", "GRIANTE", 1],
    ["E173", "CE", "GRICIGNANO", 0],
    ["E173", "CE", "GRICIGNANO DI AVERSA", 1],
    ["E174", "BZ", "GRIES", 0],
    ["E174", "BZ", "GRIES DI BOLZANO", 0],
    ["E175", "BG", "GRIGNANO", 0],
    ["E176", "RO", "GRIGNANO", 0],
    ["E176", "RO", "GRIGNANO DI POLESINE", 0],
    ["E177", "NO", "GRIGNASCO", 1],
    ["E178", "TN", "GRIGNO", 1],
    ["E179", "UD", "GRIMACCO", 1],
    ["E180", "CS", "GRIMALDI", 1],
    ["E181", "BZ", "GRIMALDO", 0],
    ["E182", "CN", "GRINZANE", 0],
    ["E182", "CN", "GRINZANE CAVOUR", 1],
    ["E183", "PL", "GRISIGNANA", 0],
    ["E184", "VI", "GRISIGNANO", 0],
    ["E184", "VI", "GRISIGNANO DI ZOCCO", 1],
    ["D415", "VE", "GRISOLERA", 0],
    ["E185", "CS", "GRISOLIA", 1],
    ["E186", "CS", "GRISOLIA CIPOLLINA", 0],
    ["E187", "BO", "GRIZZANA", 0],
    ["E187", "BO", "GRIZZANA MORANDI", 1],
    ["Z402", "EE", "GROENLANDIA", 1],
    ["E188", "AL", "GROGNARDO", 1],
    ["E189", "BG", "GROMO", 1],
    ["E190", "CO", "GRONA", 0],
    ["E191", "AL", "GRONDONA", 1],
    ["E192", "BG", "GRONE", 1],
    ["E193", "CR", "GRONTARDO", 1],
    ["E194", "CR", "GRONTORTO", 0],
    ["E195", "PV", "GROPELLO CAIROLI", 1],
    ["E195", "PV", "GROPELLO LOMELLINO", 0],
    ["E196", "PC", "GROPPARELLO", 1],
    ["E195", "PV", "GROPPELLO", 0],
    ["E197", "MI", "GROPPELLO", 0],
    ["E197", "MI", "GROPPELLO D'ADDA", 0],
    ["E198", "AL", "GROPPO", 0],
    ["E199", "TO", "GROSCAVALLO", 1],
    ["E200", "SO", "GROSIO", 1],
    ["E201", "SO", "GROSOTTO", 1],
    ["E202", "GR", "GROSSETO", 1],
    ["E203", "TO", "GROSSO", 1],
    ["E204", "RM", "GROTTAFERRATA", 1],
    ["E205", "TA", "GROTTAGLIE", 1],
    ["E206", "AV", "GROTTAMINARDA", 1],
    ["E207", "AP", "GROTTAMMARE", 1],
    ["E208", "AP", "GROTTAZZOLINA", 0],
    ["E208", "FM", "GROTTAZZOLINA", 1],
    ["E209", "AG", "GROTTE", 1],
    ["E210", "VT", "GROTTE DI CASTRO", 1],
    ["E211", "VT", "GROTTE DI SANTO STEFANO", 0],
    ["E212", "RC", "GROTTERIA", 1],
    ["E213", "MT", "GROTTOLE", 1],
    ["E214", "AV", "GROTTOLELLA", 1],
    ["E215", "VE", "GRUARO", 1],
    ["E216", "TO", "GRUGLIASCO", 1],
    ["E217", "CR", "GRUMELLO", 0],
    ["E217", "CR", "GRUMELLO CON FARFENGO CREMONESE", 0],
    ["E217", "CR", "GRUMELLO CREMONESE ED UNITI", 1],
    ["E218", "BG", "GRUMELLO DEI ZANCHI", 0],
    ["E219", "BG", "GRUMELLO DEL MONTE", 1],
    ["E220", "BG", "GRUMELLO DEL PIANO", 0],
    ["E221", "PZ", "GRUMENTO NOVA", 1],
    ["E222", "TN", "GRUMES", 0],
    ["E223", "BA", "GRUMO", 0],
    ["E224", "NA", "GRUMO", 0],
    ["E225", "TN", "GRUMO", 0],
    ["E223", "BA", "GRUMO APPULA", 1],
    ["E224", "NA", "GRUMO NEVANO", 1],
    ["E226", "VI", "GRUMOLO DELLE ABBADESSE", 1],
    ["Z508", "EE", "GUADALUPA", 1],
    ["E227", "LE", "GUAGNANO", 1],
    ["E228", "MC", "GUALDO", 1],
    ["E229", "PG", "GUALDO CATTANEO", 1],
    ["E230", "PG", "GUALDO TADINO", 1],
    ["E231", "PV", "GUALDRASCO", 0],
    ["E232", "RE", "GUALTIERI", 1],
    ["E233", "ME", "GUALTIERI SICAMINO'", 1],
    ["Z706", "EE", "GUAM (ISOLA)", 1],
    ["E234", "CA", "GUAMAGGIORE", 0],
    ["E234", "SU", "GUAMAGGIORE", 1],
    ["E235", "CO", "GUANZATE", 1],
    ["E236", "FR", "GUARCINO", 1],
    ["E240", "RO", "GUARDA VENETA", 1],
    ["E237", "VC", "GUARDABOSONE", 1],
    ["E238", "LO", "GUARDAMIGLIO", 1],
    ["E239", "CZ", "GUARDAVALLE", 1],
    ["E241", "TR", "GUARDEA", 1],
    ["E242", "CS", "GUARDIA", 0],
    ["E245", "AV", "GUARDIA LOMBARDI", 1],
    ["E246", "PZ", "GUARDIA PERTICARA", 1],
    ["E242", "CS", "GUARDIA PIEMONTESE", 1],
    ["E247", "CS", "GUARDIA PIEMONTESE TERME", 0],
    ["E249", "BN", "GUARDIA SANFRAMONDI", 1],
    ["E243", "CH", "GUARDIAGRELE", 1],
    ["E244", "CB", "GUARDIALFIERA", 1],
    ["E248", "CB", "GUARDIAREGIA", 1],
    ["E250", "PI", "GUARDISTALLO", 1],
    ["E251", "CN", "GUARENE", 1],
    ["E252", "CA", "GUASILA", 0],
    ["E252", "SU", "GUASILA", 1],
    ["E253", "RE", "GUASTALLA", 1],
    ["Z509", "EE", "GUATEMALA", 1],
    ["Z608", "EE", "GUAYANA OLANDESE", 1],
    ["Z607", "EE", "GUAYANA=GUYANA FRANCESE", 1],
    ["E254", "MI", "GUAZZINA", 0],
    ["E255", "AL", "GUAZZORA", 1],
    ["E256", "PG", "GUBBIO", 1],
    ["E258", "MI", "GUDO VISCONTI", 1],
    ["E257", "BZ", "GUDON", 0],
    ["E259", "CB", "GUGLIONESI", 1],
    ["E260", "MI", "GUGNANO", 0],
    ["Z607", "EE", "GUIANA FRANCESE", 1],
    ["E261", "MN", "GUIDIZZOLO", 1],
    ["E262", "ME", "GUIDOMANDRI", 0],
    ["E263", "RM", "GUIDONIA MONTECELIO", 1],
    ["E264", "MO", "GUIGLIA", 1],
    ["E266", "CH", "GUILMI", 1],
    ["Z319", "EE", "GUINEA", 1],
    ["Z320", "EE", "GUINEA BISSAU", 1],
    ["Z321", "EE", "GUINEA EQUATORIALE", 1],
    ["Z320", "EE", "GUINEA PORTOGHESE", 1],
    ["Z321", "EE", "GUINEA SPAGNOLA", 1],
    ["E267", "PV", "GUINZANO", 0],
    ["E268", "VA", "GURONE", 0],
    ["E269", "VB", "GURRO", 1],
    ["E270", "CA", "GUSPINI", 0],
    ["E270", "SU", "GUSPINI", 1],
    ["E271", "BS", "GUSSAGO", 1],
    ["E272", "CR", "GUSSOLA", 1],
    ["Z606", "EE", "GUYANA", 1],
    ["Z606", "EE", "GUYANA BRITANNICA", 0],
    ["Z510", "EE", "HAITI", 0],
    ["B676", "BS", "HANO", 0],
    ["Z511", "EE", "HONDURAS", 1],
    ["Z512", "EE", "HONDURAS BRITANNICO", 0],
    ["E273", "AO", "HONE", 1],
    ["Z221", "EE", "HONG KONG", 1],
    ["E275", "RC", "IATRINOLI", 0],
    ["E277", "GO", "IDRESCA D'ISONZO", 0],
    ["E278", "GO", "IDRIA", 0],
    ["E279", "GO", "IDRIA DI SOTTO", 0],
    ["E280", "BS", "IDRO", 1],
    ["Z323", "EE", "IFNI", 0],
    ["E281", "CA", "IGLESIAS", 0],
    ["E281", "SU", "IGLESIAS", 1],
    ["E282", "CN", "IGLIANO", 1],
    ["E283", "NU", "ILBONO", 1],
    ["E284", "VR", "ILLASI", 1],
    ["E285", "SS", "ILLORAI", 1],
    ["E286", "CO", "IMBERIDO", 0],
    ["E287", "LC", "IMBERSAGO", 1],
    ["E288", "TN", "IMER", 1],
    ["E289", "BO", "IMOLA", 1],
    ["E290", "IM", "IMPERIA", 1],
    ["E291", "FI", "IMPRUNETA", 1],
    ["E292", "VA", "INARZO", 1],
    ["E293", "CO", "INCINO", 0],
    ["E294", "MI", "INCIRANO", 0],
    ["E295", "AL", "INCISA", 0],
    ["E296", "FI", "INCISA", 0],
    ["E295", "AL", "INCISA BELBO", 0],
    ["E296", "FI", "INCISA IN VAL D'ARNO", 0],
    ["E295", "AT", "INCISA SCAPACCINO", 1],
    ["E297", "BS", "INCUDINE", 1],
    ["Z222", "EE", "INDIA", 1],
    ["Z223", "EE", "INDONESIA", 1],
    ["E298", "CO", "INDOVERO", 0],
    ["E299", "CO", "INDUNO", 0],
    ["E300", "MI", "INDUNO", 0],
    ["E299", "VA", "INDUNO OLONA", 1],
    ["E300", "MI", "INDUNO TICINO", 0],
    ["E301", "TO", "INGRIA", 1],
    ["E302", "CO", "INTIMIANO", 0],
    ["E303", "NO", "INTRA", 0],
    ["E304", "VB", "INTRAGNA", 1],
    ["E305", "LC", "INTROBIO", 1],
    ["E306", "AO", "INTROD", 1],
    ["E307", "AQ", "INTRODACQUA", 1],
    ["E308", "LC", "INTROZZO", 0],
    ["E309", "CO", "INVERIGO", 1],
    ["E310", "PV", "INVERNO", 0],
    ["E310", "PV", "INVERNO E MONTELEONE", 1],
    ["E311", "TO", "INVERSO PINASCA", 1],
    ["E312", "TO", "INVERSO PORTE", 0],
    ["E313", "MI", "INVERUNO", 1],
    ["E314", "NO", "INVORIO", 1],
    ["E315", "NO", "INVORIO INFERIORE", 0],
    ["E316", "NO", "INVORIO SUPERIORE", 0],
    ["E317", "MI", "INZAGO", 1],
    ["E318", "BS", "INZINO", 0],
    ["E319", "UD", "IOANNIS", 0],
    ["E319", "UD", "IOANNIZ", 0],
    ["E321", "VV", "IONADI", 1],
    ["E018", "CT", "IONIA", 0],
    ["E322", "UD", "IPPLIS", 0],
    ["Z224", "EE", "IRAN", 1],
    ["Z225", "EE", "IRAQ", 1],
    ["E323", "NU", "IRGOLI", 1],
    ["E324", "NU", "IRGOLI DI GALTELLI", 0],
    ["Z707", "EE", "IRIAN OCCIDENTALE", 1],
    ["Z116", "EE", "IRLANDA", 1],
    ["E325", "BS", "IRMA", 1],
    ["E326", "MT", "IRSINA", 1],
    ["E327", "CN", "ISASCA", 1],
    ["E328", "CZ", "ISCA SULLO IONIO", 1],
    ["E329", "NA", "ISCHIA", 1],
    ["E330", "RM", "ISCHIA", 0],
    ["E331", "TN", "ISCHIA", 0],
    ["E330", "VT", "ISCHIA DI CASTRO", 1],
    ["E332", "FG", "ISCHITELLA", 1],
    ["E333", "BS", "ISEO", 1],
    ["E334", "TN", "ISERA", 1],
    ["E335", "IS", "ISERNIA", 1],
    ["E336", "NU", "ISILI", 0],
    ["E336", "SU", "ISILI", 1],
    ["Z117", "EE", "ISLANDA", 1],
    ["E337", "PA", "ISNELLO", 1],
    ["E338", "AL", "ISOLA", 0],
    ["E339", "CZ", "ISOLA", 0],
    ["E340", "FR", "ISOLA", 0],
    ["E341", "GE", "ISOLA", 0],
    ["E342", "SO", "ISOLA", 0],
    ["E343", "TE", "ISOLA", 0],
    ["E344", "MI", "ISOLA BALBA", 0],
    ["E347", "CO", "ISOLA COMACINA", 0],
    ["E338", "AT", "ISOLA D'ASTI", 1],
    ["E355", "PL", "ISOLA D'ISTRIA", 0],
    ["E341", "GE", "ISOLA DEL CANTONE", 1],
    ["E348", "GR", "ISOLA DEL GIGLIO", 1],
    ["E343", "TE", "ISOLA DEL GRAN SASSO D'ITALIA", 1],
    ["E340", "FR", "ISOLA DEL LIRI", 1],
    ["E351", "PU", "ISOLA DEL PIANO", 1],
    ["E349", "VR", "ISOLA DELLA SCALA", 1],
    ["E350", "PA", "ISOLA DELLE FEMMINE", 1],
    ["E339", "KR", "ISOLA DI CAPO RIZZUTO", 1],
    ["E352", "PU", "ISOLA DI FANO", 0],
    ["E353", "BG", "ISOLA DI FONDRA", 1],
    ["E354", "VI", "ISOLA DI MALO", 0],
    ["E356", "CR", "ISOLA DOVARESE", 1],
    ["E357", "CR", "ISOLA PESCAROLI", 0],
    ["E358", "VR", "ISOLA PORCARIZZA", 0],
    ["E340", "FR", "ISOLA PRESSO SORA", 0],
    ["E358", "VR", "ISOLA RIZZA", 1],
    ["E359", "NO", "ISOLA SAN GIULIO", 0],
    ["E360", "AL", "ISOLA SANT'ANTONIO", 1],
    ["E354", "VI", "ISOLA VICENTINA", 1],
    ["E345", "TO", "ISOLABELLA", 1],
    ["E346", "IM", "ISOLABONA", 1],
    ["E342", "SO", "ISOLATO", 0],
    ["Z721", "EE", "ISOLE CILENE (PASQUA E SALA Y GOMEZ)", 1],
    ["Z124", "EE", "ISOLE DEL CANALE", 1],
    ["Z727", "EE", "ISOLE DELL'UNIONE", 1],
    ["E363", "FG", "ISOLE TREMITI", 1],
    ["E361", "VC", "ISOLELLA", 0],
    ["E362", "CR", "ISOLELLO", 0],
    ["E364", "BS", "ISORELLA", 1],
    ["E365", "SA", "ISPANI", 1],
    ["E366", "RG", "ISPICA", 1],
    ["E367", "VA", "ISPRA", 1],
    ["Z226", "EE", "ISRAELE", 1],
    ["C737", "BZ", "ISSENGO", 0],
    ["E368", "TO", "ISSIGLIO", 1],
    ["E369", "AO", "ISSIME", 1],
    ["E370", "BG", "ISSO", 1],
    ["E371", "AO", "ISSOGNE", 1],
    ["E372", "CH", "ISTONIO", 0],
    ["E373", "TV", "ISTRANA", 1],
    ["E374", "ME", "ITALA", 1],
    ["E375", "LT", "ITRI", 1],
    ["E376", "SS", "ITTIREDDU", 1],
    ["E377", "SS", "ITTIRI", 1],
    ["Z118", "EE", "IUGOSLAVIA", 0],
    ["E378", "TN", "IVANO FRACENA", 0],
    ["E379", "TO", "IVREA", 1],
    ["E380", "CR", "IZANO", 1],
    ["E274", "CZ", "JACURSO", 1],
    ["E276", "TN", "JAVRE'", 0],
    ["E381", "CB", "JELSI", 1],
    ["E382", "RM", "JENNE", 1],
    ["E383", "MI", "JERAGO", 0],
    ["E384", "MI", "JERAGO CON BESNATE", 0],
    ["E385", "MI", "JERAGO CON BESNATE ED ORAGO", 0],
    ["E386", "VA", "JERAGO CON ORAGO", 1],
    ["E387", "NU", "JERZU", 1],
    ["E388", "AN", "JESI", 1],
    ["C388", "VE", "JESOLO", 1],
    ["E320", "FE", "JOLANDA DI SAVOIA", 1],
    ["E389", "VV", "JOPPOLO", 1],
    ["E390", "AG", "JOPPOLO GIANCAXIO", 1],
    ["E391", "AO", "JOVENCAN", 1],
    ["D844", "ME", "KAGGI", 0],
    ["Z255", "EE", "KAZAKHSTAN", 0],
    ["Z152", "EE", "KAZAKISTAN", 0],
    ["Z255", "EE", "KAZAKISTAN", 1],
    ["Z322", "EE", "KENYA", 1],
    ["Z142", "EE", "KIRGHIZISTAN", 0],
    ["Z256", "EE", "KIRGHIZISTAN", 1],
    ["Z731", "EE", "KIRIBATI", 1],
    ["Z160", "EE", "KOSOVO", 1],
    ["Z227", "EE", "KUWAIT", 1],
    ["A345", "AQ", "L'AQUILA", 1],
    ["E394", "TO", "LA CASSA", 1],
    ["E423", "TO", "LA LOGGIA", 1],
    ["E425", "SS", "LA MADDALENA", 1],
    ["A308", "AO", "LA MADDALENA D'AOSTA", 0],
    ["A308", "AO", "LA MAGDELEINE", 1],
    ["E430", "CN", "LA MORRA", 1],
    ["Z324", "EE", "LA REUNION (ISOLA)", 1],
    ["E458", "AO", "LA SALLE", 1],
    ["E463", "SP", "LA SPEZIA", 1],
    ["E470", "AO", "LA THUILE", 1],
    ["E490", "BL", "LA VALLE", 0],
    ["E491", "BZ", "LA VALLE", 1],
    ["E490", "BL", "LA VALLE AGORDINA", 1],
    ["M348", "LC", "LA VALLETTA BRIANZA", 1],
    ["E392", "RM", "LABICO", 1],
    ["E393", "RI", "LABRO", 1],
    ["E395", "MI", "LACCHIARELLA", 1],
    ["E396", "NA", "LACCO", 0],
    ["E396", "NA", "LACCO AMENO", 1],
    ["E397", "AV", "LACEDONIA", 1],
    ["E398", "BZ", "LACES", 1],
    ["E399", "BZ", "LACIGNO", 0],
    ["E399", "BZ", "LACINIGO", 0],
    ["E400", "NU", "LACONI", 0],
    ["E400", "OR", "LACONI", 1],
    ["D079", "BZ", "LADINIA", 0],
    ["M212", "RM", "LADISPOLI", 1],
    ["E401", "SS", "LAERRU", 1],
    ["E402", "RC", "LAGANADI", 1],
    ["E403", "VI", "LAGHI", 1],
    ["E404", "UD", "LAGLESIE SAN LEOPOLDO", 0],
    ["E405", "CO", "LAGLIO", 1],
    ["E405", "CO", "LAGLIO BRIENNO", 0],
    ["E406", "CN", "LAGNASCO", 1],
    ["E407", "CS", "LAGO", 1],
    ["E408", "TV", "LAGO", 0],
    ["E409", "PZ", "LAGONEGRO", 1],
    ["E410", "FE", "LAGOSANTO", 1],
    ["E411", "ZA", "LAGOSTA", 0],
    ["M232", "TN", "LAGUNA MUSTE'", 0],
    ["E412", "BZ", "LAGUNDO", 1],
    ["E414", "SV", "LAIGUEGLIA", 1],
    ["E415", "MI", "LAINATE", 1],
    ["E416", "CO", "LAINO", 1],
    ["E417", "CS", "LAINO BORGO", 1],
    ["E418", "CS", "LAINO BRUZIO", 0],
    ["E419", "CS", "LAINO CASTELLO", 1],
    ["E420", "BZ", "LAION", 1],
    ["E421", "BZ", "LAIVES", 1],
    ["E413", "PI", "LAJATICO", 1],
    ["E422", "BG", "LALLIO", 1],
    ["C093", "AP", "LAMA", 0],
    ["E424", "CH", "LAMA", 0],
    ["E424", "CH", "LAMA DEI PELIGNI", 1],
    ["E426", "MO", "LAMA MOCOGNO", 1],
    ["E427", "MI", "LAMBRATE", 0],
    ["E428", "CO", "LAMBRUGO", 1],
    ["M208", "CZ", "LAMEZIA TERME", 1],
    ["E429", "BL", "LAMON", 1],
    ["E431", "AG", "LAMPEDUSA", 0],
    ["E431", "AG", "LAMPEDUSA E LINOSA", 1],
    ["E432", "PT", "LAMPORECCHIO", 1],
    ["E433", "VC", "LAMPORO", 1],
    ["E434", "BZ", "LANA", 1],
    ["E435", "CH", "LANCIANO", 1],
    ["E436", "NO", "LANDIONA", 1],
    ["E437", "PV", "LANDRIANO", 1],
    ["E438", "PR", "LANGHIRANO", 1],
    ["E439", "PV", "LANGOSCO", 1],
    ["E440", "PL", "LANISCHIE MONT'AQUILA", 0],
    ["E441", "NU", "LANUSEI", 1],
    ["C767", "RM", "LANUVIO", 1],
    ["E442", "ME", "LANZA", 0],
    ["E443", "SO", "LANZADA", 1],
    ["E444", "CO", "LANZO", 0],
    ["E445", "TO", "LANZO", 0],
    ["E444", "CO", "LANZO D'INTELVI", 0],
    ["E445", "TO", "LANZO TORINESE", 1],
    ["E446", "CO", "LAORCA", 0],
    ["Z228", "EE", "LAOS", 1],
    ["E447", "AP", "LAPEDONA", 0],
    ["E447", "FM", "LAPEDONA", 1],
    ["E448", "AV", "LAPIO", 1],
    ["E449", "BZ", "LAPPAGO", 0],
    ["E450", "CS", "LAPPANO", 1],
    ["E451", "PT", "LARCIANO", 1],
    ["E452", "TN", "LARDARO", 0],
    ["E453", "MI", "LARDERA", 0],
    ["E454", "PV", "LARDIRAGO", 1],
    ["E455", "PI", "LARI", 0],
    ["M207", "RM", "LARIANO", 1],
    ["M233", "TN", "LARIDO", 0],
    ["E456", "CB", "LARINO", 1],
    ["B551", "GE", "LARVEGO", 0],
    ["E464", "CA", "LAS PLASSAS", 0],
    ["E464", "SU", "LAS PLASSAS", 1],
    ["E457", "BZ", "LASA", 1],
    ["E459", "PA", "LASCARI", 1],
    ["E460", "TS", "LASE", 0],
    ["M234", "TN", "LASES", 0],
    ["E461", "TN", "LASINO", 0],
    ["E462", "CO", "LASNIGO", 1],
    ["E465", "VI", "LASTEBASSE", 1],
    ["E466", "FI", "LASTRA A SIGNA", 1],
    ["E467", "VT", "LATERA", 1],
    ["E468", "AR", "LATERINA", 0],
    ["M392", "AR", "LATERINA PERGINE VALDARNO", 1],
    ["E469", "TA", "LATERZA", 1],
    ["E471", "BR", "LATIANO", 1],
    ["E472", "LT", "LATINA", 1],
    ["E473", "UD", "LATISANA", 1],
    ["E474", "PZ", "LATRONICO", 1],
    ["E475", "CS", "LATTARICO", 1],
    ["E476", "UD", "LAUCO", 1],
    ["E477", "BZ", "LAUDES", 0],
    ["E478", "FM", "LAURANA", 0],
    ["E479", "RC", "LAUREANA", 0],
    ["E480", "SA", "LAUREANA", 0],
    ["E480", "SA", "LAUREANA CILENTO", 1],
    ["E479", "RC", "LAUREANA DI BORELLO", 0],
    ["E479", "RC", "LAUREANA DI BORRELLO", 1],
    ["E481", "BZ", "LAUREGNO", 1],
    ["E482", "PZ", "LAURENZANA", 1],
    ["E483", "PZ", "LAURIA", 1],
    ["E484", "TO", "LAURIANO", 1],
    ["E485", "SA", "LAURINO", 1],
    ["E486", "SA", "LAURITO", 1],
    ["E487", "AV", "LAURO", 1],
    ["E488", "GE", "LAVAGNA", 1],
    ["E489", "VR", "LAVAGNO", 1],
    ["E492", "TN", "LAVARONE", 1],
    ["E493", "PZ", "LAVELLO", 1],
    ["E494", "VA", "LAVENA", 0],
    ["E494", "VA", "LAVENA PONTE TRESA", 1],
    ["E495", "VA", "LAVENO", 0],
    ["E496", "VA", "LAVENO-MOMBELLO", 1],
    ["E497", "BS", "LAVENONE", 1],
    ["E498", "SA", "LAVIANO", 1],
    ["E499", "IM", "LAVINA", 0],
    ["E500", "TN", "LAVIS", 1],
    ["E501", "BZ", "LAZFONS", 0],
    ["E502", "VR", "LAZISE", 1],
    ["E503", "AL", "LAZZARONE", 0],
    ["E504", "MB", "LAZZATE", 1],
    ["E504", "MI", "LAZZATE", 0],
    ["E320", "FE", "LE VENEZIE", 0],
    ["E505", "AQ", "LECCE", 0],
    ["E506", "LE", "LECCE", 1],
    ["E505", "AQ", "LECCE NEI MARSI", 1],
    ["E507", "LC", "LECCO", 1],
    ["E508", "GO", "LEDINE", 0],
    ["M313", "TN", "LEDRO", 1],
    ["E509", "BG", "LEFFE", 1],
    ["E510", "VA", "LEGGIUNO", 1],
    ["E511", "VA", "LEGGIUNO-SANGIANO", 0],
    ["E512", "VR", "LEGNAGO", 1],
    ["E513", "FI", "LEGNAIA", 0],
    ["E514", "MI", "LEGNANO", 1],
    ["E515", "PD", "LEGNARO", 1],
    ["E516", "TN", "LEGOS", 0],
    ["E517", "NU", "LEI", 1],
    ["E518", "TO", "LEINI", 1],
    ["E519", "GE", "LEIVI", 1],
    ["E520", "TO", "LEMIE", 1],
    ["E521", "CO", "LEMNA", 0],
    ["E522", "RO", "LENDINARA", 1],
    ["E523", "ME", "LENI", 1],
    ["E524", "BG", "LENNA", 1],
    ["E525", "CO", "LENNO", 0],
    ["E526", "BS", "LENO", 1],
    ["E527", "LT", "LENOLA", 1],
    ["E528", "VC", "LENTA", 1],
    ["E529", "CO", "LENTATE", 0],
    ["E530", "MI", "LENTATE", 0],
    ["E530", "MB", "LENTATE SUL SEVESO", 1],
    ["E530", "MI", "LENTATE SUL SEVESO", 0],
    ["E529", "CO", "LENTATE VERBANO", 0],
    ["E531", "CH", "LENTELLA", 1],
    ["C562", "BL", "LENTIAI", 0],
    ["E532", "SR", "LENTINI", 1],
    ["E533", "TN", "LENZIMA", 0],
    ["E534", "TN", "LENZUMO", 0],
    ["E535", "RI", "LEONESSA", 1],
    ["E536", "EN", "LEONFORTE", 1],
    ["E537", "TA", "LEPORANO", 1],
    ["B649", "RM", "LEPRIGNANO", 0],
    ["E538", "LE", "LEQUILE", 1],
    ["E539", "CN", "LEQUIO", 0],
    ["E540", "CN", "LEQUIO", 0],
    ["E540", "CN", "LEQUIO BERRIA", 1],
    ["E539", "CN", "LEQUIO TANARO", 1],
    ["E541", "PA", "LERCARA FRIDDI", 1],
    ["E542", "SP", "LERICI", 1],
    ["E543", "AL", "LERMA", 1],
    ["E544", "NO", "LESA", 1],
    ["E545", "NO", "LESA-BELGIRATE", 0],
    ["E546", "CN", "LESEGNO", 1],
    ["E547", "PR", "LESIGNANO DE' BAGNI", 1],
    ["E548", "PR", "LESIGNANO DI PALMIA", 0],
    ["E549", "FG", "LESINA", 1],
    ["E550", "MB", "LESMO", 1],
    ["E550", "MI", "LESMO", 0],
    ["Z359", "EE", "LESOTHO", 1],
    ["E551", "TO", "LESSOLO", 1],
    ["E552", "BI", "LESSONA", 0],
    ["M371", "BI", "LESSONA", 1],
    ["E553", "UD", "LESTIZZA", 1],
    ["E554", "CE", "LETINO", 1],
    ["E555", "ME", "LETOJANNI", 1],
    ["E556", "ME", "LETOJANNI GALLODORO", 0],
    ["E557", "NA", "LETTERE", 1],
    ["E558", "PE", "LETTOMANOPPELLO", 1],
    ["Z145", "EE", "LETTONIA", 1],
    ["E559", "CH", "LETTOPALENA", 1],
    ["E560", "SP", "LEVANTO", 1],
    ["E561", "CR", "LEVATA", 0],
    ["E562", "BG", "LEVATE", 1],
    ["E563", "LE", "LEVERANO", 1],
    ["E564", "CN", "LEVICE", 1],
    ["E565", "TN", "LEVICO", 0],
    ["E565", "TN", "LEVICO TERME", 1],
    ["E566", "TO", "LEVONE", 1],
    ["E567", "BS", "LEVRANGE", 0],
    ["E518", "TO", "LEYNI", 0],
    ["E568", "CO", "LEZZA", 0],
    ["E569", "CO", "LEZZENO", 1],
    ["Z229", "EE", "LIBANO", 1],
    ["E570", "CE", "LIBERI", 1],
    ["Z325", "EE", "LIBERIA", 1],
    ["Z326", "EE", "LIBIA", 1],
    ["E571", "ME", "LIBRIZZI", 1],
    ["E572", "GO", "LIBUSSINA", 0],
    ["E573", "AG", "LICATA", 1],
    ["E574", "MS", "LICCIANA", 0],
    ["E574", "MS", "LICCIANA NARDI", 1],
    ["E575", "CR", "LICENGO", 0],
    ["E576", "RM", "LICENZA", 1],
    ["E577", "NA", "LICIGNANO", 0],
    ["E577", "NA", "LICIGNANO DI NAPOLI", 0],
    ["E578", "CT", "LICODIA", 0],
    ["E578", "CT", "LICODIA EUBEA", 1],
    ["E579", "PV", "LICONASCO", 0],
    ["E580", "SA", "LICUSATI", 0],
    ["Z119", "EE", "LIECHTENSTEIN", 1],
    ["E581", "LC", "LIERNA", 1],
    ["E582", "CO", "LIETO COLLE", 0],
    ["E583", "VC", "LIGNANA", 1],
    ["E584", "UD", "LIGNANO SABBIADORO", 1],
    ["E585", "RE", "LIGONCHIO", 0],
    ["E586", "UD", "LIGOSULLO", 0],
    ["E587", "AO", "LILLIANA", 0],
    ["E587", "AO", "LILLIANES", 1],
    ["E588", "BL", "LIMANA", 1],
    ["E589", "BN", "LIMATOLA", 1],
    ["E590", "VV", "LIMBADI", 1],
    ["E591", "MB", "LIMBIATE", 1],
    ["E591", "MI", "LIMBIATE", 0],
    ["E592", "PD", "LIMENA", 1],
    ["E593", "CO", "LIMIDO", 0],
    ["E593", "CO", "LIMIDO COMASCO", 1],
    ["E594", "ME", "LIMINA", 1],
    ["E595", "MI", "LIMITO", 0],
    ["E596", "BS", "LIMONE", 0],
    ["E597", "CN", "LIMONE", 0],
    ["E596", "BS", "LIMONE DI SAN GIOVANNI", 0],
    ["E597", "CN", "LIMONE PIEMONTE", 1],
    ["E596", "BS", "LIMONE SUL GARDA", 1],
    ["E598", "CO", "LIMONTA", 0],
    ["E599", "CB", "LIMOSANO", 1],
    ["E600", "PV", "LINAROLO", 1],
    ["E601", "MI", "LINATE AL LAMBRO", 0],
    ["E602", "CT", "LINGUAGLOSSA", 1],
    ["E603", "IM", "LINGUEGLIETTA", 0],
    ["E604", "CO", "LINZANICO", 0],
    ["E605", "AV", "LIONI", 1],
    ["E606", "ME", "LIPARI", 1],
    ["E607", "CO", "LIPOMO", 1],
    ["E608", "PV", "LIRIO", 1],
    ["E609", "VA", "LISANZA", 0],
    ["E610", "MI", "LISCATE", 1],
    ["E611", "CH", "LISCIA", 1],
    ["E612", "AP", "LISCIANO", 0],
    ["E613", "PG", "LISCIANO", 0],
    ["E612", "AP", "LISCIANO DEL TRONTO", 0],
    ["E613", "PG", "LISCIANO NICCONE", 1],
    ["E614", "TN", "LISIGNAGO", 0],
    ["E615", "CN", "LISIO", 1],
    ["E616", "VA", "LISSAGO", 0],
    ["E617", "MB", "LISSONE", 1],
    ["E617", "MI", "LISSONE", 0],
    ["E472", "RM", "LITTORIA", 0],
    ["E618", "CL", "LITTORIA NISSENA", 0],
    ["Z146", "EE", "LITUANIA", 1],
    ["E619", "BS", "LIVEMMO", 0],
    ["E620", "NA", "LIVERI", 1],
    ["E621", "SO", "LIVIGNO", 1],
    ["E622", "BL", "LIVINALLONGO", 0],
    ["E622", "BL", "LIVINALLONGO DEL COL DI LANA", 1],
    ["E623", "CO", "LIVO", 1],
    ["E624", "TN", "LIVO", 1],
    ["E625", "LI", "LIVORNO", 1],
    ["E626", "VC", "LIVORNO FERRARIS", 1],
    ["E626", "NO", "LIVORNO PIEMONTE", 0],
    ["E626", "NO", "LIVORNO VERCELLESE", 0],
    ["E627", "LO", "LIVRAGA", 1],
    ["E628", "TN", "LIZZANA", 0],
    ["E629", "LE", "LIZZANELLO", 1],
    ["E630", "TA", "LIZZANO", 1],
    ["A771", "BO", "LIZZANO IN BELVEDERE", 1],
    ["E631", "BG", "LIZZOLA", 0],
    ["E632", "SV", "LOANO", 1],
    ["E633", "AT", "LOAZZOLO", 1],
    ["E634", "ME", "LOCADI", 0],
    ["E635", "TO", "LOCANA", 1],
    ["E636", "VC", "LOCARNO", 0],
    ["E637", "BG", "LOCATE", 0],
    ["E638", "CO", "LOCATE", 0],
    ["E639", "MI", "LOCATE", 0],
    ["E637", "BG", "LOCATE BERGAMASCO", 0],
    ["E639", "MI", "LOCATE DI TRIULZI", 1],
    ["E638", "CO", "LOCATE VARESINO", 1],
    ["E640", "BG", "LOCATELLO", 1],
    ["E641", "GO", "LOCAVIZZA DI AIDUSSINA", 0],
    ["E642", "GO", "LOCAVIZZA DI CANALE", 0],
    ["E643", "TN", "LOCCA", 0],
    ["E644", "NU", "LOCERI", 1],
    ["E645", "BA", "LOCOROTONDO", 1],
    ["D976", "RC", "LOCRI", 1],
    ["E646", "NU", "LOCULI", 1],
    ["E647", "NU", "LODE'", 1],
    ["E648", "LO", "LODI", 1],
    ["E651", "LO", "LODI VECCHIO", 1],
    ["E649", "NU", "LODINE", 1],
    ["E650", "GE", "LODISIO", 0],
    ["E652", "BS", "LODRINO", 1],
    ["E653", "TN", "LODRONE", 0],
    ["E654", "BS", "LOGRATO", 1],
    ["E655", "BO", "LOIANO", 1],
    ["M275", "SS", "LOIRI PORTO SAN PAOLO", 1],
    ["E656", "LC", "LOMAGNA", 1],
    ["E657", "CO", "LOMANIGA", 0],
    ["E658", "TN", "LOMASO", 0],
    ["E659", "CO", "LOMAZZO", 1],
    ["E660", "TO", "LOMBARDORE", 1],
    ["E661", "TO", "LOMBRIASCO", 1],
    ["E662", "PV", "LOMELLO", 1],
    ["A918", "VA", "LOMNAGO", 0],
    ["E663", "TN", "LON", 0],
    ["M235", "TN", "LONA", 0],
    ["E664", "TN", "LONA LASES", 0],
    ["E664", "TN", "LONA-LASES", 1],
    ["E665", "VA", "LONATE CEPPINO", 1],
    ["E666", "VA", "LONATE POZZOLO", 1],
    ["E667", "BS", "LONATO", 0],
    ["M312", "BS", "LONATO DEL GARDA", 1],
    ["E668", "FI", "LONDA", 1],
    ["E669", "IS", "LONGANO", 1],
    ["E670", "CR", "LONGARDORE", 0],
    ["E671", "VI", "LONGARE", 1],
    ["E672", "BL", "LONGARONE", 0],
    ["M342", "BL", "LONGARONE", 1],
    ["E673", "BS", "LONGHENA", 1],
    ["E674", "ME", "LONGI", 1],
    ["E675", "FC", "LONGIANO", 1],
    ["E676", "BZ", "LONGIARU'", 0],
    ["E677", "CS", "LONGOBARDI", 1],
    ["E678", "CS", "LONGOBUCCO", 1],
    ["E679", "CO", "LONGONE", 0],
    ["E680", "LI", "LONGONE", 0],
    ["E681", "RI", "LONGONE", 0],
    ["E679", "CO", "LONGONE AL SEGRINO", 1],
    ["E681", "RI", "LONGONE DI SAN SALVATORE MAGGIORE", 0],
    ["E681", "RI", "LONGONE SABINO", 1],
    ["E682", "VI", "LONIGO", 1],
    ["E683", "TO", "LORANZE'", 1],
    ["E684", "PD", "LOREGGIA", 1],
    ["E685", "VB", "LOREGLIA", 1],
    ["E686", "BG", "LORENTINO", 0],
    ["E687", "BL", "LORENZAGO", 0],
    ["E687", "BL", "LORENZAGO DI CADORE", 1],
    ["E688", "PI", "LORENZANA", 0],
    ["E689", "RO", "LOREO", 1],
    ["E690", "AN", "LORETO", 1],
    ["E691", "PE", "LORETO", 0],
    ["E691", "PE", "LORETO APRUTINO", 1],
    ["E692", "TV", "LORIA", 1],
    ["E693", "AR", "LORO", 0],
    ["E694", "MC", "LORO", 0],
    ["E693", "AR", "LORO CIUFFENNA", 1],
    ["E694", "MC", "LORO PICENO", 1],
    ["E695", "GE", "LORSICA", 1],
    ["E696", "GO", "LOSE", 0],
    ["E697", "BA", "LOSETO", 0],
    ["E698", "BS", "LOSINE", 1],
    ["E699", "CN", "LOTTULO", 0],
    ["E700", "NU", "LOTZORAI", 1],
    ["E701", "BS", "LOVENO", 0],
    ["E702", "CO", "LOVENO", 0],
    ["E701", "BS", "LOVENO GRUMELLO", 0],
    ["E702", "CO", "LOVENO SOPRA MENAGGIO", 0],
    ["E703", "TN", "LOVER", 0],
    ["E704", "BG", "LOVERE", 1],
    ["E705", "SO", "LOVERO", 1],
    ["E706", "BS", "LOZIO", 1],
    ["E707", "VA", "LOZZA", 1],
    ["E708", "BL", "LOZZO", 0],
    ["E709", "PD", "LOZZO", 0],
    ["E710", "VA", "LOZZO", 0],
    ["E709", "PD", "LOZZO ATESTINO", 1],
    ["E708", "BL", "LOZZO CADORE", 0],
    ["E708", "BL", "LOZZO DI CADORE", 1],
    ["E711", "VC", "LOZZOLO", 1],
    ["E712", "AL", "LU", 0],
    ["M420", "AL", "LU E CUCCARO MONFERRATO", 1],
    ["E713", "VT", "LUBRIANO", 1],
    ["E714", "AG", "LUCCA", 0],
    ["E715", "LU", "LUCCA", 1],
    ["E714", "AG", "LUCCA SICULA", 1],
    ["E716", "FG", "LUCERA", 1],
    ["E717", "MI", "LUCERNATE", 0],
    ["E718", "AR", "LUCIGNANO", 1],
    ["E719", "IM", "LUCINASCO", 1],
    ["E720", "GO", "LUCINICO", 0],
    ["E721", "CO", "LUCINO", 0],
    ["E722", "CB", "LUCITO", 1],
    ["E723", "AQ", "LUCO DEI MARSI", 1],
    ["E724", "AQ", "LUCOLI", 1],
    ["E725", "BS", "LUDRIANO", 0],
    ["C033", "MI", "LUGAGNANO", 0],
    ["E726", "PC", "LUGAGNANO", 0],
    ["E726", "PC", "LUGAGNANO VAL D'ARDA", 1],
    ["E727", "TO", "LUGNACCO", 0],
    ["E392", "RM", "LUGNANO", 0],
    ["E728", "RI", "LUGNANO", 0],
    ["E729", "TR", "LUGNANO", 0],
    ["E728", "RI", "LUGNANO DI VILLA TROIANA", 0],
    ["E729", "TR", "LUGNANO IN TEVERINA", 1],
    ["E392", "RM", "LUGNANO LABICANO", 0],
    ["E730", "RA", "LUGO", 1],
    ["E731", "VI", "LUGO", 0],
    ["E731", "VI", "LUGO DI VICENZA", 1],
    ["E732", "GO", "LUICO", 0],
    ["E733", "CR", "LUIGNANO", 0],
    ["E734", "VA", "LUINO", 1],
    ["E735", "CO", "LUISAGO", 1],
    ["E736", "NU", "LULA", 1],
    ["E737", "GE", "LUMARZO", 1],
    ["E738", "BS", "LUMEZZANE", 1],
    ["E739", "BS", "LUMEZZANE PIEVE", 0],
    ["E740", "BS", "LUMEZZANE SAN SEBASTIANO", 0],
    ["E741", "BS", "LUMEZZANE SANT'APOLLONIO", 0],
    ["E742", "CA", "LUNAMATRONA", 0],
    ["E742", "SU", "LUNAMATRONA", 1],
    ["E743", "PU", "LUNANO", 1],
    ["E744", "TN", "LUNDO", 0],
    ["B387", "PV", "LUNGAVILLA", 1],
    ["E745", "CS", "LUNGRO", 1],
    ["G143", "SP", "LUNI", 1],
    ["E746", "AV", "LUOGOSANO", 1],
    ["E747", "SS", "LUOGOSANTO", 1],
    ["E748", "CB", "LUPARA", 1],
    ["E749", "CO", "LURAGO", 0],
    ["E749", "CO", "LURAGO D'ERBA", 1],
    ["E750", "CO", "LURAGO MARINONE", 1],
    ["E751", "BG", "LURANO", 1],
    ["E752", "SS", "LURAS", 1],
    ["E753", "CO", "LURATE ABBATE", 0],
    ["E753", "CO", "LURATE CACCIVIO", 1],
    ["E754", "CE", "LUSCIANO", 1],
    ["E755", "CE", "LUSCIANO E DUCENTA", 0],
    ["E756", "TO", "LUSERNA", 0],
    ["E757", "TN", "LUSERNA", 1],
    ["E758", "TO", "LUSERNA SAN GIOVANNI", 1],
    ["E759", "TO", "LUSERNETTA", 1],
    ["E760", "UD", "LUSEVERA", 1],
    ["E761", "RO", "LUSIA", 1],
    ["E762", "VI", "LUSIANA", 0],
    ["M427", "VI", "LUSIANA CONCO", 1],
    ["E763", "TO", "LUSIGLIE'", 1],
    ["E764", "BZ", "LUSON", 1],
    ["Z120", "EE", "LUSSEMBURGO", 1],
    ["E765", "PL", "LUSSINGRANDE", 0],
    ["E766", "PL", "LUSSINPICCOLO", 0],
    ["E767", "SA", "LUSTRA", 1],
    ["E768", "BZ", "LUTAGO", 0],
    ["E769", "VA", "LUVINATE", 1],
    ["E734", "CO", "LUVINO", 0],
    ["E770", "BG", "LUZZANA", 1],
    ["E771", "BN", "LUZZANO", 0],
    ["E772", "RE", "LUZZARA", 1],
    ["E773", "CS", "LUZZI", 1],
    ["E774", "NO", "LUZZOGNO", 0],
    ["Z231", "EE", "MACAO", 1],
    ["E775", "VA", "MACCAGNO", 0],
    ["M339", "VA", "MACCAGNO CON PINO E VEDDASCA", 1],
    ["E776", "VA", "MACCAGNO INFERIORE", 0],
    ["E775", "VA", "MACCAGNO SUPERIORE", 0],
    ["E777", "LO", "MACCASTORNA", 1],
    ["E778", "IS", "MACCHIA D'ISERNIA", 1],
    ["E780", "CB", "MACCHIA VALFORTORE", 1],
    ["E779", "IS", "MACCHIAGODENA", 1],
    ["E781", "CO", "MACCIO", 0],
    ["Z148", "EE", "MACEDONIA", 0],
    ["Z148", "EE", "MACEDONIA DEL NORD", 1],
    ["E782", "TO", "MACELLO", 1],
    ["E783", "MC", "MACERATA", 1],
    ["E784", "NA", "MACERATA", 0],
    ["E784", "CE", "MACERATA CAMPANIA", 1],
    ["E785", "PU", "MACERATA FELTRIA", 1],
    ["E784", "CE", "MACERATA MARCIANISE", 0],
    ["E786", "MB", "MACHERIO", 1],
    ["E786", "MI", "MACHERIO", 0],
    ["E787", "BS", "MACLODIO", 1],
    ["E788", "NU", "MACOMER", 1],
    ["Z708", "EE", "MACQUARIE (ISOLE)", 1],
    ["E789", "CN", "MACRA", 1],
    ["E790", "VB", "MACUGNAGA", 1],
    ["Z327", "EE", "MADAGASCAR", 1],
    ["E791", "CE", "MADDALONI", 1],
    ["E792", "BS", "MADERNO", 0],
    ["E342", "SO", "MADESIMO", 1],
    ["M236", "TN", "MADICE", 0],
    ["E793", "CR", "MADIGNANO", 1],
    ["E794", "BG", "MADONE", 1],
    ["E795", "VB", "MADONNA DEL SASSO", 1],
    ["E796", "TN", "MADRANO", 0],
    ["E797", "TN", "MADRUZZO", 0],
    ["M357", "TN", "MADRUZZO", 1],
    ["E798", "LT", "MAENZA", 1],
    ["E799", "CB", "MAFALDA", 1],
    ["E800", "BS", "MAGASA", 1],
    ["E801", "MI", "MAGENTA", 1],
    ["E802", "CO", "MAGGIANICO", 0],
    ["E803", "NO", "MAGGIORA", 1],
    ["E804", "PV", "MAGHERNO", 1],
    ["E805", "PG", "MAGIONE", 1],
    ["E806", "CZ", "MAGISANO", 1],
    ["E807", "AP", "MAGLIANO", 0],
    ["E808", "CN", "MAGLIANO", 0],
    ["E809", "CN", "MAGLIANO", 0],
    ["E810", "GR", "MAGLIANO", 0],
    ["E811", "AQ", "MAGLIANO", 0],
    ["E812", "RI", "MAGLIANO", 0],
    ["E809", "CN", "MAGLIANO ALFIERI", 1],
    ["E808", "CN", "MAGLIANO ALPI", 1],
    ["E809", "CN", "MAGLIANO D'ALBA", 0],
    ["E811", "AQ", "MAGLIANO DE' MARSI", 1],
    ["E807", "AP", "MAGLIANO DI TENNA", 0],
    ["E807", "FM", "MAGLIANO DI TENNA", 1],
    ["E810", "GR", "MAGLIANO IN TOSCANA", 1],
    ["E813", "RM", "MAGLIANO ROMANO", 1],
    ["E812", "RI", "MAGLIANO SABINA", 1],
    ["E814", "SA", "MAGLIANO VETERE", 1],
    ["E815", "LE", "MAGLIE", 1],
    ["E816", "SV", "MAGLIOLO", 1],
    ["E817", "TO", "MAGLIONE", 1],
    ["E818", "MN", "MAGNACAVALLO", 1],
    ["E819", "MI", "MAGNAGO", 1],
    ["E820", "UD", "MAGNANO", 0],
    ["E821", "BI", "MAGNANO", 1],
    ["E820", "UD", "MAGNANO IN RIVIERA", 1],
    ["E823", "BS", "MAGNO SOPRA INZINO", 0],
    ["E822", "GE", "MAGNONE", 0],
    ["E824", "NO", "MAGOGNINO", 0],
    ["E825", "NU", "MAGOMADAS", 0],
    ["E825", "OR", "MAGOMADAS", 1],
    ["E826", "TN", "MAGRAS", 0],
    ["E828", "VI", "MAGRE", 0],
    ["E828", "VI", "MAGRE VICENTINO", 0],
    ["E827", "TN", "MAGRE'", 0],
    ["E829", "BZ", "MAGRE' ALL'ADIGE", 0],
    ["E829", "BZ", "MAGRE' SULLA STRADA DEL VINO", 1],
    ["E830", "CO", "MAGREGLIO", 1],
    ["E831", "BZ", "MAIA ALTA", 0],
    ["E832", "BZ", "MAIA BASSA", 0],
    ["E834", "CZ", "MAIDA", 1],
    ["E835", "CS", "MAIERA'", 1],
    ["E836", "VV", "MAIERATO", 1],
    ["E837", "AN", "MAIOLATI", 0],
    ["E837", "AN", "MAIOLATI SPONTINI", 1],
    ["E838", "PU", "MAIOLO", 0],
    ["E838", "RN", "MAIOLO", 1],
    ["E839", "SA", "MAIORI", 1],
    ["E840", "LO", "MAIRAGO", 1],
    ["E841", "BS", "MAIRANO", 1],
    ["E842", "SP", "MAISSANA", 1],
    ["E833", "UD", "MAJANO", 1],
    ["M237", "TN", "MALA", 0],
    ["E843", "CR", "MALAGNINO", 1],
    ["E844", "BO", "MALALBERGO", 1],
    ["E845", "VE", "MALAMOCCO", 0],
    ["Z328", "EE", "MALAWI", 1],
    ["Z230", "EE", "MALAYSIA", 0],
    ["Z247", "EE", "MALAYSIA", 1],
    ["E846", "UD", "MALBORGHETTO", 0],
    ["E847", "UD", "MALBORGHETTO VALBRUNA", 1],
    ["E847", "UD", "MALBORGHETTO-VALBRUNA", 0],
    ["E848", "VR", "MALCESINE", 1],
    ["E849", "TS", "MALCHINA", 0],
    ["Z232", "EE", "MALDIVE", 1],
    ["E850", "TN", "MALE'", 1],
    ["E851", "BS", "MALEGNO", 1],
    ["E852", "LO", "MALEO", 1],
    ["E853", "VB", "MALESCO", 1],
    ["E854", "CT", "MALETTO", 1],
    ["E855", "ME", "MALFA", 1],
    ["E856", "VA", "MALGESSO", 1],
    ["E857", "TN", "MALGOLO", 0],
    ["E858", "LC", "MALGRATE", 1],
    ["Z329", "EE", "MALI", 1],
    ["E859", "CS", "MALITO", 1],
    ["E860", "SV", "MALLARE", 1],
    ["E861", "BZ", "MALLES", 0],
    ["E862", "BZ", "MALLES VENOSTA", 1],
    ["E863", "VA", "MALNATE", 1],
    ["E864", "VI", "MALO", 1],
    ["E865", "BS", "MALONNO", 1],
    ["E866", "TN", "MALOSCO", 0],
    ["E867", "CN", "MALPOTREMO", 0],
    ["Z121", "EE", "MALTA", 1],
    ["E868", "AP", "MALTIGNANO", 1],
    ["E869", "ME", "MALVAGNA", 1],
    ["E870", "AL", "MALVICINO", 1],
    ["Z609", "EE", "MALVINE=FALKLAND (ISOLE)", 1],
    ["E871", "AL", "MALVINO", 0],
    ["E872", "CS", "MALVITO", 1],
    ["E873", "RC", "MAMMOLA", 1],
    ["E874", "NU", "MAMOIADA", 1],
    ["Z122", "EE", "MAN (ISOLA)", 1],
    ["E875", "GR", "MANCIANO", 1],
    ["E876", "ME", "MANDANICI", 1],
    ["E877", "CA", "MANDAS", 0],
    ["E877", "SU", "MANDAS", 1],
    ["E878", "CS", "MANDATORICCIO", 1],
    ["B632", "RM", "MANDELA", 1],
    ["E879", "CO", "MANDELLO", 0],
    ["E880", "NO", "MANDELLO", 0],
    ["E879", "LC", "MANDELLO DEL LARIO", 1],
    ["E880", "NO", "MANDELLO VITTA", 1],
    ["E881", "PV", "MANDRINO", 0],
    ["E882", "TA", "MANDURIA", 1],
    ["E883", "BS", "MANERBA", 0],
    ["E883", "BS", "MANERBA DEL GARDA", 1],
    ["E884", "BS", "MANERBIO", 1],
    ["E885", "FG", "MANFREDONIA", 1],
    ["E886", "PV", "MANGIALUPO", 0],
    ["E887", "CN", "MANGO", 1],
    ["E888", "CS", "MANGONE", 1],
    ["M283", "CT", "MANIACE", 1],
    ["E889", "PN", "MANIAGO", 1],
    ["E890", "TO", "MANIGLIA", 0],
    ["E891", "AV", "MANOCALZATI", 1],
    ["E892", "PE", "MANOPPELLO", 1],
    ["E893", "TV", "MANSUE'", 1],
    ["E894", "CN", "MANTA", 1],
    ["E895", "BZ", "MANTANA", 0],
    ["E896", "SO", "MANTELLO", 1],
    ["E897", "MN", "MANTOVA", 1],
    ["E898", "TN", "MANZANO", 0],
    ["E899", "UD", "MANZANO", 1],
    ["E900", "RM", "MANZIANA", 1],
    ["E901", "BG", "MAPELLO", 1],
    ["M316", "TO", "MAPPANO", 1],
    ["E902", "SS", "MARA", 1],
    ["E903", "CA", "MARACALAGONIS", 1],
    ["E904", "MO", "MARANELLO", 1],
    ["D210", "AP", "MARANO", 0],
    ["E905", "MO", "MARANO", 0],
    ["E906", "NA", "MARANO", 0],
    ["E907", "NO", "MARANO", 0],
    ["E908", "RM", "MARANO", 0],
    ["E909", "TN", "MARANO", 0],
    ["E910", "UD", "MARANO", 0],
    ["E911", "VR", "MARANO", 0],
    ["E912", "VI", "MARANO", 0],
    ["E906", "NA", "MARANO DI NAPOLI", 1],
    ["E911", "VR", "MARANO DI VALPOLICELLA", 1],
    ["E908", "RM", "MARANO EQUO", 1],
    ["E910", "UD", "MARANO LACUNARE", 0],
    ["E910", "UD", "MARANO LAGUNARE", 1],
    ["E914", "CS", "MARANO MARCHESATO", 1],
    ["E915", "CS", "MARANO PRINCIPATO", 1],
    ["E905", "MO", "MARANO SUL PANARO", 1],
    ["E907", "NO", "MARANO TICINO", 1],
    ["E912", "VI", "MARANO VICENTINO", 1],
    ["E913", "LT", "MARANOLA", 0],
    ["E916", "BZ", "MARANZA", 0],
    ["E916", "BZ", "MARANZA IN PUSTERIA", 0],
    ["E917", "AT", "MARANZANA", 1],
    ["E918", "GE", "MARASSI", 0],
    ["E919", "PZ", "MARATEA", 1],
    ["E920", "MI", "MARCALLO", 0],
    ["E921", "MI", "MARCALLO CON CASONE", 1],
    ["E922", "MN", "MARCARIA", 1],
    ["E923", "CZ", "MARCEDUSA", 1],
    ["E924", "RM", "MARCELLINA", 1],
    ["E925", "CZ", "MARCELLINARA", 1],
    ["E926", "VR", "MARCELLISE", 0],
    ["E927", "RI", "MARCETELLI", 1],
    ["E928", "BS", "MARCHENO", 1],
    ["E929", "VA", "MARCHIROLO", 1],
    ["E930", "LI", "MARCIANA", 1],
    ["E931", "LI", "MARCIANA MARINA", 1],
    ["E932", "CE", "MARCIANISE", 1],
    ["E933", "AR", "MARCIANO DELLA CHIANA", 1],
    ["E934", "PV", "MARCIGNAGO", 1],
    ["E935", "TN", "MARCO", 0],
    ["E936", "VE", "MARCON", 1],
    ["E937", "TO", "MARCORENGO", 0],
    ["Z709", "EE", "MARCUS (ISOLE)", 1],
    ["E938", "BZ", "MAREBBE", 1],
    ["E939", "CN", "MARENE", 1],
    ["E940", "TV", "MARENO", 0],
    ["E940", "TV", "MARENO DI PIAVE", 1],
    ["E941", "TO", "MARENTINO", 1],
    ["E942", "PL", "MARESEGO", 0],
    ["E943", "BZ", "MARETA", 0],
    ["E944", "AT", "MARETTO", 1],
    ["E945", "CN", "MARGARITA", 1],
    ["E946", "BT", "MARGHERITA DI SAVOIA", 1],
    ["E946", "FG", "MARGHERITA DI SAVOIA", 0],
    ["E947", "LC", "MARGNO", 1],
    ["E948", "TN", "MARGONE", 0],
    ["E949", "MN", "MARIANA", 0],
    ["E949", "MN", "MARIANA MANTOVANA", 1],
    ["Z710", "EE", "MARIANNE (ISOLE)", 1],
    ["E950", "BG", "MARIANO", 0],
    ["E951", "CO", "MARIANO", 0],
    ["E952", "GO", "MARIANO", 0],
    ["E950", "BG", "MARIANO AL BREMBO", 0],
    ["E951", "CO", "MARIANO COMENSE", 1],
    ["E952", "GO", "MARIANO DEL FRIULI", 1],
    ["E953", "CL", "MARIANOPOLI", 1],
    ["E954", "NA", "MARIGLIANELLA", 1],
    ["E955", "NA", "MARIGLIANO", 1],
    ["E956", "RC", "MARINA DI GIOIOSA IONICA", 1],
    ["E957", "PA", "MARINEO", 1],
    ["E958", "RM", "MARINO", 1],
    ["E959", "BZ", "MARLENGO", 1],
    ["E960", "PT", "MARLIANA", 1],
    ["E961", "BS", "MARMENTINO", 1],
    ["E962", "MN", "MARMIROLO", 1],
    ["E963", "CN", "MARMORA", 1],
    ["E964", "AT", "MARMORITO", 0],
    ["E965", "VA", "MARNATE", 1],
    ["D588", "BG", "MARNE", 0],
    ["E966", "IM", "MARO CASTELLO", 0],
    ["Z330", "EE", "MAROCCO", 1],
    ["E967", "BS", "MARONE", 1],
    ["E968", "RC", "MAROPATI", 1],
    ["E969", "PR", "MARORE", 0],
    ["E970", "VI", "MAROSTICA", 1],
    ["E971", "FI", "MARRADI", 1],
    ["E972", "OR", "MARRUBIU", 1],
    ["E973", "CN", "MARSAGLIA", 1],
    ["E974", "TP", "MARSALA", 1],
    ["E975", "PG", "MARSCIANO", 1],
    ["Z711", "EE", "MARSHALL (ISOLE)", 1],
    ["E976", "PZ", "MARSICO NUOVO", 1],
    ["E977", "PZ", "MARSICOVETERE", 1],
    ["E978", "VT", "MARTA", 1],
    ["E979", "LE", "MARTANO", 1],
    ["E980", "VE", "MARTELLAGO", 1],
    ["E981", "BZ", "MARTELLO", 1],
    ["E982", "UD", "MARTIGNACCO", 1],
    ["E983", "CR", "MARTIGNANA", 0],
    ["E983", "CR", "MARTIGNANA DI PO", 1],
    ["E984", "LE", "MARTIGNANO", 1],
    ["E985", "GE", "MARTINA", 0],
    ["E986", "TA", "MARTINA", 0],
    ["E986", "TA", "MARTINA FRANCA", 1],
    ["E985", "SV", "MARTINA OLBA", 0],
    ["E987", "BG", "MARTINENGO", 1],
    ["E988", "CN", "MARTINIANA", 0],
    ["E988", "CN", "MARTINIANA PO", 1],
    ["Z513", "EE", "MARTINICA", 1],
    ["E989", "TE", "MARTINSICURO", 1],
    ["E990", "CZ", "MARTIRANO", 1],
    ["E991", "CZ", "MARTIRANO LOMBARDO", 1],
    ["E992", "SS", "MARTIS", 1],
    ["E993", "RC", "MARTONE", 1],
    ["E994", "LO", "MARUDO", 1],
    ["E995", "TA", "MARUGGIO", 1],
    ["B689", "BO", "MARZABOTTO", 1],
    ["E996", "CR", "MARZALENGO", 0],
    ["E997", "AV", "MARZANO", 0],
    ["E998", "CE", "MARZANO", 0],
    ["E999", "PV", "MARZANO", 1],
    ["E998", "CE", "MARZANO APPIO", 1],
    ["E997", "AV", "MARZANO DI NOLA", 1],
    ["F001", "CS", "MARZI", 1],
    ["F002", "VA", "MARZIO", 1],
    ["M270", "CA", "MASAINAS", 0],
    ["M270", "SU", "MASAINAS", 1],
    ["F003", "MI", "MASATE", 1],
    ["F004", "CT", "MASCALI", 1],
    ["F005", "CT", "MASCALUCIA", 1],
    ["F006", "PZ", "MASCHITO", 1],
    ["F007", "CO", "MASCIAGO", 0],
    ["F008", "MI", "MASCIAGO", 0],
    ["F008", "MI", "MASCIAGO MILANESE", 0],
    ["F007", "VA", "MASCIAGO PRIMO", 1],
    ["F009", "TV", "MASER", 1],
    ["F010", "VB", "MASERA", 1],
    ["F011", "PD", "MASERA'", 0],
    ["F011", "PD", "MASERA' DI PADOVA", 1],
    ["F012", "TV", "MASERADA", 0],
    ["F012", "TV", "MASERADA SUL PIAVE", 1],
    ["F013", "PD", "MASI", 1],
    ["F014", "TN", "MASI DI VIGO", 0],
    ["F016", "FE", "MASI TORELLO", 1],
    ["B733", "TO", "MASINO", 0],
    ["F015", "AL", "MASIO", 1],
    ["F017", "CO", "MASLIANICO", 1],
    ["F018", "VA", "MASNAGO", 0],
    ["F019", "VI", "MASON", 0],
    ["F019", "VI", "MASON VICENTINO", 0],
    ["F020", "GE", "MASONE", 1],
    ["F021", "AP", "MASSA", 0],
    ["F022", "AQ", "MASSA", 0],
    ["F023", "MS", "MASSA", 1],
    ["F024", "PG", "MASSA", 0],
    ["F022", "AQ", "MASSA D'ALBE", 1],
    ["C495", "NA", "MASSA DI SOMMA", 0],
    ["M289", "NA", "MASSA DI SOMMA", 1],
    ["F025", "PT", "MASSA E COZZILE", 1],
    ["F021", "AP", "MASSA FERMANA", 0],
    ["F021", "FM", "MASSA FERMANA", 1],
    ["F026", "FE", "MASSA FISCAGLIA", 0],
    ["F029", "RA", "MASSA LOMBARDA", 1],
    ["F030", "NA", "MASSA LUBRENSE", 1],
    ["F032", "GR", "MASSA MARITTIMA", 1],
    ["F024", "PG", "MASSA MARTANA", 1],
    ["F036", "RO", "MASSA SUPERIORE", 0],
    ["F023", "MS", "MASSA-CARRARA", 0],
    ["D211", "AN", "MASSACCIO", 0],
    ["F027", "TA", "MASSAFRA", 1],
    ["F028", "LO", "MASSALENGO", 1],
    ["F031", "CA", "MASSAMA", 0],
    ["F033", "PD", "MASSANZAGO", 1],
    ["F034", "BG", "MASSARI-MELZI", 0],
    ["F035", "LU", "MASSAROSA", 1],
    ["F037", "BI", "MASSAZZA", 1],
    ["F038", "SI", "MASSE DEL TERZO DI CITTA'", 0],
    ["F038", "SI", "MASSE DI CITTA'", 0],
    ["F039", "SI", "MASSE DI SAN MARTINO", 0],
    ["F040", "SI", "MASSE DI SIENA", 0],
    ["F041", "TO", "MASSELLO", 1],
    ["F042", "BI", "MASSERANO", 1],
    ["F043", "NA", "MASSERIE", 0],
    ["F044", "AP", "MASSIGNANO", 1],
    ["F045", "TN", "MASSIMENO", 1],
    ["F046", "SV", "MASSIMINO", 1],
    ["F047", "NO", "MASSINO", 0],
    ["F047", "NO", "MASSINO VISCONTI", 1],
    ["F048", "VB", "MASSIOLA", 1],
    ["F049", "TN", "MASTELLINA", 0],
    ["F050", "OR", "MASULLAS", 1],
    ["F051", "MC", "MATELICA", 1],
    ["F052", "MT", "MATERA", 1],
    ["F053", "TO", "MATHI", 1],
    ["F053", "TO", "MATI", 0],
    ["F054", "LE", "MATINO", 1],
    ["F055", "CB", "MATRICE", 1],
    ["F056", "TN", "MATTARELLO", 0],
    ["F057", "FM", "MATTERIA", 0],
    ["F058", "TO", "MATTIE", 1],
    ["F059", "FG", "MATTINATA", 1],
    ["F060", "FM", "MATTUGLIE", 0],
    ["E849", "TS", "MAUCHINIE", 0],
    ["Z331", "EE", "MAURITANIA", 0],
    ["Z332", "EE", "MAURIZIO (ISOLE)", 1],
    ["Z360", "EE", "MAYOTTE (ISOLA)", 1],
    ["F061", "TP", "MAZARA", 0],
    ["F061", "TP", "MAZARA DEL VALLO", 1],
    ["F062", "BZ", "MAZIA", 0],
    ["F063", "BS", "MAZZANO", 1],
    ["F064", "RM", "MAZZANO", 0],
    ["F064", "RM", "MAZZANO ROMANO", 1],
    ["F066", "ME", "MAZZARA'", 0],
    ["F065", "CL", "MAZZARINO", 1],
    ["F066", "ME", "MAZZARRA' SANT'ANDREA", 1],
    ["M271", "CT", "MAZZARRONE", 1],
    ["F067", "TO", "MAZZE'", 1],
    ["F068", "TN", "MAZZIN", 1],
    ["F069", "MI", "MAZZO", 0],
    ["F070", "SO", "MAZZO", 0],
    ["F070", "SO", "MAZZO DI VALTELLINA", 1],
    ["F069", "MI", "MAZZO MILANESE", 0],
    ["F071", "BG", "MAZZOLENI E FALGHERA", 0],
    ["F072", "BS", "MAZZUNNO", 0],
    ["F073", "CA", "MEANA", 0],
    ["F074", "TO", "MEANA", 0],
    ["F074", "TO", "MEANA DI SUSA", 1],
    ["F073", "NU", "MEANA SARDO", 1],
    ["F075", "TO", "MEANO", 0],
    ["F076", "TN", "MEANO", 0],
    ["F077", "TN", "MECHEL", 0],
    ["F078", "MB", "MEDA", 1],
    ["F078", "MI", "MEDA", 0],
    ["F079", "GO", "MEDANA", 0],
    ["F080", "PV", "MEDE", 1],
    ["F081", "GO", "MEDEA", 1],
    ["F082", "PR", "MEDESANO", 1],
    ["F083", "BO", "MEDICINA", 1],
    ["F084", "MI", "MEDIGLIA", 1],
    ["F085", "BG", "MEDOLAGO", 1],
    ["F086", "MN", "MEDOLE", 1],
    ["F087", "MO", "MEDOLLA", 1],
    ["F088", "TV", "MEDUNA", 0],
    ["F088", "TV", "MEDUNA DI LIVENZA", 1],
    ["F089", "PN", "MEDUNO", 1],
    ["F090", "PG", "MEGGIANO", 0],
    ["F091", "PD", "MEGLIADINO SAN FIDENZIO", 0],
    ["F092", "PD", "MEGLIADINO SAN VITALE", 1],
    ["F093", "NO", "MEINA", 1],
    ["F094", "BL", "MEL", 0],
    ["F095", "RO", "MELARA", 1],
    ["F096", "AL", "MELAZZO", 1],
    ["F097", "FC", "MELDOLA", 1],
    ["F098", "GE", "MELE", 1],
    ["F099", "MI", "MELEGNANELLO", 0],
    ["F100", "MI", "MELEGNANO", 1],
    ["F101", "LE", "MELENDUGNO", 1],
    ["F102", "LO", "MELETI", 1],
    ["F103", "TO", "MELEZET", 0],
    ["F104", "PZ", "MELFI", 1],
    ["F105", "RC", "MELICUCCA'", 1],
    ["F106", "RC", "MELICUCCO", 1],
    ["F107", "SR", "MELILLI", 1],
    ["F108", "KR", "MELISSA", 1],
    ["F109", "LE", "MELISSANO", 1],
    ["F110", "AV", "MELITO", 0],
    ["F111", "NA", "MELITO", 0],
    ["F112", "RC", "MELITO", 0],
    ["F111", "NA", "MELITO DI NAPOLI", 1],
    ["F112", "RC", "MELITO DI PORTO SALVO", 1],
    ["F110", "AV", "MELITO IRPINO", 1],
    ["F110", "AV", "MELITO VALLE BONITO", 0],
    ["F113", "BN", "MELIZZANO", 1],
    ["F114", "CN", "MELLE", 1],
    ["F115", "SO", "MELLO", 1],
    ["F116", "TV", "MELMA", 0],
    ["F117", "LE", "MELPIGNANO", 1],
    ["F118", "BZ", "MELTINA", 1],
    ["F119", "MI", "MELZO", 1],
    ["F120", "CO", "MENAGGIO", 1],
    ["F121", "SO", "MENAROLA", 0],
    ["M238", "TN", "MENAS", 0],
    ["F122", "PV", "MENCONICO", 1],
    ["F123", "IM", "MENDATICA", 1],
    ["F124", "IM", "MENDATICA-MONTEGROSSO", 0],
    ["F125", "CS", "MENDICINO", 1],
    ["B986", "MI", "MENEDRAGO", 0],
    ["F126", "AG", "MENFI", 1],
    ["F127", "RM", "MENTANA", 1],
    ["F128", "TO", "MENTOULLES", 0],
    ["F129", "MI", "MENZAGO", 0],
    ["F130", "VE", "MEOLO", 1],
    ["F131", "AL", "MERANA", 1],
    ["F132", "BZ", "MERANO", 1],
    ["F133", "LC", "MERATE", 1],
    ["F134", "VA", "MERCALLO", 1],
    ["F135", "PU", "MERCATELLO", 0],
    ["F135", "PU", "MERCATELLO SUL METAURO", 1],
    ["F136", "PU", "MERCATINO CONCA", 1],
    ["F137", "PU", "MERCATINO MARECCHIA", 0],
    ["F138", "SA", "MERCATO", 0],
    ["F138", "SA", "MERCATO SAN SEVERINO", 1],
    ["F139", "FC", "MERCATO SARACENO", 1],
    ["F140", "TO", "MERCENASCO", 1],
    ["F141", "AV", "MERCOGLIANO", 1],
    ["F142", "MI", "MERCUGNANO", 0],
    ["F143", "NO", "MERCURAGO", 0],
    ["F144", "UD", "MERETO DI TOMBA", 1],
    ["F144", "UD", "MERETTO DI TOMBA", 0],
    ["F145", "AN", "MERGO", 1],
    ["F146", "VB", "MERGOZZO", 1],
    ["F147", "ME", "MERI'", 1],
    ["F148", "PD", "MERLARA", 1],
    ["F149", "LO", "MERLINO", 1],
    ["F150", "GO", "MERNA", 0],
    ["F151", "CO", "MERONE", 1],
    ["F152", "BR", "MESAGNE", 1],
    ["F153", "SO", "MESE", 1],
    ["F154", "VA", "MESENZANA", 1],
    ["F155", "MI", "MESERO", 1],
    ["F156", "FE", "MESOLA", 1],
    ["F157", "KR", "MESORACA", 1],
    ["Z514", "EE", "MESSICO", 1],
    ["F158", "ME", "MESSINA", 1],
    ["F159", "VE", "MESTRE", 0],
    ["F160", "TN", "MESTRIAGO", 0],
    ["F161", "PD", "MESTRINO", 1],
    ["F162", "NA", "META", 1],
    ["A987", "PR", "METTI E POZZOLO", 0],
    ["F163", "MI", "METTONE", 0],
    ["F164", "TO", "MEUGLIANO", 0],
    ["F165", "MB", "MEZZAGO", 1],
    ["F165", "MI", "MEZZAGO", 0],
    ["F166", "MI", "MEZZANA", 0],
    ["F167", "NO", "MEZZANA", 0],
    ["F168", "TN", "MEZZANA", 1],
    ["F169", "VA", "MEZZANA", 0],
    ["F170", "PV", "MEZZANA BIGLI", 1],
    ["F166", "MI", "MEZZANA CASATI", 0],
    ["B089", "PV", "MEZZANA CORTI BOTTARONE", 0],
    ["F167", "BI", "MEZZANA MORTIGLIENGO", 1],
    ["F171", "PV", "MEZZANA RABATTONE", 1],
    ["F169", "VA", "MEZZANA SUPERIORE", 0],
    ["F172", "VR", "MEZZANE DI SOTTO", 1],
    ["F173", "GE", "MEZZANEGO", 1],
    ["F174", "PR", "MEZZANI", 0],
    ["F175", "PV", "MEZZANINO", 1],
    ["F176", "TN", "MEZZANO", 1],
    ["F180", "PV", "MEZZANO", 0],
    ["F177", "TN", "MEZZANO IMER", 0],
    ["F178", "PV", "MEZZANO PARPANESE", 0],
    ["F179", "MI", "MEZZANO PASSONE", 0],
    ["F180", "PV", "MEZZANO SICCOMARIO", 0],
    ["D731", "BZ", "MEZZASELVA", 0],
    ["D731", "BZ", "MEZZASELVA ALL'ISARCO", 0],
    ["E601", "MI", "MEZZATE", 0],
    ["F181", "CO", "MEZZEGRA", 0],
    ["F182", "TO", "MEZZENILE", 1],
    ["F183", "TN", "MEZZOCORONA", 1],
    ["F184", "PA", "MEZZOJUSO", 1],
    ["F185", "TN", "MEZZOLAGO", 0],
    ["F186", "BG", "MEZZOLDO", 1],
    ["F187", "TN", "MEZZOLOMBARDO", 1],
    ["F188", "NO", "MEZZOMERICO", 1],
    ["F189", "BI", "MIAGLIANO", 1],
    ["F190", "TV", "MIANE", 1],
    ["F191", "NO", "MIASINO", 1],
    ["F192", "VB", "MIAZZINA", 1],
    ["F193", "RI", "MICIGLIANO", 1],
    ["Z735", "EE", "MICRONESIA STATI FEDERATI", 1],
    ["Z712", "EE", "MIDWAY (ISOLE)", 1],
    ["F194", "LE", "MIGGIANO", 1],
    ["F195", "NO", "MIGIANDONE", 0],
    ["F196", "CH", "MIGLIANICO", 1],
    ["F197", "AV", "MIGLIANO", 0],
    ["F198", "FE", "MIGLIARINO", 0],
    ["F199", "FE", "MIGLIARO", 0],
    ["F200", "CZ", "MIGLIERINA", 1],
    ["F201", "MT", "MIGLIONICO", 1],
    ["F202", "GE", "MIGNANEGO", 1],
    ["F203", "CE", "MIGNANO", 0],
    ["F203", "CE", "MIGNANO MONTE LUNGO", 1],
    ["F204", "MI", "MIGNETE", 0],
    ["F204", "MI", "MIGNETTES", 0],
    ["F205", "MI", "MILANO", 1],
    ["F206", "ME", "MILAZZO", 1],
    ["E618", "CL", "MILENA", 1],
    ["F207", "VV", "MILETO", 1],
    ["F208", "OR", "MILIS", 1],
    ["F209", "CT", "MILITELLO", 0],
    ["F210", "ME", "MILITELLO", 0],
    ["F209", "CT", "MILITELLO IN VAL DI CATANIA", 1],
    ["F210", "ME", "MILITELLO ROSMARINO", 1],
    ["F211", "BZ", "MILLAN-SARNES", 0],
    ["F212", "TO", "MILLAURES", 0],
    ["F213", "SV", "MILLESIMO", 1],
    ["F214", "CT", "MILO", 1],
    ["E618", "CL", "MILOCCA", 0],
    ["F215", "BS", "MILZANELLO", 0],
    ["F216", "BS", "MILZANO", 1],
    ["F217", "CT", "MINEO", 1],
    ["F218", "VR", "MINERBE", 1],
    ["F219", "BO", "MINERBIO", 1],
    ["F220", "BA", "MINERVINO", 0],
    ["F221", "LE", "MINERVINO", 0],
    ["F221", "LE", "MINERVINO DI LECCE", 1],
    ["F220", "BA", "MINERVINO MURGE", 0],
    ["F220", "BT", "MINERVINO MURGE", 1],
    ["F222", "CO", "MINOPRIO", 0],
    ["F223", "SA", "MINORI", 1],
    ["F224", "LT", "MINTURNO", 1],
    ["F225", "LU", "MINUCCIANO", 1],
    ["F226", "SV", "MIOGLIA", 1],
    ["F227", "TN", "MIOLA", 0],
    ["F228", "UD", "MIONE", 0],
    ["F229", "VE", "MIRA", 1],
    ["F229", "VE", "MIRA ED ORIAGO", 0],
    ["F230", "AV", "MIRABELLA", 0],
    ["F231", "CT", "MIRABELLA", 0],
    ["F230", "AV", "MIRABELLA ECLANO", 1],
    ["F231", "CT", "MIRABELLA IMBACCARI", 1],
    ["F232", "AL", "MIRABELLO", 0],
    ["F233", "CB", "MIRABELLO", 0],
    ["F234", "CR", "MIRABELLO", 0],
    ["F235", "FE", "MIRABELLO", 0],
    ["F236", "MI", "MIRABELLO", 0],
    ["F237", "PV", "MIRABELLO", 0],
    ["F234", "CR", "MIRABELLO CIRIA", 0],
    ["D581", "CO", "MIRABELLO COMASCO", 0],
    ["F237", "PV", "MIRABELLO ED UNITI DI PAVIA", 0],
    ["F232", "AL", "MIRABELLO MONFERRATO", 1],
    ["F236", "MI", "MIRABELLO SAN BERNARDINO", 0],
    ["F233", "CB", "MIRABELLO SANNITICO", 1],
    ["F238", "PV", "MIRADOLO", 0],
    ["F238", "PV", "MIRADOLO TERME", 1],
    ["F239", "IS", "MIRANDA", 1],
    ["F240", "MO", "MIRANDOLA", 1],
    ["F241", "VE", "MIRANO", 1],
    ["F242", "ME", "MIRTO", 1],
    ["F243", "BG", "MISANO", 0],
    ["F244", "FC", "MISANO", 0],
    ["F245", "PV", "MISANO", 0],
    ["F244", "RN", "MISANO ADRIATICO", 1],
    ["F243", "BG", "MISANO DI GERA D'ADDA", 1],
    ["F244", "FC", "MISANO IN VILLA VITTORIA", 0],
    ["F245", "PV", "MISANO OLONA", 0],
    ["F246", "PA", "MISILMERI", 1],
    ["F247", "MB", "MISINTO", 1],
    ["F247", "MI", "MISINTO", 0],
    ["F248", "LC", "MISSAGLIA", 1],
    ["F249", "PZ", "MISSANELLO", 1],
    ["F250", "CT", "MISTERBIANCO", 1],
    ["F251", "ME", "MISTRETTA", 1],
    ["F252", "VR", "MIZZOLE", 0],
    ["F253", "IM", "MOANO", 0],
    ["F254", "AT", "MOASCA", 1],
    ["F255", "TO", "MOCCHIE", 0],
    ["F256", "GE", "MOCONESI", 1],
    ["F257", "MO", "MODENA", 1],
    ["F258", "RG", "MODICA", 1],
    ["F259", "FC", "MODIGLIANA", 1],
    ["F260", "MI", "MODIGNANO", 0],
    ["F261", "NU", "MODOLO", 0],
    ["F261", "OR", "MODOLO", 1],
    ["F262", "BA", "MODUGNO", 1],
    ["F263", "TN", "MOENA", 1],
    ["F264", "TN", "MOERNA", 0],
    ["F265", "LC", "MOGGIO", 1],
    ["F266", "UD", "MOGGIO UDINESE", 1],
    ["F267", "MN", "MOGLIA", 1],
    ["F268", "MC", "MOGLIANO", 1],
    ["F269", "TV", "MOGLIANO", 0],
    ["F269", "TV", "MOGLIANO VENETO", 1],
    ["F270", "OR", "MOGORELLA", 1],
    ["F271", "CA", "MOGORELLA RUINAS", 0],
    ["F272", "OR", "MOGORO", 1],
    ["F273", "CO", "MOIANA", 0],
    ["F274", "BN", "MOIANO", 1],
    ["F275", "UD", "MOIMACCO", 1],
    ["F276", "BG", "MOIO", 0],
    ["F277", "ME", "MOIO", 0],
    ["F278", "SA", "MOIO", 0],
    ["F277", "ME", "MOIO ALCANTARA", 1],
    ["F276", "BG", "MOIO DE' CALVI", 1],
    ["F278", "SA", "MOIO DELLA CIVITELLA", 1],
    ["F279", "CN", "MOIOLA", 1],
    ["C210", "ME", "MOLA", 0],
    ["F280", "BA", "MOLA DI BARI", 1],
    ["D708", "LT", "MOLA E CASTELLONE", 0],
    ["F281", "AL", "MOLARE", 1],
    ["F282", "GE", "MOLASSANA", 0],
    ["F283", "LU", "MOLAZZANA", 1],
    ["Z140", "EE", "MOLDAVIA", 1],
    ["F284", "BA", "MOLFETTA", 1],
    ["F285", "CO", "MOLINA", 0],
    ["M255", "AQ", "MOLINA ATERNO", 1],
    ["F286", "TN", "MOLINA DI LEDRO", 0],
    ["F287", "BN", "MOLINARA", 1],
    ["F288", "BO", "MOLINELLA", 1],
    ["F289", "BG", "MOLINI DI COLOGNOLA", 0],
    ["F290", "IM", "MOLINI DI TRIORA", 1],
    ["F291", "BZ", "MOLINI DI TURES", 0],
    ["F292", "AL", "MOLINO ALZANO", 0],
    ["F293", "AL", "MOLINO DEI TORTI", 1],
    ["F294", "CB", "MOLISE", 1],
    ["F295", "PZ", "MOLITERNO", 1],
    ["F296", "TN", "MOLLARO", 0],
    ["F297", "VC", "MOLLIA", 1],
    ["F298", "TO", "MOLLIERES", 0],
    ["F299", "AG", "MOLO", 0],
    ["F300", "AL", "MOLO", 0],
    ["F300", "AL", "MOLO DI BORBERA", 0],
    ["F301", "RC", "MOLOCHIO", 1],
    ["B947", "BG", "MOLOGNO", 0],
    ["F302", "IM", "MOLTEDO INFERIORE", 0],
    ["F303", "IM", "MOLTEDO SUPERIORE", 0],
    ["F304", "LC", "MOLTENO", 1],
    ["F305", "CO", "MOLTRASIO", 1],
    ["F306", "VI", "MOLVENA", 0],
    ["F307", "TN", "MOLVENO", 1],
    ["F308", "AT", "MOMBALDONE", 1],
    ["F309", "CN", "MOMBARCARO", 1],
    ["F310", "PU", "MOMBAROCCIO", 1],
    ["F311", "AT", "MOMBARUZZO", 1],
    ["F312", "CN", "MOMBASIGLIO", 1],
    ["F313", "AL", "MOMBELLO", 0],
    ["F314", "CO", "MOMBELLO", 0],
    ["F315", "TO", "MOMBELLO", 0],
    ["F315", "TO", "MOMBELLO DI TORINO", 1],
    ["F314", "VA", "MOMBELLO LAGO MAGGIORE", 0],
    ["F313", "AL", "MOMBELLO MONFERRATO", 1],
    ["F316", "AT", "MOMBERCELLI", 1],
    ["F317", "NO", "MOMO", 1],
    ["F318", "TO", "MOMPANTERO", 1],
    ["F319", "RI", "MOMPEO", 1],
    ["F320", "AL", "MOMPERONE", 1],
    ["F321", "BS", "MOMPIANO", 0],
    ["F322", "CB", "MONACILIONI", 1],
    ["Z123", "EE", "MONACO", 1],
    ["F323", "AT", "MONALE", 1],
    ["F324", "RC", "MONASTERACE", 1],
    ["F325", "AL", "MONASTERO", 0],
    ["F326", "CN", "MONASTERO", 0],
    ["F327", "TO", "MONASTERO", 0],
    ["F325", "AT", "MONASTERO BORMIDA", 1],
    ["F327", "TO", "MONASTERO DI LANZO", 1],
    ["F326", "CN", "MONASTERO DI VASCO", 1],
    ["F328", "BG", "MONASTEROLO", 0],
    ["F329", "CN", "MONASTEROLO", 0],
    ["F330", "CN", "MONASTEROLO", 0],
    ["F331", "TO", "MONASTEROLO", 0],
    ["F329", "CN", "MONASTEROLO CASOTTO", 1],
    ["F329", "CN", "MONASTEROLO CASSOTO", 0],
    ["F328", "BG", "MONASTEROLO DEL CASTELLO", 1],
    ["F330", "CN", "MONASTEROLO DI SAVIGLIANO", 1],
    ["F331", "TO", "MONASTEROLO TORINESE", 0],
    ["F332", "TV", "MONASTIER", 0],
    ["F332", "TV", "MONASTIER DI TREVISO", 1],
    ["F333", "CA", "MONASTIR", 0],
    ["F333", "SU", "MONASTIR", 1],
    ["F334", "VA", "MONATE", 0],
    ["F335", "TO", "MONCALIERI", 1],
    ["F336", "AT", "MONCALVO", 1],
    ["D553", "TO", "MONCENISIO", 1],
    ["F337", "AL", "MONCESTINO", 1],
    ["F338", "CN", "MONCHIERO", 1],
    ["F339", "CN", "MONCHIERO NOVELLO", 0],
    ["F340", "PR", "MONCHIO", 0],
    ["F340", "PR", "MONCHIO DELLE CORTI", 1],
    ["F341", "TN", "MONCLASSICO", 0],
    ["F342", "VC", "MONCRIVELLO", 1],
    ["F343", "AL", "MONCUCCO", 0],
    ["F344", "MI", "MONCUCCO", 0],
    ["F345", "MI", "MONCUCCO", 0],
    ["F345", "MI", "MONCUCCO DI MONZA", 0],
    ["F343", "AT", "MONCUCCO TORINESE", 1],
    ["F344", "MI", "MONCUCCO VECCHIO", 0],
    ["F346", "RN", "MONDAINO", 1],
    ["F347", "PU", "MONDAVIO", 1],
    ["F348", "PU", "MONDOLFO", 1],
    ["F349", "CO", "MONDONICO", 0],
    ["F350", "AL", "MONDONIO", 0],
    ["F351", "CN", "MONDOVI'", 1],
    ["F352", "CE", "MONDRAGONE", 1],
    ["F353", "TO", "MONDRONE", 0],
    ["F354", "GE", "MONEGLIA", 1],
    ["F355", "CN", "MONESIGLIO", 1],
    ["F356", "GO", "MONFALCONE", 1],
    ["F357", "MO", "MONFESTINO IN SERRA MAZZONI", 0],
    ["F357", "MO", "MONFESTINO IN SERRAMAZZONI", 0],
    ["F358", "CN", "MONFORTE", 0],
    ["F359", "ME", "MONFORTE", 0],
    ["F358", "CN", "MONFORTE D'ALBA", 1],
    ["F359", "ME", "MONFORTE SAN GIORGIO", 1],
    ["A449", "RM", "MONFORTINO", 0],
    ["F360", "TV", "MONFUMO", 1],
    ["F361", "AT", "MONGARDINO", 1],
    ["F362", "BZ", "MONGHEZZO DI FUORI", 0],
    ["F362", "BZ", "MONGHEZZO ESTERNO", 0],
    ["F363", "BO", "MONGHIDORO", 1],
    ["F364", "VV", "MONGIANA", 1],
    ["F365", "AL", "MONGIARDINO", 0],
    ["F366", "MI", "MONGIARDINO", 0],
    ["F365", "AL", "MONGIARDINO LIGURE", 1],
    ["F366", "MI", "MONGIARDINO SILLARO", 0],
    ["F367", "AO", "MONGIOVE", 0],
    ["F368", "ME", "MONGIUFFI MELIA", 1],
    ["Z233", "EE", "MONGOLIA", 1],
    ["F369", "BI", "MONGRANDO", 1],
    ["F370", "CS", "MONGRASSANO", 1],
    ["F371", "BZ", "MONGUELFO", 0],
    ["F371", "BZ", "MONGUELFO-TESIDO", 1],
    ["F372", "CO", "MONGUZZO", 1],
    ["F373", "BS", "MONIGA", 0],
    ["F373", "BS", "MONIGA DEL GARDA", 1],
    ["F374", "AL", "MONLEALE", 1],
    ["F375", "BS", "MONNO", 1],
    ["F376", "BA", "MONOPOLI", 1],
    ["F377", "PA", "MONREALE", 1],
    ["F378", "TS", "MONRUPINO", 1],
    ["F379", "AP", "MONSAMPIETRO MORICO", 0],
    ["F379", "FM", "MONSAMPIETRO MORICO", 1],
    ["F380", "AP", "MONSAMPOLO", 0],
    ["F380", "AP", "MONSAMPOLO DEL TRONTO", 1],
    ["F381", "AN", "MONSANO", 1],
    ["F382", "PD", "MONSELICE", 1],
    ["F383", "CA", "MONSERRATO", 1],
    ["F384", "PT", "MONSUMMANO", 0],
    ["F384", "PT", "MONSUMMANO TERME", 1],
    ["F385", "CN", "MONTA'", 1],
    ["F386", "AT", "MONTABONE", 1],
    ["F387", "AL", "MONTACUTO", 1],
    ["F388", "AP", "MONTACUTO", 0],
    ["F389", "AP", "MONTADAMO", 0],
    ["F390", "AT", "MONTAFIA", 1],
    ["F391", "CB", "MONTAGANO", 1],
    ["F392", "BZ", "MONTAGNA", 1],
    ["F393", "SO", "MONTAGNA", 0],
    ["F393", "SO", "MONTAGNA IN VALTELLINA", 1],
    ["F394", "PD", "MONTAGNANA", 1],
    ["F395", "ME", "MONTAGNAREALE", 1],
    ["F396", "TN", "MONTAGNE", 0],
    ["F397", "AV", "MONTAGUTO", 1],
    ["F398", "FI", "MONTAIONE", 1],
    ["F399", "MT", "MONTALBANO", 0],
    ["F400", "ME", "MONTALBANO", 0],
    ["F400", "ME", "MONTALBANO DI ELICONA", 0],
    ["F400", "ME", "MONTALBANO ELICONA", 1],
    ["F399", "MT", "MONTALBANO JONICO", 1],
    ["F401", "AN", "MONTALBODDO", 0],
    ["F402", "SI", "MONTALCINO", 0],
    ["M378", "SI", "MONTALCINO", 1],
    ["F403", "AL", "MONTALDEO", 1],
    ["F404", "AL", "MONTALDO", 0],
    ["F405", "CN", "MONTALDO", 0],
    ["F406", "IM", "MONTALDO", 0],
    ["F407", "TO", "MONTALDO", 0],
    ["F404", "AL", "MONTALDO BORMIDA", 1],
    ["F405", "CN", "MONTALDO DI MONDOVI'", 1],
    ["F406", "IM", "MONTALDO LIGURE", 0],
    ["F408", "CN", "MONTALDO ROERO", 1],
    ["F409", "AT", "MONTALDO SCARAMPI", 1],
    ["F407", "TO", "MONTALDO TORINESE", 1],
    ["F410", "PT", "MONTALE", 1],
    ["E895", "BZ", "MONTALE IN PUSTERIA", 0],
    ["F411", "TO", "MONTALENGHE", 1],
    ["F412", "AL", "MONTALERO", 0],
    ["F413", "PU", "MONTALFOGLIO", 0],
    ["F414", "AG", "MONTALLEGRO", 1],
    ["F415", "AP", "MONTALTO", 0],
    ["F416", "CS", "MONTALTO", 0],
    ["F417", "PV", "MONTALTO", 0],
    ["F418", "PU", "MONTALTO", 0],
    ["F419", "RM", "MONTALTO", 0],
    ["F420", "TO", "MONTALTO", 0],
    ["F416", "CS", "MONTALTO AFFUGO", 0],
    ["M387", "IM", "MONTALTO CARPASIO", 1],
    ["F415", "AP", "MONTALTO DELLE MARCHE", 1],
    ["F419", "VT", "MONTALTO DI CASTRO", 1],
    ["F420", "TO", "MONTALTO DORA", 1],
    ["F406", "IM", "MONTALTO LIGURE", 0],
    ["F417", "PV", "MONTALTO PAVESE", 1],
    ["F418", "PU", "MONTALTO TARUGO", 0],
    ["F416", "CS", "MONTALTO UFFUGO", 1],
    ["F421", "CR", "MONTANARA", 0],
    ["F422", "TO", "MONTANARO", 1],
    ["F423", "MI", "MONTANASO", 0],
    ["F423", "LO", "MONTANASO LOMBARDO", 1],
    ["F424", "CN", "MONTANERA", 1],
    ["F425", "CO", "MONTANO", 0],
    ["F426", "SA", "MONTANO", 0],
    ["F426", "SA", "MONTANO ANTILIA", 1],
    ["F425", "CO", "MONTANO COMASCO", 0],
    ["F427", "CO", "MONTANO LUCINO", 1],
    ["F428", "AP", "MONTAPPONE", 0],
    ["F428", "FM", "MONTAPPONE", 1],
    ["F429", "IS", "MONTAQUILA", 1],
    ["F430", "RI", "MONTASOLA", 1],
    ["F431", "BZ", "MONTASSILO", 0],
    ["F431", "BZ", "MONTASSILONE", 0],
    ["F432", "CZ", "MONTAURO", 1],
    ["F433", "CH", "MONTAZZOLI", 1],
    ["F434", "CR", "MONTE", 0],
    ["F435", "MI", "MONTE", 0],
    ["F436", "BZ", "MONTE A MEZZODI'", 0],
    ["F438", "BZ", "MONTE A TRAMONTANA", 0],
    ["F437", "GR", "MONTE ARGENTARIO", 1],
    ["F444", "PV", "MONTE BOLOGNOLA", 0],
    ["F456", "PG", "MONTE CASTELLO", 0],
    ["F456", "PG", "MONTE CASTELLO DI VIBIO", 1],
    ["F460", "MC", "MONTE CAVALLO", 1],
    ["F467", "PU", "MONTE CERIGNONE", 1],
    ["F476", "RN", "MONTE COLOMBO", 0],
    ["F477", "RM", "MONTE COMPATRI", 1],
    ["F434", "CR", "MONTE CREMASCO", 1],
    ["F485", "PL", "MONTE DI CAPODISTRIA", 0],
    ["F486", "VI", "MONTE DI MALO", 1],
    ["F436", "BZ", "MONTE DI MEZZODI'", 0],
    ["F488", "NA", "MONTE DI PROCIDA", 1],
    ["F438", "BZ", "MONTE DI TRAMONTANA", 0],
    ["F517", "AP", "MONTE GIBERTO", 0],
    ["F517", "FM", "MONTE GIBERTO", 1],
    ["F524", "PU", "MONTE GRIMANO", 0],
    ["F524", "PU", "MONTE GRIMANO TERME", 1],
    ["F532", "BS", "MONTE ISOLA", 1],
    ["F561", "LC", "MONTE MARENZO", 1],
    ["F571", "PU", "MONTE MONTANARO", 0],
    ["F583", "CO", "MONTE OLIMPINO", 0],
    ["F584", "NO", "MONTE OSSOLANO", 0],
    ["F589", "PU", "MONTE PORZIO", 1],
    ["F590", "RM", "MONTE PORZIO", 0],
    ["F590", "RM", "MONTE PORZIO CATONE", 1],
    ["F599", "AP", "MONTE RINALDO", 0],
    ["F599", "FM", "MONTE RINALDO", 1],
    ["F600", "AN", "MONTE ROBERTO", 1],
    ["F603", "VT", "MONTE ROMANO", 1],
    ["F616", "LT", "MONTE SAN BIAGIO", 1],
    ["F617", "BZ", "MONTE SAN CANDIDO", 0],
    ["F618", "SA", "MONTE SAN GIACOMO", 1],
    ["F619", "PG", "MONTE SAN GIOVANNI", 0],
    ["F620", "RM", "MONTE SAN GIOVANNI", 0],
    ["F620", "FR", "MONTE SAN GIOVANNI CAMPANO", 1],
    ["F619", "RI", "MONTE SAN GIOVANNI IN SABINA", 1],
    ["D423", "TP", "MONTE SAN GIULIANO", 0],
    ["F621", "MC", "MONTE SAN GIUSTO", 1],
    ["F622", "MC", "MONTE SAN MARTINO", 1],
    ["F626", "AP", "MONTE SAN PIETRANGELI", 0],
    ["F626", "FM", "MONTE SAN PIETRANGELI", 1],
    ["F627", "BO", "MONTE SAN PIETRO", 1],
    ["F460", "MC", "MONTE SAN POLO", 0],
    ["F628", "AR", "MONTE SAN SAVINO", 1],
    ["F616", "RM", "MONTE SAN VITO", 0],
    ["F634", "AN", "MONTE SAN VITO", 1],
    ["F635", "GO", "MONTE SAN VITO", 0],
    ["F631", "FG", "MONTE SANT'ANGELO", 1],
    ["F629", "AR", "MONTE SANTA MARIA", 0],
    ["F630", "PG", "MONTE SANTA MARIA", 0],
    ["F630", "PG", "MONTE SANTA MARIA IN SABINA", 0],
    ["F629", "PG", "MONTE SANTA MARIA TIBERINA", 1],
    ["F652", "GO", "MONTE URABICE", 0],
    ["F653", "AP", "MONTE URANO", 0],
    ["F653", "FM", "MONTE URANO", 1],
    ["F664", "AP", "MONTE VIDON COMBATTE", 0],
    ["F664", "FM", "MONTE VIDON COMBATTE", 1],
    ["F665", "AP", "MONTE VIDON CORRADO", 0],
    ["F665", "FM", "MONTE VIDON CORRADO", 1],
    ["D746", "RC", "MONTEBELLO", 0],
    ["F439", "PU", "MONTEBELLO", 0],
    ["F440", "PV", "MONTEBELLO", 0],
    ["F441", "PE", "MONTEBELLO", 0],
    ["F442", "VI", "MONTEBELLO", 0],
    ["F440", "PV", "MONTEBELLO DELLA BATTAGLIA", 1],
    ["F441", "PE", "MONTEBELLO DI BERTONA", 1],
    ["D746", "RC", "MONTEBELLO IONICO", 0],
    ["D746", "RC", "MONTEBELLO JONICO", 1],
    ["F439", "PU", "MONTEBELLO METAURENSE", 0],
    ["B268", "CH", "MONTEBELLO SUL SANGRO", 1],
    ["F442", "VI", "MONTEBELLO VICENTINO", 1],
    ["F443", "TV", "MONTEBELLUNA", 1],
    ["F445", "GE", "MONTEBRUNO", 1],
    ["B254", "NO", "MONTEBUGLIO", 0],
    ["F446", "RI", "MONTEBUONO", 1],
    ["F447", "AP", "MONTECALVO", 0],
    ["F448", "AV", "MONTECALVO", 0],
    ["F449", "PV", "MONTECALVO", 0],
    ["F450", "PU", "MONTECALVO", 0],
    ["F447", "AP", "MONTECALVO DEL CASTELLANO", 0],
    ["F450", "PU", "MONTECALVO IN FOGLIA", 1],
    ["F448", "AV", "MONTECALVO IRPINO", 1],
    ["F449", "PV", "MONTECALVO VERSIGGIA", 1],
    ["F451", "FI", "MONTECALVOLI", 0],
    ["F452", "LU", "MONTECARLO", 1],
    ["F453", "AN", "MONTECAROTTO", 1],
    ["F454", "MC", "MONTECASSIANO", 1],
    ["F455", "AL", "MONTECASTELLO", 1],
    ["F457", "TR", "MONTECASTRILLI", 1],
    ["A561", "PT", "MONTECATINI TERME", 0],
    ["F458", "PI", "MONTECATINI VAL DI CECINA", 1],
    ["F459", "PT", "MONTECATINI VAL DI NIEVOLE", 0],
    ["A561", "PT", "MONTECATINI-TERME", 1],
    ["F461", "VR", "MONTECCHIA DI CROSARA", 1],
    ["F462", "TR", "MONTECCHIO", 1],
    ["F463", "RE", "MONTECCHIO EMILIA", 1],
    ["F464", "VI", "MONTECCHIO MAGGIORE", 1],
    ["F465", "VI", "MONTECCHIO PRECALCINO", 1],
    ["F466", "RM", "MONTECELIO", 0],
    ["F468", "AL", "MONTECHIARO", 0],
    ["F469", "AL", "MONTECHIARO", 0],
    ["F470", "BZ", "MONTECHIARO", 0],
    ["F471", "BS", "MONTECHIARO", 0],
    ["F469", "AL", "MONTECHIARO D'ACQUI", 1],
    ["F468", "AT", "MONTECHIARO D'ASTI", 1],
    ["F472", "AL", "MONTECHIARO DENICE", 0],
    ["F471", "BS", "MONTECHIARO SUL CHIESE", 0],
    ["F473", "PR", "MONTECHIARUGOLO", 1],
    ["F474", "PU", "MONTECICCARDO", 1],
    ["F475", "CB", "MONTECILFONE", 1],
    ["F477", "RM", "MONTECOMPATRI", 0],
    ["F478", "PU", "MONTECOPIOLO", 1],
    ["F479", "SA", "MONTECORICE", 1],
    ["F480", "SA", "MONTECORVINO PUGLIANO", 1],
    ["F481", "SA", "MONTECORVINO ROVELLA", 1],
    ["F482", "MC", "MONTECOSARO", 1],
    ["F483", "VB", "MONTECRESTESE", 1],
    ["F484", "MO", "MONTECRETO", 1],
    ["F487", "AP", "MONTEDINOVE", 1],
    ["F489", "CL", "MONTEDORO", 1],
    ["F490", "PU", "MONTEFABBRI", 0],
    ["F491", "AV", "MONTEFALCIONE", 1],
    ["F492", "PG", "MONTEFALCO", 1],
    ["F493", "AP", "MONTEFALCONE", 0],
    ["F494", "BN", "MONTEFALCONE", 0],
    ["F495", "CB", "MONTEFALCONE", 0],
    ["F493", "AP", "MONTEFALCONE APPENNINO", 0],
    ["F493", "FM", "MONTEFALCONE APPENNINO", 1],
    ["F494", "BN", "MONTEFALCONE DI VAL FORTORE", 1],
    ["F495", "CB", "MONTEFALCONE NEL SANNIO", 1],
    ["F496", "MC", "MONTEFANO", 1],
    ["F497", "PU", "MONTEFELCINO", 1],
    ["F498", "CH", "MONTEFERRANTE", 1],
    ["F499", "VT", "MONTEFIASCONE", 1],
    ["F500", "TE", "MONTEFINO", 1],
    ["F501", "AP", "MONTEFIORE", 0],
    ["F502", "FC", "MONTEFIORE", 0],
    ["F502", "RN", "MONTEFIORE CONCA", 1],
    ["F501", "AP", "MONTEFIORE DELL'ASO", 1],
    ["F503", "MO", "MONTEFIORINO", 1],
    ["F502", "FC", "MONTEFIORITO", 0],
    ["F504", "RM", "MONTEFLAVIO", 1],
    ["F505", "BZ", "MONTEFONTANA", 0],
    ["F506", "AV", "MONTEFORTE", 0],
    ["F507", "SA", "MONTEFORTE", 0],
    ["F508", "VR", "MONTEFORTE", 0],
    ["F507", "SA", "MONTEFORTE CILENTO", 1],
    ["F508", "VR", "MONTEFORTE D'ALPONE", 1],
    ["F506", "AV", "MONTEFORTE IRPINO", 1],
    ["A449", "RM", "MONTEFORTINO", 0],
    ["F509", "AP", "MONTEFORTINO", 0],
    ["F509", "FM", "MONTEFORTINO", 1],
    ["F510", "TR", "MONTEFRANCO", 1],
    ["F511", "AV", "MONTEFREDANE", 1],
    ["F512", "AV", "MONTEFUSCO", 1],
    ["F513", "TR", "MONTEGABBIONE", 1],
    ["F514", "VI", "MONTEGALDA", 1],
    ["F515", "VI", "MONTEGALDELLA", 1],
    ["F516", "AP", "MONTEGALLO", 1],
    ["F518", "AL", "MONTEGIOCO", 1],
    ["F519", "CS", "MONTEGIORDANO", 1],
    ["F520", "AP", "MONTEGIORGIO", 0],
    ["F520", "FM", "MONTEGIORGIO", 1],
    ["F521", "PG", "MONTEGIOVE", 0],
    ["F522", "AP", "MONTEGRANARO", 0],
    ["F522", "FM", "MONTEGRANARO", 1],
    ["F302", "IM", "MONTEGRAZIE", 0],
    ["F523", "RN", "MONTEGRIDOLFO", 1],
    ["F525", "VA", "MONTEGRINO", 0],
    ["F526", "VA", "MONTEGRINO VALTRAVAGLIA", 1],
    ["F527", "AL", "MONTEGROSSO", 0],
    ["F528", "IM", "MONTEGROSSO", 0],
    ["F527", "AT", "MONTEGROSSO D'ASTI", 1],
    ["F528", "IM", "MONTEGROSSO PIAN LATTE", 1],
    ["F529", "PD", "MONTEGROTTO TERME", 1],
    ["F530", "PU", "MONTEGUIDUCCIO", 0],
    ["F531", "TA", "MONTEIASI", 1],
    ["F533", "PU", "MONTELABBATE", 1],
    ["F534", "RM", "MONTELANICO", 1],
    ["F535", "CH", "MONTELAPIANO", 1],
    ["F536", "AP", "MONTELEONE", 0],
    ["F537", "CZ", "MONTELEONE", 0],
    ["F538", "FG", "MONTELEONE", 0],
    ["F539", "PV", "MONTELEONE", 0],
    ["F540", "PG", "MONTELEONE", 0],
    ["F541", "RI", "MONTELEONE", 0],
    ["F542", "SS", "MONTELEONE", 0],
    ["F543", "TR", "MONTELEONE", 0],
    ["F543", "TR", "MONTELEONE D'ORVIETO", 1],
    ["F537", "CZ", "MONTELEONE DI CALABRIA", 0],
    ["F536", "AP", "MONTELEONE DI FERMO", 0],
    ["F536", "FM", "MONTELEONE DI FERMO", 1],
    ["F538", "FG", "MONTELEONE DI PUGLIA", 1],
    ["F540", "PG", "MONTELEONE DI SPOLETO", 1],
    ["F542", "SS", "MONTELEONE ROCCA DORIA", 1],
    ["F541", "RI", "MONTELEONE SABINO", 1],
    ["F539", "PV", "MONTELEONE SUI COLLI PAVESI", 0],
    ["F544", "PA", "MONTELEPRE", 1],
    ["F545", "RM", "MONTELIBRETTI", 1],
    ["F546", "AV", "MONTELLA", 1],
    ["F547", "BG", "MONTELLO", 1],
    ["F548", "CB", "MONTELONGO", 1],
    ["F549", "AP", "MONTELPARO", 0],
    ["F549", "FM", "MONTELPARO", 1],
    ["F550", "CN", "MONTELUPO", 0],
    ["F551", "FI", "MONTELUPO", 0],
    ["F550", "CN", "MONTELUPO ALBESE", 1],
    ["F551", "FI", "MONTELUPO FIORENTINO", 1],
    ["F552", "MC", "MONTELUPONE", 1],
    ["F553", "PA", "MONTEMAGGIORE", 0],
    ["F554", "PU", "MONTEMAGGIORE", 0],
    ["F555", "PU", "MONTEMAGGIORE", 0],
    ["F555", "PU", "MONTEMAGGIORE AL METAURO", 0],
    ["F553", "PA", "MONTEMAGGIORE BELSITO", 1],
    ["F556", "AT", "MONTEMAGNO", 1],
    ["F557", "AV", "MONTEMALE", 0],
    ["F558", "CN", "MONTEMALE", 0],
    ["F558", "CN", "MONTEMALE DI CUNEO", 1],
    ["F559", "AV", "MONTEMARANO", 1],
    ["F560", "AN", "MONTEMARCIANO", 1],
    ["F562", "AL", "MONTEMARZINO", 1],
    ["F563", "TA", "MONTEMESOLA", 1],
    ["F564", "CO", "MONTEMEZZO", 1],
    ["F565", "AR", "MONTEMIGNAIO", 1],
    ["F566", "AV", "MONTEMILETTO", 1],
    ["F567", "MC", "MONTEMILONE", 0],
    ["F568", "PZ", "MONTEMILONE", 1],
    ["F569", "CB", "MONTEMITRO", 1],
    ["F570", "AP", "MONTEMONACO", 1],
    ["F572", "PO", "MONTEMURLO", 1],
    ["F573", "PZ", "MONTEMURRO", 1],
    ["F574", "UD", "MONTENARS", 1],
    ["F575", "MI", "MONTENATE", 0],
    ["Z159", "EE", "MONTENEGRO", 1],
    ["F577", "GO", "MONTENERO D'IDRIA", 0],
    ["F576", "CB", "MONTENERO DI BISACCIA", 1],
    ["F579", "RI", "MONTENERO SABINO", 1],
    ["F580", "IS", "MONTENERO VAL COCCHIARA", 1],
    ["F578", "CH", "MONTENERODOMO", 1],
    ["F581", "AN", "MONTENOVO", 0],
    ["F582", "CH", "MONTEODORISIO", 1],
    ["F585", "TE", "MONTEPAGANO", 0],
    ["F586", "CZ", "MONTEPAONE", 1],
    ["F587", "TA", "MONTEPARANO", 1],
    ["E326", "MT", "MONTEPELOSO", 0],
    ["F588", "BZ", "MONTEPONENTE", 0],
    ["F591", "AP", "MONTEPRANDONE", 1],
    ["F592", "SI", "MONTEPULCIANO", 1],
    ["F593", "AN", "MONTERADO", 0],
    ["F594", "AR", "MONTERCHI", 1],
    ["F595", "AQ", "MONTEREALE", 1],
    ["F596", "UD", "MONTEREALE", 0],
    ["F596", "UD", "MONTEREALE CELLINA", 0],
    ["F596", "PN", "MONTEREALE VALCELLINA", 1],
    ["F597", "BO", "MONTERENZIO", 1],
    ["F598", "SI", "MONTERIGGIONI", 1],
    ["F601", "IS", "MONTERODUNI", 1],
    ["F602", "PU", "MONTEROLO", 0],
    ["F604", "LE", "MONTERONI", 0],
    ["F605", "SI", "MONTERONI", 0],
    ["F605", "SI", "MONTERONI D'ARBIA", 1],
    ["F604", "LE", "MONTERONI DI LECCE", 1],
    ["F606", "VT", "MONTEROSI", 1],
    ["F607", "CZ", "MONTEROSSO", 0],
    ["F608", "CN", "MONTEROSSO", 0],
    ["F609", "GE", "MONTEROSSO", 0],
    ["F610", "RG", "MONTEROSSO", 0],
    ["F609", "SP", "MONTEROSSO AL MARE", 1],
    ["F610", "RG", "MONTEROSSO ALMO", 1],
    ["F607", "VV", "MONTEROSSO CALABRO", 1],
    ["F608", "CN", "MONTEROSSO GRANA", 1],
    ["F611", "RM", "MONTEROTONDO", 1],
    ["F612", "GR", "MONTEROTONDO MARITTIMO", 1],
    ["F613", "PG", "MONTERUBBIAGLIO", 0],
    ["F614", "AP", "MONTERUBBIANO", 0],
    ["F614", "FM", "MONTERUBBIANO", 1],
    ["C518", "RM", "MONTERUFO", 0],
    ["F615", "TN", "MONTES", 0],
    ["F623", "LE", "MONTESANO", 0],
    ["F624", "PV", "MONTESANO", 0],
    ["F625", "SA", "MONTESANO", 0],
    ["F624", "PV", "MONTESANO AL PIANO", 0],
    ["F623", "LE", "MONTESANO SALENTINO", 1],
    ["F625", "SA", "MONTESANO SULLA MARCELLANA", 1],
    ["F632", "MC", "MONTESANTO", 0],
    ["F633", "PG", "MONTESANTO", 0],
    ["F633", "PG", "MONTESANTO VIEZI", 0],
    ["F633", "PG", "MONTESANTO VIGI", 0],
    ["F636", "BN", "MONTESARCHIO", 1],
    ["F637", "MT", "MONTESCAGLIOSO", 1],
    ["F638", "PV", "MONTESCANO", 1],
    ["F639", "VB", "MONTESCHENO", 1],
    ["F640", "PI", "MONTESCUDAIO", 1],
    ["F641", "RN", "MONTESCUDO", 0],
    ["M368", "RN", "MONTESCUDO-MONTE COLOMBO", 1],
    ["F641", "FC", "MONTESCUDOLO", 0],
    ["F642", "MO", "MONTESE", 1],
    ["F500", "TE", "MONTESECCO", 0],
    ["F643", "PU", "MONTESECCO", 0],
    ["F643", "PU", "MONTESECCO ANTICO", 0],
    ["F644", "PV", "MONTESEGALE", 1],
    ["F645", "AN", "MONTESICURO", 0],
    ["F646", "PE", "MONTESILVANO", 1],
    ["F647", "PE", "MONTESILVANO MARINA", 0],
    ["F435", "MI", "MONTESIRO", 0],
    ["F648", "FI", "MONTESPERTOLI", 1],
    ["F649", "GO", "MONTESPINO", 0],
    ["F650", "TO", "MONTESTRUTTO", 0],
    ["F651", "TO", "MONTEU DA PO", 1],
    ["F654", "CN", "MONTEU ROERO", 1],
    ["M239", "TN", "MONTEVACCINO", 0],
    ["F655", "AG", "MONTEVAGO", 1],
    ["F656", "AR", "MONTEVARCHI", 1],
    ["F657", "CO", "MONTEVECCHIA", 0],
    ["F657", "LC", "MONTEVECCHIA", 1],
    ["F658", "PU", "MONTEVECCHIO", 0],
    ["F659", "BO", "MONTEVEGLIO", 0],
    ["F660", "AV", "MONTEVERDE", 1],
    ["F661", "PI", "MONTEVERDI", 0],
    ["F661", "PI", "MONTEVERDI MARITTIMO", 1],
    ["F662", "VI", "MONTEVIALE", 1],
    ["F663", "VA", "MONTEVIASCO", 0],
    ["F666", "CN", "MONTEZEMOLO", 1],
    ["F667", "SS", "MONTI", 1],
    ["F668", "FC", "MONTIANO", 1],
    ["F466", "RM", "MONTICELLI", 0],
    ["F547", "BG", "MONTICELLI", 0],
    ["F669", "CN", "MONTICELLI", 0],
    ["F670", "PV", "MONTICELLI", 0],
    ["F671", "PC", "MONTICELLI", 0],
    ["F669", "CN", "MONTICELLI ALBA", 0],
    ["F672", "BS", "MONTICELLI BRUSATI", 1],
    ["F671", "PC", "MONTICELLI D'ONGINA", 1],
    ["F547", "BG", "MONTICELLI DI BORGOGNA", 0],
    ["F670", "PV", "MONTICELLI PAVESE", 1],
    ["F673", "CR", "MONTICELLI RIPA D'OGLIO", 0],
    ["F616", "RM", "MONTICELLO", 0],
    ["F674", "LC", "MONTICELLO BRIANZA", 1],
    ["F675", "VI", "MONTICELLO CONTE OTTO", 1],
    ["F669", "CN", "MONTICELLO D'ALBA", 1],
    ["F471", "BS", "MONTICHIARI", 1],
    ["F676", "SI", "MONTICIANO", 1],
    ["F677", "GR", "MONTIERI", 1],
    ["F678", "AT", "MONTIGLIO", 0],
    ["M302", "AT", "MONTIGLIO MONFERRATO", 1],
    ["F679", "MS", "MONTIGNOSO", 1],
    ["F680", "BS", "MONTIRONE", 1],
    ["F367", "AO", "MONTJOVET", 1],
    ["F681", "CR", "MONTODINE", 1],
    ["F682", "GE", "MONTOGGIO", 1],
    ["F683", "PL", "MONTONA", 0],
    ["F684", "VA", "MONTONATE", 0],
    ["F685", "PG", "MONTONE", 1],
    ["F686", "PI", "MONTOPOLI", 0],
    ["F687", "RI", "MONTOPOLI", 0],
    ["F687", "RI", "MONTOPOLI DI SABINA", 1],
    ["F686", "PI", "MONTOPOLI IN VAL D'ARNO", 1],
    ["F688", "CO", "MONTORFANO", 1],
    ["F689", "CB", "MONTORIO", 0],
    ["F690", "TE", "MONTORIO", 0],
    ["F691", "VR", "MONTORIO", 0],
    ["F690", "TE", "MONTORIO AL VOMANO", 1],
    ["F689", "CB", "MONTORIO NEI FRENTANI", 1],
    ["F692", "RM", "MONTORIO ROMANO", 1],
    ["F691", "VR", "MONTORIO VERONESE", 0],
    ["M330", "AV", "MONTORO", 1],
    ["F693", "AV", "MONTORO INFERIORE", 0],
    ["F694", "AV", "MONTORO SUPERIORE", 0],
    ["F695", "BN", "MONTORSO", 0],
    ["F696", "VI", "MONTORSO VICENTINO", 1],
    ["F697", "AP", "MONTOTTONE", 0],
    ["F697", "FM", "MONTOTTONE", 1],
    ["F698", "NU", "MONTRESTA", 0],
    ["F698", "OR", "MONTRESTA", 1],
    ["F699", "NO", "MONTRIGIASCO", 0],
    ["F700", "BA", "MONTRONE", 0],
    ["Z531", "EE", "MONTSERRAT", 1],
    ["F701", "PV", "MONTU' BECCARIA", 1],
    ["F702", "PV", "MONTU' BERCHIELLI", 0],
    ["B613", "PV", "MONTU' DEI GABBI", 0],
    ["F703", "VA", "MONVALLE", 1],
    ["F704", "MB", "MONZA", 1],
    ["F704", "MI", "MONZA", 0],
    ["F705", "MN", "MONZAMBANO", 1],
    ["F706", "BO", "MONZUNO", 1],
    ["F707", "AL", "MORANO", 0],
    ["F708", "CS", "MORANO", 0],
    ["F708", "CS", "MORANO CALABRO", 1],
    ["F707", "AL", "MORANO SUL PO", 1],
    ["F709", "AT", "MORANSENGO", 1],
    ["F710", "GO", "MORARO", 1],
    ["F711", "VA", "MORAZZONE", 1],
    ["F712", "SO", "MORBEGNO", 1],
    ["F713", "AL", "MORBELLO", 1],
    ["F714", "VC", "MORCA", 0],
    ["F715", "FC", "MORCIANO", 0],
    ["F716", "LE", "MORCIANO", 0],
    ["F716", "LE", "MORCIANO DI LEUCA", 1],
    ["F715", "RN", "MORCIANO DI ROMAGNA", 1],
    ["F717", "BN", "MORCONE", 1],
    ["F718", "BO", "MORDANO", 1],
    ["F719", "AP", "MOREGNANO", 0],
    ["F720", "BG", "MORENGO", 1],
    ["F721", "SS", "MORES", 1],
    ["F722", "AP", "MORESCO", 0],
    ["F722", "FM", "MORESCO", 1],
    ["F723", "CN", "MORETTA", 1],
    ["F724", "PC", "MORFASSO", 1],
    ["F725", "TV", "MORGANO", 1],
    ["F726", "AO", "MORGEX", 1],
    ["F727", "OR", "MORGONGIORI", 1],
    ["F728", "TN", "MORI", 1],
    ["F729", "TV", "MORIAGO", 0],
    ["F729", "TV", "MORIAGO DELLA BATTAGLIA", 1],
    ["F730", "RM", "MORICONE", 1],
    ["F731", "SA", "MORIGERATI", 1],
    ["D033", "MI", "MORIMONDO", 1],
    ["F732", "AQ", "MORINO", 1],
    ["F733", "TO", "MORIONDO TORINESE", 1],
    ["F734", "RM", "MORLUPO", 1],
    ["F735", "CS", "MORMANNO", 1],
    ["F736", "VA", "MORNAGO", 1],
    ["F737", "AL", "MORNESE", 1],
    ["F738", "BG", "MORNICO", 0],
    ["F739", "PV", "MORNICO", 0],
    ["F738", "BG", "MORNICO AL SERIO", 1],
    ["F739", "PV", "MORNICO LOSANA", 1],
    ["F740", "FR", "MOROLO", 1],
    ["F741", "VC", "MORONDO", 0],
    ["F742", "VA", "MOROSOLO", 0],
    ["F743", "CN", "MOROZZO", 1],
    ["E430", "CN", "MORRA", 0],
    ["F744", "AV", "MORRA", 0],
    ["F744", "AV", "MORRA DE SANCTIS", 1],
    ["F744", "AV", "MORRA IRPINO", 0],
    ["F745", "AN", "MORRO", 0],
    ["F746", "RI", "MORRO", 0],
    ["F747", "TE", "MORRO", 0],
    ["F745", "AN", "MORRO D'ALBA", 1],
    ["F747", "TE", "MORRO D'ORO", 1],
    ["F746", "RI", "MORRO REATINO", 1],
    ["C211", "CE", "MORRONE", 0],
    ["F748", "CB", "MORRONE", 0],
    ["F748", "CB", "MORRONE DEL SANNIO", 1],
    ["F749", "MC", "MORROVALLE", 1],
    ["F750", "PN", "MORSANO AL TAGLIAMENTO", 1],
    ["F751", "AL", "MORSASCO", 1],
    ["F752", "MI", "MORSENCHIO", 0],
    ["F753", "FC", "MORTANO", 0],
    ["F754", "PV", "MORTARA", 1],
    ["F755", "TN", "MORTASO", 0],
    ["F756", "UD", "MORTEGLIANO", 1],
    ["F757", "BZ", "MORTER", 0],
    ["F758", "LC", "MORTERONE", 1],
    ["F759", "PC", "MORTIZZA", 0],
    ["F760", "UD", "MORUZZO", 1],
    ["F761", "CR", "MOSCAZZANO", 1],
    ["F762", "AV", "MOSCHIANO", 1],
    ["F763", "FM", "MOSCHIENA", 0],
    ["F381", "AN", "MOSCIANO", 0],
    ["F764", "TE", "MOSCIANO", 0],
    ["F764", "TE", "MOSCIANO SANT'ANGELO", 1],
    ["F765", "PE", "MOSCUFO", 1],
    ["F766", "BZ", "MOSO", 0],
    ["F766", "BZ", "MOSO IN PASSIRIA", 1],
    ["F767", "GO", "MOSSA", 1],
    ["F768", "VI", "MOSSANO", 0],
    ["M304", "BI", "MOSSO", 0],
    ["F769", "BI", "MOSSO SANTA MARIA", 0],
    ["F770", "TV", "MOTTA", 0],
    ["F771", "CR", "MOTTA BALUFFI", 1],
    ["F772", "ME", "MOTTA CAMASTRA", 1],
    ["F773", "ME", "MOTTA D'AFFERMO", 1],
    ["F774", "VC", "MOTTA DE' CONTI", 1],
    ["F770", "TV", "MOTTA DI LIVENZA", 1],
    ["F777", "FG", "MOTTA MONTECORVINO", 1],
    ["F778", "PV", "MOTTA SAN DAMIANO", 0],
    ["F779", "RC", "MOTTA SAN GIOVANNI", 1],
    ["F781", "CT", "MOTTA SANT'ANASTASIA", 1],
    ["F780", "CZ", "MOTTA SANTA LUCIA", 1],
    ["F782", "MI", "MOTTA VIGANA", 0],
    ["F783", "MI", "MOTTA VISCONTI", 1],
    ["F775", "CS", "MOTTAFOLLONE", 1],
    ["F776", "BI", "MOTTALCIATA", 1],
    ["B012", "MN", "MOTTEGGIANA", 1],
    ["F784", "TA", "MOTTOLA", 1],
    ["Z333", "EE", "MOZAMBICO", 1],
    ["F785", "CH", "MOZZAGROGNA", 1],
    ["F786", "BG", "MOZZANICA", 1],
    ["F787", "AP", "MOZZANO", 0],
    ["F788", "CO", "MOZZATE", 1],
    ["F789", "VR", "MOZZECANE", 1],
    ["F790", "NO", "MOZZIO", 0],
    ["F791", "BG", "MOZZO", 1],
    ["F792", "BS", "MU", 0],
    ["F793", "MC", "MUCCIA", 1],
    ["F794", "VA", "MUCENO", 0],
    ["F795", "TS", "MUGGIA", 1],
    ["F796", "MI", "MUGGIANO", 0],
    ["F797", "MB", "MUGGIO'", 1],
    ["F797", "MI", "MUGGIO'", 0],
    ["F798", "AV", "MUGNANO", 0],
    ["F799", "NA", "MUGNANO", 0],
    ["F798", "AV", "MUGNANO DEL CARDINALE", 1],
    ["F799", "NA", "MUGNANO DI NAPOLI", 1],
    ["F800", "CA", "MULARGIA", 0],
    ["F801", "LO", "MULAZZANO", 1],
    ["F802", "MS", "MULAZZO", 1],
    ["F803", "BZ", "MULES", 0],
    ["F804", "MN", "MULO", 0],
    ["F805", "GE", "MULTEDO", 0],
    ["F806", "BS", "MURA", 1],
    ["F807", "VE", "MURANO", 0],
    ["F808", "CA", "MURAVERA", 0],
    ["F808", "SU", "MURAVERA", 1],
    ["F809", "CN", "MURAZZANO", 1],
    ["F810", "VI", "MURE", 0],
    ["F811", "CN", "MURELLO", 1],
    ["F812", "AO", "MURIAGLIO", 0],
    ["F813", "SV", "MURIALDO", 1],
    ["F814", "AL", "MURISENGO", 1],
    ["F815", "SI", "MURLO", 1],
    ["F816", "LE", "MURO", 0],
    ["F817", "PZ", "MURO", 0],
    ["F816", "LE", "MURO LECCESE", 1],
    ["F817", "PZ", "MURO LUCANO", 1],
    ["F818", "SS", "MUROS", 1],
    ["F819", "VA", "MUSADINO", 0],
    ["F820", "BS", "MUSCOLINE", 1],
    ["F821", "UD", "MUSCOLO-STRASSOLDO", 0],
    ["F822", "CA", "MUSEI", 0],
    ["F822", "SU", "MUSEI", 1],
    ["F823", "PE", "MUSELLARO", 0],
    ["F824", "BO", "MUSIANO", 0],
    ["F825", "VA", "MUSIGNANO", 0],
    ["F826", "VE", "MUSILE", 0],
    ["F826", "VE", "MUSILE DI PIAVE", 1],
    ["F827", "MI", "MUSOCCO", 0],
    ["F828", "CO", "MUSSO", 1],
    ["F829", "VI", "MUSSOLENTE", 1],
    ["A357", "CA", "MUSSOLINIA", 0],
    ["F830", "CL", "MUSSOMELI", 1],
    ["F831", "TE", "MUTIGNANO", 0],
    ["F832", "UD", "MUZZANA", 0],
    ["F832", "UD", "MUZZANA DEL TURGNANO", 1],
    ["F833", "BI", "MUZZANO", 1],
    ["Z206", "EE", "MYANMAR", 1],
    ["A500", "TS", "NABRESINA", 0],
    ["F834", "TS", "NACLA SAN MAURIZIO", 0],
    ["F835", "TN", "NAGO-TORBOLE", 1],
    ["F836", "BZ", "NALLES", 1],
    ["Z300", "EE", "NAMIBIA", 1],
    ["F837", "TN", "NANNO", 0],
    ["F838", "VI", "NANTO", 1],
    ["F839", "NA", "NAPOLI", 1],
    ["F840", "OR", "NARBOLIA", 1],
    ["F841", "CA", "NARCAO", 0],
    ["F841", "SU", "NARCAO", 1],
    ["F842", "LE", "NARDO'", 1],
    ["F843", "VV", "NARDODIPACE", 1],
    ["F844", "TR", "NARNI", 1],
    ["F845", "AG", "NARO", 1],
    ["E298", "CO", "NARRO", 0],
    ["F846", "CN", "NARZOLE", 1],
    ["F847", "SV", "NASINO", 1],
    ["F848", "ME", "NASO", 1],
    ["F849", "BZ", "NATURNO", 1],
    ["Z713", "EE", "NAURU (ISOLE)", 1],
    ["F850", "CO", "NAVA", 0],
    ["F851", "BS", "NAVE", 1],
    ["F853", "TN", "NAVE SAN ROCCO", 0],
    ["F852", "AQ", "NAVELLI", 1],
    ["F854", "BS", "NAVONO", 0],
    ["F855", "BZ", "NAZ", 0],
    ["F856", "BZ", "NAZ SCIAVES", 0],
    ["F856", "BZ", "NAZ-SCIAVES", 1],
    ["F857", "RM", "NAZZANO", 1],
    ["F858", "GE", "NE", 1],
    ["F859", "NO", "NEBBIUNO", 1],
    ["F860", "VR", "NEGARINE", 0],
    ["F861", "VR", "NEGRAR", 0],
    ["F861", "VR", "NEGRAR DI VALPOLICELLA", 1],
    ["F862", "GE", "NEIRONE", 1],
    ["F863", "CN", "NEIVE", 1],
    ["F864", "BG", "NEMBRO", 1],
    ["F865", "RM", "NEMI", 1],
    ["F866", "PZ", "NEMOLI", 1],
    ["F867", "OR", "NEONELI", 1],
    ["Z234", "EE", "NEPAL", 1],
    ["F868", "VT", "NEPI", 1],
    ["F869", "PL", "NERESINE", 0],
    ["F870", "TE", "NERETO", 1],
    ["F871", "RM", "NEROLA", 1],
    ["F872", "TV", "NERVESA", 0],
    ["F872", "TV", "NERVESA DELLA BATTAGLIA", 1],
    ["F873", "GE", "NERVI", 0],
    ["F874", "MI", "NERVIANO", 1],
    ["F875", "BG", "NESE", 0],
    ["F876", "RI", "NESPOLO", 1],
    ["F877", "CO", "NESSO", 1],
    ["F878", "BI", "NETRO", 1],
    ["F879", "RM", "NETTUNIA", 0],
    ["F880", "RM", "NETTUNO", 1],
    ["F881", "LE", "NEVIANO", 1],
    ["F882", "PR", "NEVIANO DEGLI ARDUINI", 1],
    ["F883", "CN", "NEVIGLIE", 1],
    ["F884", "BS", "NIARDO", 1],
    ["F885", "PC", "NIBBIANO", 0],
    ["F886", "NO", "NIBBIOLA", 1],
    ["F887", "LC", "NIBIONNO", 1],
    ["Z515", "EE", "NICARAGUA", 1],
    ["F888", "CZ", "NICASTRO", 0],
    ["F889", "TO", "NICHELINO", 1],
    ["F890", "CT", "NICOLOSI", 1],
    ["F891", "PV", "NICORVO", 1],
    ["F892", "EN", "NICOSIA", 1],
    ["F893", "VV", "NICOTERA", 1],
    ["F894", "CN", "NIELLA BELBO", 1],
    ["F895", "CN", "NIELLA TANARO", 1],
    ["Z334", "EE", "NIGER", 1],
    ["Z335", "EE", "NIGERIA", 1],
    ["F896", "BS", "NIGOLINE", 0],
    ["F896", "BS", "NIGOLINE-BONOMELLI", 0],
    ["F897", "MI", "NIGUARDA", 0],
    ["F898", "UD", "NIMIS", 1],
    ["F899", "CL", "NISCEMI", 1],
    ["F900", "EN", "NISSORIA", 1],
    ["Z714", "EE", "NIUE=SAVAGE (ISOLE)", 1],
    ["F901", "ME", "NIZZA DI SICILIA", 1],
    ["F902", "AT", "NIZZA MONFERRATO", 1],
    ["F903", "VA", "NIZZOLINA", 0],
    ["F904", "VE", "NOALE", 1],
    ["F905", "TN", "NOARNA", 0],
    ["F906", "TO", "NOASCA", 1],
    ["F907", "CS", "NOCARA", 1],
    ["F908", "PE", "NOCCIANO", 1],
    ["F909", "NO", "NOCCO", 0],
    ["F910", "CZ", "NOCERA", 0],
    ["F911", "PG", "NOCERA", 0],
    ["F912", "SA", "NOCERA INFERIORE", 1],
    ["F913", "SA", "NOCERA SUPERIORE", 1],
    ["F910", "CZ", "NOCERA TERINESE", 1],
    ["F911", "PG", "NOCERA UMBRA", 1],
    ["F914", "PR", "NOCETO", 1],
    ["F855", "BZ", "NOCI", 0],
    ["F915", "BA", "NOCI", 1],
    ["F916", "LE", "NOCIGLIA", 1],
    ["F917", "PZ", "NOEPOLI", 1],
    ["F918", "VR", "NOGARA", 1],
    ["F919", "TN", "NOGARE'", 0],
    ["F920", "TN", "NOGAREDO", 1],
    ["F921", "VR", "NOGAROLE", 0],
    ["F922", "VI", "NOGAROLE", 0],
    ["F921", "VR", "NOGAROLE ROCCA", 1],
    ["F922", "VI", "NOGAROLE VICENTINO", 1],
    ["F917", "PZ", "NOIA", 0],
    ["F923", "BA", "NOICATTARO", 1],
    ["F923", "BA", "NOJA", 0],
    ["F924", "NA", "NOLA", 1],
    ["F925", "TO", "NOLE", 1],
    ["F926", "SV", "NOLI", 1],
    ["F927", "TO", "NOMAGLIO", 1],
    ["F928", "TN", "NOMESINO", 0],
    ["F929", "TN", "NOMI", 1],
    ["F930", "MO", "NONANTOLA", 1],
    ["F931", "TO", "NONE", 1],
    ["F932", "VB", "NONIO", 1],
    ["F933", "NU", "NORAGUGUME", 1],
    ["F934", "OR", "NORBELLO", 1],
    ["F935", "PG", "NORCIA", 1],
    ["Z715", "EE", "NORFOLK (ISOLE E ISOLE DEL MAR DEI CORALLI)", 1],
    ["F934", "CA", "NORGHIDDO", 0],
    ["F936", "TN", "NORIGLIO", 0],
    ["F937", "LT", "NORMA", 1],
    ["Z124", "EE", "NORMANNE (ISOLE)=ISOLE DEL CANALE", 1],
    ["Z125", "EE", "NORVEGIA", 1],
    ["F938", "CR", "NOSADELLO", 0],
    ["F939", "MI", "NOSATE", 1],
    ["F940", "MI", "NOSEDO", 0],
    ["F941", "BG", "NOSSA", 0],
    ["F942", "TE", "NOTARESCO", 1],
    ["F943", "SR", "NOTO", 1],
    ["F944", "MI", "NOVA", 0],
    ["F949", "BZ", "NOVA LEVANTE", 1],
    ["F944", "MB", "NOVA MILANESE", 1],
    ["F944", "MI", "NOVA MILANESE", 0],
    ["F950", "BZ", "NOVA PONENTE", 1],
    ["A942", "MT", "NOVA SIRI", 1],
    ["F945", "BZ", "NOVACELLA", 0],
    ["F137", "PU", "NOVAFELTRIA", 0],
    ["F137", "RN", "NOVAFELTRIA", 1],
    ["F946", "VI", "NOVALE", 0],
    ["F947", "TN", "NOVALEDO", 1],
    ["F948", "TO", "NOVALESA", 1],
    ["F951", "ME", "NOVARA", 0],
    ["F952", "NO", "NOVARA", 1],
    ["F951", "ME", "NOVARA DI SICILIA", 1],
    ["F953", "AO", "NOVAREGLIA", 0],
    ["F954", "CO", "NOVATE", 0],
    ["F955", "MI", "NOVATE", 0],
    ["F956", "SO", "NOVATE", 0],
    ["F954", "CO", "NOVATE BRIANZA", 0],
    ["F956", "SO", "NOVATE MEZZOLA", 1],
    ["F955", "MI", "NOVATE MILANESE", 1],
    ["F957", "VI", "NOVE", 1],
    ["F958", "CO", "NOVEDRATE", 1],
    ["F959", "MI", "NOVEGRO", 0],
    ["M430", "TN", "NOVELLA", 1],
    ["F960", "RE", "NOVELLARA", 1],
    ["F961", "CN", "NOVELLO", 1],
    ["F339", "CN", "NOVELLO MONCHIERO", 0],
    ["F962", "PD", "NOVENTA", 0],
    ["F963", "VE", "NOVENTA", 0],
    ["F963", "VE", "NOVENTA DI PIAVE", 1],
    ["F962", "PD", "NOVENTA PADOVANA", 1],
    ["F964", "VI", "NOVENTA VICENTINA", 1],
    ["F965", "AL", "NOVI", 0],
    ["F966", "MO", "NOVI", 0],
    ["F967", "SA", "NOVI", 0],
    ["F966", "MO", "NOVI DI MODENA", 1],
    ["F965", "AL", "NOVI LIGURE", 1],
    ["F965", "AL", "NOVI PIEMONTE", 0],
    ["F967", "SA", "NOVI VELIA", 1],
    ["F968", "MI", "NOVIGLIO", 1],
    ["F969", "PU", "NOVILARA", 0],
    ["F970", "LE", "NOVOLI", 1],
    ["F971", "BS", "NOZZA", 0],
    ["F972", "CN", "NUCETTO", 1],
    ["F973", "SS", "NUCHIS", 0],
    ["F974", "CA", "NUGHEDU", 0],
    ["F975", "SS", "NUGHEDU", 0],
    ["F975", "SS", "NUGHEDU SAN NICOLO'", 1],
    ["F974", "OR", "NUGHEDU SANTA VITTORIA", 1],
    ["F976", "SS", "NULE", 1],
    ["F977", "SS", "NULVI", 1],
    ["F978", "AN", "NUMANA", 1],
    ["F979", "NU", "NUORO", 1],
    ["Z716", "EE", "NUOVA CALEDONIA (ISOLE E DIPENDENZE)", 1],
    ["Z718", "EE", "NUOVA GUINEA", 1],
    ["Z719", "EE", "NUOVA ZELANDA", 1],
    ["Z717", "EE", "NUOVE EBRIDI (ISOLE CONDOMINIO FRANCO-INGLESE)", 1],
    ["F980", "OR", "NURACHI", 1],
    ["F981", "NU", "NURAGUS", 0],
    ["F981", "SU", "NURAGUS", 1],
    ["F982", "NU", "NURALLAO", 0],
    ["F982", "SU", "NURALLAO", 1],
    ["F983", "CA", "NURAMINIS", 0],
    ["F983", "SU", "NURAMINIS", 1],
    ["F984", "CA", "NURAXINIEDDU", 0],
    ["F985", "OR", "NURECI", 1],
    ["F986", "NU", "NURRI", 0],
    ["F986", "SU", "NURRI", 1],
    ["F987", "AO", "NUS", 1],
    ["F988", "AV", "NUSCO", 1],
    ["F989", "BS", "NUVOLENTO", 1],
    ["F990", "BS", "NUVOLERA", 1],
    ["F991", "CA", "NUXIS", 0],
    ["F991", "SU", "NUXIS", 1],
    ["F992", "BI", "OCCHIEPPO INFERIORE", 1],
    ["F993", "BI", "OCCHIEPPO SUPERIORE", 1],
    ["F994", "RO", "OCCHIOBELLO", 1],
    ["F995", "AL", "OCCIMIANO", 1],
    ["F996", "AQ", "OCRE", 1],
    ["F997", "AL", "ODALENGO GRANDE", 1],
    ["F998", "AL", "ODALENGO PICCOLO", 1],
    ["F999", "TV", "ODERZO", 1],
    ["G001", "BS", "ODOLO", 1],
    ["G002", "AQ", "OFENA", 1],
    ["G003", "AN", "OFFAGNA", 1],
    ["G004", "CR", "OFFANENGO", 1],
    ["G005", "AP", "OFFIDA", 1],
    ["G006", "BS", "OFFLAGA", 1],
    ["G007", "VB", "OGGEBBIO", 1],
    ["G008", "VA", "OGGIONA", 0],
    ["G008", "VA", "OGGIONA CON SANTO STEFANO", 1],
    ["G009", "LC", "OGGIONO", 1],
    ["G010", "TO", "OGLIANICO", 1],
    ["A946", "PA", "OGLIASTRO", 0],
    ["G011", "SA", "OGLIASTRO", 0],
    ["G011", "SA", "OGLIASTRO CILENTO", 1],
    ["G012", "AO", "OIASSE", 0],
    ["G013", "CO", "OLATE", 0],
    ["G014", "SV", "OLBA", 0],
    ["G015", "SS", "OLBIA", 1],
    ["G016", "VC", "OLCENENGO", 1],
    ["G017", "CO", "OLCIO", 0],
    ["G018", "VC", "OLDENICO", 1],
    ["G019", "NO", "OLEGGIO", 1],
    ["G020", "NO", "OLEGGIO CASTELLO", 1],
    ["G021", "PV", "OLEVANO", 0],
    ["G022", "RM", "OLEVANO", 0],
    ["G023", "SA", "OLEVANO", 0],
    ["G021", "PV", "OLEVANO DI LOMELLINA", 1],
    ["G022", "RM", "OLEVANO ROMANO", 1],
    ["G023", "SA", "OLEVANO SUL FUSCIANO", 0],
    ["G023", "SA", "OLEVANO SUL TUSCIANO", 1],
    ["G024", "NO", "OLGIA", 0],
    ["G025", "CO", "OLGIATE", 0],
    ["G026", "CO", "OLGIATE", 0],
    ["G027", "CO", "OLGIATE CALCO", 0],
    ["G025", "CO", "OLGIATE COMASCO", 1],
    ["G026", "LC", "OLGIATE MOLGORA", 1],
    ["G028", "VA", "OLGIATE OLONA", 1],
    ["G029", "VA", "OLGINASIO", 0],
    ["G030", "LC", "OLGINATE", 1],
    ["G031", "NU", "OLIENA", 1],
    ["G032", "PV", "OLIVA", 0],
    ["G033", "TO", "OLIVA", 0],
    ["G032", "PV", "OLIVA GESSI", 1],
    ["G034", "CZ", "OLIVADI", 1],
    ["G035", "IM", "OLIVASTRI", 0],
    ["G036", "ME", "OLIVERI", 1],
    ["G037", "MT", "OLIVETO", 0],
    ["G038", "PG", "OLIVETO", 0],
    ["G039", "SA", "OLIVETO", 0],
    ["G039", "SA", "OLIVETO CITRA", 1],
    ["G038", "PG", "OLIVETO IN SABINA", 0],
    ["G040", "LC", "OLIVETO LARIO", 1],
    ["G037", "MT", "OLIVETO LUCANO", 1],
    ["G041", "IM", "OLIVETTA SAN MICHELE", 1],
    ["G042", "AL", "OLIVOLA", 1],
    ["A126", "CA", "OLLASTA", 0],
    ["G043", "OR", "OLLASTRA", 1],
    ["G043", "OR", "OLLASTRA SIMAXIS", 0],
    ["A126", "CA", "OLLASTRA USELLUS", 0],
    ["G044", "NU", "OLLOLAI", 1],
    ["G045", "AO", "OLLOMONT", 1],
    ["G045", "AO", "OLLOMONTE", 0],
    ["G046", "SS", "OLMEDO", 1],
    ["G047", "CR", "OLMENETA", 1],
    ["G048", "AL", "OLMO", 0],
    ["G049", "BG", "OLMO", 0],
    ["G049", "BG", "OLMO AL BREMBO", 1],
    ["G048", "AT", "OLMO GENTILE", 1],
    ["G050", "BG", "OLTRE IL COLLE", 1],
    ["G051", "BG", "OLTREPOVO", 0],
    ["G052", "TN", "OLTRESARCA", 0],
    ["G053", "GO", "OLTRESONZIA", 0],
    ["G054", "BG", "OLTRESSENDA ALTA", 1],
    ["G055", "BG", "OLTRESSENDA BASSA", 0],
    ["G056", "CO", "OLTRONA", 0],
    ["G057", "VA", "OLTRONA", 0],
    ["G057", "VA", "OLTRONA AL LAGO", 0],
    ["G056", "CO", "OLTRONA DI SAN MAMETTE", 1],
    ["G058", "NU", "OLZAI", 1],
    ["Z235", "EE", "OMAN", 1],
    ["G059", "MI", "OMATE", 0],
    ["G060", "CR", "OMBRIANO", 0],
    ["G061", "BS", "OME", 1],
    ["G062", "VB", "OMEGNA", 1],
    ["G063", "SA", "OMIGNANO", 1],
    ["G064", "NU", "ONANI", 0],
    ["G064", "NU", "ONANI'", 1],
    ["G065", "VT", "ONANO", 1],
    ["G066", "CN", "ONCINO", 1],
    ["G067", "IM", "ONEGLIA", 0],
    ["G068", "BG", "ONETA", 1],
    ["G069", "BZ", "ONIES", 0],
    ["G070", "NU", "ONIFAI", 1],
    ["G071", "NU", "ONIFERI", 1],
    ["G072", "CO", "ONNO", 0],
    ["G073", "BS", "ONO", 0],
    ["G074", "BS", "ONO", 0],
    ["G073", "BS", "ONO DEGNO", 0],
    ["G074", "BS", "ONO SAN PIETRO", 1],
    ["G075", "BG", "ONORE", 1],
    ["G076", "SV", "ONZO", 1],
    ["G077", "GO", "OPACCHIASELLA", 0],
    ["G078", "MI", "OPERA", 1],
    ["G079", "AQ", "OPI", 1],
    ["G080", "VR", "OPPEANO", 1],
    ["G081", "PZ", "OPPIDO", 0],
    ["G082", "RC", "OPPIDO", 0],
    ["G081", "PZ", "OPPIDO LUCANO", 1],
    ["G082", "RC", "OPPIDO MAMERTINA", 1],
    ["G083", "BZ", "ORA", 1],
    ["C382", "VA", "ORAGO", 0],
    ["G084", "NU", "ORANI", 1],
    ["G085", "NO", "ORASSO", 0],
    ["G086", "CB", "ORATINO", 1],
    ["G087", "TO", "ORBASSANO", 1],
    ["G088", "GR", "ORBETELLO", 1],
    ["G089", "PU", "ORCIANO DI PESARO", 0],
    ["G090", "PI", "ORCIANO PISANO", 1],
    ["G091", "GE", "ORCO", 0],
    ["D522", "SV", "ORCO FEGLINO", 1],
    ["M266", "FG", "ORDONA", 1],
    ["G092", "MI", "ORENO", 0],
    ["G093", "GE", "ORERO", 1],
    ["G094", "BG", "OREZZO", 0],
    ["G095", "VI", "ORGIANO", 1],
    ["G096", "MI", "ORGNAGA", 0],
    ["G097", "NU", "ORGOSOLO", 1],
    ["G098", "BR", "ORIA", 1],
    ["G099", "BS", "ORIANO", 0],
    ["G100", "CO", "ORIANO", 0],
    ["G101", "MI", "ORIANO", 0],
    ["G100", "CO", "ORIANO DI BRIANZA", 0],
    ["G101", "MI", "ORIANO SOPRA TICINO", 0],
    ["G102", "AQ", "ORICOLA", 1],
    ["G103", "VA", "ORIGGIO", 1],
    ["G104", "PV", "ORIGIOSO", 0],
    ["G105", "VA", "ORINO", 1],
    ["G106", "VA", "ORINO AZZIO", 0],
    ["G107", "MI", "ORIO", 0],
    ["G108", "BG", "ORIO", 0],
    ["G109", "TO", "ORIO", 0],
    ["G108", "BG", "ORIO AL SERIO", 1],
    ["G109", "TO", "ORIO CANAVESE", 1],
    ["G107", "LO", "ORIO LITTA", 1],
    ["G110", "CS", "ORIOLO", 1],
    ["G111", "RM", "ORIOLO", 0],
    ["G111", "VT", "ORIOLO ROMANO", 1],
    ["G112", "BZ", "ORIS", 0],
    ["G113", "OR", "ORISTANO", 1],
    ["G114", "CN", "ORMEA", 1],
    ["G115", "TV", "ORMELLE", 1],
    ["G116", "MB", "ORNAGO", 1],
    ["G116", "MI", "ORNAGO", 0],
    ["G117", "VB", "ORNAVASSO", 1],
    ["G118", "BG", "ORNICA", 1],
    ["G119", "NU", "OROSEI", 1],
    ["G120", "NU", "OROTELLI", 1],
    ["G121", "SA", "ORRIA", 1],
    ["G122", "NU", "ORROLI", 0],
    ["G122", "SU", "ORROLI", 1],
    ["G123", "TV", "ORSAGO", 1],
    ["G124", "AL", "ORSARA", 0],
    ["G125", "AV", "ORSARA", 0],
    ["G124", "AL", "ORSARA BORMIDA", 1],
    ["G125", "FG", "ORSARA DI PUGLIA", 1],
    ["G125", "AV", "ORSARA-DAUNO IRPINA", 0],
    ["G126", "CO", "ORSENIGO", 1],
    ["G127", "PL", "ORSERA", 0],
    ["G128", "CH", "ORSOGNA", 1],
    ["G129", "CS", "ORSOMARSO", 1],
    ["G130", "CE", "ORTA", 0],
    ["G131", "FG", "ORTA", 0],
    ["G132", "NO", "ORTA", 0],
    ["G130", "CE", "ORTA DI ATELLA", 1],
    ["G131", "FG", "ORTA NOVA", 1],
    ["G132", "NO", "ORTA NOVARESE", 0],
    ["G134", "NO", "ORTA SAN GIULIO", 1],
    ["G133", "CA", "ORTACESUS", 0],
    ["G133", "SU", "ORTACESUS", 1],
    ["G135", "VT", "ORTE", 1],
    ["G136", "LE", "ORTELLE", 1],
    ["G137", "AP", "ORTEZZANO", 0],
    ["G137", "FM", "ORTEZZANO", 1],
    ["G138", "RC", "ORTI", 0],
    ["G139", "AR", "ORTIGNANO", 0],
    ["G139", "AR", "ORTIGNANO RAGGIOLO", 1],
    ["M240", "TN", "ORTISE'", 0],
    ["G140", "BZ", "ORTISEI", 1],
    ["F479", "SA", "ORTODONICO", 0],
    ["G141", "CH", "ORTONA", 1],
    ["G142", "AQ", "ORTONA DEI MARSI", 1],
    ["G143", "SP", "ORTONOVO", 0],
    ["G144", "SV", "ORTOVERO", 1],
    ["G145", "AQ", "ORTUCCHIO", 1],
    ["G146", "NU", "ORTUERI", 1],
    ["G147", "NU", "ORUNE", 1],
    ["G148", "TR", "ORVIETO", 1],
    ["B595", "RI", "ORVINIO", 1],
    ["G149", "BS", "ORZINUOVI", 1],
    ["G150", "BS", "ORZIVECCHI", 1],
    ["G151", "TO", "OSASCO", 1],
    ["G152", "TO", "OSASIO", 1],
    ["G153", "SS", "OSCHIRI", 1],
    ["G154", "NU", "OSIDDA", 1],
    ["G155", "SV", "OSIGLIA", 1],
    ["G156", "SS", "OSILO", 1],
    ["G157", "AN", "OSIMO", 1],
    ["G158", "NU", "OSINI", 1],
    ["G159", "BG", "OSIO SOPRA", 1],
    ["G160", "BG", "OSIO SOTTO", 1],
    ["E529", "VA", "OSMATE", 0],
    ["E529", "VA", "OSMATE-LENTATE", 0],
    ["G161", "LC", "OSNAGO", 1],
    ["G162", "AP", "OSOLI", 0],
    ["G163", "UD", "OSOPPO", 1],
    ["G164", "IM", "OSPEDALETTI", 1],
    ["G165", "AV", "OSPEDALETTO", 0],
    ["G166", "MI", "OSPEDALETTO", 0],
    ["G167", "PD", "OSPEDALETTO", 0],
    ["G168", "TN", "OSPEDALETTO", 1],
    ["G165", "AV", "OSPEDALETTO D'ALPINOLO", 1],
    ["G167", "PD", "OSPEDALETTO EUGANEO", 1],
    ["G166", "LO", "OSPEDALETTO LODIGIANO", 1],
    ["G169", "BL", "OSPITALE", 0],
    ["G169", "BL", "OSPITALE DI CADORE", 1],
    ["G170", "BS", "OSPITALETTO", 1],
    ["G171", "LO", "OSSAGO LODIGIANO", 1],
    ["G172", "CR", "OSSALENGO", 0],
    ["G173", "TN", "OSSANA", 1],
    ["G174", "BG", "OSSANESGA", 0],
    ["G175", "GO", "OSSECCA VITTUGLIA", 0],
    ["G176", "GO", "OSSEGLIANO SAN MICHELE", 0],
    ["G177", "PL", "OSSERO", 0],
    ["G178", "SS", "OSSI", 1],
    ["G179", "BS", "OSSIMO", 1],
    ["G180", "CR", "OSSOLARO", 0],
    ["G181", "MI", "OSSONA", 1],
    ["G182", "CO", "OSSUCCIO", 0],
    ["G183", "CN", "OSTANA", 1],
    ["G184", "FE", "OSTELLATO", 1],
    ["G185", "CR", "OSTIANO", 1],
    ["G186", "MN", "OSTIGLIA", 1],
    ["F401", "AN", "OSTRA", 1],
    ["F581", "AN", "OSTRA VETERE", 1],
    ["G187", "BR", "OSTUNI", 1],
    ["G188", "LE", "OTRANTO", 1],
    ["G189", "TR", "OTRICOLI", 1],
    ["G190", "NA", "OTTAIANO", 0],
    ["G191", "NU", "OTTANA", 1],
    ["G192", "SA", "OTTATI", 1],
    ["G190", "NA", "OTTAVIANO", 1],
    ["G193", "AL", "OTTIGLIO", 1],
    ["G194", "PV", "OTTOBIANO", 1],
    ["G195", "PC", "OTTONE", 1],
    ["G196", "TO", "OULX", 1],
    ["G197", "AL", "OVADA", 1],
    ["G198", "UD", "OVARO", 1],
    ["G199", "AL", "OVIGLIO", 1],
    ["G200", "AQ", "OVINDOLI", 1],
    ["G201", "NU", "OVODDA", 1],
    ["G012", "AO", "OYACE", 1],
    ["G202", "TO", "OZEGNA", 1],
    ["G203", "SS", "OZIERI", 1],
    ["G204", "AL", "OZZANO", 0],
    ["G205", "BO", "OZZANO", 0],
    ["G205", "BO", "OZZANO DELL'EMILIA", 1],
    ["G204", "AL", "OZZANO DI ROBIANO", 0],
    ["G204", "AL", "OZZANO MONFERRATO", 1],
    ["G206", "MI", "OZZERO", 1],
    ["G207", "CA", "PABILLONIS", 0],
    ["G207", "SU", "PABILLONIS", 1],
    ["G209", "ME", "PACE DEL MELA", 1],
    ["G208", "TP", "PACECO", 1],
    ["G210", "AQ", "PACENTRO", 1],
    ["G211", "SR", "PACHINO", 1],
    ["G212", "PG", "PACIANO", 1],
    ["G213", "BS", "PADENGHE", 0],
    ["G213", "BS", "PADENGHE SUL GARDA", 1],
    ["G214", "TN", "PADERGNONE", 0],
    ["G215", "AL", "PADERNA", 1],
    ["G216", "BS", "PADERNELLO", 0],
    ["G217", "BS", "PADERNO", 0],
    ["G218", "CO", "PADERNO", 0],
    ["G219", "CR", "PADERNO", 0],
    ["G220", "MI", "PADERNO", 0],
    ["G221", "TV", "PADERNO", 0],
    ["G219", "CR", "PADERNO CREMONESE", 0],
    ["G218", "LC", "PADERNO D'ADDA", 1],
    ["G221", "TV", "PADERNO D'ASOLO", 0],
    ["G221", "TV", "PADERNO DEL GRAPPA", 0],
    ["G220", "MI", "PADERNO DUGNANO", 1],
    ["G219", "CR", "PADERNO FASOLARO", 0],
    ["G217", "BS", "PADERNO FRANCIACORTA", 1],
    ["G220", "MI", "PADERNO MILANESE", 0],
    ["G222", "CR", "PADERNO OSSOLARO", 0],
    ["G222", "CR", "PADERNO PONCHIELLI", 1],
    ["G223", "CO", "PADERNO ROBBIATE", 0],
    ["G224", "PD", "PADOVA", 1],
    ["G225", "SS", "PADRIA", 1],
    ["M301", "SS", "PADRU", 1],
    ["G226", "SA", "PADULA", 1],
    ["G227", "BN", "PADULI", 1],
    ["G228", "CN", "PAESANA", 1],
    ["G229", "TV", "PAESE", 1],
    ["Z126", "EE", "PAESI BASSI", 1],
    ["G230", "SA", "PAGANI", 1],
    ["G231", "AQ", "PAGANICA", 0],
    ["C782", "GR", "PAGANICO", 0],
    ["G232", "RI", "PAGANICO SABINO", 1],
    ["G233", "BG", "PAGAZZANO", 1],
    ["G234", "ME", "PAGLIARA", 1],
    ["G235", "AP", "PAGLIARE", 0],
    ["G236", "CN", "PAGLIERES", 0],
    ["G237", "CH", "PAGLIETA", 1],
    ["G238", "UD", "PAGNACCO", 1],
    ["G239", "CO", "PAGNANO", 0],
    ["G239", "CO", "PAGNANO VALSASSINA", 0],
    ["G240", "CN", "PAGNO", 1],
    ["G241", "LC", "PAGNONA", 1],
    ["G242", "AV", "PAGO", 0],
    ["G243", "BN", "PAGO", 0],
    ["G242", "AV", "PAGO DEL VALLO DI LAURO", 1],
    ["G243", "BN", "PAGO VEIANO", 1],
    ["G244", "MI", "PAINA", 0],
    ["G245", "PV", "PAIRANA", 0],
    ["G246", "BS", "PAISCO", 0],
    ["G247", "BS", "PAISCO LOVENO", 1],
    ["G248", "BS", "PAITONE", 1],
    ["Z236", "EE", "PAKISTAN", 1],
    ["G249", "BG", "PALADINA", 1],
    ["G250", "MO", "PALAGANO", 1],
    ["G251", "TA", "PALAGIANELLO", 1],
    ["G252", "TA", "PALAGIANO", 1],
    ["G253", "CT", "PALAGONIA", 1],
    ["G254", "PI", "PALAIA", 1],
    ["G255", "PR", "PALANZANO", 1],
    ["G256", "CO", "PALANZO", 0],
    ["G257", "CB", "PALATA", 1],
    ["G258", "SS", "PALAU", 1],
    ["Z734", "EE", "PALAU REPUBBLICA", 1],
    ["G259", "BG", "PALAZZAGO", 1],
    ["G260", "CR", "PALAZZO", 0],
    ["G261", "PZ", "PALAZZO", 0],
    ["G262", "TO", "PALAZZO", 0],
    ["G263", "PA", "PALAZZO ADRIANO", 1],
    ["G262", "TO", "PALAZZO CANAVESE", 1],
    ["G260", "CR", "PALAZZO PIGNANO", 1],
    ["G269", "TO", "PALAZZO PIVERONE", 0],
    ["G260", "CR", "PALAZZO PUGNANO", 0],
    ["G261", "PZ", "PALAZZO SAN GERVASIO", 1],
    ["C340", "FR", "PALAZZOLO", 0],
    ["G264", "BS", "PALAZZOLO", 0],
    ["G265", "MI", "PALAZZOLO", 0],
    ["G266", "NO", "PALAZZOLO", 0],
    ["G267", "SR", "PALAZZOLO", 0],
    ["G268", "UD", "PALAZZOLO", 0],
    ["G267", "SR", "PALAZZOLO ACREIDE", 1],
    ["G268", "UD", "PALAZZOLO DELLO STELLA", 1],
    ["C340", "CE", "PALAZZOLO DI CASTROCIELO", 0],
    ["G265", "MI", "PALAZZOLO MILANESE", 0],
    ["G264", "BS", "PALAZZOLO SULL'OGLIO", 1],
    ["G266", "VC", "PALAZZOLO VERCELLESE", 1],
    ["G270", "FI", "PALAZZUOLO", 0],
    ["G270", "FI", "PALAZZUOLO DI ROMAGNA", 0],
    ["G270", "FI", "PALAZZUOLO SUL SENIO", 1],
    ["G271", "CH", "PALENA", 1],
    ["G272", "CZ", "PALERMITI", 1],
    ["G273", "PA", "PALERMO", 1],
    ["G274", "RM", "PALESTRINA", 1],
    ["G275", "PV", "PALESTRO", 1],
    ["G276", "FR", "PALIANO", 1],
    ["G277", "RC", "PALIZZI", 1],
    ["G278", "KR", "PALLAGORIO", 1],
    ["G279", "NO", "PALLANZA", 0],
    ["G280", "VB", "PALLANZENO", 1],
    ["G281", "SV", "PALLARE", 1],
    ["G282", "AG", "PALMA", 0],
    ["G283", "CE", "PALMA", 0],
    ["G283", "NA", "PALMA CAMPANIA", 1],
    ["G282", "AG", "PALMA DI MONTECHIARO", 1],
    ["G284", "UD", "PALMANOVA", 1],
    ["G285", "LE", "PALMARIGGI", 1],
    ["G286", "CA", "PALMAS", 0],
    ["G287", "CA", "PALMAS", 0],
    ["G286", "OR", "PALMAS ARBOREA", 1],
    ["G287", "CA", "PALMAS SUERGIU", 0],
    ["G288", "RC", "PALMI", 1],
    ["G289", "AP", "PALMIANO", 1],
    ["G081", "PZ", "PALMIRA", 0],
    ["G290", "CH", "PALMOLI", 1],
    ["G291", "BA", "PALO", 0],
    ["G292", "SA", "PALO", 0],
    ["G291", "BA", "PALO DEL COLLE", 1],
    ["G293", "RM", "PALOMBARA", 0],
    ["G293", "RM", "PALOMBARA SABINA", 1],
    ["G294", "CH", "PALOMBARO", 1],
    ["G292", "SA", "PALOMONTE", 1],
    ["G295", "BG", "PALOSCO", 1],
    ["G296", "TN", "PALU'", 0],
    ["G297", "VR", "PALU'", 1],
    ["G296", "TN", "PALU' DEL FERSINA", 1],
    ["G298", "CS", "PALUDI", 1],
    ["G299", "BZ", "PALUS", 0],
    ["G300", "UD", "PALUZZA", 1],
    ["G301", "CR", "PALVARETO", 0],
    ["G302", "CN", "PAMPARATO", 1],
    ["Z516", "EE", "PANAMA", 1],
    ["Z517", "EE", "PANAMA ZONA DEL CANALE", 1],
    ["G303", "TO", "PANCALIERI", 1],
    ["G304", "PV", "PANCARANA", 1],
    ["G305", "TN", "PANCHIA'", 1],
    ["G306", "CR", "PANDINO", 1],
    ["G307", "CS", "PANETTIERI", 1],
    ["G308", "PG", "PANICALE", 1],
    ["G309", "NA", "PANICOCOLI", 0],
    ["G310", "GO", "PANIQUA", 0],
    ["G311", "BN", "PANNARANO", 1],
    ["G312", "FG", "PANNI", 1],
    ["G313", "TN", "PANNONE", 0],
    ["G314", "IM", "PANTASINA", 0],
    ["G315", "TP", "PANTELLERIA", 1],
    ["G316", "MI", "PANTIGLIATE", 1],
    ["G317", "CS", "PAOLA", 1],
    ["G318", "BN", "PAOLISI", 1],
    ["G319", "TP", "PAPARELLA SAN MARCO", 0],
    ["G320", "CS", "PAPASIDERO", 1],
    ["G321", "PV", "PAPIAGO", 0],
    ["G322", "TR", "PAPIGNO", 0],
    ["G323", "RO", "PAPOZZE", 1],
    ["Z730", "EE", "PAPUA NUOVA GUINEA", 1],
    ["Z720", "EE", "PAPUASIA", 1],
    ["G324", "MI", "PARABIAGO", 1],
    ["G325", "LE", "PARABITA", 1],
    ["G326", "RC", "PARACORIO", 0],
    ["Z610", "EE", "PARAGUAY", 1],
    ["G327", "BS", "PARATICO", 1],
    ["G328", "BZ", "PARCINES", 1],
    ["A239", "PA", "PARCO", 0],
    ["G329", "CO", "PARE'", 0],
    ["G330", "TO", "PARELLA", 1],
    ["G331", "CS", "PARENTI", 1],
    ["G332", "PL", "PARENZO", 0],
    ["G333", "CE", "PARETE", 1],
    ["G334", "AL", "PARETO", 1],
    ["G335", "VV", "PARGHELIA", 1],
    ["C782", "GR", "PARI", 0],
    ["G336", "LC", "PARLASCO", 1],
    ["G337", "PR", "PARMA", 1],
    ["G338", "AL", "PARODI", 0],
    ["G338", "AL", "PARODI LIGURE", 1],
    ["G339", "CN", "PAROLDO", 1],
    ["G340", "AV", "PAROLISE", 1],
    ["G341", "VR", "PARONA", 0],
    ["G342", "PV", "PARONA", 1],
    ["G341", "VR", "PARONA ALL'ADIGE", 0],
    ["G341", "VR", "PARONA DI VALPOLICELLA", 0],
    ["G343", "VC", "PARONE", 0],
    ["G344", "TR", "PARRANO", 1],
    ["G345", "CO", "PARRAVICINO", 0],
    ["G346", "BG", "PARRE", 1],
    ["G347", "TP", "PARTANNA", 1],
    ["G348", "PA", "PARTINICO", 1],
    ["G349", "NO", "PARUZZARO", 1],
    ["G350", "BG", "PARZANICA", 1],
    ["G351", "PG", "PASCELUPO", 0],
    ["G352", "UD", "PASIAN DI PRATO", 1],
    ["A700", "UD", "PASIAN SCHIAVONESCO", 0],
    ["G353", "PN", "PASIANO DI PORDENONE", 1],
    ["G354", "BS", "PASPARDO", 1],
    ["Z721", "EE", "PASQUA (ISOLA)", 1],
    ["G355", "CR", "PASSARERA", 0],
    ["G356", "UD", "PASSARIANO", 0],
    ["G357", "AL", "PASSERANO", 0],
    ["G358", "AT", "PASSERANO MARMORITO", 1],
    ["G359", "PG", "PASSIGNANO", 0],
    ["G359", "PG", "PASSIGNANO SUL TRASIMENO", 1],
    ["G360", "MI", "PASSIRANA", 0],
    ["G360", "MI", "PASSIRANA MILANESE", 0],
    ["G361", "BS", "PASSIRANO", 1],
    ["G362", "FR", "PASTENA", 1],
    ["G363", "BN", "PASTENE", 0],
    ["G364", "CE", "PASTORANO", 1],
    ["G365", "VR", "PASTRENGO", 1],
    ["G366", "MI", "PASTURAGO", 0],
    ["G367", "AL", "PASTURANA", 1],
    ["G368", "LC", "PASTURO", 1],
    ["G369", "AN", "PATERNO", 0],
    ["G370", "AV", "PATERNO", 0],
    ["G372", "CS", "PATERNO", 0],
    ["M269", "PZ", "PATERNO", 1],
    ["G372", "CS", "PATERNO CALABRO", 1],
    ["G369", "AN", "PATERNO D'ANCONA", 0],
    ["G371", "CT", "PATERNO'", 1],
    ["G370", "AV", "PATERNOPOLI", 1],
    ["G373", "TN", "PATONE", 0],
    ["G374", "FR", "PATRICA", 1],
    ["G375", "AP", "PATRIGNONE", 0],
    ["G376", "SS", "PATTADA", 1],
    ["G377", "ME", "PATTI", 1],
    ["G378", "LE", "PATU'", 1],
    ["G379", "OR", "PAU", 1],
    ["G380", "TS", "PAUGNANO", 0],
    ["G381", "UD", "PAULARO", 1],
    ["G382", "CA", "PAULI ARBAREI", 0],
    ["G382", "SU", "PAULI ARBAREI", 1],
    ["G383", "CA", "PAULI GERREI", 0],
    ["F383", "CA", "PAULI MONSERRATO", 0],
    ["F383", "CA", "PAULI PIRRI", 0],
    ["G384", "OR", "PAULILATINO", 1],
    ["G385", "MI", "PAULLO", 1],
    ["G386", "BN", "PAUPISI", 1],
    ["D042", "MC", "PAUSULA", 0],
    ["G387", "TO", "PAVAROLO", 1],
    ["G388", "PV", "PAVIA", 1],
    ["G389", "UD", "PAVIA", 0],
    ["G389", "UD", "PAVIA DI UDINE", 1],
    ["G390", "AL", "PAVONE", 0],
    ["G391", "BS", "PAVONE", 0],
    ["G392", "TO", "PAVONE", 0],
    ["G392", "TO", "PAVONE CANAVESE", 1],
    ["G390", "AL", "PAVONE D'ALESSANDRIA", 0],
    ["G391", "BS", "PAVONE DEL MELLA", 1],
    ["G393", "MO", "PAVULLO", 0],
    ["G393", "MO", "PAVULLO NEL FRIGNANO", 1],
    ["G394", "RC", "PAZZANO", 1],
    ["G395", "PI", "PECCIOLI", 1],
    ["G396", "TO", "PECCO", 0],
    ["G397", "AL", "PECETTO", 0],
    ["G397", "AL", "PECETTO DI VALENZA", 1],
    ["G398", "TO", "PECETTO TORINESE", 1],
    ["G399", "PC", "PECORARA", 0],
    ["G400", "CS", "PEDACE", 0],
    ["G401", "AO", "PEDANEA", 0],
    ["G402", "CT", "PEDARA", 1],
    ["G403", "AP", "PEDASO", 0],
    ["G403", "FM", "PEDASO", 1],
    ["G404", "BL", "PEDAVENA", 1],
    ["G405", "RC", "PEDAVOLI", 0],
    ["G406", "VI", "PEDEMONTE", 1],
    ["G407", "BS", "PEDERGNAGA", 0],
    ["G407", "BS", "PEDERGNAGA-ORIANO", 0],
    ["G408", "TV", "PEDEROBBA", 1],
    ["G409", "TN", "PEDERSANO", 0],
    ["G410", "SO", "PEDESINA", 1],
    ["G411", "CS", "PEDIVIGLIANO", 1],
    ["G412", "BG", "PEDRENGO", 1],
    ["G413", "MI", "PEDRIANO", 0],
    ["G414", "GE", "PEGLI", 0],
    ["G415", "CO", "PEGLIO", 1],
    ["G416", "PU", "PEGLIO", 1],
    ["G417", "MN", "PEGOGNAGA", 1],
    ["G418", "BG", "PEIA", 1],
    ["G419", "TN", "PEIO", 1],
    ["G419", "TN", "PEJO", 0],
    ["G420", "FI", "PELAGO", 1],
    ["G421", "NO", "PELLA", 1],
    ["G422", "RC", "PELLARO", 0],
    ["G423", "FI", "PELLEGRINO", 0],
    ["G424", "PR", "PELLEGRINO", 0],
    ["G423", "FI", "PELLEGRINO DA CAREGGI", 0],
    ["G424", "PR", "PELLEGRINO PARMENSE", 1],
    ["G425", "VE", "PELLESTRINA", 0],
    ["G426", "SA", "PELLEZZANO", 1],
    ["A910", "TO", "PELLICE", 0],
    ["G427", "CO", "PELLIO INTELVI", 0],
    ["G428", "TN", "PELLIZZANO", 1],
    ["G429", "TN", "PELUGO", 1],
    ["G430", "AT", "PENANGO", 1],
    ["G431", "SO", "PENDOLASCO", 0],
    ["G041", "IM", "PENNA", 0],
    ["G432", "TR", "PENNA", 0],
    ["G432", "TR", "PENNA IN TEVERINA", 1],
    ["G436", "MC", "PENNA SAN GIOVANNI", 1],
    ["G437", "TE", "PENNA SANT'ANDREA", 1],
    ["G433", "PU", "PENNABILLI", 0],
    ["G433", "RN", "PENNABILLI", 1],
    ["G434", "CH", "PENNADOMO", 1],
    ["G435", "CH", "PENNAPIEDIMONTE", 1],
    ["G438", "PE", "PENNE", 1],
    ["C999", "AQ", "PENTIMA", 0],
    ["G439", "CZ", "PENTONE", 1],
    ["G440", "CO", "PENZANO", 0],
    ["G464", "TN", "PERA", 0],
    ["G441", "CH", "PERANO", 1],
    ["G442", "BL", "PERAROLO", 0],
    ["G442", "BL", "PERAROLO DI CADORE", 1],
    ["G443", "BZ", "PERCA", 1],
    ["G444", "RM", "PERCILE", 1],
    ["G445", "NU", "PERDASDEFOGU", 1],
    ["G446", "CA", "PERDAXIUS", 0],
    ["G446", "SU", "PERDAXIUS", 1],
    ["G447", "SA", "PERDIFUMO", 1],
    ["G448", "LC", "PEREGO", 0],
    ["G449", "AQ", "PERETO", 1],
    ["G450", "SS", "PERFUGAS", 1],
    ["G443", "BZ", "PERGA", 0],
    ["G451", "AR", "PERGINE", 0],
    ["G452", "TN", "PERGINE", 0],
    ["G451", "AR", "PERGINE VALDARNO", 0],
    ["G452", "TN", "PERGINE VALSUGANA", 1],
    ["G453", "PU", "PERGOLA", 1],
    ["G454", "IM", "PERINALDO", 1],
    ["G455", "SA", "PERITO", 1],
    ["G456", "LC", "PERLEDO", 1],
    ["G457", "CN", "PERLETTO", 1],
    ["G458", "CN", "PERLO", 1],
    ["G459", "AO", "PERLOZ", 1],
    ["G460", "CN", "PERNO", 0],
    ["G461", "PD", "PERNUMIA", 1],
    ["C013", "MI", "PERO", 1],
    ["G462", "TO", "PEROSA", 0],
    ["G463", "TO", "PEROSA", 0],
    ["G463", "TO", "PEROSA ARGENTINA", 1],
    ["G462", "TO", "PEROSA CANAVESE", 1],
    ["G465", "TO", "PERRERO", 1],
    ["G466", "BN", "PERRILLO", 0],
    ["G467", "BO", "PERSICETO", 0],
    ["G468", "CR", "PERSICO", 0],
    ["G469", "CR", "PERSICO DOSIMO", 1],
    ["G470", "TN", "PERSONE", 0],
    ["G471", "VC", "PERTENGO", 1],
    ["G472", "UD", "PERTEOLE", 0],
    ["G473", "GE", "PERTI", 0],
    ["G474", "BS", "PERTICA ALTA", 1],
    ["G475", "BS", "PERTICA BASSA", 1],
    ["G476", "SA", "PERTOSA", 1],
    ["G477", "TO", "PERTUSIO", 1],
    ["Z611", "EE", "PERU'", 1],
    ["G478", "PG", "PERUGIA", 1],
    ["G479", "PU", "PESARO", 1],
    ["G480", "LU", "PESCAGLIA", 1],
    ["G481", "VR", "PESCANTINA", 1],
    ["G482", "PE", "PESCARA", 1],
    ["G483", "CR", "PESCAROLO", 0],
    ["G483", "CR", "PESCAROLO ED UNITI", 1],
    ["G484", "AQ", "PESCASSEROLI", 1],
    ["G485", "LC", "PESCATE", 1],
    ["G486", "IS", "PESCHE", 1],
    ["G487", "FG", "PESCHICI", 1],
    ["G488", "MI", "PESCHIERA", 0],
    ["G489", "VR", "PESCHIERA", 0],
    ["G488", "MI", "PESCHIERA BORROMEO", 1],
    ["G490", "BS", "PESCHIERA D'ISEO", 0],
    ["G489", "VR", "PESCHIERA DEL GARDA", 1],
    ["G490", "BS", "PESCHIERA MARAGLIO", 0],
    ["G489", "VR", "PESCHIERA SUL LAGO DI GARDA", 0],
    ["G491", "PT", "PESCIA", 1],
    ["G492", "AQ", "PESCINA", 1],
    ["G494", "BN", "PESCO SANNITA", 1],
    ["G493", "AQ", "PESCOCOSTANZO", 1],
    ["G494", "BN", "PESCOLAMAZZA", 0],
    ["G495", "IS", "PESCOLANCIANO", 1],
    ["G496", "PZ", "PESCOPAGANO", 1],
    ["G497", "IS", "PESCOPENNATARO", 1],
    ["G498", "RI", "PESCOROCCHIANO", 1],
    ["G499", "PE", "PESCOSANSONESCO", 1],
    ["G500", "FR", "PESCOSOLIDO", 1],
    ["G501", "MI", "PESSANO", 0],
    ["G502", "MI", "PESSANO CON BORNAGO", 1],
    ["G503", "CO", "PESSINA", 0],
    ["G504", "CR", "PESSINA", 0],
    ["G504", "CR", "PESSINA CREMONESE", 1],
    ["G504", "CR", "PESSINA CREMONESE ED UNITI", 0],
    ["G503", "CO", "PESSINA VALSASSINA", 0],
    ["G505", "TO", "PESSINETTO", 1],
    ["G506", "CB", "PETACCIATO", 1],
    ["G507", "RI", "PETESCIA", 0],
    ["G508", "KR", "PETILIA POLICASTRO", 1],
    ["G509", "SA", "PETINA", 1],
    ["G510", "PA", "PETRALIA SOPRANA", 1],
    ["G511", "PA", "PETRALIA SOTTANA", 1],
    ["G512", "CB", "PETRELLA", 0],
    ["G513", "RI", "PETRELLA", 0],
    ["G513", "RI", "PETRELLA SALTO", 1],
    ["G512", "CB", "PETRELLA TIFERNINA", 1],
    ["G514", "PU", "PETRIANO", 1],
    ["G515", "MC", "PETRIOLO", 1],
    ["G516", "AP", "PETRITOLI", 0],
    ["G516", "FM", "PETRITOLI", 1],
    ["G517", "CZ", "PETRIZZI", 1],
    ["G518", "CZ", "PETRONA'", 1],
    ["M281", "TP", "PETROSINO", 1],
    ["G519", "AV", "PETRURO", 0],
    ["G519", "AV", "PETRURO IRPINO", 1],
    ["G520", "NO", "PETTENASCO", 1],
    ["G521", "BI", "PETTINENGO", 1],
    ["G522", "ME", "PETTINEO", 1],
    ["G523", "IS", "PETTORANELLO DEL MOLISE", 1],
    ["G523", "CB", "PETTORANELLO DI MOLISE", 0],
    ["G523", "CB", "PETTORANO", 0],
    ["G524", "AQ", "PETTORANO", 0],
    ["G524", "AQ", "PETTORANO SUL GIZIO", 1],
    ["G525", "RO", "PETTORAZZA GRIMANI", 1],
    ["G526", "CN", "PEVERAGNO", 1],
    ["G527", "MI", "PEVERANZA", 0],
    ["G528", "VC", "PEZZANA", 1],
    ["G529", "BS", "PEZZAZE", 1],
    ["G530", "MI", "PEZZOLO DE' CODAZZI", 0],
    ["G531", "MI", "PEZZOLO DI TAVAZZANO", 0],
    ["G532", "CN", "PEZZOLO VALLE UZZONE", 1],
    ["G533", "BS", "PEZZORO", 0],
    ["G534", "PD", "PIACENZA", 0],
    ["G535", "PC", "PIACENZA", 1],
    ["G534", "PD", "PIACENZA D'ADIGE", 1],
    ["G536", "CR", "PIADENA", 0],
    ["M418", "CR", "PIADENA DRIZZONA", 1],
    ["G537", "PU", "PIAGGE", 0],
    ["G538", "SA", "PIAGGINE", 1],
    ["G539", "SA", "PIAGGINE SOPRANE", 0],
    ["G540", "SA", "PIAGGINE SOTTANE", 0],
    ["G546", "BS", "PIAN CAMUNO", 1],
    ["G550", "BS", "PIAN D'ARTOGNE", 0],
    ["F136", "PU", "PIAN DI CASTELLO", 0],
    ["G552", "AR", "PIAN DI SCO", 0],
    ["G562", "BG", "PIAN GAIANO", 0],
    ["G541", "CE", "PIANA", 0],
    ["G542", "GE", "PIANA", 0],
    ["G542", "SV", "PIANA CRIXIA", 1],
    ["G543", "PA", "PIANA DEGLI ALBANESI", 1],
    ["G543", "PA", "PIANA DEI GRECI", 0],
    ["G541", "CE", "PIANA DI CAIAZZO", 0],
    ["G541", "CE", "PIANA DI MONTE VERNA", 1],
    ["G544", "IM", "PIANAVIA", 0],
    ["G545", "AO", "PIANBOSETO", 0],
    ["G547", "SI", "PIANCASTAGNAIO", 1],
    ["G548", "VC", "PIANCERI", 0],
    ["G549", "BS", "PIANCOGNO", 1],
    ["G551", "PU", "PIANDIMELETO", 1],
    ["G553", "CS", "PIANE", 0],
    ["G554", "NO", "PIANE", 0],
    ["G553", "CS", "PIANE CRATI", 1],
    ["G554", "NO", "PIANE DI SERRAVALLE SESIA", 0],
    ["G554", "NO", "PIANE SESIA", 0],
    ["G555", "PE", "PIANELLA", 1],
    ["G556", "CO", "PIANELLO", 0],
    ["G557", "PC", "PIANELLO", 0],
    ["G556", "CO", "PIANELLO DEL LARIO", 1],
    ["G557", "PC", "PIANELLO VAL TIDONE", 1],
    ["G558", "CR", "PIANENGO", 1],
    ["G559", "TO", "PIANEZZA", 1],
    ["G560", "VI", "PIANEZZE", 1],
    ["G561", "CN", "PIANFEI", 1],
    ["G563", "IM", "PIANI", 0],
    ["G564", "BG", "PIANICO", 1],
    ["G565", "VE", "PIANIGA", 1],
    ["G546", "BS", "PIANO", 0],
    ["G566", "BO", "PIANO", 0],
    ["G567", "CO", "PIANO", 0],
    ["G568", "NA", "PIANO", 0],
    ["G569", "TN", "PIANO", 0],
    ["G566", "BO", "PIANO DEL VOGLIO", 0],
    ["G568", "NA", "PIANO DI SORRENTO", 1],
    ["F766", "BZ", "PIANO IN PASSIRIA", 0],
    ["G567", "CO", "PIANO PORLEZZA", 0],
    ["D546", "CZ", "PIANOPOLI", 1],
    ["G570", "BO", "PIANORO", 1],
    ["G571", "VT", "PIANSANO", 1],
    ["G572", "SO", "PIANTEDO", 1],
    ["G573", "NA", "PIANURA", 0],
    ["G574", "BG", "PIARIO", 1],
    ["G575", "CN", "PIASCO", 1],
    ["G576", "SO", "PIATEDA", 1],
    ["G577", "BI", "PIATTO", 1],
    ["G578", "TV", "PIAVON", 0],
    ["G579", "BG", "PIAZZA", 0],
    ["G580", "CL", "PIAZZA", 0],
    ["G581", "CO", "PIAZZA", 0],
    ["G582", "MS", "PIAZZA", 0],
    ["G582", "LU", "PIAZZA AL SERCHIO", 1],
    ["G580", "EN", "PIAZZA ARMERINA", 1],
    ["G579", "BG", "PIAZZA BREMBANA", 1],
    ["G581", "CO", "PIAZZA SANTO STEFANO", 0],
    ["G583", "BG", "PIAZZATORRE", 1],
    ["G584", "TO", "PIAZZO", 0],
    ["G585", "BG", "PIAZZO ALTO", 0],
    ["G586", "BG", "PIAZZO BASSO", 0],
    ["G587", "PD", "PIAZZOLA", 0],
    ["G587", "PD", "PIAZZOLA SUL BRENTA", 1],
    ["G588", "BG", "PIAZZOLO", 1],
    ["G589", "PE", "PICCIANO", 1],
    ["G590", "PZ", "PICERNO", 1],
    ["G591", "FR", "PICINISCO", 1],
    ["G592", "FR", "PICO", 1],
    ["G593", "AT", "PIEA", 1],
    ["G594", "BI", "PIEDICAVALLO", 1],
    ["G595", "TR", "PIEDILUCO", 0],
    ["G596", "CE", "PIEDIMONTE", 0],
    ["G597", "CT", "PIEDIMONTE", 0],
    ["G598", "FR", "PIEDIMONTE", 0],
    ["G596", "CE", "PIEDIMONTE D'ALIFE", 0],
    ["G599", "GO", "PIEDIMONTE DEL CALVARIO", 0],
    ["G597", "CT", "PIEDIMONTE ETNEO", 1],
    ["G596", "CE", "PIEDIMONTE MATESE", 1],
    ["G598", "FR", "PIEDIMONTE SAN GERMANO", 1],
    ["G600", "VB", "PIEDIMULERA", 1],
    ["F090", "PG", "PIEDIPATERNO SUL NERA", 0],
    ["G601", "PG", "PIEGARO", 1],
    ["M296", "PL", "PIEMONTE D'ISTRIA", 0],
    ["G041", "IM", "PIENA", 0],
    ["G602", "SI", "PIENZA", 1],
    ["G603", "CR", "PIERANICA", 1],
    ["G604", "FG", "PIETRA", 0],
    ["G605", "GE", "PIETRA", 0],
    ["G612", "PV", "PIETRA DE' GIORGI", 1],
    ["G617", "PV", "PIETRA GAVINA", 0],
    ["G605", "SV", "PIETRA LIGURE", 1],
    ["G619", "AL", "PIETRA MARAZZI", 1],
    ["G606", "IS", "PIETRABBONDANTE", 1],
    ["G607", "IM", "PIETRABRUNA", 1],
    ["G608", "TE", "PIETRACAMELA", 1],
    ["G609", "CB", "PIETRACATELLA", 1],
    ["G610", "CB", "PIETRACUPA", 1],
    ["G611", "AV", "PIETRADEFUSI", 1],
    ["G613", "CH", "PIETRAFERRAZZANA", 1],
    ["G614", "PZ", "PIETRAFESA", 0],
    ["G615", "CS", "PIETRAFITTA", 1],
    ["G616", "PZ", "PIETRAGALLA", 1],
    ["G618", "PG", "PIETRALUNGA", 1],
    ["C795", "CS", "PIETRAMALA", 0],
    ["G620", "CE", "PIETRAMELARA", 1],
    ["G604", "FG", "PIETRAMONTECORVINO", 1],
    ["G621", "PE", "PIETRANICO", 1],
    ["G622", "CS", "PIETRAPAOLA", 1],
    ["G623", "PZ", "PIETRAPERTOSA", 1],
    ["G624", "EN", "PIETRAPERZIA", 1],
    ["G625", "CN", "PIETRAPORZIO", 1],
    ["G626", "BN", "PIETRAROJA", 1],
    ["G627", "PU", "PIETRARUBBIA", 1],
    ["G628", "LU", "PIETRASANTA", 1],
    ["G629", "AV", "PIETRASTORNINA", 1],
    ["G630", "CE", "PIETRAVAIRANO", 1],
    ["G631", "BN", "PIETRELCINA", 1],
    ["G632", "IM", "PIEVE", 0],
    ["G633", "MN", "PIEVE", 0],
    ["G634", "MI", "PIEVE", 0],
    ["G636", "PT", "PIEVE A NIEVOLE", 1],
    ["G635", "PV", "PIEVE ALBIGNOLA", 1],
    ["G638", "BL", "PIEVE D'ALPAGO", 0],
    ["G647", "CR", "PIEVE D'OLMI", 1],
    ["G639", "PV", "PIEVE DEL CAIRO", 1],
    ["M422", "TV", "PIEVE DEL GRAPPA", 1],
    ["G640", "CR", "PIEVE DELMONA", 0],
    ["G641", "TN", "PIEVE DI BONO", 0],
    ["M365", "TN", "PIEVE DI BONO-PREZZO", 1],
    ["G642", "BL", "PIEVE DI CADORE", 1],
    ["G643", "BO", "PIEVE DI CENTO", 1],
    ["G633", "MN", "PIEVE DI CORIANO", 0],
    ["G644", "TN", "PIEVE DI LEDRO", 0],
    ["G645", "TV", "PIEVE DI SOLIGO", 1],
    ["G646", "GE", "PIEVE DI SORI", 0],
    ["G632", "IM", "PIEVE DI TECO", 1],
    ["G634", "MI", "PIEVE EMANUELE", 1],
    ["G096", "LO", "PIEVE FISSIRAGA", 1],
    ["G648", "LU", "PIEVE FOSCIANA", 1],
    ["G646", "GE", "PIEVE LIGURE", 1],
    ["G650", "PV", "PIEVE PORTO MORONE", 1],
    ["G651", "CR", "PIEVE SAN GIACOMO", 1],
    ["G652", "CR", "PIEVE SAN MAURIZIO", 0],
    ["G654", "RE", "PIEVE SAN VINCENZO", 0],
    ["G653", "AR", "PIEVE SANTO STEFANO", 1],
    ["G655", "CR", "PIEVE TERZAGNI", 0],
    ["G656", "TN", "PIEVE TESINO", 1],
    ["G657", "MC", "PIEVE TORINA", 1],
    ["G658", "VB", "PIEVE VERGONTE", 1],
    ["G637", "MC", "PIEVEBOVIGLIANA", 0],
    ["G649", "MO", "PIEVEPELAGO", 1],
    ["G659", "FR", "PIGLIO", 1],
    ["G660", "IM", "PIGNA", 1],
    ["G661", "CE", "PIGNATARO", 0],
    ["G662", "FR", "PIGNATARO", 0],
    ["G662", "FR", "PIGNATARO INTERAMNA", 1],
    ["G661", "CE", "PIGNATARO MAGGIORE", 1],
    ["G663", "PZ", "PIGNOLA", 1],
    ["G663", "PZ", "PIGNOLA DI BASILICATA", 0],
    ["G664", "SP", "PIGNONE", 1],
    ["G665", "CO", "PIGRA", 1],
    ["G666", "VC", "PILA", 1],
    ["G667", "TN", "PILCANTE", 0],
    ["G668", "BS", "PILZONE", 0],
    ["G669", "CA", "PIMENTEL", 0],
    ["G669", "SU", "PIMENTEL", 1],
    ["G670", "NA", "PIMONTE", 1],
    ["G671", "PV", "PINAROLO", 0],
    ["G671", "PV", "PINAROLO PO", 1],
    ["G672", "TO", "PINASCA", 1],
    ["G673", "RO", "PINCARA", 1],
    ["G674", "TO", "PINEROLO", 1],
    ["F831", "TE", "PINETO", 1],
    ["G675", "PL", "PINGUENTE", 0],
    ["G676", "AL", "PINO", 0],
    ["G677", "CO", "PINO", 0],
    ["G676", "AT", "PINO D'ASTI", 1],
    ["G678", "TO", "PINO DI CHIERI", 0],
    ["G677", "VA", "PINO SULLA SPONDA DEL LAGO MAGGIORE", 0],
    ["G678", "TO", "PINO TORINESE", 1],
    ["G679", "MI", "PINZANO", 0],
    ["G680", "UD", "PINZANO", 0],
    ["G680", "PN", "PINZANO AL TAGLIAMENTO", 1],
    ["G681", "TN", "PINZOLO", 1],
    ["G682", "PU", "PIOBBICO", 1],
    ["G683", "CN", "PIOBESI", 0],
    ["G684", "TO", "PIOBESI", 0],
    ["G683", "CN", "PIOBESI D'ALBA", 1],
    ["G684", "TO", "PIOBESI TORINESE", 1],
    ["G685", "VC", "PIODE", 1],
    ["G686", "MI", "PIOLTELLO", 1],
    ["G687", "LI", "PIOMBINO", 1],
    ["G688", "PD", "PIOMBINO", 0],
    ["G688", "PD", "PIOMBINO DESE", 1],
    ["G689", "PR", "PIONE", 0],
    ["G690", "MC", "PIORACO", 1],
    ["G691", "TO", "PIOSSASCO", 1],
    ["G692", "AT", "PIOVA'", 0],
    ["G692", "AT", "PIOVA' MASSAIA", 1],
    ["G693", "PD", "PIOVE DI SACCO", 1],
    ["G694", "VI", "PIOVENE", 0],
    ["G694", "VI", "PIOVENE ROCCHETTE", 1],
    ["G695", "AL", "PIOVERA", 0],
    ["G696", "PC", "PIOZZANO", 1],
    ["G697", "CN", "PIOZZO", 1],
    ["G698", "RM", "PIPERNO", 0],
    ["G699", "ME", "PIRAINO", 1],
    ["G700", "PL", "PIRANO", 0],
    ["G701", "CA", "PIRRI", 0],
    ["G702", "PI", "PISA", 1],
    ["G703", "NO", "PISANO", 1],
    ["G704", "RM", "PISCIANO", 0],
    ["G705", "TO", "PISCINA", 1],
    ["M291", "CA", "PISCINAS", 0],
    ["M291", "SU", "PISCINAS", 1],
    ["G706", "NA", "PISCINOLA", 0],
    ["G707", "SA", "PISCIOTTA", 1],
    ["G708", "CZ", "PISCOPIO", 0],
    ["G709", "PL", "PISINO", 0],
    ["G710", "BS", "PISOGNE", 1],
    ["G704", "RM", "PISONIANO", 1],
    ["G711", "PV", "PISSARELLO", 0],
    ["G712", "MT", "PISTICCI", 1],
    ["G713", "PT", "PISTOIA", 1],
    ["G714", "BI", "PISTOLESA", 0],
    ["Z722", "EE", "PITCAIRN (E DIPENDENZE)", 1],
    ["G715", "PT", "PITEGLIO", 0],
    ["G716", "GR", "PITIGLIANO", 1],
    ["G717", "MN", "PIUBEGA", 1],
    ["G718", "SO", "PIURO", 1],
    ["G719", "TO", "PIVERONE", 1],
    ["G720", "PV", "PIZZALE", 1],
    ["G721", "CR", "PIZZIGHETTONE", 1],
    ["G722", "VV", "PIZZO", 1],
    ["G723", "PV", "PIZZOCORNO", 0],
    ["G724", "CH", "PIZZOFERRATO", 1],
    ["G725", "MI", "PIZZOLANO", 0],
    ["G726", "AQ", "PIZZOLI", 1],
    ["G727", "IS", "PIZZONE", 1],
    ["G728", "VV", "PIZZONI", 1],
    ["G729", "RC", "PLACANICA", 1],
    ["G730", "BZ", "PLAGNOLO", 0],
    ["G731", "GO", "PLANINA", 0],
    ["G730", "BZ", "PLANOL", 0],
    ["G732", "BZ", "PLATA", 0],
    ["G733", "CS", "PLATACI", 1],
    ["G734", "CZ", "PLATANIA", 1],
    ["G732", "BZ", "PLATEA", 0],
    ["G735", "RC", "PLATI'", 1],
    ["G736", "UD", "PLATISCHIS", 0],
    ["G299", "BZ", "PLAUS", 1],
    ["G737", "CO", "PLESIO", 1],
    ["G738", "GO", "PLEZZO", 0],
    ["G739", "GO", "PLISCOVIZZA DELLA MADONNA", 0],
    ["G740", "SS", "PLOAGHE", 1],
    ["G741", "SV", "PLODIO", 1],
    ["G742", "CN", "POCAPAGLIA", 1],
    ["G743", "UD", "POCENIA", 1],
    ["G744", "GO", "POCRAI DEL PERO", 0],
    ["G745", "RC", "PODARGONI", 0],
    ["G746", "MS", "PODENZANA", 1],
    ["G747", "PC", "PODENZANO", 1],
    ["G599", "UD", "PODGORA", 0],
    ["G748", "GO", "PODRAGA", 0],
    ["G749", "FR", "POFI", 1],
    ["G750", "IM", "POGGI", 0],
    ["G751", "LE", "POGGIARDO", 1],
    ["G752", "SI", "POGGIBONSI", 1],
    ["G753", "MN", "POGGIO", 0],
    ["G754", "PO", "POGGIO A CAIANO", 1],
    ["G755", "RN", "POGGIO BERNI", 0],
    ["G756", "RI", "POGGIO BUSTONE", 1],
    ["G757", "RI", "POGGIO CATINO", 1],
    ["G759", "RI", "POGGIO FIDONI", 0],
    ["G761", "FG", "POGGIO IMPERIALE", 1],
    ["G763", "RI", "POGGIO MIRTETO", 1],
    ["G764", "RI", "POGGIO MOIANO", 1],
    ["G765", "RI", "POGGIO NATIVO", 1],
    ["G766", "AQ", "POGGIO PICENZE", 1],
    ["G768", "FE", "POGGIO RENATICO", 1],
    ["G753", "MN", "POGGIO RUSCO", 1],
    ["G770", "RI", "POGGIO SAN LORENZO", 1],
    ["G771", "AN", "POGGIO SAN MARCELLO", 1],
    ["D566", "MC", "POGGIO SAN VICINO", 1],
    ["B317", "IS", "POGGIO SANNITA", 1],
    ["M324", "RN", "POGGIO TORRIANA", 1],
    ["G758", "PG", "POGGIODOMO", 1],
    ["G760", "CH", "POGGIOFIORITO", 1],
    ["G762", "NA", "POGGIOMARINO", 1],
    ["G767", "TP", "POGGIOREALE", 1],
    ["G769", "BA", "POGGIORSINI", 1],
    ["G431", "SO", "POGGIRIDENTI", 1],
    ["G772", "MI", "POGLIANO", 0],
    ["G772", "MI", "POGLIANO MILANESE", 1],
    ["G773", "CO", "POGNANA", 0],
    ["G773", "CO", "POGNANA LARIO", 1],
    ["G774", "BG", "POGNANO", 1],
    ["G775", "NO", "POGNO", 1],
    ["M241", "TN", "POIA", 0],
    ["G776", "VI", "POIANA MAGGIORE", 0],
    ["G777", "TO", "POIRINO", 1],
    ["G776", "VI", "POJANA MAGGIORE", 1],
    ["G778", "PL", "POLA", 0],
    ["G779", "BS", "POLAVENO", 1],
    ["G780", "PN", "POLCENIGO", 1],
    ["G781", "CR", "POLENGO", 0],
    ["G782", "RO", "POLESELLA", 1],
    ["G783", "PR", "POLESINE", 0],
    ["G783", "PR", "POLESINE PARMENSE", 0],
    ["M367", "PR", "POLESINE ZIBELLO", 1],
    ["G784", "RM", "POLI", 1],
    ["G785", "VV", "POLIA", 1],
    ["G508", "CZ", "POLICASTRO", 0],
    ["B657", "SA", "POLICASTRO DEL GOLFO", 0],
    ["G786", "MT", "POLICORO", 1],
    ["G787", "BA", "POLIGNANO", 0],
    ["G788", "PC", "POLIGNANO", 0],
    ["G787", "BA", "POLIGNANO A MARE", 1],
    ["G788", "PC", "POLIGNANO PIACENTINO", 0],
    ["G789", "MO", "POLINAGO", 1],
    ["Z723", "EE", "POLINESIA FRANCESE (ISOLE)", 1],
    ["G790", "TR", "POLINO", 1],
    ["G791", "RC", "POLISTENA", 1],
    ["G792", "PA", "POLIZZI", 0],
    ["G792", "PA", "POLIZZI GENEROSA", 1],
    ["G793", "SA", "POLLA", 1],
    ["G794", "AO", "POLLEIN", 1],
    ["G795", "NA", "POLLENA TROCCHIA", 1],
    ["F567", "MC", "POLLENZA", 1],
    ["G796", "SA", "POLLICA", 1],
    ["G797", "PA", "POLLINA", 1],
    ["G798", "BI", "POLLONE", 1],
    ["G799", "CH", "POLLUTRI", 1],
    ["G800", "CN", "POLONGHERA", 1],
    ["Z127", "EE", "POLONIA", 1],
    ["G801", "BS", "POLPENAZZE DEL GARDA", 1],
    ["G802", "PD", "POLVERARA", 1],
    ["G803", "AN", "POLVERIGI", 1],
    ["G804", "PI", "POMARANCE", 1],
    ["G805", "TO", "POMARETTO", 1],
    ["G806", "MT", "POMARICO", 1],
    ["G696", "PC", "POMARO", 0],
    ["G807", "AL", "POMARO", 0],
    ["G807", "AL", "POMARO MONFERRATO", 1],
    ["G696", "PC", "POMARO PIACENTINO", 0],
    ["G808", "TN", "POMAROLO", 1],
    ["G809", "NO", "POMBIA", 1],
    ["G810", "PV", "POMETO", 0],
    ["G811", "RM", "POMEZIA", 1],
    ["G812", "NA", "POMIGLIANO D'ARCO", 1],
    ["D790", "NA", "POMIGLIANO D'ATELLA", 0],
    ["G813", "NA", "POMPEI", 1],
    ["G814", "IM", "POMPEIANA", 1],
    ["G815", "BS", "POMPIANO", 1],
    ["G816", "MN", "POMPONESCO", 1],
    ["G817", "CA", "POMPU", 0],
    ["G817", "OR", "POMPU", 1],
    ["G818", "BS", "PONCARALE", 1],
    ["G819", "BS", "PONCARALE FLERO", 0],
    ["G820", "BI", "PONDERANO", 1],
    ["G821", "CO", "PONNA", 1],
    ["G822", "PI", "PONSACCO", 1],
    ["G823", "PD", "PONSO", 1],
    ["G545", "AO", "PONT BOZET", 0],
    ["G826", "TO", "PONT CANAVESE", 0],
    ["G826", "TO", "PONT-CANAVESE", 1],
    ["G854", "AO", "PONT-SAINT-MARTIN", 1],
    ["G824", "BS", "PONTAGNA", 0],
    ["G825", "FI", "PONTASSIEVE", 1],
    ["G545", "AO", "PONTBOSET", 1],
    ["G827", "BN", "PONTE", 1],
    ["G828", "CO", "PONTE", 0],
    ["G829", "SO", "PONTE", 0],
    ["G830", "BZ", "PONTE ALL'ISARCO", 0],
    ["G833", "PT", "PONTE BUGGIANESE", 1],
    ["G835", "PV", "PONTE CARATE", 0],
    ["B589", "PD", "PONTE CASALE", 0],
    ["G842", "PC", "PONTE DELL'OLIO", 1],
    ["G844", "BS", "PONTE DI LEGNO", 1],
    ["G845", "BG", "PONTE DI NOSSA", 0],
    ["G846", "TV", "PONTE DI PIAVE", 1],
    ["G830", "BZ", "PONTE GARDENA", 1],
    ["G829", "SO", "PONTE IN VALTELLINA", 1],
    ["G847", "CO", "PONTE LAMBRO", 1],
    ["B662", "BL", "PONTE NELLE ALPI", 1],
    ["G851", "PV", "PONTE NIZZA", 1],
    ["F941", "BG", "PONTE NOSSA", 1],
    ["G854", "AO", "PONTE SAN MARTINO", 0],
    ["G855", "PD", "PONTE SAN NICOLO'", 1],
    ["G856", "BG", "PONTE SAN PIETRO", 1],
    ["G831", "UD", "PONTEBBA", 1],
    ["G832", "UD", "PONTEBBA NOVA", 0],
    ["G834", "SA", "PONTECAGNANO FAIANO", 1],
    ["G836", "RO", "PONTECCHIO", 0],
    ["G836", "RO", "PONTECCHIO POLESINE", 1],
    ["G837", "CN", "PONTECHIANALE", 1],
    ["G838", "FR", "PONTECORVO", 1],
    ["G839", "AL", "PONTECURONE", 1],
    ["G840", "IM", "PONTEDASSIO", 1],
    ["G841", "GE", "PONTEDECIMO", 0],
    ["G843", "PI", "PONTEDERA", 1],
    ["G832", "UD", "PONTEFELLA", 0],
    ["G848", "BN", "PONTELANDOLFO", 1],
    ["G849", "CE", "PONTELATONE", 1],
    ["G847", "CO", "PONTELEZZA", 0],
    ["G850", "PD", "PONTELONGO", 1],
    ["G852", "PC", "PONTENURE", 1],
    ["G853", "BG", "PONTERANICA", 1],
    ["G857", "MI", "PONTESESTO", 0],
    ["G858", "AL", "PONTESTURA", 1],
    ["G859", "BS", "PONTEVICO", 1],
    ["G860", "AO", "PONTEY", 1],
    ["G861", "AL", "PONTI", 1],
    ["G862", "VR", "PONTI", 0],
    ["G862", "MN", "PONTI SUL MINCIO", 1],
    ["G863", "NA", "PONTICELLI", 0],
    ["G864", "BG", "PONTIDA", 1],
    ["G865", "LT", "PONTINIA", 1],
    ["G866", "SV", "PONTINVREA", 1],
    ["G867", "BG", "PONTIROLO", 0],
    ["G868", "CR", "PONTIROLO", 0],
    ["G868", "CR", "PONTIROLO CAPREDONI", 0],
    ["G867", "BG", "PONTIROLO NUOVO", 1],
    ["G864", "BG", "PONTITA", 0],
    ["G869", "BS", "PONTOGLIO", 1],
    ["G870", "MS", "PONTREMOLI", 1],
    ["A370", "RM", "PONZA", 0],
    ["G871", "LT", "PONZA", 1],
    ["A370", "RM", "PONZA D'ARCINAZZO", 0],
    ["G872", "AL", "PONZANO", 0],
    ["G873", "AP", "PONZANO", 0],
    ["G874", "RM", "PONZANO", 0],
    ["G875", "TV", "PONZANO", 0],
    ["G873", "AP", "PONZANO DI FERMO", 0],
    ["G873", "FM", "PONZANO DI FERMO", 1],
    ["G872", "AL", "PONZANO MONFERRATO", 1],
    ["G874", "RM", "PONZANO ROMANO", 1],
    ["G875", "TV", "PONZANO VENETO", 1],
    ["G876", "CO", "PONZATE", 0],
    ["G877", "AL", "PONZONE", 1],
    ["G878", "PE", "POPOLI", 1],
    ["G879", "AR", "POPPI", 1],
    ["G880", "TN", "POR", 0],
    ["G881", "TR", "PORANO", 1],
    ["G882", "LU", "PORCARI", 1],
    ["G883", "AP", "PORCHIA", 0],
    ["G884", "TR", "PORCHIANO", 0],
    ["G885", "AP", "PORCHIANO ASCOLI", 0],
    ["G884", "TR", "PORCHIANO DEL MONTE", 0],
    ["G885", "AP", "PORCHIANO DELL'ASCENSIONE", 0],
    ["G886", "PN", "PORCIA", 1],
    ["G887", "SA", "PORCILI", 0],
    ["G888", "PN", "PORDENONE", 1],
    ["G889", "CO", "PORLEZZA", 1],
    ["G890", "IM", "PORNASSIO", 1],
    ["G891", "UD", "PORPETTO", 1],
    ["A558", "BO", "PORRETTA", 0],
    ["A558", "BO", "PORRETTA TERME", 0],
    ["G892", "FI", "PORTA AL BORGO", 0],
    ["G893", "FI", "PORTA CARRATICA", 0],
    ["E470", "AO", "PORTA LITTORIA", 0],
    ["G896", "FI", "PORTA LUCCHESE", 0],
    ["G897", "CR", "PORTA OMBRIANO", 0],
    ["G899", "FI", "PORTA SAN MARCO", 0],
    ["G894", "AT", "PORTACOMARO", 1],
    ["G895", "PV", "PORTALBERA", 1],
    ["G898", "PG", "PORTARIA", 0],
    ["G900", "TO", "PORTE", 1],
    ["M358", "TN", "PORTE DI RENDENA", 1],
    ["G901", "BS", "PORTESE", 0],
    ["G902", "NA", "PORTICI", 1],
    ["G903", "CE", "PORTICO", 0],
    ["G904", "FI", "PORTICO", 0],
    ["G903", "CE", "PORTICO DI CASERTA", 1],
    ["G904", "FI", "PORTICO DI ROMAGNA", 0],
    ["G904", "FC", "PORTICO E SAN BENEDETTO", 1],
    ["G905", "RC", "PORTIGLIOLA", 1],
    ["E822", "GE", "PORTIO", 0],
    ["G906", "CO", "PORTO", 0],
    ["G907", "CO", "PORTO", 0],
    ["G908", "MI", "PORTO", 0],
    ["E680", "LI", "PORTO AZZURRO", 1],
    ["G906", "VA", "PORTO CERESIO", 1],
    ["M263", "LE", "PORTO CESAREO", 1],
    ["G911", "MC", "PORTO CIVITANOVA", 0],
    ["G908", "MI", "PORTO D'ADDA", 0],
    ["F299", "AG", "PORTO EMPEDOCLE", 1],
    ["E680", "LI", "PORTO LONGONE", 0],
    ["G917", "MN", "PORTO MANTOVANO", 1],
    ["G918", "IM", "PORTO MAURIZIO", 0],
    ["G919", "MC", "PORTO RECANATI", 1],
    ["G920", "AP", "PORTO SAN GIORGIO", 0],
    ["G920", "FM", "PORTO SAN GIORGIO", 1],
    ["G921", "AP", "PORTO SANT'ELPIDIO", 0],
    ["G921", "FM", "PORTO SANT'ELPIDIO", 1],
    ["G923", "RO", "PORTO TOLLE", 1],
    ["G924", "SS", "PORTO TORRES", 1],
    ["G907", "VA", "PORTO VALTRAVAGLIA", 1],
    ["G926", "RO", "PORTO VIRO", 1],
    ["G909", "TV", "PORTOBUFFOLE'", 1],
    ["G910", "CB", "PORTOCANNONE", 1],
    ["G912", "LI", "PORTOFERRAIO", 1],
    ["G913", "GE", "PORTOFINO", 1],
    ["Z128", "EE", "PORTOGALLO", 1],
    ["G914", "VE", "PORTOGRUARO", 1],
    ["G915", "PL", "PORTOLE", 0],
    ["G916", "FE", "PORTOMAGGIORE", 1],
    ["M257", "SR", "PORTOPALO DI CAPO PASSERO", 1],
    ["G922", "CA", "PORTOSCUSO", 0],
    ["G922", "SU", "PORTOSCUSO", 1],
    ["G925", "SP", "PORTOVENERE", 1],
    ["G927", "BI", "PORTULA", 1],
    ["G928", "BS", "PORZANO", 0],
    ["G929", "NU", "POSADA", 1],
    ["G930", "BG", "POSCANTE", 0],
    ["G931", "VI", "POSINA", 1],
    ["G932", "SA", "POSITANO", 1],
    ["G933", "TV", "POSSAGNO", 1],
    ["G934", "RI", "POSTA", 1],
    ["G935", "FR", "POSTA FIBRENO", 1],
    ["G936", "BZ", "POSTAL", 1],
    ["G937", "SO", "POSTALESIO", 1],
    ["G938", "PG", "POSTICCIOLA", 0],
    ["G939", "SA", "POSTIGLIONE", 1],
    ["G940", "VC", "POSTUA", 1],
    ["G941", "TS", "POSTUMIA GROTTE", 0],
    ["G942", "PZ", "POTENZA", 1],
    ["F632", "MC", "POTENZA PICENA", 1],
    ["G943", "VI", "POVE", 0],
    ["G943", "VI", "POVE DEL GRAPPA", 1],
    ["G944", "TV", "POVEGLIANO", 1],
    ["G945", "VR", "POVEGLIANO VERONESE", 1],
    ["G946", "TS", "POVERIO", 0],
    ["G947", "RE", "POVIGLIO", 1],
    ["G948", "TN", "POVO", 0],
    ["G949", "UD", "POVOLETTO", 1],
    ["G950", "TN", "POZZA", 0],
    ["G950", "TN", "POZZA DI FASSA", 0],
    ["G951", "PG", "POZZAGLIA", 0],
    ["G951", "RI", "POZZAGLIA SABINA", 1],
    ["G952", "CR", "POZZAGLIO", 0],
    ["B914", "CR", "POZZAGLIO ED UNITI", 1],
    ["G953", "RG", "POZZALLO", 1],
    ["G954", "IS", "POZZILLI", 1],
    ["G955", "MI", "POZZO", 0],
    ["G956", "PU", "POZZO", 0],
    ["G957", "VI", "POZZO", 0],
    ["G956", "PU", "POZZO ALTO", 0],
    ["G958", "CR", "POZZO BARONZIO", 0],
    ["G955", "MI", "POZZO D'ADDA", 1],
    ["G960", "AL", "POZZOL GROPPO", 1],
    ["G959", "BS", "POZZOLENGO", 1],
    ["G957", "VI", "POZZOLEONE", 1],
    ["G961", "AL", "POZZOLO FORMIGARO", 1],
    ["G962", "SS", "POZZOMAGGIORE", 1],
    ["G963", "PD", "POZZONOVO", 1],
    ["G964", "NA", "POZZUOLI", 1],
    ["G965", "MI", "POZZUOLO", 0],
    ["G966", "UD", "POZZUOLO", 0],
    ["G966", "UD", "POZZUOLO DEL FRIULI", 1],
    ["G965", "MI", "POZZUOLO MARTESANA", 1],
    ["G967", "GE", "PRA", 0],
    ["G968", "BG", "PRADALUNGA", 1],
    ["G969", "UD", "PRADAMANO", 1],
    ["G970", "CN", "PRADLEVE", 0],
    ["G970", "CN", "PRADLEVES", 1],
    ["G971", "PV", "PRADO", 0],
    ["G972", "BO", "PRADURO E SASSO", 0],
    ["G973", "TO", "PRAGELATO", 1],
    ["G975", "CS", "PRAIA A MARE", 1],
    ["G976", "SA", "PRAIANO", 1],
    ["G977", "BS", "PRALBOINO", 1],
    ["G978", "TO", "PRALI", 1],
    ["G979", "TO", "PRALORMO", 1],
    ["G980", "BI", "PRALUNGO", 1],
    ["G978", "TO", "PRALY", 0],
    ["G981", "VE", "PRAMAGGIORE", 1],
    ["G982", "TO", "PRAMOLLO", 1],
    ["G983", "BS", "PRANDAGLIO", 0],
    ["G984", "TN", "PRANZO", 0],
    ["G985", "VC", "PRAROLO", 1],
    ["G986", "TO", "PRAROSTINO", 1],
    ["G987", "AL", "PRASCO", 1],
    ["G988", "TO", "PRASCORSANO", 1],
    ["G989", "TN", "PRASO", 0],
    ["G990", "AV", "PRATA", 0],
    ["G991", "CE", "PRATA", 0],
    ["G992", "AQ", "PRATA", 0],
    ["G993", "SO", "PRATA", 0],
    ["G994", "UD", "PRATA", 0],
    ["G993", "SO", "PRATA CAMPORTACCIO", 1],
    ["G992", "AQ", "PRATA D'ANSIDONIA", 1],
    ["G994", "PN", "PRATA DI PORDENONE", 1],
    ["G990", "AV", "PRATA DI PRINCIPATO ULTRA", 1],
    ["G991", "CE", "PRATA SANNITA", 1],
    ["G995", "CE", "PRATELLA", 1],
    ["G996", "BZ", "PRATI", 0],
    ["G997", "TO", "PRATIGLIONE", 1],
    ["A767", "BS", "PRATO", 0],
    ["G998", "CR", "PRATO", 0],
    ["G999", "PO", "PRATO", 1],
    ["H001", "NO", "PRATO", 0],
    ["H002", "UD", "PRATO", 0],
    ["H003", "BZ", "PRATO ALLA DRAVA", 0],
    ["H004", "BZ", "PRATO ALLO STELVIO", 1],
    ["H002", "UD", "PRATO CARNICO", 1],
    ["G999", "FI", "PRATO IN TOSCANA", 0],
    ["H004", "BZ", "PRATO IN VENOSTA", 0],
    ["G998", "CR", "PRATO MUZIO", 0],
    ["H001", "NO", "PRATO SESIA", 1],
    ["H004", "BZ", "PRATO VENOSTA", 0],
    ["H006", "AV", "PRATOLA", 0],
    ["H007", "AQ", "PRATOLA", 0],
    ["H007", "AQ", "PRATOLA PELIGNA", 1],
    ["H006", "AV", "PRATOLA SERRA", 1],
    ["H008", "AR", "PRATOVECCHIO", 0],
    ["M329", "AR", "PRATOVECCHIO STIA", 1],
    ["H009", "AR", "PRATOVECCHIO-STIA", 0],
    ["H010", "PN", "PRAVISDOMINI", 1],
    ["G974", "BI", "PRAY", 1],
    ["H011", "CN", "PRAZZO", 1],
    ["H012", "TN", "PRE'", 0],
    ["H042", "AO", "PRE'-SAINT-DIDIER", 1],
    ["H013", "RC", "PRECACORE", 0],
    ["H014", "UD", "PRECENICCO", 1],
    ["H015", "PG", "PRECI", 1],
    ["H016", "MI", "PRECOTTO", 0],
    ["M344", "TN", "PREDAIA", 1],
    ["H017", "FC", "PREDAPPIO", 1],
    ["H017", "FC", "PREDAPPIO NUOVA", 0],
    ["H018", "TN", "PREDAZZO", 1],
    ["H019", "BZ", "PREDOI", 1],
    ["H020", "BG", "PREDORE", 1],
    ["H021", "AL", "PREDOSA", 1],
    ["H022", "TV", "PREGANZIOL", 1],
    ["H023", "TN", "PREGASINA", 0],
    ["H024", "TN", "PREGHENA", 0],
    ["H025", "NO", "PREGLIA", 0],
    ["H026", "MI", "PREGNANA", 0],
    ["H026", "MI", "PREGNANA MILANESE", 1],
    ["B117", "PV", "PREGOLA", 0],
    ["H027", "IM", "PRELA'", 1],
    ["H028", "LC", "PREMANA", 1],
    ["H029", "UD", "PREMARIACCO", 1],
    ["H030", "VB", "PREMENO", 1],
    ["H031", "MI", "PREMENUGO", 0],
    ["H032", "MI", "PREMEZZO", 0],
    ["H033", "VB", "PREMIA", 1],
    ["H034", "FC", "PREMILCUORE", 1],
    ["H035", "TN", "PREMIONE", 0],
    ["H036", "BG", "PREMOLO", 1],
    ["H037", "NO", "PREMOSELLO", 0],
    ["H037", "VB", "PREMOSELLO-CHIOVENDA", 1],
    ["H038", "UD", "PREONE", 1],
    ["H039", "TN", "PREORE", 0],
    ["H040", "UD", "PREPOTTO", 1],
    ["H041", "NO", "PREQUARTERA", 0],
    ["H043", "BS", "PRESEGLIE", 1],
    ["H044", "BS", "PRESEGNO", 0],
    ["H045", "CE", "PRESENZANO", 1],
    ["H046", "BG", "PRESEZZO", 1],
    ["H047", "LE", "PRESICCE", 0],
    ["M428", "LE", "PRESICCE-ACQUARICA", 1],
    ["H048", "VR", "PRESSANA", 1],
    ["H049", "TN", "PRESSON", 0],
    ["H050", "BS", "PRESTINE", 0],
    ["H051", "NO", "PRESTINONE", 0],
    ["H052", "CH", "PRETORO", 1],
    ["H053", "AQ", "PRETURO", 0],
    ["H054", "GO", "PREVACINA", 0],
    ["H055", "BS", "PREVALLE", 1],
    ["H056", "AQ", "PREZZA", 1],
    ["H057", "TN", "PREZZO", 0],
    ["H058", "TO", "PRIACCO", 0],
    ["H059", "CN", "PRIERO", 1],
    ["H060", "CN", "PRIERO-MONTEZEMOLO", 0],
    ["H061", "MO", "PRIGNANO", 0],
    ["H062", "SA", "PRIGNANO", 0],
    ["H062", "SA", "PRIGNANO CILENTO", 1],
    ["H061", "MO", "PRIGNANO SULLA SECCHIA", 1],
    ["H063", "LC", "PRIMALUNA", 1],
    ["H064", "FM", "PRIMANO", 0],
    ["H065", "AL", "PRIMEGLIO SCHIERANO", 0],
    ["H066", "TN", "PRIMIERO", 0],
    ["M359", "TN", "PRIMIERO SAN MARTINO DI CASTROZZA", 1],
    ["H067", "TN", "PRIO'", 0],
    ["H068", "CN", "PRIOCCA", 1],
    ["H069", "CN", "PRIOLA", 1],
    ["M279", "SR", "PRIOLO GARGALLO", 1],
    ["G698", "LT", "PRIVERNO", 1],
    ["H070", "PA", "PRIZZI", 1],
    ["H071", "VT", "PROCENO", 1],
    ["H072", "NA", "PROCIDA", 1],
    ["H073", "GE", "PROPATA", 1],
    ["H074", "CO", "PROSERPIO", 1],
    ["H075", "MI", "PROSPIANO", 0],
    ["H076", "LT", "PROSSEDI", 1],
    ["H077", "BS", "PROVAGLIO", 0],
    ["H078", "BS", "PROVAGLIO D'ISEO", 1],
    ["H079", "BS", "PROVAGLIO SOPRA", 0],
    ["H080", "BS", "PROVAGLIO SOTTO", 0],
    ["H077", "BS", "PROVAGLIO VAL SABBIA", 1],
    ["H081", "BZ", "PROVES", 1],
    ["H082", "BS", "PROVEZZE", 0],
    ["H083", "CB", "PROVVIDENTI", 1],
    ["H084", "VR", "PRUN", 0],
    ["H085", "CN", "PRUNETTO", 1],
    ["H086", "BS", "PUEGNAGO", 0],
    ["H086", "BS", "PUEGNAGO DEL GARDA", 1],
    ["H086", "BS", "PUEGNAGO SUL GARDA", 0],
    ["Z518", "EE", "PUERTO RICO", 1],
    ["H087", "BN", "PUGLIANELLO", 1],
    ["C435", "CR", "PUGNOLO", 0],
    ["H088", "CA", "PULA", 1],
    ["H089", "UD", "PULFERO", 1],
    ["H090", "TA", "PULSANO", 1],
    ["H091", "BG", "PUMENENGO", 1],
    ["H092", "BL", "PUOS D'ALPAGO", 0],
    ["H093", "CO", "PURIA", 0],
    ["H094", "CO", "PUSIANO", 1],
    ["H095", "SS", "PUTIFIGARI", 1],
    ["H096", "BA", "PUTIGNANO", 1],
    ["Z237", "EE", "QATAR", 1],
    ["H097", "AV", "QUADRELLE", 1],
    ["H098", "CH", "QUADRI", 1],
    ["H099", "AV", "QUAGLIETTA", 0],
    ["H100", "TO", "QUAGLIUZZO", 1],
    ["H101", "NA", "QUALIANO", 1],
    ["H102", "AT", "QUARANTI", 1],
    ["D213", "BZ", "QUARAZZE", 0],
    ["H103", "BI", "QUAREGNA", 0],
    ["M414", "BI", "QUAREGNA CERRETO", 1],
    ["H104", "AL", "QUARGNENTO", 1],
    ["H105", "NO", "QUARNA", 0],
    ["H106", "VB", "QUARNA SOPRA", 1],
    ["H107", "VB", "QUARNA SOTTO", 1],
    ["H108", "VC", "QUARONA", 1],
    ["H109", "PT", "QUARRATA", 1],
    ["H110", "AO", "QUART", 1],
    ["H111", "AL", "QUARTI", 0],
    ["H112", "MI", "QUARTIANO", 0],
    ["H113", "AL", "QUARTO", 0],
    ["H114", "NA", "QUARTO", 1],
    ["H115", "GE", "QUARTO AL MARE", 0],
    ["H113", "AL", "QUARTO ASTESE", 0],
    ["H116", "MI", "QUARTO CAGNINO", 0],
    ["H117", "VE", "QUARTO D'ALTINO", 1],
    ["H115", "GE", "QUARTO DEI MILLE", 0],
    ["H110", "AO", "QUARTO PRAETORIA", 0],
    ["H118", "CA", "QUARTU", 0],
    ["H118", "CA", "QUARTU SANT'ELENA", 1],
    ["H119", "CA", "QUARTUCCIU", 1],
    ["H120", "TO", "QUASSOLO", 1],
    ["H121", "AL", "QUATTORDIO", 1],
    ["H122", "RE", "QUATTRO CASTELLA", 1],
    ["H123", "MN", "QUATTROVILLE", 0],
    ["H124", "BL", "QUERO", 0],
    ["M332", "BL", "QUERO VAS", 1],
    ["H125", "TN", "QUETTA", 0],
    ["H126", "SV", "QUILIANO", 1],
    ["H127", "TO", "QUINCINETTO", 1],
    ["H128", "AV", "QUINDICI", 1],
    ["H129", "MN", "QUINGENTOLE", 1],
    ["H130", "CR", "QUINTANO", 1],
    ["H131", "TV", "QUINTO", 0],
    ["H132", "VC", "QUINTO", 0],
    ["H133", "VR", "QUINTO", 0],
    ["H134", "VI", "QUINTO", 0],
    ["H135", "GE", "QUINTO AL MARE", 0],
    ["H131", "TV", "QUINTO DI TREVISO", 1],
    ["H133", "VR", "QUINTO DI VALPANTENA", 0],
    ["H137", "MI", "QUINTO ROMANO", 0],
    ["H132", "VC", "QUINTO VERCELLESE", 1],
    ["H134", "VI", "QUINTO VICENTINO", 1],
    ["H136", "AP", "QUINTODECIMO", 0],
    ["H138", "MI", "QUINTOSOLE", 0],
    ["H139", "BS", "QUINZANELLO", 0],
    ["H140", "BS", "QUINZANO", 0],
    ["H141", "MI", "QUINZANO", 0],
    ["H142", "VR", "QUINZANO", 0],
    ["H140", "BS", "QUINZANO D'OGLIO", 1],
    ["H141", "MI", "QUINZANO SAN PIETRO", 0],
    ["H142", "VR", "QUINZANO VERONESE", 0],
    ["H143", "MN", "QUISTELLO", 1],
    ["H144", "CR", "QUISTRO", 0],
    ["H145", "BI", "QUITTENGO", 0],
    ["H146", "TN", "RABBI", 1],
    ["H147", "LE", "RACALE", 1],
    ["H148", "AG", "RACALMUTO", 1],
    ["H149", "UD", "RACCOLANA", 0],
    ["H150", "CN", "RACCONIGI", 1],
    ["H151", "ME", "RACCUJA", 1],
    ["H152", "BZ", "RACIGNES", 0],
    ["H152", "BZ", "RACINES", 1],
    ["H153", "SI", "RADDA", 0],
    ["H153", "SI", "RADDA IN CHIANTI", 1],
    ["H154", "CT", "RADDUSA", 1],
    ["H155", "RC", "RADICENA", 0],
    ["H156", "SI", "RADICOFANI", 1],
    ["H157", "SI", "RADICONDOLI", 1],
    ["H158", "BS", "RAFFA", 0],
    ["H159", "AG", "RAFFADALI", 1],
    ["M287", "CT", "RAGALNA", 1],
    ["H160", "AR", "RAGGIOLO", 0],
    ["H161", "UD", "RAGOGNA", 1],
    ["H162", "TN", "RAGOLI", 0],
    ["H163", "RG", "RAGUSA", 1],
    ["H164", "RG", "RAGUSA IBLA", 0],
    ["H164", "RG", "RAGUSA INFERIORE", 0],
    ["H165", "CE", "RAIANO", 0],
    ["H166", "AQ", "RAIANO", 1],
    ["H167", "BZ", "RAISSA", 0],
    ["H168", "CT", "RAMACCA", 1],
    ["G654", "RE", "RAMISETO", 0],
    ["H169", "RO", "RAMO DI PALO", 0],
    ["H170", "CO", "RAMPONIO", 0],
    ["H171", "CO", "RAMPONIO VERNA", 0],
    ["H172", "CO", "RANCIO", 0],
    ["H173", "CO", "RANCIO", 0],
    ["H172", "CO", "RANCIO DI LECCO", 0],
    ["H173", "VA", "RANCIO VALCUVIA", 1],
    ["H174", "VA", "RANCO", 1],
    ["H175", "CT", "RANDAZZO", 1],
    ["M242", "TN", "RANGO", 0],
    ["H176", "BG", "RANICA", 1],
    ["H177", "BG", "RANZANICO", 1],
    ["H179", "SV", "RANZI PIETRA", 0],
    ["H178", "GO", "RANZIANO", 0],
    ["H180", "IM", "RANZO", 1],
    ["H181", "TN", "RANZO", 0],
    ["H182", "AP", "RAPAGNANO", 0],
    ["H182", "FM", "RAPAGNANO", 1],
    ["H183", "GE", "RAPALLO", 1],
    ["H184", "CH", "RAPINO", 1],
    ["H185", "SI", "RAPOLANO", 0],
    ["H185", "SI", "RAPOLANO TERME", 1],
    ["H186", "PZ", "RAPOLLA", 1],
    ["H187", "PZ", "RAPONE", 1],
    ["H188", "VC", "RASSA", 1],
    ["H189", "BZ", "RASUN ANTERSELVA", 0],
    ["H190", "BZ", "RASUN DI SOPRA", 0],
    ["H191", "BZ", "RASUN DI SOTTO", 0],
    ["M307", "BZ", "RASUN VALDAORA", 0],
    ["H189", "BZ", "RASUN-ANTERSELVA", 1],
    ["H192", "SO", "RASURA", 1],
    ["H193", "FM", "RATECEVO IN MONTE", 0],
    ["H194", "AG", "RAVANUSA", 1],
    ["H195", "MO", "RAVARINO", 1],
    ["H196", "UD", "RAVASCLETTO", 1],
    ["H197", "CO", "RAVELLINO", 0],
    ["H198", "SA", "RAVELLO", 1],
    ["H199", "RA", "RAVENNA", 1],
    ["H200", "UD", "RAVEO", 1],
    ["H201", "TN", "RAVINA", 0],
    ["H202", "CE", "RAVISCANINA", 1],
    ["H203", "VB", "RE", 1],
    ["H204", "PV", "REA", 1],
    ["H205", "AG", "REALMONTE", 1],
    ["H206", "UD", "REANA", 0],
    ["H206", "UD", "REANA DEL ROIALE", 0],
    ["H206", "UD", "REANA DEL ROJALE", 1],
    ["H207", "TO", "REANO", 1],
    ["H208", "CO", "REBBIO", 0],
    ["H209", "SS", "REBECCU", 0],
    ["H210", "CE", "RECALE", 1],
    ["H211", "MC", "RECANATI", 1],
    ["H212", "GE", "RECCO", 1],
    ["H213", "NO", "RECETTO", 1],
    ["H214", "VI", "RECOARO", 0],
    ["H214", "VI", "RECOARO TERME", 1],
    ["H215", "CR", "RECORFANO", 0],
    ["H216", "PV", "REDAVALLE", 1],
    ["H217", "BG", "REDONA", 0],
    ["H218", "MN", "REDONDESCO", 1],
    ["H219", "AT", "REFRANCORE", 1],
    ["H220", "TV", "REFRONTOLO", 1],
    ["H221", "EN", "REGALBUTO", 1],
    ["H222", "FI", "REGGELLO", 1],
    ["H223", "RE", "REGGIO", 0],
    ["H224", "RC", "REGGIO DI CALABRIA", 1],
    ["H223", "RE", "REGGIO NELL'EMILIA", 1],
    ["H225", "RE", "REGGIOLO", 1],
    ["H226", "MI", "REGINA FITTAREZZA", 0],
    ["Z114", "EE", "REGNO UNITO", 1],
    ["H227", "BN", "REINO", 1],
    ["H228", "ME", "REITANO", 1],
    ["H229", "UD", "REMANZACCO", 1],
    ["H230", "BS", "REMEDELLO", 1],
    ["H231", "BS", "REMEDELLO SOPRA", 0],
    ["H232", "BS", "REMEDELLO SOTTO", 0],
    ["H233", "MB", "RENATE", 1],
    ["H233", "MI", "RENATE", 0],
    ["H234", "MI", "RENATE VEDUGGIO", 0],
    ["H235", "CS", "RENDE", 1],
    ["H236", "BZ", "RENON", 1],
    ["H237", "TS", "REPNO", 0],
    ["Z156", "EE", "REPUBBLICA CECA", 1],
    ["Z213", "EE", "REPUBBLICA DI COREA", 1],
    ["Z127", "EE", "REPUBBLICA DI POLONIA", 1],
    ["Z505", "EE", "REPUBBLICA DOMINICANA", 1],
    ["H238", "TV", "RESANA", 1],
    ["H239", "MI", "RESCALDA", 0],
    ["H240", "MI", "RESCALDINA", 1],
    ["H241", "BZ", "RESIA", 0],
    ["H242", "UD", "RESIA", 1],
    ["H241", "BZ", "RESIA ALL'ADIGE", 0],
    ["H243", "NA", "RESINA", 0],
    ["H244", "UD", "RESIUTTA", 1],
    ["H245", "CL", "RESUTTANO", 1],
    ["H246", "PV", "RETORBIDO", 1],
    ["H247", "CN", "REVELLO", 1],
    ["H248", "MN", "REVERE", 0],
    ["H250", "AL", "REVIGLIASCO", 0],
    ["H251", "TO", "REVIGLIASCO", 0],
    ["H250", "AT", "REVIGLIASCO D'ASTI", 1],
    ["H251", "TO", "REVIGLIASCO TORINESE", 0],
    ["H252", "TV", "REVINE", 0],
    ["H253", "TV", "REVINE LAGO", 1],
    ["H254", "TN", "REVO'", 0],
    ["H255", "CO", "REZZAGO", 1],
    ["H256", "BS", "REZZATO", 1],
    ["H257", "IM", "REZZO", 1],
    ["H259", "CO", "REZZO CAVARGNA", 0],
    ["H258", "GE", "REZZOAGLIO", 1],
    ["H260", "CO", "REZZONICO", 0],
    ["H261", "AO", "RHEMES", 0],
    ["H262", "AO", "RHEMES-NOTRE-DAME", 1],
    ["H263", "AO", "RHEMES-SAINT-GEORGES", 1],
    ["H264", "MI", "RHO", 1],
    ["Z337", "EE", "RHODESIA", 0],
    ["H262", "AO", "RHEMES-NOTRE-DAME", 0],
    ["H263", "AO", "RHEMES-SAINT-GEORGES", 0],
    ["H265", "RC", "RIACE", 1],
    ["H266", "SV", "RIALTO", 1],
    ["H267", "RM", "RIANO", 1],
    ["H268", "CE", "RIARDO", 1],
    ["H269", "AG", "RIBERA", 1],
    ["H270", "TO", "RIBORDONE", 1],
    ["H271", "VV", "RICADI", 1],
    ["H272", "AL", "RICALDONE", 1],
    ["H273", "CB", "RICCIA", 1],
    ["H274", "RN", "RICCIONE", 1],
    ["H275", "GE", "RICCO'", 0],
    ["H275", "SP", "RICCO' DEL GOLFO DI SPEZIA", 1],
    ["H276", "CR", "RICENGO", 1],
    ["H277", "SA", "RICIGLIANO", 1],
    ["H278", "TO", "RICLARETTO", 0],
    ["H279", "BZ", "RIDANNA", 0],
    ["H280", "TV", "RIESE", 0],
    ["H280", "TV", "RIESE PIO X", 1],
    ["H281", "CL", "RIESI", 1],
    ["H282", "RI", "RIETI", 1],
    ["H283", "GO", "RIFEMBERGO", 0],
    ["H284", "BZ", "RIFIANO", 1],
    ["H285", "CN", "RIFREDDO", 1],
    ["H286", "FI", "RIGNANO", 0],
    ["H287", "FG", "RIGNANO", 0],
    ["H288", "RM", "RIGNANO", 0],
    ["H288", "RM", "RIGNANO FLAMINIO", 1],
    ["H287", "FG", "RIGNANO GARGANICO", 1],
    ["H286", "FI", "RIGNANO SULL'ARNO", 1],
    ["H289", "UD", "RIGOLATO", 1],
    ["H290", "BG", "RIGOSA", 0],
    ["H291", "VC", "RIMA SAN GIUSEPPE", 0],
    ["H292", "VC", "RIMASCO", 0],
    ["H293", "VC", "RIMELLA", 1],
    ["H294", "RN", "RIMINI", 1],
    ["H295", "BZ", "RINA", 0],
    ["H296", "AL", "RINCO", 0],
    ["H295", "BZ", "RINNA", 0],
    ["H297", "LI", "RIO", 0],
    ["H298", "RE", "RIO", 0],
    ["M391", "LI", "RIO", 1],
    ["H299", "BZ", "RIO DI PUSTERIA", 1],
    ["H305", "LI", "RIO MARINA", 0],
    ["H306", "BZ", "RIO MOLINO", 0],
    ["H297", "LI", "RIO NELL'ELBA", 0],
    ["H298", "RE", "RIO SALICETO", 1],
    ["H300", "RM", "RIOFREDDO", 1],
    ["H301", "CA", "RIOLA", 0],
    ["H301", "OR", "RIOLA SARDO", 1],
    ["H302", "RA", "RIOLO", 0],
    ["H302", "RA", "RIOLO DEI BAGNI", 0],
    ["H302", "RA", "RIOLO TERME", 1],
    ["H303", "MO", "RIOLUNATO", 1],
    ["H304", "SP", "RIOMAGGIORE", 1],
    ["H307", "PZ", "RIONERO", 0],
    ["H308", "CB", "RIONERO", 0],
    ["H307", "PZ", "RIONERO IN VOLTURE", 0],
    ["H307", "PZ", "RIONERO IN VULTURE", 1],
    ["H308", "IS", "RIONERO SANNITICO", 1],
    ["H309", "MI", "RIOZZO", 0],
    ["H320", "CH", "RIPA TEATINA", 1],
    ["H310", "AP", "RIPABERARDA", 0],
    ["H311", "CB", "RIPABOTTONI", 1],
    ["H312", "PZ", "RIPACANDIDA", 1],
    ["E799", "CB", "RIPALDA", 0],
    ["H313", "CB", "RIPALIMOSANI", 1],
    ["H314", "CR", "RIPALTA ARPINA", 1],
    ["H315", "CR", "RIPALTA CREMASCA", 1],
    ["H316", "CR", "RIPALTA GUERINA", 1],
    ["H317", "CR", "RIPALTA NUOVA", 0],
    ["E799", "CB", "RIPALTA SUL TRIGNO", 0],
    ["H318", "CR", "RIPALTA VECCHIA", 0],
    ["H319", "PI", "RIPARBELLA", 1],
    ["H321", "AP", "RIPATRANSONE", 1],
    ["H322", "AN", "RIPE", 0],
    ["H323", "MC", "RIPE SAN GINESIO", 1],
    ["H324", "FR", "RIPI", 1],
    ["H325", "CT", "RIPOSTO", 1],
    ["H167", "BZ", "RISCONE", 0],
    ["H326", "CN", "RITTANA", 1],
    ["H327", "BL", "RIVA", 0],
    ["H328", "IM", "RIVA", 0],
    ["H329", "NO", "RIVA", 0],
    ["H330", "TN", "RIVA", 0],
    ["H330", "TN", "RIVA DEL GARDA", 1],
    ["M410", "FE", "RIVA DEL PO", 1],
    ["H331", "BG", "RIVA DI SOLTO", 1],
    ["H332", "BZ", "RIVA DI TURES", 0],
    ["H332", "BZ", "RIVA IN VALLE", 0],
    ["H328", "IM", "RIVA LIGURE", 1],
    ["H337", "TO", "RIVA PRESSO CHIERI", 1],
    ["H345", "IM", "RIVA SANTO STEFANO", 0],
    ["H329", "VC", "RIVA VALDOBBIA", 0],
    ["H333", "TO", "RIVALBA", 1],
    ["D958", "PC", "RIVALTA", 0],
    ["H334", "AL", "RIVALTA", 0],
    ["H335", "TO", "RIVALTA", 0],
    ["H334", "AL", "RIVALTA BORMIDA", 1],
    ["H335", "TO", "RIVALTA DI TORINO", 1],
    ["D958", "PC", "RIVALTA TREBBIA", 0],
    ["H327", "BL", "RIVAMONTE", 0],
    ["H327", "BL", "RIVAMONTE AGORDINO", 1],
    ["H336", "PV", "RIVANAZZANO", 0],
    ["H336", "PV", "RIVANAZZANO TERME", 1],
    ["H338", "TO", "RIVARA", 1],
    ["H339", "GE", "RIVAROLO", 0],
    ["H340", "TO", "RIVAROLO", 0],
    ["H340", "TO", "RIVAROLO CANAVESE", 1],
    ["H341", "CR", "RIVAROLO CREMONESE", 0],
    ["H341", "CR", "RIVAROLO DEL RE ED UNITI", 1],
    ["H342", "MN", "RIVAROLO FUORI", 0],
    ["H339", "GE", "RIVAROLO LIGURE", 0],
    ["H342", "MN", "RIVAROLO MANTOVANO", 1],
    ["H343", "AL", "RIVARONE", 1],
    ["H344", "TO", "RIVAROSSA", 1],
    ["H346", "VC", "RIVE", 1],
    ["H347", "UD", "RIVE D'ARCANO", 1],
    ["H348", "PZ", "RIVELLO", 1],
    ["H349", "TO", "RIVERA", 0],
    ["H350", "PC", "RIVERGARO", 1],
    ["H351", "BG", "RIVIERA D'ADDA", 0],
    ["H352", "UD", "RIVIGNANO", 0],
    ["M317", "UD", "RIVIGNANO TEOR", 1],
    ["H353", "AQ", "RIVISONDOLI", 1],
    ["H354", "RI", "RIVODUTRI", 1],
    ["H355", "TO", "RIVOLI", 1],
    ["H356", "VR", "RIVOLI", 0],
    ["H356", "VR", "RIVOLI VERONESE", 1],
    ["H357", "CR", "RIVOLTA", 0],
    ["H357", "CR", "RIVOLTA D'ADDA", 1],
    ["H358", "BS", "RIVOLTELLA SUL GARDA", 0],
    ["G356", "UD", "RIVOLTO", 0],
    ["H359", "RC", "RIZZICONI", 1],
    ["H360", "FE", "RO", 0],
    ["H361", "VI", "ROANA", 1],
    ["H362", "CN", "ROASCHIA", 1],
    ["H363", "CN", "ROASCIO", 1],
    ["H364", "VC", "ROASENDA", 0],
    ["H365", "VC", "ROASIO", 1],
    ["H366", "AT", "ROATTO", 1],
    ["H367", "TO", "ROBASSOMERO", 1],
    ["H368", "MI", "ROBBIANO", 0],
    ["G223", "LC", "ROBBIATE", 1],
    ["G223", "CO", "ROBBIATE PADERNO", 0],
    ["H369", "PV", "ROBBIO", 1],
    ["H370", "MI", "ROBECCHETTO", 0],
    ["H371", "MI", "ROBECCHETTO CON INDUNO", 1],
    ["H372", "CR", "ROBECCO", 0],
    ["H373", "MI", "ROBECCO", 0],
    ["H374", "MI", "ROBECCO", 0],
    ["H375", "PV", "ROBECCO", 0],
    ["H372", "CR", "ROBECCO D'OGLIO", 1],
    ["H374", "MI", "ROBECCO LODIGIANO", 0],
    ["H375", "PV", "ROBECCO PAVESE", 1],
    ["H373", "MI", "ROBECCO SUL NAVIGLIO", 1],
    ["H376", "AT", "ROBELLA", 1],
    ["H377", "CN", "ROBILANTE", 1],
    ["H378", "CN", "ROBURENT", 1],
    ["H378", "CN", "ROBURENTO", 0],
    ["H379", "BL", "ROCCA", 0],
    ["H380", "ME", "ROCCA", 0],
    ["H381", "NO", "ROCCA", 0],
    ["H386", "TO", "ROCCA CANAVESE", 1],
    ["H387", "RM", "ROCCA CANTERANO", 1],
    ["H391", "CN", "ROCCA CIGLIE'", 1],
    ["H379", "BL", "ROCCA D'AGORDO", 0],
    ["H392", "AT", "ROCCA D'ARAZZO", 1],
    ["H393", "FR", "ROCCA D'ARCE", 1],
    ["H398", "CE", "ROCCA D'EVANDRO", 1],
    ["H395", "CN", "ROCCA DE' BALDI", 1],
    ["H396", "PV", "ROCCA DE' GIORGI", 1],
    ["H397", "BG", "ROCCA DEL COLLE", 0],
    ["H399", "AQ", "ROCCA DI BOTTE", 1],
    ["H400", "AQ", "ROCCA DI CAMBIO", 1],
    ["H401", "RM", "ROCCA DI CAVE", 1],
    ["H386", "TO", "ROCCA DI CORIO", 0],
    ["H402", "AQ", "ROCCA DI MEZZO", 1],
    ["H403", "KR", "ROCCA DI NETO", 1],
    ["H404", "RM", "ROCCA DI PAPA", 1],
    ["H403", "CZ", "ROCCA FERDINANDEA", 0],
    ["H414", "AL", "ROCCA GRIMALDA", 1],
    ["H416", "CS", "ROCCA IMPERIALE", 1],
    ["H421", "LT", "ROCCA MASSIMA", 1],
    ["H429", "AQ", "ROCCA PIA", 1],
    ["H379", "BL", "ROCCA PIETORE", 1],
    ["H381", "NO", "ROCCA PIETRA", 0],
    ["H432", "RM", "ROCCA PRIORA", 1],
    ["H437", "FC", "ROCCA SAN CASCIANO", 1],
    ["H438", "AV", "ROCCA SAN FELICE", 1],
    ["H439", "CH", "ROCCA SAN GIOVANNI", 1],
    ["H440", "TE", "ROCCA SANTA MARIA", 1],
    ["H441", "RM", "ROCCA SANTO STEFANO", 1],
    ["H446", "RI", "ROCCA SINIBALDA", 1],
    ["H450", "PV", "ROCCA SUSELLA", 1],
    ["H382", "AV", "ROCCABASCERANA", 1],
    ["H383", "KR", "ROCCABERNARDA", 1],
    ["H384", "PR", "ROCCABIANCA", 1],
    ["H385", "CN", "ROCCABRUNA", 1],
    ["H388", "PE", "ROCCACARAMANICO", 0],
    ["H389", "AQ", "ROCCACASALE", 1],
    ["H390", "AP", "ROCCACASAREGNANA", 0],
    ["H393", "FR", "ROCCADARCE", 0],
    ["H394", "SA", "ROCCADASPIDE", 1],
    ["H405", "ME", "ROCCAFIORITA", 1],
    ["H390", "AP", "ROCCAFLUVIONE", 1],
    ["H406", "AL", "ROCCAFORTE", 0],
    ["H407", "CN", "ROCCAFORTE", 0],
    ["H408", "RC", "ROCCAFORTE", 0],
    ["H408", "RC", "ROCCAFORTE DEL GRECO", 1],
    ["H406", "AL", "ROCCAFORTE LIGURE", 1],
    ["H407", "CN", "ROCCAFORTE MONDOVI'", 1],
    ["H409", "TA", "ROCCAFORZATA", 1],
    ["H410", "BS", "ROCCAFRANCA", 1],
    ["H411", "RM", "ROCCAGIOVINE", 1],
    ["H412", "SA", "ROCCAGLORIOSA", 1],
    ["H413", "LT", "ROCCAGORGA", 1],
    ["H415", "FR", "ROCCAGUGLIELMA", 0],
    ["D831", "UD", "ROCCALBA", 0],
    ["H417", "GR", "ROCCALBEGNA", 1],
    ["H418", "ME", "ROCCALUMERA", 1],
    ["H419", "VT", "ROCCALVECCE", 0],
    ["H420", "IS", "ROCCAMANDOLFI", 1],
    ["H422", "PA", "ROCCAMENA", 1],
    ["H423", "CE", "ROCCAMONFINA", 1],
    ["H424", "CH", "ROCCAMONTEPIANO", 1],
    ["H425", "PE", "ROCCAMORICE", 1],
    ["H426", "PZ", "ROCCANOVA", 1],
    ["H427", "RI", "ROCCANTICA", 1],
    ["H428", "PA", "ROCCAPALUMBA", 1],
    ["H430", "TO", "ROCCAPIATTA", 0],
    ["H431", "SA", "ROCCAPIEMONTE", 1],
    ["H433", "NA", "ROCCARAINOLA", 1],
    ["H434", "AQ", "ROCCARASO", 1],
    ["H435", "AP", "ROCCAREONILE", 0],
    ["H436", "CE", "ROCCAROMANA", 1],
    ["H442", "CH", "ROCCASCALEGNA", 1],
    ["H443", "FR", "ROCCASECCA", 1],
    ["H444", "RM", "ROCCASECCA", 0],
    ["H444", "LT", "ROCCASECCA DEI VOLSCI", 1],
    ["H445", "IS", "ROCCASICURA", 1],
    ["H447", "CN", "ROCCASPARVERA", 1],
    ["H448", "CH", "ROCCASPINALVETI", 1],
    ["H449", "GR", "ROCCASTRADA", 1],
    ["H380", "ME", "ROCCAVALDINA", 1],
    ["H429", "AQ", "ROCCAVALLEOSCURA", 0],
    ["H451", "AT", "ROCCAVERANO", 1],
    ["H452", "SV", "ROCCAVIGNALE", 1],
    ["H453", "CN", "ROCCAVIONE", 1],
    ["H454", "CB", "ROCCAVIVARA", 1],
    ["H455", "ME", "ROCCELLA", 0],
    ["H456", "RC", "ROCCELLA", 0],
    ["H456", "RC", "ROCCELLA IONICA", 1],
    ["H455", "ME", "ROCCELLA VALDEMONE", 1],
    ["H457", "TO", "ROCCHEMOLLES", 0],
    ["H458", "CB", "ROCCHETTA", 0],
    ["H459", "CE", "ROCCHETTA", 0],
    ["H460", "IM", "ROCCHETTA", 0],
    ["H461", "MS", "ROCCHETTA", 0],
    ["H458", "IS", "ROCCHETTA A VOLTURNO", 1],
    ["H462", "CN", "ROCCHETTA BELBO", 1],
    ["H463", "GE", "ROCCHETTA CAIRO", 0],
    ["H464", "SV", "ROCCHETTA CENGIO", 0],
    ["H461", "SP", "ROCCHETTA DI VARA", 1],
    ["H459", "CE", "ROCCHETTA E CROCE", 1],
    ["H465", "AL", "ROCCHETTA LIGURE", 1],
    ["H460", "IM", "ROCCHETTA NERVINA", 1],
    ["H466", "AT", "ROCCHETTA PALAFEA", 1],
    ["H467", "FG", "ROCCHETTA SANT'ANTONIO", 1],
    ["H468", "AT", "ROCCHETTA TANARO", 1],
    ["H469", "PG", "ROCCHETTE IN SABINA", 0],
    ["H470", "MI", "RODANO", 1],
    ["H471", "UD", "RODDA", 0],
    ["H472", "CN", "RODDI", 1],
    ["H473", "CN", "RODDINO", 1],
    ["H474", "CN", "RODELLO", 1],
    ["H475", "BZ", "RODENGO", 1],
    ["H476", "BS", "RODENGO", 0],
    ["H477", "BS", "RODENGO SAIANO", 1],
    ["H477", "BS", "RODENGO-SAIANO", 0],
    ["H478", "CO", "RODERO", 1],
    ["H480", "FG", "RODI GARGANICO", 1],
    ["H479", "ME", "RODI'", 0],
    ["H479", "ME", "RODI' MILICI", 1],
    ["H481", "MN", "RODIGO", 1],
    ["H482", "TS", "RODITTI", 0],
    ["H483", "TO", "RODORETTO", 0],
    ["H484", "BS", "ROE' VOLCIANO", 1],
    ["H485", "SA", "ROFRANO", 1],
    ["H486", "LC", "ROGENO", 1],
    ["H487", "CO", "ROGGIANO", 0],
    ["H488", "CS", "ROGGIANO GRAVINA", 1],
    ["H487", "VA", "ROGGIANO VALTRAVAGLIA", 0],
    ["H489", "RC", "ROGHUDI", 1],
    ["H488", "CS", "ROGIANO", 0],
    ["H490", "CS", "ROGLIANO", 1],
    ["H491", "PV", "ROGNANO", 1],
    ["H492", "BG", "ROGNO", 1],
    ["H493", "SO", "ROGOLO", 1],
    ["H494", "RM", "ROIATE", 1],
    ["H495", "CH", "ROIO", 0],
    ["H496", "AQ", "ROIO", 0],
    ["H495", "CH", "ROIO DEL SANGRO", 1],
    ["H496", "AQ", "ROIO PIANO", 0],
    ["H497", "AO", "ROISAN", 1],
    ["H498", "TO", "ROLETTO", 1],
    ["H499", "TO", "ROLLIERES", 0],
    ["H500", "RE", "ROLO", 1],
    ["H501", "RM", "ROMA", 1],
    ["H502", "NO", "ROMAGNANO", 0],
    ["H503", "SA", "ROMAGNANO", 0],
    ["H504", "TN", "ROMAGNANO", 0],
    ["H503", "SA", "ROMAGNANO AL MONTE", 1],
    ["H502", "NO", "ROMAGNANO SESIA", 1],
    ["H505", "PV", "ROMAGNESE", 1],
    ["H506", "TN", "ROMALLO", 0],
    ["H507", "SS", "ROMANA", 1],
    ["H508", "CR", "ROMANENGO", 1],
    ["Z129", "EE", "ROMANIA", 1],
    ["H509", "BG", "ROMANO", 0],
    ["H510", "CO", "ROMANO", 0],
    ["H511", "TO", "ROMANO", 0],
    ["H512", "VI", "ROMANO", 0],
    ["H510", "CO", "ROMANO BRIANZA", 0],
    ["H511", "TO", "ROMANO CANAVESE", 1],
    ["H512", "VI", "ROMANO D'EZZELINO", 1],
    ["H509", "BG", "ROMANO DI LOMBARDIA", 1],
    ["H513", "GO", "ROMANS", 0],
    ["H514", "GO", "ROMANS D'ISONZO", 1],
    ["H515", "TN", "ROMARZOLLO", 0],
    ["H516", "VV", "ROMBIOLO", 1],
    ["H517", "TN", "ROMENO", 1],
    ["H518", "NO", "ROMENTINO", 1],
    ["H519", "ME", "ROMETTA", 1],
    ["H520", "CR", "ROMPREZZAGNO", 0],
    ["H521", "CO", "RONAGO", 1],
    ["H524", "CR", "RONCA DE' GOLFERANI", 0],
    ["H522", "VR", "RONCA'", 1],
    ["H523", "TV", "RONCADE", 1],
    ["H525", "BS", "RONCADELLE", 1],
    ["H526", "CR", "RONCADELLO", 0],
    ["H526", "CR", "RONCADELLO D'ADDA", 0],
    ["H527", "PV", "RONCARO", 1],
    ["H528", "TN", "RONCEGNO", 0],
    ["H528", "TN", "RONCEGNO TERME", 1],
    ["H529", "MB", "RONCELLO", 1],
    ["H529", "MI", "RONCELLO", 0],
    ["H530", "MI", "RONCHETTO", 0],
    ["H531", "GO", "RONCHI", 0],
    ["H532", "TN", "RONCHI", 0],
    ["H531", "GO", "RONCHI DEI LEGIONARI", 1],
    ["M243", "TN", "RONCHI DI ALA", 0],
    ["H531", "GO", "RONCHI DI MONFALCONE", 0],
    ["H532", "TN", "RONCHI VALSUGANA", 1],
    ["H533", "UD", "RONCHIS", 1],
    ["H534", "VT", "RONCIGLIONE", 1],
    ["H535", "BG", "RONCO", 0],
    ["H536", "GE", "RONCO", 0],
    ["H537", "MI", "RONCO", 0],
    ["H538", "NO", "RONCO", 0],
    ["H539", "TO", "RONCO", 0],
    ["H540", "VR", "RONCO", 0],
    ["H540", "VR", "RONCO ALL'ADIGE", 1],
    ["H538", "BI", "RONCO BIELLESE", 1],
    ["H537", "MB", "RONCO BRIANTINO", 1],
    ["H537", "MI", "RONCO BRIANTINO", 0],
    ["H539", "TO", "RONCO CANAVESE", 1],
    ["H536", "GE", "RONCO SCRIVIA", 1],
    ["H535", "BG", "RONCOBELLO", 1],
    ["H541", "MN", "RONCOFERRARO", 1],
    ["H542", "FC", "RONCOFREDDO", 1],
    ["H543", "TN", "RONCOGNO", 0],
    ["H544", "BG", "RONCOLA", 1],
    ["H545", "TN", "RONCONE", 0],
    ["H546", "GE", "RONDANINA", 1],
    ["H547", "TO", "RONDISSONE", 1],
    ["H548", "CO", "RONGIO", 0],
    ["H549", "VC", "RONSECCO", 1],
    ["H550", "GO", "RONZINA", 0],
    ["H551", "TN", "RONZO", 0],
    ["M303", "TN", "RONZO-CHIENIS", 1],
    ["H552", "TN", "RONZONE", 1],
    ["H553", "BI", "ROPPOLO", 1],
    ["H554", "TO", "RORA'", 1],
    ["H555", "TO", "RORETO", 0],
    ["H555", "TO", "RORETO CHISONE", 0],
    ["H556", "VI", "ROSA'", 1],
    ["H557", "RC", "ROSALI", 0],
    ["H558", "RC", "ROSARNO", 1],
    ["H559", "PV", "ROSASCO", 1],
    ["H560", "MI", "ROSATE", 1],
    ["H561", "BI", "ROSAZZA", 1],
    ["H562", "PE", "ROSCIANO", 1],
    ["H563", "BG", "ROSCIATE", 0],
    ["H564", "SA", "ROSCIGNO", 1],
    ["H565", "CS", "ROSE", 1],
    ["H566", "CH", "ROSELLO", 1],
    ["H567", "MI", "ROSERIO", 0],
    ["H568", "FG", "ROSETO", 0],
    ["H572", "CS", "ROSETO CAPO SPULICO", 1],
    ["F585", "TE", "ROSETO DEGLI ABRUZZI", 1],
    ["H568", "FG", "ROSETO VALFORTORE", 1],
    ["H569", "AL", "ROSIGNANO", 0],
    ["H570", "PI", "ROSIGNANO", 0],
    ["H570", "LI", "ROSIGNANO MARITTIMO", 1],
    ["H569", "AL", "ROSIGNANO MONFERRATO", 1],
    ["H571", "AL", "ROSINGO", 0],
    ["H572", "CS", "ROSITO", 0],
    ["H572", "CS", "ROSITO CAPO SPULICO", 0],
    ["H573", "RO", "ROSOLINA", 1],
    ["H574", "SR", "ROSOLINI", 1],
    ["H575", "AN", "ROSORA", 1],
    ["H576", "AN", "ROSORA MERGO", 0],
    ["H577", "VC", "ROSSA", 1],
    ["H578", "CN", "ROSSANA", 1],
    ["H579", "CS", "ROSSANO", 0],
    ["H580", "VI", "ROSSANO", 0],
    ["H580", "VI", "ROSSANO VENETO", 1],
    ["H581", "GE", "ROSSIGLIONE", 1],
    ["H582", "BG", "ROSSINO", 0],
    ["D255", "GE", "ROSSO", 0],
    ["H583", "TO", "ROSTA", 1],
    ["H584", "BG", "ROTA", 0],
    ["H585", "CS", "ROTA", 0],
    ["H584", "BG", "ROTA D'IMAGNA", 1],
    ["H586", "BG", "ROTA DENTRO", 0],
    ["H587", "BG", "ROTA FUORI", 0],
    ["H585", "CS", "ROTA GRECA", 1],
    ["H588", "AP", "ROTELLA", 1],
    ["H589", "CB", "ROTELLO", 1],
    ["H590", "PZ", "ROTONDA", 1],
    ["H591", "MT", "ROTONDELLA", 1],
    ["H592", "AV", "ROTONDI", 1],
    ["H593", "PC", "ROTTOFRENO", 1],
    ["H594", "VI", "ROTZO", 1],
    ["H555", "TO", "ROURE", 1],
    ["H595", "MI", "ROVAGNASCO", 0],
    ["H596", "LC", "ROVAGNATE", 0],
    ["H364", "VC", "ROVASENDA", 1],
    ["H597", "VA", "ROVATE", 0],
    ["H598", "BS", "ROVATO", 1],
    ["H599", "GE", "ROVEGNO", 1],
    ["H600", "NO", "ROVEGRO", 0],
    ["H601", "CO", "ROVELLASCA", 1],
    ["H602", "CO", "ROVELLO", 0],
    ["H602", "CO", "ROVELLO PORRO", 1],
    ["H603", "CO", "ROVENNA", 0],
    ["H605", "TN", "ROVER CARBONARE", 0],
    ["H604", "MN", "ROVERBELLA", 1],
    ["H606", "VR", "ROVERCHIARA", 1],
    ["H607", "TN", "ROVERE' DELLA LUNA", 1],
    ["H608", "VR", "ROVERE' DI VELO", 0],
    ["H608", "VR", "ROVERE' VERONESE", 1],
    ["H609", "UD", "ROVEREDO", 0],
    ["H610", "VR", "ROVEREDO", 0],
    ["H610", "VR", "ROVEREDO DI GUA'", 1],
    ["H609", "PN", "ROVEREDO IN PIANO", 1],
    ["H611", "CR", "ROVERETO", 0],
    ["H612", "TN", "ROVERETO", 1],
    ["H613", "FC", "ROVERSANO", 0],
    ["H614", "PV", "ROVESCALA", 1],
    ["H615", "BG", "ROVETTA", 1],
    ["H616", "BG", "ROVETTA CON FINO", 0],
    ["H617", "FI", "ROVEZZANO", 0],
    ["H601", "CO", "ROVI PORRO", 0],
    ["H618", "RM", "ROVIANO", 1],
    ["H619", "PL", "ROVIGNO D'ISTRIA", 0],
    ["H620", "RO", "ROVIGO", 1],
    ["H621", "CS", "ROVITO", 1],
    ["H622", "PD", "ROVOLON", 1],
    ["H623", "MI", "ROZZANO", 1],
    ["H624", "PL", "ROZZO", 0],
    ["Z338", "EE", "RUANDA", 1],
    ["H625", "PD", "RUBANO", 1],
    ["H626", "CR", "RUBBIANO", 0],
    ["H627", "TO", "RUBIANA", 1],
    ["H628", "RE", "RUBIERA", 1],
    ["H629", "UD", "RUDA", 1],
    ["H630", "BS", "RUDIANO", 1],
    ["H631", "TO", "RUEGLIO", 1],
    ["H632", "LE", "RUFFANO", 1],
    ["H633", "CN", "RUFFIA", 1],
    ["H634", "TN", "RUFFRE'", 0],
    ["H634", "TN", "RUFFRE'-MENDOLA", 1],
    ["H635", "FI", "RUFINA", 1],
    ["H636", "MI", "RUGINELLO", 0],
    ["F271", "OR", "RUINAS", 1],
    ["H637", "PV", "RUINO", 0],
    ["H638", "NO", "RUMIANCA", 0],
    ["H639", "TN", "RUMO", 1],
    ["H640", "VA", "RUNO", 0],
    ["H641", "PZ", "RUOTI", 1],
    ["H237", "TS", "RUPINGRANDE", 0],
    ["H642", "RA", "RUSSI", 1],
    ["Z154", "EE", "RUSSIA=FEDERAZIONE RUSSA", 1],
    ["H643", "BA", "RUTIGLIANO", 1],
    ["H644", "SA", "RUTINO", 1],
    ["H165", "CE", "RUVIANO", 1],
    ["H645", "BA", "RUVO", 0],
    ["H646", "PZ", "RUVO", 0],
    ["H646", "PZ", "RUVO DEL MONTE", 1],
    ["H645", "BA", "RUVO DI PUGLIA", 1],
    ["Z238", "EE", "RYUKYU (ISOLE)", 0],
    ["H647", "LT", "SABAUDIA", 1],
    ["H648", "VC", "SABBIA", 0],
    ["H649", "BG", "SABBIO", 0],
    ["H650", "BS", "SABBIO", 0],
    ["H649", "BG", "SABBIO BERGAMASCO", 0],
    ["H650", "BS", "SABBIO CHIESE", 1],
    ["H651", "CO", "SABBIONCELLO", 0],
    ["H652", "MN", "SABBIONETA", 1],
    ["H653", "GO", "SABLE GRANDE", 0],
    ["H654", "SA", "SACCO", 1],
    ["M244", "TN", "SACCO", 0],
    ["H655", "PD", "SACCOLONGO", 1],
    ["H656", "VA", "SACCONAGO", 0],
    ["H657", "PN", "SACILE", 1],
    ["H658", "RM", "SACROFANO", 1],
    ["H659", "NU", "SADALI", 0],
    ["H659", "SU", "SADALI", 1],
    ["H660", "GO", "SAGA", 0],
    ["H661", "NU", "SAGAMA", 0],
    ["H661", "OR", "SAGAMA", 1],
    ["H662", "NO", "SAGLIANO", 0],
    ["H663", "PV", "SAGLIANO", 0],
    ["H663", "PV", "SAGLIANO DI CRENNA", 0],
    ["H662", "BI", "SAGLIANO MICCA", 1],
    ["H664", "FM", "SAGORIA", 0],
    ["H665", "GO", "SAGRADO", 1],
    ["H666", "TN", "SAGRON MIS", 1],
    ["H667", "RO", "SAGUEDO", 0],
    ["Z362", "EE", "SAHARA MERIDIONALE", 0],
    ["Z363", "EE", "SAHARA SETTENTRIONALE", 0],
    ["Z339", "EE", "SAHARA SPAGNOLO", 0],
    ["H668", "BS", "SAIANO", 0],
    ["Z533", "EE", "SAINT KITTS E NEVIS", 1],
    ["Z527", "EE", "SAINT LUCIA", 1],
    ["Z403", "EE", "SAINT PIERRE ET MIQUELON (ISOLE)", 1],
    ["Z528", "EE", "SAINT VINCENT E GRENADINE", 1],
    ["H669", "AO", "SAINT-CHRISTOPHE", 1],
    ["H670", "AO", "SAINT-DENIS", 1],
    ["H671", "AO", "SAINT-MARCEL", 1],
    ["H672", "AO", "SAINT-NICOLAS", 1],
    ["H673", "AO", "SAINT-OYEN", 1],
    ["H674", "AO", "SAINT-PIERRE", 1],
    ["H675", "AO", "SAINT-RHE'MY-EN-BOSSES", 0],
    ["H675", "AO", "SAINT-RHEMY-EN-BOSSES", 1],
    ["H676", "AO", "SAINT-VINCENT", 1],
    ["H677", "AL", "SALA", 0],
    ["H678", "BO", "SALA", 0],
    ["H679", "CO", "SALA", 0],
    ["H680", "CO", "SALA", 0],
    ["H681", "NO", "SALA", 0],
    ["H682", "PR", "SALA", 0],
    ["H683", "SA", "SALA", 0],
    ["H680", "CO", "SALA AL BARRO", 0],
    ["H682", "PR", "SALA BAGANZA", 1],
    ["H681", "BI", "SALA BIELLESE", 1],
    ["H678", "BO", "SALA BOLOGNESE", 1],
    ["H679", "CO", "SALA COMACINA", 1],
    ["H683", "SA", "SALA CONSILINA", 1],
    ["H686", "SA", "SALA DI GIOI", 0],
    ["E458", "AO", "SALA DORA", 0],
    ["H677", "AL", "SALA MONFERRATO", 1],
    ["H684", "TO", "SALABERTANO", 0],
    ["H685", "AL", "SALABUE", 0],
    ["H687", "MT", "SALANDRA", 1],
    ["H688", "TP", "SALAPARUTA", 1],
    ["H689", "RO", "SALARA", 1],
    ["H690", "VC", "SALASCO", 1],
    ["H691", "TO", "SALASSA", 1],
    ["H684", "TO", "SALBERTRAND", 1],
    ["H692", "GO", "SALCANO", 0],
    ["F810", "VI", "SALCEDO", 1],
    ["H693", "CB", "SALCITO", 1],
    ["H694", "AL", "SALE", 1],
    ["H695", "CN", "SALE", 0],
    ["H696", "TO", "SALE CANISCHIO", 0],
    ["H697", "AO", "SALE CASTELNUOVO", 0],
    ["H695", "CN", "SALE DELLE LANGHE", 1],
    ["H699", "BS", "SALE MARASINO", 1],
    ["H704", "CN", "SALE SAN GIOVANNI", 1],
    ["H698", "NO", "SALECCHIO", 0],
    ["H700", "TP", "SALEMI", 1],
    ["H686", "SA", "SALENTO", 1],
    ["H701", "MI", "SALERANO", 0],
    ["H702", "TO", "SALERANO", 0],
    ["H702", "TO", "SALERANO CANAVESE", 1],
    ["H701", "LO", "SALERANO SUL LAMBRO", 1],
    ["H703", "SA", "SALERNO", 1],
    ["H705", "PD", "SALETTO", 0],
    ["H706", "TV", "SALGAREDA", 1],
    ["H707", "NO", "SALI", 0],
    ["H707", "VC", "SALI VERCELLESE", 1],
    ["H708", "LE", "SALICE", 0],
    ["H709", "RC", "SALICE", 0],
    ["H709", "RC", "SALICE CALABRO", 0],
    ["H708", "LE", "SALICE SALENTINO", 1],
    ["H710", "CN", "SALICETO", 1],
    ["H711", "ME", "SALINA", 0],
    ["H712", "VR", "SALINE", 0],
    ["E946", "FG", "SALINE DI BARLETTA", 0],
    ["H713", "RI", "SALISANO", 1],
    ["H714", "VR", "SALIZZOLE", 1],
    ["H715", "PE", "SALLE", 1],
    ["H716", "CN", "SALMORE", 0],
    ["H716", "CN", "SALMOUR", 1],
    ["H717", "BS", "SALO'", 1],
    ["Z724", "EE", "SALOMONE (ISOLE)", 1],
    ["H718", "GO", "SALONA D'ISONZO", 0],
    ["H719", "BZ", "SALORNO", 0],
    ["H719", "BZ", "SALORNO SULLA STRADA DEL VINO", 1],
    ["H720", "PR", "SALSOMAGGIORE", 0],
    ["H720", "PR", "SALSOMAGGIORE TERME", 1],
    ["H721", "PU", "SALTARA", 0],
    ["E857", "TN", "SALTER MALGOLO", 0],
    ["H722", "TO", "SALTO", 0],
    ["H723", "VA", "SALTRIO", 1],
    ["H724", "RN", "SALUDECIO", 1],
    ["H725", "VC", "SALUGGIA", 1],
    ["H726", "BI", "SALUSSOLA", 1],
    ["H727", "CN", "SALUZZO", 1],
    ["B653", "CO", "SALVADERA", 0],
    ["H728", "RO", "SALVATERRA", 0],
    ["H729", "LE", "SALVE", 1],
    ["H730", "PZ", "SALVIA", 0],
    ["H731", "CR", "SALVIROLA", 1],
    ["H731", "CR", "SALVIROLA CREMASCA", 0],
    ["H732", "SA", "SALVITELLE", 1],
    ["H733", "AV", "SALZA", 0],
    ["H734", "TO", "SALZA", 0],
    ["H734", "TO", "SALZA DI PINEROLO", 1],
    ["H733", "AV", "SALZA IRPINA", 1],
    ["H735", "VE", "SALZANO", 1],
    ["H736", "VA", "SAMARATE", 1],
    ["H737", "GO", "SAMARIA", 0],
    ["H738", "CA", "SAMASSI", 0],
    ["H738", "SU", "SAMASSI", 1],
    ["H739", "CA", "SAMATZAI", 0],
    ["H739", "SU", "SAMATZAI", 1],
    ["H740", "GO", "SAMBASSO", 0],
    ["H741", "RC", "SAMBATELLO", 0],
    ["H742", "CZ", "SAMBIASE", 0],
    ["H743", "AG", "SAMBUCA", 0],
    ["H744", "FI", "SAMBUCA", 0],
    ["H743", "AG", "SAMBUCA DI SICILIA", 1],
    ["H743", "AG", "SAMBUCA LABAT", 0],
    ["H744", "PT", "SAMBUCA PISTOIESE", 1],
    ["H743", "AG", "SAMBUCA ZABUT", 0],
    ["H745", "RM", "SAMBUCI", 1],
    ["H746", "CN", "SAMBUCO", 1],
    ["H747", "CN", "SAMBUCO PIETRAPORZIO", 0],
    ["H748", "NO", "SAMBUGHETTO", 0],
    ["H749", "BA", "SAMMICHELE DI BARI", 1],
    ["H013", "RC", "SAMO", 1],
    ["H751", "RC", "SAMO DI CALABRIA", 0],
    ["Z726", "EE", "SAMOA", 1],
    ["Z725", "EE", "SAMOA AMERICANE (ISOLE)", 1],
    ["H750", "TN", "SAMOCLEVO", 0],
    ["H752", "SO", "SAMOLACO", 1],
    ["H753", "TO", "SAMONE", 1],
    ["H754", "TN", "SAMONE", 1],
    ["H755", "CN", "SAMPEIRE", 0],
    ["H755", "CN", "SAMPEYRE", 1],
    ["H756", "OR", "SAMUGHEO", 1],
    ["H758", "AV", "SAN BARBATO", 0],
    ["H759", "BS", "SAN BARTOLOMEO", 0],
    ["H760", "CO", "SAN BARTOLOMEO", 0],
    ["H761", "CO", "SAN BARTOLOMEO", 0],
    ["H762", "NO", "SAN BARTOLOMEO", 0],
    ["H761", "CO", "SAN BARTOLOMEO AL BOSCO", 0],
    ["H763", "IM", "SAN BARTOLOMEO AL MARE", 1],
    ["H763", "IM", "SAN BARTOLOMEO DEL CERVO", 0],
    ["A457", "IM", "SAN BARTOLOMEO ED ARZENO", 0],
    ["H764", "BN", "SAN BARTOLOMEO IN GALDO", 1],
    ["H760", "CO", "SAN BARTOLOMEO VAL CAVARGNA", 1],
    ["H762", "NO", "SAN BARTOLOMEO VALMARA", 0],
    ["H765", "CS", "SAN BASILE", 1],
    ["H766", "CA", "SAN BASILIO", 0],
    ["H766", "SU", "SAN BASILIO", 1],
    ["H767", "CR", "SAN BASSANO", 1],
    ["H768", "RO", "SAN BELLINO", 1],
    ["C257", "PG", "SAN BENEDETTO", 0],
    ["H769", "AP", "SAN BENEDETTO", 0],
    ["H770", "CN", "SAN BENEDETTO", 0],
    ["H771", "MN", "SAN BENEDETTO", 0],
    ["H770", "CN", "SAN BENEDETTO BELBO", 1],
    ["H772", "AQ", "SAN BENEDETTO DEI MARSI", 1],
    ["H769", "AP", "SAN BENEDETTO DEL TRONTO", 1],
    ["H773", "AQ", "SAN BENEDETTO IN PERILLIS", 1],
    ["H771", "MN", "SAN BENEDETTO PO", 1],
    ["H774", "CS", "SAN BENEDETTO ULLANO", 1],
    ["G566", "BO", "SAN BENEDETTO VAL DI SAMBRO", 1],
    ["H775", "TO", "SAN BENIGNO CANAVESE", 1],
    ["H776", "CR", "SAN BERNARDINO", 0],
    ["H777", "VB", "SAN BERNARDINO VERBANO", 1],
    ["H778", "AG", "SAN BIAGIO", 0],
    ["H779", "CE", "SAN BIAGIO", 0],
    ["H780", "IM", "SAN BIAGIO", 0],
    ["H780", "IM", "SAN BIAGIO DELLA CIMA", 1],
    ["H781", "TV", "SAN BIAGIO DI CALLALTA", 1],
    ["H778", "AG", "SAN BIAGIO PLATANI", 1],
    ["H779", "FR", "SAN BIAGIO SARACINISCO", 1],
    ["H782", "CB", "SAN BIASE", 1],
    ["H783", "VR", "SAN BONIFACIO", 1],
    ["H784", "CH", "SAN BUONO", 1],
    ["H785", "VV", "SAN CALOGERO", 1],
    ["H786", "BZ", "SAN CANDIDO", 1],
    ["H787", "GO", "SAN CANZIAN D'ISONZO", 1],
    ["H787", "GO", "SAN CANZIANO", 0],
    ["H788", "NO", "SAN CARLO", 0],
    ["H789", "TO", "SAN CARLO", 0],
    ["H789", "TO", "SAN CARLO CANAVESE", 1],
    ["H788", "NO", "SAN CARLO D'OSSOLA", 0],
    ["H789", "TO", "SAN CARLO DI CIRIE'", 0],
    ["H790", "SI", "SAN CASCIANO DEI BAGNI", 1],
    ["H791", "FI", "SAN CASCIANO IN VAL DI PESA", 1],
    ["M264", "LE", "SAN CASSIANO", 1],
    ["H792", "CL", "SAN CATALDO", 1],
    ["M295", "RM", "SAN CESAREO", 1],
    ["H793", "LE", "SAN CESARIO", 0],
    ["H794", "MO", "SAN CESARIO", 0],
    ["H793", "LE", "SAN CESARIO DI LECCE", 1],
    ["H794", "MO", "SAN CESARIO SUL PANARO", 1],
    ["H795", "PZ", "SAN CHIRICO NUOVO", 1],
    ["H796", "PZ", "SAN CHIRICO RAPARO", 1],
    ["H797", "PA", "SAN CIPIRELLO", 1],
    ["H798", "CE", "SAN CIPRIANO", 0],
    ["H799", "PV", "SAN CIPRIANO", 0],
    ["H800", "SA", "SAN CIPRIANO", 0],
    ["H798", "CE", "SAN CIPRIANO D'AVERSA", 1],
    ["H800", "SA", "SAN CIPRIANO PICENTINO", 1],
    ["H799", "PV", "SAN CIPRIANO PO", 1],
    ["H801", "RN", "SAN CLEMENTE", 1],
    ["H802", "GE", "SAN COLOMBANO", 0],
    ["H803", "MI", "SAN COLOMBANO", 0],
    ["H804", "TO", "SAN COLOMBANO", 0],
    ["H803", "MI", "SAN COLOMBANO AL LAMBRO", 1],
    ["H804", "TO", "SAN COLOMBANO BELMONTE", 1],
    ["H802", "GE", "SAN COLOMBANO CERTENOLI", 1],
    ["H805", "CT", "SAN CONO", 1],
    ["H806", "CS", "SAN COSMO", 0],
    ["H806", "CS", "SAN COSMO ALBANESE", 1],
    ["H807", "CZ", "SAN COSTANTINO", 0],
    ["H808", "PZ", "SAN COSTANTINO", 0],
    ["H808", "PZ", "SAN COSTANTINO ALBANESE", 1],
    ["H807", "VV", "SAN COSTANTINO CALABRO", 1],
    ["H809", "PU", "SAN COSTANZO", 1],
    ["H810", "AL", "SAN CRISTOFORO", 1],
    ["H811", "AL", "SAN DAMIANO", 0],
    ["H812", "CN", "SAN DAMIANO", 0],
    ["H813", "MI", "SAN DAMIANO", 0],
    ["H814", "PV", "SAN DAMIANO", 0],
    ["H814", "PV", "SAN DAMIANO AL COLLE", 1],
    ["H811", "AT", "SAN DAMIANO D'ASTI", 1],
    ["H813", "MI", "SAN DAMIANO DI MONZA", 0],
    ["H812", "CN", "SAN DAMIANO MACRA", 1],
    ["H815", "CR", "SAN DANIELE", 0],
    ["H816", "UD", "SAN DANIELE", 0],
    ["H817", "GO", "SAN DANIELE DEL CARSO", 0],
    ["H816", "UD", "SAN DANIELE DEL FRIULI", 1],
    ["H815", "CR", "SAN DANIELE PO", 1],
    ["H815", "CR", "SAN DANIELE RIPA PO", 0],
    ["H818", "CS", "SAN DEMETRIO", 0],
    ["H819", "AQ", "SAN DEMETRIO", 0],
    ["H818", "CS", "SAN DEMETRIO CORONE", 1],
    ["H819", "AQ", "SAN DEMETRIO NE' VESTINI", 1],
    ["H042", "AO", "SAN DESIDERIO TERME", 0],
    ["H820", "TO", "SAN DIDERO", 1],
    ["H823", "VE", "SAN DONA' DI PIAVE", 1],
    ["H822", "BR", "SAN DONACI", 1],
    ["H824", "CE", "SAN DONATO", 0],
    ["H825", "CS", "SAN DONATO", 0],
    ["H826", "LE", "SAN DONATO", 0],
    ["H827", "MI", "SAN DONATO", 0],
    ["H828", "PR", "SAN DONATO", 0],
    ["H828", "PR", "SAN DONATO D'ENZA", 0],
    ["H826", "LE", "SAN DONATO DI LECCE", 1],
    ["H825", "CS", "SAN DONATO DI NINEA", 1],
    ["H827", "MI", "SAN DONATO MILANESE", 1],
    ["H824", "FR", "SAN DONATO VAL DI COMINO", 1],
    ["D324", "TS", "SAN DORLIGO DELLA VALLE", 1],
    ["H830", "CO", "SAN FEDELE", 0],
    ["H830", "CO", "SAN FEDELE INTELVI", 0],
    ["H831", "PZ", "SAN FELE", 1],
    ["H832", "BS", "SAN FELICE", 0],
    ["H833", "CB", "SAN FELICE", 0],
    ["H834", "CE", "SAN FELICE", 0],
    ["H835", "MO", "SAN FELICE", 0],
    ["H836", "RM", "SAN FELICE", 0],
    ["H837", "BZ", "SAN FELICE", 0],
    ["H834", "CE", "SAN FELICE A CANCELLO", 1],
    ["H836", "LT", "SAN FELICE CIRCEO", 1],
    ["H838", "BS", "SAN FELICE DEL BENACO", 1],
    ["H833", "CB", "SAN FELICE DEL LITTORIO", 0],
    ["H833", "CB", "SAN FELICE DEL MOLISE", 1],
    ["H832", "BS", "SAN FELICE DI SCOVOLO", 0],
    ["H833", "CB", "SAN FELICE SLAVO", 0],
    ["H835", "MO", "SAN FELICE SUL PANARO", 1],
    ["F901", "ME", "SAN FERDINANDO", 0],
    ["H839", "FG", "SAN FERDINANDO", 0],
    ["M277", "RC", "SAN FERDINANDO", 1],
    ["H839", "BT", "SAN FERDINANDO DI PUGLIA", 1],
    ["H839", "FG", "SAN FERDINANDO DI PUGLIA", 0],
    ["H840", "CO", "SAN FERMO DELLA BATTAGLIA", 1],
    ["H841", "CS", "SAN FILI", 1],
    ["H842", "ME", "SAN FILIPPO DEL MELA", 1],
    ["H843", "TV", "SAN FIOR", 1],
    ["H844", "LO", "SAN FIORANO", 1],
    ["H845", "UD", "SAN FLORIANO", 0],
    ["H845", "GO", "SAN FLORIANO DEL COLLIO", 1],
    ["H846", "CZ", "SAN FLORO", 1],
    ["H847", "TO", "SAN FRANCESCO AL CAMPO", 1],
    ["H848", "SS", "SAN FRANCESCO D'AGLIENTU", 0],
    ["H849", "GE", "SAN FRANCESCO D'ALBARO", 0],
    ["H850", "ME", "SAN FRATELLO", 1],
    ["H853", "GE", "SAN FRUTTUOSO", 0],
    ["H854", "BG", "SAN GALLO", 0],
    ["H856", "CA", "SAN GAVINO", 0],
    ["H856", "CA", "SAN GAVINO MONREALE", 0],
    ["H856", "SU", "SAN GAVINO MONREALE", 1],
    ["H857", "TR", "SAN GEMINI", 1],
    ["H858", "BZ", "SAN GENESIO", 0],
    ["H859", "PV", "SAN GENESIO", 0],
    ["H858", "BZ", "SAN GENESIO ATESINO", 1],
    ["H859", "PV", "SAN GENESIO ED UNITI", 1],
    ["H860", "NA", "SAN GENNARO", 0],
    ["H860", "NA", "SAN GENNARO VESUVIANO", 1],
    ["C034", "FR", "SAN GERMANO", 0],
    ["H861", "NO", "SAN GERMANO", 0],
    ["H862", "TO", "SAN GERMANO", 0],
    ["H863", "VI", "SAN GERMANO", 0],
    ["H862", "TO", "SAN GERMANO CHISONE", 1],
    ["H863", "VI", "SAN GERMANO DEI BERICI", 0],
    ["H861", "VC", "SAN GERMANO VERCELLESE", 1],
    ["H864", "BG", "SAN GERVASIO", 0],
    ["H865", "BS", "SAN GERVASIO", 0],
    ["H865", "BS", "SAN GERVASIO BRESCIANO", 1],
    ["H864", "BG", "SAN GERVASIO D'ADDA", 0],
    ["F618", "SA", "SAN GIACOMO", 0],
    ["H866", "BZ", "SAN GIACOMO", 0],
    ["H867", "CB", "SAN GIACOMO", 0],
    ["H868", "SO", "SAN GIACOMO", 0],
    ["H869", "TN", "SAN GIACOMO", 0],
    ["H867", "CB", "SAN GIACOMO DEGLI SCHIAVONI", 1],
    ["H870", "MN", "SAN GIACOMO DELLE SEGNATE", 1],
    ["H868", "SO", "SAN GIACOMO FILIPPO", 1],
    ["H871", "TS", "SAN GIACOMO IN COLLE", 0],
    ["H866", "BZ", "SAN GIACOMO IN VALLE AURINA", 0],
    ["B952", "VC", "SAN GIACOMO VERCELLESE", 1],
    ["H873", "TO", "SAN GILLIO", 1],
    ["H874", "TO", "SAN GILLIO TORINESE", 0],
    ["H875", "SI", "SAN GIMIGNANO", 1],
    ["H876", "MC", "SAN GINESIO", 1],
    ["C259", "SA", "SAN GIORGIO", 0],
    ["E408", "TV", "SAN GIORGIO", 0],
    ["H878", "AL", "SAN GIORGIO", 0],
    ["H879", "BZ", "SAN GIORGIO", 0],
    ["H880", "CE", "SAN GIORGIO", 0],
    ["H881", "CS", "SAN GIORGIO", 0],
    ["H882", "LE", "SAN GIORGIO", 0],
    ["H883", "MN", "SAN GIORGIO", 0],
    ["H884", "MI", "SAN GIORGIO", 0],
    ["H885", "PV", "SAN GIORGIO", 0],
    ["H886", "PU", "SAN GIORGIO", 0],
    ["H887", "PC", "SAN GIORGIO", 0],
    ["H888", "PZ", "SAN GIORGIO", 0],
    ["H889", "RC", "SAN GIORGIO", 0],
    ["H890", "TO", "SAN GIORGIO", 0],
    ["H891", "UD", "SAN GIORGIO", 0],
    ["H892", "NA", "SAN GIORGIO A CREMANO", 1],
    ["H880", "FR", "SAN GIORGIO A LIRI", 1],
    ["H881", "CS", "SAN GIORGIO ALBANESE", 1],
    ["H883", "MN", "SAN GIORGIO BIGARELLO", 1],
    ["H890", "TO", "SAN GIORGIO CANAVESE", 1],
    ["H894", "BN", "SAN GIORGIO DEL SANNIO", 1],
    ["H891", "PN", "SAN GIORGIO DELLA RICHINVELDA", 1],
    ["H893", "PD", "SAN GIORGIO DELLE PERTICHE", 1],
    ["E408", "TV", "SAN GIORGIO DI LAGO", 0],
    ["H885", "PV", "SAN GIORGIO DI LOMELLINA", 1],
    ["H883", "MN", "SAN GIORGIO DI MANTOVA", 0],
    ["H895", "UD", "SAN GIORGIO DI NOGARO", 1],
    ["H886", "PU", "SAN GIORGIO DI PESARO", 0],
    ["H896", "BO", "SAN GIORGIO DI PIANO", 1],
    ["H897", "PD", "SAN GIORGIO IN BOSCO", 1],
    ["H879", "BZ", "SAN GIORGIO IN VAL TURES", 0],
    ["H882", "TA", "SAN GIORGIO IONICO", 1],
    ["H898", "BN", "SAN GIORGIO LA MOLARA", 1],
    ["H894", "BN", "SAN GIORGIO LA MONTAGNA", 0],
    ["H888", "MT", "SAN GIORGIO LUCANO", 1],
    ["H878", "AL", "SAN GIORGIO MONFERRATO", 1],
    ["H889", "RC", "SAN GIORGIO MORGETO", 1],
    ["H887", "PC", "SAN GIORGIO PIACENTINO", 1],
    ["H899", "AT", "SAN GIORGIO SCARAMPI", 1],
    ["H882", "TA", "SAN GIORGIO SOTTO TARANTO", 0],
    ["H884", "MI", "SAN GIORGIO SU LEGNANO", 1],
    ["H900", "TO", "SAN GIORIO DI SUSA", 1],
    ["H901", "AR", "SAN GIOVANNI", 0],
    ["H902", "BZ", "SAN GIOVANNI", 0],
    ["H903", "RC", "SAN GIOVANNI", 0],
    ["H904", "TO", "SAN GIOVANNI", 0],
    ["H907", "SA", "SAN GIOVANNI A PIRO", 1],
    ["H908", "NA", "SAN GIOVANNI A TEDUCCIO", 0],
    ["H906", "UD", "SAN GIOVANNI AL NATISONE", 1],
    ["H905", "CO", "SAN GIOVANNI ALLA CASTAGNA", 0],
    ["H909", "GE", "SAN GIOVANNI BATTISTA", 0],
    ["H910", "BG", "SAN GIOVANNI BIANCO", 1],
    ["H911", "SI", "SAN GIOVANNI D'ASSO", 0],
    ["H912", "MN", "SAN GIOVANNI DEL DOSSO", 1],
    ["H913", "VT", "SAN GIOVANNI DI BIEDA", 0],
    ["H914", "AG", "SAN GIOVANNI DI CAMMARATA", 0],
    ["M390", "TN", "SAN GIOVANNI DI FASSA", 1],
    ["H915", "CT", "SAN GIOVANNI DI GALERNO", 0],
    ["H903", "RC", "SAN GIOVANNI DI GERACE", 1],
    ["H906", "UD", "SAN GIOVANNI DI MANZANO", 0],
    ["H914", "AG", "SAN GIOVANNI GEMINI", 1],
    ["H916", "VR", "SAN GIOVANNI ILARIONE", 1],
    ["H918", "CR", "SAN GIOVANNI IN CROCE", 1],
    ["H919", "CS", "SAN GIOVANNI IN FIORE", 1],
    ["H920", "CB", "SAN GIOVANNI IN GALDO", 1],
    ["H921", "RN", "SAN GIOVANNI IN MARIGNANO", 1],
    ["G467", "BO", "SAN GIOVANNI IN PERSICETO", 1],
    ["H902", "BZ", "SAN GIOVANNI IN VALLE AURINA", 0],
    ["H917", "FR", "SAN GIOVANNI INCARICO", 1],
    ["H922", "CT", "SAN GIOVANNI LA PUNTA", 1],
    ["H923", "CH", "SAN GIOVANNI LIPIONI", 1],
    ["H924", "VR", "SAN GIOVANNI LUPATOTO", 1],
    ["H904", "TO", "SAN GIOVANNI PELLICE", 0],
    ["H925", "PG", "SAN GIOVANNI REATINO", 0],
    ["H926", "FG", "SAN GIOVANNI ROTONDO", 1],
    ["G287", "CA", "SAN GIOVANNI SUERGIU", 0],
    ["G287", "SU", "SAN GIOVANNI SUERGIU", 1],
    ["D690", "CH", "SAN GIOVANNI TEATINO", 1],
    ["H901", "AR", "SAN GIOVANNI VALDARNO", 1],
    ["H927", "MI", "SAN GIULIANO", 0],
    ["H928", "CB", "SAN GIULIANO DEL SANNIO", 1],
    ["H929", "CB", "SAN GIULIANO DI PUGLIA", 1],
    ["H928", "CB", "SAN GIULIANO DI SEPINO", 0],
    ["H930", "MI", "SAN GIULIANO MILANESE", 1],
    ["A562", "PI", "SAN GIULIANO TERME", 1],
    ["H931", "NA", "SAN GIUSEPPE", 0],
    ["H932", "NO", "SAN GIUSEPPE", 0],
    ["H933", "PA", "SAN GIUSEPPE", 0],
    ["H934", "RC", "SAN GIUSEPPE", 0],
    ["H932", "VC", "SAN GIUSEPPE DI CASTO", 0],
    ["H933", "PA", "SAN GIUSEPPE JATO", 1],
    ["H931", "NA", "SAN GIUSEPPE VESUVIANO", 1],
    ["H935", "PG", "SAN GIUSTINO", 1],
    ["F621", "MC", "SAN GIUSTO", 0],
    ["H936", "TO", "SAN GIUSTO", 0],
    ["H936", "TO", "SAN GIUSTO CANAVESE", 1],
    ["H937", "FI", "SAN GODENZO", 1],
    ["H938", "BL", "SAN GREGORIO", 0],
    ["H939", "CE", "SAN GREGORIO", 0],
    ["H940", "CT", "SAN GREGORIO", 0],
    ["H941", "CZ", "SAN GREGORIO", 0],
    ["H942", "RM", "SAN GREGORIO", 0],
    ["H943", "SA", "SAN GREGORIO", 0],
    ["H941", "VV", "SAN GREGORIO D'IPPONA", 1],
    ["H942", "RM", "SAN GREGORIO DA SASSOLA", 1],
    ["H940", "CT", "SAN GREGORIO DI CATANIA", 1],
    ["H943", "SA", "SAN GREGORIO MAGNO", 1],
    ["H939", "CE", "SAN GREGORIO MATESE", 1],
    ["H938", "BL", "SAN GREGORIO NELLE ALPI", 1],
    ["H945", "BO", "SAN LAZZARO", 0],
    ["H946", "IM", "SAN LAZZARO", 0],
    ["H947", "PC", "SAN LAZZARO", 0],
    ["H947", "PC", "SAN LAZZARO ALBERONI", 0],
    ["H945", "BO", "SAN LAZZARO DI SAVENA", 1],
    ["H948", "PR", "SAN LAZZARO PARMENSE", 0],
    ["H946", "IM", "SAN LAZZARO REALE", 0],
    ["H949", "PU", "SAN LEO", 0],
    ["H949", "RN", "SAN LEO", 1],
    ["H950", "BZ", "SAN LEONARDO", 0],
    ["H951", "UD", "SAN LEONARDO", 1],
    ["H952", "BZ", "SAN LEONARDO IN PASSIRIA", 1],
    ["E404", "UD", "SAN LEOPOLDO ALAGLESIE", 0],
    ["H953", "BN", "SAN LEUCIO", 0],
    ["H954", "NA", "SAN LEUCIO", 0],
    ["H953", "BN", "SAN LEUCIO DEL SANNIO", 1],
    ["H955", "BN", "SAN LORENZELLO", 1],
    ["A256", "RM", "SAN LORENZO", 0],
    ["H956", "BZ", "SAN LORENZO", 0],
    ["H957", "IM", "SAN LORENZO", 0],
    ["H958", "PU", "SAN LORENZO", 0],
    ["H959", "RC", "SAN LORENZO", 1],
    ["H957", "IM", "SAN LORENZO AL MARE", 1],
    ["H960", "CR", "SAN LORENZO AROLDO", 0],
    ["H961", "CS", "SAN LORENZO BELLIZZI", 1],
    ["H963", "CR", "SAN LORENZO DE' PICENARDI", 0],
    ["H962", "CS", "SAN LORENZO DEL VALLO", 1],
    ["H964", "GO", "SAN LORENZO DI MOSSA", 0],
    ["H956", "BZ", "SAN LORENZO DI SEBATO", 1],
    ["M345", "TN", "SAN LORENZO DORSINO", 1],
    ["H965", "CR", "SAN LORENZO GUAZZONE", 0],
    ["H966", "TN", "SAN LORENZO IN BANALE", 0],
    ["H958", "PU", "SAN LORENZO IN CAMPO", 1],
    ["H956", "BZ", "SAN LORENZO IN PUSTERIA", 0],
    ["H964", "GO", "SAN LORENZO ISONTINO", 1],
    ["H967", "BN", "SAN LORENZO MAGGIORE", 1],
    ["H968", "CR", "SAN LORENZO MONDINARI", 0],
    ["H969", "VT", "SAN LORENZO NUOVO", 1],
    ["H970", "RC", "SAN LUCA", 1],
    ["H971", "CS", "SAN LUCIDO", 1],
    ["H972", "TN", "SAN LUGANO", 0],
    ["H973", "BN", "SAN LUPO", 1],
    ["H975", "AV", "SAN MANGO", 0],
    ["H976", "CZ", "SAN MANGO", 0],
    ["H977", "SA", "SAN MANGO", 0],
    ["H976", "CZ", "SAN MANGO D'AQUINO", 1],
    ["H977", "SA", "SAN MANGO PIEMONTE", 1],
    ["H975", "AV", "SAN MANGO SUL CALORE", 1],
    ["H978", "CE", "SAN MARCELLINO", 1],
    ["H979", "AN", "SAN MARCELLO", 1],
    ["H980", "FI", "SAN MARCELLO", 0],
    ["H980", "PT", "SAN MARCELLO PISTOIESE", 0],
    ["M377", "PT", "SAN MARCELLO PITEGLIO", 1],
    ["H981", "CS", "SAN MARCO", 0],
    ["H982", "ME", "SAN MARCO", 0],
    ["H983", "BN", "SAN MARCO A MONTI", 0],
    ["H981", "CS", "SAN MARCO ARGENTANO", 1],
    ["H982", "ME", "SAN MARCO D'ALFONSIO", 0],
    ["H982", "ME", "SAN MARCO D'ALUNZIO", 1],
    ["H984", "BN", "SAN MARCO DEI CAVOTI", 1],
    ["F043", "CE", "SAN MARCO EVANGELISTA", 1],
    ["F043", "NA", "SAN MARCO EVANGELISTA", 0],
    ["H985", "FG", "SAN MARCO IN LAMIS", 1],
    ["H986", "FG", "SAN MARCO LA CATOLA", 1],
    ["Z130", "EE", "SAN MARINO", 1],
    ["H987", "AL", "SAN MARTINO", 0],
    ["H988", "BZ", "SAN MARTINO", 0],
    ["H989", "BZ", "SAN MARTINO", 0],
    ["H990", "CB", "SAN MARTINO", 0],
    ["H991", "CH", "SAN MARTINO", 0],
    ["H992", "CS", "SAN MARTINO", 0],
    ["H993", "PL", "SAN MARTINO", 0],
    ["H994", "PZ", "SAN MARTINO", 0],
    ["H995", "RM", "SAN MARTINO", 0],
    ["H996", "RO", "SAN MARTINO", 0],
    ["H997", "TO", "SAN MARTINO", 0],
    ["H998", "TO", "SAN MARTINO", 0],
    ["H999", "UD", "SAN MARTINO", 0],
    ["H995", "VT", "SAN MARTINO AL CIMINO", 0],
    ["I001", "BZ", "SAN MARTINO AL MONTE", 0],
    ["H999", "PN", "SAN MARTINO AL TAGLIAMENTO", 1],
    ["H987", "AL", "SAN MARTINO AL TANARO", 0],
    ["H987", "AT", "SAN MARTINO ALFIERI", 1],
    ["I002", "BN", "SAN MARTINO AVE GRATIA PLOENA", 0],
    ["I003", "VR", "SAN MARTINO BUON ALBERGO", 1],
    ["H997", "TO", "SAN MARTINO CANAVESE", 1],
    ["H994", "PZ", "SAN MARTINO D'AGRI", 1],
    ["I004", "GE", "SAN MARTINO D'ALBARO", 0],
    ["I005", "MN", "SAN MARTINO DALL'ARGINE", 1],
    ["I006", "BG", "SAN MARTINO DE' CALVI", 0],
    ["I007", "CR", "SAN MARTINO DEL LAGO", 1],
    ["H992", "CS", "SAN MARTINO DI FINITA", 1],
    ["I008", "PD", "SAN MARTINO DI LUPARI", 1],
    ["H998", "TO", "SAN MARTINO DI PERRERO", 0],
    ["H996", "RO", "SAN MARTINO DI VENEZZE", 1],
    ["H988", "BZ", "SAN MARTINO IN BADIA", 1],
    ["I009", "CR", "SAN MARTINO IN BELISETO", 0],
    ["I010", "BZ", "SAN MARTINO IN CASIES", 0],
    ["H989", "BZ", "SAN MARTINO IN PASSIRIA", 1],
    ["H990", "CB", "SAN MARTINO IN PENSILIS", 1],
    ["I011", "RE", "SAN MARTINO IN RIO", 1],
    ["I012", "LO", "SAN MARTINO IN STRADA", 1],
    ["H993", "PL", "SAN MARTINO IN VALLE", 0],
    ["I013", "GO", "SAN MARTINO QUISCA", 0],
    ["I002", "BN", "SAN MARTINO SANNITA", 1],
    ["I014", "PV", "SAN MARTINO SICCOMARIO", 1],
    ["I015", "PR", "SAN MARTINO SINZANO", 0],
    ["H991", "CH", "SAN MARTINO SULLA MARRUCINA", 1],
    ["I016", "AV", "SAN MARTINO VALLE CAUDINA", 1],
    ["I017", "AL", "SAN MARZANO", 0],
    ["I018", "LE", "SAN MARZANO", 0],
    ["I019", "SA", "SAN MARZANO", 0],
    ["I018", "TA", "SAN MARZANO DI SAN GIUSEPPE", 1],
    ["I020", "AL", "SAN MARZANO MOASCA", 0],
    ["I017", "AT", "SAN MARZANO OLIVETO", 1],
    ["I019", "SA", "SAN MARZANO SUL SARNO", 1],
    ["I021", "AL", "SAN MARZANOTTO", 0],
    ["I022", "VR", "SAN MASSIMO", 0],
    ["I023", "CB", "SAN MASSIMO", 1],
    ["I022", "VR", "SAN MASSIMO ALL'ADIGE", 0],
    ["E003", "NO", "SAN MAURIZIO", 0],
    ["I024", "TO", "SAN MAURIZIO CANAVESE", 1],
    ["I025", "NO", "SAN MAURIZIO D'OPAGLIO", 1],
    ["I026", "CZ", "SAN MAURO", 0],
    ["I027", "FC", "SAN MAURO", 0],
    ["I028", "PA", "SAN MAURO", 0],
    ["I029", "PZ", "SAN MAURO", 0],
    ["I030", "TO", "SAN MAURO", 0],
    ["I028", "PA", "SAN MAURO CASTELVERDE", 1],
    ["I031", "SA", "SAN MAURO CILENTO", 1],
    ["I027", "FC", "SAN MAURO DI ROMA", 0],
    ["I027", "FC", "SAN MAURO DI ROMAGNA", 0],
    ["H712", "VR", "SAN MAURO DI SALINE", 1],
    ["I029", "MT", "SAN MAURO FORTE", 1],
    ["I032", "SA", "SAN MAURO LA BRUCA", 1],
    ["I026", "KR", "SAN MAURO MARCHESATO", 1],
    ["I027", "FC", "SAN MAURO PASCOLI", 1],
    ["I030", "TO", "SAN MAURO TORINESE", 1],
    ["H749", "BA", "SAN MICHELE", 0],
    ["I033", "AL", "SAN MICHELE", 0],
    ["I034", "AV", "SAN MICHELE", 0],
    ["I035", "CT", "SAN MICHELE", 0],
    ["I036", "CR", "SAN MICHELE", 0],
    ["I037", "CN", "SAN MICHELE", 0],
    ["I038", "CN", "SAN MICHELE", 0],
    ["I039", "RM", "SAN MICHELE", 0],
    ["I040", "VE", "SAN MICHELE", 0],
    ["I041", "VR", "SAN MICHELE", 0],
    ["I040", "VE", "SAN MICHELE AL TAGLIAMENTO", 1],
    ["I042", "TN", "SAN MICHELE ALL'ADIGE", 1],
    ["I036", "CR", "SAN MICHELE CREMASCO", 0],
    ["I033", "AT", "SAN MICHELE D'ASTI", 0],
    ["H117", "VE", "SAN MICHELE DEL QUARTO", 0],
    ["I043", "PD", "SAN MICHELE DELLE BADESSE", 0],
    ["I035", "CT", "SAN MICHELE DI GANZARIA", 1],
    ["I044", "TS", "SAN MICHELE DI POSTUMIA", 0],
    ["I034", "AV", "SAN MICHELE DI SERINO", 1],
    ["I041", "VR", "SAN MICHELE EXTRA", 0],
    ["I039", "VT", "SAN MICHELE IN TEVERINA", 0],
    ["I037", "CN", "SAN MICHELE MONDOVI'", 1],
    ["I038", "CN", "SAN MICHELE PRAZZO", 0],
    ["I045", "BR", "SAN MICHELE SALENTINO", 1],
    ["I046", "PI", "SAN MINIATO", 1],
    ["I024", "TO", "SAN MORIZIO", 0],
    ["I024", "TO", "SAN MORIZIO CANAVESE", 0],
    ["I047", "VI", "SAN NAZARIO", 0],
    ["I049", "BN", "SAN NAZZARO", 1],
    ["I050", "BS", "SAN NAZZARO", 0],
    ["I051", "CO", "SAN NAZZARO", 0],
    ["B449", "BN", "SAN NAZZARO CALVI", 0],
    ["I050", "BS", "SAN NAZZARO MELLA", 0],
    ["I052", "NO", "SAN NAZZARO SESIA", 1],
    ["I051", "CO", "SAN NAZZARO VAL CAVARGNA", 1],
    ["I053", "BA", "SAN NICANDRO", 0],
    ["I054", "FG", "SAN NICANDRO", 0],
    ["I055", "AQ", "SAN NICANDRO", 0],
    ["I055", "AQ", "SAN NICANDRO D'AQUILA", 0],
    ["I054", "FG", "SAN NICANDRO GARGANICO", 1],
    ["G923", "RO", "SAN NICCOLO", 0],
    ["I056", "CE", "SAN NICOLA", 0],
    ["I057", "CZ", "SAN NICOLA", 0],
    ["I058", "CZ", "SAN NICOLA", 0],
    ["I060", "CS", "SAN NICOLA ARCELLA", 1],
    ["I061", "AV", "SAN NICOLA BARONIA", 1],
    ["I058", "VV", "SAN NICOLA DA CRISSA", 1],
    ["I057", "KR", "SAN NICOLA DELL'ALTO", 1],
    ["I056", "CE", "SAN NICOLA LA STRADA", 1],
    ["I062", "BN", "SAN NICOLA MANFREDI", 1],
    ["I063", "BL", "SAN NICOLO'", 0],
    ["A368", "OR", "SAN NICOLO' D'ARCIDANO", 1],
    ["I063", "BL", "SAN NICOLO' DI COMELICO", 1],
    ["G383", "CA", "SAN NICOLO' GERREI", 0],
    ["G383", "SU", "SAN NICOLO' GERREI", 1],
    ["I064", "MI", "SAN NOVO", 0],
    ["I065", "BZ", "SAN PANCRAZIO", 1],
    ["I066", "LE", "SAN PANCRAZIO", 0],
    ["I067", "MI", "SAN PANCRAZIO", 0],
    ["I068", "PR", "SAN PANCRAZIO", 0],
    ["I067", "MI", "SAN PANCRAZIO AL COLLE", 0],
    ["I068", "PR", "SAN PANCRAZIO PARMENSE", 0],
    ["I066", "BR", "SAN PANCRAZIO SALENTINO", 1],
    ["I069", "CA", "SAN PANTALEO", 0],
    ["G407", "BS", "SAN PAOLO", 1],
    ["I070", "AL", "SAN PAOLO", 0],
    ["I071", "AN", "SAN PAOLO", 0],
    ["I072", "FG", "SAN PAOLO", 0],
    ["I073", "NA", "SAN PAOLO", 0],
    ["I074", "NO", "SAN PAOLO", 0],
    ["B906", "PZ", "SAN PAOLO ALBANESE", 1],
    ["I073", "NA", "SAN PAOLO BEL SITO", 1],
    ["I074", "BI", "SAN PAOLO CERVO", 0],
    ["B310", "BG", "SAN PAOLO D'ARGON", 1],
    ["I070", "AL", "SAN PAOLO DELLA VALLE", 0],
    ["I072", "FG", "SAN PAOLO DI CIVITATE", 1],
    ["I071", "AN", "SAN PAOLO DI JESI", 1],
    ["I075", "CR", "SAN PAOLO RIPA D'OGLIO", 0],
    ["I076", "AT", "SAN PAOLO SOLBRITO", 1],
    ["I077", "MI", "SAN PEDRINO", 0],
    ["I078", "TS", "SAN PELAGIO", 0],
    ["I079", "BG", "SAN PELLEGRINO", 0],
    ["I079", "BG", "SAN PELLEGRINO TERME", 1],
    ["I080", "PV", "SAN PERONE", 0],
    ["I081", "GE", "SAN PIER D'ARENA", 0],
    ["I082", "GO", "SAN PIER D'ISONZO", 1],
    ["I083", "RC", "SAN PIER FEDELE", 0],
    ["I084", "ME", "SAN PIER MONFORTE", 0],
    ["I084", "ME", "SAN PIER NICETO", 1],
    ["I085", "FI", "SAN PIERO A SIEVE", 0],
    ["I086", "ME", "SAN PIERO PATTI", 1],
    ["I083", "RC", "SAN PIETRO", 0],
    ["I087", "BZ", "SAN PIETRO", 0],
    ["I088", "BL", "SAN PIETRO", 0],
    ["I089", "SA", "SAN PIETRO", 0],
    ["I090", "TO", "SAN PIETRO", 0],
    ["I091", "UD", "SAN PIETRO", 0],
    ["I093", "CZ", "SAN PIETRO A MAIDA", 1],
    ["I094", "NA", "SAN PIETRO A PATIERNO", 0],
    ["I095", "CZ", "SAN PIETRO A TIRIOLO", 0],
    ["I092", "UD", "SAN PIETRO AL NATISONE", 1],
    ["I089", "SA", "SAN PIETRO AL TANAGRO", 1],
    ["I095", "CZ", "SAN PIETRO APOSTOLO", 1],
    ["I096", "IS", "SAN PIETRO AVELLANA", 1],
    ["I097", "MI", "SAN PIETRO BESTAZZO", 0],
    ["I088", "BL", "SAN PIETRO CADORE", 0],
    ["I098", "CT", "SAN PIETRO CLARENZA", 1],
    ["I099", "MI", "SAN PIETRO CUSICO", 0],
    ["I106", "BG", "SAN PIETRO D'ORZIO", 0],
    ["I092", "UD", "SAN PIETRO DEGLI SCHIAVI", 0],
    ["I100", "TS", "SAN PIETRO DEL CARSO", 0],
    ["I082", "TS", "SAN PIETRO DELL'ISONZO", 0],
    ["I101", "TV", "SAN PIETRO DI BARBOZZA", 0],
    ["I088", "BL", "SAN PIETRO DI CADORE", 1],
    ["I102", "RC", "SAN PIETRO DI CARIDA'", 1],
    ["I103", "TV", "SAN PIETRO DI FELETTO", 1],
    ["I091", "GO", "SAN PIETRO DI GORIZIA", 0],
    ["I104", "CN", "SAN PIETRO DI MONTEROSSO", 0],
    ["I105", "VR", "SAN PIETRO DI MORUBIO", 1],
    ["I107", "PD", "SAN PIETRO ENGU", 0],
    ["I108", "CS", "SAN PIETRO IN AMANTEA", 1],
    ["I109", "VR", "SAN PIETRO IN CARIANO", 1],
    ["I110", "BO", "SAN PIETRO IN CASALE", 1],
    ["G788", "PC", "SAN PIETRO IN CERRO", 1],
    ["I111", "CE", "SAN PIETRO IN CUROLIS", 0],
    ["I107", "PD", "SAN PIETRO IN GU", 1],
    ["I114", "CS", "SAN PIETRO IN GUARANO", 1],
    ["I115", "LE", "SAN PIETRO IN LAMA", 1],
    ["I087", "BZ", "SAN PIETRO IN VALLE AURINA", 0],
    ["I112", "NA", "SAN PIETRO INDELICATO", 0],
    ["I113", "CE", "SAN PIETRO INFINE", 1],
    ["I112", "AV", "SAN PIETRO IRPINO", 0],
    ["F529", "PD", "SAN PIETRO MONTAGNON", 0],
    ["I116", "NO", "SAN PIETRO MOSEZZO", 1],
    ["I117", "VI", "SAN PIETRO MUSSOLINO", 1],
    ["I118", "CA", "SAN PIETRO PULA", 0],
    ["I086", "ME", "SAN PIETRO SOPRA PATTI", 0],
    ["I090", "TO", "SAN PIETRO VAL LEMINA", 1],
    ["I119", "BR", "SAN PIETRO VERNOTICO", 1],
    ["I120", "PD", "SAN PIETRO VIMINARIO", 1],
    ["I121", "AQ", "SAN PIO DELLE CAMERE", 1],
    ["I122", "CB", "SAN POLO", 0],
    ["I123", "RE", "SAN POLO", 0],
    ["I124", "TV", "SAN POLO", 0],
    ["I123", "RE", "SAN POLO D'ENZA", 1],
    ["I123", "RE", "SAN POLO D'ENZA IN CAVIANO", 0],
    ["I125", "RM", "SAN POLO DEI CAVALIERI", 1],
    ["I124", "TV", "SAN POLO DI PIAVE", 1],
    ["I122", "CB", "SAN POLO MATESE", 1],
    ["I122", "CB", "SAN POLOMATESE", 0],
    ["I126", "TO", "SAN PONSO", 1],
    ["I126", "AO", "SAN PONSO CANAVESE", 0],
    ["I127", "PV", "SAN PONZO", 0],
    ["I127", "PV", "SAN PONZO SEMOLA", 0],
    ["I128", "MO", "SAN POSSIDONIO", 1],
    ["I129", "AV", "SAN POTITO", 0],
    ["I130", "CE", "SAN POTITO", 0],
    ["I130", "CE", "SAN POTITO SANNITICO", 1],
    ["I129", "AV", "SAN POTITO ULTRA", 1],
    ["I131", "CE", "SAN PRISCO", 1],
    ["I132", "RC", "SAN PROCOPIO", 1],
    ["I133", "MO", "SAN PROSPERO", 1],
    ["I134", "GE", "SAN QUIRICO", 0],
    ["I135", "SI", "SAN QUIRICO", 0],
    ["I135", "SI", "SAN QUIRICO D'ORCIA", 1],
    ["I134", "GE", "SAN QUIRICO IN VAL DI POLCEVERA", 0],
    ["I136", "PN", "SAN QUIRINO", 1],
    ["I137", "TO", "SAN RAFFAELE CIMENA", 1],
    ["H675", "AO", "SAN REMIGIO", 0],
    ["I138", "IM", "SAN REMO", 0],
    ["I139", "RC", "SAN ROBERTO", 1],
    ["I140", "LO", "SAN ROCCO AL PORTO", 1],
    ["I142", "LU", "SAN ROMANO IN GARFAGNANA", 1],
    ["E519", "GE", "SAN RUFINO", 0],
    ["I143", "SA", "SAN RUFO", 1],
    ["I144", "AL", "SAN SALVATORE", 0],
    ["I145", "BN", "SAN SALVATORE", 0],
    ["I146", "CR", "SAN SALVATORE", 0],
    ["I147", "ME", "SAN SALVATORE", 0],
    ["I147", "ME", "SAN SALVATORE DI FITALIA", 1],
    ["I144", "AL", "SAN SALVATORE MONFERRATO", 1],
    ["I145", "BN", "SAN SALVATORE TELESINO", 1],
    ["I148", "CH", "SAN SALVO", 1],
    ["I149", "CR", "SAN SAVINO", 0],
    ["I150", "AL", "SAN SEBASTIANO", 0],
    ["I151", "NA", "SAN SEBASTIANO", 0],
    ["I152", "TO", "SAN SEBASTIANO", 0],
    ["I151", "NA", "SAN SEBASTIANO AL VESUVIO", 1],
    ["I150", "AL", "SAN SEBASTIANO CURONE", 1],
    ["I152", "TO", "SAN SEBASTIANO DA PO", 1],
    ["I153", "PR", "SAN SECONDO", 0],
    ["I154", "TO", "SAN SECONDO", 0],
    ["I154", "TO", "SAN SECONDO DI PINEROLO", 1],
    ["I153", "PR", "SAN SECONDO PARMENSE", 1],
    ["I156", "MC", "SAN SEVERINO", 0],
    ["I157", "PZ", "SAN SEVERINO", 0],
    ["I157", "PZ", "SAN SEVERINO LUCANO", 1],
    ["I156", "MC", "SAN SEVERINO MARCHE", 1],
    ["F138", "SA", "SAN SEVERINO ROTA", 0],
    ["I158", "FG", "SAN SEVERO", 1],
    ["I159", "BZ", "SAN SIGISMONDO", 0],
    ["I160", "CR", "SAN SILLO", 0],
    ["I161", "CH", "SAN SILVESTRO", 0],
    ["I162", "CO", "SAN SIRO", 1],
    ["I163", "AV", "SAN SOSSIO", 0],
    ["I163", "AV", "SAN SOSSIO BARONIA", 1],
    ["I164", "CZ", "SAN SOSTENE", 1],
    ["I165", "CS", "SAN SOSTI", 1],
    ["I166", "CA", "SAN SPERATE", 0],
    ["I166", "SU", "SAN SPERATE", 1],
    ["I373", "VE", "SAN STINO DI LIVENZA", 1],
    ["I261", "CE", "SAN TAMMARO", 1],
    ["I328", "ME", "SAN TEODORO", 1],
    ["I329", "NU", "SAN TEODORO", 0],
    ["I329", "SS", "SAN TEODORO", 1],
    ["I345", "BL", "SAN TIZIANO DI GOIMNA", 0],
    ["I347", "BL", "SAN TOMASO", 0],
    ["I347", "BL", "SAN TOMASO AGORDINO", 1],
    ["I376", "CH", "SAN VALENTINO", 0],
    ["I377", "SA", "SAN VALENTINO", 0],
    ["I379", "BZ", "SAN VALENTINO", 0],
    ["I378", "BZ", "SAN VALENTINO AL BRENNERO", 0],
    ["I379", "BZ", "SAN VALENTINO ALLA MUTTA", 0],
    ["I376", "PE", "SAN VALENTINO IN ABRUZZO CITERIORE", 1],
    ["H019", "BZ", "SAN VALENTINO IN PREDOI", 0],
    ["I377", "SA", "SAN VALENTINO TORIO", 1],
    ["I380", "PV", "SAN VARESE", 0],
    ["I381", "TR", "SAN VENANZO", 1],
    ["I382", "TV", "SAN VENDEMIANO", 1],
    ["I383", "CA", "SAN VERO CONGIUS", 0],
    ["I384", "OR", "SAN VERO MILIS", 1],
    ["I385", "BS", "SAN VIGILIO", 0],
    ["I387", "CB", "SAN VINCENZO", 0],
    ["I388", "CS", "SAN VINCENZO", 0],
    ["I389", "AQ", "SAN VINCENZO", 0],
    ["I390", "LI", "SAN VINCENZO", 1],
    ["I387", "CB", "SAN VINCENZO A VOLTURNO", 0],
    ["H676", "AO", "SAN VINCENZO DELLA FONTE", 0],
    ["I388", "CS", "SAN VINCENZO LA COSTA", 1],
    ["I389", "AQ", "SAN VINCENZO VALLE ROVETO", 1],
    ["I391", "NA", "SAN VITALIANO", 1],
    ["I392", "BL", "SAN VITO", 0],
    ["I393", "CZ", "SAN VITO", 0],
    ["I394", "CH", "SAN VITO", 0],
    ["I395", "CR", "SAN VITO", 0],
    ["I396", "LE", "SAN VITO", 0],
    ["I397", "MI", "SAN VITO", 0],
    ["I398", "PG", "SAN VITO", 0],
    ["I399", "PU", "SAN VITO", 0],
    ["I400", "RM", "SAN VITO", 0],
    ["I401", "VI", "SAN VITO", 0],
    ["I402", "CA", "SAN VITO", 0],
    ["I402", "SU", "SAN VITO", 1],
    ["I403", "PN", "SAN VITO AL TAGLIAMENTO", 1],
    ["I404", "UD", "SAN VITO AL TORRE", 1],
    ["I394", "CH", "SAN VITO CHIETINO", 1],
    ["I396", "BR", "SAN VITO DEI NORMANNI", 1],
    ["I392", "BL", "SAN VITO DI CADORE", 1],
    ["I405", "UD", "SAN VITO DI FAGAGNA", 1],
    ["I401", "VI", "SAN VITO DI LEGUZZANO", 1],
    ["I406", "GO", "SAN VITO DI VIPACCO", 0],
    ["I397", "MI", "SAN VITO E MARTA", 0],
    ["I395", "CR", "SAN VITO E MODESTO", 0],
    ["I398", "TR", "SAN VITO IN MONTE", 0],
    ["I407", "TP", "SAN VITO LO CAPO", 1],
    ["I400", "RM", "SAN VITO ROMANO", 1],
    ["I399", "PU", "SAN VITO SUL CESANO", 0],
    ["I393", "CZ", "SAN VITO SULLO IONIO", 1],
    ["I408", "CE", "SAN VITTORE", 0],
    ["I409", "MI", "SAN VITTORE", 0],
    ["I408", "FR", "SAN VITTORE DEL LAZIO", 1],
    ["I409", "MI", "SAN VITTORE OLONA", 1],
    ["I412", "BS", "SAN ZENO", 0],
    ["I413", "PV", "SAN ZENO", 0],
    ["I414", "VR", "SAN ZENO DI MONTAGNA", 1],
    ["I413", "PV", "SAN ZENO E FOPPA", 0],
    ["I412", "BS", "SAN ZENO NAVIGLIO", 1],
    ["I415", "MI", "SAN ZENONE", 0],
    ["I416", "PV", "SAN ZENONE", 0],
    ["I417", "TV", "SAN ZENONE", 0],
    ["I415", "MI", "SAN ZENONE AL LAMBRO", 1],
    ["I416", "PV", "SAN ZENONE AL PO", 1],
    ["I417", "TV", "SAN ZENONE DEGLI EZZELINI", 1],
    ["H757", "LE", "SANARICA", 1],
    ["H821", "BI", "SANDIGLIANO", 1],
    ["H829", "VI", "SANDRIGO", 1],
    ["H851", "CN", "SANFRE'", 1],
    ["H852", "CN", "SANFRONT", 1],
    ["H852", "CN", "SANFRONTE", 0],
    ["H855", "TO", "SANGANO", 1],
    ["H872", "VA", "SANGIANO", 1],
    ["H877", "CS", "SANGINETO", 1],
    ["H944", "VR", "SANGUINETTO", 1],
    ["H974", "CA", "SANLURI", 0],
    ["H974", "SU", "SANLURI", 1],
    ["I048", "PV", "SANNAZZARO", 0],
    ["I048", "PV", "SANNAZZARO DE' BURGONDI", 1],
    ["I053", "BA", "SANNICANDRO DI BARI", 1],
    ["I054", "FG", "SANNICANDRO GARGANICO", 0],
    ["I059", "LE", "SANNICOLA", 1],
    ["I138", "IM", "SANREMO", 1],
    ["I155", "AR", "SANSEPOLCRO", 1],
    ["I167", "CO", "SANT'ABBONDIO", 0],
    ["I189", "IS", "SANT'AGAPITO", 1],
    ["I190", "AL", "SANT'AGATA", 0],
    ["I191", "BO", "SANT'AGATA", 0],
    ["I192", "CS", "SANT'AGATA", 0],
    ["I193", "FG", "SANT'AGATA", 0],
    ["I194", "MI", "SANT'AGATA", 0],
    ["I195", "NO", "SANT'AGATA", 0],
    ["I196", "RA", "SANT'AGATA", 0],
    ["I191", "BO", "SANT'AGATA BOLOGNESE", 1],
    ["I197", "BN", "SANT'AGATA DE' GOTI", 1],
    ["I198", "RC", "SANT'AGATA DEL BIANCO", 1],
    ["I192", "CS", "SANT'AGATA DI ESARO", 1],
    ["I199", "ME", "SANT'AGATA DI MILITELLO", 1],
    ["I193", "FG", "SANT'AGATA DI PUGLIA", 1],
    ["I200", "AV", "SANT'AGATA DI SOTTO", 0],
    ["I201", "PU", "SANT'AGATA FELTRIA", 0],
    ["I201", "RN", "SANT'AGATA FELTRIA", 1],
    ["I190", "AL", "SANT'AGATA FOSSILI", 1],
    ["I200", "AV", "SANT'AGATA IRPINA", 0],
    ["I202", "CT", "SANT'AGATA LI BATTIATI", 1],
    ["I194", "MI", "SANT'AGATA MARTESANA", 0],
    ["I195", "NO", "SANT'AGATA SOPRA CANNOBIO", 0],
    ["I196", "RA", "SANT'AGATA SUL SANTERNO", 1],
    ["I208", "NA", "SANT'AGNELLO", 1],
    ["I209", "FE", "SANT'AGOSTINO", 0],
    ["I210", "CN", "SANT'ALBANO", 0],
    ["I211", "PV", "SANT'ALBANO", 0],
    ["I211", "PV", "SANT'ALBANO DI BOBBIO", 0],
    ["I210", "CN", "SANT'ALBANO STURA", 1],
    ["I212", "BS", "SANT'ALESSANDRO", 0],
    ["I213", "PV", "SANT'ALESSIO", 0],
    ["I214", "RC", "SANT'ALESSIO", 0],
    ["I213", "PV", "SANT'ALESSIO CON VIALONE", 1],
    ["I214", "RC", "SANT'ALESSIO IN ASPROMONTE", 1],
    ["I215", "ME", "SANT'ALESSIO SICULO", 1],
    ["I216", "CT", "SANT'ALFIO", 1],
    ["I256", "CE", "SANT'AMBROGIO", 0],
    ["I257", "CO", "SANT'AMBROGIO", 0],
    ["I258", "TO", "SANT'AMBROGIO", 0],
    ["I259", "VR", "SANT'AMBROGIO", 0],
    ["I258", "TO", "SANT'AMBROGIO DI TORINO", 1],
    ["I259", "VR", "SANT'AMBROGIO DI VALPOLICELLA", 1],
    ["I256", "FR", "SANT'AMBROGIO SUL GARIGLIANO", 1],
    ["I257", "VA", "SANT'AMBROGIO-OLONA", 0],
    ["I262", "NA", "SANT'ANASTASIA", 1],
    ["D429", "MC", "SANT'ANATOLIA", 0],
    ["I263", "PG", "SANT'ANATOLIA", 0],
    ["I263", "PG", "SANT'ANATOLIA DI NARCO", 1],
    ["I264", "AV", "SANT'ANDREA", 0],
    ["I265", "FR", "SANT'ANDREA", 0],
    ["I266", "CZ", "SANT'ANDREA", 0],
    ["I268", "PU", "SANT'ANDREA", 0],
    ["I269", "UD", "SANT'ANDREA", 0],
    ["I266", "CZ", "SANT'ANDREA APOSTOLO DELLO IONIO", 1],
    ["I265", "FR", "SANT'ANDREA DEL GARIGLIANO", 1],
    ["A136", "TV", "SANT'ANDREA DI CAVASAGRA", 0],
    ["I264", "AV", "SANT'ANDREA DI CONZA", 1],
    ["I269", "GO", "SANT'ANDREA DI GORIZIA", 0],
    ["I268", "PU", "SANT'ANDREA DI SUASA", 0],
    ["I270", "CE", "SANT'ANDREA DI VALLEFREDDA", 0],
    ["I271", "CA", "SANT'ANDREA FRIUS", 0],
    ["I271", "SU", "SANT'ANDREA FRIUS", 1],
    ["I272", "BZ", "SANT'ANDREA IN MONTE", 0],
    ["I273", "CE", "SANT'ANGELO", 0],
    ["I274", "MI", "SANT'ANGELO", 0],
    ["I275", "PD", "SANT'ANGELO", 0],
    ["I276", "PV", "SANT'ANGELO", 0],
    ["I277", "BN", "SANT'ANGELO A CUPOLO", 1],
    ["I278", "SA", "SANT'ANGELO A FASANELLA", 1],
    ["I280", "AV", "SANT'ANGELO A SCALA", 1],
    ["I279", "AV", "SANT'ANGELO ALL'ESCA", 1],
    ["I273", "CE", "SANT'ANGELO D'ALIFE", 1],
    ["I281", "AV", "SANT'ANGELO DEI LOMBARDI", 1],
    ["I282", "IS", "SANT'ANGELO DEL PESCO", 1],
    ["I283", "ME", "SANT'ANGELO DI BROLO", 1],
    ["I275", "PD", "SANT'ANGELO DI PIOVE DI SACCO", 1],
    ["I284", "RM", "SANT'ANGELO IN CAPOCCIA", 0],
    ["I238", "CB", "SANT'ANGELO IN GROTTE", 0],
    ["I285", "PU", "SANT'ANGELO IN LIZZOLA", 0],
    ["I286", "MC", "SANT'ANGELO IN PONTANO", 1],
    ["I287", "PU", "SANT'ANGELO IN VADO", 1],
    ["I288", "PZ", "SANT'ANGELO LE FRATTE", 1],
    ["I289", "CB", "SANT'ANGELO LIMOSANO", 1],
    ["I274", "LO", "SANT'ANGELO LODIGIANO", 1],
    ["I276", "PV", "SANT'ANGELO LOMELLINA", 1],
    ["I290", "AG", "SANT'ANGELO MUXARO", 1],
    ["I284", "RM", "SANT'ANGELO ROMANO", 1],
    ["M209", "CA", "SANT'ANNA ARRESI", 0],
    ["M209", "SU", "SANT'ANNA ARRESI", 1],
    ["I292", "VR", "SANT'ANNA D'ALFAEDO", 1],
    ["I293", "NA", "SANT'ANTIMO", 1],
    ["I294", "CA", "SANT'ANTIOCO", 0],
    ["I294", "SU", "SANT'ANTIOCO", 1],
    ["I295", "MI", "SANT'ANTONINO", 0],
    ["I296", "TO", "SANT'ANTONINO", 0],
    ["I296", "TO", "SANT'ANTONINO DI SUSA", 1],
    ["I295", "MI", "SANT'ANTONINO TICINO", 0],
    ["I297", "BG", "SANT'ANTONIO", 0],
    ["I298", "CA", "SANT'ANTONIO", 0],
    ["I299", "PC", "SANT'ANTONIO", 0],
    ["I299", "PC", "SANT'ANTONIO A TREBBIA", 0],
    ["I300", "NA", "SANT'ANTONIO ABATE", 1],
    ["I297", "BG", "SANT'ANTONIO D'ADDA", 0],
    ["M276", "SS", "SANT'ANTONIO DI GALLURA", 1],
    ["I298", "OR", "SANT'ANTONIO RUINAS", 0],
    ["I302", "FR", "SANT'APOLLINARE", 1],
    ["I303", "RO", "SANT'APOLLINARE", 0],
    ["I303", "RO", "SANT'APOLLINARE CON SELVA", 0],
    ["F557", "AV", "SANT'ARCANGELO", 0],
    ["I304", "FC", "SANT'ARCANGELO", 0],
    ["I305", "PZ", "SANT'ARCANGELO", 1],
    ["I304", "FC", "SANT'ARCANGELO DI ROMAGNA", 0],
    ["F557", "BN", "SANT'ARCANGELO TRIMONTE", 1],
    ["I306", "CE", "SANT'ARPINO", 1],
    ["I307", "SA", "SANT'ARSENIO", 1],
    ["I317", "SA", "SANT'EGIDIO", 0],
    ["I318", "TE", "SANT'EGIDIO", 0],
    ["I318", "TE", "SANT'EGIDIO ALLA VIBRATA", 1],
    ["I317", "SA", "SANT'EGIDIO DEL MONTE ALBINO", 1],
    ["I319", "PD", "SANT'ELENA", 1],
    ["Z340", "EE", "SANT'ELENA (ISOLA)", 1],
    ["B466", "IS", "SANT'ELENA SANNITA", 1],
    ["I320", "CB", "SANT'ELIA", 0],
    ["I321", "CE", "SANT'ELIA", 0],
    ["I322", "CZ", "SANT'ELIA", 0],
    ["I323", "PG", "SANT'ELIA", 0],
    ["I320", "CB", "SANT'ELIA A PIANISI", 1],
    ["I321", "FR", "SANT'ELIA FIUMERAPIDO", 1],
    ["I323", "PG", "SANT'ELIA REATINO", 0],
    ["I324", "AP", "SANT'ELPIDIO A MARE", 0],
    ["I324", "FM", "SANT'ELPIDIO A MARE", 1],
    ["I325", "AP", "SANT'ELPIDIO MORICO", 0],
    ["I331", "NA", "SANT'ERASMO", 0],
    ["B031", "PD", "SANT'EUFEMIA", 0],
    ["I186", "BS", "SANT'EUFEMIA", 0],
    ["I332", "CH", "SANT'EUFEMIA", 0],
    ["I332", "PE", "SANT'EUFEMIA A MAIELLA", 1],
    ["I333", "RC", "SANT'EUFEMIA D'ASPROMONTE", 1],
    ["I186", "BS", "SANT'EUFEMIA DELLA FONTE", 0],
    ["I334", "CZ", "SANT'EUFEMIA LAMEZIA", 0],
    ["H673", "AO", "SANT'EUGENDO", 0],
    ["I335", "CH", "SANT'EUSANIO", 0],
    ["I336", "AQ", "SANT'EUSANIO", 0],
    ["I335", "CH", "SANT'EUSANIO DEL SANGRO", 1],
    ["I336", "AQ", "SANT'EUSANIO FORCONESE", 1],
    ["I340", "GE", "SANT'ILARIO", 0],
    ["I341", "RC", "SANT'ILARIO", 0],
    ["I342", "RE", "SANT'ILARIO", 0],
    ["I342", "RE", "SANT'ILARIO D'ENZA", 1],
    ["I341", "RC", "SANT'ILARIO DELLO IONIO", 1],
    ["I340", "GE", "SANT'ILARIO LIGURE", 0],
    ["I344", "PU", "SANT'IPPOLITO", 1],
    ["D630", "UD", "SANT'ODORICO", 0],
    ["I346", "GE", "SANT'OLCESE", 1],
    ["I348", "TE", "SANT'OMERO", 1],
    ["I349", "BG", "SANT'OMOBONO IMAGNA", 0],
    ["M333", "BG", "SANT'OMOBONO TERME", 1],
    ["I350", "VV", "SANT'ONOFRIO", 1],
    ["I352", "RM", "SANT'ORESTE", 1],
    ["I354", "TN", "SANT'ORSOLA", 0],
    ["I354", "TN", "SANT'ORSOLA TERME", 1],
    ["I375", "PD", "SANT'URBANO", 1],
    ["I168", "BG", "SANTA BRIGIDA", 1],
    ["I169", "CL", "SANTA CATERINA", 0],
    ["I170", "CZ", "SANTA CATERINA", 0],
    ["I171", "CS", "SANTA CATERINA", 0],
    ["I171", "CS", "SANTA CATERINA ALBANESE", 1],
    ["I170", "CZ", "SANTA CATERINA DELLO IONIO", 1],
    ["I169", "CL", "SANTA CATERINA VILLARMOSA", 1],
    ["I172", "LE", "SANTA CESAREA", 0],
    ["I172", "LE", "SANTA CESAREA TERME", 1],
    ["I173", "BZ", "SANTA CRISTINA", 0],
    ["I174", "PA", "SANTA CRISTINA", 0],
    ["I175", "PV", "SANTA CRISTINA", 0],
    ["I176", "RC", "SANTA CRISTINA", 0],
    ["I176", "RC", "SANTA CRISTINA D'ASPROMONTE", 1],
    ["I175", "PV", "SANTA CRISTINA E BISSONE", 1],
    ["I174", "PA", "SANTA CRISTINA GELA", 1],
    ["I173", "BZ", "SANTA CRISTINA IN GARDENA", 0],
    ["I173", "BZ", "SANTA CRISTINA VALGARDENA", 1],
    ["I177", "FI", "SANTA CROCE", 0],
    ["I178", "RG", "SANTA CROCE", 0],
    ["I178", "RG", "SANTA CROCE CAMERINA", 1],
    ["I179", "BN", "SANTA CROCE DEL SANNIO", 1],
    ["I180", "GO", "SANTA CROCE DI AIDUSSINA", 0],
    ["I181", "CB", "SANTA CROCE DI MAGLIANO", 1],
    ["I179", "BN", "SANTA CROCE DI MORCONE", 0],
    ["I177", "PI", "SANTA CROCE SULL'ARNO", 1],
    ["I183", "CS", "SANTA DOMENICA", 0],
    ["I184", "ME", "SANTA DOMENICA", 0],
    ["I183", "CS", "SANTA DOMENICA TALAO", 1],
    ["I184", "ME", "SANTA DOMENICA VITTORIA", 1],
    ["I185", "AG", "SANTA ELISABETTA", 1],
    ["I186", "BS", "SANTA EUFEMIA", 0],
    ["I187", "GR", "SANTA FIORA", 1],
    ["I188", "PA", "SANTA FLAVIA", 1],
    ["I203", "PV", "SANTA GIULETTA", 1],
    ["I204", "SV", "SANTA GIULIA", 0],
    ["I205", "OR", "SANTA GIUSTA", 1],
    ["I206", "BL", "SANTA GIUSTINA", 1],
    ["I207", "PD", "SANTA GIUSTINA IN COLLE", 1],
    ["I217", "PI", "SANTA LUCE", 1],
    ["I218", "PI", "SANTA LUCE-ORCIANO", 0],
    ["I219", "AV", "SANTA LUCIA", 0],
    ["I220", "ME", "SANTA LUCIA", 0],
    ["I221", "TV", "SANTA LUCIA", 0],
    ["I222", "GO", "SANTA LUCIA D'ISONZO", 0],
    ["I220", "ME", "SANTA LUCIA DEL MELA", 1],
    ["I221", "TV", "SANTA LUCIA DI PIAVE", 1],
    ["I219", "AV", "SANTA LUCIA DI SERINO", 1],
    ["I223", "BZ", "SANTA MADDALENA IN CASIES", 0],
    ["I224", "AG", "SANTA MARGHERITA", 0],
    ["I225", "GE", "SANTA MARGHERITA", 0],
    ["I226", "PD", "SANTA MARGHERITA", 0],
    ["I227", "PV", "SANTA MARGHERITA", 0],
    ["I228", "PV", "SANTA MARGHERITA", 0],
    ["I229", "TN", "SANTA MARGHERITA", 0],
    ["I226", "PD", "SANTA MARGHERITA D'ADIGE", 0],
    ["I224", "AG", "SANTA MARGHERITA DI BELICE", 1],
    ["I227", "PV", "SANTA MARGHERITA DI BOBBIO", 0],
    ["I230", "PV", "SANTA MARGHERITA DI STAFFORA", 1],
    ["I225", "GE", "SANTA MARGHERITA LIGURE", 1],
    ["I228", "PV", "SANTA MARGHERITA PO", 0],
    ["C717", "CS", "SANTA MARIA", 0],
    ["I231", "AP", "SANTA MARIA", 0],
    ["I232", "PI", "SANTA MARIA A MONTE", 1],
    ["I233", "CE", "SANTA MARIA A VICO", 1],
    ["I234", "CE", "SANTA MARIA CAPUA VETERE", 1],
    ["M284", "SS", "SANTA MARIA COGHINAS", 1],
    ["C717", "CS", "SANTA MARIA DEL CEDRO", 1],
    ["I238", "IS", "SANTA MARIA DEL MOLISE", 1],
    ["I239", "VA", "SANTA MARIA DEL MONTE", 0],
    ["I231", "AP", "SANTA MARIA DEL TRONTO", 0],
    ["I235", "CR", "SANTA MARIA DELLA CROCE", 0],
    ["I236", "PV", "SANTA MARIA DELLA STRADA", 0],
    ["I237", "PV", "SANTA MARIA DELLA VERSA", 1],
    ["I240", "CT", "SANTA MARIA DI LICODIA", 1],
    ["A946", "PA", "SANTA MARIA DI OGLIASTRO", 0],
    ["I241", "CO", "SANTA MARIA DI ROVAGNATE", 0],
    ["I242", "VE", "SANTA MARIA DI SALA", 1],
    ["I243", "LC", "SANTA MARIA HOE'", 1],
    ["I244", "CH", "SANTA MARIA IMBARO", 1],
    ["A785", "BO", "SANTA MARIA IN DUNO", 0],
    ["I245", "MI", "SANTA MARIA IN PRATO", 0],
    ["I246", "VR", "SANTA MARIA IN STELLE", 0],
    ["M273", "NA", "SANTA MARIA LA CARITA'", 1],
    ["I247", "CE", "SANTA MARIA LA FOSSA", 1],
    ["I248", "UD", "SANTA MARIA LA LONGA", 1],
    ["I234", "CE", "SANTA MARIA MAGGIORE", 0],
    ["I249", "VB", "SANTA MARIA MAGGIORE", 1],
    ["I250", "NO", "SANTA MARIA MAGGIORE E CRANA", 0],
    ["I251", "AN", "SANTA MARIA NUOVA", 1],
    ["I252", "CO", "SANTA MARIA REZZONICO", 0],
    ["I253", "SA", "SANTA MARINA", 1],
    ["I254", "ME", "SANTA MARINA SALINA", 1],
    ["I255", "RM", "SANTA MARINELLA", 1],
    ["I260", "SA", "SANTA MENNA", 0],
    ["I291", "TP", "SANTA NINFA", 1],
    ["I301", "AV", "SANTA PAOLINA", 1],
    ["I308", "KR", "SANTA SEVERINA", 1],
    ["I309", "CS", "SANTA SOFIA", 0],
    ["I310", "FC", "SANTA SOFIA", 1],
    ["I309", "CS", "SANTA SOFIA D'EPIRO", 1],
    ["I311", "ME", "SANTA TERESA", 0],
    ["I312", "SS", "SANTA TERESA", 0],
    ["I311", "ME", "SANTA TERESA DI RIVA", 1],
    ["I312", "SS", "SANTA TERESA GALLURA", 1],
    ["I313", "CO", "SANTA VALERIA", 0],
    ["I314", "CT", "SANTA VENERINA", 1],
    ["I315", "AP", "SANTA VITTORIA", 0],
    ["I316", "CN", "SANTA VITTORIA", 0],
    ["I316", "CN", "SANTA VITTORIA D'ALBA", 1],
    ["I315", "AP", "SANTA VITTORIA IN MATENANO", 0],
    ["I315", "FM", "SANTA VITTORIA IN MATENANO", 1],
    ["I182", "CA", "SANTADI", 0],
    ["I182", "SU", "SANTADI", 1],
    ["I304", "RN", "SANTARCANGELO DI ROMAGNA", 1],
    ["I326", "AQ", "SANTE MARIE", 1],
    ["I327", "TO", "SANTENA", 1],
    ["I330", "BA", "SANTERAMO", 0],
    ["I331", "NA", "SANTERAMO", 0],
    ["I330", "BA", "SANTERAMO IN COLLE", 1],
    ["I337", "VC", "SANTHIA'", 1],
    ["I339", "LT", "SANTI COSMA E DAMIANO", 1],
    ["I338", "BS", "SANTICOLO", 0],
    ["I343", "NO", "SANTINO", 0],
    ["I355", "GO", "SANTO SPIRITO DELLA BAINSIZZA", 0],
    ["I356", "AG", "SANTO STEFANO", 0],
    ["I357", "AV", "SANTO STEFANO", 0],
    ["I358", "BG", "SANTO STEFANO", 0],
    ["I359", "CS", "SANTO STEFANO", 0],
    ["I360", "AQ", "SANTO STEFANO", 0],
    ["I361", "MI", "SANTO STEFANO", 0],
    ["I362", "MI", "SANTO STEFANO", 0],
    ["I363", "GE", "SANTO STEFANO", 0],
    ["I364", "RM", "SANTO STEFANO", 0],
    ["I365", "IM", "SANTO STEFANO AL MARE", 1],
    ["I366", "IM", "SANTO STEFANO AL MARE CON CASTELLARO", 0],
    ["I367", "CN", "SANTO STEFANO BELBO", 1],
    ["I368", "GE", "SANTO STEFANO D'AVETO", 1],
    ["I362", "MI", "SANTO STEFANO DEL CORNO", 0],
    ["I358", "BG", "SANTO STEFANO DEL MONTE DEGLI ANGELI", 0],
    ["I357", "AV", "SANTO STEFANO DEL SOLE", 1],
    ["I369", "ME", "SANTO STEFANO DI BRIGA", 0],
    ["C919", "BL", "SANTO STEFANO DI CADORE", 1],
    ["I370", "ME", "SANTO STEFANO DI CAMASTRA", 1],
    ["I363", "SP", "SANTO STEFANO DI MAGRA", 1],
    ["I359", "CS", "SANTO STEFANO DI ROGLIANO", 1],
    ["I360", "AQ", "SANTO STEFANO DI SESSANIO", 1],
    ["I371", "RC", "SANTO STEFANO IN ASPROMONTE", 1],
    ["I362", "LO", "SANTO STEFANO LODIGIANO", 1],
    ["I356", "AG", "SANTO STEFANO QUISQUINA", 1],
    ["I372", "CN", "SANTO STEFANO ROERO", 1],
    ["I361", "MI", "SANTO STEFANO TICINO", 1],
    ["I373", "VE", "SANTO STINO DI LIVENZA", 0],
    ["I260", "SA", "SANTOMENNA", 1],
    ["I351", "FR", "SANTOPADRE", 1],
    ["I353", "VI", "SANTORSO", 1],
    ["I374", "OR", "SANTU LUSSURGIU", 1],
    ["I386", "PL", "SANVINCENTI", 0],
    ["I410", "SA", "SANZA", 1],
    ["I411", "TN", "SANZENO", 1],
    ["Z341", "EE", "SAO TOME' E PRINCIPE (ISOLE)", 1],
    ["I418", "PD", "SAONARA", 1],
    ["I419", "TN", "SAONE", 0],
    ["E221", "PZ", "SAPONARA", 0],
    ["I420", "ME", "SAPONARA", 1],
    ["E221", "PZ", "SAPONARA DI GRUMENTO", 0],
    ["I420", "ME", "SAPONARA VILLAFRANCA", 0],
    ["I421", "BL", "SAPPADA", 0],
    ["I421", "UD", "SAPPADA", 1],
    ["I422", "SA", "SAPRI", 1],
    ["I423", "CS", "SARACENA", 1],
    ["I424", "RM", "SARACINESCO", 1],
    ["I425", "VI", "SARCEDO", 1],
    ["I426", "PZ", "SARCONI", 1],
    ["I427", "TN", "SARDAGNA", 0],
    ["I428", "CA", "SARDARA", 0],
    ["I428", "SU", "SARDARA", 1],
    ["I429", "AL", "SARDIGLIANO", 1],
    ["I430", "VI", "SAREGO", 1],
    ["I431", "BZ", "SARENTINO", 1],
    ["I432", "AL", "SAREZZANO", 1],
    ["I433", "BS", "SAREZZO", 1],
    ["I434", "PC", "SARMATO", 1],
    ["I435", "TV", "SARMEDE", 1],
    ["I436", "MC", "SARNANO", 1],
    ["I437", "BG", "SARNICO", 1],
    ["I438", "SA", "SARNO", 1],
    ["I439", "TN", "SARNONICO", 1],
    ["I440", "IM", "SAROLA", 0],
    ["I441", "VA", "SARONNO", 1],
    ["I442", "AO", "SARRE", 1],
    ["I443", "CA", "SARROCH", 1],
    ["I444", "FC", "SARSINA", 1],
    ["I445", "SI", "SARTEANO", 1],
    ["I446", "CO", "SARTIRANA", 0],
    ["I447", "PV", "SARTIRANA", 0],
    ["I446", "CO", "SARTIRANA BRIANTEA", 0],
    ["I447", "PV", "SARTIRANA LOMELLINA", 1],
    ["I448", "NU", "SARULE", 1],
    ["I449", "SP", "SARZANA", 1],
    ["I450", "AQ", "SASSA", 0],
    ["I451", "SA", "SASSANO", 1],
    ["I452", "SS", "SASSARI", 1],
    ["I453", "SV", "SASSELLO", 1],
    ["I454", "LI", "SASSETTA", 1],
    ["I455", "BN", "SASSINORO", 1],
    ["C097", "CE", "SASSO", 0],
    ["I456", "IM", "SASSO", 0],
    ["I457", "PZ", "SASSO", 0],
    ["I458", "TN", "SASSO", 0],
    ["G972", "BO", "SASSO BOLOGNESE", 0],
    ["I456", "IM", "SASSO DI BORDIGHERA", 0],
    ["I457", "PZ", "SASSO DI CASTALDA", 1],
    ["G972", "BO", "SASSO MARCONI", 1],
    ["I459", "PU", "SASSOCORVARO", 0],
    ["M413", "PU", "SASSOCORVARO AUDITORE", 1],
    ["I460", "PU", "SASSOFELTRIO", 1],
    ["I461", "AN", "SASSOFERRATO", 1],
    ["I462", "MO", "SASSUOLO", 1],
    ["I463", "CZ", "SATRIANO", 1],
    ["G614", "PZ", "SATRIANO DI LUCANIA", 1],
    ["I464", "UD", "SAURIS", 1],
    ["I466", "TO", "SAUZE D'OULX", 1],
    ["I465", "TO", "SAUZE DI CESANA", 1],
    ["I467", "TA", "SAVA", 1],
    ["Z714", "EE", "SAVAGE (ISOLE)", 1],
    ["I468", "KR", "SAVELLI", 1],
    ["I469", "NA", "SAVIANO", 1],
    ["I470", "CN", "SAVIGLIANO", 1],
    ["I471", "AV", "SAVIGNANO", 0],
    ["I472", "FC", "SAVIGNANO", 0],
    ["I473", "MO", "SAVIGNANO", 0],
    ["M245", "TN", "SAVIGNANO", 0],
    ["I471", "AV", "SAVIGNANO DI PUGLIA", 0],
    ["I472", "FC", "SAVIGNANO DI ROMAGNA", 0],
    ["I471", "AV", "SAVIGNANO IRPINO", 1],
    ["I473", "MO", "SAVIGNANO SUL PANARO", 1],
    ["I472", "FC", "SAVIGNANO SUL RUBICONE", 1],
    ["I474", "BO", "SAVIGNO", 0],
    ["I475", "GE", "SAVIGNONE", 1],
    ["I476", "BS", "SAVIORE", 0],
    ["I476", "BS", "SAVIORE DELL'ADAMELLO", 1],
    ["I477", "ME", "SAVOCA", 1],
    ["I478", "UD", "SAVOGNA", 1],
    ["I479", "UD", "SAVOGNA", 0],
    ["I479", "GO", "SAVOGNA D'ISONZO", 1],
    ["H730", "PZ", "SAVOIA DI LUCANIA", 1],
    ["I480", "SV", "SAVONA", 1],
    ["I481", "TO", "SAVOULX", 0],
    ["I482", "PE", "SCAFA", 1],
    ["I483", "SA", "SCAFATI", 1],
    ["I484", "CN", "SCAGNELLO", 1],
    ["I485", "CS", "SCALA", 0],
    ["I486", "SA", "SCALA", 1],
    ["I485", "CS", "SCALA COELI", 1],
    ["I487", "PV", "SCALDASOLE", 1],
    ["I488", "BZ", "SCALE", 0],
    ["I489", "CS", "SCALEA", 1],
    ["I490", "TO", "SCALENGHE", 1],
    ["I488", "BZ", "SCALERES", 0],
    ["I491", "CN", "SCALETTA", 0],
    ["I492", "ME", "SCALETTA", 0],
    ["I491", "CN", "SCALETTA UZZONE", 0],
    ["I492", "ME", "SCALETTA ZANCLEA", 1],
    ["I493", "AV", "SCAMPITELLA", 1],
    ["I494", "KR", "SCANDALE", 1],
    ["I495", "AT", "SCANDELUZZA", 0],
    ["I496", "RE", "SCANDIANO", 1],
    ["B962", "FI", "SCANDICCI", 1],
    ["I497", "CR", "SCANDOLARA RAVARA", 1],
    ["I498", "CR", "SCANDOLARA RIPA D'OGLIO", 1],
    ["I499", "RI", "SCANDRIGLIA", 1],
    ["I500", "CR", "SCANNABUE", 0],
    ["I501", "AQ", "SCANNO", 1],
    ["I502", "BG", "SCANO", 0],
    ["I503", "CA", "SCANO", 0],
    ["I502", "BG", "SCANO AL BREMBO", 0],
    ["I503", "OR", "SCANO DI MONTIFERRO", 1],
    ["I503", "NU", "SCANO MONTIFERRO", 0],
    ["I504", "GR", "SCANSANO", 1],
    ["M256", "MT", "SCANZANO JONICO", 1],
    ["I505", "BG", "SCANZO", 0],
    ["I506", "BG", "SCANZOROSCIATE", 1],
    ["I507", "IS", "SCAPOLI", 1],
    ["I508", "CO", "SCARENNA", 0],
    ["I509", "CO", "SCARIA", 0],
    ["I510", "GR", "SCARLINO", 1],
    ["I511", "TO", "SCARMAGNO", 1],
    ["I512", "CN", "SCARNAFIGI", 1],
    ["I513", "CN", "SCARNAFIGI-RUFFIA", 0],
    ["C702", "RM", "SCARPA", 0],
    ["I514", "FI", "SCARPERIA", 0],
    ["M326", "FI", "SCARPERIA E SAN PIERO", 1],
    ["I515", "BS", "SCARPIZZOLO", 0],
    ["I516", "BZ", "SCAVES", 0],
    ["I517", "PU", "SCAVOLINO", 0],
    ["I519", "BZ", "SCENA", 1],
    ["I520", "CH", "SCERNI", 1],
    ["I521", "PG", "SCHEGGIA", 0],
    ["I522", "PG", "SCHEGGIA E PASCELUPO", 1],
    ["I523", "PG", "SCHEGGINO", 1],
    ["I524", "GO", "SCHERBINA", 0],
    ["I525", "VA", "SCHIANNO", 0],
    ["D682", "FR", "SCHIAVI", 0],
    ["I526", "CH", "SCHIAVI", 0],
    ["I526", "CH", "SCHIAVI DI ABRUZZO", 1],
    ["E570", "CE", "SCHIAVI DI FORMICOLA", 0],
    ["I527", "VI", "SCHIAVON", 1],
    ["I528", "NO", "SCHIERANCO", 0],
    ["I529", "CO", "SCHIGNANO", 1],
    ["I530", "BG", "SCHILPARIO", 1],
    ["I531", "VI", "SCHIO", 1],
    ["I532", "MN", "SCHIVENOGLIA", 1],
    ["I533", "AG", "SCIACCA", 1],
    ["I534", "PA", "SCIARA", 1],
    ["I516", "BZ", "SCIAVES", 0],
    ["I535", "RG", "SCICLI", 1],
    ["I536", "RC", "SCIDO", 1],
    ["D290", "CS", "SCIGLIANO", 1],
    ["I537", "RC", "SCILLA", 1],
    ["I538", "PA", "SCILLATO", 1],
    ["I539", "TO", "SCIOLZE", 1],
    ["I540", "NA", "SCISCIANO", 1],
    ["I541", "PA", "SCLAFANI", 0],
    ["I541", "PA", "SCLAFANI BAGNI", 1],
    ["I518", "TN", "SCLEMO", 0],
    ["I542", "UD", "SCODAVACCA", 0],
    ["I543", "AQ", "SCONTRONE", 1],
    ["I544", "VC", "SCOPA", 1],
    ["I545", "VC", "SCOPELLO", 1],
    ["I546", "AQ", "SCOPPITO", 1],
    ["I547", "TS", "SCOPPO", 0],
    ["I548", "CT", "SCORDIA", 1],
    ["I549", "LE", "SCORRANO", 1],
    ["I550", "FC", "SCORTICATA", 0],
    ["I551", "VE", "SCORZE'", 1],
    ["I552", "GO", "SCRILLA", 0],
    ["H658", "RM", "SCROFANO", 0],
    ["I553", "AQ", "SCURCOLA", 0],
    ["I553", "AQ", "SCURCOLA MARSICANA", 1],
    ["I554", "TN", "SCURELLE", 1],
    ["I555", "AT", "SCURZOLENGO", 1],
    ["M310", "ZA", "SEBENICO", 0],
    ["I556", "IM", "SEBORGA", 1],
    ["I557", "GO", "SEBREGLIE", 0],
    ["I558", "AQ", "SECINARO", 1],
    ["I559", "LE", "SECLI'", 1],
    ["I560", "NA", "SECONDIGLIANO", 0],
    ["I561", "LO", "SECUGNAGO", 1],
    ["I562", "UD", "SEDEGLIANO", 1],
    ["I563", "BL", "SEDICO", 1],
    ["I564", "OR", "SEDILO", 1],
    ["I565", "SS", "SEDINI", 1],
    ["I566", "MI", "SEDRIANO", 1],
    ["I567", "BG", "SEDRINA", 1],
    ["I568", "GO", "SEDULA", 0],
    ["I569", "MC", "SEFRO", 1],
    ["I570", "CA", "SEGARIU", 0],
    ["I570", "SU", "SEGARIU", 1],
    ["I571", "GR", "SEGGIANO", 1],
    ["I572", "CO", "SEGHEBBIA", 0],
    ["C842", "UD", "SEGNACCO", 0],
    ["E162", "MI", "SEGNANO", 0],
    ["I573", "RM", "SEGNI", 1],
    ["I574", "SV", "SEGNO", 0],
    ["I575", "TN", "SEGNO", 0],
    ["I576", "TN", "SEGONZANO", 1],
    ["I577", "MI", "SEGRATE", 1],
    ["I578", "TV", "SEGUSINO", 1],
    ["Z342", "EE", "SEICELLE (ISOLE)", 1],
    ["I579", "TN", "SEIO", 0],
    ["I580", "CA", "SELARGIUS", 1],
    ["I581", "RI", "SELCI", 1],
    ["I582", "CA", "SELEGAS", 0],
    ["I582", "SU", "SELEGAS", 1],
    ["I583", "BG", "SELINO", 0],
    ["I584", "GO", "SELLA DELLE TRINCEE", 0],
    ["M360", "TN", "SELLA GIUDICARIE", 1],
    ["I585", "PG", "SELLANO", 1],
    ["I586", "MI", "SELLANUOVA", 0],
    ["I587", "BG", "SELLERE", 0],
    ["I588", "BS", "SELLERO", 1],
    ["I589", "CZ", "SELLIA", 1],
    ["I590", "CZ", "SELLIA MARINA", 1],
    ["I591", "BZ", "SELVA", 0],
    ["I592", "BL", "SELVA BELLUNESE", 0],
    ["I593", "BZ", "SELVA DEI MOLINI", 1],
    ["I592", "BL", "SELVA DI CADORE", 1],
    ["I594", "VR", "SELVA DI PROGNO", 1],
    ["I591", "BZ", "SELVA DI VAL GARDENA", 1],
    ["I591", "BZ", "SELVA IN GARDENA", 0],
    ["I595", "PD", "SELVAZZANO DENTRO", 1],
    ["I596", "NO", "SELVE", 0],
    ["I596", "BI", "SELVE MARCONE", 0],
    ["I597", "BG", "SELVINO", 1],
    ["I598", "SS", "SEMESTENE", 1],
    ["I599", "PV", "SEMIANA", 1],
    ["I600", "RC", "SEMINARA", 1],
    ["I601", "GR", "SEMPRONIANO", 1],
    ["I602", "MI", "SENAGO", 1],
    ["I603", "BZ", "SENALE", 0],
    ["I603", "BZ", "SENALE-SAN FELICE", 1],
    ["I604", "BZ", "SENALES", 1],
    ["I604", "BZ", "SENALES IN VENOSTA", 0],
    ["Z343", "EE", "SENEGAL", 1],
    ["I605", "OR", "SENEGHE", 1],
    ["I606", "AV", "SENERCHIA", 1],
    ["I607", "BS", "SENIGA", 1],
    ["I608", "AN", "SENIGALLIA", 1],
    ["I609", "OR", "SENIS", 1],
    ["I610", "PZ", "SENISE", 1],
    ["I611", "CO", "SENNA", 0],
    ["I612", "MI", "SENNA", 0],
    ["I611", "CO", "SENNA COMASCO", 1],
    ["I612", "LO", "SENNA LODIGIANA", 1],
    ["I613", "OR", "SENNARIOLO", 1],
    ["I614", "SS", "SENNORI", 1],
    ["I615", "CA", "SENORBI'", 0],
    ["I615", "SU", "SENORBI'", 1],
    ["I616", "TS", "SENOSECCHIA", 0],
    ["I617", "TN", "SEO", 0],
    ["I618", "CB", "SEPINO", 1],
    ["I619", "VB", "SEPPIANA", 0],
    ["I620", "CO", "SEPRIO", 0],
    ["I621", "PN", "SEQUALS", 1],
    ["I622", "LU", "SERAVEZZA", 1],
    ["I623", "CA", "SERBARIU", 0],
    ["Z158", "EE", "SERBIA", 1],
    ["Z118", "EE", "SERBIA E MONTENEGRO", 0],
    ["I624", "CA", "SERDIANA", 0],
    ["I624", "SU", "SERDIANA", 1],
    ["I625", "MB", "SEREGNO", 1],
    ["I625", "MI", "SEREGNO", 0],
    ["I626", "BL", "SEREN", 0],
    ["I626", "BL", "SEREN DEL GRAPPA", 1],
    ["I627", "CR", "SERGNANO", 1],
    ["I628", "BG", "SERIATE", 1],
    ["I629", "BG", "SERINA", 1],
    ["I630", "AV", "SERINO", 1],
    ["I631", "BS", "SERLE", 1],
    ["I632", "MN", "SERMIDE", 0],
    ["I632", "MN", "SERMIDE E FELONICA", 1],
    ["I633", "BS", "SERMIONE", 0],
    ["I634", "LT", "SERMONETA", 1],
    ["I635", "TV", "SERNAGLIA", 0],
    ["I635", "TV", "SERNAGLIA DELLA BATTAGLIA", 1],
    ["I636", "SO", "SERNIO", 1],
    ["I637", "AT", "SEROLE", 1],
    ["I638", "GO", "SERPENIZZA", 0],
    ["I639", "CZ", "SERRA", 0],
    ["I640", "GE", "SERRA", 0],
    ["I642", "CS", "SERRA D'AIELLO", 1],
    ["I643", "AN", "SERRA DE' CONTI", 1],
    ["I650", "CS", "SERRA PEDACE", 0],
    ["I640", "GE", "SERRA RICCO'", 1],
    ["I639", "VV", "SERRA SAN BRUNO", 1],
    ["I653", "AN", "SERRA SAN QUIRICO", 1],
    ["I654", "PU", "SERRA SANT'ABBONDIO", 1],
    ["I641", "FG", "SERRACAPRIOLA", 1],
    ["I644", "CL", "SERRADIFALCO", 1],
    ["I645", "AL", "SERRALUNGA", 0],
    ["I646", "CN", "SERRALUNGA CERESETO", 0],
    ["I646", "CN", "SERRALUNGA D'ALBA", 1],
    ["I645", "AL", "SERRALUNGA DI CREA", 1],
    ["I647", "CA", "SERRAMANNA", 0],
    ["I647", "SU", "SERRAMANNA", 1],
    ["F357", "MO", "SERRAMAZZONI", 1],
    ["I648", "SA", "SERRAMEZZANA", 1],
    ["I649", "PE", "SERRAMONACESCA", 1],
    ["I651", "MC", "SERRAPETRONA", 1],
    ["I652", "NA", "SERRARA FONTANA", 1],
    ["I655", "CZ", "SERRASTRETTA", 1],
    ["I656", "RC", "SERRATA", 1],
    ["C191", "BO", "SERRAVALLE", 0],
    ["I657", "AL", "SERRAVALLE", 0],
    ["I658", "AL", "SERRAVALLE", 0],
    ["I659", "CN", "SERRAVALLE", 0],
    ["I660", "FI", "SERRAVALLE", 0],
    ["I661", "MC", "SERRAVALLE", 0],
    ["I662", "MN", "SERRAVALLE", 0],
    ["I663", "NO", "SERRAVALLE", 0],
    ["I664", "TV", "SERRAVALLE", 0],
    ["I662", "MN", "SERRAVALLE A PO", 1],
    ["I665", "TN", "SERRAVALLE ALL'ADIGE", 0],
    ["I658", "AT", "SERRAVALLE D'ASTI", 0],
    ["I659", "CN", "SERRAVALLE DELLE LANGHE", 0],
    ["I661", "MC", "SERRAVALLE DI CHIENTI", 1],
    ["I659", "CN", "SERRAVALLE LANGHE", 1],
    ["I657", "AL", "SERRAVALLE LIBARNA", 0],
    ["I660", "PT", "SERRAVALLE PISTOIESE", 1],
    ["I657", "AL", "SERRAVALLE SCRIVIA", 1],
    ["I663", "VC", "SERRAVALLE SESIA", 1],
    ["I666", "SA", "SERRE", 1],
    ["I667", "CA", "SERRENTI", 0],
    ["I667", "SU", "SERRENTI", 1],
    ["I668", "NU", "SERRI", 0],
    ["I668", "SU", "SERRI", 1],
    ["I669", "FR", "SERRONE", 1],
    ["I670", "PU", "SERRUNGARINA", 0],
    ["I671", "CZ", "SERSALE", 1],
    ["I672", "TN", "SERSO", 0],
    ["C070", "AP", "SERVIGLIANO", 0],
    ["C070", "FM", "SERVIGLIANO", 1],
    ["I673", "BL", "SERVO", 0],
    ["I674", "TS", "SESANA", 0],
    ["I675", "MI", "SESONA", 0],
    ["I676", "CE", "SESSA", 0],
    ["I676", "CE", "SESSA AURUNCA", 1],
    ["I677", "SA", "SESSA CILENTO", 1],
    ["I678", "AT", "SESSAME", 1],
    ["I679", "CB", "SESSANO", 0],
    ["I679", "IS", "SESSANO DEL MOLISE", 1],
    ["I680", "AL", "SESSANT", 0],
    ["E070", "SP", "SESTA GODANO", 1],
    ["I681", "AR", "SESTINO", 1],
    ["I682", "CB", "SESTO", 0],
    ["I683", "CR", "SESTO", 0],
    ["I684", "FI", "SESTO", 0],
    ["I685", "MI", "SESTO", 0],
    ["I686", "UD", "SESTO", 0],
    ["I687", "BZ", "SESTO", 1],
    ["I686", "PN", "SESTO AL REGHENA", 1],
    ["I688", "VA", "SESTO CALENDE", 1],
    ["I682", "IS", "SESTO CAMPANO", 1],
    ["I683", "CR", "SESTO ED UNITI", 1],
    ["I684", "FI", "SESTO FIORENTINO", 1],
    ["I685", "MI", "SESTO PERGOLA", 0],
    ["I690", "MI", "SESTO SAN GIOVANNI", 1],
    ["I691", "MI", "SESTO ULTERIANO", 0],
    ["I689", "MO", "SESTOLA", 1],
    ["I693", "GE", "SESTRI LEVANTE", 1],
    ["I694", "GE", "SESTRI PONENTE", 0],
    ["I692", "TO", "SESTRIERE", 1],
    ["I692", "TO", "SESTRIERES", 0],
    ["I695", "CA", "SESTU", 1],
    ["I696", "MI", "SETTALA", 1],
    ["I697", "FR", "SETTEFRATI", 1],
    ["I698", "AT", "SETTIME", 1],
    ["I699", "CA", "SETTIMO", 0],
    ["I700", "MI", "SETTIMO", 0],
    ["I700", "MI", "SETTIMO MILANESE", 1],
    ["I701", "TO", "SETTIMO ROTTARO", 1],
    ["I699", "CA", "SETTIMO SAN PIETRO", 1],
    ["I702", "TO", "SETTIMO TAVAGNASCO", 0],
    ["I703", "TO", "SETTIMO TORINESE", 1],
    ["I702", "TO", "SETTIMO VITTONE", 1],
    ["I704", "CZ", "SETTINGIANO", 1],
    ["I705", "CA", "SETZU", 0],
    ["I705", "SU", "SETZU", 1],
    ["I706", "NU", "SEUI", 0],
    ["I706", "SU", "SEUI", 1],
    ["I707", "NU", "SEULO", 0],
    ["I707", "SU", "SEULO", 1],
    ["I708", "CA", "SEUNI", 0],
    ["I709", "MB", "SEVESO", 1],
    ["I709", "MI", "SEVESO", 0],
    ["I710", "TN", "SEVIGNANO", 0],
    ["I711", "AL", "SEZZADIO", 1],
    ["I712", "LT", "SEZZE", 1],
    ["I711", "AL", "SEZZE'", 0],
    ["I713", "BG", "SFORZATICA", 0],
    ["I714", "TN", "SFRUZ", 1],
    ["I715", "TS", "SGONICO", 1],
    ["I716", "FR", "SGURGOLA", 1],
    ["I717", "OR", "SIAMAGGIORE", 1],
    ["I718", "OR", "SIAMANNA", 1],
    ["I719", "OR", "SIAMANNA SIAPICCIA", 0],
    ["I720", "SA", "SIANO", 1],
    ["I721", "OR", "SIAPICCIA", 1],
    ["I722", "CA", "SICCI", 0],
    ["I722", "CA", "SICCI SAN BIAGIO", 0],
    ["M253", "SA", "SICIGNANO", 0],
    ["M253", "SA", "SICIGNANO DEGLI ALBURNI", 1],
    ["I723", "AG", "SICULIANA", 1],
    ["I724", "CA", "SIDDI", 0],
    ["I724", "SU", "SIDDI", 1],
    ["I725", "RC", "SIDERNO", 1],
    ["I726", "SI", "SIENA", 1],
    ["Z344", "EE", "SIERRA LEONE", 1],
    ["I727", "PG", "SIGILLO", 1],
    ["I728", "FI", "SIGNA", 1],
    ["Z239", "EE", "SIKKIM", 0],
    ["I729", "BZ", "SILANDRO", 1],
    ["I730", "NU", "SILANUS", 1],
    ["F116", "TV", "SILEA", 1],
    ["I731", "CA", "SILI", 0],
    ["I732", "SS", "SILIGO", 1],
    ["I734", "CA", "SILIQUA", 0],
    ["I734", "SU", "SILIQUA", 1],
    ["I735", "CA", "SILIUS", 0],
    ["I735", "SU", "SILIUS", 1],
    ["I736", "NO", "SILLA", 0],
    ["I737", "LU", "SILLANO", 0],
    ["M347", "LU", "SILLANO GIUNCUGNANO", 1],
    ["I736", "NO", "SILLAVENGO", 1],
    ["I733", "PL", "SILUM MONT'AQUILA", 0],
    ["I739", "PV", "SILVANO", 0],
    ["I738", "AL", "SILVANO D'ORBA", 1],
    ["I739", "PV", "SILVANO PIETRA", 1],
    ["I740", "CR", "SILVELLA", 0],
    ["I741", "TE", "SILVI", 1],
    ["I742", "OR", "SIMALA", 1],
    ["I743", "OR", "SIMAXIS", 1],
    ["I744", "VV", "SIMBARIO", 1],
    ["I745", "CZ", "SIMERI CRICHI", 1],
    ["I746", "TS", "SINADOLE", 0],
    ["I747", "ME", "SINAGRA", 1],
    ["A468", "SI", "SINALUNGA", 1],
    ["I748", "NU", "SINDIA", 1],
    ["Z248", "EE", "SINGAPORE", 1],
    ["I749", "OR", "SINI", 1],
    ["I750", "CN", "SINIO", 1],
    ["I751", "NU", "SINISCOLA", 1],
    ["I752", "CA", "SINNAI", 1],
    ["I753", "RC", "SINOPOLI", 1],
    ["I754", "SR", "SIRACUSA", 1],
    ["Z240", "EE", "SIRIA", 1],
    ["I755", "NA", "SIRICO", 0],
    ["I756", "AV", "SIRIGNANO", 1],
    ["I757", "OR", "SIRIS", 1],
    ["I633", "BS", "SIRMIONE", 1],
    ["I758", "AN", "SIROLO", 1],
    ["I759", "LC", "SIRONE", 1],
    ["I760", "TN", "SIROR", 0],
    ["I761", "LC", "SIRTORI", 1],
    ["I762", "CA", "SISINI", 0],
    ["I763", "PR", "SISSA", 0],
    ["M325", "PR", "SISSA TRECASALI", 1],
    ["I764", "CA", "SIURGUS", 0],
    ["I765", "CA", "SIURGUS DONIGALA", 0],
    ["I765", "SU", "SIURGUS DONIGALA", 1],
    ["I766", "BS", "SIVIANO", 0],
    ["E265", "PV", "SIZIANO", 1],
    ["I767", "NO", "SIZZANO", 1],
    ["I768", "GO", "SLAPPE-ZORZI", 0],
    ["I769", "BZ", "SLINGIA", 0],
    ["I769", "BZ", "SLINIGA", 0],
    ["I770", "TS", "SLIVIA", 0],
    ["I770", "TS", "SLIVNO", 0],
    ["Z155", "EE", "SLOVACCHIA", 1],
    ["Z150", "EE", "SLOVENIA", 1],
    ["I771", "BZ", "SLUDERNO", 1],
    ["I772", "TN", "SMARANO", 0],
    ["I773", "FM", "SMERIA", 0],
    ["I774", "AP", "SMERILLO", 0],
    ["I774", "FM", "SMERILLO", 1],
    ["I775", "VR", "SOAVE", 1],
    ["I776", "NA", "SOCCAVO", 0],
    ["I777", "UD", "SOCCHIEVE", 1],
    ["I778", "OR", "SODDI'", 1],
    ["I780", "LE", "SOGLIANO", 0],
    ["I779", "FC", "SOGLIANO AL RUBICONE", 1],
    ["I780", "LE", "SOGLIANO CAVOUR", 1],
    ["I781", "AT", "SOGLIO", 1],
    ["I782", "BS", "SOIANO DEL LAGO", 1],
    ["I783", "VI", "SOLAGNA", 1],
    ["I784", "CA", "SOLANAS", 0],
    ["I188", "PA", "SOLANTO", 0],
    ["I785", "SR", "SOLARINO", 1],
    ["I786", "MI", "SOLARO", 1],
    ["I787", "RA", "SOLAROLO", 1],
    ["I788", "CR", "SOLAROLO DEL PERSICO", 0],
    ["I789", "CR", "SOLAROLO MONASTEROLO", 0],
    ["I790", "CR", "SOLAROLO RAINERIO", 1],
    ["I791", "OR", "SOLARUSSA", 1],
    ["I792", "CO", "SOLBIATE", 0],
    ["I793", "VA", "SOLBIATE ARNO", 1],
    ["I793", "VA", "SOLBIATE ARNO E MONTE", 0],
    ["M412", "CO", "SOLBIATE CON CAGNO", 1],
    ["I794", "VA", "SOLBIATE OLONA", 1],
    ["I793", "MI", "SOLBIATE SULL'ARNO", 0],
    ["I795", "AL", "SOLBRITO", 0],
    ["I796", "IM", "SOLDANO", 1],
    ["I797", "CA", "SOLEMINIS", 0],
    ["I797", "SU", "SOLEMINIS", 1],
    ["I798", "AL", "SOLERO", 1],
    ["I799", "PD", "SOLESINO", 1],
    ["I800", "LE", "SOLETO", 1],
    ["I801", "MN", "SOLFERINO", 1],
    ["I802", "MO", "SOLIERA", 1],
    ["I803", "PR", "SOLIGNANO", 1],
    ["I804", "AQ", "SOLMONA", 0],
    ["I805", "AV", "SOLOFRA", 1],
    ["I806", "NO", "SOLOGNO", 0],
    ["I807", "TO", "SOLOMIAC", 0],
    ["I808", "AL", "SOLONGHELLO", 1],
    ["I809", "BN", "SOLOPACA", 1],
    ["I810", "MI", "SOLTARICO", 0],
    ["I811", "BG", "SOLTO", 0],
    ["I812", "BG", "SOLTO COLLINA", 1],
    ["I813", "BG", "SOLZA", 1],
    ["I814", "CO", "SOLZAGO", 0],
    ["I815", "LO", "SOMAGLIA", 1],
    ["Z345", "EE", "SOMALIA", 1],
    ["Z346", "EE", "SOMALIA FRANCESE", 0],
    ["I816", "CO", "SOMANA", 0],
    ["I817", "CN", "SOMANO", 1],
    ["B148", "BG", "SOMBRENO", 0],
    ["I818", "BG", "SOMENDENNA", 0],
    ["I819", "MI", "SOMMA", 0],
    ["I820", "NA", "SOMMA", 0],
    ["I819", "VA", "SOMMA LOMBARDO", 1],
    ["I820", "NA", "SOMMA VESUVIANA", 1],
    ["I821", "VR", "SOMMACAMPAGNA", 1],
    ["I822", "CN", "SOMMARIVA DEL BOSCO", 1],
    ["I823", "CN", "SOMMARIVA PERNO", 1],
    ["I824", "CL", "SOMMATINO", 1],
    ["I825", "PV", "SOMMO", 1],
    ["I826", "VR", "SONA", 1],
    ["I827", "CR", "SONCINO", 1],
    ["I828", "SO", "SONDALO", 1],
    ["I829", "SO", "SONDRIO", 1],
    ["I830", "BG", "SONGAVAZZO", 1],
    ["I831", "BS", "SONICO", 1],
    ["I832", "LT", "SONNINO", 1],
    ["I833", "GO", "SONZIA", 0],
    ["I834", "TN", "SOPRAMONTE", 0],
    ["I835", "BI", "SOPRANA", 0],
    ["I836", "BS", "SOPRAPONTE", 0],
    ["I837", "BS", "SOPRAZOCCO", 0],
    ["I838", "FR", "SORA", 1],
    ["I839", "TN", "SORAGA", 0],
    ["I839", "TN", "SORAGA DI FASSA", 1],
    ["I840", "PR", "SORAGNA", 1],
    ["I841", "GR", "SORANO", 1],
    ["I842", "FC", "SORBANO", 0],
    ["I843", "AV", "SORBO", 0],
    ["I844", "CZ", "SORBO", 0],
    ["I844", "CZ", "SORBO SAN BASILE", 1],
    ["I843", "AV", "SORBO SERPICO", 1],
    ["I845", "PR", "SORBOLO", 0],
    ["M411", "PR", "SORBOLO MEZZANI", 1],
    ["I846", "PU", "SORBOLONGO", 0],
    ["I847", "BI", "SORDEVOLO", 1],
    ["I848", "LO", "SORDIO", 1],
    ["I849", "CR", "SORESINA", 1],
    ["I850", "VR", "SORGA'", 1],
    ["I851", "NU", "SORGONO", 1],
    ["I852", "GE", "SORI", 1],
    ["I852", "GE", "SORI-CANEPA", 0],
    ["I853", "VV", "SORIANELLO", 1],
    ["I854", "CZ", "SORIANO", 0],
    ["I855", "RM", "SORIANO", 0],
    ["I854", "VV", "SORIANO CALABRO", 1],
    ["I855", "VT", "SORIANO NEL CIMINO", 1],
    ["I237", "PV", "SORIASCO", 0],
    ["I856", "CO", "SORICO", 1],
    ["I857", "NO", "SORISO", 1],
    ["I858", "BG", "SORISOLE", 1],
    ["I859", "AL", "SORLI", 0],
    ["I860", "CO", "SORMANO", 1],
    ["I861", "OR", "SORRADILE", 1],
    ["I862", "NA", "SORRENTO", 1],
    ["I863", "SS", "SORSO", 1],
    ["I864", "SR", "SORTINO", 1],
    ["I865", "CR", "SOSPIRO", 1],
    ["I866", "BL", "SOSPIROLO", 1],
    ["I867", "VI", "SOSSANO", 1],
    ["I868", "BI", "SOSTEGNO", 1],
    ["I869", "BG", "SOTTO IL MONTE", 0],
    ["I869", "BG", "SOTTO IL MONTE GIOVANNI XXIII", 1],
    ["I870", "NO", "SOVAZZA", 0],
    ["I871", "TN", "SOVER", 1],
    ["I872", "CZ", "SOVERATO", 1],
    ["I873", "BG", "SOVERE", 1],
    ["I874", "CZ", "SOVERIA MANNELLI", 1],
    ["I875", "CZ", "SOVERIA SIMERI", 1],
    ["I876", "BL", "SOVERZENE", 1],
    ["I877", "SI", "SOVICILLE", 1],
    ["I878", "MB", "SOVICO", 1],
    ["I878", "MI", "SOVICO", 0],
    ["I879", "VI", "SOVIZZO", 1],
    ["I673", "BL", "SOVRAMONTE", 1],
    ["I880", "NO", "SOZZAGO", 1],
    ["E366", "RG", "SPACCAFORNO", 0],
    ["I881", "ME", "SPADAFORA", 1],
    ["I882", "ME", "SPADAFORA SAN MARTINO", 0],
    ["I883", "ME", "SPADAFORA SAN PIETRO", 0],
    ["I884", "VV", "SPADOLA", 1],
    ["Z131", "EE", "SPAGNA", 1],
    ["I885", "CE", "SPARANISE", 1],
    ["I886", "TO", "SPARONE", 1],
    ["I887", "LE", "SPECCHIA", 1],
    ["I887", "LE", "SPECCHIA DE' PRETI", 0],
    ["I888", "PG", "SPELLO", 1],
    ["I889", "TN", "SPERA", 0],
    ["I890", "TV", "SPERCENIGO", 0],
    ["I891", "EN", "SPERLINGA", 1],
    ["I892", "LT", "SPERLONGA", 1],
    ["I893", "AV", "SPERONE", 1],
    ["I894", "PV", "SPESSA", 1],
    ["E463", "SP", "SPEZIA", 0],
    ["I895", "CS", "SPEZZANO ALBANESE", 1],
    ["I896", "CS", "SPEZZANO DELLA SILA", 1],
    ["I897", "CS", "SPEZZANO GRANDE", 0],
    ["I898", "CS", "SPEZZANO PICCOLO", 0],
    ["I899", "TN", "SPIAZZO", 1],
    ["I901", "AL", "SPIGNO", 0],
    ["I902", "RM", "SPIGNO", 0],
    ["I901", "AL", "SPIGNO MONFERRATO", 1],
    ["I902", "LT", "SPIGNO SATURNIA", 1],
    ["I903", "MO", "SPILAMBERTO", 1],
    ["I904", "PN", "SPILIMBERGO", 1],
    ["I905", "VV", "SPILINGA", 1],
    ["I906", "CR", "SPINADESCO", 1],
    ["I907", "BA", "SPINAZZOLA", 0],
    ["I907", "BT", "SPINAZZOLA", 1],
    ["I908", "VE", "SPINEA", 1],
    ["I909", "CR", "SPINEDA", 1],
    ["I910", "CB", "SPINETE", 1],
    ["I911", "AL", "SPINETO", 0],
    ["I911", "AL", "SPINETO SCRIVIA", 1],
    ["I912", "AP", "SPINETOLI", 1],
    ["I900", "BZ", "SPINGA", 0],
    ["I913", "BG", "SPINO", 0],
    ["I914", "CR", "SPINO", 0],
    ["I913", "BG", "SPINO AL BREMBO", 0],
    ["I914", "CR", "SPINO D'ADDA", 1],
    ["I915", "BG", "SPINONE", 0],
    ["I916", "BG", "SPINONE AL LAGO", 1],
    ["I916", "BG", "SPINONE DEI CASTELLI", 0],
    ["I917", "PZ", "SPINOSO", 1],
    ["I918", "PV", "SPIRAGO", 0],
    ["I919", "BG", "SPIRANO", 1],
    ["I920", "NO", "SPOCCIA", 0],
    ["I921", "PG", "SPOLETO", 1],
    ["I922", "PE", "SPOLTORE", 1],
    ["I923", "LE", "SPONGANO", 1],
    ["I924", "TN", "SPORMAGGIORE", 1],
    ["I925", "TN", "SPORMINORE", 1],
    ["I926", "SV", "SPOTORNO", 1],
    ["I927", "TV", "SPRESIANO", 1],
    ["I928", "SO", "SPRIANA", 1],
    ["I929", "CZ", "SQUILLACE", 1],
    ["I930", "LE", "SQUINZANO", 1],
    ["Z209", "EE", "SRI LANKA", 1],
    ["I931", "BG", "STABELLO", 0],
    ["D475", "RM", "STABIA", 0],
    ["I932", "AN", "STAFFOLO", 1],
    ["I933", "PV", "STAGHIGLIONE", 0],
    ["I934", "GE", "STAGLIENO", 0],
    ["I935", "CR", "STAGNO LOMBARDO", 1],
    ["I935", "CR", "STAGNO PAGLIARO", 0],
    ["I936", "RC", "STAITI", 1],
    ["I937", "CZ", "STALETTI", 0],
    ["I937", "CZ", "STALETTI'", 1],
    ["I938", "PD", "STANGHELLA", 1],
    ["I939", "GO", "STARANZANO", 1],
    ["Z404", "EE", "STATI UNITI D'AMERICA", 1],
    ["M298", "TA", "STATTE", 1],
    ["I940", "BZ", "STAVA", 0],
    ["I940", "BZ", "STAVE", 0],
    ["I941", "AL", "STAZZANO", 1],
    ["I942", "LU", "STAZZEMA", 1],
    ["I943", "CO", "STAZZONA", 1],
    ["I944", "CO", "STAZZONA-GERMASINO", 0],
    ["I945", "VV", "STEFANACONI", 1],
    ["I946", "SV", "STELLA", 1],
    ["G887", "SA", "STELLA CILENTO", 1],
    ["I947", "SV", "STELLANELLO", 1],
    ["I948", "BZ", "STELVIO", 1],
    ["I949", "TN", "STENICO", 1],
    ["I950", "LE", "STERNATIA", 1],
    ["I951", "BG", "STEZZANO", 1],
    ["I952", "AR", "STIA", 0],
    ["I953", "RO", "STIENTA", 1],
    ["I954", "MT", "STIGLIANO", 1],
    ["I955", "RC", "STIGNANO", 1],
    ["I956", "RC", "STILO", 1],
    ["I957", "CR", "STILO DE' MARIANI", 0],
    ["I958", "BZ", "STILVES", 0],
    ["I959", "RI", "STIMIGLIANO", 1],
    ["M290", "SS", "STINTINO", 1],
    ["I960", "SA", "STIO", 1],
    ["I961", "TS", "STORIE", 0],
    ["I962", "FG", "STORNARA", 1],
    ["I963", "FG", "STORNARELLA", 1],
    ["I964", "TN", "STORO", 1],
    ["I965", "VE", "STRA", 1],
    ["I966", "CR", "STRACONDOLO", 0],
    ["I967", "TN", "STRADA", 0],
    ["I968", "PV", "STRADELLA", 1],
    ["I969", "TO", "STRAMBINELLO", 1],
    ["I970", "TO", "STRAMBINO", 1],
    ["I971", "AO", "STRAMBINO ROMANO", 0],
    ["I972", "TN", "STRAMENTIZZO", 0],
    ["I973", "FR", "STRANGOLAGALLI", 1],
    ["M246", "TN", "STRAVINO", 0],
    ["I974", "UD", "STREGNA", 1],
    ["I975", "TN", "STREMBO", 1],
    ["I976", "VB", "STRESA", 1],
    ["I976", "NO", "STRESA BORROMEO", 0],
    ["I977", "AL", "STREVI", 1],
    ["I978", "NA", "STRIANO", 1],
    ["I979", "TN", "STRIGNO", 0],
    ["I980", "BI", "STRONA", 1],
    ["I981", "TR", "STRONCONE", 1],
    ["I982", "KR", "STRONGOLI", 1],
    ["I983", "NO", "STROPINO", 0],
    ["I984", "VC", "STROPPIANA", 1],
    ["I985", "CN", "STROPPO", 1],
    ["I986", "BG", "STROZZA", 1],
    ["I987", "GE", "STRUPPA", 0],
    ["I988", "TN", "STUMIAGA", 0],
    ["I989", "GO", "STURIA DELLE FUSINE", 0],
    ["I990", "AV", "STURNO", 1],
    ["B014", "PV", "SUARDI", 1],
    ["I991", "AR", "SUBBIANO", 1],
    ["I992", "RM", "SUBIACO", 1],
    ["I993", "CE", "SUCCIVO", 1],
    ["Z907", "EE", "SUD SUDAN", 0],
    ["Z347", "EE", "SUDAFRICANA REPUBBLICA", 1],
    ["Z348", "EE", "SUDAN", 1],
    ["I994", "LC", "SUEGLIO", 1],
    ["I995", "CA", "SUELLI", 0],
    ["I995", "SU", "SUELLI", 1],
    ["I996", "LC", "SUELLO", 1],
    ["I997", "BG", "SUISIO", 1],
    ["I998", "MB", "SULBIATE", 1],
    ["I998", "MI", "SULBIATE", 0],
    ["I999", "MI", "SULBIATE INFERIORE", 0],
    ["L001", "MI", "SULBIATE SUPERIORE", 0],
    ["I804", "AQ", "SULMONA", 1],
    ["L002", "BS", "SULZANO", 1],
    ["L003", "VA", "SUMIRAGO", 1],
    ["L004", "AV", "SUMMONTE", 1],
    ["L005", "NO", "SUNA", 0],
    ["L006", "NU", "SUNI", 0],
    ["L006", "OR", "SUNI", 1],
    ["L007", "NO", "SUNO", 1],
    ["L008", "LE", "SUPERSANO", 1],
    ["L009", "FR", "SUPINO", 1],
    ["L010", "LE", "SURANO", 1],
    ["L011", "LE", "SURBO", 1],
    ["Z608", "EE", "SURINAME", 1],
    ["L013", "TO", "SUSA", 1],
    ["L012", "TN", "SUSA'", 0],
    ["L014", "TV", "SUSEGANA", 1],
    ["L015", "MN", "SUSTINENTE", 1],
    ["L016", "CL", "SUTERA", 1],
    ["L017", "VT", "SUTRI", 1],
    ["L018", "UD", "SUTRIO", 1],
    ["L019", "LI", "SUVERETO", 1],
    ["L020", "MN", "SUZZARA", 1],
    ["Z132", "EE", "SVEZIA", 1],
    ["Z133", "EE", "SVIZZERA", 1],
    ["Z349", "EE", "SWAZILAND", 1],
    ["L021", "BZ", "TABLA'", 0],
    ["L022", "LC", "TACENO", 1],
    ["L023", "OR", "TADASUNI", 1],
    ["L024", "IM", "TAGGIA", 1],
    ["Z147", "EE", "TAGIKISTAN", 0],
    ["Z257", "EE", "TAGIKISTAN", 1],
    ["L025", "AQ", "TAGLIACOZZO", 1],
    ["L026", "RO", "TAGLIO DI PO", 1],
    ["G926", "RO", "TAGLIO DI PORTO VIRO", 0],
    ["L027", "AL", "TAGLIOLO", 0],
    ["L027", "AL", "TAGLIOLO MONFERRATO", 1],
    ["L028", "AL", "TAGLIOLO-BELFORTE", 0],
    ["L029", "BG", "TAGLIUNO", 0],
    ["L030", "BL", "TAIBON", 0],
    ["L030", "BL", "TAIBON AGORDINO", 1],
    ["L031", "MI", "TAINATE", 0],
    ["L032", "VA", "TAINO", 1],
    ["L033", "TN", "TAIO", 0],
    ["G736", "UD", "TAIPANA", 1],
    ["Z217", "EE", "TAIWAN", 1],
    ["L034", "PU", "TALAMELLO", 0],
    ["L034", "RN", "TALAMELLO", 1],
    ["L035", "SO", "TALAMONA", 1],
    ["L036", "NU", "TALANA", 1],
    ["L037", "BG", "TALEGGIO", 1],
    ["L038", "AR", "TALLA", 1],
    ["L039", "UD", "TALMASSONS", 1],
    ["L040", "BL", "TAMBRE", 1],
    ["L041", "BZ", "TANAS", 0],
    ["Z350", "EE", "TANGANICA", 0],
    ["Z357", "EE", "TANZANIA", 1],
    ["L042", "ME", "TAORMINA", 1],
    ["L043", "NO", "TAPIGLIANO", 0],
    ["L044", "UD", "TAPOGLIANO", 0],
    ["L045", "NO", "TAPPIA", 0],
    ["L046", "RI", "TARANO", 1],
    ["L047", "CH", "TARANTA", 0],
    ["L047", "CH", "TARANTA PELIGNA", 1],
    ["L048", "CN", "TARANTASCA", 1],
    ["L049", "TA", "TARANTO", 1],
    ["L050", "UD", "TARCENTO", 1],
    ["L051", "BZ", "TARCES", 0],
    ["L052", "UD", "TARCETTA", 0],
    ["L053", "GO", "TARNOVA DELLA SELVA", 0],
    ["D024", "VT", "TARQUINIA", 1],
    ["L054", "BZ", "TARRES", 0],
    ["L051", "BZ", "TARRES DI SOPRA", 0],
    ["L054", "BZ", "TARRES DI SOTTO", 0],
    ["L055", "CS", "TARSIA", 1],
    ["L056", "SO", "TARTANO", 1],
    ["L057", "UD", "TARVISIO", 1],
    ["L058", "TV", "TARZO", 1],
    ["L059", "AL", "TASSAROLO", 1],
    ["L060", "TN", "TASSULLO", 0],
    ["L061", "AV", "TAURANO", 1],
    ["L062", "AV", "TAURASI", 1],
    ["L063", "RC", "TAURIANOVA", 1],
    ["L064", "LE", "TAURISANO", 1],
    ["L065", "UD", "TAVAGNACCO", 1],
    ["L066", "TO", "TAVAGNASCO", 1],
    ["L067", "FI", "TAVARNELLE", 0],
    ["L067", "FI", "TAVARNELLE VAL DI PESA", 0],
    ["L068", "MI", "TAVAZZANO", 0],
    ["F260", "LO", "TAVAZZANO CON VILLAVESCO", 1],
    ["L069", "CB", "TAVENNA", 1],
    ["L070", "CZ", "TAVERNA", 1],
    ["L071", "CO", "TAVERNERIO", 1],
    ["G033", "TO", "TAVERNETTE", 0],
    ["E187", "BO", "TAVERNOLA", 0],
    ["L072", "AV", "TAVERNOLA", 0],
    ["L073", "BG", "TAVERNOLA", 0],
    ["L073", "BG", "TAVERNOLA BERGAMASCA", 1],
    ["E187", "BO", "TAVERNOLA RENO", 0],
    ["L072", "AV", "TAVERNOLA SAN FELICE", 0],
    ["C698", "BS", "TAVERNOLE SUL MELLA", 1],
    ["C698", "BS", "TAVERNOLE-CIMMO", 0],
    ["L074", "LE", "TAVIANO", 1],
    ["L075", "BI", "TAVIGLIANO", 1],
    ["L076", "TN", "TAVODO", 0],
    ["L077", "IM", "TAVOLE", 0],
    ["L078", "PU", "TAVOLETO", 1],
    ["L079", "TN", "TAVON", 0],
    ["L080", "CO", "TAVORDO", 0],
    ["L081", "PU", "TAVULLIA", 1],
    ["L082", "PZ", "TEANA", 1],
    ["L083", "CE", "TEANO", 1],
    ["D292", "SA", "TEGGIANO", 1],
    ["L084", "SO", "TEGLIO", 1],
    ["L085", "VE", "TEGLIO", 0],
    ["L085", "VE", "TEGLIO VENETO", 1],
    ["H197", "CO", "TEGNONE", 0],
    ["L086", "BN", "TELESE", 0],
    ["L086", "BN", "TELESE TERME", 1],
    ["L087", "BG", "TELGATE", 1],
    ["L088", "SS", "TELTI", 1],
    ["L089", "TN", "TELVE", 1],
    ["L090", "TN", "TELVE DI SOPRA", 1],
    ["L091", "BZ", "TELVES", 0],
    ["L092", "GO", "TEMENIZZA", 0],
    ["L093", "SS", "TEMPIO PAUSANIA", 1],
    ["L094", "BS", "TEMU'", 1],
    ["L095", "CN", "TENDA", 0],
    ["L096", "TN", "TENNA", 1],
    ["L097", "TN", "TENNO", 1],
    ["L098", "BZ", "TEODONA", 0],
    ["L098", "BZ", "TEODONE", 0],
    ["L099", "FC", "TEODORANO", 0],
    ["L100", "PD", "TEOLO", 1],
    ["L101", "UD", "TEOR", 0],
    ["L102", "AV", "TEORA", 1],
    ["L103", "TE", "TERAMO", 1],
    ["L104", "NO", "TERDOBBIATE", 1],
    ["L105", "FR", "TERELLE", 1],
    ["L106", "BZ", "TERENTO", 1],
    ["E548", "PR", "TERENZO", 1],
    ["M282", "SS", "TERGU", 1],
    ["L107", "TN", "TERLAGO", 0],
    ["L108", "BZ", "TERLANO", 1],
    ["L109", "BA", "TERLIZZI", 1],
    ["M210", "ME", "TERME VIGLIATORE", 1],
    ["L110", "TN", "TERMENAGO", 0],
    ["L111", "BZ", "TERMENO", 0],
    ["L111", "BZ", "TERMENO SULLA STRADA DEL VINO", 1],
    ["L112", "PA", "TERMINI", 0],
    ["L112", "PA", "TERMINI IMERESE", 1],
    ["L113", "CB", "TERMOLI", 1],
    ["L114", "TN", "TERMON", 0],
    ["L115", "VA", "TERNATE", 1],
    ["L116", "BI", "TERNENGO", 1],
    ["L117", "TR", "TERNI", 1],
    ["L118", "BG", "TERNO", 0],
    ["L118", "BG", "TERNO D'ISOLA", 1],
    ["L119", "GO", "TERNOVA D'ISONZO", 0],
    ["C339", "FC", "TERRA DEL SOLE", 0],
    ["C339", "FC", "TERRA DEL SOLE E CASTROCARO", 0],
    ["L120", "LT", "TERRACINA", 1],
    ["L121", "TN", "TERRAGNOLO", 1],
    ["L122", "OR", "TERRALBA", 1],
    ["D960", "CL", "TERRANOVA", 0],
    ["G015", "SS", "TERRANOVA", 0],
    ["L123", "AR", "TERRANOVA", 0],
    ["L124", "CS", "TERRANOVA", 0],
    ["L125", "MI", "TERRANOVA", 0],
    ["L126", "PZ", "TERRANOVA", 0],
    ["L127", "RC", "TERRANOVA", 0],
    ["L124", "CS", "TERRANOVA DA SIBARI", 1],
    ["L125", "LO", "TERRANOVA DEI PASSERINI", 1],
    ["L128", "FM", "TERRANOVA DI BISTERZA", 0],
    ["L126", "PZ", "TERRANOVA DI POLLINO", 1],
    ["D960", "CL", "TERRANOVA DI SICILIA", 0],
    ["G015", "SS", "TERRANOVA PAUSANIA", 0],
    ["L127", "RC", "TERRANOVA SAPPO MINULIO", 1],
    ["L123", "AR", "TERRANUOVA BRACCIOLINI", 1],
    ["L129", "MS", "TERRAROSSA", 0],
    ["L130", "PV", "TERRASA", 0],
    ["L131", "PA", "TERRASINI", 1],
    ["L131", "PA", "TERRASINI FAVAROTTA", 0],
    ["L132", "PD", "TERRASSA", 0],
    ["L132", "PD", "TERRASSA PADOVANA", 1],
    ["L133", "CS", "TERRATI", 0],
    ["L134", "CS", "TERRAVECCHIA", 1],
    ["L135", "MI", "TERRAZZANO", 0],
    ["L136", "VR", "TERRAZZO", 1],
    ["M407", "TN", "TERRE D'ADIGE", 1],
    ["M381", "FE", "TERRE DEL RENO", 1],
    ["M379", "PU", "TERRE ROVERESCHE", 1],
    ["L137", "TN", "TERRES", 0],
    ["L138", "PI", "TERRICCIOLA", 1],
    ["Z161", "EE", "TERRITORI PALESTINESI", 1],
    ["Z346", "EE", "TERRITORIO FRANCESE DEGLI AFAR E DEGLI ISSA", 0],
    ["L139", "AL", "TERRUGGIA", 1],
    ["L140", "NU", "TERTENIA", 1],
    ["L141", "BS", "TERZANO", 0],
    ["L142", "NA", "TERZIGNO", 1],
    ["L143", "AL", "TERZO", 1],
    ["L144", "UD", "TERZO", 0],
    ["L144", "UD", "TERZO D'AQUILEIA", 1],
    ["L144", "UD", "TERZO DI AQUILEIA", 0],
    ["L145", "TN", "TERZOLAS", 1],
    ["L146", "IM", "TERZORIO", 1],
    ["L147", "TN", "TESERO", 1],
    ["L148", "BZ", "TESIDO", 0],
    ["L149", "BZ", "TESIMO", 1],
    ["L150", "VT", "TESSENNANO", 1],
    ["L151", "NA", "TESTACCIO", 0],
    ["L151", "NA", "TESTACCIO D'ISCHIA", 0],
    ["L152", "SV", "TESTICO", 1],
    ["L153", "NU", "TETI", 1],
    ["L154", "CA", "TEULADA", 0],
    ["L154", "SU", "TEULADA", 1],
    ["L155", "CE", "TEVEROLA", 1],
    ["L156", "VI", "TEZZE", 0],
    ["L156", "VI", "TEZZE SUL BRENTA", 1],
    ["Z241", "EE", "THAILANDIA", 1],
    ["L157", "VI", "THIENE", 1],
    ["L158", "SS", "THIESI", 1],
    ["L159", "TO", "THURES", 0],
    ["L160", "NU", "TIANA", 1],
    ["L161", "TN", "TIARNO", 0],
    ["L162", "TN", "TIARNO DI SOPRA", 0],
    ["L163", "TN", "TIARNO DI SOTTO", 0],
    ["L164", "CR", "TICENGO", 1],
    ["L165", "AL", "TICINETO", 1],
    ["L166", "LE", "TIGGIANO", 1],
    ["L167", "GE", "TIGLIETO", 1],
    ["L168", "AT", "TIGLIOLE", 1],
    ["L169", "BS", "TIGNALE", 1],
    ["M247", "TN", "TIGNERONE", 0],
    ["L170", "BS", "TIMOLINE", 0],
    ["Z242", "EE", "TIMOR (ISOLA)", 0],
    ["L171", "TO", "TINA", 0],
    ["L172", "NU", "TINNURA", 0],
    ["L172", "OR", "TINNURA", 1],
    ["L173", "AQ", "TIONE", 0],
    ["L174", "TN", "TIONE", 0],
    ["L173", "AQ", "TIONE DEGLI ABRUZZI", 1],
    ["L174", "TN", "TIONE DI TRENTO", 1],
    ["L175", "SO", "TIRANO", 1],
    ["L176", "BZ", "TIRES", 1],
    ["L177", "CZ", "TIRIOLO", 1],
    ["L178", "BZ", "TIROLO", 1],
    ["L179", "BZ", "TISA", 0],
    ["L179", "BZ", "TISO", 0],
    ["L180", "SS", "TISSI", 1],
    ["L181", "PZ", "TITO", 1],
    ["L182", "RM", "TIVOLI", 1],
    ["H109", "PT", "TIZZANA", 0],
    ["L183", "PR", "TIZZANO", 0],
    ["L183", "PR", "TIZZANO VAL PARMA", 1],
    ["L184", "RE", "TOANO", 1],
    ["L185", "BN", "TOCCO", 0],
    ["L186", "CH", "TOCCO", 0],
    ["L185", "BN", "TOCCO CAUDIO", 1],
    ["L186", "PE", "TOCCO DA CASAURIA", 1],
    ["L187", "VB", "TOCENO", 1],
    ["L188", "PG", "TODI", 1],
    ["L189", "RI", "TOFFIA", 1],
    ["Z351", "EE", "TOGO", 1],
    ["L190", "SV", "TOIRANO", 1],
    ["Z727", "EE", "TOKELAU=ISOLE DELL'UNIONE", 1],
    ["L191", "MC", "TOLENTINO", 1],
    ["L192", "RM", "TOLFA", 1],
    ["L193", "BI", "TOLLEGNO", 1],
    ["L194", "CH", "TOLLO", 1],
    ["L195", "UD", "TOLMEZZO", 1],
    ["L196", "GO", "TOLMINO", 0],
    ["L197", "PZ", "TOLVE", 1],
    ["L198", "TS", "TOMADIO", 0],
    ["L081", "PU", "TOMBA DI PESARO", 0],
    ["C071", "AN", "TOMBA DI SENIGALLIA", 0],
    ["L199", "PD", "TOMBOLO", 1],
    ["L200", "TN", "TON", 1],
    ["L201", "TN", "TONADICO", 0],
    ["L202", "NU", "TONARA", 1],
    ["L203", "AT", "TONCO", 1],
    ["L204", "AT", "TONENGO", 1],
    ["D717", "VI", "TONEZZA", 0],
    ["D717", "VI", "TONEZZA DEL CIMONE", 1],
    ["Z728", "EE", "TONGA", 1],
    ["L205", "CE", "TORA E PICCILLI", 1],
    ["L206", "CS", "TORANO", 0],
    ["L207", "TE", "TORANO", 0],
    ["L206", "CS", "TORANO CASTELLO", 1],
    ["L207", "TE", "TORANO NUOVO", 1],
    ["L208", "VA", "TORBA", 0],
    ["L209", "BS", "TORBIATO", 0],
    ["L210", "BS", "TORBOLE", 0],
    ["L210", "BS", "TORBOLE CASAGLIA", 1],
    ["L211", "TN", "TORCEGNO", 1],
    ["L212", "SA", "TORCHIARA", 1],
    ["L213", "BR", "TORCHIAROLO", 1],
    ["L214", "AV", "TORELLA", 0],
    ["L215", "CB", "TORELLA", 0],
    ["L214", "AV", "TORELLA DEI LOMBARDI", 1],
    ["L215", "CB", "TORELLA DEL SANNIO", 1],
    ["L216", "PG", "TORGIANO", 1],
    ["L217", "AO", "TORGNON", 1],
    ["L217", "AO", "TORGNONE", 0],
    ["L218", "CH", "TORINO", 0],
    ["L219", "TO", "TORINO", 1],
    ["L218", "CH", "TORINO DI SANGRO", 1],
    ["L220", "BA", "TORITTO", 1],
    ["L221", "CR", "TORLINO", 0],
    ["L221", "CR", "TORLINO VIMERCATI", 1],
    ["L222", "MI", "TORMO", 0],
    ["L223", "NO", "TORNACO", 1],
    ["L224", "CH", "TORNARECCIO", 1],
    ["L225", "CR", "TORNATA", 1],
    ["L226", "MI", "TORNAVENTO", 0],
    ["L227", "AQ", "TORNIMPARTE", 1],
    ["L228", "CO", "TORNO", 1],
    ["L229", "PR", "TORNOLO", 1],
    ["L230", "CB", "TORO", 1],
    ["L231", "NU", "TORPE'", 1],
    ["L232", "TN", "TORRA", 0],
    ["L233", "SA", "TORRACA", 1],
    ["L234", "PV", "TORRADELLO", 0],
    ["L235", "SS", "TORRALBA", 1],
    ["L236", "IM", "TORRAZZA", 0],
    ["L237", "PV", "TORRAZZA COSTE", 1],
    ["L238", "TO", "TORRAZZA PIEMONTE", 1],
    ["L239", "BI", "TORRAZZO", 1],
    ["L240", "CZ", "TORRE", 0],
    ["L241", "CN", "TORRE", 0],
    ["L242", "PU", "TORRE", 0],
    ["L243", "RM", "TORRE", 0],
    ["L244", "SO", "TORRE", 0],
    ["L245", "NA", "TORRE ANNUNZIATA", 1],
    ["L247", "TO", "TORRE BAIRO", 0],
    ["L249", "PV", "TORRE BERETTI", 0],
    ["L250", "PV", "TORRE BERETTI E CASTELLARO", 1],
    ["L251", "BG", "TORRE BOLDONE", 1],
    ["L252", "CN", "TORRE BORMIDA", 1],
    ["L243", "FR", "TORRE CAJETANI", 1],
    ["L247", "TO", "TORRE CANAVESE", 1],
    ["L255", "CR", "TORRE D'ANGIOLINI", 0],
    ["L256", "PV", "TORRE D'ARESE", 1],
    ["L269", "PV", "TORRE D'ISOLA", 1],
    ["L257", "BG", "TORRE DE' BUSI", 1],
    ["L257", "LC", "TORRE DE' BUSI", 0],
    ["L262", "PV", "TORRE DE' NEGRI", 1],
    ["L263", "PE", "TORRE DE' PASSERI", 1],
    ["L258", "CR", "TORRE DE' PICENARDI", 1],
    ["L264", "AL", "TORRE DE' RATTI", 0],
    ["L265", "BG", "TORRE DE' ROVERI", 1],
    ["L266", "PV", "TORRE DE' TORTI", 0],
    ["L258", "CR", "TORRE DEI MALAMBERTI", 0],
    ["L259", "NA", "TORRE DEL GRECO", 1],
    ["L260", "PV", "TORRE DEL MANGANO", 0],
    ["L261", "PV", "TORRE DEL MONTE", 0],
    ["L267", "VE", "TORRE DI MOSTO", 1],
    ["L268", "AP", "TORRE DI PALME", 0],
    ["L240", "CZ", "TORRE DI RUGGIERO", 1],
    ["L244", "SO", "TORRE DI SANTA MARIA", 1],
    ["L272", "AV", "TORRE LE NOCELLE", 1],
    ["L241", "CN", "TORRE MONDOVI'", 1],
    ["L274", "SA", "TORRE ORSAIA", 1],
    ["L275", "TR", "TORRE ORSINA", 0],
    ["L276", "BG", "TORRE PALLAVICINA", 1],
    ["L277", "TO", "TORRE PELLICE", 1],
    ["L278", "CN", "TORRE SAN GIORGIO", 1],
    ["L242", "PU", "TORRE SAN MARCO", 0],
    ["L279", "AP", "TORRE SAN PATRIZIO", 0],
    ["L279", "FM", "TORRE SAN PATRIZIO", 1],
    ["L280", "BR", "TORRE SANTA SUSANNA", 1],
    ["L283", "CN", "TORRE UZZONE", 0],
    ["L285", "PV", "TORRE VECCHIA", 0],
    ["L246", "UD", "TORREANO", 1],
    ["L248", "VI", "TORREBELVICINO", 1],
    ["L253", "CH", "TORREBRUNA", 1],
    ["L254", "BN", "TORRECUSO", 1],
    ["L270", "PD", "TORREGLIA", 1],
    ["L271", "ME", "TORREGROTTA", 1],
    ["L273", "FG", "TORREMAGGIORE", 1],
    ["M286", "ME", "TORRENOVA", 1],
    ["L281", "CN", "TORRESINA", 1],
    ["L282", "PA", "TORRETTA", 1],
    ["L284", "CH", "TORREVECCHIA", 0],
    ["L285", "PV", "TORREVECCHIA PIA", 1],
    ["L284", "CH", "TORREVECCHIA TEATINA", 1],
    ["L286", "RI", "TORRI", 0],
    ["L287", "VR", "TORRI", 0],
    ["L287", "VR", "TORRI DEL BENACO", 1],
    ["L297", "VI", "TORRI DI QUARTESOLO", 1],
    ["L286", "RI", "TORRI IN SABINA", 1],
    ["L288", "IM", "TORRIA", 0],
    ["I550", "RN", "TORRIANA", 0],
    ["L289", "PV", "TORRIANO", 0],
    ["L290", "FR", "TORRICE", 1],
    ["L281", "CN", "TORRICELLA", 0],
    ["L291", "CH", "TORRICELLA", 0],
    ["L292", "PV", "TORRICELLA", 0],
    ["L293", "RI", "TORRICELLA", 0],
    ["L294", "TA", "TORRICELLA", 1],
    ["L295", "TE", "TORRICELLA", 0],
    ["L296", "CR", "TORRICELLA DEL PIZZO", 1],
    ["L293", "RI", "TORRICELLA IN SABINA", 1],
    ["L291", "CH", "TORRICELLA PELIGNA", 1],
    ["L295", "TE", "TORRICELLA SICURA", 1],
    ["L292", "PV", "TORRICELLA VERZATE", 1],
    ["L298", "GE", "TORRIGLIA", 1],
    ["L299", "PR", "TORRILE", 1],
    ["L300", "PV", "TORRINO", 0],
    ["L301", "AV", "TORRIONI", 1],
    ["L302", "RM", "TORRITA", 0],
    ["L303", "SI", "TORRITA", 0],
    ["L303", "SI", "TORRITA DI SIENA", 1],
    ["L302", "RM", "TORRITA TIBERINA", 1],
    ["A355", "NU", "TORTOLI'", 1],
    ["L304", "AL", "TORTONA", 1],
    ["L305", "CS", "TORTORA", 1],
    ["L306", "SA", "TORTORELLA", 1],
    ["L307", "TE", "TORTORETO", 1],
    ["L308", "ME", "TORTORICI", 1],
    ["L309", "UD", "TORVISCOSA", 1],
    ["L310", "RM", "TOSCANELLA", 0],
    ["L311", "BS", "TOSCOLANO", 0],
    ["L312", "BS", "TOSCOLANO-MADERNO", 1],
    ["L313", "TN", "TOSS", 0],
    ["L314", "TE", "TOSSICIA", 1],
    ["B044", "BO", "TOSSIGNANO", 0],
    ["L315", "GE", "TOVO", 0],
    ["L316", "SO", "TOVO", 0],
    ["L316", "SO", "TOVO DI SANT'AGATA", 1],
    ["L315", "SV", "TOVO SAN GIACOMO", 1],
    ["L317", "PA", "TRABIA", 1],
    ["L318", "BG", "TRABUCHELLO", 0],
    ["L319", "VA", "TRADATE", 1],
    ["F224", "CE", "TRAETTO", 0],
    ["L320", "NO", "TRAFFIUME", 0],
    ["L321", "OR", "TRAMATZA", 1],
    ["L322", "TN", "TRAMBILENO", 1],
    ["L322", "TN", "TRAMBILLENO", 0],
    ["L323", "SA", "TRAMONTI", 1],
    ["L324", "PN", "TRAMONTI DI SOPRA", 1],
    ["L325", "PN", "TRAMONTI DI SOTTO", 1],
    ["L326", "PZ", "TRAMUTOLA", 1],
    ["L327", "TO", "TRANA", 1],
    ["L328", "BA", "TRANI", 0],
    ["L328", "BT", "TRANI", 1],
    ["L329", "TN", "TRANSACQUA", 0],
    ["Z365", "EE", "TRANSKEI", 0],
    ["L330", "SO", "TRAONA", 1],
    ["L331", "TP", "TRAPANI", 1],
    ["L332", "PA", "TRAPPETO", 1],
    ["L333", "NO", "TRAREGO", 0],
    ["L333", "VB", "TRAREGO VIGGIONA", 1],
    ["L334", "AQ", "TRASACCO", 1],
    ["L335", "UD", "TRASAGHIS", 1],
    ["L336", "VB", "TRASQUERA", 1],
    ["D449", "LU", "TRASSILICO", 0],
    ["L337", "CA", "TRATALIAS", 0],
    ["L337", "SU", "TRATALIAS", 1],
    ["L338", "TO", "TRAUSELLA", 0],
    ["I236", "PV", "TRAVACO' SICCOMARIO", 1],
    ["L339", "BS", "TRAVAGLIATO", 1],
    ["L340", "TO", "TRAVE", 0],
    ["L341", "VA", "TRAVEDONA", 0],
    ["L342", "VA", "TRAVEDONA-MONATE", 1],
    ["L343", "CO", "TRAVERSA", 0],
    ["L344", "TO", "TRAVERSE", 0],
    ["L345", "TO", "TRAVERSELLA", 1],
    ["L346", "PR", "TRAVERSETOLO", 1],
    ["L340", "TO", "TRAVES", 1],
    ["L347", "PN", "TRAVESIO", 1],
    ["L348", "PC", "TRAVO", 1],
    ["M361", "TN", "TRE VILLE", 1],
    ["L349", "PD", "TREBASELEGHE", 1],
    ["L350", "PV", "TREBBIANO", 0],
    ["L350", "PV", "TREBBIANO NIZZA", 0],
    ["L351", "PC", "TREBECCO", 0],
    ["L352", "GE", "TREBIANO", 0],
    ["L352", "GE", "TREBIANO MAGRA", 0],
    ["L353", "CS", "TREBISACCE", 1],
    ["L354", "PR", "TRECASALI", 0],
    ["M280", "NA", "TRECASE", 1],
    ["L355", "CT", "TRECASTAGNI", 1],
    ["M318", "AN", "TRECASTELLI", 1],
    ["L356", "NO", "TRECATE", 1],
    ["L357", "PZ", "TRECCHINA", 1],
    ["L358", "MI", "TRECELLA", 0],
    ["L359", "RO", "TRECENTA", 1],
    ["L360", "CR", "TREDOSSI", 0],
    ["L361", "FC", "TREDOZIO", 1],
    ["L362", "MI", "TREGASIO", 0],
    ["L363", "CH", "TREGLIO", 1],
    ["L364", "VR", "TREGNAGO", 1],
    ["L365", "CO", "TREGOLO", 0],
    ["L366", "MC", "TREIA", 1],
    ["L367", "CN", "TREISO", 1],
    ["L368", "LC", "TREMENICO", 0],
    ["L369", "CT", "TREMESTIERI", 0],
    ["L369", "CT", "TREMESTIERI ETNEO", 1],
    ["L370", "CO", "TREMEZZINA", 0],
    ["M341", "CO", "TREMEZZINA", 1],
    ["L371", "CO", "TREMEZZO", 0],
    ["L372", "BS", "TREMOSINE", 0],
    ["L372", "BS", "TREMOSINE SUL GARDA", 1],
    ["L373", "MI", "TRENNO", 0],
    ["L374", "BZ", "TRENS", 0],
    ["L375", "CS", "TRENTA", 0],
    ["L376", "GO", "TRENTA D'ISONZO", 0],
    ["L377", "SA", "TRENTINARA", 1],
    ["L378", "TN", "TRENTO", 1],
    ["L379", "CE", "TRENTOLA", 0],
    ["L379", "CE", "TRENTOLA DUCENTA", 1],
    ["L380", "BS", "TRENZANO", 1],
    ["L381", "UD", "TREPPO", 0],
    ["L381", "UD", "TREPPO CARNICO", 0],
    ["L382", "UD", "TREPPO GRANDE", 1],
    ["M399", "UD", "TREPPO LIGOSULLO", 1],
    ["L383", "LE", "TREPUZZI", 1],
    ["L384", "SI", "TREQUANDA", 1],
    ["L385", "TN", "TRES", 0],
    ["L386", "MS", "TRESANA", 1],
    ["L387", "VI", "TRESCHE-CONCA", 0],
    ["L388", "BG", "TRESCORE", 0],
    ["L389", "CR", "TRESCORE", 0],
    ["L388", "BG", "TRESCORE BALNEARIO", 1],
    ["L389", "CR", "TRESCORE CREMASCO", 1],
    ["L390", "FE", "TRESIGALLO", 0],
    ["M409", "FE", "TRESIGNANA", 1],
    ["L391", "RC", "TRESILICO", 0],
    ["L392", "SO", "TRESIVIO", 1],
    ["L393", "OR", "TRESNURAGHES", 1],
    ["L394", "VI", "TRETTO", 0],
    ["L395", "CO", "TREVANO", 0],
    ["L396", "VR", "TREVENZUOLO", 1],
    ["L397", "PG", "TREVI", 1],
    ["L398", "RM", "TREVI", 0],
    ["L398", "FR", "TREVI NEL LAZIO", 1],
    ["L399", "AV", "TREVICO", 1],
    ["L400", "BG", "TREVIGLIO", 1],
    ["L401", "RM", "TREVIGNANO", 0],
    ["L402", "TV", "TREVIGNANO", 1],
    ["L401", "RM", "TREVIGNANO ROMANO", 1],
    ["L403", "AL", "TREVILLE", 1],
    ["L404", "BG", "TREVIOLO", 1],
    ["L405", "VA", "TREVISAGO", 0],
    ["L406", "BS", "TREVISO", 0],
    ["L407", "TV", "TREVISO", 1],
    ["L406", "BS", "TREVISO BRESCIANO", 1],
    ["L408", "MI", "TREZZANO", 0],
    ["L409", "MI", "TREZZANO", 0],
    ["L408", "MI", "TREZZANO ROSA", 1],
    ["L409", "MI", "TREZZANO SUL NAVIGLIO", 1],
    ["L410", "CN", "TREZZO", 0],
    ["L411", "MI", "TREZZO", 0],
    ["L411", "MI", "TREZZO SULL'ADDA", 1],
    ["L410", "CN", "TREZZO TINELLA", 1],
    ["L412", "CR", "TREZZOLASCO", 0],
    ["L413", "CO", "TREZZONE", 1],
    ["L414", "PD", "TRIBANO", 1],
    ["L415", "MI", "TRIBIANO", 1],
    ["L416", "GE", "TRIBOGNA", 1],
    ["A147", "CR", "TRIBURGO", 0],
    ["L417", "GO", "TRIBUSSA", 0],
    ["L418", "MT", "TRICARICO", 1],
    ["L419", "LE", "TRICASE", 1],
    ["L420", "VC", "TRICERRO", 1],
    ["L421", "UD", "TRICESIMO", 1],
    ["L422", "BL", "TRICHIANA", 0],
    ["L423", "NU", "TRIEI", 1],
    ["L424", "TS", "TRIESTE", 1],
    ["L425", "BA", "TRIGGIANO", 1],
    ["L426", "CR", "TRIGOLO", 1],
    ["Z612", "EE", "TRINIDAD E TOBAGO", 1],
    ["L427", "CN", "TRINITA'", 1],
    ["L428", "SS", "TRINITA' D'AGULTU E VIGNOLA", 1],
    ["B915", "BT", "TRINITAPOLI", 1],
    ["B915", "FG", "TRINITAPOLI", 0],
    ["L429", "VC", "TRINO", 1],
    ["L429", "VC", "TRINO VERCELLESE", 0],
    ["L430", "IM", "TRIORA", 1],
    ["L431", "ME", "TRIPI", 1],
    ["L432", "AL", "TRISOBBIO", 1],
    ["L433", "VI", "TRISSINO", 1],
    ["L434", "MB", "TRIUGGIO", 1],
    ["L434", "MI", "TRIUGGIO", 0],
    ["L435", "CB", "TRIVENTO", 1],
    ["L436", "BI", "TRIVERO", 0],
    ["L437", "FR", "TRIVIGLIANO", 1],
    ["L438", "UD", "TRIVIGNANO UDINESE", 1],
    ["L439", "PZ", "TRIVIGNO", 1],
    ["L440", "PV", "TRIVOLZIO", 1],
    ["L441", "MI", "TRIVULZA", 0],
    ["L442", "MI", "TRIVULZINA", 0],
    ["L443", "NO", "TROBASO", 0],
    ["L444", "BZ", "TRODENA", 0],
    ["L444", "BZ", "TRODENA NEL PARCO NATURALE", 1],
    ["L445", "TO", "TROFARELLO", 1],
    ["L446", "PV", "TROGNANO", 0],
    ["L447", "FG", "TROIA", 1],
    ["L448", "EN", "TROINA", 1],
    ["L449", "PV", "TROMELLO", 1],
    ["L450", "VB", "TRONTANO", 1],
    ["A705", "CO", "TRONZANO", 0],
    ["L451", "NO", "TRONZANO", 0],
    ["A705", "VA", "TRONZANO LAGO MAGGIORE", 1],
    ["L451", "VC", "TRONZANO VERCELLESE", 1],
    ["L452", "VV", "TROPEA", 1],
    ["L453", "PV", "TROVO", 1],
    ["L454", "MI", "TRUCCAZZANO", 1],
    ["L455", "BZ", "TUBRE", 1],
    ["L456", "TN", "TUENETTO", 0],
    ["L457", "TN", "TUENNO", 0],
    ["L458", "CB", "TUFARA", 1],
    ["L459", "CH", "TUFILLO", 1],
    ["L460", "NA", "TUFINO", 1],
    ["L461", "AV", "TUFO", 1],
    ["L462", "LE", "TUGLIE", 1],
    ["L463", "CA", "TUILI", 0],
    ["L463", "SU", "TUILI", 1],
    ["L464", "SS", "TULA", 1],
    ["L465", "BZ", "TUNES", 0],
    ["Z352", "EE", "TUNISIA", 1],
    ["L466", "PG", "TUORO", 0],
    ["L466", "PG", "TUORO SUL TRASIMENO", 1],
    ["L467", "PV", "TURAGO BORDONE", 0],
    ["G507", "RI", "TURANIA", 1],
    ["L468", "BS", "TURANO", 0],
    ["L469", "MI", "TURANO", 0],
    ["L469", "LO", "TURANO LODIGIANO", 1],
    ["L470", "CO", "TURATE", 1],
    ["L471", "MI", "TURBIGO", 1],
    ["Z243", "EE", "TURCHIA", 1],
    ["L472", "BA", "TURI", 1],
    ["Z151", "EE", "TURKMENISTAN", 0],
    ["Z258", "EE", "TURKMENISTAN", 1],
    ["Z519", "EE", "TURKS E CAICOS (ISOLE)", 1],
    ["L473", "CA", "TURRI", 0],
    ["L473", "SU", "TURRI", 1],
    ["L474", "GO", "TURRIACO", 1],
    ["L475", "PE", "TURRIVALIGNANI", 1],
    ["L476", "MI", "TURRO", 0],
    ["L476", "MI", "TURRO MILANESE", 0],
    ["L477", "MT", "TURSI", 1],
    ["L478", "ME", "TUSA", 1],
    ["L310", "VT", "TUSCANIA", 1],
    ["Z732", "EE", "TUVALU", 1],
    ["L479", "IM", "UBAGA", 0],
    ["C789", "BG", "UBIALE CLANEZZO", 1],
    ["L480", "VA", "UBOLDO", 1],
    ["L481", "UD", "UCOVIZZA", 0],
    ["Z138", "EE", "UCRAINA", 1],
    ["L482", "ME", "UCRIA", 1],
    ["L483", "UD", "UDINE", 1],
    ["Z353", "EE", "UGANDA", 1],
    ["L484", "LE", "UGENTO", 1],
    ["L485", "LE", "UGGIANO LA CHIESA", 1],
    ["L486", "CO", "UGGIATE", 0],
    ["L487", "CO", "UGGIATE-TREVANO", 1],
    ["L481", "UD", "UGOVIZZA VALBRUNA", 0],
    ["L488", "CA", "ULA'", 0],
    ["L488", "OR", "ULA' TIRSO", 1],
    ["L489", "NU", "ULASSAI", 1],
    ["L490", "BZ", "ULTIMO", 1],
    ["G196", "TO", "ULZIO", 0],
    ["L491", "PL", "UMAGO", 0],
    ["F978", "AN", "UMANA", 0],
    ["D786", "PG", "UMBERTIDE", 1],
    ["L492", "KR", "UMBRIATICO", 1],
    ["L493", "NO", "UNCHIO", 0],
    ["Z134", "EE", "UNGHERIA", 1],
    ["Z135", "EE", "UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE", 0],
    ["L494", "BS", "URAGO D'OGLIO", 1],
    ["L495", "BS", "URAGO MELLA", 0],
    ["L496", "OR", "URAS", 1],
    ["L497", "PD", "URBANA", 1],
    ["L498", "PU", "URBANIA", 1],
    ["L499", "SV", "URBE", 1],
    ["L500", "PU", "URBINO", 1],
    ["L501", "MC", "URBISAGLIA", 1],
    ["L501", "MC", "URBISAGLIA BONSERVIZI", 0],
    ["L502", "BG", "URGNANO", 1],
    ["L503", "SS", "URI", 1],
    ["L504", "CO", "URIO", 0],
    ["Z613", "EE", "URUGUAY", 1],
    ["L505", "CB", "URURI", 1],
    ["L506", "NU", "URZULEI", 1],
    ["L507", "GE", "USCIO", 1],
    ["L508", "OR", "USELLUS", 1],
    ["L509", "SS", "USINI", 1],
    ["L510", "MI", "USMATE", 0],
    ["L511", "MB", "USMATE VELATE", 1],
    ["L511", "MI", "USMATE VELATE", 0],
    ["L512", "CA", "USSANA", 0],
    ["L512", "SU", "USSANA", 1],
    ["L513", "CA", "USSARAMANNA", 0],
    ["L513", "SU", "USSARAMANNA", 1],
    ["L514", "NU", "USSASSAI", 1],
    ["L515", "TO", "USSEAUX", 1],
    ["L516", "TO", "USSEGLIO", 1],
    ["L517", "MC", "USSITA", 1],
    ["L518", "CN", "USSOLO", 0],
    ["L519", "PA", "USTICA", 1],
    ["L520", "GO", "USTIE", 0],
    ["L521", "CA", "UTA", 1],
    ["Z143", "EE", "UZBEKISTAN", 0],
    ["Z259", "EE", "UZBEKISTAN", 1],
    ["L522", "PT", "UZZANO", 1],
    ["L523", "PV", "VACCARIZZA", 0],
    ["L524", "CS", "VACCARIZZO", 0],
    ["L524", "CS", "VACCARIZZO ALBANESE", 1],
    ["L525", "RI", "VACONE", 1],
    ["L526", "CH", "VACRI", 1],
    ["L527", "BZ", "VADENA", 1],
    ["L528", "GE", "VADO", 0],
    ["L528", "SV", "VADO LIGURE", 1],
    ["L533", "LU", "VAGLI SOTTO", 1],
    ["L529", "FI", "VAGLIA", 1],
    ["L530", "AL", "VAGLIERANO", 0],
    ["L531", "AL", "VAGLIO", 0],
    ["L532", "PZ", "VAGLIO", 0],
    ["L532", "PZ", "VAGLIO BASILICATA", 1],
    ["L532", "PZ", "VAGLIO DI BASILICATA", 0],
    ["L532", "PZ", "VAGLIO LUCANO", 0],
    ["L531", "AT", "VAGLIO SERRA", 1],
    ["L534", "NO", "VAGNA", 0],
    ["L535", "CR", "VAIANO", 0],
    ["L536", "MI", "VAIANO", 0],
    ["L537", "PO", "VAIANO", 1],
    ["L535", "CR", "VAIANO CREMASCO", 1],
    ["L536", "MI", "VAIANO VALLE", 0],
    ["L538", "TO", "VAIE", 1],
    ["L539", "CR", "VAILATE", 1],
    ["L540", "CE", "VAIRANO", 0],
    ["L541", "CR", "VAIRANO", 0],
    ["L542", "PV", "VAIRANO", 0],
    ["L541", "CR", "VAIRANO CREMASCO", 0],
    ["L540", "CE", "VAIRANO PATENORA", 1],
    ["L542", "PV", "VAIRANO PAVESE", 0],
    ["M265", "PN", "VAJONT", 1],
    ["M334", "BG", "VAL BREMBILLA", 1],
    ["G451", "AR", "VAL D'AMBRA", 0],
    ["L555", "TO", "VAL DELLA TORRE", 1],
    ["M405", "TO", "VAL DI CHY", 1],
    ["L562", "PV", "VAL DI NIZZA", 1],
    ["H261", "AO", "VAL DI REMA", 0],
    ["L564", "BZ", "VAL DI VIZZE", 1],
    ["M374", "BL", "VAL DI ZOLDO", 1],
    ["M384", "VI", "VAL LIONA", 1],
    ["L637", "VA", "VAL MARCHIROLO", 0],
    ["L638", "SO", "VAL MASINO", 1],
    ["H259", "CO", "VAL REZZO", 1],
    ["M254", "FM", "VAL SANTAMARINA", 0],
    ["L543", "BZ", "VALAS", 0],
    ["L544", "BG", "VALBONDIONE", 1],
    ["L545", "BG", "VALBREMBO", 1],
    ["M423", "VI", "VALBRENTA", 1],
    ["L546", "GE", "VALBREVENNA", 1],
    ["L547", "CO", "VALBRONA", 1],
    ["L548", "TO", "VALCHIUSA", 0],
    ["M415", "TO", "VALCHIUSA", 1],
    ["L549", "AO", "VALCHIUSELLA", 0],
    ["L550", "TN", "VALDA", 0],
    ["A179", "TN", "VALDAGNO", 0],
    ["L551", "VI", "VALDAGNO", 1],
    ["A179", "TN", "VALDAGNO DI TRENTO", 0],
    ["M343", "TN", "VALDAONE", 1],
    ["L552", "BZ", "VALDAORA", 1],
    ["L553", "PL", "VALDARSA", 0],
    ["L554", "VI", "VALDASTICO", 1],
    ["L556", "BI", "VALDENGO", 1],
    ["G319", "TP", "VALDERICE", 1],
    ["L557", "SO", "VALDIDENTRO", 1],
    ["L558", "CN", "VALDIERI", 1],
    ["L559", "BZ", "VALDIGIOVO", 0],
    ["L560", "AO", "VALDIGNA D'AOSTA", 0],
    ["M417", "BI", "VALDILANA", 1],
    ["L561", "ME", "VALDINA", 1],
    ["L563", "SO", "VALDISOTTO", 1],
    ["L565", "TV", "VALDOBBIADENE", 1],
    ["L566", "VC", "VALDUGGIA", 1],
    ["L567", "VR", "VALEGGIO", 0],
    ["L568", "PV", "VALEGGIO", 1],
    ["L567", "VR", "VALEGGIO SUL MINCIO", 1],
    ["L569", "VT", "VALENTANO", 1],
    ["L570", "AL", "VALENZA", 1],
    ["L571", "BA", "VALENZANO", 1],
    ["L572", "LO", "VALERA FRATTA", 1],
    ["L573", "PG", "VALFABBRICA", 1],
    ["L574", "AT", "VALFENERA", 1],
    ["L575", "TN", "VALFLORIANA", 1],
    ["M382", "MC", "VALFORNACE", 1],
    ["L576", "SO", "VALFURVA", 1],
    ["L577", "VA", "VALGANNA", 1],
    ["L578", "TO", "VALGIOIE", 1],
    ["L559", "BZ", "VALGIOVO", 0],
    ["L579", "BG", "VALGOGLIO", 1],
    ["L580", "CN", "VALGRANA", 1],
    ["L581", "LC", "VALGREGHENTINO", 1],
    ["L582", "AO", "VALGRISANCHE", 0],
    ["L582", "AO", "VALGRISENCHE", 1],
    ["L582", "AO", "VALGRISENZA", 0],
    ["L583", "EN", "VALGUARNERA CAROPEPE", 1],
    ["L585", "BG", "VALL'ALTA", 0],
    ["L584", "BL", "VALLADA", 0],
    ["L584", "BL", "VALLADA AGORDINA", 1],
    ["L586", "BI", "VALLANZENGO", 1],
    ["L587", "BZ", "VALLARGA", 0],
    ["L588", "TN", "VALLARSA", 1],
    ["L589", "AV", "VALLATA", 1],
    ["L590", "BL", "VALLE", 0],
    ["L591", "CE", "VALLE", 0],
    ["L592", "MI", "VALLE", 0],
    ["L593", "PV", "VALLE", 0],
    ["L594", "CE", "VALLE AGRICOLA", 1],
    ["L595", "BZ", "VALLE AURINA", 1],
    ["M404", "VB", "VALLE CANNOBINA", 1],
    ["L597", "TE", "VALLE CASTELLANA", 1],
    ["L602", "PL", "VALLE D'ISTRIA", 0],
    ["G540", "SA", "VALLE DELL'ANGELO", 1],
    ["L590", "BL", "VALLE DI CADORE", 1],
    ["L601", "BZ", "VALLE DI CASIES", 1],
    ["L591", "CE", "VALLE DI MADDALONI", 1],
    ["L594", "CE", "VALLE DI PRATA", 0],
    ["L592", "MI", "VALLE GUIDINO", 0],
    ["L606", "VC", "VALLE INFERIORE MOSSO", 0],
    ["L593", "PV", "VALLE LOMELLINA", 1],
    ["L606", "BI", "VALLE MOSSO", 0],
    ["L617", "PV", "VALLE SALIMBENE", 1],
    ["L618", "TN", "VALLE SAN FELICE", 0],
    ["L619", "TE", "VALLE SAN GIOVANNI", 0],
    ["L620", "BI", "VALLE SAN NICOLAO", 1],
    ["L621", "BZ", "VALLE SAN SILVESTRO", 0],
    ["L622", "VC", "VALLE SUPERIORE MOSSO", 0],
    ["L596", "IM", "VALLEBONA", 1],
    ["L598", "FR", "VALLECORSA", 1],
    ["L599", "IM", "VALLECROSIA", 1],
    ["L600", "IM", "VALLECROSIA CON SAN BIAGIO DELLA CIMA", 0],
    ["L603", "PA", "VALLEDOLMO", 1],
    ["L604", "SS", "VALLEDORIA", 1],
    ["I322", "CZ", "VALLEFIORITA", 1],
    ["M331", "PU", "VALLEFOGLIA", 1],
    ["L605", "FR", "VALLEFREDDA", 0],
    ["M362", "TN", "VALLELAGHI", 1],
    ["L607", "VV", "VALLELONGA", 1],
    ["L608", "BZ", "VALLELUNGA", 0],
    ["L609", "CL", "VALLELUNGA", 0],
    ["L608", "BZ", "VALLELUNGA CARLINO", 0],
    ["L609", "CL", "VALLELUNGA PRATAMENO", 1],
    ["L605", "FR", "VALLEMAIO", 1],
    ["L610", "UD", "VALLENONCELLO", 0],
    ["L611", "RM", "VALLEPIETRA", 1],
    ["L612", "VT", "VALLERANO", 1],
    ["L613", "CA", "VALLERMOSA", 0],
    ["L613", "SU", "VALLERMOSA", 1],
    ["L614", "FR", "VALLEROTONDA", 1],
    ["L615", "BZ", "VALLES", 0],
    ["L616", "AV", "VALLESACCARDA", 1],
    ["L623", "BG", "VALLEVE", 1],
    ["L624", "VI", "VALLI DEI SIGNORI", 0],
    ["L624", "VI", "VALLI DEL PASUBIO", 1],
    ["L625", "RM", "VALLINFREDA", 1],
    ["L626", "BS", "VALLIO", 0],
    ["L626", "BS", "VALLIO TERME", 1],
    ["L627", "PG", "VALLO", 0],
    ["L628", "SA", "VALLO", 0],
    ["L629", "TO", "VALLO", 0],
    ["L628", "SA", "VALLO DELLA LUCANIA", 1],
    ["L627", "PG", "VALLO DI NERA", 1],
    ["L629", "TO", "VALLO TORINESE", 1],
    ["L630", "VI", "VALLONARA", 0],
    ["L631", "CN", "VALLORIA", 0],
    ["L632", "IM", "VALLORIA", 0],
    ["L632", "IM", "VALLORIA MARITTIMA", 0],
    ["L631", "CN", "VALLORIATE", 1],
    ["L633", "AL", "VALMACCA", 1],
    ["L634", "LC", "VALMADRERA", 1],
    ["L635", "VC", "VALMAGGIA", 0],
    ["L636", "CN", "VALMALA", 0],
    ["L639", "RM", "VALMONTONE", 1],
    ["L640", "CO", "VALMOREA", 1],
    ["L641", "PR", "VALMOZZOLA", 1],
    ["L642", "BG", "VALNEGRA", 1],
    ["L643", "AO", "VALPELLINA", 0],
    ["L643", "AO", "VALPELLINE", 1],
    ["L644", "TO", "VALPERGA", 1],
    ["L645", "AO", "VALPRATO", 0],
    ["B510", "TO", "VALPRATO SOANA", 1],
    ["L646", "VI", "VALROVINA", 0],
    ["M320", "BO", "VALSAMOGGIA", 1],
    ["L647", "AO", "VALSAVARA", 0],
    ["L647", "AO", "VALSAVARANCHE", 0],
    ["L647", "AO", "VALSAVARENCHE", 1],
    ["L648", "BS", "VALSAVIORE", 0],
    ["L649", "BG", "VALSECCA", 0],
    ["D513", "MT", "VALSINNI", 1],
    ["C936", "CO", "VALSOLDA", 1],
    ["L650", "VI", "VALSTAGNA", 0],
    ["L651", "VB", "VALSTRONA", 1],
    ["L652", "BG", "VALTESSE", 0],
    ["L653", "PG", "VALTOPINA", 1],
    ["L654", "AO", "VALTORNENZA", 0],
    ["L655", "BG", "VALTORTA", 1],
    ["L654", "AO", "VALTOURNANCHE", 0],
    ["L654", "AO", "VALTOURNENCHE", 1],
    ["L656", "SA", "VALVA", 1],
    ["M395", "LC", "VALVARRONE", 1],
    ["L657", "PN", "VALVASONE", 0],
    ["M346", "PN", "VALVASONE ARZENE", 1],
    ["L658", "CT", "VALVERDE", 1],
    ["L659", "PV", "VALVERDE", 0],
    ["L468", "BS", "VALVESTINO", 1],
    ["L660", "BZ", "VANDOIES", 1],
    ["L661", "BZ", "VANDOIES DI SOPRA", 0],
    ["L662", "BZ", "VANDOIES DI SOTTO", 0],
    ["L663", "BZ", "VANGA", 0],
    ["Z733", "EE", "VANUATU", 1],
    ["L664", "MI", "VANZAGHELLO", 1],
    ["L665", "MI", "VANZAGO", 1],
    ["L666", "NO", "VANZONE", 0],
    ["L666", "VB", "VANZONE CON SAN CARLO", 1],
    ["L667", "MI", "VAPRIO", 0],
    ["L668", "NO", "VAPRIO", 0],
    ["L667", "MI", "VAPRIO D'ADDA", 1],
    ["L668", "NO", "VAPRIO D'AGOGNA", 1],
    ["L669", "VC", "VARALLO", 1],
    ["L670", "NO", "VARALLO POMBIA", 1],
    ["L671", "CO", "VARANO", 0],
    ["M248", "TN", "VARANO", 0],
    ["L671", "VA", "VARANO BORGHI", 1],
    ["L672", "PR", "VARANO DE' MELEGARI", 1],
    ["L673", "RC", "VARAPODIO", 1],
    ["L674", "VA", "VARARO", 0],
    ["L675", "SV", "VARAZZE", 1],
    ["L676", "PG", "VARCO", 0],
    ["L676", "RI", "VARCO SABINO", 1],
    ["L677", "MB", "VAREDO", 1],
    ["L677", "MI", "VAREDO", 0],
    ["L678", "TN", "VARENA", 0],
    ["L679", "AL", "VARENGO", 0],
    ["L680", "LC", "VARENNA", 1],
    ["L681", "GE", "VARESE", 0],
    ["L682", "VA", "VARESE", 1],
    ["L681", "SP", "VARESE LIGURE", 1],
    ["L683", "AL", "VARGO", 0],
    ["L684", "GE", "VARIGOTTI", 0],
    ["L685", "TO", "VARISELLA", 1],
    ["L686", "UD", "VARMO", 1],
    ["L687", "BZ", "VARNA", 1],
    ["L688", "BN", "VARONI", 0],
    ["L689", "PR", "VARSI", 1],
    ["L690", "PV", "VARZI", 1],
    ["L691", "VB", "VARZO", 1],
    ["L692", "BL", "VAS", 0],
    ["A701", "VT", "VASANELLO", 1],
    ["L693", "IM", "VASIA", 1],
    ["L694", "TN", "VASIO", 0],
    ["L695", "CO", "VASSENA", 0],
    ["E372", "CH", "VASTO", 1],
    ["L696", "IS", "VASTOGIRARDI", 1],
    ["L697", "TN", "VATTARO", 0],
    ["L698", "TO", "VAUDA CANAVESE", 1],
    ["L698", "TO", "VAUDA DI FRONT", 0],
    ["H847", "TO", "VAUDA SAN MORIZIO", 0],
    ["L538", "TO", "VAYES", 0],
    ["E728", "RI", "VAZIA", 0],
    ["L699", "VV", "VAZZANO", 1],
    ["L700", "TV", "VAZZOLA", 1],
    ["L701", "VA", "VECCANA", 0],
    ["L702", "PI", "VECCHIANO", 1],
    ["L703", "CO", "VEDANO", 0],
    ["L704", "MI", "VEDANO", 0],
    ["L704", "MB", "VEDANO AL LAMBRO", 1],
    ["L704", "MI", "VEDANO AL LAMBRO", 0],
    ["L703", "VA", "VEDANO OLONA", 1],
    ["L705", "VA", "VEDDASCA", 0],
    ["L706", "TV", "VEDELAGO", 1],
    ["L707", "BG", "VEDESETA", 1],
    ["L708", "MI", "VEDUGGIO", 0],
    ["L709", "MB", "VEDUGGIO CON COLZANO", 1],
    ["L709", "MI", "VEDUGGIO CON COLZANO", 0],
    ["L710", "PD", "VEGGIANO", 1],
    ["M293", "FM", "VEGLIA", 0],
    ["L711", "LE", "VEGLIE", 1],
    ["L712", "BI", "VEGLIO", 1],
    ["L713", "VT", "VEJANO", 1],
    ["L543", "BZ", "VELASIO", 0],
    ["L511", "MI", "VELATE", 0],
    ["L714", "VA", "VELATE", 0],
    ["L511", "MI", "VELATE MILANESE", 0],
    ["L715", "CO", "VELESO", 1],
    ["L716", "PV", "VELEZZO", 0],
    ["L716", "PV", "VELEZZO LOMELLINA", 1],
    ["L717", "PT", "VELLANO", 0],
    ["L718", "SV", "VELLEGO", 0],
    ["L719", "RM", "VELLETRI", 1],
    ["L720", "PV", "VELLEZZO", 0],
    ["L720", "PV", "VELLEZZO BELLINI", 1],
    ["L721", "BS", "VELLO", 0],
    ["L722", "VR", "VELO", 0],
    ["L723", "VI", "VELO", 0],
    ["L723", "VI", "VELO D'ASTICO", 1],
    ["L722", "VR", "VELO VERONESE", 1],
    ["L724", "BZ", "VELTURNO", 1],
    ["L725", "IS", "VENAFRO", 1],
    ["L726", "TO", "VENALZIO", 0],
    ["L727", "TO", "VENARIA REALE", 1],
    ["L728", "AP", "VENAROTTA", 1],
    ["L729", "CN", "VENASCA", 1],
    ["L726", "TO", "VENAUS", 1],
    ["Z366", "EE", "VENDA", 1],
    ["L730", "SV", "VENDONE", 1],
    ["L731", "LC", "VENDROGNO", 0],
    ["L732", "VA", "VENEGONO", 0],
    ["L733", "VA", "VENEGONO INFERIORE", 1],
    ["L734", "VA", "VENEGONO SUPERIORE", 1],
    ["L735", "ME", "VENETICO", 1],
    ["L736", "VE", "VENEZIA", 1],
    ["Z614", "EE", "VENEZUELA", 1],
    ["L737", "CO", "VENIANO", 1],
    ["L738", "PZ", "VENOSA", 1],
    ["M364", "RE", "VENTASSO", 1],
    ["L739", "AV", "VENTICANO", 1],
    ["L740", "PA", "VENTIMIGLIA", 0],
    ["L741", "IM", "VENTIMIGLIA", 1],
    ["L740", "PA", "VENTIMIGLIA DI SICILIA", 1],
    ["L742", "LT", "VENTOTENE", 1],
    ["L743", "UD", "VENZONE", 1],
    ["L744", "MI", "VERANO", 0],
    ["L745", "BZ", "VERANO", 1],
    ["L744", "MB", "VERANO BRIANZA", 1],
    ["L744", "MI", "VERANO BRIANZA", 0],
    ["L746", "VB", "VERBANIA", 1],
    ["L747", "CS", "VERBICARO", 1],
    ["L748", "CO", "VERCANA", 1],
    ["L749", "SO", "VERCEIA", 1],
    ["L750", "VC", "VERCELLI", 1],
    ["L751", "LC", "VERCURAGO", 1],
    ["L752", "BG", "VERDELLINO", 1],
    ["L753", "BG", "VERDELLO", 1],
    ["L754", "CO", "VERDERIO", 0],
    ["M337", "LC", "VERDERIO", 1],
    ["L755", "LC", "VERDERIO INFERIORE", 0],
    ["L756", "LC", "VERDERIO SUPERIORE", 0],
    ["L757", "TN", "VERDESINA", 0],
    ["L758", "CN", "VERDUNO", 1],
    ["L759", "SV", "VEREZZI", 0],
    ["L760", "CO", "VERGANO", 0],
    ["L761", "NO", "VERGANO", 0],
    ["L761", "NO", "VERGANO NOVARESE", 0],
    ["L762", "BO", "VERGATO", 1],
    ["L763", "LU", "VERGEMOLI", 0],
    ["L764", "FC", "VERGHERETO", 1],
    ["L765", "VA", "VERGIATE", 1],
    ["Z520", "EE", "VERGINI AMERICANE (ISOLE)", 1],
    ["Z525", "EE", "VERGINI BRITANNICHE (ISOLE)", 1],
    ["L766", "MI", "VERGO", 0],
    ["L767", "VA", "VERGOBBIO", 0],
    ["H840", "CO", "VERGOSA", 0],
    ["L768", "MI", "VERMEZZO", 0],
    ["M424", "MI", "VERMEZZO CON ZELO", 1],
    ["L769", "TN", "VERMIGLIO", 1],
    ["L770", "CO", "VERNA", 0],
    ["L771", "CN", "VERNANTE", 1],
    ["L772", "PC", "VERNASCA", 1],
    ["L773", "MI", "VERNATE", 1],
    ["L774", "SP", "VERNAZZA", 1],
    ["L775", "PO", "VERNIO", 1],
    ["L776", "LE", "VERNOLE", 1],
    ["L777", "BS", "VEROLANUOVA", 1],
    ["L778", "BS", "VEROLAVECCHIA", 1],
    ["L779", "TO", "VEROLENGO", 1],
    ["L780", "FR", "VEROLI", 1],
    ["L781", "VR", "VERONA", 1],
    ["D193", "VR", "VERONELLA", 1],
    ["L782", "GO", "VERPOGLIANO", 0],
    ["L783", "AO", "VERRAYES", 1],
    ["C282", "AO", "VERRE'S", 0],
    ["C282", "AO", "VERRES", 1],
    ["L784", "PV", "VERRETTO", 1],
    ["C200", "CB", "VERRINO", 0],
    ["L785", "BI", "VERRONE", 1],
    ["L786", "PV", "VERRUA", 0],
    ["L787", "TO", "VERRUA", 0],
    ["L788", "PV", "VERRUA PO", 1],
    ["L787", "TO", "VERRUA SAVOIA", 1],
    ["L786", "PV", "VERRUA SICCOMARIO", 0],
    ["L789", "GO", "VERSA", 0],
    ["L790", "BZ", "VERSCIAGO", 0],
    ["L791", "CO", "VERTEMATE", 0],
    ["L792", "CO", "VERTEMATE CON MINOPRIO", 1],
    ["L793", "PL", "VERTENEGLIO", 0],
    ["L794", "GO", "VERTOIBA", 0],
    ["L794", "GO", "VERTOIBA IN CAMPI SANTI", 0],
    ["L795", "BG", "VERTOVA", 1],
    ["L796", "GO", "VERTOVINO", 0],
    ["L797", "RN", "VERUCCHIO", 1],
    ["L798", "NO", "VERUNO", 0],
    ["L799", "SO", "VERVIO", 1],
    ["L800", "TN", "VERVO'", 0],
    ["L801", "UD", "VERZEGNIS", 1],
    ["L803", "GE", "VERZI PIETRA", 0],
    ["L802", "KR", "VERZINO", 1],
    ["L804", "CN", "VERZUOLO", 1],
    ["L805", "PD", "VESCOVANA", 1],
    ["L806", "CR", "VESCOVATO", 1],
    ["L807", "AT", "VESIME", 1],
    ["L808", "NO", "VESPOLATE", 1],
    ["L809", "IM", "VESSALICO", 1],
    ["L810", "VR", "VESTENANOVA", 1],
    ["L811", "TO", "VESTIGNE'", 1],
    ["L812", "BS", "VESTONE", 1],
    ["L813", "LC", "VESTRENO", 0],
    ["L814", "VT", "VETRALLA", 1],
    ["L815", "RE", "VETTO", 1],
    ["L816", "BS", "VEZZA", 0],
    ["L817", "CN", "VEZZA", 0],
    ["L817", "CN", "VEZZA D'ALBA", 1],
    ["L816", "BS", "VEZZA D'OGLIO", 1],
    ["L818", "BZ", "VEZZANO", 0],
    ["L819", "GE", "VEZZANO", 0],
    ["L820", "RE", "VEZZANO", 0],
    ["L821", "TN", "VEZZANO", 0],
    ["L818", "BZ", "VEZZANO IN VENOSTA", 0],
    ["L819", "SP", "VEZZANO LIGURE", 1],
    ["L820", "RE", "VEZZANO SUL CROSTOLO", 1],
    ["L822", "GE", "VEZZI", 0],
    ["L823", "SV", "VEZZI PORTIO", 1],
    ["L824", "NO", "VEZZO", 0],
    ["L825", "CR", "VHO", 0],
    ["E136", "BO", "VIADAGOLA", 0],
    ["L826", "MN", "VIADANA", 1],
    ["L827", "BG", "VIADANICA", 1],
    ["L828", "CT", "VIAGRANDE", 1],
    ["L829", "AT", "VIALE", 1],
    ["L829", "AT", "VIALE D'ASTI", 0],
    ["L830", "TO", "VIALFRE'", 1],
    ["L713", "RM", "VIANO", 0],
    ["L831", "RE", "VIANO", 1],
    ["L832", "TN", "VIARAGO", 0],
    ["L833", "LU", "VIAREGGIO", 1],
    ["L834", "AT", "VIARIGI", 1],
    ["F537", "VV", "VIBO VALENTIA", 1],
    ["H930", "MI", "VIBOLDONE", 0],
    ["L835", "SA", "VIBONATI", 1],
    ["L836", "FR", "VICALVI", 1],
    ["L837", "PA", "VICARI", 1],
    ["L838", "FI", "VICCHIO", 1],
    ["L839", "NO", "VICENO", 0],
    ["L840", "VI", "VICENZA", 1],
    ["L548", "TO", "VICO", 0],
    ["L841", "CN", "VICO", 0],
    ["L842", "FG", "VICO", 0],
    ["L843", "RM", "VICO", 0],
    ["L548", "TO", "VICO CANAVESE", 0],
    ["L842", "FG", "VICO DEL GARGANO", 1],
    ["L844", "CE", "VICO DI PANTANO", 0],
    ["L845", "NA", "VICO EQUENSE", 1],
    ["L843", "FR", "VICO NEL LAZIO", 1],
    ["L841", "CN", "VICOFORTE", 1],
    ["L846", "PE", "VICOLI", 1],
    ["L847", "NO", "VICOLUNGO", 1],
    ["L848", "PC", "VICOMARINO", 0],
    ["L849", "VA", "VICONAGO", 0],
    ["L850", "PI", "VICOPISANO", 1],
    ["L851", "RM", "VICOVARO", 1],
    ["L852", "MI", "VIDARDO", 0],
    ["M259", "SS", "VIDDALBA", 1],
    ["L853", "CR", "VIDICETO CON GURATA", 0],
    ["L854", "PV", "VIDIGULFO", 1],
    ["L855", "CR", "VIDOLASCO", 0],
    ["L856", "TV", "VIDOR", 1],
    ["L857", "TO", "VIDRACCO", 1],
    ["L858", "FG", "VIESTE", 1],
    ["Z251", "EE", "VIETNAM", 1],
    ["Z245", "EE", "VIETNAM DEL NORD", 0],
    ["Z244", "EE", "VIETNAM DEL SUD", 0],
    ["L859", "PZ", "VIETRI", 0],
    ["L860", "SA", "VIETRI", 0],
    ["L859", "PZ", "VIETRI DI POTENZA", 1],
    ["L860", "SA", "VIETRI SUL MARE", 1],
    ["L861", "MI", "VIGADORE", 0],
    ["L862", "PV", "VIGALFO", 0],
    ["L863", "TN", "VIGALZANO", 0],
    ["L864", "VB", "VIGANELLA", 0],
    ["L865", "BG", "VIGANO", 0],
    ["L867", "MI", "VIGANO", 0],
    ["L867", "MI", "VIGANO CERTOSINO", 0],
    ["L865", "BG", "VIGANO SAN MARTINO", 1],
    ["L866", "LC", "VIGANO'", 1],
    ["L868", "FE", "VIGARANO MAINARDA", 1],
    ["L869", "VR", "VIGASIO", 1],
    ["L870", "PR", "VIGATTO", 0],
    ["L871", "MI", "VIGENTINO", 0],
    ["L872", "PV", "VIGEVANO", 1],
    ["L873", "PZ", "VIGGIANELLO", 1],
    ["L874", "PZ", "VIGGIANO", 1],
    ["L875", "NO", "VIGGIONA", 0],
    ["L876", "VA", "VIGGIU'", 1],
    ["L876", "VA", "VIGGIU' ED UNITI", 0],
    ["L877", "CR", "VIGHIZZOLO", 0],
    ["L878", "PD", "VIGHIZZOLO", 0],
    ["L878", "PD", "VIGHIZZOLO D'ESTE", 1],
    ["L879", "AL", "VIGLIANO", 0],
    ["L880", "NO", "VIGLIANO", 0],
    ["L880", "BI", "VIGLIANO BIELLESE", 1],
    ["L879", "AT", "VIGLIANO D'ASTI", 1],
    ["L881", "AL", "VIGNALE MONFERRATO", 1],
    ["L882", "VT", "VIGNANELLO", 1],
    ["L883", "MI", "VIGNATE", 1],
    ["G663", "PZ", "VIGNOLA", 0],
    ["L884", "TN", "VIGNOLA", 0],
    ["L885", "MO", "VIGNOLA", 1],
    ["L886", "TN", "VIGNOLA FALESINA", 0],
    ["L886", "TN", "VIGNOLA-FALESINA", 1],
    ["L887", "AL", "VIGNOLE BORBERA", 1],
    ["L888", "CN", "VIGNOLO", 1],
    ["L889", "VB", "VIGNONE", 1],
    ["L890", "BL", "VIGO", 0],
    ["M249", "TN", "VIGO CAVEDINE", 0],
    ["L891", "TN", "VIGO D'ANAUNIA", 0],
    ["L890", "BL", "VIGO DI CADORE", 1],
    ["L893", "TN", "VIGO DI FASSA", 0],
    ["M250", "TN", "VIGO LOMASO", 0],
    ["L903", "TN", "VIGO RENDENA", 0],
    ["L892", "PD", "VIGODARZERE", 1],
    ["L894", "BG", "VIGOLO", 1],
    ["L895", "TN", "VIGOLO BASELGA", 0],
    ["L896", "TN", "VIGOLO VATTARO", 0],
    ["L897", "PC", "VIGOLZONE", 1],
    ["L898", "TO", "VIGONE", 1],
    ["L899", "VE", "VIGONOVO", 1],
    ["L900", "PD", "VIGONZA", 1],
    ["L901", "MI", "VIGONZINO", 0],
    ["L902", "PV", "VIGONZONE", 0],
    ["L904", "AL", "VIGUZZOLO", 1],
    ["L960", "CO", "VILL'ALBESE", 0],
    ["L905", "CE", "VILLA", 0],
    ["L906", "NO", "VILLA", 0],
    ["L907", "SO", "VILLA", 0],
    ["L908", "SO", "VILLA", 0],
    ["L909", "UD", "VILLA", 0],
    ["L910", "TN", "VILLA AGNEDO", 0],
    ["L911", "TN", "VILLA BANALE", 0],
    ["L912", "VR", "VILLA BARTOLOMEA", 1],
    ["L913", "LU", "VILLA BASILICA", 1],
    ["L917", "PV", "VILLA BISCOSSI", 1],
    ["B318", "TS", "VILLA CACCIA", 0],
    ["L919", "BS", "VILLA CARCINA", 1],
    ["L920", "BR", "VILLA CASTELLI", 1],
    ["L921", "AO", "VILLA CASTELNUOVO", 0],
    ["L922", "PE", "VILLA CELIERA", 1],
    ["L925", "BS", "VILLA COGOZZO", 0],
    ["L926", "LU", "VILLA COLLEMANDINA", 1],
    ["L927", "AT", "VILLA CORSIONE", 0],
    ["L928", "MI", "VILLA CORTESE", 1],
    ["L929", "BG", "VILLA D'ADDA", 1],
    ["L918", "RO", "VILLA D'ADIGE", 0],
    ["L930", "BS", "VILLA D'ALLEGNO", 0],
    ["A215", "BG", "VILLA D'ALME'", 1],
    ["L938", "BG", "VILLA D'OGNA", 1],
    ["L941", "BZ", "VILLA D'UTA", 0],
    ["L932", "PL", "VILLA DECANI", 0],
    ["L933", "BI", "VILLA DEL BOSCO", 1],
    ["L934", "PD", "VILLA DEL CONTE", 1],
    ["L935", "FM", "VILLA DEL NEVOSO", 0],
    ["D801", "CE", "VILLA DI BRIANO", 1],
    ["L907", "SO", "VILLA DI CHIAVENNA", 1],
    ["L936", "BG", "VILLA DI SERIO", 1],
    ["L908", "SO", "VILLA DI TIRANO", 1],
    ["L937", "PD", "VILLA DI VILLA", 0],
    ["L940", "MI", "VILLA DOSIA", 0],
    ["L937", "PD", "VILLA ESTENSE", 1],
    ["L943", "IM", "VILLA FARALDI", 1],
    ["L955", "IM", "VILLA GUARDIA", 0],
    ["L956", "CO", "VILLA GUARDIA", 1],
    ["E181", "BZ", "VILLA IN SELVA", 0],
    ["L957", "TN", "VILLA LAGARINA", 1],
    ["A081", "FR", "VILLA LATINA", 1],
    ["L844", "CE", "VILLA LITERNO", 1],
    ["L969", "RE", "VILLA MINOZZO", 1],
    ["L941", "BZ", "VILLA OTTONE", 0],
    ["L996", "MI", "VILLA PIZZONE", 0],
    ["F804", "MN", "VILLA POMA", 0],
    ["L997", "MI", "VILLA POMPEIANA", 0],
    ["M001", "MI", "VILLA RAVERIO", 0],
    ["M006", "TN", "VILLA RENDENA", 0],
    ["M010", "CO", "VILLA ROMANO", 0],
    ["M012", "MI", "VILLA ROSSA", 0],
    ["M017", "MI", "VILLA SAN FIORANO", 0],
    ["M018", "RC", "VILLA SAN GIOVANNI", 1],
    ["H913", "VT", "VILLA SAN GIOVANNI IN TUSCIA", 1],
    ["H934", "RC", "VILLA SAN GIUSEPPE", 0],
    ["I118", "CA", "VILLA SAN PIETRO", 1],
    ["M019", "AT", "VILLA SAN SECONDO", 1],
    ["M023", "AQ", "VILLA SANT'ANGELO", 1],
    ["C592", "AO", "VILLA SANT'ANSELMO", 0],
    ["I298", "OR", "VILLA SANT'ANTONIO", 1],
    ["M020", "BZ", "VILLA SANTA CATERINA", 0],
    ["L905", "FR", "VILLA SANTA LUCIA", 1],
    ["M021", "AQ", "VILLA SANTA LUCIA DEGLI ABRUZZI", 1],
    ["M022", "CH", "VILLA SANTA MARIA", 1],
    ["L909", "UD", "VILLA SANTINA", 1],
    ["I364", "FR", "VILLA SANTO STEFANO", 1],
    ["M024", "TS", "VILLA SLAVINA", 0],
    ["M029", "IM", "VILLA TALLA", 0],
    ["A609", "OR", "VILLA VERDE", 1],
    ["L760", "CO", "VILLA VERGANO", 0],
    ["M033", "IM", "VILLA VIANI", 0],
    ["M034", "UD", "VILLA VICENTINA", 0],
    ["M035", "CE", "VILLA VOLTURNO", 0],
    ["L914", "TS", "VILLABASSA", 0],
    ["L915", "BZ", "VILLABASSA", 1],
    ["L916", "PA", "VILLABATE", 1],
    ["E503", "AL", "VILLABELLA", 0],
    ["L918", "RO", "VILLABONA", 0],
    ["L923", "BS", "VILLACHIARA", 1],
    ["L924", "CA", "VILLACIDRO", 0],
    ["L924", "SU", "VILLACIDRO", 1],
    ["L931", "AL", "VILLADEATI", 1],
    ["L939", "RO", "VILLADOSE", 1],
    ["L906", "VB", "VILLADOSSOLA", 1],
    ["L942", "CN", "VILLAFALLETTO", 1],
    ["L944", "AG", "VILLAFRANCA", 0],
    ["L945", "AL", "VILLAFRANCA", 0],
    ["L946", "MS", "VILLAFRANCA", 0],
    ["L947", "PD", "VILLAFRANCA", 0],
    ["L948", "TO", "VILLAFRANCA", 0],
    ["L949", "VR", "VILLAFRANCA", 0],
    ["L945", "AT", "VILLAFRANCA D'ASTI", 1],
    ["L949", "VR", "VILLAFRANCA DI VERONA", 1],
    ["L946", "MS", "VILLAFRANCA IN LUNIGIANA", 1],
    ["L947", "PD", "VILLAFRANCA PADOVANA", 1],
    ["L948", "TO", "VILLAFRANCA PIEMONTE", 1],
    ["L948", "TO", "VILLAFRANCA SABAUDA", 0],
    ["L944", "AG", "VILLAFRANCA SICULA", 1],
    ["L950", "ME", "VILLAFRANCA TIRRENA", 1],
    ["L951", "PA", "VILLAFRATI", 1],
    ["L952", "VI", "VILLAGA", 1],
    ["L953", "NU", "VILLAGRANDE STRISAILI", 1],
    ["L954", "CA", "VILLAGRECA", 0],
    ["L958", "AQ", "VILLALAGO", 1],
    ["L959", "CL", "VILLALBA", 1],
    ["L961", "CH", "VILLALFONSINA", 1],
    ["L962", "PV", "VILLALUNGA", 0],
    ["L963", "AL", "VILLALVERNIA", 1],
    ["L964", "CH", "VILLAMAGNA", 1],
    ["L965", "AV", "VILLAMAINA", 1],
    ["L966", "CA", "VILLAMAR", 0],
    ["L966", "SU", "VILLAMAR", 1],
    ["L967", "RO", "VILLAMARZANA", 1],
    ["L968", "CA", "VILLAMASSARGIA", 0],
    ["L968", "SU", "VILLAMASSARGIA", 1],
    ["L970", "AL", "VILLAMIROGLIO", 1],
    ["M251", "TN", "VILLAMONTAGNA", 0],
    ["L971", "BZ", "VILLANDRO", 1],
    ["L972", "AL", "VILLANOVA", 0],
    ["L973", "AV", "VILLANOVA", 0],
    ["L974", "CN", "VILLANOVA", 0],
    ["L975", "GE", "VILLANOVA", 0],
    ["L976", "MI", "VILLANOVA", 0],
    ["L978", "NO", "VILLANOVA", 0],
    ["L979", "PD", "VILLANOVA", 0],
    ["L980", "PC", "VILLANOVA", 0],
    ["L981", "AO", "VILLANOVA BALTEA", 0],
    ["L978", "BI", "VILLANOVA BIELLESE", 1],
    ["L982", "TO", "VILLANOVA CANAVESE", 1],
    ["L975", "SV", "VILLANOVA D'ALBENGA", 1],
    ["L983", "PV", "VILLANOVA D'ARDENGHI", 1],
    ["L984", "AT", "VILLANOVA D'ASTI", 1],
    ["L973", "AV", "VILLANOVA DEL BATTISTA", 1],
    ["L985", "RO", "VILLANOVA DEL GHEBBO", 1],
    ["L977", "LO", "VILLANOVA DEL SILLARO", 1],
    ["L979", "PD", "VILLANOVA DI CAMPOSAMPIERO", 1],
    ["L972", "AL", "VILLANOVA DI CASALE MONFERRATO", 0],
    ["L988", "RO", "VILLANOVA MARCHESANA", 1],
    ["L982", "TO", "VILLANOVA MATHI", 0],
    ["L974", "CN", "VILLANOVA MONDOVI'", 1],
    ["L972", "AL", "VILLANOVA MONFERRATO", 1],
    ["L989", "SS", "VILLANOVA MONTELEONE", 1],
    ["L990", "CN", "VILLANOVA SOLARO", 1],
    ["L980", "PC", "VILLANOVA SULL'ARDA", 1],
    ["L991", "OR", "VILLANOVA TRUSCHEDU", 1],
    ["L992", "SU", "VILLANOVA TULO", 1],
    ["L976", "MI", "VILLANOVA VIMERCATE", 0],
    ["L986", "CA", "VILLANOVAFORRU", 0],
    ["L986", "SU", "VILLANOVAFORRU", 1],
    ["L987", "CA", "VILLANOVAFRANCA", 0],
    ["L987", "SU", "VILLANOVAFRANCA", 1],
    ["L992", "NU", "VILLANOVATULO", 0],
    ["L993", "CN", "VILLANOVAVETTA", 0],
    ["L994", "PV", "VILLANTERIO", 1],
    ["L977", "MI", "VILLANUOVA", 0],
    ["L984", "AL", "VILLANUOVA", 0],
    ["L995", "BS", "VILLANUOVA", 0],
    ["L984", "AT", "VILLANUOVA D'ASTI", 0],
    ["L995", "BS", "VILLANUOVA SUL CLISI", 1],
    ["M278", "CA", "VILLAPERUCCIO", 0],
    ["M278", "SU", "VILLAPERUCCIO", 1],
    ["B903", "CS", "VILLAPIANA", 1],
    ["A185", "LE", "VILLAPICCIOTTI", 0],
    ["L998", "CA", "VILLAPUTZU", 0],
    ["L998", "SU", "VILLAPUTZU", 1],
    ["L999", "TO", "VILLAR ALMESE", 0],
    ["L999", "TO", "VILLAR DORA", 1],
    ["M007", "TO", "VILLAR FOCCHIARDO", 1],
    ["M013", "TO", "VILLAR PELLICE", 1],
    ["M014", "TO", "VILLAR PEROSA", 1],
    ["M015", "CN", "VILLAR SAN COSTANZO", 1],
    ["M002", "TO", "VILLARBASSE", 1],
    ["M003", "VC", "VILLARBOIT", 1],
    ["M004", "TO", "VILLAREGGIA", 1],
    ["M005", "PV", "VILLAREGGIO", 0],
    ["G309", "NA", "VILLARICCA", 1],
    ["G760", "CH", "VILLARIELLI", 0],
    ["E022", "CA", "VILLARIOS", 0],
    ["E022", "CA", "VILLARIOS MASAINAS", 0],
    ["M008", "CR", "VILLAROCCA", 0],
    ["M009", "AL", "VILLAROMAGNANO", 1],
    ["M011", "EN", "VILLAROSA", 1],
    ["M016", "CA", "VILLASALTO", 0],
    ["M016", "SU", "VILLASALTO", 1],
    ["M017", "MB", "VILLASANTA", 1],
    ["M017", "MI", "VILLASANTA", 0],
    ["B738", "CA", "VILLASIMIUS", 0],
    ["B738", "SU", "VILLASIMIUS", 1],
    ["M025", "CA", "VILLASOR", 0],
    ["M025", "SU", "VILLASOR", 1],
    ["M026", "CA", "VILLASPECIOSA", 0],
    ["M026", "SU", "VILLASPECIOSA", 1],
    ["M027", "TO", "VILLASTELLONE", 1],
    ["M028", "VC", "VILLATA", 1],
    ["M030", "OR", "VILLAURBANA", 1],
    ["M031", "AQ", "VILLAVALLELONGA", 1],
    ["M032", "VI", "VILLAVERLA", 1],
    ["F260", "MI", "VILLAVESCO", 0],
    ["M036", "TN", "VILLAZZANO", 0],
    ["M363", "TN", "VILLE D'ANAUNIA", 1],
    ["M037", "TN", "VILLE DEL MONTE", 0],
    ["M431", "TN", "VILLE DI FIEMME", 1],
    ["M038", "GO", "VILLE MONTEVECCHIO", 0],
    ["M039", "IM", "VILLE SAN PIETRO", 0],
    ["M040", "IM", "VILLE SAN SEBASTIANO", 0],
    ["L981", "AO", "VILLENEUVE", 1],
    ["M043", "GO", "VILLESSE", 1],
    ["M041", "AQ", "VILLETTA BARREA", 1],
    ["M042", "VB", "VILLETTE", 1],
    ["M044", "MN", "VILLIMPENTA", 1],
    ["M045", "BG", "VILLONGO", 1],
    ["M046", "BG", "VILLONGO SAN FILASTRO", 0],
    ["M047", "BG", "VILLONGO SANT'ALESSANDRO", 0],
    ["M048", "TV", "VILLORBA", 1],
    ["M049", "BG", "VILMINORE", 0],
    ["M050", "BG", "VILMINORE DI SCALVE", 1],
    ["M051", "PV", "VIMANONE", 0],
    ["M052", "MB", "VIMERCATE", 1],
    ["M052", "MI", "VIMERCATE", 0],
    ["M053", "MI", "VIMODRONE", 1],
    ["M054", "CO", "VIMOGNO", 0],
    ["M055", "CN", "VINADIO", 1],
    ["M056", "MI", "VINAGO", 0],
    ["M057", "CB", "VINCHIATURO", 1],
    ["M058", "AT", "VINCHIO", 1],
    ["M059", "FI", "VINCI", 1],
    ["M060", "TO", "VINOVO", 1],
    ["M061", "VC", "VINTEBBIO", 0],
    ["M062", "NO", "VINZAGLIO", 1],
    ["M063", "CN", "VIOLA", 1],
    ["M064", "TN", "VION", 0],
    ["M065", "BS", "VIONE", 1],
    ["M066", "GO", "VIPACCO", 0],
    ["M067", "BZ", "VIPITENO", 1],
    ["H123", "MN", "VIRGILIO", 0],
    ["M068", "BS", "VIRLE", 0],
    ["M069", "TO", "VIRLE", 0],
    ["M069", "TO", "VIRLE PIEMONTE", 1],
    ["M068", "BS", "VIRLE TRE PONTI", 0],
    ["M070", "BS", "VISANO", 1],
    ["M071", "TO", "VISCHE", 1],
    ["M072", "NA", "VISCIANO", 1],
    ["M073", "UD", "VISCO", 1],
    ["M074", "PL", "VISIGNANO", 0],
    ["M075", "PL", "VISINADA", 0],
    ["M076", "CO", "VISINO", 0],
    ["M077", "AL", "VISONE", 1],
    ["M078", "MC", "VISSO", 1],
    ["M079", "PV", "VISTARINO", 1],
    ["M080", "TO", "VISTRORIO", 1],
    ["M081", "TP", "VITA", 1],
    ["M082", "VT", "VITERBO", 1],
    ["Z704", "EE", "VITI", 1],
    ["M083", "FR", "VITICUSO", 1],
    ["M084", "CE", "VITICUSO E ACQUAFONDATA", 0],
    ["M085", "PN", "VITO D'ASIO", 1],
    ["M086", "VT", "VITORCHIANO", 1],
    ["M087", "MI", "VITTADONE", 0],
    ["M088", "RG", "VITTORIA", 1],
    ["M089", "TV", "VITTORIO", 0],
    ["M089", "TV", "VITTORIO VENETO", 1],
    ["M090", "AQ", "VITTORITO", 1],
    ["M091", "MI", "VITTUONE", 1],
    ["M092", "CE", "VITULACCIO", 0],
    ["M093", "BN", "VITULANO", 1],
    ["M092", "CE", "VITULAZIO", 1],
    ["M094", "TO", "VIU'", 1],
    ["M095", "RM", "VIVARO", 0],
    ["M096", "PN", "VIVARO", 1],
    ["M095", "RM", "VIVARO ROMANO", 1],
    ["M097", "PV", "VIVENTE", 0],
    ["M098", "BI", "VIVERONE", 1],
    ["M099", "BZ", "VIZZE", 0],
    ["M100", "CT", "VIZZINI", 1],
    ["M101", "MI", "VIZZOLA", 0],
    ["M101", "VA", "VIZZOLA TICINO", 1],
    ["M102", "MI", "VIZZOLO", 0],
    ["M102", "MI", "VIZZOLO PREDABISSI", 1],
    ["M103", "PD", "VO", 0],
    ["M103", "PD", "VO'", 1],
    ["M104", "BS", "VOBARNO", 1],
    ["M105", "GE", "VOBBIA", 1],
    ["M106", "VC", "VOCCA", 1],
    ["M107", "NO", "VOCOGNO E PRESTINONE", 0],
    ["M108", "BL", "VODO CADORE", 1],
    ["M108", "BL", "VODO DI CADORE", 0],
    ["M109", "PV", "VOGHERA", 1],
    ["M110", "FE", "VOGHIERA", 1],
    ["M111", "VB", "VOGOGNA", 1],
    ["M112", "GO", "VOISSIZZA DI COMENO", 0],
    ["M113", "TN", "VOLANO", 1],
    ["H484", "BS", "VOLCIANO", 0],
    ["M114", "VA", "VOLDOMINO", 0],
    ["M115", "NA", "VOLLA", 1],
    ["M116", "CR", "VOLONGO", 1],
    ["M117", "FM", "VOLOSCA", 0],
    ["M118", "TV", "VOLPAGO", 0],
    ["M118", "TV", "VOLPAGO DEL MONTELLO", 1],
    ["M119", "PV", "VOLPARA", 1],
    ["M120", "AL", "VOLPEDO", 1],
    ["M121", "AL", "VOLPEGLINO", 1],
    ["M122", "TO", "VOLPIANO", 1],
    ["D117", "BG", "VOLPINO", 0],
    ["M125", "MN", "VOLTA MANTOVANA", 1],
    ["Z354", "EE", "VOLTA=ALTOVOLTA", 1],
    ["M123", "AL", "VOLTAGGIO", 1],
    ["M124", "BL", "VOLTAGO", 0],
    ["M124", "BL", "VOLTAGO AGORDINO", 1],
    ["M126", "PI", "VOLTERRA", 1],
    ["M127", "CR", "VOLTIDO", 1],
    ["M128", "VA", "VOLTORRE", 0],
    ["M129", "GE", "VOLTRI", 0],
    ["M130", "AV", "VOLTURARA", 0],
    ["M131", "FG", "VOLTURARA", 0],
    ["M131", "FG", "VOLTURARA APPULA", 1],
    ["M130", "AV", "VOLTURARA IRPINA", 1],
    ["M132", "FG", "VOLTURINO", 1],
    ["M133", "TO", "VOLVERA", 1],
    ["M134", "GO", "VOLZANA", 0],
    ["M135", "GO", "VOSCHIA", 0],
    ["M136", "CN", "VOTTIGNASCO", 1],
    ["Z729", "EE", "WALLIS E FUTUNA (ISOLE)", 1],
    ["M137", "TP", "XITTA", 0],
    ["Z246", "EE", "YEMEN", 1],
    ["Z250", "EE", "YEMEN REPUBBLICA DEMOCRATICA POPOLARE", 0],
    ["M138", "VV", "ZACCANOPOLI", 1],
    ["M139", "CT", "ZAFFERANA ETNEA", 1],
    ["M140", "CZ", "ZAGARISE", 1],
    ["M141", "RM", "ZAGAROLO", 1],
    ["Z312", "EE", "ZAIRE", 1],
    ["M142", "TN", "ZAMBANA", 0],
    ["Z355", "EE", "ZAMBIA", 1],
    ["M143", "VV", "ZAMBRONE", 1],
    ["B938", "MI", "ZANAVASCO", 0],
    ["M144", "BG", "ZANDOBBIO", 1],
    ["M145", "VI", "ZANE'", 1],
    ["M146", "CR", "ZANENGO", 0],
    ["M147", "BG", "ZANICA", 1],
    ["Z356", "EE", "ZANZIBAR", 1],
    ["M148", "CR", "ZAPPELLO CON BOLZONE", 0],
    ["M267", "FG", "ZAPPONETA", 1],
    ["M149", "ZA", "ZARA", 0],
    ["M150", "PV", "ZAVATTARELLO", 1],
    ["M151", "PV", "ZAVATTARELLO VALVERDE", 0],
    ["M152", "PV", "ZECCONE", 1],
    ["M153", "OR", "ZEDDIANI", 1],
    ["C590", "BZ", "ZEDES", 0],
    ["M154", "VE", "ZELARINO", 0],
    ["M155", "PV", "ZELATA", 0],
    ["M156", "CO", "ZELBIO", 1],
    ["M157", "CO", "ZELBIO VELESO", 0],
    ["M158", "LO", "ZELO BUON PERSICO", 1],
    ["M159", "MI", "ZELO FORAMAGNO", 0],
    ["M160", "MI", "ZELO SURRIGONE", 0],
    ["M161", "PV", "ZEME", 1],
    ["M162", "PV", "ZENEVREDO", 1],
    ["M163", "TV", "ZENSON DI PIAVE", 1],
    ["M164", "CA", "ZEPPARA", 0],
    ["M165", "PC", "ZERBA", 1],
    ["M166", "PV", "ZERBO", 1],
    ["M167", "PV", "ZERBOLO'", 1],
    ["M168", "OR", "ZERFALIU", 1],
    ["M169", "MS", "ZERI", 1],
    ["M170", "VI", "ZERMEGHEDO", 1],
    ["M171", "TV", "ZERO BRANCO", 1],
    ["M172", "VR", "ZEVIO", 1],
    ["L848", "PC", "ZIANO", 0],
    ["M173", "TN", "ZIANO", 0],
    ["M173", "TN", "ZIANO DI FIEMME", 1],
    ["L848", "PC", "ZIANO PIACENTINO", 1],
    ["M174", "PR", "ZIBELLO", 0],
    ["M175", "PV", "ZIBIDO AL LAMBRO", 0],
    ["M176", "MI", "ZIBIDO SAN GIACOMO", 1],
    ["M177", "SP", "ZIGNAGO", 1],
    ["Z337", "EE", "ZIMBABWE", 1],
    ["M178", "VR", "ZIMELLA", 1],
    ["M179", "BI", "ZIMONE", 1],
    ["M180", "PV", "ZINASCO", 1],
    ["M181", "MI", "ZIVIDO", 0],
    ["M182", "GE", "ZOAGLI", 1],
    ["M183", "MO", "ZOCCA", 1],
    ["M184", "BG", "ZOGNO", 1],
    ["M185", "BO", "ZOLA PREDOSA", 1],
    ["I345", "BL", "ZOLDO ALTO", 0],
    ["M186", "GO", "ZOLLA", 0],
    ["M187", "LE", "ZOLLINO", 1],
    ["M188", "BS", "ZONE", 1],
    ["M189", "BL", "ZOPPE'", 0],
    ["M189", "BL", "ZOPPE' DI CADORE", 1],
    ["M190", "PN", "ZOPPOLA", 1],
    ["M191", "MI", "ZORLESCO", 0],
    ["M192", "NO", "ZORNASCO", 0],
    ["M193", "BG", "ZORZINO", 0],
    ["M194", "VI", "ZOVENCEDO", 1],
    ["M195", "NO", "ZOVERALLO", 0],
    ["M196", "BI", "ZUBIENA", 1],
    ["M197", "SV", "ZUCCARELLO", 1],
    ["M198", "TN", "ZUCLO", 0],
    ["M199", "VI", "ZUGLIANO", 1],
    ["M200", "UD", "ZUGLIO", 1],
    ["M201", "BI", "ZUMAGLIA", 1],
    ["M202", "CS", "ZUMPANO", 1],
    ["M203", "AV", "ZUNGOLI", 1],
    ["M204", "VV", "ZUNGRI", 1],
    ["M205", "MI", "ZUNICO", 0],
    ["M206", "CA", "ZURI", 0],
]

export const cities = (prov) => {
    const result = [];
    let pk = "";    
    for (let i in PROVINCES) {
        if (PROVINCES[i] === prov) {
            pk = i;
            break;
        }
    }
    for (let i in COUNTRIES) {
        if (COUNTRIES[i][1] === pk) {
            let name = COUNTRIES[i][2];
            if (!result.includes(name)) {
                result.push(name);
            }
        }
    }
    return result;
};

export const getCountries = () => {
    const results = [];
    for (let idx in COUNTRIES) {
        const name = COUNTRIES[idx][2];
        if (!results.includes(name)) {
            results.push(name);
        }
    }
    return results;
};