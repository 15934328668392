//import { List, Datagrid, TextField, EmailField } from "react-admin";
import { List, DateInput, SimpleList, DateField, Datagrid, TextField, FunctionField, EmailField, UrlField, EditButton, choices } from "react-admin";
import { Edit, Create, TopToolbar, SelectColumnsButton,CreateButton,ExportButton,DatagridConfigurable, SimpleForm, ReferenceInput, TextInput, ReferenceField, SelectInput} from "react-admin";
import { BulkDeleteButton, BulkExportButton } from 'react-admin';
import { useNotify, useRefresh, useRedirect, useDataProvider} from 'react-admin';
import { Fragment,useState } from 'react';
import { MyEmailField } from "./MyUrlField";
import jsSHA from 'jssha';
import { PasswordInput } from 'react-admin';
import Button from '@mui/material/Button';
import { useRecordContext,downloadCSV } from 'react-admin';
import { required } from 'react-admin';
import { properties } from "../utils/properties";
import SendIcon from '@mui/icons-material/Send';
import {  FilterButton } from "ra-ui-materialui";
import { Box, Tooltip,Typography } from "@mui/material";
import { useFormContext } from 'react-hook-form';
import { useParams, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import {Grid} from "@mui/material";
import { SaveButton,Toolbar } from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { useEditContext,useGetOne,useGetMany,useGetManyReference } from 'react-admin';
import moment from "moment";
import "moment/locale/it";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

const getTime=() =>{
    const now = new Date();
    const formattedDate = now.toLocaleString('it-IT', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    }).replace(/\//g, '-').replace(',', ''); 
    const parts = formattedDate.split(' ');
    const finalDate = `${parts[0].split('-').reverse().join('-')} ${parts[1]}`;
    return finalDate;
}
/*
const exportToXLS = (records, fetchRelatedRecords) => {  
    const worksheet = XLSX.utils.json_to_sheet(records);    
  
    const colWidths = Object.keys(records[0] || {}).map(key => ({
      wch: Math.max(
          key.length, // Larghezza del nome della colonna
          ...records.map(record => (record[key] ? record[key].toString().length : 0)) // Larghezza del contenuto
      )
  }));
  worksheet["!cols"] = colWidths;
  
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Dati");  
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });    
    const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    saveAs(data, "iscrizioni.xlsx");
  };
  */

  
  const exportToXLS = async (records, fetchRelatedRecords) => {
    if (!records || records.length === 0) return;
    
    const bambiniMap = await fetchRelatedRecords(records, "bambino_id", "bambini");
    const entiMap = await fetchRelatedRecords(records, "ente_id", "enti");
    const statoMap = await fetchRelatedRecords(records, "stato_iscrizione_id", "stati_iscrizioni");

    const exportedRecords = records.map(record => ({
        "Identificativo": record.id,  
        Bambino: bambiniMap[record.bambino_id]
            ? `${bambiniMap[record.bambino_id].nome} ${bambiniMap[record.bambino_id].cognome} - ${bambiniMap[record.bambino_id].codice_fiscale}`
            : "N/A",
        Ente: entiMap[record.ente_id]
            ? `${entiMap[record.ente_id].nome}`
            : "N/A",
        "Stato iscrizione":  statoMap[record.stato_iscrizione_id]
            ? `${statoMap[record.stato_iscrizione_id].stato}`
            : "N/A",
        "Data di creazione": record. creation_time? moment(record.creation_time).format("DD/MM/YYYY, HH:MM") : "",
        "Data ultima modifica": record.edit_time? moment(record.edit_time).format("DD/MM/YYYY, HH:MM") : "",
        "Titoli disponibili": record.valid,  
        "Assicurazione pagata": bambiniMap[record.bambino_id] &&
        bambiniMap[record.bambino_id].data_pagamento_assicurazione
        ? moment(bambiniMap[record.bambino_id].data_pagamento_assicurazione).isAfter(moment().subtract(1, "year"))
          ? moment(bambiniMap[record.bambino_id].data_pagamento_assicurazione).format("DD/MM/YYYY")
          : "No"
        : "No",         
        "Disponibilità cambio sede/orario": record.disponibilita_cambio_orario_sede,
        "Adempimenti richiesti": record.adempimenti_richiesti,
        "Fragilità economica": record.fragilita_economica,
        "Liberatoria per immagini": record.liberatoria_immagini,
        "Autorizzazione somministrazione farmaci": record.somministrazione_farmaci,    
        "In attesa del servizio educativo tradizionale": record.in_attesa_servizio_educativo_tradizionale,
        "Delega per il ritiro dei minori": record.delega_minori,
        "Autorizzazione uscite sul territerio": record.autorizzazione_uscite,
        "Fratelli/sorelle": record.fratelli_sorelle,
        "Altro recapito telefonico": record.recapito_telefonico,    
        "Come ha conosciuto il servizio": record.sponsor,        
        
        "Pre-iscrizione: data inizio": record.data_inizio_desiderata? moment(record.data_inizio_desiderata).format("DD/MM/YYYY") : "",
        "Pre-iscrizione: data fine": record.data_fine_desiderata? moment(record.data_fine_desiderata).format("DD/MM/YYYY") : "",
        "Pre-iscrizione: Fascia ISEE": tipoFasceChoices[record.isee_preiscrizione] ? tipoFasceChoices[record.isee_preiscrizione]:"",        
        "Pre-iscrizione: Tipologia":tipoPreiscrizioneChoices[record.tipo_preiscrizione]? tipoPreiscrizioneChoices[record.tipo_preiscrizione]:"",         
        "Pre-iscrizione: Liberatoria privacy": record.privacy_preiscrizione,
        "Pre-iscrizione: Lunedì ora inizio": record.inizio_lunedi_desiderata,
        "Pre-iscrizione: Lunedì ora fine": record.fine_lunedi_desiderata,
        "Pre-iscrizione: Martedì ora inizio": record.inizio_martedi_desiderata,
        "Pre-iscrizione: Martedì ora fine": record.fine_martedi_desiderata,
        "Pre-iscrizione: Mercoledì ora inizio": record.inizio_mercoledi_desiderata,
        "Pre-iscrizione: Mercoledì ora fine": record.fine_mercoledi_desiderata,
        "Pre-iscrizione: Giovedì ora inizio": record.inizio_giovedi_desiderata,
        "Pre-iscrizione: Giovedì ora fine": record.fine_giovedi_desiderata,
        "Pre-iscrizione: Venerdì ora inizio": record.inizio_venerdi_desiderata,
        "Pre-iscrizione: Venerdì ora fine": record.fine_venerdi_desiderata,
        
        "Iscrizione: data inizio": record.data_inizio_scelta? moment(record.data_inizio_scelta).format("DD/MM/YYYY") : "",
        "Iscrizione: data fine": record.data_fine_scelta? moment(record.data_fine_scelta).format("DD/MM/YYYY") : "",
        "Iscrizione: Fascia ISEE": tipoFasceChoices[record.isee] ? tipoFasceChoices[record.isee]:"",  
        "Iscrizione: Data sottoscrizione ISEE": record.isee_data_sottoscrizione? moment(record.isee_data_sottoscrizione).format("DD/MM/YYYY") : "",
        "Iscrizione: Indicatore ISEE": record.indicatore_isee,
        "Iscrizione: Tipologia": tipoPreiscrizioneChoices[record.tipo_iscrizione]? tipoPreiscrizioneChoices[record.tipo_iscrizione]:"",
        "Iscrizione: Liberatoria privacy": record.privacy_iscrizione,
        "Iscrizione: Patto educativo":record.patto_educativo,
      
        "Iscrizione: Lunedì ora inizio": record.inizio_lunedi_effettiva,
        "Iscrizione: Lunedì ora fine": record.fine_lunedi_effettiva,
        "Iscrizione: Martedì ora inizio": record.inizio_martedi_effettiva,
        "Iscrizione: Martedì ora fine": record.fine_martedi_effettiva,
        "Iscrizione: Mercoledì ora inizio": record.inizio_mercoledi_effettiva,
        "Iscrizione: Mercoledì ora fine": record.fine_mercoledi_effettiva,
        "Iscrizione: Giovedì ora inizio": record.inizio_giovedi_effettiva,
        "Iscrizione: Giovedì ora fine": record.fine_giovedi_effettiva,
        "Iiscrizione: Venerdì ora inizio": record.inizio_venerdi_effettiva,
        "Iscrizione: Venerdì ora fine": record.fine_venerdi_effettiva,        

        "Note": record.note,                
        "Note pagamenti": record.note_pagamenti,                                           
  }));
  const worksheet = XLSX.utils.json_to_sheet(exportedRecords);
  const colWidths = Object.keys(exportedRecords[0] || {}).map(key => ({
    wch: Math.max(
      key.length, 
      ...exportedRecords.map(record => (record[key] ? record[key].toString().length : 0))
    )
  }));
  worksheet["!cols"] = colWidths;
  
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Dati");  
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  saveAs(data, "iscrizioni.xlsx");
};


const IscrizioneBulkActionButtons = () => (
  <Fragment>
      <BulkExportButton maxResults= {15000}  exporter={exportToXLS} />      
      {/*<BulkDeleteButton mutationMode="pessimistic"/>*/}
  </Fragment>
);

const ValidCircleField =  (props)  => (
    <FunctionField            
      source="valid"
      //label={<span style={{ fontWeight: "bold" }}>Coperta da titoli</span>}
      sortable={false}
      render={(record) => (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%"}} >
          <Tooltip title={record.valid ===true ? "Titoli disponibili" : 
          record.valid ===false? "Nessun titolo disponibile" : "Cancellata"}>
            <span
              style={{
                display: "inline-block",
                width: "12px",
                height: "12px",
                borderRadius: "50%",            
                //backgroundColor: record.valid ? "green" : "red",
                backgroundColor: record.valid === true ? "green" 
                  : record.valid === false ? "red" 
                  : "lightgray"
              }}/>
          </Tooltip>
        </div>
      )}
    />
  );


const IscrizioneFilters = [
  <TextInput label="Cerca" source="q" alwaysOn />,
  <ReferenceInput source="ente_id" reference="enti" label="Ente " perPage={1000} sort={{ field: 'nome', order: 'ASC' }}>     
        <SelectInput sx={{textAlign: "left"}} option="nome" optionText="nome"/>
  </ReferenceInput>,
  <ReferenceInput source="bambino_id" reference="bambini" label="Bambino" perPage={1000} sort={{ field: 'nome', order: 'ASC' }}>
        <SelectInput optionText={(record) => `${record.nome} ${record.cognome} (${record.codice_fiscale})`} />
  </ReferenceInput> ,
  <ReferenceInput source="stato_iscrizione_id" reference="stati_iscrizioni" label="Stato Iscrizione" perPage={1000} sort={{ field: 'stato', order: 'ASC' }}>
        <SelectInput option="stato" optionText="stato" />
  </ReferenceInput> ,
  <SelectInput 
    label="Coperto da Titoli" 
    source="valid" 
    choices={[    
        { id: true, name: ' Titoli disponibili' },
        { id: false, name: 'Nessun titolo disponibile' },
    ]} />
];



const tariffe =[
[0,0,0,0],
[28,35,40,27],
[39,48,55,37],
[50,63,72,49],
[62,77,88,60],
[73,91,104,70],
[86,107,122,83],
[97,121,139,94],
[108,135,155,104],
[120,150,171,116],
[132,165,188,127],
[143,179,204,138],
[156,195,222,150],
[167,209,239,161],
[179,224,256,173],
[192,240,274,185],
[203,254,291,196],
[216,270,309,208],
[228,285,326,220],
[240,300,343,232],
[249,312,356,240],
[253,317,362,244],
[263,329,376,254],
[268,336,383,259],
[280,350,400,270],
[0,0,0,0],
]

const contaGiorniLavorativi = (year, month, holidays = []) => {
    let count = 0;
    let date = moment(`${year}-${month}-01`, "YYYY-MM-DD");
    //console.log("DATE",date);
    let lastDay = date.clone().endOf("month");

    while (date.isSameOrBefore(lastDay, "day")) {
        const day = date.isoWeekday(); 
        const isHoliday = holidays.includes(date.format("YYYY-MM-DD"));        
        if (day !== 6 && day !== 7 && !isHoliday) {
            count++;
        }
        date.add(1, "day"); 
    }
    return count;
};

const contaGiorniLavorativiRimanenti = function(year, month, startDay, holidays = []) {
    //let currentDate = moment(`${year}-${month}-${startDay}`, "YYYY-MM-DD");
    let currentDate=moment(startDay);
    let lastDay = moment(`${year}-${month}-01`, "YYYY-MM-DD").endOf("month");
    let count = 0;

    if (currentDate.isAfter(lastDay, "day")) {
        return 0; 
    }

    while (currentDate.isSameOrBefore(lastDay, "day")) {
        const day = currentDate.isoWeekday();
        const isHoliday = holidays.includes(currentDate.format("YYYY-MM-DD"));

        if (day !== 6 && day !== 7 && !isHoliday) {
            count++;
        }
        currentDate.add(1, "day");
    }
    return count;
}

const calcolaCostoPrimoMese=function (costoMese, giorniMese, giorniServizio){
    return (costoMese/22*giorniServizio).toFixed(2).replace('.', ',');
    //return (costoMese/giorniMese*giorniServizio).toFixed(2);
}


//EXPO
/*
const exporter1 = (records, fetchRelatedRecords) => {
    let data;
    let data1;
    let dataForExport;



fetchRelatedRecords(records, 'ente_id', 'enti').then(enti => {  
  data1 = records.map(record => ({
        ...record,
        ente: enti[record.ente_id].nome,                
  }));   
fetchRelatedRecords(data1,'bambino_id','bambini').then(bambini =>{
      
      data = data1.map(record => ({
      ...record,
      
      bambino: bambini[record.bambino_id].nome,                
})); 

  
dataForExport = data.map(record => {        
    const { id_bambino,id, ...dataForExport } =record;
    return dataForExport;
});
   
// <TextField source="nome" /> <TextField source="cognome" /> - <TextField source="codice_fiscale" />



  jsonExport(dataForExport, {
    headers: ['id','ente', 'bambino', "stato_iscrizione_id","creation_time", "edit_time"],

}, (err, csv) => {
    downloadCSV(csv, 'iscrizioni');
});
})
  
});
};
*/
//EXPO





const IscrizioneActions = (props) => {
  const {
    className,
    basePath,    
    resource,
    currentSort,
    filterValues,
    exporter,
  } = props;
  return (
    <TopToolbar className={className}>
      <SelectColumnsButton />            
      <FilterButton/>
      <CreateButton basePath={basePath} /> 
      <ExportButton         
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
        maxResults= {15000}   
      />    
    </TopToolbar>
  );
};







const ExpandIscrizioni = () => {
    const record = useRecordContext();
    
    return (
      <Grid container>            
            <h3>PRE-ISCRIZIONE</h3>
            <Box display="flex" alignItems="center" width="100%">
                <Typography variant="h7" sx={{ marginRight: 1, fontWeight:'bold' }}> 
                    Tipologia pre-iscrizione: </Typography>
                    <FunctionField 
                    label="Tipologia di pre-iscrizione" 
                    source="tipo_preiscrizione"
                    render={(record) => tipoPreiscrizioneChoices[record.tipo_preiscrizione] || "Sconosciuto"} 
                />                            
            </Box>
            <Box display="flex" alignItems="center" width="100%">
                <Typography variant="h7" sx={{ marginRight: 1, fontWeight:'bold' }}>                 
                    ISEE preiscirizione: </Typography>                    
                    <FunctionField 
                    label="ISEE preiscirizione" 
                    source="isee_preiscrizione"
                    render={(record) => tipoFasceChoices[record.isee_preiscrizione] || "Sconosciuto"} 
                />                            
            </Box>
            <Box display="flex" alignItems="center" width="100%">
                <Typography variant="h7" sx={{ marginRight: 1, fontWeight:'bold' }}> 
                    Liberatoria privacy (preiscirizione): </Typography>                    
                <TextField source="privacy_preiscrizione"/>
            </Box>  
            
            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }} >
                <Box key={"700"}  minWidth="30px"  sx={{paddingRight:'1.5em'}}>
                    <h4> Data Inizio: </h4><TextField source="data_inizio_desiderata" label="Data Inizio desiderata"/>                
                </Box>    
                <Box key={"710"}  minWidth="30px"  sx={{paddingRight:'1.5em'}}>                
                    <h4> Data Fine: </h4><TextField source="data_fine_desiderata" label="Data Fine desiderata"/>
                </Box>    
                <Box key={"720"}  minWidth="30px"  sx={{paddingRight:'1.5em'}}>        
                    <h4> Lunedì: </h4>
                    <TextField source="inizio_lunedi_desiderata" label="Lunedì Inizio"  fullWidth placeholder="hh:mm"/> -
                    <TextField source="fine_lunedi_desiderata" label="Lunedì Fine"  fullWidth placeholder="hh:mm" />                        
                </Box>
                <Box key={"800"} minWidth="30px" sx={{paddingRight:'1.5em'}}>
                    <h4> Martedì: </h4>
                    <TextField source="inizio_martedi_desiderata" label="Martedì Inizio"  fullWidth placeholder="hh:mm"/> -
                    <TextField source="fine_martedi_desiderata" label="Martedì Fine"  fullWidth placeholder="hh:mm"/>
                </Box>
                
                <Box key={"900"} minWidth="30px" sx={{paddingRight:'1.5em'}}>
                    <h4> Mercoledì: </h4>
                    <TextField source="inizio_mercoledi_desiderata" label="Mercoledì Inizio"  fullWidth placeholder="hh:mm"/> -
                    <TextField source="fine_mercoledi_desiderata" label="Mercoledì Fine"  fullWidth placeholder="hh:mm"/>
                </Box>
                        
                <Box key={"1700"} minWidth="30px" sx={{paddingRight:'1.5em'}}>
                    <h4> Giovedì: </h4>
                    <TextField source="inizio_giovedi_desiderata" label="Giovedì Inizio" fullWidth placeholder="hh:mm"/> -
                    <TextField source="fine_giovedi_desiderata" label="Giovedì Fine"  fullWidth placeholder="hh:mm"/>
                </Box>
                
                <Box key={"1800"}  minWidth="30px" sx={{paddingRight:'1.5em'}}>
                    <h4> Venerdì: </h4>
                    <TextField source="inizio_venerdi_desiderata" label="Venerdì Inizio"  fullWidth placeholder="hh:mm"/> - 
                    <TextField source="fine_venerdi_desiderata" label="Venerdì Fine" fullWidth placeholder="hh:mm" />
                </Box>                      
            </Box>
                  
            <h3 style={{paddingTop:"40px"}}>ISCRIZIONE</h3>
            <Box display="flex" alignItems="center" width="100%">
                <Typography variant="h7" sx={{ marginRight: 1, fontWeight:'bold' }}> 
                    Tipologia iscrizione: </Typography>
                    <FunctionField 
                    label="Tipologia di iscrizione" 
                    source="tipo_iscrizione"
                    render={(record) => tipoPreiscrizioneChoices[record.tipo_iscrizione] || "Sconosciuto"} 
                />                            
            </Box>
            <Box display="flex" alignItems="center" width="100%">
                <Typography variant="h7" sx={{ marginRight: 1, fontWeight:'bold' }}> 
                    ISEE Iscrizione: </Typography>                   
                    <FunctionField                     
                    source="isee"
                    render={(record) => tipoFasceChoices[record.isee] || "Sconosciuto"} 
                />                            
            </Box>  
            <Box display="flex" alignItems="center" width="100%">
                <Typography variant="h7" sx={{ marginRight: 1, fontWeight:'bold' }}> 
                    Data sottoscrizione ISEE: </Typography>
                <TextField source="isee_data_sottoscrizione" />
            </Box>  
            <Box display="flex" alignItems="center" width="100%">
                <Typography variant="h7" sx={{ marginRight: 1, fontWeight:'bold' }}> 
                    Indicatore ISEE: </Typography>
                <TextField source="indicatore_isee" />
            </Box>       
            <Box display="flex" alignItems="center" width="100%">
                <Typography variant="h7" sx={{ marginRight: 1, fontWeight:'bold' }}> 
                    Liberatoria privacy (iscrizione): </Typography>                    
                <TextField source="privacy_iscrizione"/>
            </Box>         

            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }} >            
                <Box key={"810"}  minWidth="30px"  sx={{paddingRight:'1.5em'}}>
                    <h4> Data Inizio: </h4><TextField source="data_inizio_scelta" label="Data Inizio desiderata"/>                
                </Box>    
                <Box key={"820"}  minWidth="30px"  sx={{paddingRight:'1.5em'}}>                
                    <h4> Data Fine: </h4><TextField source="data_fine_scelta" label="Data Fine desiderata"/>
                </Box>
                <Box key={"820"}  minWidth="30px"  sx={{paddingRight:'1.5em'}}>        
                    <h4> Lunedì: </h4>
                    <TextField source="inizio_lunedi_effettiva" label="Lunedì Inizio"  fullWidth placeholder="hh:mm"/> -
                    <TextField source="fine_lunedi_effettiva" label="Lunedì Fine"  fullWidth placeholder="hh:mm" />                        
                </Box>
                <Box key={"840"} minWidth="30px" sx={{paddingRight:'1.5em'}}>
                    <h4> Martedì: </h4>
                    <TextField source="inizio_martedi_effettiva" label="Martedì Inizio"  fullWidth placeholder="hh:mm"/> -
                    <TextField source="fine_martedi_effettiva" label="Martedì Fine"  fullWidth placeholder="hh:mm"/>
                </Box>
                
                <Box key={"850"} minWidth="30px" sx={{paddingRight:'1.5em'}}>
                    <h4> Mercoledì: </h4>
                    <TextField source="inizio_mercoledi_effettiva" label="Mercoledì Inizio"  fullWidth placeholder="hh:mm"/> -
                    <TextField source="fine_mercoledi_effettiva" label="Mercoledì Fine"  fullWidth placeholder="hh:mm"/>
                </Box>
                        
                <Box key={"1700"} minWidth="30px" sx={{paddingRight:'1.5em'}}>
                    <h4> Giovedì: </h4>
                    <TextField source="inizio_giovedi_effettiva" label="Giovedì Inizio" fullWidth placeholder="hh:mm"/> -
                    <TextField source="fine_giovedi_effettiva" label="Giovedì Fine"  fullWidth placeholder="hh:mm"/>
                </Box>
                
                <Box key={"1800"}  minWidth="30px" sx={{paddingRight:'1.5em'}}>
                    <h4> Venerdì: </h4>
                    <TextField source="inizio_venerdi_effettiva" label="Venerdì Inizio"  fullWidth placeholder="hh:mm"/> - 
                    <TextField source="fine_venerdi_effettiva" label="Venerdì Fine" fullWidth placeholder="hh:mm" />
                </Box>                       
            </Box>

            <h3 style={{paddingTop:"40px"}}> ALTRE INFORMAZIONI</h3>
            <Box display="flex" alignItems="center" width="100%">
                <Typography variant="h7" sx={{ marginRight: 1, fontWeight:'bold' }}> 
                    Assicurazione pagata: 
                </Typography>    
                <ReferenceField source="bambino_id" reference="bambini" label="Assicurazione pagata">
                    <AssicurazioneField />
                </ReferenceField>
            </Box>  
            <Box display="flex" alignItems="center" width="100%">
                <Typography variant="h7" sx={{ marginRight: 1, fontWeight:'bold' }}> 
                    Disponibilità cambio sede/orario: </Typography>                    
                <TextField source="disponibilita_cambio_orario_sede"/>
            </Box>  
            <Box display="flex" alignItems="center" width="100%">
                <Typography variant="h7" sx={{ marginRight: 1, fontWeight:'bold' }}> 
                    Adempimenti richiesti: </Typography>            
                <TextField source="adempimenti_richiesti" label="Adempimenti richiesti"/>
            </Box>  
            <Box display="flex" alignItems="center" width="100%">
                <Typography variant="h7" sx={{ marginRight: 1, fontWeight:'bold' }}> 
                    Patto educativo: </Typography>
                <TextField source="patto_educativo" label="Patto educativo"/>
            </Box>  
            <Box display="flex" alignItems="center" width="100%">
                <Typography variant="h7" sx={{ marginRight: 1, fontWeight:'bold' }}> 
                    Fragilità economica: </Typography>
                <TextField source="fragilita_economica" label="Fragilità economica"/>
            </Box>  
            <Box display="flex" alignItems="center" width="100%">
                <Typography variant="h7" sx={{ marginRight: 1, fontWeight:'bold' }}> 
                    Liberatoria per Immagini: </Typography>
                <TextField source="liberatoria_immagini" label="Liberatoria per Immagini"/>            
            </Box>  
            <Box display="flex" alignItems="center" width="100%">
                <Typography variant="h7" sx={{ marginRight: 1, fontWeight:'bold' }}> 
                    Autorizzazione somministrazione farmaci: </Typography>
                <TextField source="somministrazione_farmaci" />
            </Box>  
            <Box display="flex" alignItems="center" width="100%">
                <Typography variant="h7" sx={{ marginRight: 1, fontWeight:'bold' }}> 
                In attesa del servizio educativo tradizionale: </Typography>
                <TextField source="in_attesa_servizio_educativo_tradizionale" label="In attedsa del servizio educativo tradizionale"/>
            </Box>  
            <Box display="flex" alignItems="center" width="100%">
                <Typography variant="h7" sx={{ marginRight: 1, fontWeight:'bold' }}> 
                    Delega ritiro minori: </Typography>
                <TextField source="delega_minori"/>
            </Box> 
            <Box display="flex" alignItems="center" width="100%">
                <Typography variant="h7" sx={{ marginRight: 1, fontWeight:'bold' }}> 
                    Autorizzazione uscite sul territorio: </Typography>
                <TextField source="autorizzazione_uscite" />
            </Box>
            <Box display="flex" alignItems="center" width="100%">
                <Typography variant="h7" sx={{ marginRight: 1, fontWeight:'bold' }}> 
                    Fratelli o sorelle?: </Typography>
                <TextField source="fratelli_sorelle" label="Fratelli o sorelle?"/>
                </Box>  
            <Box display="flex" alignItems="center" width="100%">
                <Typography variant="h7" sx={{ marginRight: 1, fontWeight:'bold' }}> 
                Recapito telefonico: </Typography>
                <TextField source="recapito_telefonico" label="Recapito telefonico"/>
                </Box>                         
            <Box display="flex" alignItems="center" width="100%">
                <Typography variant="h7" sx={{ marginRight: 1, fontWeight:'bold' }}> 
                    Come ha conosciuto il servizio?: </Typography>
                <TextField source="sponsor" label="Come ha conosciuto il servizio"/>                      
                </Box>         
            <Box display="flex" alignItems="center" width="100%">
                <Typography variant="h7" sx={{ marginRight: 1, fontWeight:'bold' }}> 
                Note relative ai Pagamenti: </Typography>
                <TextField source="note_pagamenti" label="Note relative ai Pagamenti"/>
            </Box>
            <Box display="flex" alignItems="center" width="100%">
                <Typography variant="h7" sx={{ marginRight: 1, fontWeight:'bold' }}> 
                    Note: </Typography>
                <TextField source="Note" />
            </Box>     
      </Grid>          
        
    );
}

const tipoPreiscrizioneChoices = {
    "0": "Abbonamento 3gg/settimana",
    "1": "Abbonamento 4gg/settimana",
    "2": "Abbonamento 5gg/settimana",
    "3": "Carnet 40ore",
  };

const tipoFasceChoices = {
    '0': 'Fascia Esenzione',
    '1': 'Fascia 0,00 € - 3.900,00 €',
    '2': 'Fascia 3.900,01 € - 5.000,00 €',
    '3': 'Fascia 5.000,01 € - 6.100,00 €',
    '4': 'Fascia 6.100,01 € - 7.200,00 €',
    '5': 'Fascia 7.200,01 € - 8.400,00 €',
    '6': 'Fascia 8.400,01 € - 9.500,00 €',
    '7': 'Fascia 9.500,01 € - 10.600,00 €',
    '8': 'Fascia  10.600,01 € -  11.700,00 €',
    '9': 'Fascia 11.700,01 € - 12.800,00 €',
    '10': 'Fascia 12.800,01 € - 13.900,00 €',
    '11': 'Fascia 13.900,01 € - 15.000,00 €',
    '12': 'Fascia 15.000,01 € - 16.200,00 €',
    '13': 'Fascia 16.200,01 € - 17.300,00 €',
    '14': 'Fascia 17.300,01 € - 18.400,00 €',
    '15': 'Fascia 18.400,01 € - 19.500,00 €',
    '16': 'Fascia 19.500,01 € - 20.600,00 €',
    '17': 'Fascia 20.600,01 € - 21.800,00 €',
    '18': 'Fascia 21.800,01 € - 22.900,00 €',
    '19': 'Fascia 22.900,01 € - 24.000,00 €',
    '20': 'Fascia 24.000,01 € - 27.500,00 €',
    '21': 'Fascia 27.500,01 € - 31.000,00 €',
    '22': 'Fascia 31.000,01 € - 34.500,00 €',
    '23': 'Fascia 34.500,01 € - 38.000,00 €',
    '24': 'Fascia 38.000,01 € - 999.000,00 €',
    '25': 'Dato Non Disponibile'}

const AssicurazioneField = () => {
        const record = useRecordContext(); 
        //console.log("ASSICURAZIONE",record);
        if (!record?.data_pagamento_assicurazione) return <Typography>No</Typography>;      
        const dataAssicurazione = moment(record.data_pagamento_assicurazione);
        const unAnnoFa = moment().subtract(1, "year");
        //console.log("ASSICURAZIONE",dataAssicurazione, unAnnoFa);      
        return  <Typography variant="h7" sx={{ color: "#000 !important", marginRight: 1, fontWeight:'normal' }}>{dataAssicurazione.isAfter(unAnnoFa) ? dataAssicurazione.format("DD/MM/YYYY")  : "No"}</Typography>;
};
      


export const IscrizioneList = () => (
  <List  filters={IscrizioneFilters} perPage={50} actions={<IscrizioneActions exporter={exportToXLS}/>}>    
    <DatagridConfigurable bulkActionButtons={<IscrizioneBulkActionButtons />} expand={<ExpandIscrizioni/>}>       
      <TextField source="id" />      
      <ReferenceField source="bambino_id" reference="bambini" label="Bambino">
        <TextField source="nome" /> <TextField source="cognome" /> - <TextField source="codice_fiscale" />
      </ReferenceField>  
      <ReferenceField source="ente_id" reference="enti" label="Ente">
        <TextField source="nome" />
      </ReferenceField>      
      <ReferenceField source="stato_iscrizione_id" reference="stati_iscrizioni" label="Stato iscrizione">
        <TextField source="stato" />
      </ReferenceField>              
      <DateField source="creation_time" label="Data creazione" locales="it-IT" showTime={true}/>                  
      <DateField source="edit_time" label="Data ultima modifica" locales="it-IT" showTime={true}/>                  
      <ValidCircleField label="Titoli disponibili"/>  

      
      <EditButton/>      
    </DatagridConfigurable>
  </List>
);

{/*
 
*/}
//----------------------------------------------------------------------------------------------------------------------------------------------------------
//----------------------------------------------------------------------------------------------------------------------------------------------------------
//----------------------------------------------------------------------------------------------------------------------------------------------------------
//----------------------------------------------------------------------------------------------------------------------------------------------------------

//SELECTEDVALUE -> localIScrizioni.stato_iscrizione_id;
const CustomToolbar = () => (
    <Toolbar>
      <SaveButton />
    </Toolbar>
  );


export const IscrizioneEdit = () => {
  const { id } = useParams();
  const notify = useNotify();  
  const redirect = useRedirect();
  const dataProvider = useDataProvider();  
  const refresh = useRefresh(); 



  const [localIscrizioni, setLocalIscrizioni] = useState({});
  const { data: iscrizioni, isPending, error } = useGetOne('iscrizioni', { id: id });  
  const { data: slots, isPending2, error2 } = useGetManyReference(
    'slots',
    { 
        target: 'ente_id',
        id: iscrizioni.ente_id,
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'giorno', order: 'DESC' }
    }
  );
  
  const [choicesLunedìInizio, setChoicesLunedìInizio] = useState([]);      
  const [choicesLunedìFine, setChoicesLunedìFine] = useState([]);      
  const [choicesMartediInizio, setChoicesMartediInizio] = useState([]);      
  const [choicesMartediFine, setChoicesMartediFine] = useState([]);      
  const [choicesMercolediInizio, setChoicesMercolediInizio] = useState([]);      
  const [choicesMercolediFine, setChoicesMercolediFine] = useState([]);      
  const [choicesGiovediInizio, setChoicesGiovediInizio] = useState([]);      
  const [choicesGiovediFine, setChoicesGiovediFine] = useState([]);      
  const [choicesVenerdiInizio, setChoicesVenerdiInizio] = useState([]);      
  const [choicesVenerdiFine, setChoicesVenerdiFine] = useState([]);      
  const [choicesSabatoInizio, setChoicesSabatoInizio] = useState([]);      
  const [choicesSabatoFine, setChoicesSabatoFine] = useState([]);      
  
  

  useEffect(() => {  
        console.log("UE-1");
        if (iscrizioni) {
            console.log("STATO ID",iscrizioni.stato_iscrizione_id);
            setLocalIscrizioni({ ...iscrizioni });         
            //setSelectedValue(iscrizioni.stato_iscrizione_id);
            if(iscrizioni.stato_iscrizione_id==1){
                //setSelectedTitoloValue(iscrizioni.tipo_preiscrizione);
                //setSelectedISEEValue(iscrizioni.isee_preiscrizione);
                //setDataInizio(iscrizioni.data_inizio_desiderata)
            }
            else if(iscrizioni.stato_iscrizione_id==3){
                console.log("TRE");
                //setSelectedTitoloValue(iscrizioni.tipo_preiscrizione);
                //setSelectedISEEValue(iscrizioni.isee_preiscrizione);
                //setDataInizio(iscrizioni.data_inizio_desiderata)
            }
            else if(iscrizioni.stato_iscrizione_id==4){
                console.log("QUATTRO");
                //setSelectedTitoloValue(iscrizioni.tipo_preiscrizione);
                //setSelectedISEEValue(iscrizioni.isee_preiscrizione);
                //setDataInizio(iscrizioni.data_inizio_desiderata)
            }
            else{
                //setSelectedTitoloValue(iscrizioni.tipo_iscrizione);
                //setSelectedISEEValue(iscrizioni.isee);    
                //setDataInizio(iscrizioni.data_inizio_scelta);
            }         
        }
        if(slots!=undefined){
            slots.forEach(slot => {   
                var choices_init=[];         
                var choices_end=[];                                   
                if(slot['0700']!=0){
                        choices_init.push({id: '07:00', name: '07:00' });
                }
                if(slot['0730']!=0){
                    choices_init.push({id: '07:30', name: '07:30' });
                    choices_end.push({id: '07:30', name: '07:30' });
                }
                else{
                    if(choices_init.length!=0)
                        choices_end.push({id: '07:30', name: '07:30' });
                }
                if(slot['0800']!=0){
                    choices_init.push({id: '08:00', name: '08:00' });
                    choices_end.push({id: '08:00', name: '08:00' })
                }
                else{
                    if(choices_init.length!=0)                    
                        choices_end.push({id: '08:00', name: '08:00' });
                }
                if(slot['0830']!=0){
                    choices_init.push({id: '08:30', name: '08:30' });
                    choices_end.push({id: '08:30', name: '08:30' });
                }
                else{
                    if(choices_init.length!=0)                    
                        choices_end.push({id: '08:30', name: '08:30' });
                }
                if(slot['0900']!=0){
                    choices_init.push({id: '09:00', name: '09:00' });
                    choices_end.push({id: '09:00', name: '09:00' });
                }
                else{
                    if(choices_init.length!=0)                    
                        choices_end.push({id: '09:00', name: '09:00' })
                }
                if(slot['0930']!=0){
                    choices_init.push({id: '09:30', name: '09:30' });
                    choices_end.push({id: '09:30', name: '09:30' })
                }
                else{
                    if(choices_init.length!=0)                    
                    choices_end.push({id: '09:30', name: '09:30' })
                }
                if(slot['1000']!=0){
                    choices_init.push({id: '10:00', name: '10:00' });
                    choices_end.push({id: '10:00', name: '10:00' })
                }
                else{
                    if(choices_init.length!=0)                    
                        choices_end.push({id: '10:00', name: '10:00' })
                }
                if(slot['1030']!=0){
                    choices_init.push({id: '10:30', name: '10:30' });
                    choices_end.push({id: '10:30', name: '10:30' })
                }
                else{
                    if(choices_init.length!=0)                    
                        choices_end.push({id: '10:30', name: '10:30' })
                }
                if(slot['1100']!=0){
                    choices_init.push({id: '11:00', name: '11:00' });
                    choices_end.push({id: '11:00', name: '11:00' })
                }
                else{
                    if(choices_init.length!=0)                    
                        choices_end.push({id: '11:00', name: '11:00' })
                }
                if(slot['1130']!=0){
                    choices_init.push({id: '11:30', name: '11:30' });
                    choices_end.push({id: '11:30', name: '11:30' })
                }
                else{
                    if(choices_init.length!=0)                    
                        choices_end.push({id: '11:30', name: '11:30' })
                }
                if(slot['1200']!=0){
                    choices_init.push({id: '12:00', name: '12:00' });
                    choices_end.push({id: '12:00', name: '12:00' })
                }
                else{
                    if(choices_init.length!=0)                    
                    choices_end.push({id: '12:00', name: '12:00' })
                }
                if(slot['1230']!=0){
                    choices_init.push({id: '12:30', name: '12:30' });
                    choices_end.push({id: '12:30', name: '12:30' })
                }
                else{
                    if(choices_init.length!=0)                    
                        choices_end.push({id: '12:30', name: '12:30' })
                }
                if(slot['1300']!=0){
                    choices_init.push({id: '13:00', name: '13:00' });
                    choices_end.push({id: '13:00', name: '13:00' })
                }
                else{
                    if(choices_init.length!=0)                    
                        choices_end.push({id: '13:00', name: '13:00' })
                }
                if(slot['1330']!=0){
                    choices_init.push({id: '13:30', name: '13:30' });
                    choices_end.push({id: '13:30', name: '13:30' })
                }
                else{
                    if(choices_init.length!=0)                    
                        choices_end.push({id: '13:30', name: '13:30' })
                }
                if(slot['1400']!=0){
                    choices_init.push({id: '14:00', name: '14:00' });
                    choices_end.push({id: '14:00', name: '14:00' })
                }
                else{
                    if(choices_init.length!=0)                    
                        choices_end.push({id: '14:00', name: '14:00' })
                }
                if(slot['1430']!=0){
                    choices_init.push({id: '14:30', name: '14:30' });
                    choices_end.push({id: '14:30', name: '14:30' })
                }
                else{
                    if(choices_init.length!=0)                    
                        choices_end.push({id: '14:30', name: '14:30' })
                }
                if(slot['1500']!=0){
                    choices_init.push({id: '15:00', name: '15:00' });
                    choices_end.push({id: '15:00', name: '15:00' })
                }
                else{
                    if(choices_init.length!=0)                    
                        choices_end.push({id: '15:00', name: '15:00' })
                }
                if(slot['1530']!=0){
                    choices_init.push({id: '15:30', name: '15:30' });
                    choices_end.push({id: '15:30', name: '15:30' })
                }
                else{
                    if(choices_init.length!=0)                    
                        choices_end.push({id: '15:30', name: '15:30' })
                }
                if(slot['1600']!=0){
                    choices_init.push({id: '16:00', name: '16:00' });
                    choices_end.push({id: '16:00', name: '16:00' })
                }
                else{
                    if(choices_init.length!=0)                    
                        choices_end.push({id: '16:00', name: '16:00' })
                }
                if(slot['1630']!=0){
                    choices_init.push({id: '16:30', name: '16:30' });
                    choices_end.push({id: '16:30', name: '16:30' })
                }
                else{
                    if(choices_init.length!=0)                    
                        choices_end.push({id: '16:30', name: '16:30' })
                }
                if(slot['1700']!=0){
                    choices_init.push({id: '17:00', name: '17:00' });
                    choices_end.push({id: '17:00', name: '17:00' })
                }
                else{
                    if(choices_init.length!=0)                    
                        choices_end.push({id: '17:00', name: '17:00' })
                }
                if(slot['1730']!=0){
                    choices_init.push({id: '17:30', name: '17:30' });
                    choices_end.push({id: '17:30', name: '17:30' })
                }
                else{
                    if(choices_init.length!=0)                    
                        choices_end.push({id: '17:30', name: '17:30' })
                }
                if(slot['1800']!=0){
                    choices_init.push({id: '18:00', name: '18:00' });
                    choices_end.push({id: '18:00', name: '18:00' })
                }
                else{
                    if(choices_init.length!=0)                    
                        choices_end.push({id: '18:00', name: '18:00' })
                }
                if(slot['1830']!=0){
                    choices_init.push({id: '18:30', name: '18:30' });
                    choices_end.push({id: '18:30', name: '18:30' })
                }
                else{
                    if(choices_init.length!=0)                    
                        choices_end.push({id: '18:30', name: '18:30' })
                }
                if(slot['1900']!=0){
                    choices_init.push({id: '19:00', name: '19:00' });
                    choices_end.push({id: '19:00', name: '19:00' })
                }
                else{
                    if(choices_init.length!=0)                    
                        choices_end.push({id: '19:00', name: '19:00' });
                }
                if(slot['1930']!=0){                
                    choices_end.push({id: '19:30', name: '19:30' });
                }            
                if (slot.giorno =='lunedì'){
                    //console.log("lunedì trovato");
                    setChoicesLunedìInizio(choices_init);
                    setChoicesLunedìFine(choices_end);             
                }
                if (slot.giorno =='martedì'){
                    //console.log("martedì trovato");
                    setChoicesMartediInizio(choices_init);
                    setChoicesMartediFine(choices_end);
                }
                if (slot.giorno =='mercoledì'){
                    //console.log("mercoledì trovato");
                    setChoicesMercolediInizio(choices_init);
                    setChoicesMercolediFine(choices_end);
                }
                if (slot.giorno =='giovedì'){
                    //console.log("giovedì trovato");
                    setChoicesGiovediInizio(choices_init);
                    setChoicesGiovediFine(choices_end);
                }
                if (slot.giorno =='venerdì'){
                    //console.log("venerdì trovato");
                    setChoicesVenerdiInizio(choices_init);
                    setChoicesVenerdiFine(choices_end);
                }
                if (slot.giorno =='sabato'){
                    //console.log("sabato trovato");
                    setChoicesSabatoInizio(choices_init);
                    setChoicesSabatoFine(choices_end);
                }        
               });
        }
  },[iscrizioni,slots]);
    

  useEffect(() => {
    console.log("UE-2");
    //if ( selectedISEEValue ) {
        //console.log("TUTTO OK", dataInizio, localIscrizioni.format("MM"), moment(dataInizio).format("YYYY"));
        //setGiorniLavorativiMese(contaGiorniLavorativi(moment(dataInizio).format("YYYY"), moment(dataInizio).format("MM")));
        //setGiorniLavorativiRestantiMese(contaGiorniLavorativiRimanenti(moment(dataInizio).format("YYYY"), moment(dataInizio).format("MM"), dataInizio));
   // }
}, [ ]);
 


useEffect(() => {
    console.log("localIscrizioni aggiornato:", localIscrizioni);
    let maxGiorni = giorniDisponibili[localIscrizioni?.tipo_preiscrizione] || 5;
    const giorniSelezionati = giorni.filter(giorno => 
        localIscrizioni?.[`inizio_${giorno}_desiderata`] || 
        localIscrizioni?.[`fine_${giorno}_desiderata`]
    );
    console.log("BAH PRE",giorniSelezionati.length , maxGiorni);
    if (giorniSelezionati.length > maxGiorni) {
        const ultimoGiorno = giorniSelezionati[giorniSelezionati.length - 1];
        console.log("UTLIMO G",ultimoGiorno)
        // Se c'è un giorno selezionato che è oltre il limite, resetta il suo valore
        setLocalIscrizioni(prev => {
            const newIscrizioni = { ...prev };
            newIscrizioni[`inizio_${ultimoGiorno}_desiderata`] = null;
            newIscrizioni[`fine_${ultimoGiorno}_desiderata`] = null;
            return newIscrizioni;
        });

    }
    
    maxGiorni = giorniDisponibili[localIscrizioni?.tipo_iscrizione] || 5;
    const giorniSelezionatiIscrizione = giorni.filter(giorno => 
        localIscrizioni?.[`inizio_${giorno}_effettiva`] || 
        localIscrizioni?.[`fine_${giorno}_effettiva`]
    );
    console.log("BAH ISCR",giorniSelezionatiIscrizione.length , maxGiorni);
    if (giorniSelezionatiIscrizione.length > maxGiorni) {
        const ultimoGiorno = giorniSelezionatiIscrizione[giorniSelezionatiIscrizione.length - 1];
        console.log("UTLIMO GGGG",ultimoGiorno)
        // Se c'è un giorno selezionato che è oltre il limite, resetta il suo valore
        setLocalIscrizioni(prev => {
            const newIscrizioni = { ...prev };
            newIscrizioni[`inizio_${ultimoGiorno}_effettiva`] = null;
            newIscrizioni[`fine_${ultimoGiorno}_effettiva`] = null;
            return newIscrizioni;
        });
    }
  }, [localIscrizioni]);

  const onChanging = (value) => { //CAMBIO ENTE
    console.log("REC",value);
    setChoicesLunedìInizio([]);      
    setChoicesLunedìFine([]);      
    setChoicesMartediInizio([]);      
    setChoicesMartediFine([]);      

    setChoicesMercolediInizio([]);      
    setChoicesMercolediFine([]);      
    setChoicesGiovediInizio([]);      
    setChoicesGiovediFine([]);      

    setChoicesVenerdiInizio([]);      
    setChoicesVenerdiFine([]);      
    setChoicesSabatoInizio([]);      
    setChoicesSabatoFine([]);      
    try {
        dataProvider.getManyReference("slots", {
            target: "ente_id",
            id: value,
            pagination: { page: 1, perPage: 1000 },
            sort: { field: "giorno", order: "ASC" }
          })
          .then(({ data }) => {
            console.log("Dati ricevuti:", data);
             
            if(data!=undefined){
            data.forEach(slot => {   
                var choices_init=[];         
                var choices_end=[];                                   
                if(slot['0700']!=0){
                        choices_init.push({id: '07:00', name: '07:00' });
                }
                if(slot['0730']!=0){
                    choices_init.push({id: '07:30', name: '07:30' });
                    choices_end.push({id: '07:30', name: '07:30' });
                }
                else{
                    if(choices_init.length!=0)
                        choices_end.push({id: '07:30', name: '07:30' });
                }
                if(slot['0800']!=0){
                    choices_init.push({id: '08:00', name: '08:00' });
                    choices_end.push({id: '08:00', name: '08:00' })
                }
                else{
                    if(choices_init.length!=0)                    
                        choices_end.push({id: '08:00', name: '08:00' });
                }
                if(slot['0830']!=0){
                    choices_init.push({id: '08:30', name: '08:30' });
                    choices_end.push({id: '08:30', name: '08:30' });
                }
                else{
                    if(choices_init.length!=0)                    
                        choices_end.push({id: '08:30', name: '08:30' });
                }
                if(slot['0900']!=0){
                    choices_init.push({id: '09:00', name: '09:00' });
                    choices_end.push({id: '09:00', name: '09:00' });
                }
                else{
                    if(choices_init.length!=0)                    
                        choices_end.push({id: '09:00', name: '09:00' })
                }
                if(slot['0930']!=0){
                    choices_init.push({id: '09:30', name: '09:30' });
                    choices_end.push({id: '09:30', name: '09:30' })
                }
                else{
                    if(choices_init.length!=0)                    
                    choices_end.push({id: '09:30', name: '09:30' })
                }
                if(slot['1000']!=0){
                    choices_init.push({id: '10:00', name: '10:00' });
                    choices_end.push({id: '10:00', name: '10:00' })
                }
                else{
                    if(choices_init.length!=0)                    
                        choices_end.push({id: '10:00', name: '10:00' })
                }
                if(slot['1030']!=0){
                    choices_init.push({id: '10:30', name: '10:30' });
                    choices_end.push({id: '10:30', name: '10:30' })
                }
                else{
                    if(choices_init.length!=0)                    
                        choices_end.push({id: '10:30', name: '10:30' })
                }
                if(slot['1100']!=0){
                    choices_init.push({id: '11:00', name: '11:00' });
                    choices_end.push({id: '11:00', name: '11:00' })
                }
                else{
                    if(choices_init.length!=0)                    
                        choices_end.push({id: '11:00', name: '11:00' })
                }
                if(slot['1130']!=0){
                    choices_init.push({id: '11:30', name: '11:30' });
                    choices_end.push({id: '11:30', name: '11:30' })
                }
                else{
                    if(choices_init.length!=0)                    
                        choices_end.push({id: '11:30', name: '11:30' })
                }
                if(slot['1200']!=0){
                    choices_init.push({id: '12:00', name: '12:00' });
                    choices_end.push({id: '12:00', name: '12:00' })
                }
                else{
                    if(choices_init.length!=0)                    
                    choices_end.push({id: '12:00', name: '12:00' })
                }
                if(slot['1230']!=0){
                    choices_init.push({id: '12:30', name: '12:30' });
                    choices_end.push({id: '12:30', name: '12:30' })
                }
                else{
                    if(choices_init.length!=0)                    
                        choices_end.push({id: '12:30', name: '12:30' })
                }
                if(slot['1300']!=0){
                    choices_init.push({id: '13:00', name: '13:00' });
                    choices_end.push({id: '13:00', name: '13:00' })
                }
                else{
                    if(choices_init.length!=0)                    
                        choices_end.push({id: '13:00', name: '13:00' })
                }
                if(slot['1330']!=0){
                    choices_init.push({id: '13:30', name: '13:30' });
                    choices_end.push({id: '13:30', name: '13:30' })
                }
                else{
                    if(choices_init.length!=0)                    
                        choices_end.push({id: '13:30', name: '13:30' })
                }
                if(slot['1400']!=0){
                    choices_init.push({id: '14:00', name: '14:00' });
                    choices_end.push({id: '14:00', name: '14:00' })
                }
                else{
                    if(choices_init.length!=0)                    
                        choices_end.push({id: '14:00', name: '14:00' })
                }
                if(slot['1430']!=0){
                    choices_init.push({id: '14:30', name: '14:30' });
                    choices_end.push({id: '14:30', name: '14:30' })
                }
                else{
                    if(choices_init.length!=0)                    
                        choices_end.push({id: '14:30', name: '14:30' })
                }
                if(slot['1500']!=0){
                    choices_init.push({id: '15:00', name: '15:00' });
                    choices_end.push({id: '15:00', name: '15:00' })
                }
                else{
                    if(choices_init.length!=0)                    
                        choices_end.push({id: '15:00', name: '15:00' })
                }
                if(slot['1530']!=0){
                    choices_init.push({id: '15:30', name: '15:30' });
                    choices_end.push({id: '15:30', name: '15:30' })
                }
                else{
                    if(choices_init.length!=0)                    
                        choices_end.push({id: '15:30', name: '15:30' })
                }
                if(slot['1600']!=0){
                    choices_init.push({id: '16:00', name: '16:00' });
                    choices_end.push({id: '16:00', name: '16:00' })
                }
                else{
                    if(choices_init.length!=0)                    
                        choices_end.push({id: '16:00', name: '16:00' })
                }
                if(slot['1630']!=0){
                    choices_init.push({id: '16:30', name: '16:30' });
                    choices_end.push({id: '16:30', name: '16:30' })
                }
                else{
                    if(choices_init.length!=0)                    
                        choices_end.push({id: '16:30', name: '16:30' })
                }
                if(slot['1700']!=0){
                    choices_init.push({id: '17:00', name: '17:00' });
                    choices_end.push({id: '17:00', name: '17:00' })
                }
                else{
                    if(choices_init.length!=0)                    
                        choices_end.push({id: '17:00', name: '17:00' })
                }
                if(slot['1730']!=0){
                    choices_init.push({id: '17:30', name: '17:30' });
                    choices_end.push({id: '17:30', name: '17:30' })
                }
                else{
                    if(choices_init.length!=0)                    
                        choices_end.push({id: '17:30', name: '17:30' })
                }
                if(slot['1800']!=0){
                    choices_init.push({id: '18:00', name: '18:00' });
                    choices_end.push({id: '18:00', name: '18:00' })
                }
                else{
                    if(choices_init.length!=0)                    
                        choices_end.push({id: '18:00', name: '18:00' })
                }
                if(slot['1830']!=0){
                    choices_init.push({id: '18:30', name: '18:30' });
                    choices_end.push({id: '18:30', name: '18:30' })
                }
                else{
                    if(choices_init.length!=0)                    
                        choices_end.push({id: '18:30', name: '18:30' })
                }
                if(slot['1900']!=0){
                    choices_init.push({id: '19:00', name: '19:00' });
                    choices_end.push({id: '19:00', name: '19:00' })
                }
                else{
                    if(choices_init.length!=0)                    
                        choices_end.push({id: '19:00', name: '19:00' });
                }
                if(slot['1930']!=0){                
                    choices_end.push({id: '19:30', name: '19:30' });
                }            
                if (slot.giorno =='lunedì'){
                    //console.log("lunedì trovato");
                    setChoicesLunedìInizio(choices_init);
                    setChoicesLunedìFine(choices_end);             
                }
                if (slot.giorno =='martedì'){
                    //console.log("martedì trovato");
                    setChoicesMartediInizio(choices_init);
                    setChoicesMartediFine(choices_end);
                }
                if (slot.giorno =='mercoledì'){
                    //console.log("mercoledì trovato");
                    setChoicesMercolediInizio(choices_init);
                    setChoicesMercolediFine(choices_end);
                }
                if (slot.giorno =='giovedì'){
                    //console.log("giovedì trovato");
                    setChoicesGiovediInizio(choices_init);
                    setChoicesGiovediFine(choices_end);
                }
                if (slot.giorno =='venerdì'){
                    //console.log("venerdì trovato");
                    setChoicesVenerdiInizio(choices_init);
                    setChoicesVenerdiFine(choices_end);
                }
                if (slot.giorno =='sabato'){
                    //console.log("sabato trovato");
                    setChoicesSabatoInizio(choices_init);
                    setChoicesSabatoFine(choices_end);
                }        
               });
            }
          })
          .catch(error => console.error("Errore nel recupero dati:", error));                     
  } 
  catch (error) {
    console.log("EERR22",error);
    notify("Qualcosa è andato storto nella creazione dell'iscrizione. Riprovare più tardi");
  }
};


const onChangingStato = (event) => {
    //console.log("STATO-1", event, localIscrizioni);
    //console.log("STATO", event, localIscrizioni.stato_iscrizione_id);

    setLocalIscrizioni((prev) => {
        if (!prev) return prev;

        let newIscrizioni = { ...prev };
        
        if (localIscrizioni.stato_iscrizione_id == "2" && event == "1") {
            console.log("CAMBIAMENTO da ISCR A PRE", prev.stato_iscrizione_id);
            newIscrizioni = {
                ...newIscrizioni,
                stato_iscrizione_id:"1",
            };            
        }
        if (localIscrizioni.stato_iscrizione_id == "1" && event == "2") {
            console.log("CAMBIAMENTO da PRE A ISCR", prev.stato_iscrizione_id);
            newIscrizioni = {
                ...newIscrizioni,
                stato_iscrizione_id:"2",
                data_inizio_scelta:prev.data_inizio_desiderata,     
                data_fine_scelta:prev.data_fine_desiderata,  
                tipo_iscrizione:prev.tipo_preiscrizione,    
                isee:prev.isee_preiscrizione,     
                inizio_lunedi_effettiva: prev.inizio_lunedi_desiderata,
                inizio_martedi_effettiva: prev.inizio_martedi_desiderata,
                inizio_mercoledi_effettiva: prev.inizio_mercoledi_desiderata,
                inizio_giovedi_effettiva: prev.inizio_giovedi_desiderata,
                inizio_venerdi_effettiva: prev.inizio_venerdi_desiderata,
                fine_lunedi_effettiva: prev.fine_lunedi_desiderata,
                fine_martedi_effettiva: prev.fine_martedi_desiderata,
                fine_mercoledi_effettiva: prev.fine_mercoledi_desiderata,
                fine_giovedi_effettiva: prev.fine_giovedi_desiderata,
                fine_venerdi_effettiva: prev.fine_venerdi_desiderata,
            };            
        }
        if (localIscrizioni.stato_iscrizione_id == "3"  && event == "4") {
            console.log("CAMBIAMENTO da SOSP A PNN", prev.stato_iscrizione_id);
            newIscrizioni = {
                ...newIscrizioni,
                stato_iscrizione_id:event,
            };
        }

        if (localIscrizioni.stato_iscrizione_id == "4"  && event == "3") {
            console.log("CAMBIAMENTO da ANN A sOSP", prev.stato_iscrizione_id);
            newIscrizioni = {
                ...newIscrizioni,
                stato_iscrizione_id:event,
            };
        }
        if ((localIscrizioni.stato_iscrizione_id == "3" || localIscrizioni.stato_iscrizione_id == "4") && event == "1") {
            console.log("CAMBIAMENTO da SOST A PRE", prev.stato_iscrizione_id);
            newIscrizioni = {
                ...newIscrizioni,
                stato_iscrizione_id:event,
            };
        }
        if ((localIscrizioni.stato_iscrizione_id == "3" || localIscrizioni.stato_iscrizione_id == "4") && event == "2") {
            console.log("CAMBIAMENTO da SOST A ISCR", prev.stato_iscrizione_id);
            newIscrizioni = {
                ...newIscrizioni,
                stato_iscrizione_id:event,
            };
        }

        if ((localIscrizioni.stato_iscrizione_id == "2" && event == "3") || (localIscrizioni.stato_iscrizione_id == "2" && event == "4")) {
            console.log("CAMBIAMENTO da ISCR A CANC/ANN", prev.stato_iscrizione_id, localIscrizioni.stato_iscrizione_id, event);
            newIscrizioni = {
                ...newIscrizioni,
                stato_iscrizione_id:event,
            };
        }
        if ((localIscrizioni.stato_iscrizione_id == "1" && event == "3") || (localIscrizioni.stato_iscrizione_id == "1" && event == "4")) {
            console.log("CAMBIAMENTO da PRE A CANC/ANN", prev.stato_iscrizione_id, localIscrizioni.stato_iscrizione_id, event);
            newIscrizioni = {
                ...newIscrizioni,
                stato_iscrizione_id:event,
            };
        }
        return newIscrizioni;
    });
    
    console.log("CAMBIO STATO", localIscrizioni);
    setLocalIscrizioni((newState) => {
        console.log("CAMBIO STATO NEW", newState);
        return newState;
    });
};




const transform = (data) => {
    
    const sanitizedData = {};
    for (const key in data) {
        if(data[key]!="")
            sanitizedData[key] = data[key]; 
        else    
            sanitizedData[key] = null; 
    }
    
    sanitizedData["edit_time"]=getTime();
    return sanitizedData; 
};


//16/02
const giorniDisponibili = {
    "0": 3, // Abbonamento 3gg
    "1": 4, // Abbonamento 4gg
    "2": 5, // Abbonamento 5gg    
};

const giorni = ["lunedi", "martedi", "mercoledi", "giovedi", "venerdi"];

const giorniCompilati = giorni.filter(giorno => 
    localIscrizioni?.[`inizio_${giorno}_desiderata`] || 
    localIscrizioni?.[`fine_${giorno}_desiderata`]
).length;

const isDisabilitato = (giorno) => {
    const maxGiorni = giorniDisponibili[localIscrizioni?.tipo_preiscrizione] || 5;
   
    const giornoSelezionato = localIscrizioni?.[`inizio_${giorno}_desiderata`] || 
                              localIscrizioni?.[`fine_${giorno}_desiderata`];

    if (giorniCompilati >= maxGiorni) {
        if (!giornoSelezionato) {
            return true;
        }
        
        const giorniSelezionati = giorni.filter(giorno => 
            localIscrizioni?.[`inizio_${giorno}_desiderata`] || 
            localIscrizioni?.[`fine_${giorno}_desiderata`]
        );

        if (giorniSelezionati.length > maxGiorni) {
            const ultimoGiorno = giorniSelezionati[giorniSelezionati.length - 1];
            if (giorno === ultimoGiorno) {                                
                return true;
            }
        }
    }
    return false;    
};

const giorniCompilatiIscrizione = giorni.filter(giorno => 
    localIscrizioni?.[`inizio_${giorno}_effettiva`] || 
    localIscrizioni?.[`fine_${giorno}_effettiva`]
).length;

const isDisabilitatoIscrizione = (giorno) => {
    const maxGiorni = giorniDisponibili[localIscrizioni?.tipo_iscrizione] || 5;
   
    const giornoSelezionato = localIscrizioni?.[`inizio_${giorno}_effettiva`] || 
                              localIscrizioni?.[`fine_${giorno}_effettiva`];

    if (giorniCompilatiIscrizione >= maxGiorni) {
        if (!giornoSelezionato) {
            return true;
        }
        
        const giorniSelezionatiIscrizione = giorni.filter(giorno => 
            localIscrizioni?.[`inizio_${giorno}_effettiva`] || 
            localIscrizioni?.[`fine_${giorno}_effettiva`]
        );

        if (giorniSelezionatiIscrizione.length > maxGiorni) {
            const ultimoGiorno = giorniSelezionatiIscrizione[giorniSelezionatiIscrizione.length - 1];
            if (giorno === ultimoGiorno) {                                
                return true;
            }
        }
    }
    return false;    
};
//16/02
 
  const handleEdit = async (values) => {
    try {    
        let maxGiorni = giorniDisponibili[values.tipo_preiscrizione] || 5;
        let giorniSelezionati = giorni.filter(giorno => 
          values?.[`inizio_${giorno}_desiderata`] || 
          values?.[`fine_${giorno}_desiderata`]
        );
        console.log("PEER BAHCCO PRE",giorniSelezionati.length , maxGiorni);
        const sanitizedData = {};
        for (const key in values) {          
              sanitizedData[key] = values[key];             
        }     
        while(giorniSelezionati.length > maxGiorni){
            if (giorniSelezionati.length > maxGiorni) {        
                console.log("PEER BAHCCO2 ",giorniSelezionati , maxGiorni);
                const ultimoGiorno = giorniSelezionati[giorniSelezionati.length - 1];
                console.log("PEER BAHCCO3 ",ultimoGiorno);
                console.log("ULTIMO GIO",values, ultimoGiorno,`inizio_${ultimoGiorno}_desiderata`);          
                for (const key in sanitizedData) {
                    if(key==`inizio_${ultimoGiorno}_desiderata`)
                    sanitizedData[key] = null;
                    else if(key==`fine_${ultimoGiorno}_desiderata`)
                    sanitizedData[key] = null;        
                }             
                giorniSelezionati.pop();
                console.log("PEER BAHCCO4 ", giorniSelezionati);
            }
        }
        maxGiorni = giorniDisponibili[localIscrizioni?.tipo_iscrizione] || 5;
        let giorniSelezionatiIscrizione = giorni.filter(giorno => 
            values?.[`inizio_${giorno}_effettiva`] || 
            values?.[`fine_${giorno}_effettiva`]
        );
        console.log("PEER BAHCCO ISCR",giorniSelezionatiIscrizione.length , maxGiorni);
                
        while(giorniSelezionatiIscrizione.length > maxGiorni){
              if (giorniSelezionatiIscrizione.length > maxGiorni) {        
                  console.log("PEER BAHCCO2 ",giorniSelezionatiIscrizione , maxGiorni);
                  const ultimoGiorno = giorniSelezionatiIscrizione[giorniSelezionatiIscrizione.length - 1];
                  console.log("PEER BAHCCO3 ",ultimoGiorno);
                  console.log("ULTIMO GIO",values, ultimoGiorno,`inizio_${ultimoGiorno}_effettiva`);          
                  for (const key in sanitizedData) {
                      if(key==`inizio_${ultimoGiorno}_effettiva`)
                      sanitizedData[key] = null;
                      else if(key==`fine_${ultimoGiorno}_effettiva`)
                      sanitizedData[key] = null;        
              } 
              giorniSelezionatiIscrizione.pop();
              console.log("PEER BAHCCO4 ", giorniSelezionatiIscrizione);
          }
        }
        console.log("EDEE",sanitizedData);
        var data2=transform(sanitizedData);
        console.log("Editing",data2)
        const { data } = await dataProvider.update('iscrizioni', { id:data2.id, data: data2 });          
      
        notify("Dati aggiornati con successo!", { messageArgs: { smart_count: 1 } });
        refresh();
        redirect('/iscrizioni');                        
    } catch (error) {
        if (error?.status) {
            switch (error.status) {
                case 400:
                    notify("Dati non validi. ", { type: 'warning' });
                    break;
                case 401:
                    notify("Non autorizzato.", { type: 'error' });
                    break;
                case 403:
                    notify("Accesso negato.", { type: 'error' });
                    break;
                case 407:
                    notify("Sei troppo avanti nel tempo.", { type: 'error' });
                    break;
                case 412:                    
                    const jsonObject = JSON.parse(JSON.stringify(error, null, 2));
                    //console.log("PD",jsonObject.body.error.message);                    
                    notify(jsonObject.body.error.message, { type: 'error' });
                    break;
                case 500:
                    notify("Errore interno del server.", { type: 'error' });
                    break;
                default:
                    notify(`Errore ${error.body.status}: Si è verificato un problema.`, { type: 'error' });
            }
        } else {
            notify(`Qualcosa è andato storto nella creazione dell'iscrizione. Riprovare più tardi`, { type: 'error' });
        }
      }
    };
  
    const slotNonSelezionato = (inizio, fine) => {
        //console.log("slot non selezionato",inizio, fine,(!inizio && !fine));
        if (!inizio && !fine) return true; 
            return false;
      };

      const calcolaDifferenzaMinuti = (inizio, fine) => {
        
        if (!inizio || !fine) return 500; 
        inizio = inizio.replace(".", ":");
        fine = fine.replace(".", ":");
            
        const [h1, m1] = inizio.split(":").map(Number);
        const [h2, m2] = fine.split(":").map(Number);
    
        const minutiInizio = h1 * 60 + m1;
        const minutiFine = h2 * 60 + m2;            
        return minutiFine - minutiInizio;
    };

  const validateDurationFields = (values) => {
        const errors = {};
        console.log("VALID",values);

        if(values.stato_iscrizione_id=="1"){ //PREISCRIZIONE
            console.log("PRE",values);
            
            if(!slotNonSelezionato (values.inizio_lunedi_desiderata, values.fine_lunedi_desiderata)){       
                if(calcolaDifferenzaMinuti(values.inizio_lunedi_desiderata, values.fine_lunedi_desiderata)<180 ||calcolaDifferenzaMinuti(values.inizio_lunedi_desiderata, values.fine_lunedi_desiderata)>300){
                    errors.inizio_lunedi_desiderata="La durata deve essere compresa tra 3 ore e 5 ore";
                    errors.fine_lunedi_desiderata="La durata deve essere compresa tra 3 ore e 5 ore";  
                }
            }
            
            if(!slotNonSelezionato (values.inizio_martedi_desiderata, values.fine_martedi_desiderata)){
                if(calcolaDifferenzaMinuti(values.inizio_martedi_desiderata, values.fine_martedi_desiderata)<180 ||calcolaDifferenzaMinuti(values.inizio_martedi_desiderata, values.fine_martedi_desiderata)>300){
                    errors.inizio_martedi_desiderata="La durata deve essere compresa tra 3 ore e 5 ore";
                    errors.fine_martedi_desiderata="La durata deve essere compresa tra 3 ore e 5 ore";
                }
            }
            if(!slotNonSelezionato (values.inizio_mercoledi_desiderata, values.fine_mercoledi_desiderata)){
                if(calcolaDifferenzaMinuti(values.inizio_mercoledi_desiderata, values.fine_mercoledi_desiderata)<180 ||calcolaDifferenzaMinuti(values.inizio_mercoledi_desiderata, values.fine_mercoledi_desiderata)>300){
                    errors.inizio_mercoledi_desiderata="La durata deve essere compresa tra 3 ore e 5 ore";
                    errors.fine_mercoledi_desiderata="La durata deve essere compresa tra 3 ore e 5 ore";
                }
            }
            if(!slotNonSelezionato (values.inizio_giovedi_desiderata, values.fine_giovedi_desiderata)){
                if(calcolaDifferenzaMinuti(values.inizio_giovedi_desiderata, values.fine_giovedi_desiderata)<180 ||calcolaDifferenzaMinuti(values.inizio_giovedi_desiderata, values.fine_giovedi_desiderata)>300){
                    errors.inizio_giovedi_desiderata="La durata deve essere compresa tra 3 ore e 5 ore";
                    errors.fine_giovedi_desiderata="La durata deve essere compresa tra 3 ore e 5 ore";
                }
            }
            if(!slotNonSelezionato (values.inizio_venerdi_desiderata, values.fine_venerdi_desiderata)){
                if(calcolaDifferenzaMinuti(values.inizio_venerdi_desiderata, values.fine_venerdi_desiderata)<180 ||calcolaDifferenzaMinuti(values.inizio_venerdi_desiderata, values.fine_venerdi_desiderata)>300){
                    errors.inizio_venerdi_desiderata="La durata deve essere compresa tra 3 ore e 5 ore";
                    errors.fine_venerdi_desiderata="La durata deve essere compresa tra 3 ore e 5 ore";
                }
            }            
            if(values.disponibilita_cambio_orario_sede==""||values.disponibilita_cambio_orario_sede==null)
                errors.disponibilita_cambio_orario_sede="Il campo è obbligatorio";
            if(values.adempimenti_richiesti==""||values.adempimenti_richiesti==null)
                errors.adempimenti_richiesti="Il campo è obbligatorio";
            if(values.in_attesa_servizio_educativo_tradizionale==""||values.in_attesa_servizio_educativo_tradizionale==null)
                errors.in_attesa_servizio_educativo_tradizionale="Il campo è obbligatorio";
            if(values.privacy_preiscrizione==""||values.privacy_preiscrizione==null)
                errors.privacy_preiscrizione="Il campo è obbligatorio";
            if(values.data_inizio_desiderata==""||values.data_inizio_desiderata==null)
                errors.data_inizio_desiderata="Il campo è obbligatorio";
            if(values.data_fine_desiderata==""||values.data_fine_desiderata==null)
                errors.data_fine_desiderata="Il campo è obbligatorio";
            if(values.tipo_preiscrizione==""||values.tipo_preiscrizione==null)
                errors.tipo_preiscrizione="Il campo è obbligatorio";
            if(values.isee_preiscrizione==""||values.isee_preiscrizione==null)
                errors.isee_preiscrizione="Il campo è obbligatorio";

        }
        else if(values.stato_iscrizione_id=="2"){ //ISCRIZIONE
            console.log("ISCR",values);
            if(!slotNonSelezionato (values.inizio_lunedi_effettiva, values.fine_lunedi_effettiva)){       
                if(calcolaDifferenzaMinuti(values.inizio_lunedi_effettiva, values.fine_lunedi_effettiva)<180 ||calcolaDifferenzaMinuti(values.inizio_lunedi_effettiva, values.fine_lunedi_effettiva)>300){
                    errors.inizio_lunedi_effettiva="La durata deve essere compresa tra 3 ore e 5 ore";
                    errors.fine_lunedi_effettiva="La durata deve essere compresa tra 3 ore e 5 ore";  
                }
            }
            
            if(!slotNonSelezionato (values.inizio_martedi_effettiva, values.fine_martedi_effettiva)){
                if(calcolaDifferenzaMinuti(values.inizio_martedi_effettiva, values.fine_martedi_effettiva)<180 ||calcolaDifferenzaMinuti(values.inizio_martedi_effettiva, values.fine_martedi_effettiva)>300){
                    errors.inizio_martedi_effettiva="La durata deve essere compresa tra 3 ore e 5 ore";
                    errors.fine_martedi_effettiva="La durata deve essere compresa tra 3 ore e 5 ore";
                }
            }
            if(!slotNonSelezionato (values.inizio_mercoledi_effettiva, values.fine_mercoledi_effettiva)){
                if(calcolaDifferenzaMinuti(values.inizio_mercoledi_effettiva, values.fine_mercoledi_effettiva)<180 ||calcolaDifferenzaMinuti(values.inizio_mercoledi_effettiva, values.fine_mercoledi_effettiva)>300){
                    errors.inizio_mercoledi_effettiva="La durata deve essere compresa tra 3 ore e 5 ore";
                    errors.fine_mercoledi_effettiva="La durata deve essere compresa tra 3 ore e 5 ore";
                }
            }
            if(!slotNonSelezionato (values.inizio_giovedi_effettiva, values.fine_giovedi_effettiva)){
                if(calcolaDifferenzaMinuti(values.inizio_giovedi_effettiva, values.fine_giovedi_effettiva)<180 ||calcolaDifferenzaMinuti(values.inizio_giovedi_effettiva, values.fine_giovedi_effettiva)>300){
                    errors.inizio_giovedi_effettiva="La durata deve essere compresa tra 3 ore e 5 ore";
                    errors.fine_giovedi_effettiva="La durata deve essere compresa tra 3 ore e 5 ore";
                }
            }
            if(!slotNonSelezionato (values.inizio_venerdi_effettiva, values.fine_venerdi_effettiva)){
                if(calcolaDifferenzaMinuti(values.inizio_venerdi_effettiva, values.fine_venerdi_effettiva)<180 ||calcolaDifferenzaMinuti(values.inizio_venerdi_effettiva, values.fine_venerdi_effettiva)>300){
                    errors.inizio_venerdi_effettiva="La durata deve essere compresa tra 3 ore e 5 ore";
                    errors.fine_venerdi_effettiva="La durata deve essere compresa tra 3 ore e 5 ore";
                }
            }            
            if(values.disponibilita_cambio_orario_sede==""||values.disponibilita_cambio_orario_sede==null)
                errors.disponibilita_cambio_orario_sede="Il campo è obbligatorio";
            if(values.data_inizio_scelta==""||values.data_inizio_scelta==null)
                errors.data_inizio_scelta="Il campo è obbligatorio";
            if(values.data_fine_scelta==""||values.data_fine_scelta==null)
                errors.data_fine_scelta="Il campo è obbligatorio";
            if(values.tipo_iscrizione==""||values.tipo_iscrizione==null)
                errors.tipo_iscrizione="Il campo è obbligatorio";
            if(values.isee==""||values.isee==null)
                errors.isee="Il campo è obbligatorio";               

            if(values.patto_educativo==""||values.patto_educativo==null)
                errors.patto_educativo="Il campo è obbligatorio";
            if(values.in_attesa_servizio_educativo_tradizionale==""||values.in_attesa_servizio_educativo_tradizionale==null)
                errors.in_attesa_servizio_educativo_tradizionale="Il campo è obbligatorio";
            if(values.privacy_iscrizione==""||values.privacy_iscrizione==null)
                errors.privacy_iscrizione="Il campo è obbligatorio";
            if(values.fragilita_economica==""||values.fragilita_economica==null)
                errors.fragilita_economica="Il campo è obbligatorio";
            if(values.liberatoria_immagini==""||values.liberatoria_immagini==null)
                errors.liberatoria_immagini="Il campo è obbligatorio";
            if(values.fratelli_sorelle==""||values.fratelli_sorelle==null)
                errors.fratelli_sorelle="Il campo è obbligatorio";
            if(values.somministrazione_farmaci==""||values.somministrazione_farmaci==null)
                errors.somministrazione_farmaci="Il campo è obbligatorio";
            if(values.delega_minori==""||values.delega_minori==null)
                errors.delega_minori="Il campo è obbligatorio";
            if(values.autorizzazione_uscite==""||values.autorizzazione_uscite==null)
                errors.autorizzazione_uscite="Il campo è obbligatorio";
            if(values.indicatore_isee==""||values.indicatore_isee==null)
                errors.indicatore_isee="Il campo è obbligatorio";
            if(values.isee_data_sottoscrizione==""||values.isee_data_sottoscrizione==null)
                errors.isee_data_sottoscrizione="Il campo è obbligatorio";
        }
        return errors;  
      };


   

  return(
  console.log("WWW",localIscrizioni),
  <Edit title={<username />} mutationMode="pessimistic" transform={transform}>     
    <SimpleForm toolbar={<CustomToolbar />} onSubmit={handleEdit} validate={validateDurationFields} sx={{ maxWidth: '100%' }}>  
    <Typography variant="h6" gutterBottom>
          Dati
    </Typography>       
    
    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <ReferenceInput source="bambino_id" reference="bambini" label="Bambino" perPage={1000} >      
                <SelectInput disabled={true} validate={required()} option="nome" optionText={(record) => `${record.nome} ${record.cognome} - ${record.codice_fiscale}`}    options={{fullWidth:true}} sx={{textAlign: "left"}} fullWidth  />
            </ReferenceInput> 
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
            <ReferenceInput source="ente_id" reference="enti" label="Ente" perPage={1000}>      
                <SelectInput disabled={true}  validate={required()} option="nome" optionText="nome"  options={{fullWidth:true}} sx={{textAlign: "left"}} fullWidth       
                onChange={(event) => {
                //console.log(event.target.value);                    
                    onChanging(event.target.value)}}/> 
            </ReferenceInput> 
        </Box>
    </Box> 
    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <ReferenceInput source="stato_iscrizione_id" reference="stati_iscrizioni" label="Stato Iscrizione" perPage={1000}>      
                <SelectInput   validate={required()} option="stato" optionText="stato" value={localIscrizioni.stato_iscrizione_id}  options={{fullWidth:true}} sx={{textAlign: "left"}} fullWidth                  
                 onChange={(event) => {                                  
                    onChangingStato(event.target.value)
                    }}
            
                />
            </ReferenceInput> 
        </Box>   
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
        </Box>             
    </Box> 

    
    {localIscrizioni != null && localIscrizioni.stato_iscrizione_id == "1" ? (  //PREISCRIZIONE    
        <Box display={{ width: '100%',  alignItems: "flex-start", textAlign: "left"  }}>
            <Typography variant="h6" gutterBottom> Dati Pre-Iscrizione </Typography>  

            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <DateInput
                    validate={required()} source="data_inizio_desiderata" label="Data di inizio" locales="it-IT" fullWidth
                    defaultValue={localIscrizioni?.data_inizio_desiderata || ""}
                      onChange={(event) => {                           
                        setLocalIscrizioni((prev) => ({
                            ...prev,
                            data_inizio_desiderata: event.target.value,
                          }));
                      
                        }}
                        /> 
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <DateInput validate={required()} source="data_fine_desiderata" label="Data di fine" locales="it-IT" fullWidth
                    defaultValue={localIscrizioni?.data_fine_desiderata || ""}
                    onChange={(event) => {                           
                      setLocalIscrizioni((prev) => ({
                          ...prev,
                          data_fine_desiderata: event.target.value,
                        }));                    
                      }}/>
                </Box>        
            </Box>

            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <SelectInput label="Tipologia di iscrizione"
                        source="tipo_preiscrizione" validate={required()}
                        choices={[{ id: '0', name: 'Abbonamento 3gg/settimana' },{  id: '1', name: 'Abbonamento 4gg/settimana' },{ id: '2', name: 'Abbonamento 5gg/settimana' },{ id: '3', name: 'Carnet 40ore' } ]}
                        options={{fullWidth:true}} sx={{textAlign: "left"}} fullWidth                         
                        defaultValue={localIscrizioni?.tipo_preiscrizione || ""}     
                        onChange={(event) => {                                      
                                setLocalIscrizioni((prev) => ({
                                  ...prev,
                                  tipo_preiscrizione: event.target.value,
                                }));
                              }}                        
                         />  
                </Box> 
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                        <SelectInput label="ISEE"
                        source="isee_preiscrizione" validate={required()}
                        choices={[{ id: '0', name: 'Fascia Esenzione' },{ id: '1', name: 'Fascia 0,00 € - 3.900,00 €' },{  id: '2', name: 'Fascia 3.900,01 € - 5.000,00 €' },{  id: '3', name: 'Fascia 5.000,01 € - 6.100,00 €' },{  id: '4', name: 'Fascia 6.100,01 € - 7.200,00 €' },{  id: '5', name: 'Fascia 7.200,01 € - 8.400,00 €' },
                        {  id: '6', name: 'Fascia 8.400,01 € - 9.500,00 €' },{  id: '7', name: 'Fascia 9.500,01 € - 10.600,00 €' },{  id: '8', name: 'Fascia  10.600,01 € -  11.700,00 €' },{  id: '9', name: 'Fascia 11.700,01 € - 12.800,00 €' },{  id: '10', name: 'Fascia 12.800,01 € - 13.900,00 €' },
                        {  id: '11', name: 'Fascia 13.900,01 € - 15.000,00 €' },{  id: '12', name: 'Fascia 15.000,01 € - 16.200,00 €' },{  id: '13', name: 'Fascia 16.200,01 € - 17.300,00 €' },{  id: '14', name: 'Fascia 17.300,01 € - 18.400,00 €' },{  id: '15', name: 'Fascia 18.400,01 € - 19.500,00 €' },
                        {  id: '16', name: 'Fascia 19.500,01 € - 20.600,00 €' },{  id: '17', name: 'Fascia 20.600,01 € - 21.800,00 €' },{  id: '18', name: 'Fascia 21.800,01 € - 22.900,00 €' },{  id: '19', name: 'Fascia 22.900,01 € - 24.000,00 €' },{  id: '20', name: 'Fascia 24.000,01 € - 27.500,00 €' },
                        {  id: '21', name: 'Fascia 27.500,01 € - 31.000,00 €' },{  id: '22', name: 'Fascia 31.000,01 € - 34.500,00 €' },{  id: '23', name: 'Fascia 34.500,01 € - 38.000,00 €' },{  id: '24', name: 'Fascia 38.000,01 € - 999.000,00 €' },{  id: '25', name: 'Dato Non Disponibile' } ]}
                        options={{fullWidth:true}} sx={{textAlign: "left"}} fullWidth 
                        defaultValue={localIscrizioni?.isee_preiscrizione || ""}   
                        onChange={(event) => {                                      
                            setLocalIscrizioni((prev) => ({
                              ...prev,
                              isee_preiscrizione: event.target.value,
                            }));
                          }} 
                        />  
                </Box>  
            </Box>    
            {localIscrizioni.isee_preiscrizione && localIscrizioni.tipo_preiscrizione ? (
                <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>                                   
                        

                        {localIscrizioni.tipo_preiscrizione!=3 ?(
                            //console.log("tariffe",tariffe,selectedTitoloValue,selectedISEEValue,tariffe[selectedISEEValue][selectedTitoloValue]),
                        <div style={{display:'flex', flexDirection: 'column'}}>
                            <Typography variant="h7" gutterBottom> Costo del Servizio selezionato</Typography>  
                            <Typography variant="h7" gutterBottom>   - Costo dell'abbonamento (primo mese): € {calcolaCostoPrimoMese(tariffe[localIscrizioni.isee_preiscrizione][localIscrizioni.tipo_preiscrizione],
                                contaGiorniLavorativi(moment(localIscrizioni.data_inizio_desiderata).format("YYYY"), moment(localIscrizioni.data_inizio_desiderata).format("MM")), 
                                contaGiorniLavorativiRimanenti(moment(localIscrizioni.data_inizio_desiderata).format("YYYY"), moment(localIscrizioni.data_inizio_desiderata).format("MM"), localIscrizioni.data_inizio_desiderata))
                                } </Typography> 
                            <Typography variant="h7" gutterBottom>   - Costo dell'abbonamento (mesi seguenti): € {tariffe[localIscrizioni.isee_preiscrizione][localIscrizioni.tipo_preiscrizione]},00 </Typography> 
                            <Typography variant="h7" gutterBottom>  </Typography>  
                        </div>) :(
                            <div style={{display:'flex', flexDirection: 'column'}}>
                            <Typography variant="h7" gutterBottom> Costo del Servizio selezionato</Typography>  
                            <Typography variant="h7" gutterBottom>   - Costo del carnet: € {tariffe[localIscrizioni.isee_preiscrizione][localIscrizioni.tipo_preiscrizione]},00 </Typography>  
                            <Typography variant="h7" gutterBottom>  </Typography>  
                            </div>
                        )}
                </Box> ):(<></>)
            }
         

            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                
                <Box key={"0700"} display="grid" gridTemplateColumns="repeat(5, 1fr)" gap="1em" width="100%">
                    <Box display="flex" flexDirection="column" >
                        <Typography sx={{textAlign:"left"}} variant="h7" gutterBottom>Lunedì</Typography>
                        <SelectInput sx={{textAlign: "left"}}  source="inizio_lunedi_desiderata" label="Lunedì Inizio" options={{fullWidth:true}} fullWidth
                            choices={choicesLunedìInizio} disabled={choicesLunedìInizio.length==0 || isDisabilitato("lunedi") ? true:false}
                            defaultValue={localIscrizioni?.inizio_lunedi_desiderata || ""}     
                            onChange={(event) => {                                      
                                    setLocalIscrizioni((prev) => ({
                                      ...prev,
                                      inizio_lunedi_desiderata: event.target.value,
                                    }));
                                  }}                                                     
                            />                        
                        <SelectInput sx={{textAlign: "left"}}  source="fine_lunedi_desiderata" label="Lunedì Fine" options={{fullWidth:true}} fullWidth
                            choices={choicesLunedìFine} disabled={choicesLunedìFine.length==0 || isDisabilitato("lunedi") ? true:false} 
                            defaultValue={localIscrizioni?.fine_lunedi_desiderata || ""}     
                            onChange={(event) => {                                      
                                    setLocalIscrizioni((prev) => ({
                                      ...prev,
                                      fine_lunedi_desiderata: event.target.value,
                                    }));
                                  }}                        />                         
                    </Box>  
                    <Box display="flex" flexDirection="column" >
                        <Typography sx={{textAlign:"left"}} variant="h7" gutterBottom>Martedì</Typography>
                        <SelectInput sx={{textAlign: "left"}}  source="inizio_martedi_desiderata" label="Martedì Inizio" options={{fullWidth:true}} fullWidth
                            choices={choicesMartediInizio} disabled={choicesMartediInizio.length==0 || isDisabilitato("martedi") ? true:false}
                            defaultValue={localIscrizioni?.inizio_martedi_desiderata || ""}     
                            onChange={(event) => {                                      
                                    setLocalIscrizioni((prev) => ({
                                      ...prev,
                                      inizio_martedi_desiderata: event.target.value,
                                    }));
                                  }}   
                            />
                        <SelectInput sx={{textAlign: "left"}}  source="fine_martedi_desiderata" label="Martedì Fine" options={{fullWidth:true}} fullWidth
                            choices={choicesMartediFine} disabled={choicesMartediFine.length==0 || isDisabilitato("martedi")  ? true:false}
                            defaultValue={localIscrizioni?.fine_martedi_desiderata || ""}     
                            onChange={(event) => {                                      
                                    setLocalIscrizioni((prev) => ({
                                      ...prev,
                                      fine_martedi_desiderata: event.target.value,
                                    }));
                                  }}  
                            />                    
                    </Box>  
                    <Box display="flex" flexDirection="column" >
                        <Typography sx={{textAlign:"left"}} variant="h7" gutterBottom>Mercoledì</Typography>
                        <SelectInput sx={{textAlign: "left"}}  source="inizio_mercoledi_desiderata" label="Mercoledì Inizio" options={{fullWidth:true}} fullWidth
                            choices={choicesMercolediInizio} disabled={choicesMercolediInizio.length==0 || isDisabilitato("mercoledi") ? true:false}
                            defaultValue={localIscrizioni?.inizio_mercoledi_desiderata || ""}     
                            onChange={(event) => {                                      
                                    setLocalIscrizioni((prev) => ({
                                      ...prev,
                                      inizio_mercoledi_desiderata: event.target.value,
                                    }));
                                  }}  
                            />  
                        <SelectInput sx={{textAlign: "left"}}  source="fine_mercoledi_desiderata" label="Mercoledì Fine" options={{fullWidth:true}} fullWidth
                            choices={choicesMercolediFine} disabled={choicesMercolediFine.length==0 || isDisabilitato("mercoledi") ? true:false}
                            defaultValue={localIscrizioni?.fine_mercoledi_desiderata || ""}     
                            onChange={(event) => {                                      
                                    setLocalIscrizioni((prev) => ({
                                      ...prev,
                                      fine_mercoledi_desiderata: event.target.value,
                                    }));
                                  }}  
                            />                           
                    </Box>  
                    <Box display="flex" flexDirection="column" >
                        <Typography sx={{textAlign:"left"}} variant="h7" gutterBottom>Giovedì</Typography>
                        <SelectInput sx={{textAlign: "left"}}  source="inizio_giovedi_desiderata" label="Giovedì Inizio" options={{fullWidth:true}} fullWidth
                            choices={choicesGiovediInizio} disabled={choicesGiovediInizio.length==0 || isDisabilitato("giovedi") ? true:false}
                            defaultValue={localIscrizioni?.inizio_giovedi_desiderata || ""}     
                            onChange={(event) => {                                      
                                    setLocalIscrizioni((prev) => ({
                                      ...prev,
                                      inizio_giovedi_desiderata: event.target.value,
                                    }));
                                  }}
                            />    
                        <SelectInput sx={{textAlign: "left"}}  source="fine_giovedi_desiderata" label="Giovedì Fine" options={{fullWidth:true}} fullWidth
                            choices={choicesGiovediFine} disabled={choicesGiovediFine.length==0 || isDisabilitato("giovedi") ? true:false}
                            defaultValue={localIscrizioni?.fine_giovedi_desiderata || ""}     
                            onChange={(event) => {                                      
                                    setLocalIscrizioni((prev) => ({
                                      ...prev,
                                      fine_giovedi_desiderata: event.target.value,
                                    }));
                                  }}
                            />                                                
                    </Box>  
                    <Box display="flex" flexDirection="column" >
                        <Typography sx={{textAlign:"left"}} variant="h7" gutterBottom>Venerdì</Typography>
                        <SelectInput sx={{textAlign: "left"}}  source="inizio_venerdi_desiderata" label="Venerdì Inizio" options={{fullWidth:true}} fullWidth
                            choices={choicesVenerdiInizio} disabled={choicesVenerdiInizio.length==0 || isDisabilitato("venerdi") ? true:false}
                            defaultValue={localIscrizioni?.inizio_venerdi_desiderata || ""}     
                            onChange={(event) => {                                      
                                    setLocalIscrizioni((prev) => ({
                                      ...prev,
                                      inizio_venerdi_desiderata: event.target.value,
                                    }));
                                  }}
                            />      
                        <SelectInput sx={{textAlign: "left"}}  source="fine_venerdi_desiderata" label="Venerdì Fine" options={{fullWidth:true}} fullWidth
                            choices={choicesVenerdiFine} disabled={choicesVenerdiFine.length==0 || isDisabilitato("venerdi") ? true:false}
                            defaultValue={localIscrizioni?.fine_venerdi_desiderata || ""}     
                            onChange={(event) => {                                      
                                    setLocalIscrizioni((prev) => ({
                                      ...prev,
                                      fine_venerdi_desiderata: event.target.value,
                                    }));
                                  }}
                            />                           
                    </Box>          
                </Box>
            </Box>         
      
            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>    
                <SelectInput sx={{textAlign: "left"}}  source="disponibilita_cambio_orario_sede" validate={required()} options={{fullWidth:true}} fullWidth label="Disponibilità cambio sede/orario"
                    choices={[{ id: 'Si', name: 'Si' },{ id: 'No', name: 'No' } ]}
                    defaultValue={localIscrizioni?.disponibilita_cambio_orario_sede || ""}     
                    onChange={(event) => {                                      
                                    setLocalIscrizioni((prev) => ({
                                      ...prev,
                                      disponibilita_cambio_orario_sede: event.target.value,
                                    }));
                                  }}
                    /> 
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                <SelectInput sx={{textAlign: "left"}}  source="adempimenti_richiesti"  options={{fullWidth:true}} validate={required()} fullWidth label="Adempimenti richiesti"
                    choices={[{ id: 'Si', name: 'Si' },{ id: 'No', name: 'No' } ]}
                    defaultValue={localIscrizioni?.adempimenti_richiesti || ""}     
                    onChange={(event) => {                                      
                                    setLocalIscrizioni((prev) => ({
                                      ...prev,
                                      adempimenti_richiesti: event.target.value,
                                    }));
                                  }}
                    />                 
                </Box>        
            </Box>  

            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>        
                    <SelectInput sx={{textAlign: "left"}}  source="in_attesa_servizio_educativo_tradizionale" validate={required()} options={{fullWidth:true}} fullWidth label="In attesa del servizio educativo tradizionale"
                        choices={[{ id: 'Si', name: 'Si' },{ id: 'No', name: 'No' } ]}
                        defaultValue={localIscrizioni?.in_attesa_servizio_educativo_tradizionale || ""}     
                        onChange={(event) => {                                      
                                        setLocalIscrizioni((prev) => ({
                                          ...prev,
                                          in_attesa_servizio_educativo_tradizionale: event.target.value,
                                        }));
                                      }}
                        /> 
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <SelectInput sx={{textAlign: "left"}}  source="privacy_preiscrizione"  options={{fullWidth:true}} validate={required()} fullWidth label="Liberatoria Privacy"
                        choices={[{ id: 'Si', name: 'Si' },{ id: 'No', name: 'No' } ]}
                        defaultValue={localIscrizioni?.privacy_preiscrizione || ""}     
                        onChange={(event) => {                                      
                                        setLocalIscrizioni((prev) => ({
                                          ...prev,
                                          privacy_preiscrizione: event.target.value,
                                        }));
                                      }}
                        /> 
                </Box>        
            </Box>
            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>                        
                  <TextInput source="sponsor" label="Come ha conosciuto il servizio" fullWidth
                   defaultValue={localIscrizioni?.sponsor || ""}     
                   onChange={(event) => {                                      
                                   setLocalIscrizioni((prev) => ({
                                     ...prev,
                                     sponsor: event.target.value,
                                   }));
                                 }}
                  />              
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
               
                </Box>        
            </Box>
            </Box>
    ):(
        <></>
    )        
    }


    {localIscrizioni != null && localIscrizioni.stato_iscrizione_id  == "2" ? (  //ISCRIZIONE 
     <Box display={{ width: '100%',  alignItems: "flex-start", textAlign: "left"  }}>
           <Typography variant="h6" gutterBottom>
                    Dati Iscrizione
           </Typography>  
           <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}> 
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}> 
                    <DateInput validate={required()} source="data_inizio_scelta" label="Data di inizio" locales="it-IT" fullWidth                     
                        defaultValue={localIscrizioni?.data_inizio_scelta || ""}                    
                        onChange={(event) => {                                                   
                            setLocalIscrizioni((prev) => ({
                            ...prev,
                            data_inizio_scelta : event.target.value,
                        }));
                        }}
                        /> 
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <DateInput validate={required()} source="data_fine_scelta" label="Data di fine" locales="it-IT" fullWidth
                      defaultValue={localIscrizioni?.data_fine_scelta || ""}                    
                      onChange={(event) => {                                                   
                          setLocalIscrizioni((prev) => ({
                          ...prev,
                          data_fine_scelta : event.target.value,
                      }));
                      }}
                      />
                </Box>        
            </Box>
            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <SelectInput label="Tipologia di iscrizione"
                        source="tipo_iscrizione" validate={required()}
                        choices={[{ id: '0', name: 'Abbonamento 3gg/settimana' },{  id: '1', name: 'Abbonamento 4gg/settimana' },{ id: '2', name: 'Abbonamento 5gg/settimana' },{ id: '3', name: 'Carnet 40ore' } ]}
                        options={{fullWidth:true}} sx={{textAlign: "left"}} fullWidth 
                        defaultValue={localIscrizioni?.tipo_iscrizione || ""}     
                        onChange={(event) => {                                      
                            setLocalIscrizioni((prev) => ({
                              ...prev,
                              tipo_iscrizione: event.target.value,
                            }));
                          }}  
                        
                        />  
                </Box> 
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                        <SelectInput label="ISEE"
                        source="isee" validate={required()}
                        choices={[{ id: '0', name: 'Fascia Esenzione' },{ id: '1', name: 'Fascia 0,00 € - 3.900,00 €' },{  id: '2', name: 'Fascia 3.900,01 € - 5.000,00 €' },{  id: '3', name: 'Fascia 5.000,01 € - 6.100,00 €' },{  id: '4', name: 'Fascia 6.100,01 € - 7.200,00 €' },{  id: '5', name: 'Fascia 7.200,01 € - 8.400,00 €' },
                        {  id: '6', name: 'Fascia 8.400,01 € - 9.500,00 €' },{  id: '7', name: 'Fascia 9.500,01 € - 10.600,00 €' },{  id: '8', name: 'Fascia  10.600,01 € -  11.700,00 €' },{  id: '9', name: 'Fascia 11.700,01 € - 12.800,00 €' },{  id: '10', name: 'Fascia 12.800,01 € - 13.900,00 €' },
                        {  id: '11', name: 'Fascia 13.900,01 € - 15.000,00 €' },{  id: '12', name: 'Fascia 15.000,01 € - 16.200,00 €' },{  id: '13', name: 'Fascia 16.200,01 € - 17.300,00 €' },{  id: '14', name: 'Fascia 17.300,01 € - 18.400,00 €' },{  id: '15', name: 'Fascia 18.400,01 € - 19.500,00 €' },
                        {  id: '16', name: 'Fascia 19.500,01 € - 20.600,00 €' },{  id: '17', name: 'Fascia 20.600,01 € - 21.800,00 €' },{  id: '18', name: 'Fascia 21.800,01 € - 22.900,00 €' },{  id: '19', name: 'Fascia 22.900,01 € - 24.000,00 €' },{  id: '20', name: 'Fascia 24.000,01 € - 27.500,00 €' },
                        {  id: '21', name: 'Fascia 27.500,01 € - 31.000,00 €' },{  id: '22', name: 'Fascia 31.000,01 € - 34.500,00 €' },{  id: '23', name: 'Fascia 34.500,01 € - 38.000,00 €' },{  id: '24', name: 'Fascia 38.000,01 € - 999.000,00 €' }]}                        
                        options={{fullWidth:true}} sx={{textAlign: "left"}} fullWidth 
                        defaultValue={localIscrizioni?.isee || ""}  
                        onChange={(event) => {                                      
                            setLocalIscrizioni((prev) => ({
                              ...prev,
                              isee: event.target.value,
                            }));
                          }} 
                        />  
                </Box>  
            </Box>    
            {localIscrizioni.isee && localIscrizioni.tipo_iscrizione ? (
                //console.log("perchè"),
                <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>                                   
                        
                        {localIscrizioni.tipo_iscrizione!=3 ?(
                            //console.log("tariffe",tariffe,selectedTitoloValue,selectedISEEValue,tariffe[selectedISEEValue][selectedTitoloValue]),
                        <div style={{display:'flex', flexDirection: 'column'}}>
                            <Typography variant="h7" gutterBottom> Costo del Servizio selezionato</Typography>  
                            <Typography variant="h7" gutterBottom>   - Costo dell'abbonamento (primo mese): € {calcolaCostoPrimoMese(tariffe[localIscrizioni.isee][localIscrizioni.tipo_iscrizione],
                                contaGiorniLavorativi(moment(localIscrizioni.data_inizio_scelta).format("YYYY"), moment(localIscrizioni.data_inizio_scelta).format("MM")), 
                                contaGiorniLavorativiRimanenti(moment(localIscrizioni.data_inizio_scelta).format("YYYY"), moment(localIscrizioni.data_inizio_scelta).format("MM"), localIscrizioni.data_inizio_scelta))
                                } </Typography>                              
                            <Typography variant="h7" gutterBottom>   - Costo dell'abbonamento (mesi seguenti): € {tariffe[localIscrizioni.isee][localIscrizioni.tipo_iscrizione]},00 </Typography> 
                            <Typography variant="h7" gutterBottom>  </Typography>  
                        </div>) :(
                            <div style={{display:'flex', flexDirection: 'column'}}>
                            <Typography variant="h7" gutterBottom> Costo del Servizio selezionato</Typography>  
                            <Typography variant="h7" gutterBottom>   - Costo del carnet: € {tariffe[localIscrizioni.isee][localIscrizioni.tipo_iscrizione]},00 </Typography>  
                            <Typography variant="h7" gutterBottom>  </Typography>  
                            </div>
                        )}
                </Box> ):(<></>)
            }
            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>                
                <Box key={"0700"} display="grid" gridTemplateColumns="repeat(5, 1fr)" gap="1em" width="100%">
                    <Box display="flex" flexDirection="column" >
                        <Typography sx={{textAlign:"left"}} variant="h7" gutterBottom>Lunedì</Typography>
                        <SelectInput sx={{textAlign: "left"}}  source="inizio_lunedi_effettiva" label="Lunedì Inizio" options={{fullWidth:true}} fullWidth
                            choices={choicesLunedìInizio} disabled={choicesLunedìInizio.length==0 || isDisabilitatoIscrizione("lunedi")? true:false}
                            defaultValue={localIscrizioni?.inizio_lunedi_effettiva || ""}     
                            onChange={(event) => {                                      
                                    setLocalIscrizioni((prev) => ({
                                      ...prev,
                                      inizio_lunedi_effettiva: event.target.value,
                                    }));
                                  }}
                            />    
                        <SelectInput sx={{textAlign: "left"}}  source="fine_lunedi_effettiva" label="Lunedì Fine" options={{fullWidth:true}} fullWidth
                            choices={choicesLunedìFine} disabled={choicesLunedìFine.length==0 || isDisabilitatoIscrizione("lunedi")? true:false}
                            defaultValue={localIscrizioni?.fine_lunedi_effettiva || ""}     
                            onChange={(event) => {                                      
                                    setLocalIscrizioni((prev) => ({
                                      ...prev,
                                      fine_lunedi_effettiva: event.target.value,
                                    }));
                                  }}
                            />     
                    </Box>      
                    <Box display="flex" flexDirection="column" >
                        <Typography sx={{textAlign:"left"}} variant="h7" gutterBottom>Martedì</Typography>
                        <SelectInput sx={{textAlign: "left"}}  source="inizio_martedi_effettiva" label="Martedì Inizio" options={{fullWidth:true}} fullWidth
                            choices={choicesMartediInizio} disabled={choicesMartediInizio.length==0 || isDisabilitatoIscrizione("martedi")? true:false}
                            defaultValue={localIscrizioni?.inizio_martedi_effettiva || ""}     
                            onChange={(event) => {                                      
                                    setLocalIscrizioni((prev) => ({
                                      ...prev,
                                      inizio_martedi_effettiva: event.target.value,
                                    }));
                                  }}
                            />                   
                        <SelectInput sx={{textAlign: "left"}}  source="fine_martedi_effettiva" label="Martedì Fine" options={{fullWidth:true}} fullWidth
                            choices={choicesMartediFine} disabled={choicesMartediFine.length==0 || isDisabilitatoIscrizione("martedi")? true:false}
                            defaultValue={localIscrizioni?.fine_martedi_effettiva || ""}     
                            onChange={(event) => {                                      
                                    setLocalIscrizioni((prev) => ({
                                      ...prev,
                                      fine_martedi_effettiva: event.target.value,
                                    }));
                                  }}
                            />       
                    </Box>
                    <Box display="flex" flexDirection="column" >
                        <Typography sx={{textAlign:"left"}} variant="h7" gutterBottom>Mercoledì</Typography>
                        <SelectInput sx={{textAlign: "left"}}  source="inizio_mercoledi_effettiva" label="Mercoledì Inizio" options={{fullWidth:true}} fullWidth
                            choices={choicesMercolediInizio} disabled={choicesMercolediInizio.length==0 || isDisabilitatoIscrizione("mercoledi")? true:false}
                            defaultValue={localIscrizioni?.inizio_mercoledi_effettiva || ""}     
                            onChange={(event) => {                                      
                                    setLocalIscrizioni((prev) => ({
                                      ...prev,
                                      inizio_mercoledi_effettiva: event.target.value,
                                    }));
                                  }}
                            />    
                        <SelectInput sx={{textAlign: "left"}}  source="fine_mercoledi_effettiva" label="Mercoledì Fine" options={{fullWidth:true}} fullWidth
                            choices={choicesMercolediFine} disabled={choicesMercolediFine.length==0 || isDisabilitatoIscrizione("mercoledi")? true:false}
                            defaultValue={localIscrizioni?.fine_mercoledi_effettiva || ""}     
                            onChange={(event) => {                                      
                                    setLocalIscrizioni((prev) => ({
                                      ...prev,
                                      fine_mercoledi_effettiva: event.target.value,
                                    }));
                                  }}
                            />     
                    </Box>
                    <Box display="flex" flexDirection="column" >
                        <Typography sx={{textAlign:"left"}} variant="h7" gutterBottom>Giovedì</Typography>
                        <SelectInput sx={{textAlign: "left"}}  source="inizio_giovedi_effettiva" label="Giovedì Inizio" options={{fullWidth:true}} fullWidth
                            choices={choicesGiovediInizio} disabled={choicesGiovediInizio.length==0 || isDisabilitatoIscrizione("giovedi")? true:false}
                            defaultValue={localIscrizioni?.inizio_giovedi_effettiva || ""}     
                            onChange={(event) => {                                      
                                    setLocalIscrizioni((prev) => ({
                                      ...prev,
                                      inizio_giovedi_effettiva: event.target.value,
                                    }));
                                  }}
                            />    
                    <SelectInput sx={{textAlign: "left"}}  source="fine_giovedi_effettiva" label="Giovedì Fine" options={{fullWidth:true}} fullWidth
                            choices={choicesGiovediFine} disabled={choicesGiovediFine.length==0 || isDisabilitatoIscrizione("giovedi")? true:false}
                            defaultValue={localIscrizioni?.fine_giovedi_effettiva || ""}     
                            onChange={(event) => {                                      
                                    setLocalIscrizioni((prev) => ({
                                      ...prev,
                                      fine_giovedi_effettiva: event.target.value,
                                    }));
                                  }}
                            />  
                    </Box>
                    <Box display="flex" flexDirection="column" >
                        <Typography sx={{textAlign:"left"}} variant="h7" gutterBottom>Venerdì</Typography>
                        <SelectInput sx={{textAlign: "left"}}  source="inizio_venerdi_effettiva" label="Venerdì Inizio" options={{fullWidth:true}} fullWidth
                            choices={choicesVenerdiInizio} disabled={choicesVenerdiInizio.length==0 || isDisabilitatoIscrizione("venerdi")? true:false}
                            defaultValue={localIscrizioni?.inizio_venerdi_effettiva || ""}     
                            onChange={(event) => {                                      
                                    setLocalIscrizioni((prev) => ({
                                      ...prev,
                                      inizio_venerdi_effettiva: event.target.value,
                                    }));
                                  }}
                            />    
                        <SelectInput sx={{textAlign: "left"}}  source="fine_venerdi_effettiva" label="Venerdì Fine" options={{fullWidth:true}} fullWidth
                            choices={choicesVenerdiFine} disabled={choicesVenerdiFine.length==0 || isDisabilitatoIscrizione("venerdi")? true:false}
                            defaultValue={localIscrizioni?.fine_venerdi_effettiva || ""}     
                            onChange={(event) => {                                      
                                    setLocalIscrizioni((prev) => ({
                                      ...prev,
                                      fine_venerdi_effettiva: event.target.value,
                                    }));
                                  }}
                            />     
                    </Box>                
                </Box>              
            </Box> 
            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>                            
                    <DateInput source="isee_data_sottoscrizione" validate={required()}  label="Data sottoscrizione ISEE" locales="it-IT" fullWidth
                     defaultValue={localIscrizioni?.isee_data_sottoscrizione || ""}     
                     onChange={(event) => {                                      
                         setLocalIscrizioni((prev) => ({
                           ...prev,
                           isee_data_sottoscrizione: event.target.value,
                         }));
                       }}  
                    
                    />                                          
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="indicatore_isee" validate={required()}  label="Indicatore ISEE (€)" fullWidth
                     defaultValue={localIscrizioni?.indicatore_isee || ""}  
                     onChange={(event) => {                                      
                         setLocalIscrizioni((prev) => ({
                           ...prev,
                           indicatore_isee: event.target.value,
                         }));
                       }} 
                    />                  
                </Box>        
            </Box>  

            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>   
                    <SelectInput sx={{textAlign: "left"}}  source="disponibilita_cambio_orario_sede" validate={required()} options={{fullWidth:true}} fullWidth label="Disponibilità cambio sede/orario"
                        choices={[{ id: 'Si', name: 'Si' },{ id: 'No', name: 'No' } ]}
                        defaultValue={localIscrizioni?.disponibilita_cambio_orario_sede || ""}  
                        onChange={(event) => {                                      
                            setLocalIscrizioni((prev) => ({
                            ...prev,
                            disponibilita_cambio_orario_sede: event.target.value,
                            }));
                        }} />                      
                  
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <SelectInput sx={{textAlign: "left"}}  source="patto_educativo"  options={{fullWidth:true}} validate={required()} fullWidth label="Patto educativo"
                        choices={[{ id: 'Si', name: 'Si' },{ id: 'No', name: 'No' } ]}
                        defaultValue={localIscrizioni?.patto_educativo || ""}  
                        onChange={(event) => {                                      
                            setLocalIscrizioni((prev) => ({
                              ...prev,
                              patto_educativo: event.target.value,
                            }));
                          }} 
                        />                                                                            
                </Box>        
            </Box>
            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>        
                    <SelectInput sx={{textAlign: "left"}}  source="privacy_iscrizione"  options={{fullWidth:true}} validate={required()} fullWidth label="Liberatoria Privacy"
                        choices={[{ id: 'Si', name: 'Si' },{ id: 'No', name: 'No' } ]}
                        defaultValue={localIscrizioni?.privacy_iscrizione || ""}  
                        onChange={(event) => {                                      
                            setLocalIscrizioni((prev) => ({
                              ...prev,
                              privacy_iscrizione: event.target.value,
                            }));
                          }} 
                        />                   
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                <SelectInput sx={{textAlign: "left"}}  source="fragilita_economica"  options={{fullWidth:true}} validate={required()} fullWidth label="Eventuale fragilità economica"
                        choices={[{ id: 'Si', name: 'Si' },{ id: 'No', name: 'No' } ]}
                        defaultValue={localIscrizioni?.fragilita_economica || ""}  
                        onChange={(event) => {                                      
                            setLocalIscrizioni((prev) => ({
                              ...prev,
                              fragilita_economica: event.target.value,
                            }));
                          }} 
                        />                                                                        
                    
                </Box>        
            </Box>
            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <SelectInput sx={{textAlign: "left"}}  source="liberatoria_immagini" validate={required()} options={{fullWidth:true}} fullWidth label="Liberatoria per Immagini"
                        choices={[{ id: 'Si', name: 'Si' },{ id: 'No', name: 'No' } ]}
                        defaultValue={localIscrizioni?.liberatoria_immagini || ""}  
                        onChange={(event) => {                                      
                            setLocalIscrizioni((prev) => ({
                              ...prev,
                              liberatoria_immagini: event.target.value,
                            }));
                          }} 
                        />                
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <SelectInput sx={{textAlign: "left"}}  source="somministrazione_farmaci" validate={required()} options={{fullWidth:true}} fullWidth label="Autorizzazione somministrazione farmaci"
                        choices={[{ id: 'Si', name: 'Si' },{ id: 'No', name: 'No' } ]}
                        defaultValue={localIscrizioni?.somministrazione_farmaci || ""}  
                        onChange={(event) => {                                      
                            setLocalIscrizioni((prev) => ({
                              ...prev,
                              somministrazione_farmaci: event.target.value,
                            }));
                          }} 
                        />   
                </Box>        
            </Box>     
            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <SelectInput sx={{textAlign: "left"}}  source="in_attesa_servizio_educativo_tradizionale" validate={required()} options={{fullWidth:true}} fullWidth label="In attesa del servizio educativo tradizionale"
                        choices={[{ id: 'Si', name: 'Si' },{ id: 'No', name: 'No' } ]}
                        defaultValue={localIscrizioni?.in_attesa_servizio_educativo_tradizionale || ""}  
                        onChange={(event) => {                                      
                            setLocalIscrizioni((prev) => ({
                              ...prev,
                              in_attesa_servizio_educativo_tradizionale: event.target.value,
                            }));
                          }} 
                        />  

                    
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                <SelectInput sx={{textAlign: "left"}}  source="delega_minori" validate={required()} options={{fullWidth:true}} fullWidth label="Delega ritiro minori"
                        choices={[{ id: 'Si', name: 'Si' },{ id: 'No', name: 'No' } ]}
                        defaultValue={localIscrizioni?.delega_minori || ""}  
                        onChange={(event) => {                                      
                            setLocalIscrizioni((prev) => ({
                              ...prev,
                              delega_minori: event.target.value,
                            }));
                          }} 
                        />                
                    
                </Box>        
            </Box>  
            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>                
                <SelectInput sx={{textAlign: "left"}}  validate={required()} source="autorizzazione_uscite"  options={{fullWidth:true}} fullWidth label="Autorizzazione uscite sul territorio"
                        choices={[{ id: 'Si', name: 'Si' },{ id: 'No', name: 'No' } ]}
                        defaultValue={localIscrizioni?.autorizzazione_uscite || ""}  
                        onChange={(event) => {                                      
                            setLocalIscrizioni((prev) => ({
                              ...prev,
                              autorizzazione_uscite: event.target.value,
                            }));
                          }} 
                        />                
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                <SelectInput sx={{textAlign: "left"}}  validate={required()} source="fratelli_sorelle"  options={{fullWidth:true}} fullWidth label="Eventuali fratelli o sorelle"
                        choices={[{ id: 'Si', name: 'Si' },{ id: 'No', name: 'No' } ]}
                        defaultValue={localIscrizioni?.fratelli_sorelle || ""}  
                        onChange={(event) => {                                      
                            setLocalIscrizioni((prev) => ({
                              ...prev,
                              fratelli_sorelle: event.target.value,
                            }));
                          }} 
                        />                                                          
                </Box>        
            </Box>          
            
            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <TextInput source="recapito_telefonico" label="Ulteriore recapito telefonico" fullWidth
                     defaultValue={localIscrizioni?.recapito_telefonico || ""}  
                     onChange={(event) => {                                      
                         setLocalIscrizioni((prev) => ({
                           ...prev,
                           recapito_telefonico: event.target.value,
                         }));
                       }} 
                    />                    
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <TextInput source="sponsor" label="Come ha conosciuto il servizio" fullWidth
                      defaultValue={localIscrizioni?.sponsor || ""}  
                      onChange={(event) => {                                      
                          setLocalIscrizioni((prev) => ({
                            ...prev,
                            sponsor: event.target.value,
                          }));
                        }} 
                    />                                                                                                    
                </Box>        
            </Box>               
     </Box>
    ):
    (
        <></>
    )        
    }

   
    <Typography variant="h6" gutterBottom>
                    Note
    </Typography>
    
    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>        
        <TextInput source="note_pagamenti" label="Note relative ai Pagamenti" fullWidth
        defaultValue={localIscrizioni?.note_pagamenti || ""}  
        onChange={(event) => {                                      
            setLocalIscrizioni((prev) => ({
              ...prev,
              note_pagamenti: event.target.value,
            }));
          }} 
        />                  
    </Box>    

    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>        
        <TextInput source="note" label="Altre note" fullWidth
         defaultValue={localIscrizioni?.note || ""}  
         onChange={(event) => {                                      
             setLocalIscrizioni((prev) => ({
               ...prev,
               note: event.target.value,
             }));
           }} 
        />            
    </Box>     
      

    </SimpleForm>
  </Edit> );
};


//----------------------------------------------------------------------------------------------------------------------------------------------------------
//----------------------------------------------------------------------------------------------------------------------------------------------------------
//----------------------------------------------------------------------------------------------------------------------------------------------------------
//----------------------------------------------------------------------------------------------------------------------------------------------------------


const transform2 = (data) => {  
  const sanitizedData = {};
  for (const key in data) {    
      sanitizedData[key] = data[key]; 
  }
  if(data.stato_iscrizione_id =='2'){        
    sanitizedData["data_fine_desiderata"]=data["data_fine_scelta"]; 
    sanitizedData["data_inizio_desiderata"]=data["data_inizio_scelta"]; 
    sanitizedData["isee_preiscrizione"]=data["isee_iscrizione"]; 
    sanitizedData["privacy_preiscrizione"]=data["privacy_iscrizione"]; 
    sanitizedData["tipo_preiscrizione"]=data["tipo_iscrizione"]; 
    sanitizedData["isee_preiscrizione"]=data["isee_iscrizione"]; 
    sanitizedData["inizio_lunedi_desiderata"]=data["inizio_lunedi_effettiva"]; 
    sanitizedData["inizio_martedi_desiderata"]=data["inizio_martedi_effettiva"]; 
    sanitizedData["inizio_mercoledi_desiderata"]=data["inizio_mercoledi_effettiva"]; 
    sanitizedData["inizio_giovedi_desiderata"]=data["inizio_giovedi_effettiva"]; 
    sanitizedData["inizio_venerdi_desiderata"]=data["inizio_venerdi_effettiva"]; 
    sanitizedData["fine_lunedi_desiderata"]=data["fine_lunedi_effettiva"]; 
    sanitizedData["fine_martedi_desiderata"]=data["fine_martedi_effettiva"]; 
    sanitizedData["fine_mercoledi_desiderata"]=data["fine_mercoledi_effettiva"]; 
    sanitizedData["fine_giovedi_desiderata"]=data["fine_giovedi_effettiva"]; 
    sanitizedData["fine_venerdi_desiderata"]=data["fine_venerdi_effettiva"];
  }
  sanitizedData["creation_time"]=getTime();  
  //sanitizedData["edit_time"]=new Date().toISOString().slice(0, 19).replace('T', ' ');  
  sanitizedData["edit_time"]=getTime();    
  return sanitizedData; 
};







  export const IscrizioneCreate = ({ onSuccess, ...props }) => {

//DA DDD

    const notify = useNotify();  
    const redirect = useRedirect();
    const dataProvider = useDataProvider();  
    const refresh = useRefresh(); 

    const [localIscrizioni, setLocalIscrizioni] = useState({});
        
    const [choicesLunedìInizio, setChoicesLunedìInizio] = useState([]);      
    const [choicesLunedìFine, setChoicesLunedìFine] = useState([]);      
    const [choicesMartediInizio, setChoicesMartediInizio] = useState([]);      
    const [choicesMartediFine, setChoicesMartediFine] = useState([]);      
    const [choicesMercolediInizio, setChoicesMercolediInizio] = useState([]);      
    const [choicesMercolediFine, setChoicesMercolediFine] = useState([]);      
    const [choicesGiovediInizio, setChoicesGiovediInizio] = useState([]);      
    const [choicesGiovediFine, setChoicesGiovediFine] = useState([]);      
    const [choicesVenerdiInizio, setChoicesVenerdiInizio] = useState([]);      
    const [choicesVenerdiFine, setChoicesVenerdiFine] = useState([]);      
       
    const giorniDisponibili = {
        "0": 3, // Abbonamento 3gg
        "1": 4, // Abbonamento 4gg
        "2": 5, // Abbonamento 5gg    
    };

    const giorni = ["lunedi", "martedi", "mercoledi", "giovedi", "venerdi"];

    const giorniCompilati = giorni.filter(giorno => 
        localIscrizioni?.[`inizio_${giorno}_desiderata`] || 
        localIscrizioni?.[`fine_${giorno}_desiderata`]
    ).length;

    const isDisabilitato = (giorno) => {
        const maxGiorni = giorniDisponibili[localIscrizioni?.tipo_preiscrizione] || 5;
        const giornoSelezionato = localIscrizioni?.[`inizio_${giorno}_desiderata`] || 
                              localIscrizioni?.[`fine_${giorno}_desiderata`];

        if (giorniCompilati >= maxGiorni) {
            if (!giornoSelezionato) {
                return true;
            }
        
            const giorniSelezionati = giorni.filter(giorno => 
                localIscrizioni?.[`inizio_${giorno}_desiderata`] || 
                localIscrizioni?.[`fine_${giorno}_desiderata`]
            );

            if (giorniSelezionati.length > maxGiorni) {
                const ultimoGiorno = giorniSelezionati[giorniSelezionati.length - 1];
                if (giorno === ultimoGiorno) {                                
                    return true;
                }
            }
        }
        return false;    
    };

const giorniCompilatiIscrizione = giorni.filter(giorno => 
    localIscrizioni?.[`inizio_${giorno}_effettiva`] || 
    localIscrizioni?.[`fine_${giorno}_effettiva`]
).length;

const isDisabilitatoIscrizione = (giorno) => {
   const maxGiorni = giorniDisponibili[localIscrizioni?.tipo_iscrizione] || 5;
   
    const giornoSelezionato = localIscrizioni?.[`inizio_${giorno}_effettiva`] || 
                              localIscrizioni?.[`fine_${giorno}_effettiva`];

    if (giorniCompilatiIscrizione >= maxGiorni) {
        if (!giornoSelezionato) {
            return true;
        }
        
        const giorniSelezionatiIscrizione = giorni.filter(giorno => 
            localIscrizioni?.[`inizio_${giorno}_effettiva`] || 
            localIscrizioni?.[`fine_${giorno}_effettiva`]
        );

        if (giorniSelezionatiIscrizione.length > maxGiorni) {
            const ultimoGiorno = giorniSelezionatiIscrizione[giorniSelezionatiIscrizione.length - 1];
            if (giorno === ultimoGiorno) {                                
                return true;
            }
        }
    }
    return false;    
};


    useEffect(() => {  
      console.log("UE-1");
      /*if (iscrizioni) {
          console.log("STATO ID",iscrizioni.stato_iscrizione_id);
          setLocalIscrizioni({ ...iscrizioni });         
          //setSelectedValue(iscrizioni.stato_iscrizione_id);
          if(iscrizioni.stato_iscrizione_id==1){
              //setSelectedTitoloValue(iscrizioni.tipo_preiscrizione);
              //setSelectedISEEValue(iscrizioni.isee_preiscrizione);
              //setDataInizio(iscrizioni.data_inizio_desiderata)
          }
          else if(iscrizioni.stato_iscrizione_id==3){
              console.log("TRE");
              //setSelectedTitoloValue(iscrizioni.tipo_preiscrizione);
              //setSelectedISEEValue(iscrizioni.isee_preiscrizione);
              //setDataInizio(iscrizioni.data_inizio_desiderata)
          }
          else if(iscrizioni.stato_iscrizione_id==4){
              console.log("QUATTRO");
              //setSelectedTitoloValue(iscrizioni.tipo_preiscrizione);
              //setSelectedISEEValue(iscrizioni.isee_preiscrizione);
              //setDataInizio(iscrizioni.data_inizio_desiderata)
          }
          else{
              //setSelectedTitoloValue(iscrizioni.tipo_iscrizione);
              //setSelectedISEEValue(iscrizioni.isee);    
              //setDataInizio(iscrizioni.data_inizio_scelta);
          }         
      }
      if(slots!=undefined){
          slots.forEach(slot => {   
              var choices_init=[];         
              var choices_end=[];                                   
              if(slot['0700']!=0){
                      choices_init.push({id: '07:00', name: '07:00' });
              }
              if(slot['0730']!=0){
                  choices_init.push({id: '07:30', name: '07:30' });
                  choices_end.push({id: '07:30', name: '07:30' });
              }
              else{
                  if(choices_init.length!=0)
                      choices_end.push({id: '07:30', name: '07:30' });
              }
              if(slot['0800']!=0){
                  choices_init.push({id: '08:00', name: '08:00' });
                  choices_end.push({id: '08:00', name: '08:00' })
              }
              else{
                  if(choices_init.length!=0)                    
                      choices_end.push({id: '08:00', name: '08:00' });
              }
              if(slot['0830']!=0){
                  choices_init.push({id: '08:30', name: '08:30' });
                  choices_end.push({id: '08:30', name: '08:30' });
              }
              else{
                  if(choices_init.length!=0)                    
                      choices_end.push({id: '08:30', name: '08:30' });
              }
              if(slot['0900']!=0){
                  choices_init.push({id: '09:00', name: '09:00' });
                  choices_end.push({id: '09:00', name: '09:00' });
              }
              else{
                  if(choices_init.length!=0)                    
                      choices_end.push({id: '09:00', name: '09:00' })
              }
              if(slot['0930']!=0){
                  choices_init.push({id: '09:30', name: '09:30' });
                  choices_end.push({id: '09:30', name: '09:30' })
              }
              else{
                  if(choices_init.length!=0)                    
                  choices_end.push({id: '09:30', name: '09:30' })
              }
              if(slot['1000']!=0){
                  choices_init.push({id: '10:00', name: '10:00' });
                  choices_end.push({id: '10:00', name: '10:00' })
              }
              else{
                  if(choices_init.length!=0)                    
                      choices_end.push({id: '10:00', name: '10:00' })
              }
              if(slot['1030']!=0){
                  choices_init.push({id: '10:30', name: '10:30' });
                  choices_end.push({id: '10:30', name: '10:30' })
              }
              else{
                  if(choices_init.length!=0)                    
                      choices_end.push({id: '10:30', name: '10:30' })
              }
              if(slot['1100']!=0){
                  choices_init.push({id: '11:00', name: '11:00' });
                  choices_end.push({id: '11:00', name: '11:00' })
              }
              else{
                  if(choices_init.length!=0)                    
                      choices_end.push({id: '11:00', name: '11:00' })
              }
              if(slot['1130']!=0){
                  choices_init.push({id: '11:30', name: '11:30' });
                  choices_end.push({id: '11:30', name: '11:30' })
              }
              else{
                  if(choices_init.length!=0)                    
                      choices_end.push({id: '11:30', name: '11:30' })
              }
              if(slot['1200']!=0){
                  choices_init.push({id: '12:00', name: '12:00' });
                  choices_end.push({id: '12:00', name: '12:00' })
              }
              else{
                  if(choices_init.length!=0)                    
                  choices_end.push({id: '12:00', name: '12:00' })
              }
              if(slot['1230']!=0){
                  choices_init.push({id: '12:30', name: '12:30' });
                  choices_end.push({id: '12:30', name: '12:30' })
              }
              else{
                  if(choices_init.length!=0)                    
                      choices_end.push({id: '12:30', name: '12:30' })
              }
              if(slot['1300']!=0){
                  choices_init.push({id: '13:00', name: '13:00' });
                  choices_end.push({id: '13:00', name: '13:00' })
              }
              else{
                  if(choices_init.length!=0)                    
                      choices_end.push({id: '13:00', name: '13:00' })
              }
              if(slot['1330']!=0){
                  choices_init.push({id: '13:30', name: '13:30' });
                  choices_end.push({id: '13:30', name: '13:30' })
              }
              else{
                  if(choices_init.length!=0)                    
                      choices_end.push({id: '13:30', name: '13:30' })
              }
              if(slot['1400']!=0){
                  choices_init.push({id: '14:00', name: '14:00' });
                  choices_end.push({id: '14:00', name: '14:00' })
              }
              else{
                  if(choices_init.length!=0)                    
                      choices_end.push({id: '14:00', name: '14:00' })
              }
              if(slot['1430']!=0){
                  choices_init.push({id: '14:30', name: '14:30' });
                  choices_end.push({id: '14:30', name: '14:30' })
              }
              else{
                  if(choices_init.length!=0)                    
                      choices_end.push({id: '14:30', name: '14:30' })
              }
              if(slot['1500']!=0){
                  choices_init.push({id: '15:00', name: '15:00' });
                  choices_end.push({id: '15:00', name: '15:00' })
              }
              else{
                  if(choices_init.length!=0)                    
                      choices_end.push({id: '15:00', name: '15:00' })
              }
              if(slot['1530']!=0){
                  choices_init.push({id: '15:30', name: '15:30' });
                  choices_end.push({id: '15:30', name: '15:30' })
              }
              else{
                  if(choices_init.length!=0)                    
                      choices_end.push({id: '15:30', name: '15:30' })
              }
              if(slot['1600']!=0){
                  choices_init.push({id: '16:00', name: '16:00' });
                  choices_end.push({id: '16:00', name: '16:00' })
              }
              else{
                  if(choices_init.length!=0)                    
                      choices_end.push({id: '16:00', name: '16:00' })
              }
              if(slot['1630']!=0){
                  choices_init.push({id: '16:30', name: '16:30' });
                  choices_end.push({id: '16:30', name: '16:30' })
              }
              else{
                  if(choices_init.length!=0)                    
                      choices_end.push({id: '16:30', name: '16:30' })
              }
              if(slot['1700']!=0){
                  choices_init.push({id: '17:00', name: '17:00' });
                  choices_end.push({id: '17:00', name: '17:00' })
              }
              else{
                  if(choices_init.length!=0)                    
                      choices_end.push({id: '17:00', name: '17:00' })
              }
              if(slot['1730']!=0){
                  choices_init.push({id: '17:30', name: '17:30' });
                  choices_end.push({id: '17:30', name: '17:30' })
              }
              else{
                  if(choices_init.length!=0)                    
                      choices_end.push({id: '17:30', name: '17:30' })
              }
              if(slot['1800']!=0){
                  choices_init.push({id: '18:00', name: '18:00' });
                  choices_end.push({id: '18:00', name: '18:00' })
              }
              else{
                  if(choices_init.length!=0)                    
                      choices_end.push({id: '18:00', name: '18:00' })
              }
              if(slot['1830']!=0){
                  choices_init.push({id: '18:30', name: '18:30' });
                  choices_end.push({id: '18:30', name: '18:30' })
              }
              else{
                  if(choices_init.length!=0)                    
                      choices_end.push({id: '18:30', name: '18:30' })
              }
              if(slot['1900']!=0){
                  choices_init.push({id: '19:00', name: '19:00' });
                  choices_end.push({id: '19:00', name: '19:00' })
              }
              else{
                  if(choices_init.length!=0)                    
                      choices_end.push({id: '19:00', name: '19:00' });
              }
              if(slot['1930']!=0){                
                  choices_end.push({id: '19:30', name: '19:30' });
              }            
              if (slot.giorno =='lunedì'){
                  //console.log("lunedì trovato");
                  setChoicesLunedìInizio(choices_init);
                  setChoicesLunedìFine(choices_end);             
              }
              if (slot.giorno =='martedì'){
                  //console.log("martedì trovato");
                  setChoicesMartediInizio(choices_init);
                  setChoicesMartediFine(choices_end);
              }
              if (slot.giorno =='mercoledì'){
                  //console.log("mercoledì trovato");
                  setChoicesMercolediInizio(choices_init);
                  setChoicesMercolediFine(choices_end);
              }
              if (slot.giorno =='giovedì'){
                  //console.log("giovedì trovato");
                  setChoicesGiovediInizio(choices_init);
                  setChoicesGiovediFine(choices_end);
              }
              if (slot.giorno =='venerdì'){
                  //console.log("venerdì trovato");
                  setChoicesVenerdiInizio(choices_init);
                  setChoicesVenerdiFine(choices_end);
              }
              if (slot.giorno =='sabato'){
                  //console.log("sabato trovato");
                  setChoicesSabatoInizio(choices_init);
                  setChoicesSabatoFine(choices_end);
              }        
             });
      }*/
},[]);//[iscrizioni,slots]);
  

useEffect(() => {
  console.log("UE-2");
  
}, [ ]);



useEffect(() => {
 console.log("USEEFFECT", localIscrizioni);
  
 let maxGiorni = giorniDisponibili[localIscrizioni?.tipo_preiscrizione] || 5;
 if(localIscrizioni.stato_iscrizione_id=="1"){ //PREISCRIZIONE
        const giorniSelezionati = giorni.filter(giorno => 
            localIscrizioni?.[`inizio_${giorno}_desiderata`] || 
            localIscrizioni?.[`fine_${giorno}_desiderata`]
        );
        console.log("BAH PRE",giorniSelezionati.length , maxGiorni);
        if (giorniSelezionati.length > maxGiorni) {
            const ultimoGiorno = giorniSelezionati[giorniSelezionati.length - 1];
            console.log("UTLIMO G",ultimoGiorno)
            // Se c'è un giorno selezionato che è oltre il limite, resetta il suo valore
            setLocalIscrizioni(prev => {
                const newIscrizioni = { ...prev };
                newIscrizioni[`inizio_${ultimoGiorno}_desiderata`] = null;
                newIscrizioni[`fine_${ultimoGiorno}_desiderata`] = null;
                return newIscrizioni;
            });
        }
  }
  maxGiorni = giorniDisponibili[localIscrizioni?.tipo_iscrizione] || 5;
  if(localIscrizioni.stato_iscrizione_id=="2"){ //ISCRIZIONE
        const giorniSelezionatiIscrizione = giorni.filter(giorno => 
            localIscrizioni?.[`inizio_${giorno}_effettiva`] || 
            localIscrizioni?.[`fine_${giorno}_effettiva`]
        );
        console.log("BAH ISCR",giorniSelezionatiIscrizione.length , maxGiorni);
        if (giorniSelezionatiIscrizione.length > maxGiorni) {
            const ultimoGiorno = giorniSelezionatiIscrizione[giorniSelezionatiIscrizione.length - 1];
            console.log("UTLIMO GGGG",ultimoGiorno)
            // Se c'è un giorno selezionato che è oltre il limite, resetta il suo valore
            setLocalIscrizioni(prev => {
                const newIscrizioni = { ...prev };
                newIscrizioni[`inizio_${ultimoGiorno}_effettiva`] = null;
                newIscrizioni[`fine_${ultimoGiorno}_effettiva`] = null;
                return newIscrizioni;
            });
        }
  }
  
}, [localIscrizioni]);

const onChanging = (value) => { //CAMBIO ENTE
  console.log("REC",value);
  setChoicesLunedìInizio([]);      
  setChoicesLunedìFine([]);      
  setChoicesMartediInizio([]);      
  setChoicesMartediFine([]);      

  setChoicesMercolediInizio([]);      
  setChoicesMercolediFine([]);      
  setChoicesGiovediInizio([]);      
  setChoicesGiovediFine([]);      

  setChoicesVenerdiInizio([]);      
  setChoicesVenerdiFine([]);      
    
  try {
      dataProvider.getManyReference("slots", {
          target: "ente_id",
          id: value,
          pagination: { page: 1, perPage: 1000 },
          sort: { field: "giorno", order: "ASC" }
        })
        .then(({ data }) => {
          console.log("Dati ricevuti:", data);
           
          if(data!=undefined){
          data.forEach(slot => {   
              var choices_init=[];         
              var choices_end=[];                                   
              if(slot['0700']!=0){
                      choices_init.push({id: '07:00', name: '07:00' });
              }
              if(slot['0730']!=0){
                  choices_init.push({id: '07:30', name: '07:30' });
                  choices_end.push({id: '07:30', name: '07:30' });
              }
              else{
                  if(choices_init.length!=0)
                      choices_end.push({id: '07:30', name: '07:30' });
              }
              if(slot['0800']!=0){
                  choices_init.push({id: '08:00', name: '08:00' });
                  choices_end.push({id: '08:00', name: '08:00' })
              }
              else{
                  if(choices_init.length!=0)                    
                      choices_end.push({id: '08:00', name: '08:00' });
              }
              if(slot['0830']!=0){
                  choices_init.push({id: '08:30', name: '08:30' });
                  choices_end.push({id: '08:30', name: '08:30' });
              }
              else{
                  if(choices_init.length!=0)                    
                      choices_end.push({id: '08:30', name: '08:30' });
              }
              if(slot['0900']!=0){
                  choices_init.push({id: '09:00', name: '09:00' });
                  choices_end.push({id: '09:00', name: '09:00' });
              }
              else{
                  if(choices_init.length!=0)                    
                      choices_end.push({id: '09:00', name: '09:00' })
              }
              if(slot['0930']!=0){
                  choices_init.push({id: '09:30', name: '09:30' });
                  choices_end.push({id: '09:30', name: '09:30' })
              }
              else{
                  if(choices_init.length!=0)                    
                  choices_end.push({id: '09:30', name: '09:30' })
              }
              if(slot['1000']!=0){
                  choices_init.push({id: '10:00', name: '10:00' });
                  choices_end.push({id: '10:00', name: '10:00' })
              }
              else{
                  if(choices_init.length!=0)                    
                      choices_end.push({id: '10:00', name: '10:00' })
              }
              if(slot['1030']!=0){
                  choices_init.push({id: '10:30', name: '10:30' });
                  choices_end.push({id: '10:30', name: '10:30' })
              }
              else{
                  if(choices_init.length!=0)                    
                      choices_end.push({id: '10:30', name: '10:30' })
              }
              if(slot['1100']!=0){
                  choices_init.push({id: '11:00', name: '11:00' });
                  choices_end.push({id: '11:00', name: '11:00' })
              }
              else{
                  if(choices_init.length!=0)                    
                      choices_end.push({id: '11:00', name: '11:00' })
              }
              if(slot['1130']!=0){
                  choices_init.push({id: '11:30', name: '11:30' });
                  choices_end.push({id: '11:30', name: '11:30' })
              }
              else{
                  if(choices_init.length!=0)                    
                      choices_end.push({id: '11:30', name: '11:30' })
              }
              if(slot['1200']!=0){
                  choices_init.push({id: '12:00', name: '12:00' });
                  choices_end.push({id: '12:00', name: '12:00' })
              }
              else{
                  if(choices_init.length!=0)                    
                  choices_end.push({id: '12:00', name: '12:00' })
              }
              if(slot['1230']!=0){
                  choices_init.push({id: '12:30', name: '12:30' });
                  choices_end.push({id: '12:30', name: '12:30' })
              }
              else{
                  if(choices_init.length!=0)                    
                      choices_end.push({id: '12:30', name: '12:30' })
              }
              if(slot['1300']!=0){
                  choices_init.push({id: '13:00', name: '13:00' });
                  choices_end.push({id: '13:00', name: '13:00' })
              }
              else{
                  if(choices_init.length!=0)                    
                      choices_end.push({id: '13:00', name: '13:00' })
              }
              if(slot['1330']!=0){
                  choices_init.push({id: '13:30', name: '13:30' });
                  choices_end.push({id: '13:30', name: '13:30' })
              }
              else{
                  if(choices_init.length!=0)                    
                      choices_end.push({id: '13:30', name: '13:30' })
              }
              if(slot['1400']!=0){
                  choices_init.push({id: '14:00', name: '14:00' });
                  choices_end.push({id: '14:00', name: '14:00' })
              }
              else{
                  if(choices_init.length!=0)                    
                      choices_end.push({id: '14:00', name: '14:00' })
              }
              if(slot['1430']!=0){
                  choices_init.push({id: '14:30', name: '14:30' });
                  choices_end.push({id: '14:30', name: '14:30' })
              }
              else{
                  if(choices_init.length!=0)                    
                      choices_end.push({id: '14:30', name: '14:30' })
              }
              if(slot['1500']!=0){
                  choices_init.push({id: '15:00', name: '15:00' });
                  choices_end.push({id: '15:00', name: '15:00' })
              }
              else{
                  if(choices_init.length!=0)                    
                      choices_end.push({id: '15:00', name: '15:00' })
              }
              if(slot['1530']!=0){
                  choices_init.push({id: '15:30', name: '15:30' });
                  choices_end.push({id: '15:30', name: '15:30' })
              }
              else{
                  if(choices_init.length!=0)                    
                      choices_end.push({id: '15:30', name: '15:30' })
              }
              if(slot['1600']!=0){
                  choices_init.push({id: '16:00', name: '16:00' });
                  choices_end.push({id: '16:00', name: '16:00' })
              }
              else{
                  if(choices_init.length!=0)                    
                      choices_end.push({id: '16:00', name: '16:00' })
              }
              if(slot['1630']!=0){
                  choices_init.push({id: '16:30', name: '16:30' });
                  choices_end.push({id: '16:30', name: '16:30' })
              }
              else{
                  if(choices_init.length!=0)                    
                      choices_end.push({id: '16:30', name: '16:30' })
              }
              if(slot['1700']!=0){
                  choices_init.push({id: '17:00', name: '17:00' });
                  choices_end.push({id: '17:00', name: '17:00' })
              }
              else{
                  if(choices_init.length!=0)                    
                      choices_end.push({id: '17:00', name: '17:00' })
              }
              if(slot['1730']!=0){
                  choices_init.push({id: '17:30', name: '17:30' });
                  choices_end.push({id: '17:30', name: '17:30' })
              }
              else{
                  if(choices_init.length!=0)                    
                      choices_end.push({id: '17:30', name: '17:30' })
              }
              if(slot['1800']!=0){
                  choices_init.push({id: '18:00', name: '18:00' });
                  choices_end.push({id: '18:00', name: '18:00' })
              }
              else{
                  if(choices_init.length!=0)                    
                      choices_end.push({id: '18:00', name: '18:00' })
              }
              if(slot['1830']!=0){
                  choices_init.push({id: '18:30', name: '18:30' });
                  choices_end.push({id: '18:30', name: '18:30' })
              }
              else{
                  if(choices_init.length!=0)                    
                      choices_end.push({id: '18:30', name: '18:30' })
              }
              if(slot['1900']!=0){
                  choices_init.push({id: '19:00', name: '19:00' });
                  choices_end.push({id: '19:00', name: '19:00' })
              }
              else{
                  if(choices_init.length!=0)                    
                      choices_end.push({id: '19:00', name: '19:00' });
              }
              if(slot['1930']!=0){                
                  choices_end.push({id: '19:30', name: '19:30' });
              }            
              if (slot.giorno =='lunedì'){
                  //console.log("lunedì trovato");
                  setChoicesLunedìInizio(choices_init);
                  setChoicesLunedìFine(choices_end);             
              }
              if (slot.giorno =='martedì'){
                  //console.log("martedì trovato");
                  setChoicesMartediInizio(choices_init);
                  setChoicesMartediFine(choices_end);
              }
              if (slot.giorno =='mercoledì'){
                  //console.log("mercoledì trovato");
                  setChoicesMercolediInizio(choices_init);
                  setChoicesMercolediFine(choices_end);
              }
              if (slot.giorno =='giovedì'){
                  //console.log("giovedì trovato");
                  setChoicesGiovediInizio(choices_init);
                  setChoicesGiovediFine(choices_end);
              }
              if (slot.giorno =='venerdì'){
                  //console.log("venerdì trovato");
                  setChoicesVenerdiInizio(choices_init);
                  setChoicesVenerdiFine(choices_end);
              }
             
             });
          }
        })
        .catch(error => console.error("Errore nel recupero dati:", error));                     
} 
catch (error) {
  console.log("EERR22",error);
  notify("Qualcosa è andato storto nella creazione dell'iscrizione. Riprovare più tardi");
}
};


const onChangingStato = (event) => {
  //console.log("STATO-1", event, localIscrizioni);
  //console.log("STATO", event, localIscrizioni.stato_iscrizione_id);

  setLocalIscrizioni((prev) => {
      if (!prev) return prev;

      let newIscrizioni = { ...prev };
      
      if (event == "1") {          
          newIscrizioni = {
              ...newIscrizioni,
              stato_iscrizione_id:"1",
          };            
      }
      if (event == "2") {          
          newIscrizioni = {
              ...newIscrizioni,
              stato_iscrizione_id:"2",
              data_inizio_scelta:null,     
              data_fine_scelta:null,
              tipo_iscrizione:null,
              isee:null,
              inizio_lunedi_effettiva: null,
              inizio_martedi_effettiva: null,
              inizio_mercoledi_effettiva: null,
              inizio_giovedi_effettiva: null,
              inizio_venerdi_effettiva: null,
              fine_lunedi_effettiva: null,
              fine_martedi_effettiva: null,
              fine_mercoledi_effettiva: null,
              fine_giovedi_effettiva: null,
              fine_venerdi_effettiva: null,
          };            
      }
      if (event == "4") {
          
          newIscrizioni = {
              ...newIscrizioni,
              stato_iscrizione_id:event,
          };
      }

      if ( event == "3") {          
          newIscrizioni = {
              ...newIscrizioni,
              stato_iscrizione_id:event,
          };
      }          
      return newIscrizioni;
  });
  
  console.log("CAMBIO STATO", localIscrizioni);
  setLocalIscrizioni((newState) => {
      console.log("CAMBIO STATO NEW", newState);
      return newState;
  });
};

//AA DDD

    const handleCreate = async (values) => {
        try {    
            let maxGiorni = giorniDisponibili[values.tipo_preiscrizione] || 5;
            let giorniSelezionati = giorni.filter(giorno => 
              values?.[`inizio_${giorno}_desiderata`] || 
              values?.[`fine_${giorno}_desiderata`]
            );
            console.log("PEER BAHCCO PRE",giorniSelezionati.length , maxGiorni);
            const sanitizedData = {};
            for (const key in values) {          
                  sanitizedData[key] = values[key];             
            }     
            if(localIscrizioni.stato_iscrizione_id=="1"){ //PREISCRIZIONE
            while(giorniSelezionati.length > maxGiorni){
                if (giorniSelezionati.length > maxGiorni) {        
                    console.log("PEER BAHCCO2 ",giorniSelezionati , maxGiorni);
                    const ultimoGiorno = giorniSelezionati[giorniSelezionati.length - 1];
                    console.log("PEER BAHCCO3 ",ultimoGiorno);
                    console.log("ULTIMO GIO",values, ultimoGiorno,`inizio_${ultimoGiorno}_desiderata`);          
                    for (const key in sanitizedData) {
                        if(key==`inizio_${ultimoGiorno}_desiderata`)
                        sanitizedData[key] = null;
                        else if(key==`fine_${ultimoGiorno}_desiderata`)
                        sanitizedData[key] = null;        
                    }             
                    giorniSelezionati.pop();
                    console.log("PEER BAHCCO4 ", giorniSelezionati);
                }
            }
            }
            else if(localIscrizioni.stato_iscrizione_id=="2"){ //ISCRIZIONE
            maxGiorni = giorniDisponibili[localIscrizioni?.tipo_iscrizione] || 5;
            let giorniSelezionatiIscrizione = giorni.filter(giorno => 
                values?.[`inizio_${giorno}_effettiva`] || 
                values?.[`fine_${giorno}_effettiva`]
            );
            console.log("PEER BAHCCO ISCR",giorniSelezionatiIscrizione.length , maxGiorni);
                
            while(giorniSelezionatiIscrizione.length > maxGiorni){
                  if (giorniSelezionatiIscrizione.length > maxGiorni) {        
                      console.log("PEER BAHCCO2 ",giorniSelezionatiIscrizione , maxGiorni);
                      const ultimoGiorno = giorniSelezionatiIscrizione[giorniSelezionatiIscrizione.length - 1];
                      console.log("PEER BAHCCO3 ",ultimoGiorno);
                      console.log("ULTIMO GIO",values, ultimoGiorno,`inizio_${ultimoGiorno}_effettiva`);          
                      for (const key in sanitizedData) {
                          if(key==`inizio_${ultimoGiorno}_effettiva`)
                          sanitizedData[key] = null;
                          else if(key==`fine_${ultimoGiorno}_effettiva`)
                          sanitizedData[key] = null;        
                  } 
                  giorniSelezionatiIscrizione.pop();
                  console.log("PEER BAHCCO4 ", giorniSelezionatiIscrizione);
              }
            }
            }
            console.log("EDEE",sanitizedData);
            var data2=transform2(sanitizedData);

            console.log("Editing",data2)
            //const { data } = await dataProvider.update('iscrizioni', { id:data2.id, data: data2 });                    
            //notify("Dati aggiornati con successo!", { messageArgs: { smart_count: 1 } });
            //refresh();
            const { data } = await dataProvider.create('iscrizioni', { data: data2 });                
            console.log('Resource created successfully:', data, values);     
            notify("Iscrizione registrata correttamente!", { messageArgs: { smart_count: 1 } });
            refresh();
            redirect('/iscrizioni');        
            //redirect('/iscrizioni');                        
        } catch (error) {
            if (error?.status) {
                switch (error.status) {
                    case 400:
                        notify("Dati non validi. ", { type: 'warning' });
                        break;
                    case 401:
                        notify("Non autorizzato.", { type: 'error' });
                        break;
                    case 403:
                        notify("Accesso negato.", { type: 'error' });
                        break;
                    case 407:
                        notify("Sei troppo avanti nel tempo.", { type: 'error' });
                        break;
                    case 412:                    
                        const jsonObject = JSON.parse(JSON.stringify(error, null, 2));
                        //console.log("PD",jsonObject.body.error.message);                    
                        notify(jsonObject.body.error.message, { type: 'error' });
                        break;
                    case 500:
                        notify("Errore interno del server.", { type: 'error' });
                        break;
                    default:
                        notify(`Errore ${error.body.status}: Si è verificato un problema.`, { type: 'error' });
                }
            } else {
                notify(`Qualcosa è andato storto nella creazione dell'iscrizione. Riprovare più tardi`, { type: 'error' });
            }
          }
        //AA
    };

    const formatTime = (input) => {
        const digits = input.replace(/\D/g, "");
        if (digits.length <= 2) return digits;
        return `${digits.slice(0, 2)}:${digits.slice(2, 4)}`;
      };

      const slotNonSelezionato = (inizio, fine) => {
        //console.log("slot non selezionato",inizio, fine,(!inizio && !fine));
        if (!inizio && !fine) return true; 
            return false;
      };

      const calcolaDifferenzaMinuti = (inizio, fine) => {
        
        if (!inizio || !fine) return 500; 
        inizio = inizio.replace(".", ":");
        fine = fine.replace(".", ":");
            
        const [h1, m1] = inizio.split(":").map(Number);
        const [h2, m2] = fine.split(":").map(Number);
    
        const minutiInizio = h1 * 60 + m1;
        const minutiFine = h2 * 60 + m2;            
        return minutiFine - minutiInizio;
    };
      
  
      const validateDurationFields = (values) => {
        const errors = {};
        console.log("VALID",values);

        if(values.stato_iscrizione_id=="1"){ //PREISCRIZIONE
            console.log("PRE",values);
            
            if(!slotNonSelezionato (values.inizio_lunedi_desiderata, values.fine_lunedi_desiderata)){       
                if(calcolaDifferenzaMinuti(values.inizio_lunedi_desiderata, values.fine_lunedi_desiderata)<180 ||calcolaDifferenzaMinuti(values.inizio_lunedi_desiderata, values.fine_lunedi_desiderata)>300){
                    errors.inizio_lunedi_desiderata="La durata deve essere compresa tra 3 ore e 5 ore";
                    errors.fine_lunedi_desiderata="La durata deve essere compresa tra 3 ore e 5 ore";  
                }
            }
            
            if(!slotNonSelezionato (values.inizio_martedi_desiderata, values.fine_martedi_desiderata)){
                if(calcolaDifferenzaMinuti(values.inizio_martedi_desiderata, values.fine_martedi_desiderata)<180 ||calcolaDifferenzaMinuti(values.inizio_martedi_desiderata, values.fine_martedi_desiderata)>300){
                    errors.inizio_martedi_desiderata="La durata deve essere compresa tra 3 ore e 5 ore";
                    errors.fine_martedi_desiderata="La durata deve essere compresa tra 3 ore e 5 ore";
                }
            }
            if(!slotNonSelezionato (values.inizio_mercoledi_desiderata, values.fine_mercoledi_desiderata)){
                if(calcolaDifferenzaMinuti(values.inizio_mercoledi_desiderata, values.fine_mercoledi_desiderata)<180 ||calcolaDifferenzaMinuti(values.inizio_mercoledi_desiderata, values.fine_mercoledi_desiderata)>300){
                    errors.inizio_mercoledi_desiderata="La durata deve essere compresa tra 3 ore e 5 ore";
                    errors.fine_mercoledi_desiderata="La durata deve essere compresa tra 3 ore e 5 ore";
                }
            }
            if(!slotNonSelezionato (values.inizio_giovedi_desiderata, values.fine_giovedi_desiderata)){
                if(calcolaDifferenzaMinuti(values.inizio_giovedi_desiderata, values.fine_giovedi_desiderata)<180 ||calcolaDifferenzaMinuti(values.inizio_giovedi_desiderata, values.fine_giovedi_desiderata)>300){
                    errors.inizio_giovedi_desiderata="La durata deve essere compresa tra 3 ore e 5 ore";
                    errors.fine_giovedi_desiderata="La durata deve essere compresa tra 3 ore e 5 ore";
                }
            }
            if(!slotNonSelezionato (values.inizio_venerdi_desiderata, values.fine_venerdi_desiderata)){
                if(calcolaDifferenzaMinuti(values.inizio_venerdi_desiderata, values.fine_venerdi_desiderata)<180 ||calcolaDifferenzaMinuti(values.inizio_venerdi_desiderata, values.fine_venerdi_desiderata)>300){
                    errors.inizio_venerdi_desiderata="La durata deve essere compresa tra 3 ore e 5 ore";
                    errors.fine_venerdi_desiderata="La durata deve essere compresa tra 3 ore e 5 ore";
                }
            }            
            if(values.disponibilita_cambio_orario_sede==""||values.disponibilita_cambio_orario_sede==null)
                errors.disponibilita_cambio_orario_sede="Il campo è obbligatorio";
            if(values.adempimenti_richiesti==""||values.adempimenti_richiesti==null)
                errors.adempimenti_richiesti="Il campo è obbligatorio";
            if(values.in_attesa_servizio_educativo_tradizionale==""||values.in_attesa_servizio_educativo_tradizionale==null)
                errors.in_attesa_servizio_educativo_tradizionale="Il campo è obbligatorio";
            if(values.privacy_preiscrizione==""||values.privacy_preiscrizione==null)
                errors.privacy_preiscrizione="Il campo è obbligatorio";
            if(values.data_inizio_desiderata==""||values.data_inizio_desiderata==null)
                errors.data_inizio_desiderata="Il campo è obbligatorio";
            if(values.data_fine_desiderata==""||values.data_fine_desiderata==null)
                errors.data_fine_desiderata="Il campo è obbligatorio";
            if(values.tipo_preiscrizione==""||values.tipo_preiscrizione==null)
                errors.tipo_preiscrizione="Il campo è obbligatorio";
            if(values.isee_preiscrizione==""||values.isee_preiscrizione==null)
                errors.isee_preiscrizione="Il campo è obbligatorio";

        }
        else if(values.stato_iscrizione_id=="2"){ //ISCRIZIONE
            console.log("ISCR",values);
            if(!slotNonSelezionato (values.inizio_lunedi_effettiva, values.fine_lunedi_effettiva)){       
                if(calcolaDifferenzaMinuti(values.inizio_lunedi_effettiva, values.fine_lunedi_effettiva)<180 ||calcolaDifferenzaMinuti(values.inizio_lunedi_effettiva, values.fine_lunedi_effettiva)>300){
                    errors.inizio_lunedi_effettiva="La durata deve essere compresa tra 3 ore e 5 ore";
                    errors.fine_lunedi_effettiva="La durata deve essere compresa tra 3 ore e 5 ore";  
                }
            }
            
            if(!slotNonSelezionato (values.inizio_martedi_effettiva, values.fine_martedi_effettiva)){
                if(calcolaDifferenzaMinuti(values.inizio_martedi_effettiva, values.fine_martedi_effettiva)<180 ||calcolaDifferenzaMinuti(values.inizio_martedi_effettiva, values.fine_martedi_effettiva)>300){
                    errors.inizio_martedi_effettiva="La durata deve essere compresa tra 3 ore e 5 ore";
                    errors.fine_martedi_effettiva="La durata deve essere compresa tra 3 ore e 5 ore";
                }
            }
            if(!slotNonSelezionato (values.inizio_mercoledi_effettiva, values.fine_mercoledi_effettiva)){
                if(calcolaDifferenzaMinuti(values.inizio_mercoledi_effettiva, values.fine_mercoledi_effettiva)<180 ||calcolaDifferenzaMinuti(values.inizio_mercoledi_effettiva, values.fine_mercoledi_effettiva)>300){
                    errors.inizio_mercoledi_effettiva="La durata deve essere compresa tra 3 ore e 5 ore";
                    errors.fine_mercoledi_effettiva="La durata deve essere compresa tra 3 ore e 5 ore";
                }
            }
            if(!slotNonSelezionato (values.inizio_giovedi_effettiva, values.fine_giovedi_effettiva)){
                if(calcolaDifferenzaMinuti(values.inizio_giovedi_effettiva, values.fine_giovedi_effettiva)<180 ||calcolaDifferenzaMinuti(values.inizio_giovedi_effettiva, values.fine_giovedi_effettiva)>300){
                    errors.inizio_giovedi_effettiva="La durata deve essere compresa tra 3 ore e 5 ore";
                    errors.fine_giovedi_effettiva="La durata deve essere compresa tra 3 ore e 5 ore";
                }
            }
            if(!slotNonSelezionato (values.inizio_venerdi_effettiva, values.fine_venerdi_effettiva)){
                if(calcolaDifferenzaMinuti(values.inizio_venerdi_effettiva, values.fine_venerdi_effettiva)<180 ||calcolaDifferenzaMinuti(values.inizio_venerdi_effettiva, values.fine_venerdi_effettiva)>300){
                    errors.inizio_venerdi_effettiva="La durata deve essere compresa tra 3 ore e 5 ore";
                    errors.fine_venerdi_effettiva="La durata deve essere compresa tra 3 ore e 5 ore";
                }
            }                     
             if(values.disponibilita_cambio_orario_sede==""||values.disponibilita_cambio_orario_sede==null)
                errors.disponibilita_cambio_orario_sede="Il campo è obbligatorio";
            if(values.data_inizio_scelta==""||values.data_inizio_scelta==null)
                errors.data_inizio_scelta="Il campo è obbligatorio";
            if(values.data_fine_scelta==""||values.data_fine_scelta==null)
                errors.data_fine_scelta="Il campo è obbligatorio";
            if(values.tipo_iscrizione==""||values.tipo_iscrizione==null)
                errors.tipo_iscrizione="Il campo è obbligatorio";
            if(values.isee==""||values.isee==null)
                errors.isee="Il campo è obbligatorio";
            if(values.patto_educativo==""||values.patto_educativo==null)
                errors.patto_educativo="Il campo è obbligatorio";
            if(values.in_attesa_servizio_educativo_tradizionale==""||values.in_attesa_servizio_educativo_tradizionale==null)
                errors.in_attesa_servizio_educativo_tradizionale="Il campo è obbligatorio";
            if(values.privacy_iscrizione==""||values.privacy_iscrizione==null)
                errors.privacy_iscrizione="Il campo è obbligatorio";
            if(values.fragilita_economica==""||values.fragilita_economica==null)
                errors.fragilita_economica="Il campo è obbligatorio";
            if(values.liberatoria_immagini==""||values.liberatoria_immagini==null)
                errors.liberatoria_immagini="Il campo è obbligatorio";
            if(values.fratelli_sorelle==""||values.fratelli_sorelle==null)
                errors.fratelli_sorelle="Il campo è obbligatorio";
            if(values.somministrazione_farmaci==""||values.somministrazione_farmaci==null)
                errors.somministrazione_farmaci="Il campo è obbligatorio";
            if(values.delega_minori==""||values.delega_minori==null)
                errors.delega_minori="Il campo è obbligatorio";
            if(values.autorizzazione_uscite==""||values.autorizzazione_uscite==null)
                errors.autorizzazione_uscite="Il campo è obbligatorio";
            if(values.indicatore_isee==""||values.indicatore_isee==null)
                errors.indicatore_isee="Il campo è obbligatorio";
            if(values.isee_data_sottoscrizione==""||values.isee_data_sottoscrizione==null)
                errors.isee_data_sottoscrizione="Il campo è obbligatorio";            
        }
        return errors;  
      };

    
  return(
  <Create transform={transform2}  {...props} >   
    <SimpleForm onSubmit={handleCreate} validate={validateDurationFields} sx={{ maxWidth: '100%' }}>  

     <Typography variant="h6" gutterBottom>
          Dati
    </Typography>       
    
    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <ReferenceInput source="bambino_id" reference="bambini" label="Bambino" perPage={1000} >      
                <SelectInput  validate={required()} option="nome" optionText={(record) => `${record.nome} ${record.cognome} - ${record.codice_fiscale}`}    options={{fullWidth:true}} sx={{textAlign: "left"}} fullWidth  />
            </ReferenceInput> 
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
            <ReferenceInput source="ente_id" reference="enti" label="Ente" perPage={1000}>      
                <SelectInput validate={required()} option="nome" optionText="nome"  options={{fullWidth:true}} sx={{textAlign: "left"}} fullWidth       
                onChange={(event) => {
                //console.log(event.target.value);                    
                    onChanging(event.target.value)}}/> 
            </ReferenceInput> 
        </Box>
    </Box> 
    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
         {/**   <ReferenceInput source="stato_iscrizione_id" reference="stati_iscrizioni" label="Stato Iscrizione" perPage={1000}>      
                <SelectInput choices={[{ id: '1', stato: 'Preiscrizione' },{ id: '2', stato: 'Iscrizione' } ]}   validate={required()} option="stato" optionText="stato" value={localIscrizioni.stato_iscrizione_id}  options={{fullWidth:true}} sx={{textAlign: "left"}} fullWidth                  
                 onChange={(event) => {                                  
                    onChangingStato(event.target.value)
                    }}            
                />
            </ReferenceInput> */} 
            <SelectInput source="stato_iscrizione_id"  label="Stato Iscrizione" choices={[{ id: '1', stato: 'Preiscrizione' },{ id: '2', stato: 'Iscrizione' } ]}   validate={required()} option="stato" optionText="stato" value={localIscrizioni.stato_iscrizione_id}  options={{fullWidth:true}} sx={{textAlign: "left"}} fullWidth                  
                 onChange={(event) => {                                  
                    onChangingStato(event.target.value)
                    }}            
                />
        </Box>   
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
        </Box>             
    </Box> 

    
    {localIscrizioni != null && localIscrizioni.stato_iscrizione_id == "1" ? (  //PREISCRIZIONE    
        <Box display={{ width: '100%',  alignItems: "flex-start", textAlign: "left"  }}>
            <Typography variant="h6" gutterBottom> Dati Pre-Iscrizione </Typography>  

            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <DateInput
                    validate={required()} source="data_inizio_desiderata" label="Data di inizio" locales="it-IT" fullWidth
                    defaultValue={localIscrizioni?.data_inizio_desiderata || ""}
                      onChange={(event) => {                           
                        setLocalIscrizioni((prev) => ({
                            ...prev,
                            data_inizio_desiderata: event.target.value,
                          }));
                      
                        }}
                        /> 
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <DateInput validate={required()} source="data_fine_desiderata" label="Data di fine" locales="it-IT" fullWidth
                    defaultValue={localIscrizioni?.data_fine_desiderata || ""}
                    onChange={(event) => {                           
                      setLocalIscrizioni((prev) => ({
                          ...prev,
                          data_fine_desiderata: event.target.value,
                        }));                    
                      }}/>
                </Box>        
            </Box>

            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <SelectInput label="Tipologia di iscrizione"
                        source="tipo_preiscrizione" validate={required()}
                        choices={[{ id: '0', name: 'Abbonamento 3gg/settimana' },{  id: '1', name: 'Abbonamento 4gg/settimana' },{ id: '2', name: 'Abbonamento 5gg/settimana' },{ id: '3', name: 'Carnet 40ore' } ]}
                        options={{fullWidth:true}} sx={{textAlign: "left"}} fullWidth                         
                        defaultValue={localIscrizioni?.tipo_preiscrizione || ""}     
                        onChange={(event) => {                                      
                                setLocalIscrizioni((prev) => ({
                                  ...prev,
                                  tipo_preiscrizione: event.target.value,
                                }));
                              }}                        
                         />  
                </Box> 
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                        <SelectInput label="ISEE"
                        source="isee_preiscrizione" validate={required()}
                        choices={[{ id: '0', name: 'Fascia Esenzione' },{ id: '1', name: 'Fascia 0,00 € - 3.900,00 €' },{  id: '2', name: 'Fascia 3.900,01 € - 5.000,00 €' },{  id: '3', name: 'Fascia 5.000,01 € - 6.100,00 €' },{  id: '4', name: 'Fascia 6.100,01 € - 7.200,00 €' },{  id: '5', name: 'Fascia 7.200,01 € - 8.400,00 €' },
                        {  id: '6', name: 'Fascia 8.400,01 € - 9.500,00 €' },{  id: '7', name: 'Fascia 9.500,01 € - 10.600,00 €' },{  id: '8', name: 'Fascia  10.600,01 € -  11.700,00 €' },{  id: '9', name: 'Fascia 11.700,01 € - 12.800,00 €' },{  id: '10', name: 'Fascia 12.800,01 € - 13.900,00 €' },
                        {  id: '11', name: 'Fascia 13.900,01 € - 15.000,00 €' },{  id: '12', name: 'Fascia 15.000,01 € - 16.200,00 €' },{  id: '13', name: 'Fascia 16.200,01 € - 17.300,00 €' },{  id: '14', name: 'Fascia 17.300,01 € - 18.400,00 €' },{  id: '15', name: 'Fascia 18.400,01 € - 19.500,00 €' },
                        {  id: '16', name: 'Fascia 19.500,01 € - 20.600,00 €' },{  id: '17', name: 'Fascia 20.600,01 € - 21.800,00 €' },{  id: '18', name: 'Fascia 21.800,01 € - 22.900,00 €' },{  id: '19', name: 'Fascia 22.900,01 € - 24.000,00 €' },{  id: '20', name: 'Fascia 24.000,01 € - 27.500,00 €' },
                        {  id: '21', name: 'Fascia 27.500,01 € - 31.000,00 €' },{  id: '22', name: 'Fascia 31.000,01 € - 34.500,00 €' },{  id: '23', name: 'Fascia 34.500,01 € - 38.000,00 €' },{  id: '24', name: 'Fascia 38.000,01 € - 999.000,00 €' },{  id: '25', name: 'Dato Non Disponibile' } ]}
                        options={{fullWidth:true}} sx={{textAlign: "left"}} fullWidth 
                        defaultValue={localIscrizioni?.isee_preiscrizione || ""}   
                        onChange={(event) => {                                      
                            setLocalIscrizioni((prev) => ({
                              ...prev,
                              isee_preiscrizione: event.target.value,
                            }));
                          }} 
                        />  
                </Box>  
            </Box>    
            {localIscrizioni.isee_preiscrizione && localIscrizioni.tipo_preiscrizione ? (
                <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>                                   
                        

                        {localIscrizioni.tipo_preiscrizione!=3 ?(
                            //console.log("tariffe",tariffe,selectedTitoloValue,selectedISEEValue,tariffe[selectedISEEValue][selectedTitoloValue]),
                        <div style={{display:'flex', flexDirection: 'column'}}>
                            <Typography variant="h7" gutterBottom> Costo del Servizio selezionato</Typography>  
                            <Typography variant="h7" gutterBottom>   - Costo dell'abbonamento (primo mese): € {calcolaCostoPrimoMese(tariffe[localIscrizioni.isee_preiscrizione][localIscrizioni.tipo_preiscrizione],
                                contaGiorniLavorativi(moment(localIscrizioni.data_inizio_desiderata).format("YYYY"), moment(localIscrizioni.data_inizio_desiderata).format("MM")), 
                                contaGiorniLavorativiRimanenti(moment(localIscrizioni.data_inizio_desiderata).format("YYYY"), moment(localIscrizioni.data_inizio_desiderata).format("MM"), localIscrizioni.data_inizio_desiderata))
                                } </Typography> 
                            <Typography variant="h7" gutterBottom>   - Costo dell'abbonamento (mesi seguenti): € {tariffe[localIscrizioni.isee_preiscrizione][localIscrizioni.tipo_preiscrizione]},00 </Typography> 
                            <Typography variant="h7" gutterBottom>  </Typography>  
                        </div>) :(
                            <div style={{display:'flex', flexDirection: 'column'}}>
                            <Typography variant="h7" gutterBottom> Costo del Servizio selezionato</Typography>  
                            <Typography variant="h7" gutterBottom>   - Costo del carnet: € {tariffe[localIscrizioni.isee_preiscrizione][localIscrizioni.tipo_preiscrizione]},00 </Typography>  
                            <Typography variant="h7" gutterBottom>  </Typography>  
                            </div>
                        )}
                </Box> ):(<></>)
            }
         

            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                
                <Box key={"0700"} display="grid" gridTemplateColumns="repeat(5, 1fr)" gap="1em" width="100%">
                    <Box display="flex" flexDirection="column" >
                        <Typography sx={{textAlign:"left"}} variant="h7" gutterBottom>Lunedì</Typography>
                        <SelectInput sx={{textAlign: "left"}}  source="inizio_lunedi_desiderata" label="Lunedì Inizio" options={{fullWidth:true}} fullWidth
                            choices={choicesLunedìInizio} disabled={choicesLunedìInizio.length==0 || isDisabilitato("lunedi") ? true:false}
                            defaultValue={localIscrizioni?.inizio_lunedi_desiderata || ""}     
                            onChange={(event) => {                                      
                                    setLocalIscrizioni((prev) => ({
                                      ...prev,
                                      inizio_lunedi_desiderata: event.target.value,
                                    }));
                                  }}                                                     
                            />                        
                        <SelectInput sx={{textAlign: "left"}}  source="fine_lunedi_desiderata" label="Lunedì Fine" options={{fullWidth:true}} fullWidth
                            choices={choicesLunedìFine} disabled={choicesLunedìFine.length==0 || isDisabilitato("lunedi") ? true:false} 
                            defaultValue={localIscrizioni?.fine_lunedi_desiderata || ""}     
                            onChange={(event) => {                                      
                                    setLocalIscrizioni((prev) => ({
                                      ...prev,
                                      fine_lunedi_desiderata: event.target.value,
                                    }));
                                  }}                        />                         
                    </Box>  
                    <Box display="flex" flexDirection="column" >
                        <Typography sx={{textAlign:"left"}} variant="h7" gutterBottom>Martedì</Typography>
                        <SelectInput sx={{textAlign: "left"}}  source="inizio_martedi_desiderata" label="Martedì Inizio" options={{fullWidth:true}} fullWidth
                            choices={choicesMartediInizio} disabled={choicesMartediInizio.length==0 || isDisabilitato("martedi") ? true:false}
                            defaultValue={localIscrizioni?.inizio_martedi_desiderata || ""}     
                            onChange={(event) => {                                      
                                    setLocalIscrizioni((prev) => ({
                                      ...prev,
                                      inizio_martedi_desiderata: event.target.value,
                                    }));
                                  }}   
                            />
                        <SelectInput sx={{textAlign: "left"}}  source="fine_martedi_desiderata" label="Martedì Fine" options={{fullWidth:true}} fullWidth
                            choices={choicesMartediFine} disabled={choicesMartediFine.length==0 || isDisabilitato("martedi")  ? true:false}
                            defaultValue={localIscrizioni?.fine_martedi_desiderata || ""}     
                            onChange={(event) => {                                      
                                    setLocalIscrizioni((prev) => ({
                                      ...prev,
                                      fine_martedi_desiderata: event.target.value,
                                    }));
                                  }}  
                            />                    
                    </Box>  
                    <Box display="flex" flexDirection="column" >
                        <Typography sx={{textAlign:"left"}} variant="h7" gutterBottom>Mercoledì</Typography>
                        <SelectInput sx={{textAlign: "left"}}  source="inizio_mercoledi_desiderata" label="Mercoledì Inizio" options={{fullWidth:true}} fullWidth
                            choices={choicesMercolediInizio} disabled={choicesMercolediInizio.length==0 || isDisabilitato("mercoledi") ? true:false}
                            defaultValue={localIscrizioni?.inizio_mercoledi_desiderata || ""}     
                            onChange={(event) => {                                      
                                    setLocalIscrizioni((prev) => ({
                                      ...prev,
                                      inizio_mercoledi_desiderata: event.target.value,
                                    }));
                                  }}  
                            />  
                        <SelectInput sx={{textAlign: "left"}}  source="fine_mercoledi_desiderata" label="Mercoledì Fine" options={{fullWidth:true}} fullWidth
                            choices={choicesMercolediFine} disabled={choicesMercolediFine.length==0 || isDisabilitato("mercoledi") ? true:false}
                            defaultValue={localIscrizioni?.fine_mercoledi_desiderata || ""}     
                            onChange={(event) => {                                      
                                    setLocalIscrizioni((prev) => ({
                                      ...prev,
                                      fine_mercoledi_desiderata: event.target.value,
                                    }));
                                  }}  
                            />                           
                    </Box>  
                    <Box display="flex" flexDirection="column" >
                        <Typography sx={{textAlign:"left"}} variant="h7" gutterBottom>Giovedì</Typography>
                        <SelectInput sx={{textAlign: "left"}}  source="inizio_giovedi_desiderata" label="Giovedì Inizio" options={{fullWidth:true}} fullWidth
                            choices={choicesGiovediInizio} disabled={choicesGiovediInizio.length==0 || isDisabilitato("giovedi") ? true:false}
                            defaultValue={localIscrizioni?.inizio_giovedi_desiderata || ""}     
                            onChange={(event) => {                                      
                                    setLocalIscrizioni((prev) => ({
                                      ...prev,
                                      inizio_giovedi_desiderata: event.target.value,
                                    }));
                                  }}
                            />    
                        <SelectInput sx={{textAlign: "left"}}  source="fine_giovedi_desiderata" label="Giovedì Fine" options={{fullWidth:true}} fullWidth
                            choices={choicesGiovediFine} disabled={choicesGiovediFine.length==0 || isDisabilitato("giovedi") ? true:false}
                            defaultValue={localIscrizioni?.fine_giovedi_desiderata || ""}     
                            onChange={(event) => {                                      
                                    setLocalIscrizioni((prev) => ({
                                      ...prev,
                                      fine_giovedi_desiderata: event.target.value,
                                    }));
                                  }}
                            />                                                
                    </Box>  
                    <Box display="flex" flexDirection="column" >
                        <Typography sx={{textAlign:"left"}} variant="h7" gutterBottom>Venerdì</Typography>
                        <SelectInput sx={{textAlign: "left"}}  source="inizio_venerdi_desiderata" label="Venerdì Inizio" options={{fullWidth:true}} fullWidth
                            choices={choicesVenerdiInizio} disabled={choicesVenerdiInizio.length==0 || isDisabilitato("venerdi") ? true:false}
                            defaultValue={localIscrizioni?.inizio_venerdi_desiderata || ""}     
                            onChange={(event) => {                                      
                                    setLocalIscrizioni((prev) => ({
                                      ...prev,
                                      inizio_venerdi_desiderata: event.target.value,
                                    }));
                                  }}
                            />      
                        <SelectInput sx={{textAlign: "left"}}  source="fine_venerdi_desiderata" label="Venerdì Fine" options={{fullWidth:true}} fullWidth
                            choices={choicesVenerdiFine} disabled={choicesVenerdiFine.length==0 || isDisabilitato("venerdi") ? true:false}
                            defaultValue={localIscrizioni?.fine_venerdi_desiderata || ""}     
                            onChange={(event) => {                                      
                                    setLocalIscrizioni((prev) => ({
                                      ...prev,
                                      fine_venerdi_desiderata: event.target.value,
                                    }));
                                  }}
                            />                           
                    </Box>          
                </Box>
            </Box>         
      
            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>    
                <SelectInput sx={{textAlign: "left"}}  source="disponibilita_cambio_orario_sede" validate={required()} options={{fullWidth:true}} fullWidth label="Disponibilità cambio sede/orario"
                    choices={[{ id: 'Si', name: 'Si' },{ id: 'No', name: 'No' } ]}
                    defaultValue={localIscrizioni?.disponibilita_cambio_orario_sede || ""}     
                    onChange={(event) => {                                      
                                    setLocalIscrizioni((prev) => ({
                                      ...prev,
                                      disponibilita_cambio_orario_sede: event.target.value,
                                    }));
                                  }}
                    /> 
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                <SelectInput sx={{textAlign: "left"}}  source="adempimenti_richiesti"  options={{fullWidth:true}} validate={required()} fullWidth label="Adempimenti richiesti"
                    choices={[{ id: 'Si', name: 'Si' },{ id: 'No', name: 'No' } ]}
                    defaultValue={localIscrizioni?.adempimenti_richiesti || ""}     
                    onChange={(event) => {                                      
                                    setLocalIscrizioni((prev) => ({
                                      ...prev,
                                      adempimenti_richiesti: event.target.value,
                                    }));
                                  }}
                    />                 
                </Box>        
            </Box>  

            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>        
                    <SelectInput sx={{textAlign: "left"}}  source="in_attesa_servizio_educativo_tradizionale" validate={required()} options={{fullWidth:true}} fullWidth label="In attesa del servizio educativo tradizionale"
                        choices={[{ id: 'Si', name: 'Si' },{ id: 'No', name: 'No' } ]}
                        defaultValue={localIscrizioni?.in_attesa_servizio_educativo_tradizionale || ""}     
                        onChange={(event) => {                                      
                                        setLocalIscrizioni((prev) => ({
                                          ...prev,
                                          in_attesa_servizio_educativo_tradizionale: event.target.value,
                                        }));
                                      }}
                        /> 
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <SelectInput sx={{textAlign: "left"}}  source="privacy_preiscrizione"  options={{fullWidth:true}} validate={required()} fullWidth label="Liberatoria Privacy"
                        choices={[{ id: 'Si', name: 'Si' },{ id: 'No', name: 'No' } ]}
                        defaultValue={localIscrizioni?.privacy_preiscrizione || ""}     
                        onChange={(event) => {                                      
                                        setLocalIscrizioni((prev) => ({
                                          ...prev,
                                          privacy_preiscrizione: event.target.value,
                                        }));
                                      }}
                        /> 
                </Box>        
            </Box>
            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>                        
                  <TextInput source="sponsor" label="Come ha conosciuto il servizio" fullWidth
                   defaultValue={localIscrizioni?.sponsor || ""}     
                   onChange={(event) => {                                      
                                   setLocalIscrizioni((prev) => ({
                                     ...prev,
                                     sponsor: event.target.value,
                                   }));
                                 }}
                  />              
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
               
                </Box>        
            </Box>
            </Box>
    ):(
        <></>
    )        
    }


    {localIscrizioni != null && localIscrizioni.stato_iscrizione_id  == "2" ? (  //ISCRIZIONE 
     <Box display={{ width: '100%',  alignItems: "flex-start", textAlign: "left"  }}>
           <Typography variant="h6" gutterBottom>
                    Dati Iscrizione
           </Typography>  
           <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}> 
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}> 
                    <DateInput validate={required()} source="data_inizio_scelta" label="Data di inizio" locales="it-IT" fullWidth                     
                        defaultValue={localIscrizioni?.data_inizio_scelta || ""}                    
                        onChange={(event) => {                                                   
                            setLocalIscrizioni((prev) => ({
                            ...prev,
                            data_inizio_scelta : event.target.value,
                        }));
                        }}
                        /> 
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <DateInput validate={required()} source="data_fine_scelta" label="Data di fine" locales="it-IT" fullWidth
                      defaultValue={localIscrizioni?.data_fine_scelta || ""}                    
                      onChange={(event) => {                                                   
                          setLocalIscrizioni((prev) => ({
                          ...prev,
                          data_fine_scelta : event.target.value,
                      }));
                      }}
                      />
                </Box>        
            </Box>
            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <SelectInput label="Tipologia di iscrizione"
                        source="tipo_iscrizione" validate={required()}
                        choices={[{ id: '0', name: 'Abbonamento 3gg/settimana' },{  id: '1', name: 'Abbonamento 4gg/settimana' },{ id: '2', name: 'Abbonamento 5gg/settimana' },{ id: '3', name: 'Carnet 40ore' } ]}
                        options={{fullWidth:true}} sx={{textAlign: "left"}} fullWidth 
                        defaultValue={localIscrizioni?.tipo_iscrizione || ""}     
                        onChange={(event) => {                                      
                            setLocalIscrizioni((prev) => ({
                              ...prev,
                              tipo_iscrizione: event.target.value,
                            }));
                          }}  
                        
                        />  
                </Box> 
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                        <SelectInput label="ISEE"
                        source="isee" validate={required()}
                        choices={[{ id: '0', name: 'Fascia Esenzione' },{ id: '1', name: 'Fascia 0,00 € - 3.900,00 €' },{  id: '2', name: 'Fascia 3.900,01 € - 5.000,00 €' },{  id: '3', name: 'Fascia 5.000,01 € - 6.100,00 €' },{  id: '4', name: 'Fascia 6.100,01 € - 7.200,00 €' },{  id: '5', name: 'Fascia 7.200,01 € - 8.400,00 €' },
                        {  id: '6', name: 'Fascia 8.400,01 € - 9.500,00 €' },{  id: '7', name: 'Fascia 9.500,01 € - 10.600,00 €' },{  id: '8', name: 'Fascia  10.600,01 € -  11.700,00 €' },{  id: '9', name: 'Fascia 11.700,01 € - 12.800,00 €' },{  id: '10', name: 'Fascia 12.800,01 € - 13.900,00 €' },
                        {  id: '11', name: 'Fascia 13.900,01 € - 15.000,00 €' },{  id: '12', name: 'Fascia 15.000,01 € - 16.200,00 €' },{  id: '13', name: 'Fascia 16.200,01 € - 17.300,00 €' },{  id: '14', name: 'Fascia 17.300,01 € - 18.400,00 €' },{  id: '15', name: 'Fascia 18.400,01 € - 19.500,00 €' },
                        {  id: '16', name: 'Fascia 19.500,01 € - 20.600,00 €' },{  id: '17', name: 'Fascia 20.600,01 € - 21.800,00 €' },{  id: '18', name: 'Fascia 21.800,01 € - 22.900,00 €' },{  id: '19', name: 'Fascia 22.900,01 € - 24.000,00 €' },{  id: '20', name: 'Fascia 24.000,01 € - 27.500,00 €' },
                        {  id: '21', name: 'Fascia 27.500,01 € - 31.000,00 €' },{  id: '22', name: 'Fascia 31.000,01 € - 34.500,00 €' },{  id: '23', name: 'Fascia 34.500,01 € - 38.000,00 €' },{  id: '24', name: 'Fascia 38.000,01 € - 999.000,00 €' }]}                        
                        options={{fullWidth:true}} sx={{textAlign: "left"}} fullWidth 
                        defaultValue={localIscrizioni?.isee || ""}  
                        onChange={(event) => {                                      
                            setLocalIscrizioni((prev) => ({
                              ...prev,
                              isee: event.target.value,
                            }));
                          }} 
                        />  
                </Box>  
            </Box>    
            {localIscrizioni.isee && localIscrizioni.tipo_iscrizione ? (
                //console.log("perchè"),
                <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>                                   
                        
                        {localIscrizioni.tipo_iscrizione!=3 ?(
                            //console.log("tariffe",tariffe,selectedTitoloValue,selectedISEEValue,tariffe[selectedISEEValue][selectedTitoloValue]),
                        <div style={{display:'flex', flexDirection: 'column'}}>
                            <Typography variant="h7" gutterBottom> Costo del Servizio selezionato</Typography>  
                            <Typography variant="h7" gutterBottom>   - Costo dell'abbonamento (primo mese): € {calcolaCostoPrimoMese(tariffe[localIscrizioni.isee][localIscrizioni.tipo_iscrizione],
                                contaGiorniLavorativi(moment(localIscrizioni.data_inizio_scelta).format("YYYY"), moment(localIscrizioni.data_inizio_scelta).format("MM")), 
                                contaGiorniLavorativiRimanenti(moment(localIscrizioni.data_inizio_scelta).format("YYYY"), moment(localIscrizioni.data_inizio_scelta).format("MM"), localIscrizioni.data_inizio_scelta))
                                } </Typography>                              
                            <Typography variant="h7" gutterBottom>   - Costo dell'abbonamento (mesi seguenti): € {tariffe[localIscrizioni.isee][localIscrizioni.tipo_iscrizione]},00 </Typography> 
                            <Typography variant="h7" gutterBottom>  </Typography>  
                        </div>) :(
                            <div style={{display:'flex', flexDirection: 'column'}}>
                            <Typography variant="h7" gutterBottom> Costo del Servizio selezionato</Typography>  
                            <Typography variant="h7" gutterBottom>   - Costo del carnet: € {tariffe[localIscrizioni.isee][localIscrizioni.tipo_iscrizione]},00 </Typography>  
                            <Typography variant="h7" gutterBottom>  </Typography>  
                            </div>
                        )}
                </Box> ):(<></>)
            }
            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>                
                <Box key={"0700"} display="grid" gridTemplateColumns="repeat(5, 1fr)" gap="1em" width="100%">
                    <Box display="flex" flexDirection="column" >
                        <Typography sx={{textAlign:"left"}} variant="h7" gutterBottom>Lunedì</Typography>
                        <SelectInput sx={{textAlign: "left"}}  source="inizio_lunedi_effettiva" label="Lunedì Inizio" options={{fullWidth:true}} fullWidth
                            choices={choicesLunedìInizio} disabled={choicesLunedìInizio.length==0 || isDisabilitatoIscrizione("lunedi")? true:false}
                            defaultValue={localIscrizioni?.inizio_lunedi_effettiva || ""}     
                            onChange={(event) => {                                      
                                    setLocalIscrizioni((prev) => ({
                                      ...prev,
                                      inizio_lunedi_effettiva: event.target.value,
                                    }));
                                  }}
                            />    
                        <SelectInput sx={{textAlign: "left"}}  source="fine_lunedi_effettiva" label="Lunedì Fine" options={{fullWidth:true}} fullWidth
                            choices={choicesLunedìFine} disabled={choicesLunedìFine.length==0 || isDisabilitatoIscrizione("lunedi")? true:false}
                            defaultValue={localIscrizioni?.fine_lunedi_effettiva || ""}     
                            onChange={(event) => {                                      
                                    setLocalIscrizioni((prev) => ({
                                      ...prev,
                                      fine_lunedi_effettiva: event.target.value,
                                    }));
                                  }}
                            />     
                    </Box>      
                    <Box display="flex" flexDirection="column" >
                        <Typography sx={{textAlign:"left"}} variant="h7" gutterBottom>Martedì</Typography>
                        <SelectInput sx={{textAlign: "left"}}  source="inizio_martedi_effettiva" label="Martedì Inizio" options={{fullWidth:true}} fullWidth
                            choices={choicesMartediInizio} disabled={choicesMartediInizio.length==0 || isDisabilitatoIscrizione("martedi")? true:false}
                            defaultValue={localIscrizioni?.inizio_martedi_effettiva || ""}     
                            onChange={(event) => {                                      
                                    setLocalIscrizioni((prev) => ({
                                      ...prev,
                                      inizio_martedi_effettiva: event.target.value,
                                    }));
                                  }}
                            />                   
                        <SelectInput sx={{textAlign: "left"}}  source="fine_martedi_effettiva" label="Martedì Fine" options={{fullWidth:true}} fullWidth
                            choices={choicesMartediFine} disabled={choicesMartediFine.length==0 || isDisabilitatoIscrizione("martedi")? true:false}
                            defaultValue={localIscrizioni?.fine_martedi_effettiva || ""}     
                            onChange={(event) => {                                      
                                    setLocalIscrizioni((prev) => ({
                                      ...prev,
                                      fine_martedi_effettiva: event.target.value,
                                    }));
                                  }}
                            />       
                    </Box>
                    <Box display="flex" flexDirection="column" >
                        <Typography sx={{textAlign:"left"}} variant="h7" gutterBottom>Mercoledì</Typography>
                        <SelectInput sx={{textAlign: "left"}}  source="inizio_mercoledi_effettiva" label="Mercoledì Inizio" options={{fullWidth:true}} fullWidth
                            choices={choicesMercolediInizio} disabled={choicesMercolediInizio.length==0 || isDisabilitatoIscrizione("mercoledi")? true:false}
                            defaultValue={localIscrizioni?.inizio_mercoledi_effettiva || ""}     
                            onChange={(event) => {                                      
                                    setLocalIscrizioni((prev) => ({
                                      ...prev,
                                      inizio_mercoledi_effettiva: event.target.value,
                                    }));
                                  }}
                            />    
                        <SelectInput sx={{textAlign: "left"}}  source="fine_mercoledi_effettiva" label="Mercoledì Fine" options={{fullWidth:true}} fullWidth
                            choices={choicesMercolediFine} disabled={choicesMercolediFine.length==0 || isDisabilitatoIscrizione("mercoledi")? true:false}
                            defaultValue={localIscrizioni?.fine_mercoledi_effettiva || ""}     
                            onChange={(event) => {                                      
                                    setLocalIscrizioni((prev) => ({
                                      ...prev,
                                      fine_mercoledi_effettiva: event.target.value,
                                    }));
                                  }}
                            />     
                    </Box>
                    <Box display="flex" flexDirection="column" >
                        <Typography sx={{textAlign:"left"}} variant="h7" gutterBottom>Giovedì</Typography>
                        <SelectInput sx={{textAlign: "left"}}  source="inizio_giovedi_effettiva" label="Giovedì Inizio" options={{fullWidth:true}} fullWidth
                            choices={choicesGiovediInizio} disabled={choicesGiovediInizio.length==0 || isDisabilitatoIscrizione("giovedi")? true:false}
                            defaultValue={localIscrizioni?.inizio_giovedi_effettiva || ""}     
                            onChange={(event) => {                                      
                                    setLocalIscrizioni((prev) => ({
                                      ...prev,
                                      inizio_giovedi_effettiva: event.target.value,
                                    }));
                                  }}
                            />    
                    <SelectInput sx={{textAlign: "left"}}  source="fine_giovedi_effettiva" label="Giovedì Fine" options={{fullWidth:true}} fullWidth
                            choices={choicesGiovediFine} disabled={choicesGiovediFine.length==0 || isDisabilitatoIscrizione("giovedi")? true:false}
                            defaultValue={localIscrizioni?.fine_giovedi_effettiva || ""}     
                            onChange={(event) => {                                      
                                    setLocalIscrizioni((prev) => ({
                                      ...prev,
                                      fine_giovedi_effettiva: event.target.value,
                                    }));
                                  }}
                            />  
                    </Box>
                    <Box display="flex" flexDirection="column" >
                        <Typography sx={{textAlign:"left"}} variant="h7" gutterBottom>Venerdì</Typography>
                        <SelectInput sx={{textAlign: "left"}}  source="inizio_venerdi_effettiva" label="Venerdì Inizio" options={{fullWidth:true}} fullWidth
                            choices={choicesVenerdiInizio} disabled={choicesVenerdiInizio.length==0 || isDisabilitatoIscrizione("venerdi")? true:false}
                            defaultValue={localIscrizioni?.inizio_venerdi_effettiva || ""}     
                            onChange={(event) => {                                      
                                    setLocalIscrizioni((prev) => ({
                                      ...prev,
                                      inizio_venerdi_effettiva: event.target.value,
                                    }));
                                  }}
                            />    
                        <SelectInput sx={{textAlign: "left"}}  source="fine_venerdi_effettiva" label="Venerdì Fine" options={{fullWidth:true}} fullWidth
                            choices={choicesVenerdiFine} disabled={choicesVenerdiFine.length==0 || isDisabilitatoIscrizione("venerdi")? true:false}
                            defaultValue={localIscrizioni?.fine_venerdi_effettiva || ""}     
                            onChange={(event) => {                                      
                                    setLocalIscrizioni((prev) => ({
                                      ...prev,
                                      fine_venerdi_effettiva: event.target.value,
                                    }));
                                  }}
                            />     
                    </Box>                
                </Box>              
            </Box> 
            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>                            
                    <DateInput source="isee_data_sottoscrizione" validate={required()}  label="Data sottoscrizione ISEE" locales="it-IT" fullWidth
                     defaultValue={localIscrizioni?.isee_data_sottoscrizione || ""}     
                     onChange={(event) => {                                      
                         setLocalIscrizioni((prev) => ({
                           ...prev,
                           isee_data_sottoscrizione: event.target.value,
                         }));
                       }}  
                    
                    />                                          
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="indicatore_isee" label="Indicatore ISEE (€)" validate={required()}  fullWidth
                     defaultValue={localIscrizioni?.indicatore_isee || ""}  
                     onChange={(event) => {                                      
                         setLocalIscrizioni((prev) => ({
                           ...prev,
                           indicatore_isee: event.target.value,
                         }));
                       }} 
                    />                  
                </Box>        
            </Box>  

            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>   
                    <SelectInput sx={{textAlign: "left"}}  source="disponibilita_cambio_orario_sede" validate={required()} options={{fullWidth:true}} fullWidth label="Disponibilità cambio sede/orario"
                        choices={[{ id: 'Si', name: 'Si' },{ id: 'No', name: 'No' } ]}
                        defaultValue={localIscrizioni?.disponibilita_cambio_orario_sede || ""}  
                        onChange={(event) => {                                      
                            setLocalIscrizioni((prev) => ({
                            ...prev,
                            disponibilita_cambio_orario_sede: event.target.value,
                            }));
                        }} />                      
                  
                </Box>

                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <SelectInput sx={{textAlign: "left"}}  source="patto_educativo"  options={{fullWidth:true}} validate={required()} fullWidth label="Patto educativo"
                    choices={[{ id: 'Si', name: 'Si' },{ id: 'No', name: 'No' } ]}
                    defaultValue={localIscrizioni?.patto_educativo || ""}  
                    onChange={(event) => {                                      
                        setLocalIscrizioni((prev) => ({
                          ...prev,
                          patto_educativo: event.target.value,
                        }));
                      }} 
                    />                                                                            
                </Box>                     
            </Box>
            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>        
                    <SelectInput sx={{textAlign: "left"}}  source="privacy_iscrizione"  options={{fullWidth:true}} validate={required()} fullWidth label="Liberatoria Privacy"
                        choices={[{ id: 'Si', name: 'Si' },{ id: 'No', name: 'No' } ]}
                        defaultValue={localIscrizioni?.privacy_iscrizione || ""}  
                        onChange={(event) => {                                      
                            setLocalIscrizioni((prev) => ({
                              ...prev,
                              privacy_iscrizione: event.target.value,
                            }));
                          }} 
                        />                   
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                <SelectInput sx={{textAlign: "left"}}  source="fragilita_economica"  options={{fullWidth:true}} validate={required()} fullWidth label="Eventuale fragilità economica"
                        choices={[{ id: 'Si', name: 'Si' },{ id: 'No', name: 'No' } ]}
                        defaultValue={localIscrizioni?.fragilita_economica || ""}  
                        onChange={(event) => {                                      
                            setLocalIscrizioni((prev) => ({
                              ...prev,
                              fragilita_economica: event.target.value,
                            }));
                          }} 
                        />                                                                        
                    
                </Box>        
            </Box>
            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <SelectInput sx={{textAlign: "left"}}  source="liberatoria_immagini" validate={required()} options={{fullWidth:true}} fullWidth label="Liberatoria per Immagini"
                        choices={[{ id: 'Si', name: 'Si' },{ id: 'No', name: 'No' } ]}
                        defaultValue={localIscrizioni?.liberatoria_immagini || ""}  
                        onChange={(event) => {                                      
                            setLocalIscrizioni((prev) => ({
                              ...prev,
                              liberatoria_immagini: event.target.value,
                            }));
                          }} 
                        />                
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <SelectInput sx={{textAlign: "left"}}  source="somministrazione_farmaci" validate={required()} options={{fullWidth:true}} fullWidth label="Autorizzazione somministrazione farmaci"
                        choices={[{ id: 'Si', name: 'Si' },{ id: 'No', name: 'No' } ]}
                        defaultValue={localIscrizioni?.somministrazione_farmaci || ""}  
                        onChange={(event) => {                                      
                            setLocalIscrizioni((prev) => ({
                              ...prev,
                              somministrazione_farmaci: event.target.value,
                            }));
                          }} 
                        />   
                </Box>        
            </Box>     
            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <SelectInput sx={{textAlign: "left"}}  source="in_attesa_servizio_educativo_tradizionale" validate={required()} options={{fullWidth:true}} fullWidth label="In attesa del servizio educativo tradizionale"
                        choices={[{ id: 'Si', name: 'Si' },{ id: 'No', name: 'No' } ]}
                        defaultValue={localIscrizioni?.in_attesa_servizio_educativo_tradizionale || ""}  
                        onChange={(event) => {                                      
                            setLocalIscrizioni((prev) => ({
                              ...prev,
                              in_attesa_servizio_educativo_tradizionale: event.target.value,
                            }));
                          }} 
                        />  

                    
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                <SelectInput sx={{textAlign: "left"}}  source="delega_minori" validate={required()} options={{fullWidth:true}} fullWidth label="Delega ritiro minori"
                        choices={[{ id: 'Si', name: 'Si' },{ id: 'No', name: 'No' } ]}
                        defaultValue={localIscrizioni?.delega_minori || ""}  
                        onChange={(event) => {                                      
                            setLocalIscrizioni((prev) => ({
                              ...prev,
                              delega_minori: event.target.value,
                            }));
                          }} 
                        />                
                    
                </Box>        
            </Box>  
            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>                
                <SelectInput sx={{textAlign: "left"}}  validate={required()} source="autorizzazione_uscite"  options={{fullWidth:true}} fullWidth label="Autorizzazione uscite sul territorio"
                        choices={[{ id: 'Si', name: 'Si' },{ id: 'No', name: 'No' } ]}
                        defaultValue={localIscrizioni?.autorizzazione_uscite || ""}  
                        onChange={(event) => {                                      
                            setLocalIscrizioni((prev) => ({
                              ...prev,
                              autorizzazione_uscite: event.target.value,
                            }));
                          }} 
                        />                
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                <SelectInput sx={{textAlign: "left"}}  validate={required()} source="fratelli_sorelle"  options={{fullWidth:true}} fullWidth label="Eventuali fratelli o sorelle"
                        choices={[{ id: 'Si', name: 'Si' },{ id: 'No', name: 'No' } ]}
                        defaultValue={localIscrizioni?.fratelli_sorelle || ""}  
                        onChange={(event) => {                                      
                            setLocalIscrizioni((prev) => ({
                              ...prev,
                              fratelli_sorelle: event.target.value,
                            }));
                          }} 
                        />                                                          
                </Box>        
            </Box>          
            
            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <TextInput source="recapito_telefonico" label="Ulteriore recapito telefonico" fullWidth
                     defaultValue={localIscrizioni?.recapito_telefonico || ""}  
                     onChange={(event) => {                                      
                         setLocalIscrizioni((prev) => ({
                           ...prev,
                           recapito_telefonico: event.target.value,
                         }));
                       }} 
                    />                    
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <TextInput source="sponsor" label="Come ha conosciuto il servizio" fullWidth
                      defaultValue={localIscrizioni?.sponsor || ""}  
                      onChange={(event) => {                                      
                          setLocalIscrizioni((prev) => ({
                            ...prev,
                            sponsor: event.target.value,
                          }));
                        }} 
                    />                                                                                                    
                </Box>        
            </Box>               
     </Box>
    ):
    (
        <></>
    )        
    }

   
    <Typography variant="h6" gutterBottom>
                    Note
    </Typography>
    
    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>        
        <TextInput source="note_pagamenti" label="Note relative ai Pagamenti" fullWidth
        defaultValue={localIscrizioni?.note_pagamenti || ""}  
        onChange={(event) => {                                      
            setLocalIscrizioni((prev) => ({
              ...prev,
              note_pagamenti: event.target.value,
            }));
          }} 
        />                  
    </Box>    

    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>        
        <TextInput source="note" label="Altre note" fullWidth
         defaultValue={localIscrizioni?.note || ""}  
         onChange={(event) => {                                      
             setLocalIscrizioni((prev) => ({
               ...prev,
               note: event.target.value,
             }));
           }} 
        />            
    </Box>      
      
    </SimpleForm>
  </Create> );


};

