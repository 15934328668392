import React, { useState } from "react";
import { Box, IconButton } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

const LogoStepper  = ({ images, activeStep, handleNext, handlePrev }) => {


  return (
    <Box position="relative" width="100%" maxHeight="500px">
      {/* Immagine */}
      <img
        src={images[activeStep]}
        alt={`Step ${activeStep + 1}`}
        style={{
          width: "100%",
          maxHeight: "500px",
          objectFit: "contain",
          borderRadius: "8px",
        }}
      />

      {/* Freccia Sinistra */}
      <IconButton
        onClick={handlePrev}
        sx={{
          position: "absolute",
          top: "50%",
          left: 10,
          transform: "translateY(-50%)",
          backgroundColor: "rgba(0, 0, 0, 0.4)",
          color: "white",
          "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.6)" },
        }}
      >
        <ArrowBackIos />
      </IconButton>

      {/* Freccia Destra */}
      <IconButton
        onClick={handleNext}
        sx={{
          position: "absolute",
          top: "50%",
          right: 10,
          transform: "translateY(-50%)",
          backgroundColor: "rgba(0, 0, 0, 0.4)",
          color: "white",
          "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.6)" },
        }}
      >
        <ArrowForwardIos />
      </IconButton>
    </Box>
  );
};

export default LogoStepper;
