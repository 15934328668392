//import { List, Datagrid, TextField, EmailField } from "react-admin";
import { SimpleList, SelectColumnsButton, Datagrid, TextField, EmailField, UrlField, EditButton, TopToolbar } from "react-admin";
import { Edit, Create, DatagridConfigurable, DateField, SimpleForm, ReferenceInput, TextInput, ReferenceField, SelectInput} from "react-admin";
import { BulkDeleteButton, DeleteButton, BulkExportButton } from 'react-admin';
import { Fragment,useRef } from 'react';
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { MyEmailField,MyEmptyDateField } from "./MyUrlField";
import { useNotify, useRefresh, useRedirect, useDataProvider} from 'react-admin';
import { DateInput, Toolbar, SaveButton } from 'react-admin';
import { ImportButton } from "react-admin-import-csv";
import { CreateButton, FilterButton, ExportButton } from "ra-ui-materialui";
import { Chip }  from '@mui/material';
import { List, downloadCSV, required } from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { useTranslate } from 'react-admin';
import React, { useState } from 'react';
import { useGetOne,useGetMany,useGetManyReference } from 'react-admin';
import { Box, Typography, FormControl, FormLabel,RadioGroup,FormControlLabel,Radio } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { FunctionField } from "react-admin";
import { Tooltip } from "@mui/material";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";

const ValidCircleField =  (props)  => (
  <FunctionField            
    source="valid"
    //label={<span style={{ fontWeight: "bold" }}>Coperta da titoli</span>}
    sortable={false}
    render={(record) => (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%"}} >
        <Tooltip title={record.valid ===true ? "Valido" : 
        record.valid ===false? "Scaduto" : ""}>
          <span
            style={{
              display: "inline-block",
              width: "12px",
              height: "12px",
              borderRadius: "50%",            
              //backgroundColor: record.valid ? "green" : "red",
              backgroundColor: record.valid === true ? "green" 
                : record.valid === false ? "red" 
                : "lightgray"
            }}/>
        </Tooltip>
      </div>
    )}
  />
);

const getTime=() =>{
  const now = new Date();
  const formattedDate = now.toLocaleString('it-IT', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
  }).replace(/\//g, '-').replace(',', ''); 
  const parts = formattedDate.split(' ');
  const finalDate = `${parts[0].split('-').reverse().join('-')} ${parts[1]}`;
  return finalDate;
}
/*
const exportToXLS = (records, fetchRelatedRecords) => {  
  const worksheet = XLSX.utils.json_to_sheet(records);    

  const colWidths = Object.keys(records[0] || {}).map(key => ({
    wch: Math.max(
        key.length, // Larghezza del nome della colonna
        ...records.map(record => (record[key] ? record[key].toString().length : 0)) // Larghezza del contenuto
    )
}));
worksheet["!cols"] = colWidths;

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Dati");  
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });    
  const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  saveAs(data, "titoli.xlsx");
};
*/

const exportToXLS = async (records, fetchRelatedRecords) => {
  if (!records || records.length === 0) return;
  
  const bambiniMap = await fetchRelatedRecords(records, "id_bambino", "bambini");

  const exportedRecords = records.map(record => ({
    "Identificativo": record.id,
    Bambino: bambiniMap[record.id_bambino]
      ? `${bambiniMap[record.id_bambino].nome} ${bambiniMap[record.id_bambino].cognome} - ${bambiniMap[record.id_bambino].codice_fiscale}`
      : "N/A",
    "Data acquisto": record.data_acquisto ? moment(record.data_acquisto).format("DD/MM/YYYY") : "",
    "Frequenza abbonamento": record.frequenza_abbonamento ? record.frequenza_abbonamento+" giorni/settimana" :"" ,
    "Scadenza abbonamento": record.scadenza_abbonamento ? moment(record.scadenza_abbonamento).format("DD/MM/YYYY") : "",
    "Ore disponibili carnet": record.ore_disponibili_carnet,
    "Ore utilizzate carnet": record.ore_utilizzate_carnet,
    "Scadenza carnet": record.scadenza_carnet ? moment(record.scadenza_carnet).format("DD/MM/YYYY") : "",
    "Titolo valido": record.valid,
    "Data di inserimento": record.timestamp_inserimento
  }));
  const worksheet = XLSX.utils.json_to_sheet(exportedRecords);
  const colWidths = Object.keys(exportedRecords[0] || {}).map(key => ({
    wch: Math.max(
      key.length, 
      ...exportedRecords.map(record => (record[key] ? record[key].toString().length : 0))
    )
  }));
  worksheet["!cols"] = colWidths;
  
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Dati");  
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  saveAs(data, "titoli.xlsx");
};


const TitoloBulkActionButtons = () => (
  <Fragment>
      <BulkExportButton maxResults= {15000} exporter={exportToXLS}  />               
      
      <BulkDeleteButton mutationMode="pessimistic"/>
  </Fragment>
);


const TitoloFilters = [
  <TextInput label="Cerca" source="q" alwaysOn />,
  <ReferenceInput source="id_bambino" reference="bambini" label="Bambino" perPage={1000} sort={{ field: 'nome', order: 'ASC' }}>
      <SelectInput optionText={(record) => `${record.nome} ${record.cognome} (${record.codice_fiscale})`} />
  </ReferenceInput> ,  
];

/*
const exporter1 = (records, fetchRelatedRecords) => {
      let data;
      let data1;
      let dataForExport;



fetchRelatedRecords(records, 'ente_id', 'enti').then(enti => {
    //console.log("I1:", records);    
    data1 = records.map(record => ({
          ...record,
          ente: enti[record.ente_id].nome,                
    })); 
    //console.log("EXPORT-1",data1);
    //console.log("EXP3",JSON.stringify(data));
    fetchRelatedRecords(data1,'id_bambino','bambini').then(bambini =>{
        //console.log("I2",data1);
        data = data1.map(record => ({
        ...record,
        //circuito: circuiti[record.circuito_id].circuito,                
        bambino: bambini[record.id_bambino].bambino,                
    })); 
    //console.log("EXPORT-2",data);
    
    dataForExport = data.map(record => {        
      const { id_bambino,id, ...dataForExport } =record;
      return dataForExport;
  });
     
  //}); 

    jsonExport(dataForExport, {
      headers: ['questionario_id','ente', 'circuito', "timestamp_submit","cognome", "nome", "sesso", "data_nascita", "stato_nascita", 
      "provincia_nascita", "luogo_nascita", "indirizzo_residenza", "citta_residenza", "cap_residenza", "provincia_residenza", "codice_fiscale", 
      "codice_abbonamento_musei",  "scadenza_abbonamento_musei", "confermaPresaDAtto", "presaDAtto1","presaDAtto", "cognome_genitore",
      "nome_genitore", "email_genitore", "telefono_genitore"],
  
  }, (err, csv) => {
      downloadCSV(csv, 'registrazioni');
  });
  })
    
});
};
*/
  

const TitoloActions = (props) => {
  const {
    className,
    basePath,    
    resource,
    currentSort,
    filterValues,
    exporter,
  } = props;
  return (
    <TopToolbar className={className}>
      <SelectColumnsButton />            
      <FilterButton/>
      <CreateButton basePath={basePath} />
      <ExportButton         
        resource={resource}
        sort={currentSort}
        filter={filterValues}        
        exporter={exporter}
        maxResults= {15000}   
      />        
    </TopToolbar>
  );
};


export const TitoloList = (props) => (
  <List {...props} sort={{ field: 'id', order: 'ASC' }} filters={TitoloFilters} perPage={50} actions={<TitoloActions exporter={exportToXLS}/>}>    
    <DatagridConfigurable  bulkActionButtons={<TitoloBulkActionButtons />}>   
        <TextField sortable={false} source="id" />
        <ReferenceField sortable={false} source="id_bambino" reference="bambini" label="Bambino">
            <TextField  source="nome" /> <TextField source="cognome" /> - <TextField source="codice_fiscale" />
        </ReferenceField>        
        <DateField sortable={false} source="data_acquisto" label="Data acquisto" locales="it-IT" showTime={false}/>          
        <TextField sortable={false} source="frequenza_abbonamento" label="Frequenza abbonamento"/>
        <DateField sortable={false} source="scadenza_abbonamento" label="Scadenza abbonamento" locales="it-IT" showTime={false}/>        
        <TextField sortable={false} source="ore_disponibili_carnet" label="Ore disponibili carnet"/>     
        <TextField sortable={false} source="ore_utilizzate_carnet" label="Ore utilizzate carnet"/>        
        <DateField sortable={false} source="scadenza_carnet" label="Scadenza carnet" locales="it-IT" showTime={false}/>                  
        <ValidCircleField label="Valido"/>
        <EditButton/>
    </DatagridConfigurable>
  </List>
);

/*
const TitoloEditToolbar = props => (
  <Toolbar {...props} >
      <SaveButton />
      <DeleteButton />
  </Toolbar>
);
*/


export const TitoloEdit = () => {
  const [localTitolo, setLocalTitolo] = useState({});
  const { id } = useParams();
  const { data: titolo, isPending, error } = useGetOne('titoli', { id: id });    
  const notify = useNotify();      
  const redirect = useRedirect();
  const refresh = useRefresh(); 
  const dataProvider = useDataProvider();  
  
  useEffect(() => {  
    console.log("UE-1");
    if (titolo) {    
        let newTitolo = { ...titolo };
        if (titolo.frequenza_abbonamento!= null) {
          newTitolo = {
            ...newTitolo,
            tipologia:"abbonamento",
          }
        }
        else{
          newTitolo = {
            ...newTitolo,
            tipologia:"carnet",
          }                         
        }
        setLocalTitolo({ ...newTitolo });                                  
    }
  },[titolo]);
      
  useEffect(() => {  
      console.log("UE-2");        
  },[localTitolo]);

  const handleEdit = async (values) => {
    try {
      var data2=transform2(values);
      console.log("Editing",values,data2);
      console.log(data2.id, values.id);
      const { data } = await dataProvider.update('titoli', { id:data2.id, data: data2 });          
      console.log('Resource updated successfully:', data, values);          
      notify("Dati aggiornati con successo!", { messageArgs: { smart_count: 1 } });
      refresh();
      redirect('/titoli');                  
    } catch (error) {      
      notify("Qualcosa è andato storto nella modifica del titolo. Riprovare più tardi");
    }
  };

  return(        
    <Edit title={<username />} mutationMode="pessimistic" transform={transform2}>         
        <SimpleForm onSubmit={handleEdit} sx={{ maxWidth: '100%' }}  >                     
        <Typography variant="h6" gutterBottom>
            Dati Titolo (Abbonamento/Carnet)
        </Typography>       
    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>            
            <ReferenceInput source="id_bambino" reference="bambini"  perPage={1000}>                     
                <SelectInput label="Bambino"  option="nome" optionText={(record) => `${record.nome} ${record.cognome} - ${record.codice_fiscale}`}    options={{fullWidth:true}} sx={{textAlign: "left"}} fullWidth  validate={required()} disabled={true}/>
            </ReferenceInput>
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
            <DateInput source="data_acquisto" fullWidth label="Data acquisto" disabled={true}/>
        </Box>
    </Box>
    
    <FormControl sx={{ display: "flex", alignItems: "flex-start", textAlign: "left" }}>
        <FormLabel id="radio-buttons-group-label">
            <Typography variant="h6" style={{paddingLeft:"0px",color:"black"}} gutterBottom>
                Tipologia titolo
            </Typography>  
        </FormLabel>
          <RadioGroup aria-labelledby="radio-buttons-group-label" name="radio-buttons-group"
            defaultValue={localTitolo.tipologia} >        
          <FormControlLabel  value="abbonamento"  control={<Radio checked={localTitolo.tipologia === "abbonamento"} />} label="Abbonamento" disabled />
          <FormControlLabel  value="carnet"  control={<Radio checked={localTitolo.tipologia === "carnet"} />} label="Carnet" disabled />        
        </RadioGroup>
    </FormControl>

    {localTitolo.tipologia === "abbonamento" ? (
      <div style={{ width: "100%" }}>
          <Typography variant="h6" gutterBottom sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", textAlign: "left", width: "100%" }}>
              Dati Abbonamento
          </Typography>   
          <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>    
                <SelectInput sx={{textAlign: "left"}}  source="frequenza_abbonamento" label="Frequenza abbonamento" options={{fullWidth:true}} disabled={true} fullWidth
                    choices={[{ id: '3', name: '3 giorni/settimana' },{ id: '5', name: '4 giorni/settimana' },{ id: '5', name: '5 giorni/settimana' } ]}/>
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>    
                  <DateInput disabled={true} source="scadenza_abbonamento" fullWidth/>    
              </Box>              
            </Box>
          </Box>  
          
          
      </div>
    ):(
      //console.log("CARNET",titolo.carnet,selectedValue),
      <div style={{ width: "100%" }}>
          <Typography variant="h6" gutterBottom  sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", textAlign: "left", width: "100%" }}>
              Dati Carnet
          </Typography> 

          <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
              <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>    
                  <TextInput source="ore_disponibili_carnet" label="Ore disponibili carnet"   fullWidth 
                  value={localTitolo.ore_disponibili_carnet}  options={{fullWidth:true}} sx={{textAlign: "left"}} disabled={true} />
              </Box>
              <Box flex={2} ml={{ xs: 0, sm: '0.5em' }}>
              <DateInput disabled={true} source="scadenza_carnet" fullWidth/>    
            </Box>
          </Box>          
          
      </div>
    )}
        </SimpleForm>
      </Edit>
    )
};



const transform2 = (data) => {
  const sanitizedData = {};
  for (const key in data) {   
      sanitizedData[key] = data[key]; 
  }
  sanitizedData["timestamp_inserimento"]=getTime();
  
  return sanitizedData; 
};

const generateMonths = () => {
  const options = [];
  for (let i = -3; i <= 12; i++) {
    const date = moment().add(i, "months").endOf("month"); 
    const monthName = date.format("MMMM YYYY"); 
    
    options.push({
        id: date.format("YYYY-MM-DD"), 
        name: monthName.charAt(0).toUpperCase() + monthName.slice(1), 
    });     
  }

  return options;
};


export const TitoloCreate =  ({ onSuccess, ...props }) => { 
  const notify = useNotify();    
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const [selectedValue, setSelectedValue] = useState("abbonamento");
  
  const handleCreate = async (values) => {
    try {
      var data2=transform2(values);
      const { data } = await dataProvider.create('titoli', { data: data2 });        
      // Custom logic after successful creation
      console.log('Resource created successfully:', data, values);
      //doRegisterMail(values.email, values.password, values.username);        
      notify("Titolo registrato correttamente!", { messageArgs: { smart_count: 1 } });
      redirect('/titoli');        
    } catch (error) {
      notify("Qualcosa è andato storto nella creazione del titolo. Riprovare più tardi");
    }
  };   
  return(

    <Create transform={transform2}  {...props} >       
    {/**<SimpleForm validate={validateTitoloFields} sx={{ maxWidth: '100%' }}> */}
    <SimpleForm onSubmit={handleCreate} sx={{ maxWidth: '100%' }}> 
    <Typography variant="h6" gutterBottom>
        Dati Titolo (Abbonamento/Carnet)
    </Typography>       
    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <ReferenceInput source="id_bambino" reference="bambini"  perPage={1000}>                     
                <SelectInput label="Bambino"  option="nome" optionText={(record) => `${record.nome} ${record.cognome} - ${record.codice_fiscale}`}    options={{fullWidth:true}} sx={{textAlign: "left"}} fullWidth  validate={required()}/>
            </ReferenceInput>
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
            <DateInput source="data_acquisto" fullWidth label="Data acquisto"/>
        </Box>
    </Box>
    
    <FormControl sx={{ display: "flex", alignItems: "flex-start", textAlign: "left" }}>
        <FormLabel id="radio-buttons-group-label">
            <Typography variant="h6" style={{paddingLeft:"0px",color:"black"}} gutterBottom>
                Tipologia titolo
            </Typography>  
        </FormLabel>
        <RadioGroup aria-labelledby="radio-buttons-group-label" defaultValue="abbonamento" name="radio-buttons-group"
            value={selectedValue}
            onChange={(e) => setSelectedValue(e.target.value)}>        
            <FormControlLabel value="abbonamento" control={<Radio />} label="Abbonamento" />
            <FormControlLabel value="carnet" control={<Radio />} label="Carnet" />        
        </RadioGroup>
    </FormControl>

    {selectedValue === "abbonamento" ? (
      <div style={{ width: "100%" }}>
          <Typography variant="h6" gutterBottom sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", textAlign: "left", width: "100%" }}>
              Dati Abbonamento
          </Typography>   
          <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>    
                <SelectInput sx={{textAlign: "left"}}  source="frequenza_abbonamento" label="Frequenza abbonamento" options={{fullWidth:true}} fullWidth
                    choices={[{ id: '3', name: '3 giorni/settimana' },{ id: '5', name: '4 giorni/settimana' },{ id: '5', name: '5 giorni/settimana' } ]}/>              
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                <SelectInput sx={{textAlign: "left"}} label="Mese di validità dell'abbonamento" source="scadenza_abbonamento" choices={generateMonths()} optionText="name" optionValue="id" fullWidth />                
            </Box>
          </Box>                      
      </div>
    ):(
      <div style={{ width: "100%" }}>
          <Typography variant="h6" gutterBottom  sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", textAlign: "left", width: "100%" }}>
              Dati Carnet
          </Typography> 
          <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
              <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>    
                  <TextInput source="ore_disponibili_carnet" label="Ore disponibili carnet" defaultValue={40} disabled={true} fullWidth />    
              </Box>
              <Box flex={2} ml={{ xs: 0, sm: '0.5em' }}>
                <DateInput source="scadenza_carnet" fullWidth/>    
            </Box>
          </Box>
      </div>
    )}
    </SimpleForm>
    </Create> 
  );
}
