//import { List, Datagrid, TextField, EmailField } from "react-admin";
import { SimpleList, SelectColumnsButton, Datagrid, TextField, EmailField, UrlField, EditButton, TopToolbar } from "react-admin";
import { Edit, Create, DatagridConfigurable, DateField, SimpleForm, ReferenceInput, TextInput, NumberInput, ReferenceField, SelectInput} from "react-admin";
import { BulkDeleteButton, BulkExportButton } from 'react-admin';
import { Fragment,useRef } from 'react';
import { DateInput, Toolbar, SaveButton } from 'react-admin';
import { useNotify, useRefresh, useRedirect, useDataProvider} from 'react-admin';
import { CreateButton, FilterButton, ExportButton } from "ra-ui-materialui";
import { Chip }  from '@mui/material';
import { List, downloadCSV, required } from 'react-admin';
import { useTranslate } from 'react-admin';
import React, { useState } from 'react';
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { Box, Typography } from "@mui/material";
import moment from "moment";


const getTime=() =>{
  const now = new Date();
  const formattedDate = now.toLocaleString('it-IT', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
  }).replace(/\//g, '-').replace(',', ''); 
  const parts = formattedDate.split(' ');
  const finalDate = `${parts[0].split('-').reverse().join('-')} ${parts[1]}`;
  return finalDate;
}

const SlotBulkActionButtons = () => (
  <Fragment>
      <BulkExportButton  maxResults= {15000}  exporter={exportToXLS} />      
      <BulkDeleteButton mutationMode="pessimistic"/>
  </Fragment>
);






const exportToXLS = async (records, fetchRelatedRecords) => {
  if (!records || records.length === 0) return;
  
  const entiMap = await fetchRelatedRecords(records, "ente_id", "enti");

  const exportedRecords = records.map(record => ({
    "Identificativo": record.id,
    Ente: entiMap[record.ente_id]
    ? `${entiMap[record.ente_id].nome}`
    : "N/A",
    "Giorno": record.giorno,    
    "Data di inizio validità": record.data_inizio_validita? moment(record.data_inizio_validita).format("DD/MM/YYYY") : "",
    "Data di fine validità": record.data_fine_validita? moment(record.data_fine_validita).format("DD/MM/YYYY") : "",
    "Capacità slot 07:00": record["0700"],
    "Capacità slot 07:30": record["0730"],
    "Capacità slot 08:00": record["0800"],
    "Capacità slot 08:30": record["0830"],
    "Capacità slot 09:00": record["0900"],
    "Capacità slot 09:30": record["0930"],
    "Capacità slot 10:00": record["1000"],
    "Capacità slot 10:30": record["1030"],
    "Capacità slot 11:00": record["1100"],
    "Capacità slot 11:30": record["1130"],
    "Capacità slot 12:00": record["1200"],
    "Capacità slot 12:30": record["1230"],
    "Capacità slot 13:00": record["1300"],
    "Capacità slot 13:30": record["1330"],
    "Capacità slot 14:00": record["1400"],
    "Capacità slot 14:30": record["1430"],
    "Capacità slot 15:00": record["1500"],
    "Capacità slot 15:30": record["1530"],
    "Capacità slot 16:00": record["1600"],
    "Capacità slot 16:30": record["1630"],
    "Capacità slot 17:00": record["1700"],
    "Capacità slot 17:30": record["1730"],
    "Capacità slot 18:00": record["1800"],
    "Capacità slot 18:30": record["1830"],
    "Capacità slot 19:00": record["1900"],
    "Capacità slot 19:30": record["1930"],						
    "Data di inserimento": record.timestamp_submit

  }));
  const worksheet = XLSX.utils.json_to_sheet(exportedRecords);
  const colWidths = Object.keys(exportedRecords[0] || {}).map(key => ({
    wch: Math.max(
      key.length, 
      ...exportedRecords.map(record => (record[key] ? record[key].toString().length : 0))
    )
  }));
  worksheet["!cols"] = colWidths;
  
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Dati");  
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  saveAs(data, "disponibilita.xlsx");
};


/*
const exportToXLS = (records, fetchRelatedRecords) => {  
  const worksheet = XLSX.utils.json_to_sheet(records);    

  const colWidths = Object.keys(records[0] || {}).map(key => ({
    wch: Math.max(
        key.length, // Larghezza del nome della colonna
        ...records.map(record => (record[key] ? record[key].toString().length : 0)) // Larghezza del contenuto
    )
}));
worksheet["!cols"] = colWidths;

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Dati");  
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });    
  const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  saveAs(data, "disponibilita.xlsx");
};
*/


const QuickFilter = ({ label }) => {
  const translate = useTranslate();  
  return <Chip label={translate(label)} />;
};

const SlotFilters = [
  <TextInput label="Cerca" source="q" alwaysOn />,
 
];
/*
const exporter1 = (records, fetchRelatedRecords) => {
      let data;
      let data1;
      let dataForExport;



fetchRelatedRecords(records, 'ente_id', 'enti').then(enti => {
    //console.log("I1:", records);    
    data1 = records.map(record => ({
          ...record,
          ente: enti[record.ente_id].nome,                
    })); 
    //console.log("EXPORT-1",data1);
    //console.log("EXP3",JSON.stringify(data));
    fetchRelatedRecords(data1,'circuito_id','circuiti').then(circuiti =>{
        //console.log("I2",data1);
        data = data1.map(record => ({
        ...record,
        circuito: circuiti[record.circuito_id].circuito,                
    })); 
    //console.log("EXPORT-2",data);
    
    dataForExport = data.map(record => {        
      const { ente_id,circuito_id,gruppo_id,id, ...dataForExport } =record;
      return dataForExport;
  });
     
  //}); 

    jsonExport(dataForExport, {
      headers: ['ente', 'circuito', "timestamp_submit","cognome", "nome", "sesso", "data_nascita", "stato_nascita", 
      "provincia_nascita", "luogo_nascita", "indirizzo_residenza", "citta_residenza", "cap_residenza", "provincia_residenza", "codice_fiscale", 
      "codice_abbonamento_musei",  "scadenza_abbonamento_musei", "confermaPresaDAtto", "presaDAtto1","presaDAtto", "cognome_genitore",
      "nome_genitore", "email_genitore", "telefono_genitore"],
   

  }, (err, csv) => {
      downloadCSV(csv, 'registrazioni');
  });
  })
    
});
};
*/  

const SlotActions = (props) => {
  const {
    className,
    basePath,    
    resource,
    currentSort,
    filterValues,
    exporter,
  } = props;
  return (
    <TopToolbar className={className}>
      <SelectColumnsButton />            
      <FilterButton/>
      <CreateButton basePath={basePath} />
      <ExportButton         
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        maxResults= {15000}
        exporter={exporter}
      />        
    </TopToolbar>
  );
};


export const SlotList = (props) => (
  <List {...props} sort={{ field: 'timestamp_submit', order: 'DESC' }} filters={SlotFilters} perPage={50} actions={<SlotActions exporter={exportToXLS}/>}>
  
  

  
    <DatagridConfigurable  bulkActionButtons={<SlotBulkActionButtons />}> 
    <TextField source="id" />     
    <ReferenceField source="ente_id" reference="enti" label="Ente">
        <TextField source="nome" />
    </ReferenceField>            
    <DateField source="data_inizio_validita" label="Data di inizio validità" locales="it-IT" showTime={false}/>  
    <DateField source="data_fine_validita" label="Data di fine validità" locales="it-IT" showTime={false}/>  
    <TextField source="giorno"/> 
    <TextField source="0700" label="07.00"/>
    <TextField source="0730" label="07.30"/>
    <TextField source="0800" label="08.00"/>
    <TextField source="0830" label="08.30"/>
    <TextField source="0900" label="09.00"/>
    <TextField source="0930" label="09.30"/>
    <TextField source="1000" label="10.00"/>
    <TextField source="1030" label="10.30"/>
    <TextField source="1100" label="11.00"/>
    <TextField source="1130" label="11.30"/>
    <TextField source="1200" label="12.00"/>
    <TextField source="1230" label="12.30"/>
    <TextField source="1300" label="13.00"/>
    <TextField source="1330" label="13.30"/>
    <TextField source="1400" label="14.00"/>
    <TextField source="1430" label="14.30"/>
    <TextField source="1500" label="15.00"/>
    <TextField source="1530" label="15.30"/>
    <TextField source="1600" label="16.00"/>
    <TextField source="1630" label="16.30"/>
    <TextField source="1700" label="17.00"/>
    <TextField source="1730" label="17.30"/>
    <TextField source="1800" label="18.00"/>
    <TextField source="1830" label="18.30"/>
    <TextField source="1900" label="19.00"/>
    <TextField source="1930" label="19.30"/>

    <EditButton/>
    </DatagridConfigurable>
  </List>
);

const SlotEditToolbar = props => (
  <Toolbar {...props} >
      <SaveButton />
  </Toolbar>
);

export const SlotEdit = () => {
  const notify = useNotify();  
  const redirect = useRedirect();
  const dataProvider = useDataProvider();

  const transform = (data) => {
    console.log(data);
    const sanitizedData = {};
    for (const key in data) {
        sanitizedData[key] = data[key].toString(); 
    }
    sanitizedData["timestamp_submit"]=getTime();
    return sanitizedData; 
    //return data;
};

  const handleEdit = async (values) => {

    try {
      var data2=transform(values);
      console.log("Editing",values,data2);
      console.log(data2.id, values.id);
      const { data } = await dataProvider.update('slots', { id:data2.id, data: data2 });          
      console.log('Resource updated successfully:', data, values);          
      notify("Dati aggiornati con successo!", { messageArgs: { smart_count: 1 } });
      redirect('/slots');                  
    } catch (error) {      
      notify("Qualcosa è andato storto nella creazione degli slots. Riprovare più tardi");
    }
  };

  


  return(  
      <Edit title={<username />} mutationMode="pessimistic" transform={transform}> 
        
      <SimpleForm onSubmit={handleEdit} sx={{ maxWidth: '100%' }} toolbar={<SlotEditToolbar />} > 
      <Typography variant="h6" gutterBottom>
        Disponibilità giorno per giorno
      </Typography>             

    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <ReferenceInput source="ente_id" reference="enti" label="Ente" perPage={1000} >     
                <SelectInput disabled={true}  sx={{textAlign: "left"}} option="nome" optionText="nome"  options={{fullWidth:true}} fullWidth validate={required()}/>
            </ReferenceInput>
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
        <SelectInput sx={{textAlign: "left"}}  disabled={true}  source="giorno"  options={{fullWidth:true}} fullWidth abel="Giorno"
            choices={[{ id: 'lunedì', name: 'Lunedì' },{ id: 'martedì', name: 'Martedì' },{ id: 'mercoledì', name: 'Mercoledì' },{ id: 'giovedì', name: 'Giovedì' },{ id: 'venerdì', name: 'Venerdì' },{ id: 'sabato', name: 'Sabato' },{ id: 'domenica', name: 'Domenica' } ]}/> 
        </Box>
    </Box>
    
    {/** DA COMMENTARE: INIZIO  */}
    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>    
      <DateInput source="data_inizio_validita" label="Data di inizio validità" locales="it-IT" fullWidth validate={required()}/>    
      </Box>
      <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
      <DateInput source="data_fine_validita" label="Data di fine validità" locales="it-IT" fullWidth validate={required()}/>    
      </Box>
    </Box>  
    {/** DA COMMENTARE: FINE  */}    

    <Typography variant="h6" gutterBottom>
        Mattina (ogni box rappresenta uno slot di 30 minuti a partire dalle 07:00 fino alle 13.00)
    </Typography> 

    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
      <Box key={"700"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="0700" label="07:00"  validate={[required()]} />
      </Box>
      <Box key={"730"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="0730" label="07:30"  validate={[required()]} />
      </Box>
      <Box key={"800"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="0800" label="08:00" defaultValue={25} validate={[required()]} />
      </Box>
      <Box key={"830"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="0830" label="08:30" defaultValue={25} validate={[required()]} />
      </Box>
      <Box key={"900"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="0900" label="09:00" defaultValue={25} validate={[required()]} />
      </Box>
      <Box key={"930"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="0930" label="09:30" defaultValue={25} validate={[required()]} />
      </Box>
      <Box key={"1000"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="1000" label="10:00" defaultValue={25} validate={[required()]} />
      </Box>
      <Box key={"1030"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="1030" label="10:30" defaultValue={25} validate={[required()]} />
      </Box>
      <Box key={"1100"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="1100" label="11:00" defaultValue={25} validate={[required()]} />
      </Box>
      <Box key={"1130"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="1130" label="11:30" defaultValue={25} validate={[required()]} />
      </Box>
      <Box key={"1200"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="1200" label="12:00" defaultValue={25} validate={[required()]} />
      </Box>
      <Box key={"1230"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="1230" label="12:30" defaultValue={25} validate={[required()]} />
      </Box>
      <Box key={"1300"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="1300" label="13:00" defaultValue={25} validate={[required()]} />
      </Box>            
    </Box>

    <Typography variant="h6" gutterBottom>
        Pomeriggio (ogni box rappresenta uno slot di 30 minuti a partire dalle 13:30 fino alle 20.00)
    </Typography> 

    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>      
      <Box key={"1330"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="1330" label="13:30" defaultValue={0} validate={[required()]} parse={value => value === undefined || value === null || value === "" ? 0 : Number(value)}/>
      </Box>
      <Box key={"1400"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="1400" label="14:00" defaultValue={25} validate={[required()]} />
      </Box>
      <Box key={"1430"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="1430" label="14:30" defaultValue={25} validate={[required()]} />
      </Box>
      <Box key={"1500"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="1500" label="15:00" defaultValue={25} validate={[required()]} />
      </Box>
      <Box key={"1530"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="1530" label="15:30" defaultValue={25} validate={[required()]} />
      </Box>
      <Box key={"1600"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="1600" label="16:00" defaultValue={25} validate={[required()]} />
      </Box>
      <Box key={"1630"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="1630" label="16:30" defaultValue={25} validate={[required()]} />
      </Box>
      <Box key={"1700"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="1700" label="17:00" defaultValue={25} validate={[required()]} />
      </Box>
      <Box key={"1730"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="1730" label="17:30" defaultValue={25} validate={[required()]} />
      </Box>
      <Box key={"1800"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="1800" label="18:00" defaultValue={25} validate={[required()]} />
      </Box>
      <Box key={"1830"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="1830" label="18:30" defaultValue={0} validate={[required()]} parse={value => value === undefined || value === null || value === "" ? 0 : Number(value)}/>
      </Box>
      <Box key={"1900"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="1900" label="19:00" defaultValue={0} validate={[required()]} parse={value => value === undefined || value === null || value === "" ? 0 : Number(value)}/>
      </Box>
      <Box key={"1930"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="1930" label="19:30" validate={[required()]} parse={value => value === undefined || value === null || value === "" ? 0 : Number(value)}/>
      </Box>            
    </Box>
        </SimpleForm>
      </Edit>
    )
};



const transform2 = (data) => {
  const sanitizedData = {};
  for (const key in data) {   
      sanitizedData[key] = data[key]; 
  }
  sanitizedData["timestamp_submit"]=getTime();
  //sanitizedData["conferma_presa_atto"]="Confermo la presa visione";    
  return sanitizedData; 
};



  
  
  
  
    
  
  


export const SlotCreate =  ({ onSuccess, ...props }) => {    
  const notify = useNotify();    
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  
  const handleCreate = async (values) => {
    try {
      var data2=transform2(values);
      const { data } = await dataProvider.create('slots', { data: data2 });            
      console.log('Resource created successfully:', data, values);      
      notify("Slot creato correttamente! ", { messageArgs: { smart_count: 1 } });
      redirect('/slots');        
    } catch (error) {
      notify("Qualcosa è andato storto nella creazione degli slots. Riprovare più tardi");
    }
  };
  return(

    <Create transform={transform2}  {...props} >       
    <SimpleForm onSubmit={handleCreate}  sx={{ maxWidth: '100%' }}> 
    <Typography variant="h6" gutterBottom>
        Disponibilità giorno per giorno
    </Typography>       
    
    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <ReferenceInput source="ente_id" reference="enti" label="Ente" perPage={1000} >     
                <SelectInput sx={{textAlign: "left"}} option="nome" optionText="nome"  options={{fullWidth:true}} fullWidth validate={required()}/>
            </ReferenceInput>
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
        <SelectInput sx={{textAlign: "left"}}  source="giorno"  options={{fullWidth:true}} fullWidth abel="Giorno"
            choices={[{ id: 'lunedì', name: 'Lunedì' },{ id: 'martedì', name: 'Martedì' },{ id: 'mercoledì', name: 'Mercoledì' },{ id: 'giovedì', name: 'Giovedì' },{ id: 'venerdì', name: 'Venerdì' },{ id: 'sabato', name: 'Sabato' },{ id: 'domenica', name: 'Domenica' } ]}/> 
        </Box>
    </Box>
    
    {/** DA COMMENTARE: INIZIO  */}
    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>    
      <DateInput source="data_inizio_validita" label="Data di inizio validità" locales="it-IT" fullWidth validate={required()}/>    
      </Box>
      <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
      <DateInput source="data_fine_validita" label="Data di fine validità" locales="it-IT" fullWidth validate={required()}/>    
      </Box>
    </Box>  
    {/** DA COMMENTARE: FINE  */}    

    <Typography variant="h6" gutterBottom>
        Mattina (ogni box rappresenta uno slot di 30 minuti a partire dalle 07:00 fino alle 13.00)
    </Typography> 

    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
      <Box key={"700"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="0700" label="07:00" defaultValue={0} validate={[required()]} />
      </Box>
      <Box key={"730"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="0730" label="07:30" defaultValue={0} validate={[required()]} />
      </Box>
      <Box key={"800"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="0800" label="08:00" defaultValue={25} validate={[required()]} />
      </Box>
      <Box key={"830"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="0830" label="08:30" defaultValue={25} validate={[required()]} />
      </Box>
      <Box key={"900"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="0900" label="09:00" defaultValue={25} validate={[required()]} />
      </Box>
      <Box key={"930"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="0930" label="09:30" defaultValue={25} validate={[required()]} />
      </Box>
      <Box key={"1000"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="1000" label="10:00" defaultValue={25} validate={[required()]} />
      </Box>
      <Box key={"1030"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="1030" label="10:30" defaultValue={25} validate={[required()]} />
      </Box>
      <Box key={"1100"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="1100" label="11:00" defaultValue={25} validate={[required()]} />
      </Box>
      <Box key={"1130"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="1130" label="11:30" defaultValue={25} validate={[required()]} />
      </Box>
      <Box key={"1200"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="1200" label="12:00" defaultValue={25} validate={[required()]} />
      </Box>
      <Box key={"1230"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="1230" label="12:30" defaultValue={25} validate={[required()]} />
      </Box>
      <Box key={"1300"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="1300" label="13:00" defaultValue={25} validate={[required()]} />
      </Box>            
    </Box>

    <Typography variant="h6" gutterBottom>
        Pomeriggio (ogni box rappresenta uno slot di 30 minuti a partire dalle 13:30 fino alle 20.00)
    </Typography> 

    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>      
      <Box key={"1330"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="1330" label="13:30" defaultValue={0} validate={[required()]} />
      </Box>
      <Box key={"1400"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="1400" label="14:00" defaultValue={25} validate={[required()]} />
      </Box>
      <Box key={"1430"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="1430" label="14:30" defaultValue={25} validate={[required()]} />
      </Box>
      <Box key={"1500"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="1500" label="15:00" defaultValue={25} validate={[required()]} />
      </Box>
      <Box key={"1530"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="1530" label="15:30" defaultValue={25} validate={[required()]} />
      </Box>
      <Box key={"1600"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="1600" label="16:00" defaultValue={25} validate={[required()]} />
      </Box>
      <Box key={"1630"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="1630" label="16:30" defaultValue={25} validate={[required()]} />
      </Box>
      <Box key={"1700"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="1700" label="17:00" defaultValue={25} validate={[required()]} />
      </Box>
      <Box key={"1730"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="1730" label="17:30" defaultValue={25} validate={[required()]} />
      </Box>
      <Box key={"1800"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="1800" label="18:00" defaultValue={25} validate={[required()]} />
      </Box>
      <Box key={"1830"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="1830" label="18:30" defaultValue={0} validate={[required()]} />
      </Box>
      <Box key={"1900"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="1900" label="19:00" defaultValue={0} validate={[required()]} />
      </Box>
      <Box key={"1930"} flex="1 1 7%" minWidth="30px">
        <NumberInput source="1930" label="19:30" defaultValue={0} validate={[required()]} />
      </Box>            
    </Box>


    </SimpleForm>
    </Create> 
  );
}
