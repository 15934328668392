//import { List, Datagrid, TextField, EmailField } from "react-admin";
import { List, SimpleList, SelectColumnsButton, Datagrid, TextField, EmailField, UrlField } from "react-admin";

import { Edit, Create, SimpleForm, ReferenceInput, TextInput, ReferenceField, SelectInput, EditButton} from "react-admin";
import { TopToolbar } from "react-admin";
import { CreateButton, FilterButton, ExportButton } from "ra-ui-materialui";
import { BulkDeleteButton, BulkExportButton, Toolbar, SaveButton  } from 'react-admin';
import { MyEmailField } from "./MyUrlField";
import { MyUrlField } from "./MyUrlField";
import { Fragment } from 'react';
import { required } from 'react-admin';
import { Box, Typography } from "@mui/material";
import { useNotify, useRefresh, useRedirect, useDataProvider} from 'react-admin';
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

const getTime=() =>{
  const now = new Date();
  const formattedDate = now.toLocaleString('it-IT', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
  }).replace(/\//g, '-').replace(',', ''); 
  const parts = formattedDate.split(' ');
  const finalDate = `${parts[0].split('-').reverse().join('-')} ${parts[1]}`;
  return finalDate;
}
/*
const exportToXLS = (records, fetchRelatedRecords) => {  
  const worksheet = XLSX.utils.json_to_sheet(records);    

  const colWidths = Object.keys(records[0] || {}).map(key => ({
    wch: Math.max(
        key.length, // Larghezza del nome della colonna
        ...records.map(record => (record[key] ? record[key].toString().length : 0)) // Larghezza del contenuto
    )
}));
worksheet["!cols"] = colWidths;

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Dati");  
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });    
  const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  saveAs(data, "enti.xlsx");
};
*/

const exportToXLS = async (records, fetchRelatedRecords) => {
  if (!records || records.length === 0) return;
  
  
  const recordsMap = records.reduce((acc, record) => {
    acc[record.id] = record;
    return acc;
  }, {});		
  

  const exportedRecords = records.map(record => ({
    "Identificativo": record.id,      
    "Nome": record.nome,
    "Ragione sociale": record.ragione_sociale,
    "Sito web": record.sito_web,
    "Indirizzo": record.indirizzo,
    "Telefono": record.telefono,
    "Referente": record.referente,	
    "E-mail referente": record.email_referente,
    "E-mail privacy": record.email_privacy,            
    "Ente di appartenenza": record.parent_id
      ? `${recordsMap[record.parent_id]?.nome || 'Ente sconosciuto'}`
      : "N/A",    
    "Data di inserimento": record.timestamp_inserimento    
  }));
  const worksheet = XLSX.utils.json_to_sheet(exportedRecords);
  const colWidths = Object.keys(exportedRecords[0] || {}).map(key => ({
    wch: Math.max(
      key.length, 
      ...exportedRecords.map(record => (record[key] ? record[key].toString().length : 0))
    )
  }));
  worksheet["!cols"] = colWidths;
  
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Dati");  
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  saveAs(data, "enti.xlsx");
};


const EnteBulkActionButtons = () => (
  <Fragment>
      <BulkExportButton maxResults= {15000}  exporter={exportToXLS}/>      
      <BulkDeleteButton mutationMode="pessimistic"/>
  </Fragment>
);

const EnteFilters = [
  <TextInput label="Cerca" source="q" alwaysOn />,  
];

const EnteActions = (props) => {
  const {
    className,
    basePath,    
    resource,
    currentSort,
    filterValues,
    exporter,
  } = props;
  return (
    <TopToolbar className={className}>
      <SelectColumnsButton />            
      <FilterButton/>
      <CreateButton basePath={basePath} /> 
      <ExportButton         
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
        maxResults= {15000}   
      />    
    </TopToolbar>
  );
};



const transform = (data) => {
  console.log("TRANSFORMING",data);
  const sanitizedData = {};
  for (const key in data) {      
      sanitizedData[key] = data[key]; 
  }
  sanitizedData["parent_id"]=localStorage.getItem("ente");
  sanitizedData["timestamp_inserimento"]=getTime();
  sanitizedData["tipologia_id"]=1;
  console.log("TRANSFORMING3",sanitizedData);
  return sanitizedData;
};


export const EnteAdminList = () => (
  <List filters={EnteFilters} perPage={50} actions={<EnteActions exporter={exportToXLS}/>}> 
    {/*<Datagrid rowClick="edit">*/}
    <Datagrid bulkActionButtons={<EnteBulkActionButtons />}> 
      <TextField source="id" />
      <TextField source="nome" />
      <TextField source="ragione_sociale" />
      <TextField source="referente" />
      <TextField source="telefono" />      
      <MyEmailField source="email_privacy" label="E-mail privacy"/>
      <MyEmailField source="email_referente" label="E-mail referente"/>
      <MyUrlField source="sito_web" label="Sito Web"/>   
      <TextField source="indirizzo" />                  
       <ReferenceField source="parent_id" reference="enti" label="Parent">
        <TextField source="nome" />
      </ReferenceField>
      <EditButton/>
    </Datagrid>
  </List>
);


const EnteEditToolbar = props => (
  <Toolbar {...props} >
      <SaveButton />
  </Toolbar>
);



export const EnteAdminEdit  = () => {
    const notify = useNotify();  
    const redirect = useRedirect();
    const dataProvider = useDataProvider();

    const handleEdit = async (values) => {

    try {
      var data2=transform(values);
      console.log("Editing",values,data2);
      
      const { data } = await dataProvider.update('enti', { id:data2.id, data: data2 });          
      console.log('Resource updated successfully:', data, values);          
      notify("Dati aggiornati con successo!", { messageArgs: { smart_count: 1 } });
      redirect('/enti');                  
    } catch (error) {      
      notify("Qualcosa è andato storto nella modifica dell'ente. Riprovare più tardi");
    }
  };
  
  return(  
  <Edit title={<username />} mutationMode="pessimistic" transform={transform}> 
    <SimpleForm onSubmit={handleEdit} sx={{ maxWidth: '100%' }} toolbar={<EnteEditToolbar />} >      
        <Typography variant="h6" gutterBottom>
                    Dati ente
        </Typography>   
        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <TextInput source="nome"  fullWidth  validate={required()}/>           
              </Box>
              <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                  <TextInput source="ragione_sociale" validate={required()} fullWidth />                        
              </Box>
        </Box>

        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <TextInput source="indirizzo" validate={required()} fullWidth  />
              </Box>
              <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
              <TextInput source="telefono" validate={required()} fullWidth />     
              </Box>
        </Box>

        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <TextInput source="referente" validate={required()} fullWidth />
              </Box>
              <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                  <TextInput source="email_referente" fullWidth />
              </Box>
        </Box>
      
        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <TextInput source="sito_web" fullWidth  />
              </Box>
              <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
              <TextInput source="email_privacy" validate={required()} fullWidth  />
              </Box>
        </Box>
      {/*
        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <ReferenceInput source="parent_id" reference="enti" label="Parent" perPage={1000}>      
                      <SelectInput option="nome" validate={required()} optionText="nome"  options={{fullWidth:true}} sx={{textAlign: "left"}} fullWidth />
                </ReferenceInput>                
              </Box>
              <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>                
              </Box>
        </Box>   
        */}
    </SimpleForm>
  </Edit>
)
};

export const EnteAdminCreate =  ({ onSuccess, ...props }) => {
    const notify = useNotify();    
    const redirect = useRedirect();
    const dataProvider = useDataProvider();
        
  const handleCreate = async (values) => {
      try {
        var data2=transform(values);
        const { data } = await dataProvider.create('enti', { data: data2 });            
        console.log('Resource created successfully:', data, values);      
        notify("Ente creato correttamente! ", { messageArgs: { smart_count: 1 } });
        redirect('/enti');        
      } catch (error) {
        notify("Qualcosa è andato storto nella creazione dell'ente. Riprovare più tardi");
      }
  };
    
  return(  
    <Create transform={transform}  {...props} >   
    <SimpleForm onSubmit={handleCreate} sx={{ maxWidth: '100%' }}> 
        <Typography variant="h6" gutterBottom>
                    Dati ente
        </Typography>   
        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <TextInput source="nome"  fullWidth  validate={required()}/>           
              </Box>
              <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                  <TextInput source="ragione_sociale" validate={required()} fullWidth />                        
              </Box>
        </Box>

        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <TextInput source="indirizzo" validate={required()} fullWidth  />
              </Box>
              <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
              <TextInput source="telefono" validate={required()} fullWidth />     
              </Box>
        </Box>

        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <TextInput source="referente" validate={required()} fullWidth />
              </Box>
              <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                  <TextInput source="email_referente" fullWidth />
              </Box>
        </Box>
      
        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <TextInput source="sito_web" fullWidth  />
              </Box>
              <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
              <TextInput source="email_privacy" validate={required()} fullWidth  />
              </Box>
        </Box>
      
        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <ReferenceInput source="parent_id" reference="enti" label="Parent" perPage={1000}>      
                      <SelectInput option="nome" validate={required()} optionText="nome"  options={{fullWidth:true}} sx={{textAlign: "left"}} fullWidth />
                </ReferenceInput>                
              </Box>
              <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>                
              </Box>
        </Box>   
         

    </SimpleForm>
  </Create>
  )
};

