import React, { useState, useEffect } from "react";
import Scheduler, { SchedulerData, ViewTypes, SummaryPos, DATE_FORMAT } from "react-big-scheduler";
import moment from "moment";
import "moment/locale/it";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import Button from '@mui/material/Button';
import withDragDropContext from "./withDnDContext";
import { useNotify, useDataProvider, ConfirmClasses } from 'react-admin';
import "react-big-scheduler/lib/css/style.css";
import { useRefresh } from 'react-admin';
import { useEditContext,useGetOne,useGetMany,useGetManyReference } from 'react-admin';
import {ThreeDots} from 'react-loader-spinner';
import "./a.css";
import { Typography } from "@mui/material";

const DashboardDailyView =  ({ enteId }) => {
  const refresh = useRefresh();
  const [loading, setLoading]= useState(true);  
  //const [enteId, setEnteId] = useState(48);          
  //const [tobeReloaded, setToBeReloaded] = useState(0);   
  //const [reloadKey, setReloadKey] = useState(0);       
  //const [dataAttivita, setDataAttivita] = useState(new Date().toISOString().slice(0, 10).replace('T', ' '));
  const [dataAttivita, setDataAttivita] = useState(moment().format("YYYY-MM-DD"));
  //const [prenotaz, setPrenotaz] = useState([]);       
  let totale;
  let totale_pre;
  let totale_iscr
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [isPopoverVisible, setIsPopoverVisible] = useState(true);  

  const { data: slots, isPending2, error2 } = useGetManyReference(
      'slots',
      { 
          target: 'ente_id',
          id: enteId,
          pagination: { page: 1, perPage: 1000 },
          sort: { field: 'giorno', order: 'DESC' }
      }
  );
  
  const { data: prenotazioni, isPending, error } = useGetManyReference(
    'prenotazioni',
    { 
        target: 'ente_id',
        id: enteId,
        filter: {            
            data_attivita: dataAttivita,     
            stato_prenotazione_id_neq: 3         
          },
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'bambino_id', order: 'DESC' }
    }
  );

  const bambinoIds = prenotazioni ? [...new Set(prenotazioni.map(p => p.bambino_id))] : [];
  
  const { data: bambini, isPending: isPendingBambini, error: errorBambini } = useGetMany(
    'bambini',
    { ids: bambinoIds }
  );

  const getSummary = (schedulerData, headerEvents, slotId, slotName, headerStart, headerEnd) => {
    if(totale && totale_iscr && totale_pre){
     //console.log("CALLED GS",slotName,headerStart,headerEnd );
      //console.log("CALLED GS",headerStart,headerEnd,totale);
      let dd=moment(headerStart).format("HH:mm");
      //console.log("MOMENT",dd, slotName);
      if(slotName==="Totale Complessivo"){
        //console.log("TOT",totale,Object.keys(totale).length,dd);
        if(Object.keys(totale).length > 0){
          if (dd in totale) {
            //console.log("Valore t:", totale[dd]); 
            return {text: totale[dd], color: 'black', fontSize: '0.9rem'};
          }
          else
            return {text: "0", color: 'black', fontSize: '0.9rem'};        
        }
        else
          return {text: "0", color: 'black', fontSize: '0.9rem'};        
        }
        if(slotName==="Totale Pre Iscrizioni"){
          //console.log("TOTPRE",totale_pre,Object.keys(totale_pre).length,dd);
          if(Object.keys(totale_pre).length > 0){
            if (dd in totale_pre) {
              //console.log("Valore p:", totale_pre[dd]); 
              return {text: totale_pre[dd], color: 'black', fontSize: '0.9rem'};
            }
            else
              return {text: "0", color: 'black', fontSize: '0.9rem'};        
          }
          else
              return {text: "0", color: 'black', fontSize: '0.9rem'};        
        }
        if(slotName==="Totale Iscrizioni"){
          //console.log("TOTISCR",totale_iscr,Object.keys(totale_iscr).length,dd);
          if(Object.keys(totale_iscr).length > 0){
            if (dd in totale_iscr) {
              //console.log("Valore i:", totale_iscr[dd]); 
              return {text: totale_iscr[dd], color: 'black', fontSize: '0.9rem'};
            }
            else
              return {text: "0", color: 'black', fontSize: '0.9rem'};        
          }  
          else
            return {text: "0", color: 'black', fontSize: '0.9rem'};        
        }          
    }
  }

  function hasNonZeroValue(entry, time) { 
    const key = time.replace(":", "");        
    return entry[key] && entry[key] !== "0";
}

  const isNonWorkingTime = (schedulerData,time) => {
    if(slots){
      //console.log("CALLED",time,slots);
      var t=moment(time);
      //console.log("giorno",t.format("dddd"));
      const intDay=t.format("dddd");
      const foundEntry = slots.find(entry => entry.giorno === intDay);

      if (foundEntry) {
        //console.log(`Trovata  ${intDay}:`, foundEntry);
        return !hasNonZeroValue(foundEntry,t.format("HH:mm"));
      } else {
        //console.log(`Nessuna entry trovata per ${intDay}`);
        return false;
      }    
    }
    
  };
  
  //const forceReload = () => {
  //  setReloadKey(prev => prev + 1);
  //};

  const createSchedulerData = (events = [], resources = []) => {
    const scheduler = new SchedulerData(
      dataAttivita,  
      ViewTypes.Day,
      false,
      false,
      {
        dayStartFrom: 7,
        dayStopTo: 19,
        isEventPerspective: true,
        views: [],
        nonAgendaOtherCellHeaderFormat: "ddd D/M",
        resourceName: "Disponibilità",
        //schedulerWidth: "90%",
        //resourceTableWidth: 200,
        //agendaResourceTableWidth:"450",
        //resourceTableWidth: "350", 
        dayResourceTableWidth:400,
        //resourceTableWidth: "10%",
        startResizable: false,
        endResizable: false,
        movable: false,
        creatable: false,
        nonWorkingTimeHeadBgColor:"white",
        //nonWorkingTimeBodyBgColor:"red",
        nonWorkingTimeHeadColor:"black",    
        schedulerWidth:"100",
        //getSummaryFunc: getSummary,
        
      }
    );
    
    scheduler.setResources(resources); 
    scheduler.setEvents(events);    
    //scheduler.behaviours.isNonWorkingTimeFunc = isNonWorkingTime;
    scheduler.behaviors.isNonWorkingTimeFunc= isNonWorkingTime;
    scheduler.config.summaryPos = SummaryPos.Top;
    scheduler.config.resourceTableWidth = 200;
    scheduler.behaviors.getSummaryFunc=getSummary;
    //scheduler.schedulerDataUpdated = () => {
    //  console.log("UPDATING");
    //  scheduler.updateScheduler();  // Forza il ricalcolo del layout
    //};
    //scheduler.eventItemPopoverTemplateResolver = eventItemPopoverTemplateResolver;
    /*scheduler.behaviors.resourceItemTemplateResolver = (resource) => {   
      console.log("RITP",resource);
      return (
          <div style={{ display: "flex", alignItems: "center" }}>              
                 <span
                 style={{
                   display: "inline-block",
                   width: "12px",
                   height: "12px",
                   borderRadius: "50%",            
                   //backgroundColor: record.valid ? "green" : "red",
                   backgroundColor: resource.valid === true ? "green" 
                     : resource.valid === false ? "red" 
                     : "gray"
                 }}/>                                      
              <span>{resource.name}</span>
          </div>
      );}
      */

    return scheduler;
};
 
 const [schedulerData, setSchedulerData] = useState(createSchedulerData([], []));
  
  useEffect(() => {
    moment.locale("it");
    //console.log("DATA",dataAttivita);
               
    if (prenotazioni && bambini) {
      const prenotazioniConNomi = prenotazioni && bambini 
      ? prenotazioni.map(p => {
        const bambino = bambini.find(b => b.id === p.bambino_id);
        return {
          ...p,
          bambinoCF: bambino 
            ? `${bambino.codice_fiscale}` : "Sconosciuto",
          bambinoNome: bambino 
            ? `${bambino.nome} ${bambino.cognome} (${bambino.codice_fiscale})` 
            : "Sconosciuto"
        };
        })
      : [];
      console.log("Prenotazioni con nomi:", prenotazioniConNomi);

      /*const res = prenotazioniConNomi.map(prenotazione => (
        {          
          id: prenotazione.bambino_id,
          name: prenotazione.bambinoNome
      }));
      */
      let res=[];
      let i = 0;
      const greenCircle = String.fromCodePoint(0x1F7E2); 
      const redCircle = String.fromCodePoint(0x1F534);   
      const grayCircle = String.fromCodePoint(0x26AA); 

      while (i < prenotazioniConNomi.length) {
        let circle=String.fromCodePoint(0x1F7E2); 
        if(prenotazioniConNomi[i].valid===false)
          circle=String.fromCodePoint(0x1F534); 
        else if(prenotazioniConNomi[i].valid===true)
          circle=String.fromCodePoint(0x1F7E2); 
        else
          circle=String.fromCodePoint(0x26AA); 
        
        const prenotazione = {
          id: prenotazioniConNomi[i].bambino_id,
          //name: prenotazioniConNomi[i].bambinoNome,
          name: `${circle} ${prenotazioniConNomi[i].bambinoNome}`,          
          valid: prenotazioniConNomi[i].valid,
        };

      
        if (!res.some(p => p.id === prenotazione.id)) {
          res.push(prenotazione);
        }

        i++;
      }

      //console.log("RES",res);
      //console.log("RES22",Datas.resources);
      
      const pren = prenotazioniConNomi.map(prenotazione => ({
          id: parseInt(prenotazione.id),
          start: `${prenotazione.data_attivita} ${prenotazione.ora_inizio_attivita}:00`,
          end: `${prenotazione.data_attivita} ${prenotazione.ora_fine_attivita}:00`,
          //rrule: "FREQ=WEEKLY;DTSTART=20210621T100000Z;BYDAY=MO,TU,WE,TH,FR,SA,SU",
          resourceId: prenotazione.bambino_id,
          //title: prenotazione.bambinoNome,
          title: "",
          //bgColor: "green",                    
          name: prenotazione.bambinoNome,
          valid:prenotazione.valid,
          codiceFiscale: prenotazione.bambinoCF,
          inizio_reale:`${prenotazione.ora_inizio_reale}`,
          stato:prenotazione.stato_prenotazione_id,
          fine_reale:`${prenotazione.ora_fine_reale}`,
          bgColor: prenotazione.stato_prenotazione_id=='5' ? "lightgrey"
          : prenotazione.stato_prenotazione_id=='4' ? "black"
          : prenotazione.ora_inizio_reale === null && prenotazione.ora_fine_reale === null ? "lightgreen" 
          : prenotazione.ora_inizio_reale != null && prenotazione.ora_fine_reale === null ? "orange" 
          : "lightblue"
          
      }));    
        pren.sort((a, b) => new Date(a.start) - new Date(b.start));
        console.log("PP",pren);
        
        //0602
        const timeSlotCounts = {}; 
        const timeSlotCountsPre= {}; 
        const timeSlotCountsIscr= {}; 


        const allTimeSlots = [];
        for (let hour = 7; hour <= 19; hour++) {
          const startTime = (hour < 10 ? `0${hour}` : `${hour}`) + ":00";
          allTimeSlots.push(startTime);
          allTimeSlots.push((hour < 10 ? `0${hour}` : `${hour}`) + ":30");
        }
        //console.log("test",pren, res,allTimeSlots);
        if(pren.length>0 && res.length>0){
            prenotazioniConNomi.forEach((prenotazione) => {
            
              const startTime = moment(`${prenotazione.data_attivita} ${prenotazione.ora_inizio_attivita}:00`);
              const endTime = moment(`${prenotazione.data_attivita} ${prenotazione.ora_fine_attivita}:00`);
            
              while (startTime < endTime) {
                const key = startTime.format("HH:mm");      
                //console.log(key,prenotazione.stato_prenotazione_id);        
                if(prenotazione.stato_prenotazione_id==5){
                  if (!timeSlotCountsPre[key]) {
                    timeSlotCountsPre[key] = 0;
                  }
                  timeSlotCountsPre[key]++;
                }
                //2 e 6
                
                if(prenotazione.stato_prenotazione_id==1||prenotazione.stato_prenotazione_id==2||prenotazione.stato_prenotazione_id==6){
                  if (!timeSlotCountsIscr[key]) {
                    timeSlotCountsIscr[key] = 0;
                  }
                  timeSlotCountsIscr[key]++;   
                }      
                if(prenotazione.stato_prenotazione_id==1 || prenotazione.stato_prenotazione_id==5||prenotazione.stato_prenotazione_id==2||prenotazione.stato_prenotazione_id==6){
                if (!timeSlotCounts[key]) {
                  timeSlotCounts[key] = 0;
                }
                timeSlotCounts[key]++;   
                }                  
                startTime.add(30, 'minutes');
              }
            });
            console.log("COUNTING",timeSlotCounts, timeSlotCountsPre, timeSlotCountsIscr);
            totale=timeSlotCounts;
            totale_iscr=timeSlotCountsIscr;
            totale_pre=timeSlotCountsPre;
          
            
            const ultimaRiga = {
              id: "totale_iscrizioni1", 
              start: Object.keys(timeSlotCountsIscr)[0], 
              end: Object.keys(timeSlotCountsIscr).slice(-1)[0], 
              resourceId: "totale_iscrizioni", 
              title: "", 
              bgColor: "lightgray", 
              name: "Totale iscrizioni", 
              codiceFiscale: "", 
              inizio_reale: "",
              fine_reale: "",
              conteggi: timeSlotCountsIscr, 
            };
            //pren.push(ultimaRiga); 
            if(Object.keys(timeSlotCountsIscr)[0]!= undefined)
              pren.push(ultimaRiga); 

            //console.log("T");
            //console.log("T1",Object.keys(timeSlotCountsPre)[0]);
            //console.log("T2",Object.keys(timeSlotCountsPre).slice(-1)[0]);
            const ultimaRigaPiuUno = {
              id: "totale_preiscrizioni1", 
              start: Object.keys(timeSlotCountsPre)[0], 
              end: Object.keys(timeSlotCountsPre).slice(-1)[0], 
              resourceId: "totale_preiscrizioni", 
              title: "", 
              bgColor: "lightgray", 
              name: "Totale Pre-iscrizioni", 
              codiceFiscale: "", 
              inizio_reale: "",
              fine_reale: "",
              conteggi: timeSlotCountsPre, 
            };
            if(Object.keys(timeSlotCountsPre)[0]!= undefined)
              pren.push(ultimaRigaPiuUno); 

            const ultimaRigaPiuDue = {
              id: "totale_complessivo1", 
              start: Object.keys(timeSlotCounts)[0], 
              end: Object.keys(timeSlotCounts).slice(-1)[0], 
              resourceId: "totale_complessivo", 
              title: "", 
              bgColor: "lightgray", 
              name: "Totale complessivo", 
              codiceFiscale: "", 
              inizio_reale: "",
              fine_reale: "",
              conteggi: timeSlotCounts, 
            };
            if(Object.keys(timeSlotCounts)[0]!= undefined)
              pren.push(ultimaRigaPiuDue);

            const rigaVuota= { id: "empty", name: "", bgColor: "#00f0f0" };
            const rigaConTotale = { id: "totale_iscrizioni", name: "Totale Iscrizioni", bgColor: "#00f0f0" };
            const rigaConTotaleUno = { id: "totale_preiscrizioni", name: "Totale Pre Iscrizioni", bgColor: "#00f0f0" };
            const rigaConTotaleDue = { id: "totale_complessivo", name: "Totale Complessivo", bgColor: "#00f0f0" };
            res.push(rigaVuota);
            res.push(rigaConTotale);
            res.push(rigaConTotaleUno);
            res.push(rigaConTotaleDue);
            console.log("PREN",pren,res);
        }
        //0602
        const newSchedulerData = createSchedulerData(pren,res);
                
        setSchedulerData(newSchedulerData); 
        const timer = setTimeout(() => {
          setLoading(false);
        }, 100); 
      }
    
  }, //[schedulerData,slots,enteId,prenotaz]);
  //[prenotazioni,bambini, dataAttivita,tobeReloaded]);
  [prenotazioni,bambini, dataAttivita]);
  //[prenotazioniConNomi,dataAttivita]);

/*
const eventClicked = (schedulerData, event) => {
    alert(`Evento cliccato: ${event.title}`);
  };
*/



const prevClick = () => {
  setLoading(true); 
  const newDate = moment(dataAttivita).subtract(1, 'days').format("YYYY-MM-DD");
  setDataAttivita(newDate);
};

const nextClick = () => {
  setLoading(true); 
  const newDate = moment(dataAttivita).add(1, 'days').format("YYYY-MM-DD");
  setDataAttivita(newDate);
};


const onSelectDate = (schedulerData, date) => {
  setLoading(true); 
  const newDate = moment(date).format("YYYY-MM-DD");
  console.log(newDate);
  setDataAttivita(newDate);
};


//0602
/*const eventItemTemplateResolver = (schedulerData, event, bgColor, isStart, isEnd, mustAddCssClass, mustBeHeight) => {
  
  if (event.id !== "totale") {
    return undefined;
  }

  return (
    <div style={{ 
      fontWeight: "bold", 
      textAlign: "center", 
      backgroundColor: "lightgray", 
      borderRadius: "4px", 
      padding: "5px"
    }}>
      {event.conteggi[event.start] || 0} eventi
    </div>
  );
}; */
//0602

const eventItemPopoverTemplateResolver = (schedulerData,eventItem,title,start,end,statusColor) => {
  setIsPopoverVisible(true); 
  title=eventItem.name;

  const handleRegistraIngresso = async (idP,ingressoP) => {
    console.log("HC2",idP, ingressoP);
    try {
        await dataProvider.update('prenotazioni', {
            id: idP,
            data: { ora_inizio_reale: ingressoP },
        });
        notify('Ingresso registrato con successo', 'success');     
        //eventItem.inizio_reale=""; 
        //forceReload();
        refresh();
    } catch (error) {
        notify('Errore durante l\'aggiornamento', 'error');
    }
  };

  const handleRegistraUscita = async (idP,ingressoP) => {
    console.log("HC2",idP, ingressoP);
    try {
        await dataProvider.update('prenotazioni', {
            id: idP,
            data: { ora_fine_reale: ingressoP },
        });
        notify('Uscita registratoacon successo', 'success');   
        //eventItem.fine_reale="";   
        //forceReload();
        refresh();
    } catch (error) {
        notify('Errore durante l\'aggiornamento', 'error');
    }
  };

  const handleClick = function() {
    console.log("HC",eventItem);
    handleRegistraIngresso(eventItem.id, moment().format('HH:mm'));    
    
    //setDataAttivita(dataAttivita);
    //setToBeReloaded(tobeReloaded+1);
    
    setIsPopoverVisible(false); 
    
  }

  const handleClickExit = function() {
    console.log("HC",eventItem);
    handleRegistraUscita(eventItem.id, moment().format('HH:mm'));
    //setIsPopoverVisible(false); 
    //setDataAttivita(dataAttivita);
    //setToBeReloaded(tobeReloaded+1);
    
    setIsPopoverVisible(false); 
    //forceReload();
  }
  if (!isPopoverVisible) {
    return null;  
  }
  return (    
    console.log("OVER",eventItem),
    <div style={{ width: "300px" }}>
      <Row type="flex" align="middle">
        <Col span={2}>
          <div
            className="status-dot"
            style={{ backgroundColor: statusColor }}
          />
        </Col>
        <Col span={22} className="overflow-text">
          <span className="header2-text" title={title}>
            {title}
          </span>
        </Col>
      </Row>
      <Row type="flex" align="middle">
        <Col span={2}>
          <div />
        </Col>
        <Col span={22}>
          <span className="header1-text">
            {start.format("HH:mm")} - {end.format("HH:mm")}
          </span>
        </Col>
      </Row>
      <Row type="flex" align="middle">
        <Col span={2}>
          <div />
        </Col>
        <Col span={22}>
          <span className="header1-text">
            {eventItem.stato!=5 ?(
              <div>
                {eventItem.stato==4 ?(
                  <div style={{ display:'flex', marginTop:'10px', marginBottom:'20px', width:'100%', color:"black", gap: '10px'}}>
                  ASSENTE
                </div>
                ):
                eventItem.stato==6 ?(
                  <div style={{ display:'flex', marginTop:'10px', marginBottom:'20px', width:'100%', color:"orange", gap: '10px'}}>
                  IN CORSO
                </div>
                ):
                eventItem.stato==2 ?(
                  <div style={{ display:'flex', marginTop:'10px', marginBottom:'20px', width:'100%', color:"lightblue", gap: '10px'}}>
                  CONSUNTIVATO
                </div>
                ):
                eventItem.stato==1 ?(
                  <div style={{ display:'flex', marginTop:'10px', marginBottom:'20px', width:'100%', color:"lightgreen", gap: '10px'}}>
                  PRENOTATO
                </div>
                ):
                (<></> )}
              
              <div style={{ display:'flex', marginTop:'10px', marginBottom:'20px', width:'100%', gap: '10px'}}>                 
                {eventItem.inizio_reale!="null" ?(
                  <Button disabled={true} onClick={handleClick} size="small" variant="outlined">Ingresso {eventItem.inizio_reale}</Button> 
                ):(
                  //console.log("22",moment(dataAttivita, "YYYY-MM-DD"), moment(), moment(dataAttivita, "YYYY-MM-DD").isSame(moment(), "day") , eventItem.inizio_reale!="null"),
                  <Button disabled={!moment(dataAttivita, "YYYY-MM-DD").isSame(moment(), "day") || eventItem.inizio_reale!="null" ? true:false} onClick={handleClick} size="small" variant="outlined">Registra ingresso</Button> 
                )}
                {eventItem.fine_reale!="null" ?(
                  <Button disabled={true} onClick={handleClickExit} size="small" variant="outlined">Uscita {eventItem.fine_reale}</Button> 
                ):(
                  //console.log("PDPMM",eventItem),
                  //console.log("23",moment(dataAttivita, "YYYY-MM-DD"), moment(), moment(dataAttivita, "YYYY-MM-DD").isSame(moment(), "day") , eventItem.inizio_reale!="null"),
                  <Button disabled={!moment(dataAttivita, "YYYY-MM-DD").isSame(moment(), "day") || eventItem.fine_reale!="null"|| eventItem.inizio_reale=="null" ? true:false} onClick={handleClickExit} size="small" variant="outlined">Registra uscita</Button>
                )}                
              </div>
             </div> 
            ):(
              <div style={{ display:'flex', marginTop:'10px', marginBottom:'20px', width:'100%', color:"lightgrey", gap: '10px'}}>
                PREISCRIZIONE
              </div>
            )}
          </span>
          <span className="header1-text">
              <div style={{ display:'flex', marginTop:'10px', marginBottom:'20px', width:'100%', gap: '10px'}}>

              {eventItem.valid === true ? (
                  <Typography style={{ color: 'green' }}>COPERTA DA TITOLO</Typography>
                ): eventItem.valid === false ? (
                  <Typography style={{ color: 'red' }}>MANCA COPERTURA TITOLO</Typography>
                ):(
                <></>
                
                )}   
                
              </div>
          </span>
        </Col>
      </Row>
     
    </div>
  );
};



const toggleExpandFunc = (schedulerData, slotId) => {
  schedulerData.toggleExpandStatus(slotId);
  setSchedulerData(schedulerData);
};


  return (
    <div >
         {loading ? (
        <div style={{ width:"25%",   display: "flex", marginLeft: "auto", marginRight: "auto" }}>
          <ThreeDots color="#2196F3" height='350' width='150'/>
        </div>
      ):(      
        
      <Scheduler                
        //key={reloadKey}
        schedulerData={schedulerData}
        prevClick={prevClick}
        nextClick={nextClick}
        onSelectDate={onSelectDate}
        //onViewChange={onViewChange}
        //eventItemClick={eventClicked}
        toggleExpandFunc={toggleExpandFunc}  
        eventItemPopoverTemplateResolver = {eventItemPopoverTemplateResolver}    
        //eventItemTemplateResolver = {eventItemTemplateResolver}
      />           
      )}
    </div>
  );
};

export default withDragDropContext(DashboardDailyView);
